import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  static getUserType // check if user is logged in
    (): any {
      throw new Error('Method not implemented.');
  }

  // base_url: string = environment.API_url + ':8203/api/v1';
  base_url: string = environment.API_url + '/oauth-service/api/v1';

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private httpNew: HttpClient,
    handler: HttpBackend ) {
    this.httpNew = new HttpClient(handler);
}

  // authenticate user
  userLogin(data): Observable<any> {
    return this.http.post(`${this.base_url}/user-permission/login`, data);
  }

  refreshToken(token): Observable<any> {
    return this.httpNew.post(`${this.base_url}/user-permission/refresh-token/${token}`, {});
  }

  // check if user is logged in
  get isLoggedIn(): boolean {

    let authToken = this.storageService.decrypt(localStorage.getItem('access_token'));
    return (authToken !== null) ? true : false;
  }

  // get access token
  getToken() {
    return this.storageService.decrypt(localStorage.getItem('access_token'));
  }

  // get display name
  getDisplayName() {
    return this.storageService.decrypt(localStorage.getItem('display_name'));
  }

  // get current logged user
  getUserType() {
    return this.storageService.decrypt(localStorage.getItem('userType'));
  }

  doLogout() {

    // clear localstorage
   localStorage.clear();
   window.location.href = '/login';

  }

}
