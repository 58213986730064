import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'src/app/shared/services/storage.service';
import { environment } from 'src/environments/environment';
import jspdf, { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { FormsService } from 'src/app/shared/services/forms.service';
import { HttpResponse } from '@angular/common/http';
import { FileService } from 'src/app/shared/services/file.service';
import { WorkerService } from 'src/app/shared/services/worker.service';


@Component({
  selector: 'edit-worker-training-modal',
  templateUrl: './edit-worker-training-modal.component.html',
  styleUrls: ['./edit-worker-training-modal.component.css']
})

export class EditWorkerTrainingModalComponent implements OnInit {
 
  trainingId: number;
  trainings: any;
  editForm: FormGroup;
 startTrainingDate: any;
 expiryDate: any;
  agencyId: any;
  workerId: any;

  constructor (
    private storageService: StorageService,
    private workerService: WorkerService,
    private toast: ToastrService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private activateRoute: ActivatedRoute,


  ){

  }

  ngOnInit(): void {
      //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
      //Add 'implements OnInit' to the class.
      this.workerId = this.storageService.decrypt(localStorage.getItem('agentId'));
      this.agencyId = this.storageService.decrypt(localStorage.getItem('agentId'));
      this.activateRoute.params.subscribe((params) => {
        this.agencyId = params["id"];
  
        this.getWorkerTrainings();
      });
      

      this.editForm = this.fb.group({
        
        name: String,
        agencyId: String,
        workerId: String,
        trainingId: String,
        traingDate: String,
        trainingExpiry: String,
        status: String,

        });
  }


  getWorkerTrainings(){
      this.workerService.getWorkerTrainings(this.agencyId).subscribe(
        data => {
          this.trainings = data;
          console.log(this.trainings);
          this.loadForm();
        }
      );
    }

  updateTraining(form: FormGroup){
      console.log(form.value);
  
      if(form.valid){
        this.workerService.updateTraining(form.value).subscribe(
          resp => {
            console.table(resp);
  
            this.toast.success('Training Updated Successfully');
            // this.router.navigate(['/client/shifts/list']);
          }
        );
      }
      else{
        this.toast.warning('Missing Required Info');
      }
    }

  loadForm(){
      const myArray = this.trainings.trainingDate.split("/");
      let latest_date =myArray[2] + "-" +myArray[1] +"-"+myArray[0] ;
      this.startTrainingDate=latest_date;

      const myArray1 = this.trainings.trainingExpiry.split("/");
      let expiry_date = myArray1[2] + "-" +myArray1[1] +"-"+myArray1[0] ;
      this.expiryDate=expiry_date;
      console.log(this.expiryDate);

      if(this.trainings){
          this.editForm = this.fb.group({
              id: [this.trainings.id, Validators.required],
              name: [this.trainings.name,Validators.required],
              trainingDate: [this.startTrainingDate, Validators.required],
              trainingExpiry: [this.expiryDate, Validators.required],
              status: [this.trainings.status, Validators.required],

          })
      }
  }
  
  dismissModal(){
    this.modalService.dismissAll();
  }

}
