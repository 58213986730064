import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ShiftService } from 'src/app/shared/services/shift.service';

@Component({
  selector: 'app-types',
  templateUrl: './types.component.html',
  styleUrls: ['./types.component.css']
})
export class TypesComponent implements OnInit {

  types: any;
  selectedType: any;

  addForm: FormGroup;
  editForm: FormGroup;

  page = 1;
  pageSize = 20;
  totalItems = 0;
  showin = 0;
  first: boolean;
  last: boolean;

  constructor(
    private toast: ToastrService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private shiftsService: ShiftService
  ) { }

  ngOnInit(): void {
    this.getTypes();

    this.editForm = this.fb.group({
      id: 0
    });
  }

  formInit(modal){
    this.addForm = this.fb.group({
      name: ['', Validators.required],
      bookingType: ['', Validators.required],
      // endTime: ['', Validators.required],
    });

    this.openModal(modal);
  }

  handlePageChange(event) {

    if(event == 'next'){
      this.page += 1;
    }
    else if(event == 'prev' && this.page != 1){
      this.page -= 1;
    }

    this.getTypes();
  }

  getTypes(){
    this.shiftsService.getPaginatedShiftTypes(this.page - 1, this.pageSize).subscribe(
      data => {
        // console.table(data.content);
        this.totalItems = data.totalElements;
        this.first = data.first;
        this.last = data.last;

        if(this.page * this.pageSize > data.totalElements){
          this.showin = data.totalElements;
        }
        else{
          this.showin = this.page * this.pageSize;
        }

        this.types = data.content;
      }
    );
  }

  loadType(ref, modal, edit){
    if(ref){
      this.types.forEach(r => {
        if(r.id == ref){
          this.selectedType = r;
        }
      });

      if(edit){
        this.editForm = this.fb.group({
          id: [this.selectedType.id, Validators.required],
          name: [this.selectedType.name, Validators.required],
          // startTime: [this.selectedType.startTime, Validators.required],
          // endTime: [this.selectedType.endTime, Validators.required],
        });
      }

      this.openModal(modal);
    }
  }

  saveType(form: FormGroup){
    // console.log(form.value);

    if(form.valid){
      this.shiftsService.createShiftType(form.value).subscribe(
        resp => {
          // console.table(resp);
          this.toast.success('Shift Type Added Successfully');
          this.modalService.dismissAll();
          this.addForm.reset();
          this.getTypes();
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }

  }

  updateType(form: FormGroup){
    // console.log(form.value);

    if(form.valid){
      this.shiftsService.updateShiftType(form.value).subscribe(
        resp => {
          // console.table(resp);
          this.toast.success('Shift Type Updated Successfully');
          this.modalService.dismissAll();
          this.getTypes();
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  deleteType(){
    if(this.selectedType.id){
      this.shiftsService.deleteShiftType(this.selectedType.id).subscribe(
        resp => {
          // console.table(resp);
          this.toast.success('Shift Type Deleted Successfully');
          this.modalService.dismissAll();
          this.getTypes();
        }
      );
    }
    else{
      this.toast.warning('No Shift Type Selected.');
    }
  }

  openModal(modal){
    this.modalService.open(modal, {centered: true});
  }

}
