import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ShiftService } from './../../../../shared/services/shift.service';
import { Component, Input, OnInit } from '@angular/core';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-applied-shifts',
  templateUrl: './applied-shifts.component.html',
  styleUrls: ['./applied-shifts.component.css']
})
export class AppliedShiftsComponent implements OnInit {
  @Input('agencyFilter') agencyFilter = '';
  @Input('workerFilter') workerFilter = '';
  @Input('clientFilter') clientFilter = '';
  @Input('startDateFilter') startDateFilter = '';

  @Input('endDateFilter') endDateFilter = '';
  @Input('directorateFilter') directorateFilter = '';

  myData: any;
  shifts: any= [];
  shiftInfo: any;
  selectedShift: number;
  applicants: any;

  userType: string;

  page = 1;
  pageSize = 1000;
  totalItems = 0;
  showin = 0;
  first: boolean;
  last: boolean;

  constructor(
    private shiftsService: ShiftService,
    private toast: ToastrService,
    private router: Router,
    private authService: AuthService,
    private modalService: NgbModal,
    private storageService: StorageService
  ) {
    this.userType = authService.getUserType();
   }

  ngOnInit(): void {
    this.getShifts();
  }

  handlePageChange(event) {

    if(event == 'next'){
      this.page += 1;
    }
    else if(event == 'prev' && this.page != 1){
      this.page -= 1;
    }

    this.getShifts();
  }

  getShifts(){
    this.shiftsService.getPaginatedShiftsByStatus(this.page - 1, this.pageSize, 'NEW', this.agencyFilter, this.clientFilter, this.workerFilter, this.startDateFilter, this.endDateFilter).subscribe(
      data => {
        // console.table(data);
        this.myData = data.content.filter(
          r => r.appliedStatus == 'APPLIED' &&  r.applicantCount != 0
        );

        // console.log(myData);


        this.totalItems = this.myData?.length;
        this.first = data.first;
        this.last = data.last;

        if(this.page * this.pageSize > this.myData?.length){
          this.showin = this.myData?.length;
        }
        else{
          this.showin = this.page * this.pageSize;
        }

        this.shifts = this.myData;
      }
    );
  }

  viewDetails(ref, modal){
    if(ref){
      this.myData.forEach(r => {
        if(r.id == ref){
          this.shiftInfo = r;
        }
      });

      this.openBigModal(modal);
    }

    // console.table(this.shiftInfo);
  }

  editDetails(ref){
    this.router.navigate([`/${this.userType}/shifts/edit/${ref}`]);
  }

  loadAgency(ref, modal){
    if(ref){
      this.selectedShift = ref;

      this.openModal(modal);
    }
  }

  deleteShift(){

    if(this.selectedShift){
      this.shiftsService.deleteShift(this.selectedShift).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          this.getShifts();
          this.toast.success('Shift Deleted Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }
  }

  openModal(modal){
    this.modalService.open(modal, {centered: true});
  }

  openBigModal(modal){
    this.getApplicants();

    this.modalService.open(modal, {centered: true, size: 'xl'});
  }

  getApplicants(){
    if(this.storageService.decrypt(localStorage.getItem('agentId'))){

      this.shiftsService.getAgencyShiftApplicants(this.shiftInfo.id, this.storageService.decrypt(localStorage.getItem('agentId'))).subscribe(
        data => {
          // console.log(data);

          this.applicants = data;
        },
        err => {

          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }

        }
      );
    }else{
      this.shiftsService.getShiftApplicants(this.shiftInfo.id).subscribe(
        data => {
          // console.log(data);

          this.applicants = data;
        },
        err => {

          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }

        }
      );
    }
  }

  viewApplied(ref){
    this.router.navigate([`/${this.storageService.decrypt(localStorage.getItem('userType'))}/shifts/applied/${ref}`]);
  }

  approveWorker(ref){
    console.log(ref);

  }

}
