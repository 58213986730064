<form [formGroup]="form" (ngSubmit)="updateVehicle()">
  <div class="d-flex">
    <h5>Vehicle Details</h5>
    <button type="button" class="btn-primary btn ml-auto" (click)="enableEdit()">Edit</button>
  </div>

  <div class="card p-2 mt-2 bg-grey">
    <div class="row">
      <div class="form-group col-6 col-sm-3 col-md-2">
        <label>Name</label>
        <input type="text" formControlName="name" maxlength="255" placeholder="" class="form-control">
      </div>
      <div class="form-group col-6 col-sm-3 col-md-2">
        <label>Model</label>
        <input type="text" formControlName="model" maxlength="255" placeholder="" class="form-control">
      </div>
      <div class="form-group col-6 col-sm-3 col-md-2">
        <label>Colour</label>
        <input type="text" formControlName="color" maxlength="255" placeholder="" class="form-control">
      </div>
      <div class="form-group col-6 col-sm-3 col-md-3">
        <label>Reg Number</label>
        <input type="text" formControlName="regno" maxlength="255" placeholder="" class="form-control">
      </div>
      <div class="form-group col-6 col-sm-3 col-md-3">
        <label>Vehicle Type</label>
        <input type="text" maxlength="255" formControlName="carType" placeholder="" class="form-control">
      </div>
    </div>
  </div>

  <h5>Other</h5>
  <div class="card p-2 bg-grey">
    <div class="row">
      <div class="form-group col-6 col-sm-3 col-md-4">
        <label>Engine Number</label>
        <input type="text" maxlength="255" formControlName="engineNumber" placeholder="" class="form-control">
      </div>
      <div class="form-group col-6 col-sm-3 col-md-4">
        <label>Engine Capacity</label>
        <input type="text" maxlength="255" formControlName="engineSize" placeholder="" class="form-control">
      </div>
      <div class="form-group col-6 col-sm-3 col-md-4">
        <label>Tracker Id</label>
        <input type="text" maxlength="255" formControlName="trackerId" placeholder="" class="form-control">
      </div>
    </div>
  </div>

  <h5>Vehicle base details</h5>
  <div class="card p-2 bg-grey">
    <div class="row">
      <div class="form-group col-6 col-sm-3 col-md-4">
        <label>Contact person</label>
        <input type="text" formControlName="contactPerson" maxlength="255" placeholder="" class="form-control">
      </div>
      <div class="form-group col-6 col-sm-3 col-md-4">
        <label>Phone</label>
        <input type="text" formControlName="contactPhone" maxlength="255" placeholder="" class="form-control">
      </div>
      <div class="form-group col-6 col-sm-3 col-md-4">
        <label>Address</label>
        <input type="text" formControlName="contactAddress" maxlength="255" placeholder="" class="form-control">
      </div>
    </div>
  </div>

  <div class="mt-1 btn-group">
    <button *ngIf="form.enabled" type="button" (click)="disableForm()" class="btn-danger mr-1 btn">Cancel</button>
    <button *ngIf="form.enabled" type="submit" class="btn-primary btn">Submit</button>
  </div>
  

  <div class="d-flex mt-2">
    <div class="col-12 mt-2 text-right">
      <button class="small-length blue-btn" onclick="history.back()">Back</button>
      <button type="submit" class="btn btn-primary" (click)="updateVehicle(); goNext();">Next</button>
    </div>
  </div>
  
    
</form>
