<div class="">
  <div class="modal-header bg-main text-white">
      <h5 class="modal-title text-center w-100">Delete Transport</h5>
  </div>
  <div class="modal-body">
      <div class="bg-modal card-body">
          <div class="text-center">
              <h3>You are about to delete this transport job?</h3>
              Job Ref: {{selectedJob.id}}
          </div>
      </div>
      <div class="mt-3">
        <button class="btn btn-sm btn-danger mr-3" (click)="deleteInvoice()">
          Delete
        </button>
        <button class="btn btn-success btn-sm mr-3" (click)="closeModal()">
            Dismiss
        </button>
      </div>
  </div>
</div>
