<table class="table table-striped mb-0 styled-table table-responsive-md text-center">
  <thead class="">
      <tr class="text-center">
          <th>Shift Id</th>
          <th>Shift Date</th>
          <th>Time</th>
          <th>Assignment<br>Code</th>
          <th *ngIf="userType == 'admin'">Directorate</th>
          <th *ngIf="userType != 'client'">Client Name</th>
          <th *ngIf="userType == 'client'">Directorate</th>
          <th>Actions</th>
      </tr>
  </thead>
  <tbody>
      <tr *ngFor="let s of shifts | shiftsFilter: agencyFilter :clientFilter :startDateFilter :endDateFilter :directorateFilter;">
          <td>{{s.id}}</td>
          <td>{{s.shiftDate}}</td>
          <td>{{s.shiftStartTime}} - {{s.shiftEndTime}}</td>
          <td>{{s.assignmentCode}}</td>
          <th *ngIf="userType == 'admin'">{{s.directorate}}</th>
          <td *ngIf="userType == 'client'">{{s.directorate}}</td>
          <td *ngIf="userType != 'client'">{{s.client}}</td>
          <td>
              <span class="mr-3 text-primary-custom pointer" (click)="viewApplied(s.id)">
                  <i class="fa fa-eye" title="view"></i>
              </span>
              <!-- <span class="mr-3 text-success pointer" *ngIf="userType == 'client'" (click)="editDetails(s.id)">
                <i class="fa fa-pencil-alt" title="edit"></i>
              </span> -->
              <span class="mr-3 text-danger-custom pointer" *ngIf="userType == 'client'&&'UPDATE_SHIFT'|permission" (click)="loadAgency(s.id, delNew)">
                <i class="fa fa-trash-alt" title="delete"></i>
            </span>
          </td>
      </tr>
  </tbody>
</table>
<section class="pagination-container" *ngIf="shifts">
  <div class="container-fluid">
    <div class="row m-0">
      <div class="col-12 col-sm-12 col-md-5 text-left acontent-center">
        Showing {{ (page - 1) * pageSize + 1 }} - {{ showin }} of {{ totalItems }}
      </div>
      <div class="col-12 col-sm-6 col-md-4 text-right">
        <button class="btn text-white" (click)="handlePageChange('prev')" [disabled]="first">
          <i class="fa fa-caret-up"></i>&nbsp;&nbsp;Previous Page
        </button>
      </div>
      <div class="col-12 col-sm-6 col-md-3 text-right pr-0">
        <button class="btn text-white" (click)="handlePageChange('next')" [disabled]="last">
          Next Page&nbsp;&nbsp;<i class="fa fa-caret-down"></i>
        </button>
      </div>
    </div>
  </div>
</section>

<!-- Modals -->
<ng-template class="modal fade" #delNew let-modal>
<div class="">
    <div class="modal-header bg-danger text-white">
        <h5 class="modal-title text-center w-100">Delete Applied Shift</h5>
        <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </span>
    </div>
    <div class="modal-body">
        <div class="bg-modal card-body">
            <div class="text-center">
                <h3>You are about to delete this Applied Shift?</h3>
            </div>
        </div>
        <div align="right" class="mt-3">
          <button class="btn btn-info btn-sm mr-3" (click)="modal.dismiss()">
              Cancel
          </button>
          <button class="btn btn-sm btn-danger" (click)="deleteShift()">
              Delete Shift
          </button>
        </div>
    </div>
</div>
</ng-template>

<ng-template class="modal fade" #infoNew let-modal>
<div class="">
    <div class="modal-header bg-main text-white">
        <h5 class="modal-title text-center w-100">View Applied Shift</h5>
        <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </span>
    </div>
    <div class="modal-body" *ngIf="shiftInfo">
        <div class="bg-modal card-body">
            <div class="container">
              <div class="row">
                <div class="col-md-2 border-right">{{shiftInfo.shiftDate}} to {{shiftInfo.shiftEndDate}}  <br> {{shiftInfo.shiftLocation}}</div>
                <div class="col-md-2 border-right container">
                  <div class="row">
                    <div class="col-md-6 border-right">Starts <br> <b>{{shiftInfo.shiftStartTime}}</b> </div>
                    <div class="col-md-6">Ends <br> <b>{{shiftInfo.shiftEndTime}}</b></div>
                  </div>
                </div>
                <div class="col-md-3 border-right">
                  Assignment Code : <b>{{shiftInfo.assignmentCode}}</b> <br>
                  Gender <b class="ml-5">{{shiftInfo.gender}}</b>
                </div>
                <div class="col-md-5">
                  {{shiftInfo.notes}}
                </div>
              </div>
            </div>
        </div>
        <div class="container-fluid card-body mt-3">
          <table class="table table-striped mb-0 styled-table table-responsive-md text-center">
            <thead>
                <tr class="text-center">
                    <th>Name</th>
                    <th>Jobs</th>
                    <th>Assignment Code</th>
                    <th>Agency Name</th>
                    <th *ngIf="userType == 'client'">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let l of applicants;">
                    <td>{{l.firstname}} {{l.lastname}}</td>
                    <td>{{l.assignmentName}}</td>
                    <td>{{l.assignmentCode}}</td>
                    <td>{{l.agencyName}}</td>
                    <td *ngIf="userType == 'client'">
                      <span class="mr-3 text-success pointer" (click)="approveWorker(l.id)">
                          <i class="fa fa-check-double"></i>
                      </span>
                    </td>
                </tr>
            </tbody>
          </table>
          <!-- <section class="pagination-container" *ngIf="locations">
            <div class="container-fluid">
              <div class="row m-0">
                <div class="col-12 col-sm-12 col-md-5 text-left acontent-center">
                  Showing {{ (page - 1) * pageSize + 1 }} - {{ showin }} of {{ totalItems }}
                </div>
                <div class="col-12 col-sm-6 col-md-3 text-right pr-0">
                  <button class="btn text-white" (click)="handlePageChange('prev')" [disabled]="first">
                    <i class="fa fa-caret-up"></i>&nbsp;&nbsp;Previous Page
                  </button>
                </div>
                <div class="col-12 col-sm-6 col-md-4 text-right">
                  <button class="btn text-white" (click)="handlePageChange('next')" [disabled]="last">
                    Next Page&nbsp;&nbsp;<i class="fa fa-caret-down"></i>
                  </button>
                </div>
              </div>
            </div>
          </section>   -->
        </div>
    </div>
</div>
</ng-template>
