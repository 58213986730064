<main class="mt-5">
    <!-- Header part -->
  <div class="container-fluid">
    <div class="row aitems-center">
      <div class="col-12">
        <h3 class="mb-0">Compliance Checks</h3>
      </div>
    </div>
  </div>

  <!-- Content -->
  <section class="content mt-4">
      <div class="container-fluid">
        <table class="table table-striped mb-0 styled-table text-left">
          <thead>
              <tr class="text-left">
                  <th>Shift Id</th>
                  <th>Worker</th>
                  <th>Summary</th>
                  <th>Shift Date</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let d of complianceIssues;">
                  <td ><a routerLink="/{{userType}}/shifts/edit/{{d.shiftId}}">{{d.shiftId}}</a></td>
                  <td style="cursor: pointer;" ><a href=""  routerLink="/{{userType}}/workers/info/{{d.workerId}}">{{d.firstname}} {{d.lastname}}</a></td>
                  <td>Expired compliance documents</td>
                  <td>{{d.shiftDate[2] +"/"+ d.shiftDate[1] +"/"+ d.shiftDate[0]}}</td>
              </tr>
          </tbody>
        </table>
        <section class="pagination-container" *ngIf="complianceIssues">
          <div class="container-fluid">
            <div class="row m-0">
              <div class="col-12 col-sm-12 col-md-5 text-left acontent-center">
                Showing {{ (page - 1) * pageSize + 1 }} - {{ showin }} of {{ totalItems }}
              </div>
              <div class="col-12 col-sm-6 col-md-4 text-right">
                <button class="btn text-white" (click)="handlePageChange('prev')" [disabled]="first">
                  <i class="fa fa-caret-up"></i>&nbsp;&nbsp;Previous Page
                </button>
              </div>
              <div class="col-12 col-sm-6 col-md-3 text-right pr-0">
                <button class="btn text-white" (click)="handlePageChange('next')" [disabled]="last">
                  Next Page&nbsp;&nbsp;<i class="fa fa-caret-down"></i>
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
  </section>
</main>



