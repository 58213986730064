<div class="">
  <div class="modal-body">
    <div class="bg-modal card-body">
      <div class="col-6" style="align-self: center;">
        <h5>Assigned Vehicle</h5>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-6" style="align-self: center;">
            <h3>{{job?.vehicle?.name}}</h3>
            <h5>Job Ref: {{job?.refPrefix}}</h5>
          </div>
          <div class="col-6 card bg-grey">
            <b>{{job?.pickupPostCode}} > {{job?.destinationPostCode}}</b>
            {{job?.dateTimeRequired | date:'EEEE, MMMM d, y'}}
            <br>{{job?.dateTimeRequired | date:'HH:mm'}}
          </div>
        </div>
        <div class="row my-4 text-center" style="align-items: end;">
          <div class="col-3">
            <i *ngIf="tabindex == 1" class="fa fa-map-pin text-primary-custom"></i><br>
            <button [className]="tabindex >= 1 ? 'btn-primary w-100 rounded-tab' : 'rounded-tab w-100'" (click)="tabindex = 1">Driver's log</button>
          </div>
          <div class="col-3">
            <i *ngIf="tabindex == 2" class="fa fa-map-pin text-primary-custom"></i><br>
            <button [className]="tabindex >= 2 ? 'btn-primary w-100 rounded-tab' : 'rounded-tab w-100'" (click)="tabindex = 2">Vehicle Check</button>
          </div>
          <!-- <div class="col-3">
            <i *ngIf="tabindex == 3" class="fa fa-map-pin text-primary-custom"></i><br>
            <button [className]="tabindex >= 3 ? 'btn-primary w-100 rounded-tab' : 'rounded-tab w-100'" (click)="tabindex = 3">Cleaning</button>
          </div> -->
          <div class="col-3">
            <i *ngIf="tabindex == 3" class="fa fa-map-pin text-primary-custom"></i><br>
            <button [className]="tabindex >= 3 ? 'btn-success w-100 rounded-tab' : 'rounded-tab w-100'" (click)="tabindex = 3">Complete</button>
          </div>
        </div>
        <div class="mt-2">
          <app-log-transport-tab1
            *ngIf="tabindex == 1"
            [form]="logForm"
            [job]="job"
            [tabindex]="tabindex"
            (tabindexO)="updateTab($event)"
            (formO)="updateForm($event)"
          ></app-log-transport-tab1>

          <app-log-transport-tab2
            *ngIf="tabindex == 2"
            [form]="logForm"
            [job]="job"
            [tabindex]="tabindex"
            (tabindexO)="updateTab($event)"
            (formO)="updateForm($event)"
          ></app-log-transport-tab2>

          <app-log-transport-tab3
            *ngIf="tabindex == 3"
            [form]="logForm"
            [job]="job"
            [tabindex]="tabindex"
            (tabindexO)="updateTab($event)"
            (formO)="updateForm($event)"
          ></app-log-transport-tab3>

          <app-log-transport-tab4
            *ngIf="tabindex == 4"
            [form]="logForm"
            [job]="job"
            [tabindex]="tabindex"
            (tabindexO)="updateTab($event)"
            (formO)="updateForm($event)"
          ></app-log-transport-tab4>
        </div>
        <br>
      </div>
    </div>
  </div>
</div>
