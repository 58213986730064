<!--Login Page Starts-->
<section class="vertical-center w-100 auth-box">
    <div class="container-fluid">
      <div class="row full-height-vh m-0">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="card">
            <div class="card-content">
              <div class="card-body p-0 m-0 border-0 bg-custom-beige">
                <div class="row m-0 p-0">
                  <div
                    class="col-lg-7 p-0 m-0 d-lg-block d-none text-center align-middle"
                  >
                    <!-- <img
                      src="../../assets/images/login.jpg"
                      alt=""
                      class="img-fluid"
                      width="400"
                      height="230"
                    /> -->
                    <img
                      src="../../assets/images/login.png"
                      alt=""
                      class="img-fluid"
                      width="790"
                      height="730"
                    />
                  </div>
                  <div class="col-lg-5 col-md-12 bg-white px-4 pt-3">
                    <div>
                      <img
                      class="w-50 mt-4 pt-3"
                      src="/assets/images/svg/new-logo.svg"
                      alt="logo"
                    />
                    </div>
                    <h1 class="mt-3 mb-2">Sign In</h1>
                    <p class="card-text mb-2">
                      Welcome back, please sign in to your account.
                    </p>
                    <em class="text-danger-custom">{{msg}}</em>
                    <form class="form" [formGroup]="loginForm" (ngSubmit)="loginUser(loginForm, OTPmodal)">
                      <input
                        type="text"
                        class="form-control mb-4"
                        placeholder="Email" formControlName="username"
                      />
                      <input
                        type="password"
                        class="form-control mb-4"
                        placeholder="Password" formControlName="password"
                      />
                      <div class="d-flex justify-content-between mt-2 mb-3">
                        <div class="remember-me">
                          <div
                            class="custom-control custom-checkbox custom-control-inline mb-3"
                          >
                            <input
                              type="checkbox"
                              id="customCheckboxInline1"
                              name="customCheckboxInline1"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="customCheckboxInline1"
                            >
                              &nbsp;&nbsp;Remember Me
                            </label>
                          </div>
                        </div>
                        <div class="forgot-password-option">
                          <button type="button"
                            (click)="resetPage()"
                            class="text-decoration-none bg-white border-0 text-primary-custom"
                            >Forgot Password?</button>
                        </div>
                      </div>
                      <div class="m-3">
                        <p>
                          By signing in you accept our <a href="https://myworklink.uk/?page_id=13570">terms and conditions</a> and <a href="https://myworklink.uk/?page_id=3">privacy policy</a>.
                        </p>
                      </div>
                      <div class="fg-actions d-flex justify-content-between">
                        <button type="submit" class="btn btn-block btn-success btn-info bg-core-lime">Sign In</button>
                      </div>
                      <!-- <hr class="mt-3 mb-3" /> -->
                      <div class="d-flex justify-content-between mt-3">
                        <!-- <div class="option-login">
                          <h6 class="text-decoration-none text-info-custom">
                            Or Login With
                          </h6>
                        </div>
                        <div class="social-login-options">
                          <a class="btn btn-social-icon mr-2 btn-facebook">
                            <span class="bi bi-facebook text-primary-custom"></span>
                          </a>
                          <a class="btn btn-social-icon mr-2 btn-facebook">
                            <span class="bi bi-twitter text-primary-custom"></span>
                          </a>
                        </div> -->
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Login Page Ends-->

    <!-- Modals -->
    <ng-template class="modal fade" #OTPmodal let-modal>
      <div class="">
          <div class="modal-header bg-main text-white">
              <h5 class="modal-title text-center w-100">OTP</h5>
              <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
                <span aria-hidden="true">&times;</span>
              </span>
          </div>
          <div class="modal-body">
              <div class="bg-modal card-body">
                  <div class="">
                      <div class="bg-modal card-body">
                        <div class="container-fluid">
                          <div class="row">
                            <div class="col-12">
                              <i class="bi bi-info-circle"></i>
                              <span class="ml-2">A one time password (OTP) was sent to your email address.</span>
                              <hr class="bg-black mt-0">
                            </div>
                            <div class="col-12">
                              <div class="form-group">
                                <label for="">Enter Received OTP</label>
                                <input type="text" class="form-control" [(ngModel)]="otp">
                              </div>
                            </div>

                          </div>
                          <div align="right" class="mt-3">
                            <span type="button" class=" mr-3 text-primary-custom" (click)="modal.dismiss()">
                                RESEND OTP
                            </span>
                            <button type="button" class="btn btn-sm btn-success" (click)="loginUser(loginForm, OTPmodal)">
                                CONFIRM
                            </button>
                          </div>
                        </div>
                      </div>

                  </div>
              </div>
          </div>
      </div>
    </ng-template>
