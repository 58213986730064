<main class="mt-5">

    <!-- Header part -->
    <div class="container-fluid">
      <div class="co-sm-12 col-md-6 col-lg-4 mt-lg-5 mt-md-0 w-100 text-center pl-0">
          <div class=" text-center">
              <h2>Quick links</h2>
          </div>
      </div>
        <div class="row text-white">
          <div class="co-sm-12 col-md-6   col-lg-4 mt-3 mt-md-0 w-100 text-center">
            <a routerLink="/client/shifts/list/">
              <div class=" border rounded-1 bg-link-card text-center text-white">
                  <h4 class="font-weight-bold">Add Shifts</h4>
                  <p >Add single and multiple shifts .</p>
              </div>
            </a>
          </div>
            <div class="co-sm-12 col-md-6  col-lg-4 mt-3 mt-md-0 w-100 text-center">
                <a routerLink="/client/workers/list">
                  <div class ="border rounded-1 bg-link-card text-center text-white">
                    <h4 class="font-weight-bold">View Flexi Workers</h4>
                    <p >View available workers.</p>
                  </div>
                </a>
            </div>
        </div>
    </div>

    <!-- Content -->

</main>
