import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { TransportService } from 'src/app/shared/services/transport.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShiftService } from 'src/app/shared/services/shift.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ClientService } from 'src/app/shared/services/client.service';
import { AssignmentCodeService } from 'src/app/shared/services/assignment-code.service';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { StatisticsService } from 'src/app/shared/services/statistics.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-log-transport-job',
  templateUrl: './log-transport-job.component.html',
  styleUrls: ['./log-transport-job.component.css']
})
export class LogTransportJobComponent implements OnInit {
  selectedJob : any;
  extremeRag: Array<String> = ['S','H','M'];
  agencyFilter  : string= "";
  requestSent: boolean = false;
  clientFilter  : string= "";
  startDateFilter: string= "";
  endDateFilter: string= "";
  directorateFilter: string= "";
  worker: any;
  countries: { name: string; code: string; }[];
  rde2 =false;
  restriction = false;
  eHistory= [{}];
  nHistory:[FormGroup];
  education=[{}];
  directorates :any;
  addForm: FormGroup;
  selectedTab: string = 'new';
  edit: any;
  locations: any;
  clientId: any;
  agencies: any;
  assCodes: any;
  selectedPDirectorate: any;
  pickupLocationContactNumber: any;
  pPostCode: any;
  shiftsSummary: any;
  userType: any;
  clients: any;
  agencyId: any;
  meds = false;
  pmeds:boolean = false;
  rapid = false;
  covid = false;
  phealth = false;
  infection = false;
  diet = false;
  allergies = false;
  walk=true;
  wardEscort: boolean;
  jobId: any;
  job: any;

  constructor(
    private router: Router,
    private agencyService: AgencyService,
    private transportService: TransportService,
    private toast : ToastrService,
    private statisticsService : StatisticsService,
    private shiftsService: ShiftService,
    private storageService: StorageService,
    private assignmentCodeService: AssignmentCodeService,
    private fb: FormBuilder,
    private authService: AuthService,
    private clientService: ClientService,
    private activateRoute: ActivatedRoute,
    private modalService: NgbModal
  ) {
    this.userType = authService.getUserType();
    this.clientId =this.storageService.decrypt(localStorage.getItem('clientId'));
    this.agencyId =this.storageService.decrypt(localStorage.getItem('agentId'));
   }


  ngOnInit(): void {
      this.activateRoute.params.subscribe((params) => {
        this.jobId = params["id"];
        this.getJob();
      });


    this.initForm();
  }



  initForm(){
    this.addForm = this.fb.group({
      transportLegibleAgencyIds : [(this.userType=='agency'||this.userType==undefined)?[this.agencyId]:[], Validators.required],
      pickupDirectorateId : [],
      id : [],
      version: [],
      agencyId : [(this.userType=='agency')?this.agencyId:""],
      clientId : [this.userType=='client'?this.clientId:""],
      dateTimeRequired : ["", Validators.required],
      destination : ["", Validators.required],
      destinationContactNumber : [""],
      destinationPostCode : ["", Validators.required],
      rapidStatus : ["", Validators.required],
      escortServiceRisk : [],
      passengerAdditionalRisks : [],
      wardEscort   : [""],
      areStaffEscortingPatient   : [""],
      // patientMedication   : ["",Validators.required],
      pmeds   : [""],
      disclaimer   : ["", Validators.required],
      isPassengerAwareOfTransport : ['', Validators.required],
      passengerGender : ["", Validators.required],
      canOfferFood : [""],
      physicalAggression : ["U"],
      verballyAggressive : ["U"],
      assaultStaffDesc : [""],
      selfHarmDesc : [""],
      physicalAggressionDesc : [""],
      verballyAggressiveDesc : [""],
      absconsionRiskDesc : [""],
      genderIssuesDesc : [""],
      racialIssuesDesc : [""],
      sexuallyInappropriateDesc : [""],
      mentalHealthStatus : ["", Validators.required],
      specialRequests : [],
      patientName : ["", Validators.required],
      sexuallyInappropriate : ["U"],
      selfHarm : ["U"],
      reasonsForRestrains : [""],
      reasonForTransport : ["",Validators.required],
      passengerRequiresRestraints : ["", Validators.required],
      pickupLocationContactNumber : [""],
      pickupPostCode : ["", Validators.required],
      assaultStaff : ["U"],
      absconsionRisk: ["U"],
      otherRisks: [""],
      otherMobilityIssues: [""],
      mobilityIssues: [""],
      transportWorkerSpecList : this.fb.array([
        this.fb.group({
          assignmentCode : ["37", Validators.required],
          gender : ["NO_PREFERENCE"],
          numberOfStaff : ["",Validators.required],
       }),
        this.fb.group({
          assignmentCode : ["38", Validators.required],
          gender : ["NO_PREFERENCE"],
          numberOfStaff : ["",Validators.required],
       }),
        this.fb.group({
          assignmentCode : ["39", Validators.required],
          gender : ["NO_PREFERENCE"],
          numberOfStaff : ["",Validators.required],
       }),
     ]),
    mha:[],
    pcaddress:["",Validators.required],
    pcemail:["",[Validators.required, Validators.email]],
    pcbusiness:[""],
    pward:[""],
    pname:["",Validators.required],
    pdob:["",Validators.required],
    walk:["",Validators.required],
    walkInfo:[""],
    nhs:[""],
    diagnosis:[""],
    dname:[""],
    dbusiness:[""],
    dward:[""],
    dcontact:[""],
    demail:["",[ Validators.email]],
    bpostCode:[""],
    genderIssues:["U"],
    racialIssues:["U"],
    medication:[""],
    physicalHealth:[""],
    rapidTranq:[""],
    infectionControl:[""],
    covid:[""],
    offerFood:[""],
    allergies:[""],
    submittedBy:[""],
    semail:["",[Validators.required, Validators.email]],
    sphone:[""],
    porderNum:[""],
    sbsCode:[""],
    bname:[""],
    baddress:[""],
    binvoice:[""],
    bphone:[""],
    bemail:["", Validators.email],
    authority:[""],
    });

    this.formFromJson();
  }


  formFromJson(){
    if (this.selectedJob != null) {
      this.addForm.patchValue(this.selectedJob);
      this.addForm.patchValue(
         { disclaimer: true,
          transportWorkerSpecList: this.escortListFromJson()
          }
      );
   }
  }

  escortListFromJson(){
    console.log(this.selectedJob);
    this.escortStaffArray.clear();
     this.selectedJob.transportWorkerSpecList?.forEach(e=>{
      let escortItem = this.fb.group({
        assignmentCode : ["", Validators.required],
        gender : ["NO_PREFERENCE"],
        numberOfStaff : ["",Validators.required],
      });
      escortItem.patchValue(e)
      this.escortStaffArray.push(escortItem);
    });
  }


  clientSelected(client){
    client = this.clients.filter(v=> v.id==client.target.value)[0];
    this.addForm.patchValue({
      bname: client.name,
      baddress: client.firstLine+', '+ client.town,
      bphone: client.telephone,
      bpostCode: client.postCode,
      bemail: client.billingEmail
    })
  }


  getJob(){
    this.transportService.findById(this.jobId).subscribe(
      data => {this.job = data}
    );
  }

  get escortStaffArray() {
    return <FormArray>this.addForm.get('transportWorkerSpecList');
  }


  displayPickupDirectorateInfo(){
    let d = this.directorates.filter(v => v.id.toString() == this.addForm.value.pickupDirectorateId);

    console.log(d);
    console.log(this.directorates, this.addForm.value.pickupDirectorateId);

    this.pickupLocationContactNumber = d[0]?.phoneNumber??"";
    this.pPostCode = d[0]?.postCode?? "";

    this.addForm.patchValue({
      pickupLocationContactNumber: d[0]?.phoneNumber,
      pPostCode: d[0]?.postCode
    });
    console.log(this.addForm.value)
  }

  getCodes(){
    if(this.userType==undefined){
      this.assignmentCodeService.getGuestPaginatedAssignmentCodes(this.agencyId).subscribe(
        data => {this.assCodes = data;}  );
    } else{
          this.assignmentCodeService.getPaginatedAssignmentCodes(0, 100).subscribe(
            data => {this.assCodes = data;}
    );
    }

  }


  toggleFalse(v){
    switch (v) {
      case 'meds':
          this.meds=false;
          break;
      case 'pmeds':
          this.pmeds=false;
          break;
      case 'wardEscort':
          this.wardEscort=false;
          break;
      case 'rapid':
          this.rapid=false
          break;
      case 'covid':
          this.covid=false;
          break;
      case 'phealth':
          this.phealth=false
          break;
      case 'infection':
          this.infection=false;
      case 'diet':
          this.diet=false
          break;
      case 'allergies':
          this.allergies=false
          break;
      case 'walk':
          this.walk=false
          break;
    }
  }


  toggleTrue(v){
    switch (v) {
      case 'meds':
          this.meds=true;
          break;
      case 'meds':
          this.pmeds=true;
          break;
      case 'pmeds':
          this.pmeds=true;
          break;
      case 'wardEscort':
          this.wardEscort=true;
          break;
      case 'rapid':
          this.rapid=true
          break;
      case 'covid':
          this.covid=true;
          break;
      case 'phealth':
          this.phealth=true;
          break;
      case 'infection':
          this.infection=true;
      case 'diet':
          this.diet=true;
          break;
      case 'allergies':
          this.allergies=true;
          break;
      case 'walk':
          this.walk=true;
          break;
    }
  }

  getAgencies(){
    this.agencyService.getPaginatedAgencies(0, 100, 'TRANSPORTER').subscribe(
      data => {
        // console.table(data.content);

        let array = data.content;
        array.sort(function(a, b){
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })

        this.agencies = array;
      }
    );
  }


  closeModals(){
    this.modalService.dismissAll();
  }


  selectTab(ref) {
    if(ref){
      this.selectedTab = ref;
    }
  }

  newPage(){
    this.router.navigate(['/client/new']);
  }


  getLocations(){
    if(this.userType=="Client")this.shiftsService.getPaginatedClientDirectorates(0, 1000, this.clientId).subscribe(
      data => {
        this.directorates = data.content;
        console.log(this.locations);
      }
    );
  }

  public findInvalidControls(form) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(this.splitStringOnCapitalLetters(name));
        }
    }
    return invalid;
}

splitStringOnCapitalLetters(input: string): string {
  // Match chunks with uppercase letters followed by zero or more non-uppercase letters
  const regex = /[A-Z]+[^A-Z]*|[^A-Z]+/g;
  let stringArr = input.match(regex) || [];
  let str = "";
  stringArr.forEach(s=>{
    str +=s.toLowerCase();
    str +=" ";
  })

  return str;
}


  createJob(form){
    console.log(form.value);
    this.markAllControlsAsTouched(form);
    if(form.valid){
      if(this.addForm.value.disclaimer!=true)
        return this.toast.warning("Confirm authority to submit form at the end of filling the form.")
      if(this.selectedJob==null)
        this.transportService.createTransport(form.value).subscribe(
          data => {
          this.toast.success("Job created");
            this.addForm.reset();
            let last = this.selectedTab.toString();
            this.selectedTab = "none";
            this.ngOnInit();
            this.selectedTab = last;

            if(this.userType==undefined){
              this.requestSent=true;
            }else{
              this.modalService.dismissAll();
            }
          }
        );
      else
      this.transportService.updateTransport(form.value).subscribe(
        data => {
        this.toast.success("Job updated");
          this.addForm.reset();
          let last = this.selectedTab.toString();
          this.selectedTab = "none";
          this.ngOnInit();
          this.selectedTab = last;

          if(this.userType==undefined){
            this.requestSent=true;
          }else{
            this.modalService.dismissAll();
          }
        }
      );
    }else{
      console.log(form.value);
      this.toast.warning("Missing field(s) detected. Please add: "+this.findInvalidControls(form));
    }
  }

  clearFilters(){
    this.agencyFilter     = "";
    this.clientFilter     = "";
    this.startDateFilter   = "";
    this.endDateFilter   = "";
    this.directorateFilter   = "";
  }
  formInit(modal){


    this.openModal(modal);
  }

  openModal(modal: any){
    this.modalService.open(modal);
  }


  track(item: any, index: number) {
    return index;
  }

  toggleEdit(){
      this.edit = !this.edit;
  }



    removeEscort(i: number) {
      this.escortStaffArray.removeAt(i);
    }

    addEscort(){

      this.escortStaffArray.push(
        this.fb.group({
          assignmentCode : ["", Validators.required],
          gender : ["NO_PREFERENCE"],
          numberOfStaff : ["",Validators.required],
       }),
      );



  }



  private markAllControlsAsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markAllControlsAsTouched(control);
      }
    });
  }

}
