import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GuestRoutingModule } from './guest-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApplyComponent } from './apply/apply.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { WorkersRoutingModule } from '../admin/workers/workers-routing.module';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TransportRequestFormComponent } from './transport-request-form/transport-request-form.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatSliderModule } from '@angular/material/slider';
import { AgencyJobsModule } from '../agency/agency-jobs/jobs.module';


@NgModule({
  declarations: [ApplyComponent, TransportRequestFormComponent],
  imports: [
    CommonModule,
    AgencyJobsModule,
    GuestRoutingModule,
    ReactiveFormsModule,
    CommonModule,
    WorkersRoutingModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatButtonModule,
    MatDatepickerModule,
    FormsModule,
    NgSelectModule,
    MatSliderModule

  ],  providers: [
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'fill'}}
  ]
})
export class GuestModule { }
