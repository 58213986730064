import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'src/app/shared/services/storage.service';
import { WorkerService } from 'src/app/shared/services/worker.service';
import { ServicesService } from 'src/app/shared/services/services.service';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { HttpResponse } from '@angular/common/http';
import { FileService } from 'src/app/shared/services/file.service';
import { environment } from 'src/environments/environment';
import { FormsService } from 'src/app/shared/services/forms.service';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.css']
})

export class FormsComponent implements OnInit {

  public userType: string;
  workerId: number;
  agencyId: number;
  worker: any;
  workerInfo: any;
  // workerProperty: any;
  jobCounts: any;
  forms:any;
  formAll:any;
  jobs: any;
  fitToWork:any;
  // complianceInformation:any;
  notes:any;
  editInfoForm: FormGroup;
  addFormForm: FormGroup;
  addComplianceForm: FormGroup;
  updateFormForm: FormGroup;
  docLink = environment.API_url+"/worklink-api/tina/worklink/agency/"+localStorage.getItem('agentId')+"/compliance-2.png";
  updateComplianceForm: FormGroup;
  // complianceInformationForm: FormGroup;
  addNoteForm: FormGroup;
  addJobForm: FormGroup;
  edit = false;
  edit2 = false;
  editCompliance = false;
  assignmentCodes = [{id:1,name:'001 Cleaner'},{id:2,name:'002 Driver'},{id:1,name:'003 Nurse'}];
  paymentMethods = [{id:1,name:'Paye'},{id:2,name:'Umbrella'},{id:1,name:'Pvt. Ltd'}];
  services = [];
  activeForm: any;
  complianceAll: any;
  compliances: any;
  formDoc: any;
  activeTraining: any;
  applicationDetails: any;
  bankingDetails: any;
  hmrcDetails: any;
  occupationalDetails: any;

  //formOptions = [{id:1,name:'Form 1'},{id:2,name:'Form 2'},{id:1,name:'Form 3'}];

  constructor(
    private activateRoute: ActivatedRoute,
    private fb: FormBuilder,
    private storageService: StorageService,
    private formsService: FormsService,
    private workerService: WorkerService,
    private fileService: FileService,
    private toast: ToastrService,
  ){ }

  ngOnInit(): void {
    this.workerId = this.storageService.decrypt(localStorage.getItem('workerId'));
    this.getApplication();
    this.getBank();
    this.getHmrc();
    this.getOccupational();

  }


  getApplication(){
    this.formsService.getWorkerApplication(this.workerId).subscribe(

      data => {

        this.applicationDetails = data;


      }
    );
  }

  getBank(){
    this.workerService.getWorkerBank(this.workerId).subscribe(
      data => {
        this.bankingDetails = data;


      }
    );
  }

  getHmrc(){
    this.formsService.getWorkerHmrc(this.workerId).subscribe(
      data => {
        // this.addHmrcForm = this.fb.group(data);

        this.hmrcDetails = data;


      }
    );
  }

  getOccupational(){
    this.formsService.getWorkerOccupational(this.workerId).subscribe(
      data => {
        this.occupationalDetails = data;


      }
    );
  }




  getAllForms(){
    this.workerService.getAllForms().subscribe(
      data => {
        // console.log('datadatadatadatadata' ,data)
        this.formAll = data;
      }
    );
  }





  toggleFitToWork(value){
    if(value == 'yes')
    {
      this.fitToWork = true;
    }
    else{
      this.fitToWork = false;
    }

  }

}
