import { AuthService } from './../../../shared/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServicesService } from './../../../shared/services/services.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clients-services',
  templateUrl: './clients-services.component.html',
  styleUrls: ['./clients-services.component.css']
})
export class ClientsServicesComponent implements OnInit {

  services: any;
  selectedService: any;

  addForm: FormGroup;
  editForm: FormGroup;

  page = 1;
  pageSize = 20;
  totalItems = 0;
  showin = 0;
  first: boolean;
  last: boolean;

  userType: string;

  constructor(
    private fb: FormBuilder,
    private toast: ToastrService,
    private servicesService: ServicesService,
    private modalService: NgbModal,
    private authService: AuthService
  ) {
    this.userType = authService.getUserType();
  }

  ngOnInit(): void {
    this.getServices();

    this.editForm = this.fb.group({
      id: 0
    });
  }

  formInit(modal){
    this.addForm = this.fb.group({
      name: ['', Validators.required]
    });

    this.openModal(modal);
  }

  handlePageChange(event) {

    if(event == 'next'){
      this.page += 1;
    }
    else if(event == 'prev' && this.page != 1){
      this.page -= 1;
    }

    this.getServices();
  }

  getServices(){
    this.servicesService.getPaginatedServices(0, 100).subscribe(
      data => {
        // console.table(data.content);
        this.totalItems = data.totalElements;
        this.first = data.first;
        this.last = data.last;

        if(this.page * this.pageSize > data.totalElements){
          this.showin = data.totalElements;
        }
        else{
          this.showin = this.page * this.pageSize;
        }

        this.services = data.content;
      },
      err => {
        



      }
    );
  }

  newService(form: FormGroup){
    // console.table(form.value);

    if(form.valid){
      this.servicesService.createService(form.value).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          this.getServices();
          this.toast.success('Service Added Successfully');
        },
        err => {
          
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
          else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }

        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }
  }

  updateService(form: FormGroup){
    // console.table(form.value);

    if(form.valid){
      this.servicesService.updateService(form.value).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          this.getServices();
          this.toast.success('Service Updated Successfully');
        },
        err => {
          
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
          else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }

        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }
  }

  deleteService(){
    if(this.selectedService.id){
      this.servicesService.deleteService(this.selectedService.id).subscribe(
        resp => {
          // console.table(resp);
          this.toast.success('Service Deleted Successfully.');
          this.modalService.dismissAll();
          this.getServices();
        },
        err => {
          
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
          else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }

        }
      );
    }
  }

  loadService(ref, modal, edit){
    if(ref){
      this.services.forEach(r => {
        if(r.id == ref){
          this.selectedService = r;
        }
      });

      if(edit){
        this.editForm = this.fb.group({
          id: [this.selectedService.id, Validators.required],
          name: [this.selectedService.name, Validators.required],
        });
      }

      this.openModal(modal);
    }
  }

  openModal(modal){
    this.modalService.open(modal, {centered: true});
  }

}
