import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TransportService } from 'src/app/shared/services/transport.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShiftService } from 'src/app/shared/services/shift.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ClientService } from 'src/app/shared/services/client.service';
import { AssignmentCodeService } from 'src/app/shared/services/assignment-code.service';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { StatisticsService } from 'src/app/shared/services/statistics.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-transport-request-form',
  templateUrl: './transport-request-form.component.html',
  styleUrls: ['./transport-request-form.component.css']
})
export class TransportRequestFormComponent implements OnInit {

  agency:any;
  agencyId:any;
  userType: any;


  constructor(
    private router: Router,
    private agencyService: AgencyService,
    private transportService: TransportService,
    private toast : ToastrService,
    private statisticsService : StatisticsService,
    private shiftsService: ShiftService,
    private storageService: StorageService,
    private assignmentCodeService: AssignmentCodeService,
    private fb: FormBuilder,
    private authService: AuthService,
    private modalService: NgbModal,
    private activateRoute: ActivatedRoute,

  ) {
    this.userType = authService.getUserType();
   }


  ngOnInit(): void {
    this.activateRoute.params.subscribe((params) => {
      this.agencyId = params["agencyId"];
    });
    this.getAgency();
  }

  getAgency(){
    this.agencyService.getAgencyById(this.agencyId).subscribe(
      data => {  this.agency=data; }
    );
  }

}
