import { EditShiftComponent } from './edit-shift/edit-shift.component';
import { NewShiftComponent } from './new-shift/new-shift.component';
import { ShiftsLocationsComponent } from './../../admin/shifts/shifts-locations/shifts-locations.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShiftsListComponent } from './shifts-list/shifts-list.component';
import { ShiftsDirectoratesComponent } from 'src/app/admin/shifts/shifts-directorates/shifts-directorates.component';
import { AppliedShiftComponent } from './applied-shift/applied-shift.component';
import { MatIconModule } from '@angular/material/icon';

const routes: Routes = [
  { path: '', redirectTo: 'list', pathMatch: 'full' },
  { path: 'list', component: ShiftsListComponent },
  { path: 'new', component: NewShiftComponent },
  { path: 'edit/:id', component: EditShiftComponent },
  { path: 'applied/:id', component: AppliedShiftComponent },
  { path: 'locations', component: ShiftsLocationsComponent },
  { path: 'directorates', component: ShiftsDirectoratesComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule, MatIconModule]
})
export class ShiftsRoutingModule { }
