import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { WorkerService } from './../../../shared/services/worker.service';
import { ServicesService } from './../../../shared/services/services.service';
import { AgencyService } from './../../../shared/services/agency.service';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'src/app/shared/services/storage.service';
import { environment } from 'src/environments/environment';
import jspdf, { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { FormsService } from 'src/app/shared/services/forms.service';
import { HttpResponse } from '@angular/common/http';
import { FileService } from 'src/app/shared/services/file.service';

@Component({
  selector: 'app-worker-details',
  templateUrl: './worker-details.component.html',
  styleUrls: ['./worker-details.component.css']
})

export class WorkerDetailsComponent implements OnInit {
  public userType: string;
  workerId: number;
  agencyId: number;
  worker: any;
  workerInfo: any;
  workerProperty: any;
  jobCounts: any;
  trainings:any;
  trainingAll:any;
  jobs: any;
  forms: any;
  fitToWork:any;
  complianceInformation:any;
  notes:any;
  editInfoForm: FormGroup;
  agencyReviewForm: FormGroup;
  addTrainingForm: FormGroup;
  addComplianceForm: FormGroup;
  updateTrainingForm: FormGroup;
  updateComplianceForm: FormGroup;
  complianceInformationForm: FormGroup;
  addNoteForm: FormGroup;
  addJobForm: FormGroup;
  edit = false;
  edit2 = false;
  editCompliance = false;
  assignmentCodes = [{id:1,name:'001 Cleaner'},{id:2,name:'002 Driver'},{id:1,name:'003 Nurse'}];
  paymentMethods = [{id:1,name:'Paye'},{id:2,name:'Umbrella'},{id:1,name:'Pvt. Ltd'}];
  services = [];
  activeTraining: any;
  complianceAll: any;
  compliances: any;
  docLink: string;
  related: boolean;
  restriction: boolean;
  dbs: boolean;
  application: any;

  applicationDetails: any;
  bankingDetails: any;
  hmrcDetails: any;
  occupationalDetails: any;


  counter: number
  pdf: jsPDF
  complianceDoc: any;
  //trainingOptions = [{id:1,name:'Training 1'},{id:2,name:'Training 2'},{id:1,name:'Training 3'}];

  constructor(
    private activateRoute: ActivatedRoute,
    private workerService: WorkerService,
    private servicesService: ServicesService,
    private agencyService: AgencyService,
    private authService: AuthService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private fileService: FileService,
    private fb: FormBuilder,
    private formsService: FormsService,
    private storageService: StorageService
  ){
    this.userType = authService.getUserType();
    if(localStorage.getItem('agentId') != null){
      this.complianceInformationForm = this.fb.group({
        workerId: ['', Validators.required],
        agencyId: [this.storageService.decrypt(localStorage.getItem('agentId')), Validators.required]
      });
    }

    this.addTrainingForm = this.fb.group({

      agencyId: [this.agencyId,],
      workerId: [this.workerId,],
      name: ['',],
      code: ['',],
      serviceId: ['', ],
      trainingId: ['', ],
      trainingDate: ['', Validators.required],
      trainingExpiry: ['', Validators.required],
    });

    this.addComplianceForm = this.fb.group({
      agencyId: [this.agencyId,],
      workerId: [this.workerId,],
      name: ['',],
      code: ['',],
      // description: ['',],
      serviceId: ['', ],
      complianceId: ['', ],
      complianceDate: ['', Validators.required],
      complianceExpiry: ['', Validators.required],
    });

    this.updateTrainingForm = this.fb.group({
      id: [Validators.required ],
      comment: [''],
      status: [Validators.required],
      agencyId: [Validators.required],
      workerId: [Validators.required],
    });

    this.updateComplianceForm = this.fb.group({
      id: [Validators.required ],
      comment: [''],
      status: [Validators.required],
      agencyId: [Validators.required],
      workerId: [Validators.required],
    });

    this.addNoteForm = this.fb.group({
    });
    this.addJobForm = this.fb.group({
      assignmentCodeId: ['', Validators.required],
      paymentMethodId: ['', Validators.required]
    });

    this.agencyReviewForm = this.fb.group({
      agencyId: [this.agencyId,],
      workerId: [this.workerId,],
      eligible:[],
      proof:[],
      visa:[],
      visaExpiry:[],
      paperwork:[],
      approver:[],
      position:[],
      comment:[],
      signed:[],
      signDate:[],
    });



   }

  ngOnInit(): void {
    this.agencyId = this.storageService.decrypt(localStorage.getItem('agentId'));

    this.activateRoute.params.subscribe((params) => {
      this.workerId = params["id"]
    });

    if(!this.agencyId){
      this.activateRoute.params.subscribe((params) => {
        this.agencyId = params["agencyId"];
      });

    }
    // console.log(this.agencyId)
    this.getWorker();
    this.getAllServices();

    this.getBank();
    this.getHmrc();
    this.getOccupational();
    this.getApplication();

    this.getWorkerTrainings();
    this.getWorkerCompliances();
    this.getWorkComplianceInformation();
    this.getAllTrainings();
    this.getAllCompliances();


    this.editInfoForm = this.fb.group({
      id:[this.workerId, Validators.required], /// This is a worker id
      workerId: [this.workerId, Validators.required],
      agencyId: [this.storageService.decrypt(localStorage.getItem('agentId')), Validators.required],
      paymentMethod: [this.complianceInformation?.paymentMethod??"", Validators.required],
      employmentStartDate: [this.complianceInformation?.employmentStartDate, Validators.required],
      contractEndDate: [this.complianceInformation?.contractEndDate],
      nextCheckDate: [this.complianceInformation?.nextCheckDate, Validators.required],

    });

    this.complianceInformationForm = this.fb.group({
      id:[this.workerId, Validators.required], /// This is a worker id
      workerId: [this.workerId, Validators.required],
      agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
      rightToWork: [this.complianceInformation?.rightToWork],
      restrictions: [this.complianceInformation?.restrictions],
      dbsNumber: [this.complianceInformation?.dbsNumber],
      visaExpiry: [this.complianceInformation?.visaExpiry]
    });

    console.log(this.applicationDetails);
    console.log(this.bankingDetails);
    console.log(this.hmrcDetails);
    console.log(this.occupationalDetails);

  }


  toggleEdit(){
    this.edit = !this.edit;
    console.log(this.editInfoForm.value);
    console.log(this.complianceInformation);
    this.editInfoForm = this.fb.group({
      id: [this.worker.id, Validators.required],
      workerId: [this.workerId, Validators.required],
      agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
      paymentMethod: [this.complianceInformation?.paymentMethod??"", Validators.required],
      employmentStartDate: [this.complianceInformation?.employmentStartDate, Validators.required],
      contractEndDate: [this.complianceInformation?.contractEndDate],
      nextCheckDate: [this.complianceInformation?.nextCheckDate, Validators.required],
    });
  }
  toggleEdit2(){
    this.edit2 = !this.edit2;
  }

  public async captureScreen()
  {
    let data = document.getElementById('contentToConvert');



}


  toggleEditCompliance(){
    this.editCompliance = !this.editCompliance
  }
  toggleAddTraining (){

    this.editCompliance = !this.edit2;
  }

  getWorker(){

    this.workerService.getWorkerById(this.workerId).subscribe(
      data => {
        this.worker = data;
        // console.log(this.worker)
        // this.agencyId = data.agencyId;
      }
    );
  }

  getAllTrainings(){
    this.workerService.getAllTrainings().subscribe(
      data => {
        // console.log('datadatadatadatadata' ,data)
        this.trainingAll = data;
      }
    );
  }
  getAllCompliances(){
    this.workerService.getAllCompliances().subscribe(
      data => {
        // console.log('datadatadatadatadata' ,data)
        this.complianceAll = data;
      }
    );
  }

  getAllServices(){
    this.servicesService.getAllServices().subscribe(
      data => {
        this.services = data;
        // console.log("----------------------",this.services)
      }
    );
  }


  openDoc(url){
    this.workerService.getContentTpye(url).subscribe(res => {
      if(res.headers.get('Content-Type')!='application/pdf') {
        return window.open(url);
      }else{
        this.viewDoc(url);
      }
    });
  }

  viewDoc(url){
    this.workerService.downloadPDF(url).subscribe(res => {
      console.log(res);
      if(res==null)
        window.open(url, '_blank');
      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, '_blank');
    });
  }

  getWorkerJobCount(){
    this.workerService.getWorkerJobCount(this.workerId).subscribe(
      data => {
        this.jobCounts =
          [
            {asignmentCode : "678", jobs : "9"},
            {asignmentCode : "105", jobs : "3"},
            {asignmentCode : "307", jobs : "7"},
            {asignmentCode : "624", jobs : "2"},
          ]
        ;// delete this devip
        //this.jobCounts = data; // uncomment this devip
      }
    );
  }
  getWorkerJobs(){
    this.workerService.getWorkerJobs(this.workerId).subscribe(
      data => {
        this.jobs =
          [
            {id : "1", job : "Nurse"},
            {id : "2", job : "Cleaner"},
            {id : "3", job : "Driver"}
          ]
        ;
      }
    );
  }

  openCertificate(enrolmentId){
    this.workerService.getOnlineTrainingCertificate(enrolmentId).subscribe(
      data => {
        window.open(data.data.certificate_link.url, "_blank");

      }
    );
  }

  getWorkerTrainings(){
    this.workerService.getAgencyWorkerTrainings(this.workerId, this.agencyId).subscribe(
      data => {
        this.trainings = data;
      }
    );
  }

  getWorkerCompliances(){
    this.workerService.getAgencyWorkerCompliances(this.workerId, this.agencyId).subscribe(
      data => {
        this.compliances = data; // uncomment this devip
      }
    );
  }



  updateWorkerInfo(form: FormGroup){
    if(form.valid){
      console.log(form.value);
      this.workerService.updateWorkerInfo(form.value).subscribe(
        resp => {
          console.log("Worker details updated")
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }


  updateAgencyWorkerInfo(form: FormGroup){
    if(form.valid){
      console.log(form.value);
      this.agencyService.updateAgencyWorkerProperties(form.value).subscribe(
        resp => {
          this.toggleEdit();
          this.updateWorkerInfo(form)
          this.getWorkComplianceInformation();
          this.getWorker();
          this.toast.success('Worker Info Updated Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  updateAgencyWorkerInfoReview(form: FormGroup){
    if(form.valid){
      console.log(form.value);
      this.agencyService.updateAgencyWorkerProperties(form.value).subscribe(
        resp => {
          this.toggleEdit();
          this.getWorkComplianceInformation();
          this.toast.success('Worker Info Updated Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }




  activateWorker(){
    if(true){
      var form = {
        "agencyId": this.agencyId,
        "workerId": this.workerId
      };
      this.agencyService.activateWorker(form).subscribe(
        resp => {
          this.getWorkComplianceInformation();
          this.toast.success('Worker activated');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  deactivateWorker(){


    if(true){
      var form = {
        "agencyId": this.agencyId,
        "workerId": this.workerId
      };
      this.agencyService.deactivateWorker(form).subscribe(
        resp => {
          this.getWorkComplianceInformation();
          this.toast.success('Worker deactivated');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }



  activateApplicant(){
    if(true){
      var form = {
        "agencyId": this.agencyId,
        "workerId": this.workerId
      };
      this.agencyService.activateApplicant(form).subscribe(
        resp => {
          this.getWorkComplianceInformation();
          this.toast.success('Applicant activated');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  deactivateApplicant(){


    if(true){
      var form = {
        "agencyId": this.agencyId,
        "workerId": this.workerId
      };
      this.agencyService.deactivateApplicant(form).subscribe(
        resp => {
          this.getWorkComplianceInformation();
          this.toast.success('Applicant removed');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }


  toggleFitToWork(value){
    if(value == 'yes')
    {
      this.fitToWork = true;
    }
    else{
      this.fitToWork = false;
    }

  }

  submitFitToWork(){
    this.workerService.updateFitToWork(this.workerId,this.fitToWork).subscribe(
      resp => {
        this.getWorkerJobs();
        this.toast.success('Worker Fit To Work Updated Successfully');
      }
    );
  }


  addTraining(form: FormGroup){
    if(this.complianceDoc.size>=1048576){
      this.toast.warning("Maximum upload file size is 1mb")
    }

    if(form.valid){
      form.value.workerId =this.workerId;
      form.value.agencyId =this.agencyId;

      this.workerService.addTraining(form.value, this.complianceDoc).subscribe(
        resp => {
          this.closeModals();
          this.getWorkerTrainings();
          this.toast.success('Worker Training Added Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  updateTraining(form: FormGroup, status: string){
    form.value.id =this.activeTraining.id ;
    form.value.status = status ;
    form.value.workerId = this.workerId ;
    form.value.agencyId = this.agencyId ;
    console.log(form.value);
    if(form.valid){

      this.workerService.updateTraining(form.value).subscribe(
        resp => {
          this.closeModals();
          this.getWorkerTrainings();
          this.toast.success('Worker Training Updated Successfully');
        }
      );
    }
    else{
      console.log(form.value);
      this.toast.warning('Missing Required Info.');
    }
  }

  updateCompliance(form: FormGroup, status: string){
    form.value.id =this.activeTraining.id ;
    form.value.status = status ;
    form.value.workerId = this.workerId ;
    form.value.agencyId = this.agencyId ;
    console.log(form.value);
    if(form.valid){

      this.workerService.updateCompliance(form.value).subscribe(
        resp => {
          this.closeModals();
          this.getWorkerCompliances();
          this.toast.success('Worker Compliance Updated Successfully');
        }
      );
    }
    else{
      console.log(form.value);
      this.toast.warning('Missing Required Info.');
    }
  }





  getWorkComplianceInformation(){
    // console.log(this.storageService.decrypt(localStorage.getItem('userType')))
    if(  true  )
    {
      this.workerService.getWorkComplianceInformation(this.workerId,this.agencyId).subscribe(
        data => {
          this.complianceInformation = data;
          console.log(data);

          this.agencyReviewForm = this.fb.group({
            agencyId: [this.agencyId,],
            workerId: [this.workerId,],
            eligible:[data.eligible],
            proof:[data.proof],
            visa:[data.visa],
            visaExpiry:[data.visaExpiry],
            paperwork:[data.paperwork],
            approver:[data.approver],
            position:[data.position],
            comment:[data.comment],
            signed:[data.signed],
            signDate: [data.signDate]
          });
          console.log(this.complianceInformation)
        }
      );
    }
  }

  getWorkerNotes(){
    this.workerService.getWorkerNotes(this.workerId).subscribe(
      data => {
        this.notes = [
          {id : "1", note : "Worker note 1"},
          {id : "2", note : "Worker note 2"},
          {id : "3", note : "Worker note 3"},
          {id : "4", note : "Worker note 4"},
        ]; // delete this devip
        //this.notes = data; // uncomment this devip
      }
    );
  }

  addNote(form: FormGroup){
    // console.table(form.value);
    if(form.valid){
      this.workerService.addNote(form.value).subscribe(
        resp => {
          this.closeModals();
          this.getWorkerNotes();
          this.toast.success('Worker Note Added Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  addJob(form: FormGroup){
    // console.table(form.value);
    if(form.valid){
      this.workerService.addJob(form.value).subscribe(
        resp => {
          this.closeModals();
          this.getWorkerJobs();
          this.toast.success('Worker Job Added Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  removeJob(id){
    this.workerService.removeJob(id).subscribe(
      resp => {
        this.getWorkerJobs();
        this.toast.success('Worker Removed Successfully');
      }
    );
}


addAgencyReview(form: FormGroup){
  // console.log(this.addApplicationForm);
  // let form = this.addApplicationForm;
  form.value.serviceId = 1;
  if(form.valid){
    form.value.workerId =this.workerId ;

    this.formsService.addWorkerApplication(form.value).subscribe(
      resp => {
        this.toast.success('Worker Application Updated Successfully');
        // this.addRefs(resp.id);
      }
    );
  }
  else{
    this.toast.warning('Missing Required Info.');
  }
}

openModal(content: any, training?: any){
  this.selectedTraining = training;
    this.modalService.open(content, {centered: true});
  };

  selectedTraining(training){
    // console.log(training);
    this.activeTraining = training;
  };

  closeModals(){
    this.modalService.dismissAll();
  };

  getApplication(){
    this.formsService.getAgencyWorkerApplication(this.workerId, this.agencyId).subscribe(

      data => {
        if(data?.submitted){
          this.application = data;
        }else{
          this.application = null;
        }
        // console.log(this.application);

      }
    );
  }
  getBank(){
    this.workerService.getWorkerBank(this.workerId).subscribe(
      data => {
        this.bankingDetails = data;


      }
    );
  }

  getHmrc(){
    this.formsService.getAgencyWorkerHmrc(this.workerId, this.agencyId).subscribe(
      data => {
        // this.addHmrcForm = this.fb.group(data);

        this.hmrcDetails = data;


      }
    );
  }

  getOccupational(){
    this.formsService.getAgencyWorkerOccupational(this.workerId, this.agencyId).subscribe(
      data => {
        this.occupationalDetails = data;


      }
    );
  }

  setYes(title){
    console.log(this.rde2);

    if(title=='restriction'){this.restriction = true}
    if(title=='dbs'){this.dbs = true}
    if(title=='related'){this.related = true}

  }
  rde2(rde2: any) {
    throw new Error('Method not implemented.');
  }

  resetYes(title){
    console.log(this.rde2);

    if(title=='restriction'){this.restriction = false}
    if(title=='dbs'){this.dbs = false}
    if(title=='related'){this.related = false}

  }




  checkExpiry(date){
    date = this.formatDate(date);
    var d = new Date(date);
    var dnow =  new Date();
    var d30 =  new Date();
    d30.setDate(dnow.getDate()+30);

    if(d30> d && d > dnow){
      return "alert";
    }
    if(d30> d){
      return "expired";
    }
    return null;
  }


  formatDate(date){
    const myArray = date.split("/");
    if(myArray.length>=2){
      let formateddate =myArray[2] + "-" +myArray[1] +"-"+myArray[0] ;
      return formateddate;
    }else{
      return date;
    }
  }

  printDiv() {
    var divToPrint = document.getElementById('contentToConvert');
    var newWin = window.print();
  }

  downloadPDF() {
    this.pdf = new jsPDF('p', 'mm', 'a4') // A4 size page of PDF
    // this.length = this.items.length
    this.counter = 2

    this.generatePDF()
  }

  generatePDF() {
    var data = document.getElementById('contentToConvert')

    html2canvas(data, {
      scale: 3
    }).then((canvas) => {
      this.counter = 2;
      var imgWidth = 208
      var imgHeight = (canvas.height * imgWidth) / canvas.width

      const contentDataURL = canvas.toDataURL('image/png')
      var position = 0
      this.pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      this.pdf.addImage(contentDataURL, 'PNG', 208, position, imgWidth, imgHeight);

        this.pdf.addPage();
        this.pdf.save('users.pdf');
        return true
      // }
    })
  }


  onChange(event) {
    this.complianceDoc = event.target.files[0];
  }


  uploadCompliance(compliance){
    console.log(this.complianceDoc);
    if(this.complianceDoc.size>=1048576){
      this.toast.warning("Maximum upload file size is 1mb")
    }
    else {
    this.fileService.workerUploadCompliance(this.complianceDoc,compliance , this.workerId).subscribe(
      event => {
        if(event instanceof HttpResponse) {

        };
        this.toast.info("Sucessfully uploaded.");
        this.modalService.dismissAll();
      },
    );}
  }


  addWorkerCompliance(form: FormGroup){
    if(this.complianceDoc.size>=1048576){
      this.toast.warning("Maximum upload file size is 1mb")
    }

    form.value.serviceId = 1;
    if(form.valid){
      form.value.workerId =this.workerId ;
      form.value.agencyId =1 ;
      this.workerService.addCompliance(form.value, this.complianceDoc).subscribe(
        resp => {
          this.closeModals();
          this.getWorkerCompliances();
          this.toast.success('Worker Compliance Added Successfully');

          console.log(resp);
          this.uploadCompliance(resp.id);
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }



}
