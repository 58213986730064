<main class="mt-5">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-8 text-left">
          <h3>Update Shift Details</h3>
        </div>
        <div class="col-12 col-sm-6 col-md-4 text-left"></div>
      </div>
    </div>

    <section class="content mt-4" *ngIf="shift">
      <form [formGroup]="editForm" (ngSubmit)="updateShift(editForm)">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-4">
              <div class="form-group">
                Flexi-Worker Assignment Code

                <input
                type="text"
                disabled=""
                placeholder="Date"
                value="{{shift?.assignmentCode}}"
                class="form-control"

              />
                <!-- <select  disabled class="form-control">
                  <option value="" disabled>
                    Select Assignment Code
                  </option>
                  <option *ngFor="let a of assCodes" value="{{ a.name }}" [selected]="shift?.assignmentCode==a.name" >
                    {{ a.name }}
                  </option>
                </select> -->
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4">
              <div class="form-group">
                Gender
                <select  disabled class="form-control">
                  <option value="MALE"   [selected]=" shift.gender=='MALE' ">Male</option>
                  <option value="FEMALE"  [selected]=" shift.gender=='FEMALE' ">Female</option>
                  <option value="NO_PREFERENCE"  [selected]=" shift.gender=='NO_PREFERENCE' ">No Preference</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-4">
              <div class="form-group">
                Shift Type
                <div class="form-group">
                  <input type="text" maxlength="255"   disabled class="form-control" name="" id="" [value]=" shift.shiftType " aria-describedby="helpId" placeholder="">
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-3">
              <div class="form-group">
                Start Date
                <input
                type="date"
                placeholder="Date"
                formControlName="shiftDate"
                class="form-control"

              />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-3">
              <div class="form-group">
                Start Time
                <input
                  type="time"
                  formControlName="shiftStartTime"
                  placeholder="Date"
                  [value]="shift.shiftStartTime"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-3">
              <div class="form-group">
                End Date
                <input
                  type="date"
                  placeholder="Date"
                  formControlName="shiftEndDate"
                  class="form-control"

                />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-3">
              <div class="form-group">
                End Time
                <input
                  type="time"
                  formControlName="shiftEndTime"
                  placeholder="Date"
                  [value]="shift.shiftEndTime"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-3">
              <div class="form-group">
                <label> Break Time</label>
                <div class="input-group">
                  <select class="form-control"  formControlName="breakHr" id="myHrs"  value="0" required>

                    <option value="0" >0 hr</option>
                    <option value="1">1 hr</option>
                    <option value="2">2 hr</option>
                    <option value="3">3 hr</option>
                    <option value="4">4 hr</option>
                    <option value="5">5 hr</option>
                    <option value="6">6 hr</option>
                    <option value="7">7 hr</option>
                    <option value="8">8 hr</option>
                    <option value="9">9 hr</option>
                  </select>
                <select class="form-control"  formControlName="breakMin"  id="myMins"  value="1" required>
                  <option value="0" >0 min</option>
                  <option value="5">5 min</option>
                  <option value="10">10 min</option>
                  <option value="15">15 min</option>
                  <option value="20">20 min</option>
                  <option value="25">25 min</option>
                  <option value="30">30 min</option>
                  <option value="35">35 min</option>
                  <option value="40">40 min</option>
                  <option value="45">45 min</option>
                  <option value="50">50 min</option>
                  <option value="55">55 min</option>
                </select>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12">
              <div class="form-group">
                Reply
                <textarea
                  formControlName="reply"
                  placeholder="Reply...(200 characters)"
                  cols="30"
                  rows="5"
                  class="form-control"
                  maxlength="200"
                ></textarea>
              </div>
            </div>
            <!--div class="col-12 col-sm-12 col-md-6 col-lg-4">
              <div class="form-group">
                Show Notes To Agency
                <input
                  type="checkbox"
                  formControlName="showNoteToAgency"
                  class="ml-2"
                  [checked]="shift.showNoteToAgency"
                />
              </div>
            </div-->
            <!--div class="col-12 col-sm-12 col-md-6 col-lg-4">
              <div class="form-group">
                Show Notes To Flexi-Worker
                <input
                  type="checkbox"
                  formControlName="showNoteToFw"
                  class="ml-2"
                  [checked]="shift.showNoteToFw"
                />
              </div>
            </div-->
            <div class="col-12 col-sm-12 col-md-6 col-lg-4">
              <div class="form-group text-danger-custom danger">
                Did not attend
                <input
                  type="checkbox"
                  formControlName="noneAttendance"
                  class="ml-2"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid text-center mt-5">
          <button
            type="submit"
            class="ml-4 btn btn-danger"
          >
            <i class="bi bi-x"></i>&nbsp;&nbsp;Cancel
          </button>
          <button
            type="submit"
            class="ml-4 btn btn-success"
          >
            <i class="bi bi-plus-lg"></i>&nbsp;&nbsp;Update Shift
          </button>
        </div>
      </form>
    </section>
  </main>
