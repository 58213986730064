import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from '../shared/pages/not-found/not-found.component';
import { ApplyComponent } from './apply/apply.component';
import { TransportRequestFormComponent } from './transport-request-form/transport-request-form.component';

const routes: Routes = [
  {
    path: 'guest',
    // component: AdminContainerComponent,
    // canActivate: [AuthGuard],
    children: [
      { path: 'transport/request/:agencyId', component: TransportRequestFormComponent },
      { path: ':agencyId/:agencyName', component: ApplyComponent },
      // not found
      { path: '**', component: NotFoundComponent, pathMatch: 'full' },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GuestRoutingModule { }
