import { SharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WorkerRoutingModule } from './worker-routing.module';
import { WorkerContainerComponent } from './worker-container/worker-container.component';
import { WorkerHomeComponent } from './worker-home/worker-home.component';
import { ShiftsModule } from '../admin/shifts/shifts.module';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { WorkerAgencyComponent } from './worker-agency/worker-agency.component';
// import { PayslipsComponent } from './worker-payroll/payslips/payslips.component';
// import { ExpensesClaimedComponent } from './worker-payroll/expenses-claimed/expenses-claimed.component';
import { TrainingsComponent } from './trainings/trainings.component';
import { ComplianceComponent } from './compliance/compliance.component';
import { FormsComponent } from './forms/forms.component';
import { ApplicationComponent } from './forms/components/application/application.component';
import { OccupationalComponent } from './forms/components/occupational/occupational.component';
import { HmrcComponent } from './forms/components/hmrc/hmrc.component';
import { BankComponent } from './forms/components/bank/bank.component';
import { CertificateComponent } from './certificate/certificate.component';
import { AdminWorkersModule } from '../admin/workers/workers.module';


@NgModule({
  // declarations: [WorkerContainerComponent, WorkerHomeComponent, PayslipsComponent, ExpensesClaimedComponent],
  declarations: [WorkerContainerComponent, WorkerHomeComponent,
    FormsComponent, WorkerAgencyComponent, TrainingsComponent,
    ComplianceComponent, ApplicationComponent, OccupationalComponent,
     HmrcComponent, BankComponent, CertificateComponent,],
  imports: [
    CommonModule,
    AdminWorkersModule,
    WorkerRoutingModule,
    SharedModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatButtonModule,
    MatDatepickerModule,

  ]
})
export class WorkerModule { }
