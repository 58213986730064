import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/shared/services/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-agency-home',
  templateUrl: './agency-home.component.html',
  styleUrls: ['./agency-home.component.css']
})
export class AgencyHomeComponent implements OnInit {


  constructor(

  ) { }

  ngOnInit(): void {

  }

}
