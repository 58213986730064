import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from '../shared/pages/not-found/not-found.component';
import { ApplyComponent } from './apply/apply.component';
import { TransportRequestFormComponent } from './transport-request-form/transport-request-form.component';
import { LeaderEntryComponent } from './transport/transport-components/lead-entry/leader-entry.component';
import { TeamLeaderViewJobComponent } from './transport/transport-components/log-transport-job.component';
import { TeamLeaderLogin } from './transport/transport-components/team-leader-login/team-leader-login.component';

const routes: Routes = [
  {
    path: 'guest',
    // component: AdminContainerComponent,
    // canActivate: [AuthGuard],
    children: [
      { path: 'transport/request/:agencyId', component: TransportRequestFormComponent },
      { path: 'team-leader/entry/:id', component: LeaderEntryComponent },
      {path: "team-leader-login/:transportJobId", component: TeamLeaderLogin},
      // { path: 'team-leader/transport/:id', component: TeamLeaderViewJobComponent },
      { path: ':agencyId/:agencyName', component: ApplyComponent },
  

     
      // not found
      { path: '**', component: NotFoundComponent, pathMatch: 'full' },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GuestRoutingModule { }
