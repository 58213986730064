<main>
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,1,0" />

  <style>
    @media print {
  div.hideMePrint {
    display:none !important;
  }
  div.showMePrint {
    display:flex !important;
  }
}
  </style>

  <div class="modal-header">
    <h5 class="modal-title text-center mx-4 w-100">Recruitment Staff Profile</h5>
  </div>
  <div class="modal-body">
    <div class="">

      <div class="">
        <form  [formGroup]="agencyReviewForm" (ngSubmit)="updateAgencyWorkerInfoReview(agencyReviewForm)"  >
          <!-- <form [formGroup]="addForm" (ngSubmit)="newForm(addForm)"> -->
            <div class="bg-modal card-body">
              <div class="container-fluid">
              <div class="col-12 text-left">
                <h3>Forms/ Staff Profile</h3>
              </div>

              <section>
                  <div class="col-12">
                    <!-- <i class="bi bi-info-circle"></i>
                    <span class="ml-2 text-info-custom">You must complete all sections of the application form electronically. We will use this form to help us decide on your suitability for the post so please make sure it is accurate and complete. CVs in isolation will not be accepted.
                    </span> -->
                    <div class="  text-white mx-4 ">
                      <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">Personal Details</h5>
                      <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close">
                      <!-- <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
                        (click)="modal.dismiss()"> -->
                        <span aria-hidden="true">&times;</span>
                      </span>
                    </div>
                    <hr class="bg-black mt-0">

                    <div class="row form-group ">
                      <div class="mt-1 col-sm-3 col-md-3">
                        <Label>Title</Label>
                        <select disabled class="form-control" >
                          <option value="Mr" >Mr</option>
                          <option value="Mrs" >Mrs</option>
                          <option value="Miss" >Miss</option>
                        </select>
                      </div>
                      <div class="mt-1 col-sm-9 col-md-9">
                        <Label>Candidate Name</Label>
                        <input type="text" maxlength="255"   disabled value="{{worker?.firstname}} {{worker?.lastname}}" class="form-control">
                      </div>

                      <div class="mt-1 col-sm-12 col-md-12">
                        <Label>Home address</Label>
                        <input type="text" maxlength="255"   disabled value="{{worker?.address}}" class="form-control">
                      </div>
                      <div class="mt-1 col-sm-12 col-md-6">
                        <Label>Email</Label>
                        <input type="text" maxlength="255"   disabled value="{{worker?.email}}" class="form-control">
                      </div>
                      <div class="mt-1 col-sm-12 col-md-6">
                        <Label>Contact Number</Label>
                        <input type="text" maxlength="255"   disabled value="{{worker?.phoneNumber}}" class="form-control">
                      </div>

                      <div class="mt-1 col-sm-12 col-md-6">
                        <Label>Date of birth</Label>
                        <input type="date" class="date" disabled  value="1985-10-24" class="form-control date">
                      </div>
                      <div class="mt-1 col-sm-12 col-md-6">
                        <Label>DBS Number</Label>
                        <input type="text" maxlength="255" class="date" disabled  [value]="application?.dbsNumber" class="form-control date">
                      </div>

                      <p class="font-weight-bold col-12 mt-4 mb-0">DBS Information</p>

                      <div class="col-sm-12 col-md-3  ">
                        <!-- <Label>Date of birth</Label>
                        <input type="date" class="date"  class="form-control date"> -->
                        Basic DBS Check
                        <div *ngIf="application?.dbsType=='basic'" class="col-4">
                          <span class="material-symbols-outlined">priority</span>
                        </div>
                      </div>

                      <div class="col-sm-12 col-md-3 row">
                        <div class="col-8">STANDARD DBS Check</div>
                        <div *ngIf="application?.dbsType=='standard'" class="col-4">
                          <span class="material-symbols-outlined">priority</span>
                        </div>
                      </div>

                      <div class="mt-1 col-sm-12 col-md-3">
                        <!-- <Label>Date of birth</Label>
                        <input type="date" class="date"  class="form-control date"> -->
                        ENHANCED DBS Check
                        <div *ngIf="application?.dbsType=='enhanced'" class="col-4">
                          <span class="material-symbols-outlined">priority</span>
                        </div>
                      </div>

                      <div class="mt-1 col-sm-12 col-md-3">
                        <!-- <Label>Date of birth</Label>
                        <input type="date" class="date"  class="form-control date"> -->
                        Enhanced with Barred List(s) DBS check
                        <div *ngIf="application?.dbsType=='enhanced1'" class="col-4">
                          <span class="material-symbols-outlined">priority</span>
                        </div>
                      </div>

                    </div>
                    <div class="mt-1 col-sm-12 mb-2 col-md-6">
                      <Label>Date of last DBS check by agency</Label>
                      <input type="date" class="date" disabled formControlName="signDate" class="form-control date">
                    </div>

                  </div>

              </section>

              <section>
                  <div class="col-12">

                    <div class="  text-white mx-4 ">
                      <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close">
                      <!-- <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
                        (click)="modal.dismiss()"> -->
                        <span aria-hidden="true">&times;</span>
                      </span>
                    </div>
                    <hr class="bg-black mt-0">

                    <div class="row form-group">

                      <div class="mt-1 col-sm-12 col-md-6">
                        <Label>Cautions / Convictions</Label>
                        <input *ngIf="!application?.offenses" type="text" disabled  value="None" class="form-control">
                        <input *ngIf="application?.offenses" type="text" disabled  [value]="application?.offensesRes" class="form-control">
                      </div>


                      <div class="mt-1 col-sm-12 col-md-6">
                        <Label>NMC PIN Number</Label>
                        <input type="text" maxlength="255" disabled  value="N/A" class="form-control">
                      </div>
                      <div class="mt-1 col-sm-12 col-md-6">
                        <div class="col-md-12">I confirm that I am entitled to work in the uk </div>
                        <div class="col-md-12 d-flex">
                          <span *ngIf="application?.ukWork" class="material-symbols-outlined">priority</span>
                          <span *ngIf="!application?.ukWork" class="material-symbols-outlined text-grey">check_box_outline_blank </span>
                          <div>Yes</div>&nbsp;&nbsp;&nbsp;
                          <span *ngIf="!application?.ukWork" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                          <span *ngIf="application?.ukWork" class="material-symbols-outlined text-grey">check_box_outline_blank </span>
                          <div>No</div>
                        </div>
                      </div>
                      <div class="mt-1 col-sm-12 col-md-6">
                        <Label>Hep B if applicable</Label>
                        <div class="col-md-12 row">
                          <div class="col-md-6 d-flex">
                            <span class="material-symbols-outlined">priority</span>
                            <div>Yes</div>&nbsp;&nbsp;&nbsp;
                            <span class="material-symbols-outlined text-grey">check_box_outline_blank </span>
                            <div>No</div>
                          </div>
                          <div class="col-md-6">
                            <input type="text" maxlength="255" disabled  class="form-control">
                          </div>

                         </div>

                      </div>
                      <div class="mt-1 col-sm-12 col-md-6">
                        <Label>Job Role to be Undertaked</Label>
                        <select type="text"  disabled  class="form-control">
                          <option value="">Support Worker</option>
                          </select>
                      </div>
                    </div>
                  </div>

              </section>

              <section>
                  <div class="col-12">

                    <div class="  text-white mx-4 ">

                      <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close">
                      <!-- <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
                        (click)="modal.dismiss()"> -->
                        <span aria-hidden="true">&times;</span>
                      </span>
                    </div>
                    <hr class="bg-black mt-0">

                    <div class="row form-group">





                      <div class="row my-3">
                        <div class="col-md-8 col-sm-8 col-8">

                          <div *ngIf="userType!='client'" class="mt-1 col-sm-12 ">
                            <div >Staff eligible to work in the UK</div>

                            <div  class="d-flex">
                              <input  type="radio"  formControlName="eligible"  id="worker" [value]="true"/>
                              <label for="worker453"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                              <input type="radio"  formControlName="eligible"  id="worker" [value]="false"/>
                              <label for="worker453"> &nbsp;No</label><br/>
                            </div>

                          </div>

                          <div *ngIf="userType=='client'" class="mt-1 col-sm-12">
                            <div >Staff eligible to work in the UK</div>

                            <div class=" d-flex">
                              <span class="material-symbols-outlined">priority</span>
                              <div>Yes</div>&nbsp;&nbsp;&nbsp;
                              <span class="material-symbols-outlined text-grey">check_box_outline_blank </span>
                              <div>No</div>
                            </div>

                          </div>


                          <div  *ngIf="userType!='client'" class="mt-1 col-sm-12 col-md-12">
                            <Label>Proof provided</Label>
                            <select type="text" formControlName="proof" disabled class="form-control">
                              <option value="brp">BRP</option>
                              <option value="pass">Passport</option>
                              <option value="hof">Home Office Letter</option>
                            </select>
                          </div>

                          <div  *ngIf="userType=='client'" class="mt-1 col-sm-12 col-md-12">
                            <Label>Proof provided</Label>
                            <select type="text" formControlName="proof" disabled class="form-control">
                              <option value="brp">BRP</option>
                              <option value="pass">Passport</option>
                              <option value="hof">Home Office Letter</option>
                            </select>
                          </div>

                          <div *ngIf="userType!='client'" class="mt-1 col-sm-12 col-md-12">
                                <Label>Visa Type</Label>
                                <select formControlName="visa"  class="form-control" >
                                  <option value="ilr">ILR (Indefinite Leave to Remain)</option>
                                  <option value="t2">Tier 2 Visa</option>
                                  <option value="st">Student Visa</option>
                                  <option value="pr">PR/Citizen</option>
                                </select>
                              </div>
                              <div *ngIf="userType=='client'" class="mt-1 col-sm-12 col-md-12">
                                <Label>Visa Type</Label>
                                <select formControlName="visa" disabled class="form-control" >
                                  <option value="ilr">ILR (Indefinite Leave to Remain)</option>
                                  <option value="t2">Tier 2 Visa</option>
                                  <option value="st">Student Visa</option>
                                  <option value="pr">PR/Citizen</option>
                                </select>
                              </div>
                              <div class="mt-1 col-sm-12 col-md-12"  *ngIf="userType!='client'">
                                <Label>Visa expiry date(if applicable)</Label>
                                <input type="date" formControlName="visaExpiry" class="form-control">
                              </div>
                              <div class="mt-1 col-sm-12 col-md-12"  *ngIf="userType=='client'">
                                <Label>Visa expiry date(if applicable)</Label>
                                <input type="date" formControlName="visaExpiry" disabled class="form-control">
                              </div>
                              <div class="mt-1 col-sm-12 col-md-12 row ">
                                <div class="col-md-9">
                                  Driving Licence/Insurance (if applicable for role)
                                </div>


                                <div class="col-md-12 d-flex">
                                  <span *ngIf="application?.licence" class="material-symbols-outlined">priority</span>
                                  <span *ngIf="!application?.licence" class="material-symbols-outlined text-grey">check_box_outline_blank </span>
                                  <div>Yes</div>&nbsp;&nbsp;&nbsp;
                                  <span *ngIf="!application?.licence" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                                  <span *ngIf="application?.licence" class="material-symbols-outlined text-grey">check_box_outline_blank </span>
                                  <div>No</div>
                                </div>

                              </div>
                              <div class="mt-1 col-sm-12 col-md-12">
                                <Label>References received</Label>
                                <input *ngFor="let n of application?.references" type="text" disabled  [value]="n.name" class="form-control">
                              </div>
                              <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                                <div class="col-md-9">
                                  All required paperwork checked
                                </div>

                                <div class="col-md-3"  *ngIf="userType!='client'">
                                  <input checked="checked" type="radio" formControlName="paperwork" id="rd1" [value]="true"/>
                                  <label for="rd1"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                                  <input type="radio" formControlName="paperwork" id="rd2" [value]="false"/>
                                  <label for="rd2"> &nbsp;No</label><br/>
                                </div>
                                <div class="col-md-3"  *ngIf="userType=='client'">
                                  <div class="col-md-12 d-flex">
                                    <span *ngIf="complianceInformation!=null && complianceInformation?.paperwork" class="material-symbols-outlined">priority</span>
                                    <span *ngIf="!complianceInformation!=null && complianceInformation?.paperwork" class="material-symbols-outlined text-grey">check_box_outline_blank </span>
                                    <div>Yes</div>&nbsp;&nbsp;&nbsp;
                                    <span *ngIf="!complianceInformation!=null && complianceInformation?.paperwork" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                                    <span *ngIf="complianceInformation!=null && complianceInformation?.paperwork" class="material-symbols-outlined text-grey">check_box_outline_blank </span>
                                    <div>No</div>
                                  </div>
                                </div>

                              </div>
                        </div>
                        <div class="col-md-4 col-4 col-sm-4">
                          <img
                          class="w-100"
                          [src]="worker.profilePic"
                          alt="logo"
                          onError="this.src='assets/images/Placeholder_500x500.jpg'"
                          />
                        </div>
                      </div>


                    </div>
                  </div>

              </section>



              <section>
                <div class="col-12">

                  <div class="  text-white mx-4 ">
                      <div class="row bg-main border radius-3 mb-2 py-2 px-4 w-100" style="border-radius: 5px;">
                        <div class="col-6">Trainings & Qualifications</div>
                        <!-- <div class="col-6 text-right pr-4">Expiry</div> -->

                      </div>
                    <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close">
                    <!-- <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
                      (click)="modal.dismiss()"> -->
                      <span aria-hidden="true">&times;</span>
                    </span>
                  </div>
                  <hr class="bg-black mt-0">

                  <div class="row form-group">
                    <table id="customers">
                      <tr >
                      <th>Name</th>
                      <!-- <th>Uploaded</th> -->
                      <th>EXPIRES</th>
                      <!-- <th class="text-center">Status</th> -->
                      <!-- <th class="text-center">Actions</th> -->
                      </tr >
                      <tr *ngFor="let training of trainings;" >
                      <td  *ngIf="training.status =='APPROVED' ">{{training.name}}</td>
                      <!-- <td>{{training.description}}</td> -->
                      <!-- <td>{{training.trainingDate}}</td> -->
                      <td  *ngIf="training.status =='APPROVED' ">{{training.trainingExpiry}}</td>

                       </tr>
                  </table>


                  </div>

                </div>

            </section>

            <section>

              <div class="  text-white mx-4 ">
                <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">Agency</h5>
                <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close">
                <!-- <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
                  (click)="modal.dismiss()"> -->
                  <span aria-hidden="true">&times;</span>
                </span>
              </div>
              <hr class="bg-black mt-0">

              <div class="row form-group"  *ngIf="userType!='client'">
                <div class="col col-md-6">
                    <label>Approved By</label><br>
                    <input type="text" maxlength="255"  formControlName="approver"   value="Yvonne Chingombe" class="form-control">
                </div>
                 
                <div class="col">
                  <label>Position</label><br>
                    <input type="text" maxlength="255"   formControlName="position"  value="Director of Operations" class="form-control">
                </div>         
              </div>

              <div class="row form-group"  *ngIf="userType!='client'">
                <div class="col col-md-6">
                  <div class="row">
                    <div class="col">
                      <label>Signed (initials)</label><br>
                      <input  type="text" formControlName="signed" value="" class="form-control"/>
                    </div>
                    <div class="col">
                      <label for="">Date</label>
                      <input  type="date" formControlName="signDate" class="form-control"/>
                    </div>
                  </div>
                 
              </div>
              
              <div class="col">
                <label for=""> Next Profile Check date</label>
                  <input  type="date" formControlName="nextCheckDate" class="form-control"/>         
              </div>
          </div>

              <div class="row form-group"  *ngIf="userType=='client'">
                <div class="col-md-6">
                  <label>Approved By</label><br>
                  <input type="text" maxlength="255" formControlName="approver"  disabled class="form-control">
                  <label>Position</label><br>
                  <input type="text" maxlength="255" formControlName="position" disabled class="form-control">
                </div>
                <div class="mt-1 col-sm-12 col-md-6 row mt-2">
                  <div class="col-md-12">
                    <label>Signed (initials)</label><br>
                    <input  type="text" disabled formControlName="signed" value="" class="form-control"/>
                    <input  type="date" disabled formControlName="signDate" class="form-control"/>
                  </div>
                </div>
              </div>

            </section>

            <section>

              <div class="  text-white mx-4 ">
                <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">COMMENTS</h5>
                <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close">
                <!-- <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
                  (click)="modal.dismiss()"> -->
                  <span aria-hidden="true">&times;</span>
                </span>
              </div>
              <hr class="bg-black mt-0">

              <div class="">

                <div class="row my-3"></div>
                <div class="col-md-12">
                    <textarea   *ngIf="userType!='client'" formControlName="comment" type="text" placeholder="Enter any comments." class="form-control"   ></textarea>
                    <textarea   *ngIf="userType=='client'"  formControlName="comment" type="text" disabled class="form-control"   ></textarea>
                </div>
              </div>

            </section>


            </div>
          </div>
          <div align="right" class="mt-3">
            <button type="button" class="btn btn-danger hideMePrint btn-sm mr-3" (click)="back()" >
              Cancel
            </button>
            <button type="button" onclick="window.print()" class="btn mr-3 btn-sm btn-success hideMePrint">
              Print
            </button>
            <button type="submit" *ngIf="userType!='client'&&'UPDATE_WORKER'|permission" class="btn hideMePrint btn-sm btn-success">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>


    <script>
      function maxLength(el) {
        if (!('maxLength' in el)) {
            var max = el.attributes.maxLength.value;
            el.onkeypress = function () {
                if (this.value.length >= max) return false;
            };
        }
      }

      maxLength(document.getElementById("text"));
    </script>

  </div>


</main>
