import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FileService } from 'src/app/shared/services/file.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { WorkerService } from 'src/app/shared/services/worker.service';

@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.css']
})
export class BankComponent implements OnInit {
  addBankForm: FormGroup;
  workerId: any;
  signedDoc: any;
  uploaded=false;
  bankingDetails: any;

  constructor(
    private toast: ToastrService,
    private workerService: WorkerService,
    private fb: FormBuilder,
    private fileService: FileService,
    private storageService: StorageService,

  ) {
    this.addBankForm =  this.fb.group({
        workerId: [],
        signDate: [],
        bank: [],
        name: [],
        code: [],
        account: [],
        account1: [],
        code1: [],
        account2: [],
        code2: [],
        account3: [],
        code3: [],
        signed: [],
        fullname: [],
    })
  }

  ngOnInit(): void {
    this.workerId = this.storageService.decrypt(localStorage.getItem('workerId'));

    this.getBank();

  }


  getBank(){
    this.workerService.getWorkerBank(this.workerId).subscribe(
      data => {
        this.bankingDetails = data;


        this.addBankForm = this.fb.group({
          workerId: [data?.workerId],
          signDate: [data?.signDate],
          bank: [data?.bank],
          name: [data?.name],
          code: [data?.code],
          account: [data?.account],

          account1: [data?.account.split("-")[0]],
          code1: [data?.code.split("-")[0]],
          account2: [data?.account.split("-")[1]],
          code2: [data?.code.split("-")[1]],
          account3: [data?.account.split("-")[2]],
          code3: [data?.code.split("-")[2]],

          signed: [data?.signed],
          fullname: [data?.fullname],
        });




        // this.addBankForm.value.firstname = this.worker?.firstname;
        // this.addBankForm.value.lastname = this.worker?.lastname;
        console.log(this.addBankForm.value);


      }
    );
  }


  addWorkerBank(form: FormGroup){
    // console.table(form.value);
    form.value.serviceId = 1;
    if(form.valid){
      form.value.workerId =this.workerId ;
      form.value.code =form.value.code1+"-"+form.value.code2+"-"+form.value.code3;
      form.value.account =form.value.account1+"-"+form.value.account2+"-"+form.value.account3;

      this.workerService.addWorkerBank(form.value).subscribe(
        resp => {
          this.getBank();
          this.toast.success('Worker Banking Details Updated Successfully');

        },
        err => {

          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
          else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.success('Worker Banking Details Updated Successfully');

            // this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  onChange(event) {
    this.signedDoc = event.target.files[0];
  }

  uploadSignature(){
    this.fileService.uploadBankSig(this.signedDoc, this.storageService.decrypt(localStorage.getItem('workerId'))).subscribe(
      event => {
        if(event instanceof HttpResponse) {

        };
        this.getBank();
        this.toast.info("Sucessfully uploaded.");

      },
      (err) => {





      }, () => {

      }
    );
  }


}
