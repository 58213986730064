import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WorkerDetailsComponent } from './worker-details/worker-details.component';
import { WorkersListComponent } from './workers-list/workers-list.component';
import { TrainingAndQualificationsComponent } from './training-and-qualifications/training-and-qualifications.component';
import { ApplicationComponent } from './worker-details/components/application/application.component';
import { OccupationalComponent } from './worker-details/components/occupational/occupational.component';
import { HmrcComponent } from './worker-details/components/hmrc/hmrc.component';
import { BankComponent } from './worker-details/components/bank/bank.component';

const routes: Routes = [
  { path: '', redirectTo: 'list', pathMatch: 'full' },
  { path: 'list', component: WorkersListComponent },
  { path: 'info/:id/:agencyId', component: WorkerDetailsComponent },
  { path: 'training-and-qualifications', component: TrainingAndQualificationsComponent },

  { path: 'application/:id', component: ApplicationComponent },
  { path: 'occupational/:id', component: OccupationalComponent },
  { path: 'hmrc/:id', component: HmrcComponent },
  { path: 'bank/:id', component: BankComponent },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkersRoutingModule { }
