import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AgencyRoutingModule } from './agency-routing.module';
import { AgencyContainerComponent } from './agency-container/agency-container.component';
import { AgencyHomeComponent } from './agency-home/agency-home.component';
import { ShiftsModule } from '../admin/shifts/shifts.module';
import { MatDialogModule } from '@angular/material/dialog';
import { PipesModule } from '../shared/pipes.module';
import { WorkerComplianceComponent } from './worker-compliance/worker-compliance.component';


@NgModule({
  declarations: [AgencyContainerComponent, AgencyHomeComponent, WorkerComplianceComponent],
  imports: [
    CommonModule,
    AgencyRoutingModule,
    SharedModule,
    MatDialogModule,
    PipesModule
  ]
})
export class AgencyModule { }
