<div class="">

  <div class="">
    <form [formGroup]="addHmrcForm" (ngSubmit)="addWorkerHmrc(addHmrcForm)" >
      <!-- <form [formGroup]="addForm" (ngSubmit)="newForm(addForm)"> -->
        <div class="bg-modal card-body">
          <div class="container-fluid">
          <div class="col-12 text-left">
            <p>Forms/ HMRC TAX Declaration</p>
          </div>
          <section>
              <div class="col-12">
                <!-- <i class="bi bi-info-circle"></i>
                <span class="ml-2 text-info-custom">You must complete all sections of the application form electronically. We will use this form to help us decide on your suitability for the post so please make sure it is accurate and complete. CVs in isolation will not be accepted.
                </span> -->

                <div class="row ">
                  <div class="col-md-6">
                    <img class="" style="width: 60px;" src="../../../../../assets/images/hmr.png" alt="hmr logo image" />
                    <h3>HM Revenue <br> & Customs</h3>
                  </div>
                  <div class="col-md-6 " style="    align-self: end;" >
                    <span class="mb-auto mt-4" ></span>
                    <h3 class="text-bold mt-4 text-right"  > Starter checklist</h3>
                  </div>
                </div>
                <hr class="bg-black mt-0">

                <h3>Instructions for employer</h3>
                <p>Use this starter checklist to gather information about your new employee if they do not have a P45. You can also
                  use this form if they have a student loan (whether or not they have a P45). Use the information to help fill in your first Full
                  Payment Submission (FPS) for this employee. If you have already submitted your first FPS, keep using the tax code in that
                  FPS until HMRC sends you a new tax code. If the employee gives you their P45 after the first FPS submission, use the tax
                  code shown in parts 2 and 3 of the P45. You must keep the information recorded on the starter checklist for the current
                  and next 3 tax years. This form is for your use only.
                </p>

                <h3>Instructions for employee</h3>
                <p>Fill in this form if you do not have a P45 (a document you get from your employer when you stop working for them).
                You should also fill in this form if you have a student loan (whether or not you’ve a P45). Give the completed form to your
                employer as soon as possible. They need this information to tell HMRC about you and help them to use the right tax code.
                Make sure you answer the questions correctly. If you do not, you may pay the wrong amount of tax or student loan deductions.
              </p>
              <hr class="bg-black mt-0">
              <h3>Employee’s personal details</h3>
              <div class="row">
                <div class=" col-md-6 m-0  form-group">
                  <div class="mt-1  ">
                    <Label>Last name</Label>
                    <input disabled type="text" formControlName="lastname" placeholder="" class="form-control">
                  </div>
                  <div class="mt-1  ">
                    <Label>First names</Label><br>
                    <small>Do not enter initials or shortened names for example,
                      Jim for James or Liz for Elizabeth</small>
                    <input disabled type="text" formControlName="firstname" placeholder="" class="form-control">
                    <!-- <input disabled type="text"  placeholder="" class="form-control"> -->
                  </div>

                  <div class="mt-1  ">
                    <Label>What is your sex?</Label><br>
                    <small>As shown on your birth certificate or gender recognition
                      certificate.</small>

              <div class="">
                <input disabled checked="checked" type="radio" formControlName="sex"  value="male"/>
                <label for="sex" > &nbsp;Male</label>&nbsp;&nbsp;&nbsp;
                <input disabled type="radio" formControlName="sex" value="female"/>
                <label for="sex"> &nbsp;Female</label><br/>
              </div>

                    </div>
                  <div class="mt-1 ">
                    <Label>Date of birth</Label>
                    <input disabled type="date"  placeholder=""  formControlName="dob" class="form-control">
                  </div>

                </div>
                <div class=" col-md-6 m-0  form-group">
                  <div class="mt-1  ">
                    <Label>Home address</Label>
                    <textarea disabled type="text"  placeholder="" formControlName="address" class="form-control"></textarea>
                  </div>
                  <div class="mt-1  ">
                    <Label>National insurance number (if known)</Label><br>

                    <input disabled type="text"  placeholder="" formControlName="insuarance" class="form-control">
                  </div>


                  <div class="mt-1 ">
                    <Label>Employment start date </Label>
                    <input disabled type="date"  placeholder="" formControlName="employmentStart" class="form-control">
                  </div>

                </div>
              </div>


              <hr class="bg-black my-3">
              <h3>Employee statement</h3>
              <p>These questions will help you to choose the statement that matches your circumstances. The statement you choose helps
                your employer to apply the correct tax code.</p>
              <div class="row">
                <div class=" col-md-6 m-0  form-group">
                  <div class="mt-1  ">
                    <Label>Do you have another job?</Label>
                    <div class="col-md-6">
                      <input disabled checked="checked"  type="radio" formControlName="otherJob" id="rd1" [value]="true"/>
                      <label for="otherJob" > &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                      <input disabled type="radio"  formControlName="otherJob" [value]="false"/>
                      <label for="otherJob">&nbsp;No</label><br/>
                    </div>
                  </div>
                  <div class="mt-1  ">
                    <Label>Do you receive payments from a State,
                      workplace or private pension?</Label>
                    <div class="col-md-6">
                      <input disabled checked="checked"  type="radio" formControlName="payments" id="rd1" [value]="true"/>
                      <label for="payments" > &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                      <input disabled type="radio"  formControlName="payments" [value]="false"/>
                      <label for="payments"> &nbsp;No</label><br/>
                    </div>
                  </div>

                </div>
                <div class=" col-md-6 m-0  form-group">
                  <div class="mt-1  ">
                    <Label>Since 6 April have you received payments from:</Label>
                    <ul>
                      <li>
                        another job which has ended or any of the following taxable benefits
                      </li>
                      <li>Jobseeker’s Allowance (JSA)</li>
                      <li>Employment and Support Allowance (ESA)</li>
                      <li>Incapacity Benefit</li>
                    </ul>
                    <div class="">

                      <input disabled checked="checked"  type="radio" formControlName="april" id="rd1" [value]="true"/>
                      <label for="april" > &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                      <input disabled type="radio"  formControlName="april" [value]="false"/>
                      <label for="april"> &nbsp;No</label><br/>
                    </div>
                    <small>For more information about tax codes, go to www.gov.uk/tax-codes</small>
                  </div>
                </div>
              </div>

              <table class="table table-striped mt-4 mb-0 styled-table text-center">
                <tr>
                  <td>
                    <div>
                      <p class="font-weight-bold">Statement A</p>
                      <input disabled type="radio" formControlName="statement"  value="A"/>
                      <p>Current personal allowance</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <p class="font-weight-bold">Statement B</p>
                      <input disabled type="radio" formControlName="statement"  value="B"/>
                      <p>Current personal allowance
                        on a Week 1/Month 1 basis</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <p class="font-weight-bold">Statement C</p>
                      <input disabled type="radio" formControlName="statement"  value="C"/>
                      <p>Tax Code BR</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="text-left">
                      <p class="font-weight-bold">Key</p>
                      <p>This is my first job since 6 April and
                        since the 6 April I have not received
                        payments from any of the following:</p>
                        <ul class="text-left">
                          <li>Jobseeker’s Allowance</li>
                          <li>Employment and Support Allowance</li>
                          <li> Incapacity Benefit</li>
                        </ul>

                    </div>
                  </td>
                  <td>
                    <div class="text-left">
                      <p class="font-weight-bold">Key</p>
                      <p>Since 6 April I have had another job
                        but I do not have a P45. And/or since
                        the 6 April I have received payments
                        from any of the following:
                        </p>
                        <ul class="text-left">
                          <li>Jobseeker’s Allowance</li>
                          <li>Employment and Support Allowance</li>
                          <li> Incapacity Benefit</li>
                        </ul>

                    </div>
                  </td>
                  <td>
                    <div class="text-left">
                      <p class="font-weight-bold">Key</p>
                      <p>I have another job and/or I am in receipt
                        of a State, workplace or private pension.</p>

                      </div>
                  </td>
                </tr>
              </table>
              <table class="table table-striped mb-0 styled-table text-center">
                <td>
                  <div class="text-left">
                    <p class="font-weight-bold">Key</p>
                    <p>Jobseeker’s Allowance (JSA) is an unemployment benefit which can be claimed while looking for work.
                      Employment and Support Allowance (ESA) is a benefit which can be claimed if you have a disability
                      or health condition that affects how much you can work.
                      Incapacity Benefit is help if you could not work because of an illness or disability before 31 January 2011.
                      State Pension is a pension paid when you reach State Pension age.
                      Workplace pension is a pension which was arranged by your employer and is being paid to you.
                      Private pension is a pension arranged by you and is being paid to you.
                      Please note that no other Government or HMRC paid benefits need to be considered when completing this form.</p>

                    </div>
                </td>
              </table>


              </div>

          </section>



          <section>
            <div class="col-12">
              <!-- <i class="bi bi-info-circle"></i>
              <span class="ml-2 text-info-custom">You must complete all sections of the application form electronically. We will use this form to help us decide on your suitability for the post so please make sure it is accurate and complete. CVs in isolation will not be accepted.
              </span> -->

              <hr class="bg-black my-4">

            <h3>Student Loans</h3>
            <div class="row">
              <div class=" col-md-6 m-0  form-group">
                <div class="mt-1  ">
                  <Label>Do you have a student or postgraduate loan?</Label>

                  <div class="">
                    <input disabled checked="checked" type="radio" formControlName="loan" [value]="true"/>
                    <label for="loan" > &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                    <input disabled type="radio" formControlName="loan" [value]="false"/>
                    <label for="loan"> &nbsp;No</label><br/>
                  </div>
                </div>
                <div class="mt-1  ">
                  <Label>Do any of the following statements apply:</Label><br>
                  <ul>
                    <li>you’re still studying on a course that your
                      student loan relates to</li>
                    <li>you completed or left your course after the
                      start of the current tax year, which started
                      on 6 April</li>
                    <li>you’ve already repaid your loan in full</li>
                    <li>you’re paying the Student Loans Company
                      by Direct Debit from your bank to manage
                      your end of loan repayments</li>
                  </ul>

                  <div class="">
                    <input disabled checked="checked" type="radio" formControlName="applyStatement" id="rd1" [value]="true"/>
                    <label for="applyStatement" > &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                    <input disabled type="radio"  formControlName="applyStatement" [value]="false"/>
                    <label for="applyStatement"> &nbsp;No</label><br/>
                  </div>
                </div>
                <div class="mt-1  ">
                  <Label> To avoid repaying more than you need to, tick the
                    correct student loan or loans that you have – use the
                    guidance on the right to help you.</Label><br>
                    <small>Please tick all that apply</small>
                  <div class="row">
                    <div class="col-6">Plan 1</div>
                    <div class="col-6 text-right">
                      <input disabled  type="radio" formControlName="plan" value="1"/>
                    </div>
                    <div class="col-6">Plan 2</div>
                    <div class="col-6 text-right">
                      <input disabled type="radio" formControlName="plan" value="2"/>
                    </div>
                    <div class="col-6">Plan 4</div>
                    <div class="col-6 text-right">
                      <input disabled type="radio" formControlName="plan" value="4"/>
                    </div>
                  </div>
                  <small>Postgraduate loan (England and Wales only)</small>
                </div>



              </div>
              <div class=" col-md-6 m-0  form-group">

                <p>Employees, for more information about the type of
                  loan you have or to check your balance, go to
                  www.gov.uk/sign-in-to-manage-your-student-loan-balance
                  Employers, for guidance on student loans and which plan or loan
                  type to use if your employee has selected more than one,
                  go to www.gov.uk/guidance/special-rules-for-student-loans</p>

                  <table class="table table-striped mb-0 styled-table text-center">
                    <tr>
                      <td class="text-left">
                        <p class="font-weight-bold">You have Plan 1 if any of the following apply:</p>

                          <ul class="text-left">
                            <li>you lived in Northern Ireland when you started
                              your course</li>
                            <li>you lived in England or Wales and started your course
                              before 1 September 2012</li>
                          </ul>

                        </td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <p class="font-weight-bold">You have Plan 2 if:</p>

                        <p>
                          You lived in England or Wales and started your course
                          on or after 1 September 2012
                        </p>

                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <p class="font-weight-bold">You have Plan 4 if:</p>

                        <p>
                          You lived in Scotland and applied through the
                          Students Award Agency Scotland (SAAS) when you started
                          your course.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <p class="font-weight-bold">You have a postgraduate loan if any of the
                          following apply:</p>

                          <ul class="text-left">
                            <li>you lived in England and started your postgraduate
                              master’s course on or after 1 August 2016</li>
                            <li>you lived in Wales and started your postgraduate
                              master’s course on or after 1 August 2017</li>
                            <li> you lived in England or Wales and started your
                              postgraduate doctoral course on or after 1 August 2018</li>
                          </ul>

                        </td>
                    </tr>
                  </table>



              </div>
            </div>


            </div>

        </section>



        <section>
          <div class="col-12">
          <hr class="bg-black mt-0">
          <h3>Declaration</h3>
          I confirm that the information I’ve given on this form is correct.
          <div class="row">
            <div class=" col-md-6 m-0  form-group">
              <div class="mt-1  ">
                <Label>Full name <small>Use capital letters</small> </Label><br>

                <input disabled type="text" formControlName="fullname" placeholder="" class="form-control">
              </div>


              <div class="mt-1 ">
                <Label>Date</Label>
                <input disabled type="date" formControlName="signDate" placeholder="" class="form-control">
              </div>

            </div>
            <div class=" col-md-12 m-0  form-group">

              <div class="mt-2 col-sm-12 col-md-6">
                <br>
                <!-- <label for="files" class="btn btn-success">Upload Signature</label> -->
                <!-- <a *ngIf="hmrcDetails?.signed" [href]="hmrcDetails?.signed" class="bold mx-2" >View File</a> -->

                <input disabled id="files"  style="visibility:hidden;" type="file" (change)="onChange($event); uploadSignature()">

              </div>

            </div>
          </div>

          </div>

      </section>


        </div>
      </div>



      <section>
        <div class="col-12">

          <div class="  text-white mx-4 ">
            <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">Agency Comment</h5>
          </div>
          <hr class="bg-black mt-0">

          <div class="row form-group mx-4">
            <div class="mt-1 col-sm-12 col-md-12">
              <textarea  maxlength="5000"  type="text" formControlName="comment"  placeholder="" class="form-control"></textarea>
              <small class="">Max 5000 characters</small>
            </div>
            <div align="right" class="mt-3">
              <button type="button"  (click)="addWorkerHmrc(addHmrcForm, 'reject')" class="btn btn-danger btn-sm mr-3"  >
                Reject
              </button>
              <button type="button"   (click)="addWorkerHmrc(addHmrcForm, 'accept')" class="btn btn-success btn-sm mr-3" >
                Approve
              </button>
            </div>

          </div>
        </div>

    </section>



    </form>
  </div>
</div>
