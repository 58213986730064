import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AssignmentCodeService } from 'src/app/shared/services/assignment-code.service';
import { ShiftService } from 'src/app/shared/services/shift.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-edit-shift',
  templateUrl: './edit-shift.component.html',
  styleUrls: ['./edit-shift.component.css']
})
export class EditShiftComponent implements OnInit {

  // startDate:any;

  startDate:any;
  endDate:any;

  shiftId: number;
  shift: any;
  types: any;
  assCodes: any;
  locations: any;
  directorates: any;

  editForm: FormGroup;
  searchCriteria: string = null;
  locationFilter: number = null;

  constructor(
    private activateRoute: ActivatedRoute,
    private toast: ToastrService,
    private shiftService: ShiftService,
    private fb: FormBuilder,
    private router: Router,
    private assignmentService: AssignmentCodeService,
    public datepipe: DatePipe
  ) { }

  ngOnInit(): void {

    this.getCodes();

    this.activateRoute.params.subscribe((params) => {
      this.shiftId = params["id"];

      this.getShift();
    });

    this.getTypes();
    this.getLocations();
    this.getDirectorates();



    this.editForm = this.fb.group({
      id:                         String,
      reply:                      String,
      shiftDate:                  String,
      shiftEndDate:               String,
      assignmentCode:               String,
      // shiftDirectorateId:String,
      shiftEndTime:               String,
      // shiftLocationId:            String,
      shiftStartTime:             String,
      // shiftStatus:                String,
      // shiftType:                  String,
      showNoteToAgency:           String,
      showNoteToFw:               String,
      noneAttendance: Boolean
    });
  }

  getTypes(){
    this.shiftService.getPaginatedShiftTypes(0, 100).subscribe(
      data => {
        // console.table(data.content);
        this.types = data.content;
      }
    );
  }

  getCodes(){
    this.assignmentService.getPaginatedAssignmentCodes(0, 100).subscribe(
      data => {
        // console.table(data);

        this.assCodes = data;
      }
    );
  }

  getShift(){
    this.shiftService.getShiftById(this.shiftId).subscribe(
      resp => {
        // console.log(resp);

        this.shift = resp;
        // console.log(this.shift);
        this.loadForm();
      }
    );
  }

  updateShift(form: FormGroup){
    console.log(form.value);
    form.patchValue({
      breakTime : form.value.breakHr+"hr "+form.value.breakMin+"mins"
    });

    if(form.valid){
      this.shiftService.updateShift(form.value).subscribe(
        resp => {
          console.table(resp);

          this.toast.success('Shift Updated Successfully');
          // this.router.navigate(['/client/shifts/list']);
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }
  }

  loadForm(){
    var d = this.shift.breakTime.split(' ');
    let hr = d[0].replace('hr', '');
    let mins = d[1].replace('mins', '');


    const myArray = this.shift.shiftDate.split("/");
    let latest_date =myArray[2] + "-" +myArray[1] +"-"+myArray[0] ;
    this.startDate=latest_date;

    const myArray1 = this.shift.shiftEndDate.split("/");
    let end_date = myArray1[2] + "-" +myArray1[1] +"-"+myArray1[0] ;
    this.endDate=end_date;
    console.log(this.endDate);



    if(this.shift){
      this.editForm = this.fb.group({
        id: [this.shift.id, Validators.required],
        // assignmentCode: [this.getAssCode(this.shift.assignmentCode), Validators.required],
        breakTime: [this.shift.breakTime, Validators.required],
        breakHr: [hr, Validators.required],
        breakMin: [mins, Validators.required],
        // gender: [this.shift.gender, Validators.required],
        // hoursBeforeBroadcasting: [this.shift.hoursBeforeBroadcasting, Validators.required],
        reply: [this.shift.reply],
        shiftDate: [this.startDate, Validators.required],
        shiftEndDate: [this.endDate, Validators.required],
        // shiftDirectorateId: [this.getDirectorateId(this.shift.directorate), Validators.required],
        shiftEndTime: [this.shift.shiftEndTime, Validators.required],
        // shiftLocationId: [this.getLocationId(this.shift.shiftLocation), Validators.required],
        shiftStartTime: [this.shift.shiftStartTime, Validators.required],
        // shiftStatus: [this.shift.shiftStatus, Validators.required],
        // shiftType: [this.shift.shiftType, Validators.required],
        showNoteToAgency: [this.shift.showNoteToAgency, Validators.required],
        showNoteToFw: [this.shift.showNoteToFw, Validators.required],
        noneAttendance: [false, Validators.required]
      });
    }
  }

  getTypeId(ref){
    let lid;

    if(ref){
      this.types.forEach(r => {
        if(r.name == ref){
          lid = r.id;
        }
      });
    }

    return lid;
  }

  getAssCode(ref){
    let lid;
    const codeArr = this.assCodes;
    if(ref){
      for (let index = 0; index < codeArr.length; index++) {
        const element = codeArr[index];
        codeArr.forEach(element => {
          if(element.code == ref){
            lid = element.id;
          }
        });
      }
    }
    return lid;
  }

  getLocations(){
    this.shiftService.getPaginatedShiftLocations(0, 100).subscribe(
      data => {
        // console.table(data);

        this.locations = data;
      }
    );
  }

  getDirectorates(){
    this.shiftService.getPaginatedShiftDirectorates(this.searchCriteria, this.locationFilter, 0, 100).subscribe(
      data => {
        // console.table(data.content);

        this.directorates = data.content;
      }
    );
  }

  getLocationId(ref){
    let lid;

    if(ref){
      this.locations?.forEach(r => {
        if(r.name == ref){
          lid = r.id;
        }
      });
    }

    return lid;
  }

  formatBreak(time){
    const myArray = time.split("hr");
    const myArray2 = myArray[1].split("mins");
    const myArray3 = [myArray[0], myArray[1]];
    console.log(myArray3);
  }

  getDirectorateId(ref){
    let lid;

    if(ref){
      this.directorates?.forEach(r => {
        if(r.name == ref){
          lid = r.id;
        }
      });
    }

    return lid;
  }

}
