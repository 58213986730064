import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  base_url: string = environment.API_url + '/user-service/api/v1/user-management';
  base_url2: string = environment.API_url + '/user-service/api/v1/user-permission';

  // base_url2: string = environment.API_url2 + ':8204/api/v1';
  // base_url: string = environment.API_url + '/user-manager-test/api/v1';


  constructor(
    private http: HttpClient
  ) { }

  resetPassword(email): Observable<any> {
    return this.http.post(`${this.base_url}/user/resetPassword?email=${email}`, null);
  }

  savePassword(data): Observable<any> {
    return this.http.post(`${this.base_url}/user/savePassword`, data);
  }

  changePassword(data): Observable<any> {
    return this.http.post(`${this.base_url}/user/change-password`, data);
  }

  getPaginatedAdminUsers(page, size): Observable<any> {
    return this.http.get(`${this.base_url}/users/admin/${page}/${size}`);
  }

  getPaginatedAgencyUsers(page, size, agency): Observable<any> {
    return this.http.get(`${this.base_url}/users/agent/${page}/${size}/${agency}`);
  }

  getPaginatedClientUsers(page, size, client): Observable<any> {
    return this.http.get(`${this.base_url}/users/client/${page}/${size}/${client}`);
  }

  getPaginatedWorkerUsers(page, size): Observable<any> {
    return this.http.get(`${this.base_url}/users/worker/${page}/${size}`);
  }

  createUser(data): Observable<any> {
    return this.http.post(`${this.base_url}/user/registration`, data);
  }

  createRole(data): Observable<any> {
    return this.http.post(`${this.base_url2}/role`, data);
  }

  enableUser(data): Observable<any> {
    return this.http.post(`${this.base_url}/enable-user`, data);
  }

  changeWorkerStatus(ref, enable): Observable<any> {
    return this.http.get(`${this.base_url}/users/activate-worker-users/${ref}?active=${enable}`);
  }

  changeClientStatus(ref, enable): Observable<any> {
    return this.http.get(`${this.base_url}/users/activate-client-users/${ref}?active=${enable}`);
  }

  changeAgentStatus(ref, enable): Observable<any> {
    return this.http.get(`${this.base_url}/users/activate-agent-users/${ref}?active=${enable}`);
  }

  // deActivateUser(ref): Observable<any> {
  //   return this.http.put(`${this.base_url}/user/deactivate/${ref}`, null);
  // }

  getUserById(ref): Observable<any> {
    return this.http.get(`${this.base_url}/users/${ref}`);
  }

  getAllPaginatedUsers(page, size): Observable<any> {
    return this.http.get(`${this.base_url}/users/${page}/${size}`);
  }

  // users permissions
  getAllRoles(): Observable<any> {
    return this.http.get(this.base_url + '/user/roles');
  }

  getAssignedPermissions(id): Observable<any> {
    return this.http.get(`${this.base_url}/user-permission/assigned/${id}`);
  }

  getUnassignedPermissions(id): Observable<any> {
    return this.http.get(`${this.base_url}/user-permission/un-assigned/${id}`);
  }

  assignPermission(data): Observable<any> {
    return this.http.post(`${this.base_url}/user-permission/assign-permissions`, data);
  }

  unassignPermission(data): Observable<any> {
    return this.http.post(`${this.base_url}/user-permission/un-assign-permissions`, data);
  }

}
