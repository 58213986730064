<main class="mt-5">
  <!-- Header part -->
  <div class="container-fluid">
    <div class="row text-center">
      <div class="col-12 col-sm-12 col-md-8 text-left">
        <h3>Secure Transport</h3>
      </div>
    </div>
  </div>

  <!-- content -->
  <section class="content mt-4">
    <div class="container-fluid mt-1 text-center">
      <div class="row mb-1 mx-0">
        <div class=" pointer col-sm-2 col-md-2" (click)="selectTab('NEW')"
          [ngClass]="{'bg-custom-danger': selectedTab == 'NEW','bg-custom-unselected': selectedTab != 'NEW'}" style="border-top-left-radius: 5px;">New
          <br><span  class="badge  btn-dark"  [ngClass]="{'badge-light': selectedTab == 'NEW'}" >{{shiftsSummary?.newShift!=0?shiftsSummary?.newShift:null}}</span>
        </div>
        <div class="col-sm-2 col-md-2 pointer" (click)="selectTab('WAITING_APPROVAL')"
        [ngClass]="{'bg-custom-danger': selectedTab == 'WAITING_APPROVAL','bg-custom-unselected': selectedTab != 'WAITING_APPROVAL'}">Completed
        <br><span class="badge btn-dark" [ngClass]="{'badge-light': selectedTab == 'WAITING_APPROVAL'}">{{shiftsSummary?.completed != 0 ? shiftsSummary?.completed : null}}</span>
      </div>
      <div class="col-sm-2 col-md-2 pointer" (click)="selectTab('APPROVED')"
        [ngClass]="{'bg-custom-danger': selectedTab == 'APPROVED','bg-custom-unselected': selectedTab != 'APPROVED'}" style="border-top-right-radius: 5px;">Closed
        <br><span class="badge btn-dark" [ngClass]="{'badge-light': selectedTab == 'APPROVED'}">{{shiftsSummary?.closed != 0 ? shiftsSummary?.closed : null}}</span>
      </div>
    </div>
        <div >
          <app-worker-new-transport
          [agencyFilter]  =    "agencyFilter"
          [directorateFilter]  =    "directorateFilter"
          [clientFilter]  =    "clientFilter"
          [startDateFilter]  = "startDateFilter"
          [endDateFilter]  = "endDateFilter"
          [status] = "selectedTab"
          ></app-worker-new-transport>
      </div>
    </div>
  </section>



</main>




