<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Upload Button</title>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
    <style>
        .upload-icon {
            font-size: 50px;
            cursor: pointer;
        }
        .file-input {
            display: none;
        }
    </style>
</head>

<body>
  
<div class="modal-container" *ngIf="isModalOpen">
  <div class="modal-content">
    <h3>Add new item to inventory</h3>
    <form (ngSubmit)="onSubmit()">
      
      <div class="row">

        <div class="col-6">
                  <div class="form-group">
                    <select id="itemSelect" [(ngModel)]="selectedItem" name="item" required>
                      <option value="" disabled>Select inventory item</option>
                      <option *ngFor="let item of items" [value]="item">{{ item }}</option>
                    </select>
                  </div>
                  <br>

                  
                <div class="form-group">
                  <input type="date" id="date" [(ngModel)]="selectedDate" name="date" required />
                </div>
                <br>

                <div class="form-group">
                  <input type="date" id="date" [(ngModel)]="selectedDate" name="date" required />
                </div>
                           
        </div>

          <div class="col-6">
            <label for="">Upload images of item</label>
            <br>
            <div class="row">
            <br>
            <br>
            <div class="col">

              <i class="fa-solid fa-upload upload-icon" onclick="document.getElementById('fileInput').click();"></i>
              <input type="file" id="fileInput" class="file-input">
  
            </div>
            <div class="col">
              <i class="fa-solid fa-upload upload-icon" onclick="document.getElementById('fileInput').click();"></i>
              <input type="file" id="fileInput" class="file-input">

            </div>
          
            </div>
            <br>
            <br>

            <div class="row">
              <br>
              <br>
              <i class="fa-solid fa-upload upload-icon" onclick="document.getElementById('fileInput').click();"></i>
              <input type="file" id="fileInput" class="file-input">
              
              <i class="fa-solid fa-upload upload-icon" onclick="document.getElementById('fileInput').click();"></i>
              <input type="file" id="fileInput" class="file-input">
  
            </div>
            
<!--             <input type="file" (change)="onFileSelected($event)">
 -->
          </div>
      </div>

      <div class="modal-actions">
        <button type="button" (click)="closeModal()" class="cancel-btn">Cancel</button>
        <button type="submit" class="add-btn">Add</button>
      </div>
    </form>
  </div>
</div>

<script>
  document.getElementById('fileInput').addEventListener('change', function(event) {
      const fileName = event.target.files[0].name;
      console.log('Selected file:', fileName);
  });
</script>
</body>
</html>