<!--Login Page Starts-->
<section class="vertical-center w-100 auth-box">
    <div class="container-fluid">
      <div class="row full-height-vh m-0">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="card">
            <div class="card-content">
              <div class="card-body p-0 m-0 border-0 bg-custom-beige">
                <div class="row m-0 p-0">
                  <div
                    class="col-lg-7 p-0 m-0 d-lg-block d-none text-center align-middle"
                  >
                    <!-- <img
                      src="../../assets/images/login.jpg"
                      alt=""
                      class="img-fluid"
                      width="400"
                      height="230"
                    /> -->
                    <img
                      src="../../assets/images/login.png"
                      alt=""
                      class="img-fluid"
                      width="790"
                      height="730"
                    />
                  </div>
                  <div class="col-lg-5 col-md-12 bg-white px-4 pt-3">
                    <div class="mb-3">
                      <img
                      class="w-50"
                      src="../../assets/images/logo.png"
                      alt="logo"
                    />
                    </div>
                    <h1 class="card-title mb-2 mt-5">Password Reset</h1>
                    <p class="card-text mb-5">
                        Please enter your email address and we'll <br> send you
                        instructions on how to reset <br> your password.
                    </p>

                    <form class="form" >
                      <input
                        type="email"
                        class="form-control mb-4"
                        placeholder="Recovery Email" id="myEmail"
                      />
                      <div class="fg-actions d-flex justify-content-between">
                        <div class="login-btn">

                        </div>
                        <div class="recover-pass">
                          <button type="button" (click)="sendEmail()" class="btn btn-sm bg-core-blue text-white">
                              Reset
                            </button>
                        </div>
                      </div>
                      <hr class="mt-3 mb-3" />
                      <div class="d-flex justify-content-between mt-5">
                        <div class="option-login">
                          <h6 class="text-decoration-none text-info-custom">
                            Or Go To Login Page
                          </h6>
                        </div>
                        <div class="social-login-options">
                            <button type="button" (click)="loginPage()" class="btn btn-sm bg-core-lime text-white">Login</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Login Page Ends-->
