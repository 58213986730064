<div class="">

  <div class="">
    <form  [formGroup]="addBankForm" (ngSubmit)="addWorkerBank(addBankForm)" >
      <!-- <form [formGroup]="addForm" (ngSubmit)="newForm(addForm)"> -->
        <div class="bg-modal card-body">
          <div class="container-fluid">
          <div class="col-12 text-left">
            <h3>Forms / Bank Details</h3>
          </div>
          <section>
              <div class="col-12">
                <!-- <i class="bi bi-info-circle"></i>
                <span class="ml-2 text-info-custom">You must complete all sections of the application form electronically. We will use this form to help us decide on your suitability for the post so please make sure it is accurate and complete. CVs in isolation will not be accepted.
                </span> -->
                <div class="  text-white mx-4 ">
                  <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
                    Bank Details</h5>
                  <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close">
                  <!-- <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
                    (click)="modal.dismiss()"> -->
                    <span aria-hidden="true">&times;</span>
                  </span>
                </div>
                <hr class="bg-black mt-0">

                <div class="row form-group">
                  <div class="mt-1 col-sm-12 col-md-6">
                    <Label>Bank</Label>
                    <input disabled type="text" formControlName="bank" placeholder="" class="form-control">
                  </div>
                  <div class="mt-1 col-sm-12 col-md-6">
                    <Label>Name on Account</Label>
                    <input disabled type="text" formControlName="name" placeholder="" class="form-control">
                  </div>

                  <div class="mt-1 col-sm-12 col-md-6">
                    <Label>Sort code</Label>
                    <input disabled type="text" formControlName="code" placeholder="" class="form-control">
                  </div>
                  <div class="mt-1 col-sm-12 col-md-6">
                    <Label>Account Number</Label>
                    <input disabled type="text" formControlName="account" placeholder="" class="form-control">
                  </div>

                  <!-- <div class="mt-1 col-12 col-sm-12 col-md-12">
                    <p>Upload signature below to authorise payment through this account.</p>
                  </div> -->

                  <!--div class="mt-1 col-sm-12 col-md-12">
                    <Label>Full Name</Label>
                    <input disabled type="text" formControlName="fullname" placeholder="" class="form-control">
                  </div-->

                  <!-- <div class="mt-1 col-sm-12 col-md-6">
                    <br>
                    <label for="files" class="btn btn-success">Upload Signature</label>
                    <a *ngIf="bankingDetails?.signed" [href]="bankingDetails?.signed" class="bold mx-2" >View File</a>
                    <input disabled id="files"  style="visibility:hidden;" type="file" (change)="onChange($event); uploadSignature()">
                  </div> -->

                <!--div class="mt-1 col-12 col-sm-12 col-md-6">
                  <Label>Date</Label>
                  <input disabled type="date" formControlName="signDate" placeholder="" class="form-control">
                </div-->



                </div>
              </div>

          </section>


        </div>
      </div>
<!--
      <section>
        <div class="col-12">

          <div class="  text-white mx-4 ">
            <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">Agency Comment</h5>
          </div>
          <hr class="bg-black mt-0">

          <div class="row form-group mx-4">
            <div class="mt-1 col-sm-12 col-md-12">
              <textarea  maxlength="5000"  type="text" formControlName="comment"  placeholder="" class="form-control"></textarea>
              <small class="">Max 5000 characters</small>
            </div>
            <div align="right" class="mt-3">
              <button type="button"  (click)="addWorkerBank(addBankForm, 'reject')" class="btn btn-danger btn-sm mr-3"  >
                Reject
              </button>
              <button type="button"   (click)="addWorkerBank(addBankForm, 'accept')" class="btn btn-success btn-sm mr-3" >
                Approve
              </button>
            </div>

          </div>
        </div>

    </section> -->


    </form>
  </div>
</div>
