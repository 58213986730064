import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { WorkerTransportListComponent } from './worker-transport-list/worker-transport-list.component';
import { NewTransportComponent } from './worker-transport-list/transport-table/worker-new-transport.component';
import { LogTransportTabsComponent } from './log-transport-job/log-transport-job1.component';
import { LogTransportJobComponent } from './view/log-transport-job.component';

const routes: Routes = [
  { path: 'log/:id', component: LogTransportTabsComponent },
  { path: 'logging/:id', component: LogTransportJobComponent },
  { path: '', component: WorkerTransportListComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule, MatIconModule]
})
export class WorkerTransportRoutingModule { }
