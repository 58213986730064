import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef } from '@angular/core';
import { StorageService } from 'src/app/shared/services/storage.service';
import { JobService } from 'src/app/shared/services/job.service';
import { LogService } from '../../../../../shared/services/log.service';

@Component({
  selector: 'app-logs-table',
  templateUrl: './logs-table.component.html',
  styleUrls: ['./logs-table.component.css']
})
export class LogsTableComponent implements OnChanges {
  @Input('agencyFilter') agencyFilter = '';
  @Input('transportStatus') transportStatus = '';
  @Input('workerFilter') workerFilter = '';
  @Input('clientFilter') clientFilter = '';
  @Input('startDateFilter') startDateFilter = '';
  @Input('endDateFilter') endDateFilter = '';
  @Input('searchCriteria') searchCriteria = '';
  @Output() updated = new EventEmitter<boolean>();


  userType: any;
  jobs: any[];

  page = 1;
  pageSize = 100;
  totalItems = 0;
  showin = 0;
  first: boolean;
  last: boolean;


  id: BigInteger;
  date: Date;
  Type: string;
  driver: string;
  authoriser: string;
  logs = [];
  selectedLog: any;

  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private storageService: StorageService,
    private logService: LogService
  ) {
    this.userType = authService.getUserType();
   }

  ngOnChanges(): void {
    console.log(this.transportStatus);
    this.logs = [];
    this.getLogs();
  }


  getLogs(){
    this.logService.getAgencyLogsByStatus(this.transportStatus,this.storageService.decrypt(localStorage.getItem('agentId')),this.page - 1, this.pageSize, null,null,null, null).subscribe(
      data => {
        this.totalItems = data.totalElements;
        this.first = data.first;
        this.last = data.last;
        if(this.page * this.pageSize > data.totalElements)
          this.showin = data.totalElements;
        else
          this.showin = this.page * this.pageSize;
        this.logs = data.content;
        console.log(this.logs);
      }
    );
  }



  handlePageChange(event) {

    if(event == 'next'){
      this.page += 1;
    }
    else if(event == 'prev' && this.page != 1){
      this.page -= 1;
    }

    this.getLogs();
  }


  openBigModal(modal){
    this.modalService.open(modal, {centered: true, size: 'xl'});
  }

  viewDriverInfo(log: any, modalContent: TemplateRef<any>): void {
    this.selectedLog = log;
    this.modalService.open(modalContent, { ariaLabelledBy: 'modal-basic-title', backdrop: true, centered: true });
  }

}
