import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TransportService } from 'src/app/shared/services/transport.service';
import { UsersService } from 'src/app/shared/services/users.service';

@Component({
  selector: 'app-log-transport-job1',
  templateUrl: './log-transport-job1.component.html'
})
export class LogTransportTabsComponent implements OnInit {
tabindex: number = 1;
logForm: FormGroup;
  transportId: any;
  job: any;


constructor(
  private toast: ToastrService,
  private usersService: UsersService,
  private storageService: StorageService,
  private transportService: TransportService,
  private fb: FormBuilder,
  private router: Router,
  private activateRoute: ActivatedRoute,
  private modalService: NgbModal
) {
  this.activateRoute.params.subscribe((params) => {
    this.transportId = params["id"];
    this.logForm = this.fb.group({
      id: [''],
      startMileage: [''],
      endMileage: [''],
      totalMileage: [''],
      damageDescriptions: [''],
      notes: [''],
      damageDoc: [''],
      feedback: [''],
      damageReport: [''],
      indicators: [''],
      drivingControls: [''],
      wheelcondition: [''],
      tyreInflation: [''],
      brakes: [''],
      windscreen: [''],
      mirrors: [''],
      speedometer: [''],
      battery: [''],
      fuel: [''],
      seatbelt: [''],
      doors: [''],
      oil: [''],
      engineCheckLight: [''],
      warningLight: [''],
      litter: [''],
      hardSurface: [''],
      seats: [''],
      equipment: [''],
      sanitizer: [''],



      lamp: [''],
      sideReapter: [''],
      markers: [''],
      warningDevices: [''],
      reflectors: [''],
      body: [''],
      warningdevices: [''],
      mirror: [''],
      stoplamp: [''],
      fluidLeaks: [''],
      coolant: [''],
      drivingcontrol: [''],
      horn: [''],
      wipers: [''],
      washers: [''],
      fluidleaks: [''],
      exhaust: [''],
      InstrumentalPanel: [''],
      adblue: [''],
      trailercoupling: [''],




      cellArea: [''],
      vehicleId: [''],
      transportId: [this.transportId],








    });
  });
}



  ngOnInit(): void {
    this.getJob();
  }

  getJob(){
    this.transportService.findById(this.transportId).subscribe(
      data => {
        this.job = data;
        this.logForm.patchValue(data.vehicleLog);
        console.log(this.logForm);
      }
    );
  }

  updateTab($event){
    this.tabindex  = $event;
    if(this.tabindex>3)this.tabindex =3;
  }

  updateForm($event){
    this.logForm  = $event;
    if(this.tabindex<1)this.tabindex =1;
  }
}
