import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'src/app/shared/services/storage.service';
import { WorkerService } from 'src/app/shared/services/worker.service';
import { ServicesService } from 'src/app/shared/services/services.service';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { HttpResponse } from '@angular/common/http';
import { FileService } from 'src/app/shared/services/file.service';
import { environment } from 'src/environments/environment';
import { TrainingService } from 'src/app/shared/services/training.service';

@Component({
  selector: 'app-trainings',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.css']
})

export class CertificateComponent implements OnInit {

  public userType: string;
  workerId: number;
  agencyId: number;
  worker: any;
  workerInfo: any;
  // workerProperty: any;
  jobCounts: any;
  trainings:any;
  trainingAll:any;
  jobs: any;
  fitToWork:any;
  // complianceInformation:any;
  notes:any;
  editInfoForm: FormGroup;
  addTrainingForm: FormGroup;
  editTrainingForm: FormGroup;
  addComplianceForm: FormGroup;
  updateTrainingForm: FormGroup;
  docLink = environment.API_url+"/worklink-api/tina/worklink/agency/"+localStorage.getItem('agentId')+"/compliance-2.png";
  updateComplianceForm: FormGroup;
  // complianceInformationForm: FormGroup;
  addNoteForm: FormGroup;
  addJobForm: FormGroup;
  edit = false;
  edit2 = false;
  editCompliance = false;
  assignmentCodes = [{id:1,name:'001 Cleaner'},{id:2,name:'002 Driver'},{id:1,name:'003 Nurse'}];
  paymentMethods = [{id:1,name:'Paye'},{id:2,name:'Umbrella'},{id:1,name:'Pvt. Ltd'}];
  services = [];
  activeTraining: any;
  complianceAll: any;
  compliances: any;
  trainingDoc: any;
  workerTrainingId: any;
  training: any;

  //trainingOptions = [{id:1,name:'Training 1'},{id:2,name:'Training 2'},{id:1,name:'Training 3'}];

  constructor(
    private activateRoute: ActivatedRoute,
    private trainingService: TrainingService,
    private modalService: NgbModal,
    private storageService: StorageService
  ){ }

  ngOnInit(): void {

    this.modalService.dismissAll();
    this.activateRoute.params.subscribe((params) => {
      this.workerTrainingId = params["id"]
    });
    this.workerId = this.storageService.decrypt(localStorage.getItem('workerId'));
    this.getTraining();



  }

 getTraining(){
    this.trainingService.getWorkerTraining(this.workerTrainingId).subscribe(
      data => {
        this.training = data;
      }
    );
  }

}
