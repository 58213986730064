import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TransportService } from 'src/app/shared/services/transport.service';

@Component({
  selector: 'app-leader-entry3',
  templateUrl: './leader-entry2.component.html'
})
export class LeaderEntry2Component implements OnChanges {
  @Input('form') form:FormGroup;
  @Input('tabindex') tabindex:number;
  @Input('job') job:FormGroup;
  @Output() formO = new EventEmitter<FormGroup>();
  @Output() tabindexO = new EventEmitter<number>();

  constructor(
    private toast: ToastrService,
    private transporService: TransportService,
    private storageService: StorageService,
    private agencyService: AgencyService,
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnChanges(): void {
    while(this.form.value.medicationList==null || this.form.value.medicationList.length<15){
      if(this.form.value.medicationList==null)
        this.form.patchValue({medicationList: []});
      else
      this.form.patchValue({medicationList: [...this.form.value.medicationList, ""]});
    }
  }

  replaceStringAtIndex(arr: string[], index: number, replacement: string): string[] {
    if (index >= 0 && index < arr.length) {
        arr[index] = replacement;
    }
    return arr;
  }

  updateItem(i, event){

    var arr = [];
    arr = this.form.value.medicationList;

    var arr2 = this.replaceStringAtIndex(arr,i,event.target.value);

    console.log(arr2,this.form.value.medicationList );

    this.form.patchValue({medicationList: arr2});
  }

  getValue(i){
    return this.form.value.medicationList[i];
  }

  nextTab(){
    ++this.tabindex
    if(this.tabindex>4)this.tabindex =4;
    this.tabindexO.emit(this.tabindex);
    this.formO.emit(this.form);
  }

  prevTab(){
    --this.tabindex;
    this.tabindexO.emit(this.tabindex);
    this.formO.emit(this.form);
  }

  updateTeamLeader(){
    this.transporService.updateTeamLeader(this.form.value).subscribe(
    data=> { this.toast.success("Info Medication List Updated")
    })
 }

}
