<main class="mt-5">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,1,0" />
    <!-- header part -->
    <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 " *ngIf="worker">
            <span class="text-decoration-none text-left" style="font-size: xx-large;"><b>{{worker.firstname}} {{worker.lastname}}</b>
            </span>
              <span *ngIf=" complianceInformation!=null && complianceInformation?.status== 'APPROVED' && userType!='client' && complianceInformation!=null && complianceInformation.status!=null" class="btn btn-success ml-4 text-right">Active</span>
              <span *ngIf="  complianceInformation!=null && complianceInformation?.status!= 'APPROVED' && userType!='client' && complianceInformation!=null && complianceInformation.status!=null" class="btn btn-danger ml-4 text-right">Inactive</span>

          </div>
          <div class="col-12 col-sm-6 col-md-4 text-md-right text-sm-left mt-2 mt-md-0">

          </div>
        </div>
    </div>

    <!-- content -->
    <section class="content mt-4">
        <div class="container-fluid">
            <div class="row" *ngIf="worker">
                <div class="col-md-4 col-lg-3">
                    <div class="card border-0">
                        <div class="text-center bg-main"  >
                            <img class="card-img-top card-img-bottom w-75  mt-2 mb-3" [src]="worker.profilePic" onError="this.src='assets/images/Placeholder_500x500.jpg'" alt="worker pic">
                            <h5 class="text-white text-bold bold">{{worker.firstname}} {{worker.lastname}}</h5>
                        </div>
                        <div class="w-100 ">
                          <h6 class="text-bold mt-2" style="color: #231E5C;">Contact Information</h6>
                          <section style="background-color: rgb(221, 221, 221);" class="p-1 pr-2">
                                <i class="fa fa-phone mt-2" ></i>&nbsp;&nbsp;{{worker.phoneNumber}} <br>
                                <div><i class="fa fa-envelope mt-2" style=" overflow-wrap: anywhere">&nbsp;&nbsp;<small class="text-bold" style="font-family: 'roboto_regular';">{{worker.email}}</small></i><span></span> </div>
                                <div  *ngIf="userType != 'client'"><img src="assets/images/svg/location.svg" alt="location icon" >&nbsp;&nbsp;&nbsp;{{worker.address}} <br></div>
                            </section>
                        </div>
                        <div class="w-100 ">
                          <h6 class="text-bold mt-2" style="color: #231E5C;">Personal Details</h6>
                          <section style="background-color: rgb(221, 221, 221);" class="p-1 pr-2">
                                <div><small class="mt-2 text-bold" >Employee Number </small>&nbsp;&nbsp; {{worker.employmentNumber}}</div>
                                <!-- <div *ngIf="userType != 'client'"><small  class="mt-2 text-bold" >Nationality </small>&nbsp;&nbsp; {{worker.nationality}}</div> -->
                                <!-- <div *ngIf="userType != 'client'"><small  class="mt-2 text-bold" >Date of Birth </small>&nbsp;&nbsp; {{worker.dob}}</div> -->
                                <div *ngIf="userType != 'client'"><small  class="mt-2 text-bold" >Date of Birth </small>&nbsp;&nbsp; {{worker.dob}}</div>
                                <div><small class="mt-2 text-bold" >Gender </small>&nbsp;&nbsp; {{worker.gender}}</div>
                          </section>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 col-lg-9">
                    <div class="card">

                        <div class="card-body">
                          <div class="tabs">

                            <input type="radio" name="tabs" id="tabone" checked="checked">
                            <label for="tabone">Overview</label>
                            <div class="tab">
                                <div class="row tab-content">
                                    <!--div class="col-sm-12 col-md-12 col py-2 tab-column">
                                      <form [formGroup]="editInfoForm" (ngSubmit)="updateAgencyWorkerInfo(editInfoForm)">
                                        < <input formControlName="id" type="hidden"> >

                                        <div class="tab-column-inner tab-column-inner-bottom">
                                            <div class="tab-title H2">
                                                <span><mat-icon>how_to_reg</mat-icon> &nbsp;&nbsp;Flexi-Worker Information</span>
                                                <div class="ml-auto " *ngIf="edit==false">
                                                  <button class="btn-success rounded-3" (click)="toggleEdit()" *ngIf="userType == 'agency'&&'UPDATE_WORKER'|permission">Edit</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-column-inner" *ngIf="edit==true">
                                            <div class="row  my-2">
                                              <div class="col-5">Payment Method</div>
                                              <div class="col-1">:</div>
                                              <div class="col-5">
                                                <select formControlName="paymentMethod" class="border border-primary rounded-3">
                                                  <option value="" selected disabled>Select</option>
                                                  <option >Paye</option>
                                                  <option >Umbrella</option>
                                                  <option value="PVT" >PVT Ltd</option>
                                                </select>
                                              </div>
                                            </div>
                                            <div class="row  my-2">
                                              <div class="col-5">Employment Start Date</div>
                                              <div class="col-1">:</div>
                                              <div class="col-5">
                                                  <input formControlName="employmentStartDate" class="border border-primary rounded-3" type="date">
                                              </div>
                                            </div>
                                            <div class="row  my-2">
                                              <div class="col-5">Contract End Date</div>
                                              <div class="col-1">:</div>
                                              <div class="col-5">
                                                <input formControlName="contractEndDate" class="border border-primary rounded-3" type="date">
                                              </div>
                                            </div>
                                            <div class="row  my-2">
                                              <div class="col-5">Next Check Date</div>
                                              <div class="col-1">:</div>
                                              <div class="col-5">
                                                <input formControlName="nextCheckDate" class="border border-primary rounded-3" type="date" >
                                              </div>
                                            </div>
                                            <div class="fw-btns d-flex my-2"  *ngIf="edit==true">
                                              <button class="btn btn-danger mx-2" (click)="toggleEdit()">Cancel</button>
                                              <button class="btn btn-success  mx-2">Update</button>
                                            </div>
                                        </div>
                                        <div class="tab-column-inner" *ngIf="userType=='client'">
                                            <div class="row  my-2">
                                              <div class="col-5">Assignment code</div>
                                              <div class="col-1">:</div>
                                              <div class="col-5">
                                                  {{worker.assignmentCode ?? ''}}
                                              </div>
                                            </div>
                                            <!-- <div class="row  my-2">
                                              <div class="col-5">DBS/Cert Number</div>
                                              <div class="col-1">:</div>
                                              <div class="col-5">
                                                  {{workerInfo?.dbsNumber ?? ''}}
                                              </div>
                                            </div> -->
                                            <!-- <div class="row  my-2">
                                              <div class="col-5">Gender</div>
                                              <div class="col-1">:</div>
                                              <div class="col-5">
                                                  {{worker.gender ?? ''}}
                                              </div>
                                            </div> >
                                        </div>
                                        <div class="tab-column-inner" *ngIf="edit==false && userType!='client'">

                                            <div class="row  my-2">
                                              <div class="col-5">Payment Method</div>
                                              <div class="col-1">:</div>
                                              <div class="col-5">
                                                {{complianceInformation!=null && complianceInformation?.paymentMethod ??  'Not Set'}}
                                              </div>
                                            </div>
                                            <div class="row  my-2">
                                              <div class="col-5">Employment Start Date</div>
                                              <div class="col-1">:</div>
                                              <div class="col-5">
                                                {{complianceInformation!=null && complianceInformation?.employmentStartDate ?? 'Not Known'}}
                                              </div>
                                            </div>
                                            <div class="row  my-2">
                                              <div class="col-5">Contract End Date</div>
                                              <div class="col-1">:</div>
                                              <div class="col-5">
                                                {{complianceInformation!=null && complianceInformation?.contractEndDate ??  'Not Set'}}
                                              </div>
                                            </div>
                                            <div class="row  my-2">
                                              <div class="col-5">Next Check Date</div>
                                              <div class="col-1">:</div>
                                              <div class="col-5">
                                                {{complianceInformation!=null && complianceInformation?.nextCheckDate ?? 'Not Set'}}
                                              </div>
                                            </div>
                                        </div>

                                      </form>
                                      <div *ngIf="userType=='null'"  class="tab-column-inner tab-column-inner-bottom">
                                        <div class="tab-title">
                                            <span> <mat-icon>fitness_center</mat-icon>&nbsp;&nbsp; Fitness Status</span>
                                        </div>
                                      </div>
                                      <div  *ngIf="userType=='null'" class="tab-column-inner ">
                                          <div class="tab-title d-flex center">
                                              Fit to work
                                              <div class="form-check mx-3 d-flex center">
                                                  <label class="form-check-label p-0">
                                                  <input type="radio" class="form-check-input" style="display:block" value="yes" (click)="toggleFitToWork('yes')" [checked]="fitToWork" name="fitToWork">
                                                      YES
                                                  </label>
                                              </div>
                                              <div class="form-check mx-3 d-flex center">
                                                  <label class="form-check-label p-0">
                                                  <input type="radio" class="form-check-input" style="display:block" value="no" (click)="toggleFitToWork('no')" [checked]="!fitToWork" name="fitToWork">
                                                      No
                                                  </label>
                                              </div>
                                              <button type="button" (click)="submitFitToWork()" class="btn btn-success mx-4"  > Update</button>
                                          </div>
                                      </div>
                                    </div-->

                                    <div class="col-sm-12 col-md-12 col " *ngIf="userType=='agency'">
                                      <div class=" py-2 tab-column">
                                        <div class="tab-column-inner tab-column-inner-bottom">
                                            <div class="tab-title">
                                                <span>Job Counter</span>
                                            </div>
                                        </div>
                                        <div class="tab-column-inner">
                                          <div class="">
                                            <div class="">
                                                <table id="customers">
                                                    <tr >
                                                    <td><b class="accent">Assignment Code</b></td>
                                                    <td><b class="accent">Jobs</b></td>
                                                    <!-- <td><b class="accent">Hrs</b></td> -->
                                                    </tr>
                                                    <tr *ngFor="let jobCount of jobCounts;">
                                                      <td>{{jobCount.asignmentCode}}</td>
                                                      <td>{{jobCount.jobs}}</td>
                                                      <!-- <td>{{jobCount.hrs}}</td> -->
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        </div>
                                      </div>


                                      <div class="row">
                                        <div class="col-sm-12 col-md-12 col my-auto text-primary-custom mr-2 pointer text-right">
                                          <a class="nav-link" routerLink="/agency/workers-print/info/{{workerId}}" routerLinkActive="active">
                                            <i class="fa fa-eye" title="view"></i>&nbsp;&nbsp;View Staff Profile
                                          </a>
                                        </div>

                                        <div class="my-3 div-6" *ngIf="complianceInformation!=null && complianceInformation.status==null&&'UPDATE_WORKER'|permission">
                                          <button type="button" class="btn btn-danger btn-sm mr-3" (click)="deactivateApplicant()">
                                            Deregister Applicant
                                          </button>
                                          <button type="button" class="btn btn-sm btn-success" (click)="activateApplicant()">
                                            Accept Applicant
                                          </button>
                                        </div>
                                        <div class="my-3 div-6" *ngIf="complianceInformation!=null && complianceInformation.status!=null&&'UPDATE_WORKER'|permission">
                                          <button type="button" class="btn btn-danger btn-sm mr-3" (click)="deactivateWorker()">
                                            Deactivate Worker
                                          </button>
                                          <button type="button" class="btn btn-sm btn-success" (click)="activateWorker()">
                                            Activate Worker
                                          </button>
                                        </div>


                                      </div>


                                    </div>
                                    <span class="col-sm-12 col-md-12 col  text-primary-custom mr-2 pointer" *ngIf="userType!='agency'" (click)="openModal(staffModal); getApplication()">
                                      <i class="fa fa-eye" title="view"></i>&nbsp;&nbsp;View Staff Profile
                                    </span>

                                </div>
                            </div>

                            <input *ngIf="'VIEW_TRAINING_QUALIFICATIONS'|permission"  type="radio" name="tabs" id="tabtwo">
                            <label *ngIf="'VIEW_TRAINING_QUALIFICATIONS'|permission"  for="tabtwo">Training & Qualifications</label>
                            <div *ngIf="'VIEW_TRAINING_QUALIFICATIONS'|permission" class="tab">
                                <div class="">
                                    <div class=" tab-column">
                                        <div class="tab-column-inner tab-column-inner-bottom">
                                            <div class="tab-title">
                                                <span><mat-icon>how_to_reg</mat-icon> &nbsp;&nbsp; Training & Qualifications</span>
                                                <button class="btn btn-success ml-auto" *ngIf="userType == 'agency'" (click)="openModal(addTrainingModal)">Add</button>

                                            </div>
                                        </div>
                                        <div class="tab-column-inner ">
                                            <div class="">
                                                <table id="customers">
                                                    <tr >
                                                    <th>Name</th>
                                                    <th>Uploaded</th>
                                                    <th>EXPIRES</th>
                                                    <th class="text-center">Status</th>
                                                    <th class="text-center">Actions</th>
                                                    </tr >
                                                    <tr *ngFor="let training of trainings;">
                                                    <td>{{training.name}}</td>
                                                    <!-- <td>{{training.description}}</td> -->
                                                    <td>{{training.trainingDate}}</td>
                                                    <td>{{training.trainingExpiry}}
                                                      <img *ngIf="checkExpiry(training.trainingExpiry) == 'alert'" style="width:15px"  title="About to expire" alt="äbout to expire icon" src="/assets/images/alert_caution.png">
                                                      <img *ngIf="checkExpiry(training.trainingExpiry) == 'expired'" style="width:15px"  title="Expired" alt="expired icon" src="/assets/images/alert_danger.png">

                                                    </td>
                                                    <td *ngIf="training.status =='REJECTED' && training.hascoId ==null "  class="text-center text-danger-custom" >
                                                      <mat-icon title="Rejected">error</mat-icon></td>
                                                    <td *ngIf="training.status !='REJECTED' && training.hascoId ==null && training.status !='APPROVED' "  class="text-center">
                                                      <span class="material-symbols-outlined" style="color:#eba82a">
                                                        schedule
                                                        </span>
                                                      </td>

                                                    <td *ngIf="training.status =='APPROVED' || training.hascoId !=null "  class="text-center">
                                                      <i class="fa fa-square" style="color: rgb(26, 216, 16);"></i>
                                                    </td>
                                                    <td class="text-center" (click)="openModal(view); selectedTraining(training)"><i class="fa fa-eye text-primary-custom"></i></td>
                                                    <!-- <td class="text-center" *ngIf="userType!='client'" (click)="openModal(view); selectedTraining(training)"><i class="fa fa-eye text-primary-custom"></i></td>
                                                    <td class="text-center" *ngIf="userType=='client'" ><i class="fa fa-eye text-primary-custom"></i></td> -->
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <input *ngIf="'VIEW_TRAINING_QUALIFICATIONS'|permission"  type="radio" name="tabs" id="tabthree">
                            <label *ngIf="'VIEW_TRAINING_QUALIFICATIONS'|permission"  for="tabthree" >Compliance</label>
                            <div *ngIf="'VIEW_TRAINING_QUALIFICATIONS'|permission" class="tab">
                              <div class="">
                                  <div class=" tab-column">
                                      <div class="tab-column-inner tab-column-inner-bottom">
                                          <div class="tab-title">
                                              <span><mat-icon>assignment</mat-icon> &nbsp;&nbsp; Compliance Information</span>
                                              <button class="btn btn-success ml-auto" *ngIf="userType == 'agency'" (click)="openModal(addCompliance)">Add</button>
                                              <!-- <button class="outline-btn rounded-3" *ngIf="userType != 'admin' && editCompliance==false" (click)="toggleEditCompliance()" >Edit</button> -->
                                          </div>
                                      </div>
                                      <div class="tab-column-inner ">
                                        <div class="">
                                            <table id="customers">
                                                <tr >
                                                <th>Document Name</th>
                                                <!-- <th>File</th> -->
                                                <th>Uploaded</th>
                                                <th>EXPIRES</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                                </tr >
                                                <tr *ngFor="let training of compliances;">
                                                  <td>{{training.name}}</td>
                                                  <!-- <td>Not Uploaded</td> -->
                                                  <td>{{training.complianceDate}}</td>
                                                  <td>{{training.complianceExpiry}}
                                                    <img *ngIf="checkExpiry(training.complianceExpiry) == 'alert'" style="width:15px"  title="About to expire" alt="about to expire icon" src="/assets/images/alert_caution.png">
                                                    <img *ngIf="checkExpiry(training.complianceExpiry) == 'expired'" style="width:15px"  title="Expired" alt="expired icon" src="/assets/images/alert_danger.png">

                                                  </td>
                                                  <td *ngIf="training.status =='REJECTED' "  class="text-center text-danger" ><mat-icon title="Rejected">error</mat-icon></td>
                                                  <td *ngIf="training.status !='REJECTED' && training.status !='APPROVED' "  class="text-center">
                                                    <span class="material-symbols-outlined" style="color:#eba82a">
                                                    schedule
                                                    </span></td>
                                                  <td *ngIf="training.status =='APPROVED' "  class="text-center">
                                                    <i class="fa fa-square" style="color: rgb(26, 216, 16);"></i>
                                                  </td>
                                                  <td *ngIf="userType!='client'" class="text-center" (click)="openModal(view2); selectedTraining(training)"><i class="fa fa-eye text-primary-custom"></i></td>
                                                  <td *ngIf="userType=='client'" class="text-center" ><i class="fa fa-eye text-primary-custom"></i></td>

                                                  </tr>
                                            </table>
                                        </div>
                                    </div>
                                  </div>
                              </div>
                            </div>





                            <input *ngIf="userType != 'client'&&'VIEW_TRAINING_QUALIFICATIONS'|permission"  type="radio" name="tabs" id="tabfour">
                            <label *ngIf="userType != 'client'&&'VIEW_TRAINING_QUALIFICATIONS'|permission"  for="tabfour" >Forms</label>
                            <div *ngIf="userType != 'client'&&'VIEW_TRAINING_QUALIFICATIONS'|permission"  class="tab">
                              <div class="">
                                  <div class=" tab-column">
                                      <div class="tab-column-inner tab-column-inner-bottom">
                                          <div class="tab-title">
                                              <span><mat-icon>assignment</mat-icon> &nbsp;&nbsp; Form Information</span>
                                              <!-- <button class="btn btn-success ml-auto" *ngIf="userType == 'agency'" (click)="openModal(addForm)">Add</button> -->
                                              <!-- <button class="outline-btn rounded-3" *ngIf="userType != 'admin' && editForm==false" (click)="toggleEditForm()" >Edit</button> -->
                                          </div>
                                      </div>
                                      <div class="tab-column-inner ">
                                        <div class="">
                                            <table id="customers">
                                                <tr >
                                                <th>Form</th>
                                                <th>Last Modified</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                                </tr >
                                                <tr >
                                                  <td>Application form</td>
                                                  <td>{{application?.lastModifiedDate}}</td>
                                                  <td *ngIf="application?.status =='REJECTED' "  class="text-center text-danger-custom" ><mat-icon title="Rejected">error</mat-icon></td>
                                                  <td *ngIf="application?.status !='REJECTED' && application?.status !='APPROVED' "  class="text-center"><span class="material-symbols-outlined" style="color:#eba82a">
                                                    schedule
                                                    </span></td>
                                                  <td *ngIf="application?.status =='APPROVED' "  class="text-center">
                                                    <i class="fa fa-square" style="color: rgb(26, 216, 16);"></i>

                                                  </td>

                                                  <td class="text-center text-primary-custom" routerLink="/agency/application/{{this.workerId}}" ><i class="fa fa-eye text-primary-custom"></i></td>
                                                  </tr>
                                                  <tr >
                                                    <td>Occupational Health Medical Questionnaire</td>
                                                    <td>{{occupationalDetails?.lastModifiedDate}}</td>


                                                    <td *ngIf="occupationalDetails?.status =='REJECTED' "  class="text-center text-danger-custom" ><mat-icon title="Rejected">error</mat-icon></td>
                                                    <td *ngIf="occupationalDetails?.status !='REJECTED' && occupationalDetails?.status !='APPROVED' "  class="text-center"><span class="material-symbols-outlined" style="color:#eba82a">
                                                    schedule
                                                    </span></td>
                                                    <td *ngIf="occupationalDetails?.status =='APPROVED' "  class="text-center">
                                                      <i class="fa fa-square" style="color: rgb(26, 216, 16);"></i>
                                                    </td>

                                                    <td class="text-center text-primary-custom" routerLink="/agency/occupational/{{this.workerId}}" ><i class="fa fa-eye text-primary-custom"></i></td>
                                                  </tr>
                                                  <tr >
                                                    <td>HMRC Tax Declaration</td>
                                                    <td>{{hmrcDetails?.lastModifiedDate}}</td>

                                                    <td *ngIf="hmrcDetails?.status =='REJECTED' "  class="text-center text-danger-custom" ><mat-icon title="Rejected">error</mat-icon></td>
                                                    <td *ngIf="hmrcDetails?.status !='REJECTED' && hmrcDetails?.status !='APPROVED' "  class="text-center"><span class="material-symbols-outlined" style="color:#eba82a">
                                                    schedule
                                                    </span></td>
                                                    <td *ngIf="hmrcDetails?.status =='APPROVED' "  class="text-center">
                                                      <i class="fa fa-square" style="color: rgb(26, 216, 16);"></i>
                                                    </td>
                                                    <td class="text-center text-primary-custom" routerLink="/agency/hmrc/{{this.workerId}}" ><i class="fa fa-eye text-primary-custom"></i></td>
                                                  </tr>
                                                  <tr >
                                                    <td>Bank Details</td>
                                                    <td>{{bankingDetails?.lastModifiedDate}}</td>
                                                    <td></td>
                                                    <td class="text-center text-primary-custom" routerLink="/agency/bank/{{this.workerId}}" ><i class="fa fa-eye text-primary-custom"></i></td>
                                                  </tr>


                                            </table>
                                        </div>
                                    </div>
                                  </div>
                              </div>
                            </div>



                            <div class="tab" *ngIf="edit2==true">
                                <div class="">
                                    <div class="tab-column">
                                        <div class="tab-column-inner tab-column-inner-bottom">
                                            <div class="tab-title">
                                                <span>Compliance Information</span>
                                                <button class="outline-btn" *ngIf="userType != 'admin'&&'UPDATE_WORKER'|permission" (click)="toggleEdit2()">Edit</button>
                                            </div>
                                        </div>
                                        <div class="tab-column-inner tab-content">
                                            <div class="  p-10">
                                                <div class="">
                                                    <span>Right to Work Status</span>
                                                    <span class="tab-input-before">:</span>
                                                    <span>
                                                        <div class="form-group">
                                                          <Select type="number"
                                                            class="form-control w-content" name="" id="" aria-describedby="helpId" >
                                                            <option value="">Temporary</option>
                                                            <option value="">Residency</option>
                                                            <option value="">Citizen</option>
                                                          </Select>
                                                        </div>
                                                    </span>
                                                </div>
                                                <div class="">
                                                    <span>DBS Number</span>
                                                    <span class="tab-input-before">:</span>
                                                    <span>
                                                        <div class="form-group">
                                                          <input type="text" maxlength="255"
                                                            class="form-control w-content" name="" id="" aria-describedby="helpId" placeholder="Null">
                                                        </div>
                                                    </span>
                                                </div>
                                                <div class="">
                                                    <span>DBS/PVG No</span>
                                                    <span class="tab-input-before">:</span>
                                                    <span>
                                                        <div class="form-group">
                                                          <input type="number"
                                                            class="form-control w-content" name="" id="" aria-describedby="helpId" placeholder="Null">
                                                        </div>
                                                    </span>
                                                </div>
                                                <div class="">
                                                    <span>Expiry Date</span>
                                                    <span class="tab-input-before">:</span>
                                                    <span>
                                                        <div class="form-group">
                                                          <input type="date"
                                                            class="form-control w-content" name="" id="" aria-describedby="helpId" >
                                                        </div>
                                                    </span>
                                                </div>
                                                <div class="">
                                                    <span>Occupational Health Checked</span>
                                                    <span class="tab-input-before">:</span>
                                                    <span>
                                                        <div class="form-group">
                                                          <input type="date"
                                                            class="form-control w-content" name="" id="" aria-describedby="helpId" >
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="  p-10">
                                                <div class="">
                                                    <span>Restrictions</span>
                                                    <span class="tab-input-before">:</span>
                                                    <span>
                                                        <div class="form-group">
                                                          <select type="number"
                                                            class="form-control w-content" name="" id="" aria-describedby="helpId" placeholder="Null">
                                                            <option>Student</option>
                                                            <option>Lorem Ipsum</option>
                                                            <option>Lorem Ipsum</option>
                                                          </select>
                                                        </div>
                                                    </span>
                                                </div>
                                                <div class="">
                                                    <span>Id Number</span>
                                                    <span class="tab-input-before">:</span>
                                                    <span>
                                                        <div class="form-group">
                                                          <input type="number"
                                                            class="form-control w-content" name="" id="" aria-describedby="helpId" placeholder="Null">
                                                        </div>
                                                    </span>
                                                </div>
                                                <div class="">
                                                    <span>Id Issue Date</span>
                                                    <span class="tab-input-before">:</span>
                                                    <span>
                                                        <div class="form-group">
                                                          <input type="date"
                                                            class="form-control w-content" name="" id="" aria-describedby="helpId" placeholder="Null">
                                                        </div>
                                                    </span>
                                                </div>
                                                <div class="">
                                                    <span>Id ExpiryDate</span>
                                                    <span class="tab-input-before">:</span>
                                                    <span>
                                                        <div class="form-group">
                                                          <input type="date"
                                                            class="form-control w-content" name="" id="" aria-describedby="helpId" placeholder="Null">
                                                        </div>
                                                    </span>
                                                </div>
                                                <div class="mt-3 ">
                                                  <div class="tab-title d-flex center">
                                                      Fit to work
                                                      <div class="form-check mx-3 d-flex center">
                                                          <label class="form-check-label p-0">
                                                          <input type="checkbox" class="form-check-input" name="" id="" value="checkedValue" checked>
                                                              YES
                                                          </label>
                                                      </div>
                                                      <div class="form-check mx-3 d-flex center">
                                                          <label class="form-check-label p-0">
                                                          <input type="checkbox" class="form-check-input" name="" id="" value="checkedValue" checked>
                                                              No
                                                          </label>
                                                      </div>
                                                      <button class="btn btn-danger mx-4" (click)="toggleEdit2()"> Cancel</button>
                                                      <button class="btn btn-success mx-4" *ngIf="userType != 'admin'"> Update</button>
                                                  </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <input type="radio" name="tabs" id="tabfour">
                            <label for="tabfour">Payroll</label>
                            <div class="tab">
                                <div class="">
                                    <div class=" tab-column">
                                        <div class="tab-column-inner tab-column-inner-bottom">
                                            <div class="tab-title">
                                                <span>Payslips</span>
                                            </div>
                                        </div>
                                        <div class="tab-column-inner ">
                                            <div class="">
                                                <table id="customers">
                                                    <tr>
                                                    <th>Company</th>
                                                    <th>Contact</th>
                                                    <th>Country</th>
                                                    </tr>
                                                    <tr>
                                                    <td>Alfreds Futterkiste</td>
                                                    <td>Maria Anders</td>
                                                    <td>Germany</td>
                                                    </tr>
                                                    <tr>
                                                    <td>Berglunds snabbköp</td>
                                                    <td>Christina Berglund</td>
                                                    <td>Sweden</td>
                                                    </tr>
                                                    <tr>
                                                    <td>Centro comercial Moctezuma</td>
                                                    <td>Francisco Chang</td>
                                                    <td>Mexico</td>
                                                    </tr>
                                                    <tr>
                                                    <td>Ernst Handel</td>
                                                    <td>Roland Mendel</td>
                                                    <td>Austria</td>
                                                    </tr>
                                                    <tr>
                                                    <td>Island Trading</td>
                                                    <td>Helen Bennett</td>
                                                    <td>UK</td>
                                                    </tr>
                                                    <tr>
                                                    <td>Königlich Essen</td>
                                                    <td>Philip Cramer</td>
                                                    <td>Germany</td>
                                                    </tr>
                                                    <tr>
                                                    <td>Laughing Bacchus Winecellars</td>
                                                    <td>Yoshi Tannamuri</td>
                                                    <td>Canada</td>
                                                    </tr>
                                                    <tr>
                                                    <td>Magazzini Alimentari Riuniti</td>
                                                    <td>Giovanni Rovelli</td>
                                                    <td>Italy</td>
                                                    </tr>
                                                    <tr>
                                                    <td>North/South</td>
                                                    <td>Simon Crowther</td>
                                                    <td>UK</td>
                                                    </tr>
                                                    <tr>
                                                    <td>Paris spécialités</td>
                                                    <td>Marie Bertrand</td>
                                                    <td>France</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                            <input type="radio" name="tabs" id="tabfive">
                            <!-- <label for="tabfive">Notes</label>
                            <div class="tab">
                                <div class="">
                                    <div class="tab-column">
                                        <div class="tab-column-inner tab-column-inner-bottom">
                                          <div class="tab-title">
                                            <span> <mat-icon>notes</mat-icon>&nbsp;&nbsp; Notes</span>
                                            <div class="ml-auto" *ngIf="edit==false">
                                              <button class="outline-btn rounded-3" *ngIf="userType == 'agency'" (click)="openModal(addNoteModel)">Add</button>

                                            </div>
                                          </div>
                                        </div>
                                        <div class="tab-column-inner">
                                            <div class="  p-10">
                                                <div class="">
                                                  <table id="customers">
                                                    <tr *ngFor="let note of notes;">
                                                      <td>{{note.note}}</td>
                                                    </tr>
                                                </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Add Training & Qualifications Modal -->
    <ng-template class="modal fade" #addTrainingModal let-modal>
      <app-worker-training-modal></app-worker-training-modal>
    </ng-template>




    <!-- Staff Profile -->
    <ng-template class="modal fade" #staffModal let-modal>
      <div class="" id="contentToConvert" >
       <link rel="stylesheet" href="https://test.myworklink.uk/assets/fontawesome-free/css/all.css">
       <link href="https://test.myworklink.uk/assets/fontawesome-free/css/fontawesome.css" rel="stylesheet">
       <link href="https://test.myworklink.uk//assets/fontawesome-free/css/brands.css" rel="stylesheet">
       <link href="https://test.myworklink.uk/assets/fontawesome-free/css/solid.css" rel="stylesheet">
       <link href="https://test.myworklink.uk/https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
       <link rel="stylesheet" href="https://test.myworklink.uk/assets/css/sb-admin-2.min.css">
       <link rel="stylesheet" href="https://test.myworklink.uk/assets/css/custom-styles.css">
        <div class="modal-header bg-main text-white">
          <h5 class="modal-title text-center mx-4 w-100">Recruitment Staff Profile</h5>
          <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </span>
        </div>
        <div class="modal-body">
          <div class="">

            <div class="">
              <form  [formGroup]="agencyReviewForm" (ngSubmit)="updateAgencyWorkerInfoReview(agencyReviewForm)"  >
                <!-- <form [formGroup]="addForm" (ngSubmit)="newForm(addForm)"> -->
                  <div class="bg-modal card-body">
                    <div class="container-fluid">
                    <div class="col-12 text-left">
                      <h3>Forms/ Staff Profile</h3>
                    </div>

                    <section>
                        <div class="col-12">
                          <!-- <i class="bi bi-info-circle"></i>
                          <span class="ml-2 text-info-custom">You must complete all sections of the application form electronically. We will use this form to help us decide on your suitability for the post so please make sure it is accurate and complete. CVs in isolation will not be accepted.
                          </span> -->
                          <div class="  text-white mx-4 ">
                            <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">Personal Details</h5>
                            <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close">
                            <!-- <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
                              (click)="modal.dismiss()"> -->
                              <span aria-hidden="true">&times;</span>
                            </span>
                          </div>
                          <hr class="bg-black mt-0">

                          <div class="row form-group ">
                            <div class="mt-1 col-sm-3 col-md-3">
                              <Label>Title</Label>
                              <select disabled class="form-control" >
                                <option value="Mr" >Mr</option>
                                <option value="Mrs" >Mrs</option>
                                <option value="Miss" >Miss</option>
                              </select>
                            </div>
                            <div class="mt-1 col-sm-9 col-md-9">
                              <Label>Candidate Name</Label>
                              <input type="text" maxlength="255"   disabled value="{{worker?.firstname}} {{worker?.lastname}}" class="form-control">
                            </div>

                            <div class="mt-1 col-sm-12 col-md-12">
                              <Label>Home address</Label>
                              <input type="text" maxlength="255"   disabled value="{{worker?.address}}" class="form-control">
                            </div>
                            <div class="mt-1 col-sm-12 col-md-6">
                              <Label>Email</Label>
                              <input type="text" maxlength="255"   disabled value="{{worker?.email}}" class="form-control">
                            </div>
                            <div class="mt-1 col-sm-12 col-md-6">
                              <Label>Contact Number</Label>
                              <input type="text" maxlength="255"   disabled value="{{worker?.phoneNumber}}" class="form-control">
                            </div>

                            <div class="mt-1 col-sm-12 col-md-6">
                              <Label>Date of birth</Label>
                              <input type="date" class="date" disabled  value="1985-10-24" class="form-control date">
                            </div>
                            <div class="mt-1 col-sm-12 col-md-6">
                              <Label>DBS Number</Label>
                              <input type="text" maxlength="255" class="date" disabled  [value]="application?.dbsNumber" class="form-control date">
                            </div>

                            <p class="font-weight-bold col-12 mt-4 mb-0">DBS Information</p>

                            <div class="col-sm-12 col-md-3  ">
                              <!-- <Label>Date of birth</Label>
                              <input type="date" class="date"  class="form-control date"> -->
                              Basic DBS Check
                              <div *ngIf="application?.dbsType=='basic'" class="col-4">
                                <span class="material-symbols-outlined">priority</span>
                              </div>
                            </div>

                            <div class="col-sm-12 col-md-3 row">
                              <div class="col-8">STANDARD DBS Check</div>
                              <div *ngIf="application?.dbsType=='standard'" class="col-4">
                                <span class="material-symbols-outlined">priority</span>
                              </div>
                            </div>

                            <div class="mt-1 col-sm-12 col-md-3">
                              <!-- <Label>Date of birth</Label>
                              <input type="date" class="date"  class="form-control date"> -->
                              ENHANCED DBS Check
                              <div *ngIf="application?.dbsType=='enhanced'" class="col-4">
                                <span class="material-symbols-outlined">priority</span>
                              </div>
                            </div>

                            <div class="mt-1 col-sm-12 col-md-3">
                              <!-- <Label>Date of birth</Label>
                              <input type="date" class="date"  class="form-control date"> -->
                              Enhanced with Barred List(s) DBS check
                              <div *ngIf="application?.dbsType=='enhanced1'" class="col-4">
                                <span class="material-symbols-outlined">priority</span>
                              </div>
                            </div>

                          </div>
                          <div class="mt-1 col-sm-12 mb-2 col-md-6">
                            <Label>Date of last DBS check by agency</Label>
                            <input type="date" class="date" disabled formControlName="signDate" class="form-control date">
                          </div>

                        </div>

                    </section>

                    <section>
                        <div class="col-12">

                          <div class="  text-white mx-4 ">
                            <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close">
                            <!-- <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
                              (click)="modal.dismiss()"> -->
                              <span aria-hidden="true">&times;</span>
                            </span>
                          </div>
                          <hr class="bg-black mt-0">

                          <div class="row form-group">

                            <div class="mt-1 col-sm-12 col-md-6">
                              <Label>Cautions / Convictions</Label>
                              <input *ngIf="!application?.offenses" type="text" disabled  value="None" class="form-control">
                              <input *ngIf="application?.offenses" type="text" disabled  [value]="application?.offensesRes" class="form-control">
                            </div>


                            <div class="mt-1 col-sm-12 col-md-6">
                              <Label>NMC PIN Number</Label>
                              <input type="text" maxlength="255" disabled  value="N/A" class="form-control">
                            </div>
                            <div class="mt-1 col-sm-12 col-md-6">
                              <div class="col-md-12">I confirm that I am entitled to work in the uk </div>
                              <div class="col-md-12 d-flex">
                                <span *ngIf="application?.ukWork" class="material-symbols-outlined">priority</span>
                                <span *ngIf="!application?.ukWork" class="material-symbols-outlined text-grey">check_box_outline_blank </span>
                                <div>Yes</div>&nbsp;&nbsp;&nbsp;
                                <span *ngIf="!application?.ukWork" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                                <span *ngIf="application?.ukWork" class="material-symbols-outlined text-grey">check_box_outline_blank </span>
                                <div>No</div>
                              </div>
                            </div>
                            <div class="mt-1 col-sm-12 col-md-6">
                              <Label>Hep B if applicable</Label>
                              <div class="col-md-12 row">
                                <div class="col-md-6 d-flex">
                                  <span class="material-symbols-outlined">priority</span>
                                  <div>Yes</div>&nbsp;&nbsp;&nbsp;
                                  <span class="material-symbols-outlined text-grey">check_box_outline_blank </span>
                                  <div>No</div>
                                </div>
                                <div class="col-md-6">
                                  <input type="text" maxlength="255" disabled  class="form-control">
                                </div>

                               </div>

                            </div>
                            <div class="mt-1 col-sm-12 col-md-6">
                              <Label>Job Role to be Undertaked</Label>
                              <select type="text"  disabled  class="form-control">
                                <option value="">Support Worker</option>
                                </select>
                            </div>
                          </div>
                        </div>

                    </section>

                    <section>
                        <div class="col-12">

                          <div class="  text-white mx-4 ">

                            <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close">
                            <!-- <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
                              (click)="modal.dismiss()"> -->
                              <span aria-hidden="true">&times;</span>
                            </span>
                          </div>
                          <hr class="bg-black mt-0">

                          <div class="row form-group">





                            <div class="row col-12 my-3">
                              <div class="col-md-8">

                            <div *ngIf="userType!='client'" class="mt-1 col-sm-12 ">
                              <div >Staff eligible to work in the UK</div>

                              <div  class="d-flex">
                                <input  type="radio"  formControlName="eligible"  id="worker" [value]="true"/>
                                <label for="worker453"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                                <input type="radio"  formControlName="eligible"  id="worker" [value]="false"/>
                                <label for="worker453"> &nbsp;No</label><br/>
                              </div>

                            </div>

                            <div *ngIf="userType=='client'" class="mt-1 col-sm-12">
                              <div >Staff eligible to work in the UK</div>

                              <div class=" d-flex">
                                <span class="material-symbols-outlined">priority</span>
                                <div>Yes</div>&nbsp;&nbsp;&nbsp;
                                <span class="material-symbols-outlined text-grey">check_box_outline_blank </span>
                                <div>No</div>
                              </div>

                            </div>


                            <div  *ngIf="userType!='client'" class="mt-1 col-sm-12 col-md-12">
                              <Label>Proof provided</Label>
                              <select type="text" formControlName="proof" disabled class="form-control">
                                <option value="brp">BRP</option>
                                <option value="pass">Passport</option>
                                <option value="hof">Home Office Letter</option>
                              </select>
                            </div>

                            <div  *ngIf="userType=='client'" class="mt-1 col-sm-12 col-md-12">
                              <Label>Proof provided</Label>
                              <select type="text" formControlName="proof" disabled class="form-control">
                                <option value="brp">BRP</option>
                                <option value="pass">Passport</option>
                                <option value="hof">Home Office Letter</option>
                              </select>
                            </div>

                                <div *ngIf="userType!='client'" class="mt-1 col-sm-12 col-md-12">
                                  <Label>Visa Type</Label>
                                  <select formControlName="visa"  class="form-control" >
                                    <option value="ilr">ILR (Indefinite Leave to Remain)</option>
                                    <option value="t2">Tier 2 Visa</option>
                                    <option value="st">Student Visa</option>
                                    <option value="pr">PR/Citizen</option>
                                  </select>
                                </div>
                                <div *ngIf="userType=='client'" class="mt-1 col-sm-12 col-md-12">
                                  <Label>Visa Type</Label>
                                  <select formControlName="visa" disabled class="form-control" >
                                    <option value="ilr">ILR (Indefinite Leave to Remain)</option>
                                    <option value="t2">Tier 2 Visa</option>
                                    <option value="st">Student Visa</option>
                                    <option value="pr">PR/Citizen</option>
                                  </select>
                                </div>
                                <div class="mt-1 col-sm-12 col-md-12"  *ngIf="userType!='client'">
                                  <Label>Visa expiry date(if applicable)</Label>
                                  <input type="date" formControlName="visaExpiry" class="form-control">
                                </div>
                                <div class="mt-1 col-sm-12 col-md-12"  *ngIf="userType=='client'">
                                  <Label>Visa expiry date(if applicable)</Label>
                                  <input type="date" formControlName="visaExpiry" disabled class="form-control">
                                </div>
                                <div class="mt-1 col-sm-12 col-md-12 row ">
                                  <div class="col-md-9">
                                    Driving Licence/Insurance (if applicable for role)
                                  </div>


                                  <div class="col-md-12 d-flex">
                                    <span *ngIf="application?.licence" class="material-symbols-outlined">priority</span>
                                    <span *ngIf="!application?.licence" class="material-symbols-outlined text-grey">check_box_outline_blank </span>
                                    <div>Yes</div>&nbsp;&nbsp;&nbsp;
                                    <span *ngIf="!application?.licence" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                                    <span *ngIf="application?.licence" class="material-symbols-outlined text-grey">check_box_outline_blank </span>
                                    <div>No</div>
                                  </div>

                                </div>
                                <div class="mt-1 col-sm-12 col-md-12">
                                  <Label>References received</Label>
                                  <input *ngFor="let n of application?.references" type="text" disabled  [value]="n.name" class="form-control">
                                </div>
                                <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                                  <div class="col-md-9">
                                    All required paperwork checked
                                  </div>

                                  <div class="col-md-3"  *ngIf="userType!='client'">
                                    <input checked="checked" type="radio" formControlName="paperwork" id="rd1" [value]="true"/>
                                    <label for="rd1"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                                    <input type="radio" formControlName="paperwork" id="rd2" [value]="false"/>
                                    <label for="rd2"> &nbsp;No</label><br/>
                                  </div>
                                  <div class="col-md-3"  *ngIf="userType=='client'">
                                    <div class="col-md-12 d-flex">
                                      <span *ngIf="complianceInformation!=null && complianceInformation?.paperwork" class="material-symbols-outlined">priority</span>
                                      <span *ngIf="!complianceInformation!=null && complianceInformation?.paperwork" class="material-symbols-outlined text-grey">check_box_outline_blank </span>
                                      <div>Yes</div>&nbsp;&nbsp;&nbsp;
                                      <span *ngIf="!complianceInformation!=null && complianceInformation?.paperwork" class="text-danger-custom material-symbols-outlined">disabled_by_default</span>
                                      <span *ngIf="complianceInformation!=null && complianceInformation?.paperwork" class="material-symbols-outlined text-grey">check_box_outline_blank </span>
                                      <div>No</div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <div class="col-md-4">
                                <img
                                class="w-100"
                                [src]="worker.profilePic"
                                alt="logo"
                                onError="this.src='assets/images/Placeholder_500x500.jpg'"
                                />
                              </div>
                            </div>


                          </div>
                        </div>

                    </section>



                    <section>
                      <div class="col-12">

                        <div class="  text-white mx-4 ">
                            <div class="row bg-main border radius-3 mb-2 py-2 px-4 w-100" style="border-radius: 5px;">
                              <div class="col-6">Trainings & Qualifications</div>
                              <!-- <div class="col-6 text-right pr-4">Expiry</div> -->

                            </div>
                          <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close">
                          <!-- <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
                            (click)="modal.dismiss()"> -->
                            <span aria-hidden="true">&times;</span>
                          </span>
                        </div>
                        <hr class="bg-black mt-0">

                        <div class="row form-group">
                          <table id="customers">
                            <tr >
                            <th>Name</th>
                            <!-- <th>Uploaded</th> -->
                            <th>EXPIRES</th>
                            <!-- <th class="text-center">Status</th> -->
                            <!-- <th class="text-center">Actions</th> -->
                            </tr >
                            <tr *ngFor="let training of trainings;" >
                            <td  *ngIf="training.status =='APPROVED' ">{{training.name}}</td>
                            <!-- <td>{{training.description}}</td> -->
                            <!-- <td>{{training.trainingDate}}</td> -->
                            <td  *ngIf="training.status =='APPROVED' ">{{training.trainingExpiry}}</td>

                             </tr>
                        </table>


                        </div>

                      </div>

                  </section>

                  <section>

                    <div class="  text-white mx-4 ">
                      <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">Agency</h5>
                      <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close">
                      <!-- <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
                        (click)="modal.dismiss()"> -->
                        <span aria-hidden="true">&times;</span>
                      </span>
                    </div>
                    <hr class="bg-black mt-0">

                    <div class="row form-group"  *ngIf="userType!='client'">
                      <div class="col-md-6">
                          <label>Approved By</label><br>
                          <input type="text" maxlength="255"  formControlName="approver"   value="Yvonne Chingombe" class="form-control">
                          <label>Position</label><br>
                          <input type="text" maxlength="255"   formControlName="position"  value="Director of Operations" class="form-control">
                        </div>
                      <div class="mt-1 col-sm-12 col-md-6 row mt-2">
                        <div class="col-md-12">
                          <label>Signed (initials)</label><br>
                          <input  type="text" formControlName="signed" value="" class="form-control"/>
                          <input  type="date" formControlName="signDate" class="form-control"/>
                        </div>
                      </div>
                    </div>
                    <div class="row form-group"  *ngIf="userType=='client'">
                      <div class="col-md-6">
                        <label>Approved By</label><br>
                        <input type="text" maxlength="255" formControlName="approver"  disabled class="form-control">
                        <label>Position</label><br>
                        <input type="text" maxlength="255" formControlName="position" disabled class="form-control">
                      </div>
                      <div class="mt-1 col-sm-12 col-md-6 row mt-2">
                        <div class="col-md-12">
                          <label>Signed (initials)</label><br>
                          <input  type="text" disabled formControlName="signed" value="" class="form-control"/>
                          <input  type="date" disabled formControlName="signDate" class="form-control"/>
                        </div>
                      </div>
                    </div>

                  </section>

                  <section>

                    <div class="  text-white mx-4 ">
                      <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">COMMENTS</h5>
                      <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close">
                      <!-- <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
                        (click)="modal.dismiss()"> -->
                        <span aria-hidden="true">&times;</span>
                      </span>
                    </div>
                    <hr class="bg-black mt-0">

                    <div class="">

                      <div class="row my-3"></div>
                      <div class="col-md-12">
                          <textarea   *ngIf="userType!='client'" formControlName="comment" type="text" placeholder="Enter any comments." class="form-control"   ></textarea>
                          <textarea   *ngIf="userType=='client'"  formControlName="comment" type="text" disabled class="form-control"   ></textarea>
                      </div>
                    </div>

                  </section>


                  </div>
                </div>
                <div align="right" class="mt-3">
                  <button type="button" class="btn btn-danger btn-sm mr-3" (click)="closeModals()" >
                    Cancel
                  </button>

                  <button type="submit" *ngIf="userType!='client'&&'UPDATE_WORKER'|permission" class="btn btn-sm btn-success">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>


          <script>
            function maxLength(el) {
              if (!('maxLength' in el)) {
                  var max = el.attributes.maxLength.value;
                  el.onkeypress = function () {
                      if (this.value.length >= max) return false;
                  };
              }
            }

            maxLength(document.getElementById("text"));
          </script>

        </div>
      </div>
      <button type="button" (click)="printDiv()" class="btn mr-3 btn-sm btn-success">
        Print
      </button>
    </ng-template>


  <!-- Add Compliance & Qualifications Modal -->
  <ng-template class="modal fade" #addCompliance let-modal>
    <app-worker-compliance-modal></app-worker-compliance-modal>
  </ng-template>

      <!-- Add Note Modal -->
      <ng-template class="modal fade" #addNoteModel let-modal>
        <div class="">
          <div class="modal-header bg-main text-white">
            <h5 class="modal-title text-center mx-4 w-100">Add NOTE</h5>
            <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
              (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </span>
          </div>
          <div class="modal-body">
            <form [formGroup]="addNoteForm" (ngSubmit)="addNote(addNoteForm)">
              <div class="bg-modal card-body">
                <div class="container-fluid">
                  <div class="">
                    <div class="">
                      <span class="ml-2">NOTE</span>
                      <hr class="bg-black mt-0">
                    </div>
                    <div class="">
                      <div class="form-group">
                        <input formControlName="note" class="border border-primary rounded-3" type="text">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div align="right" class="mt-3">
                <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
                  Cancel
                </button>
                <button type="submit" class="btn btn-sm btn-success">
                  Add NOTE
                </button>
              </div>
            </form>
          </div>
        </div>
      </ng-template>

       <!-- Add Jobs Modal -->
      <ng-template class="modal fade" #addJobModel let-modal>
        <div class="">
          <div class="modal-header bg-main text-white">
            <h5 class="modal-title text-center mx-4 w-100">Add Job</h5>
            <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
              (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </span>
          </div>
          <div class="modal-body">
            <form [formGroup]="addJobForm" (ngSubmit)="addJob(addJobForm)">
              <div class="bg-modal card-body">
                <div class="container-fluid">
                  <div class="">
                    <div class="">
                      <div class="form-group">
                        <select formControlName="assignmentCodeId" class="form-control">
                          <option value="" disabled>Select Assignment Code</option>
                          <option *ngFor="let assignmentCode of assignmentCodes;" value="{{assignmentCode.id}}">{{assignmentCode.name}}</option>
                        </select>
                      </div>
                      <div class="form-group">
                        <select formControlName="paymentMethodId" class="form-control">
                          <option value="" disabled>Select Payment Method</option>
                          <option *ngFor="let paymentMethod of paymentMethods;" value="{{paymentMethod.id}}">{{paymentMethod.name}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div align="right" class="mt-3">
                <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
                  Cancel
                </button>
                <button type="submit" class="btn btn-sm btn-success">
                  Add Job
                </button>
              </div>
            </form>
          </div>
        </div>
      </ng-template>
</main>


<!-- Modals -->
<ng-template class="modal fade" #view let-modal>
  <div class="">
    <div class="modal-header bg-main text-white">
      <h5 class="modal-title text-center w-100">View DOCUMENT</h5>
      <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
        (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </span>
    </div>
    <div class="modal-body">
      <form [formGroup]="updateTrainingForm">
        <div class="bg-modal card-body">
          <div class="container-fluid">
            <section>
              <div *ngIf="activeTraining.type=='EXTERNAL'" class="col-12">
                <i class="bi bi-info-circle"></i>
                <!-- <a *ngIf="activeTraining.document" [href]="activeTraining.document" target="_blank"> Click To View Document</a> -->
                <a *ngIf="activeTraining.document" (click)="openDoc(activeTraining.document)" target="_blank"> Click To View Document</a>
                <p *ngIf="!activeTraining.document"  >Document Not Uploaded</p>
                <hr class="bg-black mt-0">
              </div>
              <div *ngIf="activeTraining.type=='PHYSICAL'" class="col-12">
                <i class="bi bi-info-circle"></i>
                Physical Training<br>
                <!-- <a >Certificate Design</a> -->
                <a  *ngIf="activeTraining.showCertificate==true"  routerLink="/agency/certificate/{{activeTraining.id}}" class="text-primary-custom" > Click To View Certificate</a>
                <p  *ngIf="activeTraining.showCertificate==false||activeTraining.showCertificate==null"   >Certificate was not issued</p>
                <hr class="bg-black mt-0">
              </div>

          </section>
            <section>

                <div *ngIf="userType!='client' && activeTraining.hascoId ==null"  class="row">
                  <div class="form-group col-12 col-sm-12 col-md-12">
                    <label for="comment">Comment</label>
                    <div class="text-info-custom" *ngIf="activeTraining.status != 'WAITING_APPROVAL'">
                      {{activeTraining.comment}}
                    </div>
                    <input type="text" maxlength="255"  placeholder="Comment" class="form-control">
                  </div>
                </div>

            </section>
          </div>
        </div>
        <div align="right" *ngIf="userType!='client' && activeTraining.hascoId ==null"  class="mt-3">
          <button type="button" class="btn btn-danger btn-sm mr-3"   (click)="updateTraining(updateTrainingForm, 'REJECTED')" >
            Reject
          </button>
          <button type="submit" class="btn btn-sm btn-success" (click)="updateTraining(updateTrainingForm, 'APPROVED')">
            Approve
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>


<!-- Modals -->
<ng-template class="modal fade" #view2 let-modal>
  <div class="">
    <div class="modal-header bg-main text-white">
      <h5 class="modal-title text-center w-100">View DOCUMENT</h5>
      <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
        (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </span>
    </div>
    <div class="modal-body">
      <form [formGroup]="updateTrainingForm">
        <div class="bg-modal card-body">
          <div class="container-fluid">
            <section>
                <div class="col-12">
                  <i class="bi bi-info-circle"></i>
                  <a *ngIf="activeTraining.document" (click)="openDoc(activeTraining.document)" target="_blank" target="_blank"> Click To View Compliance Document</a>
                  <p *ngIf="!activeTraining.document" >Document Not Uploaded</p>
                  <hr class="bg-black mt-0">
                </div>
                <div *ngIf="userType!='client'"  class="row">
                  <div class="form-group col-12 col-sm-12 col-md-12">
                    <label for="comment">Comment</label>
                    <div class="text-info-custom" *ngIf="activeTraining.status != 'WAITING_APPROVAL'">
                      {{activeTraining.comment}}
                    </div>
                    <input type="text" maxlength="255" formControlName="comment" placeholder="Comment" class="form-control">
                  </div>
                </div>

            </section>
          </div>
        </div>
        <div *ngIf="userType!='client'"  align="right" class="mt-3">
          <button type="button" class="btn btn-danger btn-sm mr-3"   (click)="updateCompliance(updateTrainingForm, 'REJECTED')" >
            Reject
          </button>
          <button type="submit" class="btn btn-sm btn-success" (click)="updateCompliance(updateTrainingForm, 'APPROVED')">
            Approve
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
