<table *ngIf="status=='NEW'"class="table table-striped mb-0 styled-table text-center">
    <thead class="">
        <tr class="text-center">
            <th>Job Ref</th>
            <!-- <th>Date Booked</th> -->
            <th>Date Required</th>
            <th>Time Required</th>
            <th>Location</th>
            <th>Actions</th>
        </tr>
    </thead>
    <tbody>


        <tr *ngFor="let s of shifts">
            <td>{{s.id}}</td>
            <!-- <td></td> -->
            <td>{{s.dateTimeRequired | date:'dd/MM/yyyy'}}</td>
            <td> {{s.dateTimeRequired | date:'HH:mm'}}</td>
            <td>{{s.destination}}</td>
            <td>
              <a class="mr-3 text-primary-custom pointer"  routerLink="/worker/transport/logging/{{s.id}}" >
                <i class="fa fa-eye" title="view"></i>
              </a>
            </td>
        </tr>


    </tbody>
  </table>


<table *ngIf="status!='NEW'" class="table table-striped mb-0 styled-table text-center">
    <thead class="">
        <tr class="text-center">
            <th>Id</th>
            <!-- <th>Date Booked</th> -->
            <th>Date</th>
            <th>Log Type</th>
            <th>Driver</th>
            <th>Authoriser</th>
            <th>Actions</th>
        </tr>
    </thead>
    <tbody>


        <tr *ngFor="let s of shifts">
            <td>{{s.id}}</td>
            <!-- <td></td> -->
            <td>{{s.createdDate | date:'dd/MM/yyyy'}}</td>
            <td> {{s.type}}</td>
            <td>{{s.worker}}</td>
            <td>{{s.approvedByName}}</td>
            <td>
              <span  class="mr-3 text-primary-custom pointer" (click)="openViewModal(jobInfo,s)" >
                <i class="fa fa-eye" title="view"></i>
              </span>
              <a *ngIf="status=='WAITING_APPROVAL'" class="mr-3 text-success-custom pointer"  routerLink="/worker/transport/logging/{{s.transportId}}" >
                <i class="fa fa-pencil-alt" title="edit"></i>
              </a>
            </td>
        </tr>


    </tbody>
  </table>
  <section class="pagination-container" *ngIf="shifts">
    <div class="container-fluid">
      <div class="row m-0">
        <div class="col-12 col-sm-12 col-md-5 text-left acontent-center">
          Showing {{ (page - 1) * pageSize + 1 }} - {{ showin }} of {{ totalItems }}
        </div>
        <div class="col-12 col-sm-6 col-md-4 text-right">
          <button class="btn text-white" (click)="handlePageChange('prev')" [disabled]="first">
            <i class="fa fa-caret-up"></i>&nbsp;&nbsp;Previous Page
          </button>
        </div>
        <div class="col-12 col-sm-6 col-md-3 text-right pr-0">
          <button class="btn text-white" (click)="handlePageChange('next')" [disabled]="last">
            Next Page&nbsp;&nbsp;<i class="fa fa-caret-down"></i>
          </button>
        </div>
      </div>
    </div>
  </section>

  <!-- Modals -->
<ng-template class="modal fade" #delNew let-modal>
  <div class="">
      <div class="modal-header bg-main text-white">
          <h5 class="modal-title text-center w-100">Cancel BOOKING</h5>
          <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </span>
      </div>
      <div class="modal-body">
          <div class="bg-modal card-body">
              <div class="text-center">
                  <h3>Would you like to cancel this booking?</h3>
                  Id: {{selectedTransport}}
              </div>
          </div>
          <div class="mt-3">
            <button class="btn btn-success btn-sm mr-3" (click)="modal.dismiss()">
                NO
            </button>
            <button class="btn btn-sm btn-danger" (click)="deleteBooking()">
                YES
            </button>
          </div>
      </div>
  </div>
</ng-template>
<!-- modals -->

<ng-template class="modal fade" #view let-modal>
  <div class="card">
    <!-- <transport-info-modal [selectedJob]="selectedTransport"></transport-info-modal> -->
  </div>
  </ng-template>


  <ng-template #jobInfo let-modal>
    <driver-info-modal [log]="selectedTransport" id="printableModal"></driver-info-modal>
</ng-template>
