<h3 *ngIf="userType!=null">You are already logged into Worklink. Go to Jobs in your menu to create jobs or else logout.</h3>
<div *ngIf="userType==null">
  <div id="guest-back"  >
    <div >
      <div class="">
          <div class="col-md-8  pt-4 mx-auto" style="background-color: rgb(254, 254, 254);">
              <div class="row">
                <div class="col-12 my-1 text-center"  id="guest-land">
                  <img class="logo-md my-auto " [src]="agency?.logo" alt="agency logo image"  >
                  <h2><b>{{agency?.name}}</b></h2>
                  <hr>

                </div>
                <div class="col-md-12 text-center" >
                  <h5><b>Secure Transport Request Form</b></h5>
                  <p>Fill in the form below to request secure transport.<br>
                </div>
              </div>
          </div>
      </div>
    <div >
    </div>
    </div>
  </div>
  <app-create-transport-job></app-create-transport-job>

</div>
<div class="p-1" >
  Powered By:
  <img class="logo-sm my-auto " src='assets/images/logo.png' alt="logo image" style=" max-width: 72px;">
</div>
