import { JobsFilterPipe } from './jobs-filter.pipe';
import { SafeUrl } from '@angular/platform-browser';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, DatePipe } from '@angular/common';
import { JobsRoutingModule } from './jobs-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/shared/pipes.module';
import { AgencyJobsListComponent } from './agency-jobs-list/agency-jobs-list.component';
import { AgencyLogsListComponent } from './agency-logs-list/agency-logs-list.component';
import { JobsTableComponent } from './agency-jobs-list/components/jobs-table/jobs-table.component';
import { LogsTableComponent } from './agency-logs-list/components/logs-table/logs-table.component';
import { DeepCleaningLogComponent } from './agency-logs-list/components/create-deep-cleaning-log/deep-cleaning-log.component';
import { VehicleComponent } from './vehicle/vehicle.component';
import { AddNewInventoryComponent } from './vehicle/view-vehicle/add-new-inventory/add-new-inventory.component';
import { TransportInfoModalComponent } from './agency-jobs-list/components/info-modal/transport-info-modal';
import {DriverInfoModalComponent} from './agency-logs-list/components/info-modal/driver-info-modal';
import { CreateTransportJobComponent } from 'src/app/client/transport/create-transport-job/create-transport-job.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DeleteTransportModalComponent } from './agency-jobs-list/components/delete-transport/delete-transport-modal';
import { ViewVehicleComponent } from './vehicle/view-vehicle/view-vehicle.component';
import { AboutVehicleTabComponent } from './vehicle/view-vehicle/about-tab/view-vehicle-tab1.component';
import { VehicleTab2Component } from './vehicle/view-vehicle/document-tab/vehicle-documents-tab.component';
import { VehicleInventoryTabComponent } from './vehicle/view-vehicle/inventory-tab/vehicle-inventory-tab.component';
import { VehicleTab4Component } from './vehicle/view-vehicle/logs-tab/view-vehicle-tab4.component';
import { PhotosTabComponent } from './vehicle/view-vehicle/photos-tab/photos-tab.component';
import { MatCheckboxModule } from '@angular/material/checkbox'; // Import MatCheckboxModule
import { SafeUrlPipe } from './vehicle/view-vehicle/add-new-inventory/safeUrl.pipe';



@NgModule({
  declarations: [AgencyJobsListComponent, AgencyLogsListComponent,TransportInfoModalComponent,DriverInfoModalComponent,DeleteTransportModalComponent,
     JobsTableComponent, LogsTableComponent, DeepCleaningLogComponent,  VehicleComponent, CreateTransportJobComponent, ViewVehicleComponent, AboutVehicleTabComponent,
     VehicleTab2Component, VehicleInventoryTabComponent, VehicleTab4Component, PhotosTabComponent,
       JobsFilterPipe,SafeUrlPipe, AddNewInventoryComponent],
  imports: [
    CommonModule,
    JobsRoutingModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    FormsModule,
    PipesModule,
    NgSelectModule,
    NgbModule, // Import NgbModule
    MatCheckboxModule, // Add MatCheckboxModule here
  ],
  exports: [
    TransportInfoModalComponent,
    DeleteTransportModalComponent,
    PhotosTabComponent,
    AboutVehicleTabComponent,
    DriverInfoModalComponent,
     VehicleTab2Component, VehicleInventoryTabComponent, VehicleTab4Component,
    JobsTableComponent,
    CreateTransportJobComponent
  ],
  providers: [DatePipe]
})
export class AgencyJobsModule { }
