import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TransportService } from 'src/app/shared/services/transport.service';

@Component({
  selector: 'app-log-transport-tab2',
  templateUrl: './log-transport-job1.component.html'
})
export class LogTransportJob2Component implements OnInit {
  @Input('form') form: FormGroup;
  @Input('tabindex') tabindex: number;
  @Input('job') job: FormGroup;
  @Output() formO = new EventEmitter<FormGroup>();
  @Output() tabindexO = new EventEmitter<number>();
  damageReport: any;
  damageDescriptions = new Map<string, string>();
  // damageDescriptions = new Map<string, string | number>();

  constructor(
    private toast: ToastrService,
    private transporService: TransportService,
    private storageService: StorageService,
    private agencyService: AgencyService,
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal
  ) {

    if (!this.job) {
      this.job = this.fb.group({
        jobField1: [''],
        jobField2: [''],

      });
    }
  }

  ngOnInit(): void {
    // type MyMap = { [key: string]: string | number }

    const damageDescriptions = JSON.parse(this.form.value.damageDescriptions);
    this.damageDescriptions = new Map(Object.entries(damageDescriptions));
    if(this.damageDescriptions==null) this.damageDescriptions = new Map<string, string>();
    console.log(this.damageDescriptions);
    console.log(this.form.value.damageDescriptions);
    console.log(this.damageDescriptions);
  }

  nextTab() {
    this.tabindex++;
    if (this.tabindex > 4) this.tabindex = 4;
    this.tabindexO.emit(this.tabindex);
    this.formO.emit(this.form);
  }

  updateDescriptions(key, event){
    const inputValue = (event.target as HTMLInputElement).value;
    console.log(this.damageDescriptions)
    this.damageDescriptions.set(key, inputValue);
  }

  isBool(input): boolean {
    return (typeof input === 'boolean');
  }


  prevTab() {
    this.tabindex--;
    this.tabindexO.emit(this.tabindex);
    this.formO.emit(this.form);
  }

  updateVehicleCheck() {
    if ((
      !this.form.value.lamp ||
      !this.form.value.indicator ||
      !this.form.value.sideReapter ||
      !this.form.value.stoplamp ||
      !this.form.value.reflectors ||
      !this.form.value.markers ||
      !this.form.value.warningdevices ||
      !this.form.value.mirror ||
      !this.form.value.brakes ||
      !this.form.value.drivingcontrol ||
      !this.form.value.tyreInflation ||
      !this.form.value.wheelcondition ||
      !this.form.value.body ||
      !this.form.value.horn ||
      !this.form.value.wipers ||
      !this.form.value.washers ||
      !this.form.value.fuel ||
      !this.form.value.oil ||
      !this.form.value.fluidleaks ||
      !this.form.value.exhaust ||
      !this.form.value.windscreen ||
      !this.form.value.speedometer ||
      !this.form.value.battery ||
      !this.form.value.coolant ||
      !this.form.value.seatbelt)  && this.form.value.damageReport==null
    ) { return this.toast.warning("Complete the vehicle check and enter damage discription");}




    const jsonObject: { [key: string]: string } = {};
    this.damageDescriptions.forEach((value, key) => {
      jsonObject[key] = value;
    });

    const jsonString: string = JSON.stringify(jsonObject);
    console.log(jsonString);





    this.form.patchValue({
      damageDescriptions: jsonString
    });

    this.transporService.updateVehicleCheck(this.form.value).subscribe(
      data => {
        this.form.patchValue({
          id:data.id
        });

        if(this.damageReport!=null)
          this.uploadDamageReport(data.id);

        console.log(this.damageReport);
         this.toast.success("Info updated");
         this.nextTab();
        },
      error => { this.toast.error("Failed to update vehicle check"); }
    );
  }


  onChange(event) {
    console.log(event.target.files);
    this.damageReport = event.target.files[0];
  }


  uploadDamageReport(compliance){
    if(this.damageReport.size>=1048576){
      this.toast.warning("Maximum upload file size is 1mb")
    }
    else {
    this.transporService.uploadDamageReport(this.damageReport,compliance).subscribe(
      event => {
        this.toast.info("Sucessfully uploaded.");
        this.modalService.dismissAll();
      },
    );}
  }

}
