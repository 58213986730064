import { AuthService } from './../../shared/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { ShiftService } from 'src/app/shared/services/shift.service';
import { StorageService } from 'src/app/shared/services/storage.service';
declare var $: any;
@Component({
  selector: 'app-worker-compliance',
  templateUrl: './worker-compliance.component.html',
  styleUrls: ['./worker-compliance.component.css']
})
export class WorkerComplianceComponent implements OnInit {

  complianceIssues: any;
  locations: any;
  selectedDirectorate: any;

  locationFilter:number = null;


  addForm: FormGroup;
  editForm: FormGroup;

  page = 1;
  pageSize = 20;
  totalItems = 0;
  showin = 0;
  first: boolean;
  last: boolean;

  userType: string;
  searchCriteria: string = null;
  agencyId: any;

  constructor(
    private shiftsService: ShiftService,
    private authService: AuthService,
    private router: Router,
    private storageService: StorageService
  ) {
    this.userType = authService.getUserType();
   }

  ngOnInit(): void {
    this.agencyId = this.storageService.decrypt(localStorage.getItem('agentId'));
    this.getShiftComplianceIssues();
  }

  handlePageChange(event) {

    if(event == 'next'){
      this.page += 1;
    }
    else if(event == 'prev' && this.page != 1){
      this.page -= 1;
    }

    this.getShiftComplianceIssues();
  }

  getShiftComplianceIssues(){
    this.shiftsService.getShiftComplianceIssues("", this.agencyId, this.page - 1, this.pageSize).subscribe(
      data => {
        this.totalItems = data.totalElements;
        this.first = data.first;
        this.last = data.last;

        if(this.page * this.pageSize > data.totalElements){
          this.showin = data.totalElements;
        }
        else{
          this.showin = this.page * this.pageSize;
        }

        this.complianceIssues = data.content;
      }
    );
  }



}
