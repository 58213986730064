<div class="">

  <div class="">
    <form  [formGroup]="addOccupationalForm" (ngSubmit)="addWorkerOccupational(addOccupationalForm)">
      <!-- <form [formGroup]="addForm" (ngSubmit)="newForm(addForm)"> -->
        <div class="bg-modal card-body">
          <div class="container-fluid">
          <div class="col-12 text-left">
            <h3>Forms/ Occupational Health Medical Questionnaire</h3>
          </div>
          <section>
              <div class="col-12">
                <!-- <i class="bi bi-info-circle"></i> -->
                <div class="   mx-4 ">
                  <h5 class="text-white bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">Confidential</h5>

                    <span class="mb-2 ">
                      Due to the nature of the role you have applied for we need to carry out a complete a new starter health questionnaire
                       – even if you have been employed in UK health services before. The health of each candidate is considered individually
                        and a decision regarding fitness for work in the prospective job role will be based on the functional effects of any
                         underlying health condition/disability/ impairment as well as health service requirements for fitness and immune status.
                         Before health clearance is given for employment you may be contacted
                       and may need to be seen by an occupational health advisor or physician with gained consent. We may
                       recommend adjustments or assistance following an assessment to enable you to carry out your proposed
                        duties safely and effectively. Recommendations to your employer will be directed to essential
                        information regarding your health and the hazards and risks of your employment and with due
                        reference to other relevant statutory requirements and professional practice. Our aim is to
                        promote and maintain the health of all individuals in the workplace: staff, service users and third parties.
                    </span>
                </div>

              </div>

          </section>


          <section>
            <div class="col-12">
              <!-- <i class="bi bi-info-circle"></i>
              <span class="ml-2 text-info-custom">You must complete all sections of the application form electronically. We will use this form to help us decide on your suitability for the post so please make sure it is accurate and complete. CVs in isolation will not be accepted.
              </span> -->
              <div class="  text-white mx-4 ">
                <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">Personal Details</h5>
                <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close">
                <!-- <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
                  (click)="modal.dismiss()"> -->
                  <span aria-hidden="true">&times;</span>
                </span>
              </div>
              <hr class="bg-black mt-0">

              <div class="row form-group">
                <div class="mt-1 col-sm-12 col-md-6">
                  <Label>Title</Label>
                  <select class="form-control" formControlName="title">
                    <option value="Mr" >Mr</option>
                    <option value="Mrs" >Mrs</option>
                    <option value="Miss" >Miss</option>
                    <option value="Mrs" >Ms</option>
                    <option value="Mrs" >Dr</option>
                  </select>                  </div>
                <div class="mt-1 col-sm-12 col-md-6">
                  <Label>Surname</Label>
                  <input type="text" maxlength="255" value="{{worker?.lastname}}" formControlName="lastname" placeholder="{{worker?.lastname}}"  disabled  class="form-control">
                </div>

                <div class="mt-1 col-sm-12 col-md-6">
                  <Label>First names</Label>
                  <input type="text" maxlength="255"  placeholder="" value="{{worker?.firstname}}" placeholder="{{worker?.firstname}}" formControlName="firstname" disabled class="form-control">
                </div>
                <div class="mt-1 col-sm-12 col-md-6">
                  <Label>Preferred name</Label>
                  <input type="text" maxlength="255"  placeholder="" formControlName="preferredName" class="form-control">
                </div>

              <!-- <div class="mt-1 col-12 col-sm-12 col-md-12">
                <Label>Provide any former name if applicable</Label>
                <input type="text" maxlength="255"  placeholder="" class="form-control">
              </div> -->

                <div class="mt-1 col-sm-12 col-md-6">
                  <Label>Nationality</Label>
                  <select formControlName="nationality" class="form-control">
                    <option *ngFor="let c of countries"  value="{{c.code}}" >{{c.name}}</option>
                  </select>
                </div>
                <div class="mt-1 col-sm-12 col-md-6">
                  <Label>Date of birth</Label>
                  <input type="date" class="date" disabled formControlName="dob" placeholder="{{worker?.dob}}" placeholder="" class="form-control date">
                </div>

              <div class="mt-1 col-12 col-sm-12 col-md-12">
                <Label>Home address</Label>
                <input type="text" maxlength="255" [value]="worker?.address" formControlName="address" placeholder="" class="form-control">
              </div>


                <div class="mt-1 col-sm-12 col-md-6">
                  <Label>Email</Label>
                  <input type="text" maxlength="255" value="{{worker?.email}}"  placeholder="{{worker?.email}}" formControlName="email" disabled placeholder="" class="form-control">
                </div>
                <div class="mt-1 col-sm-12 col-md-6">
                  <Label>Preferred Contact Number</Label>
                  <input type="text" maxlength="255" [value]="worker?.phoneNumber" formControlName="phone" placeholder="" class="form-control">
                </div>
              </div>
            </div>

        </section>

          <section>
              <div class="col-12">

                <div class="  text-white mx-4 ">
                  <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
                    Medical History</h5>
                  <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close">
                  <!-- <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
                    (click)="modal.dismiss()"> -->
                    <span aria-hidden="true">&times;</span>
                  </span>
                  <span class="ml-2 text-info-custom">
                    <i class="bi bi-info-circle"></i>
                  All staff groups complete this section.
                </span>
                </div>
                <hr class="bg-black mt-0">

                <div class="row form-group mx-4">
                  <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                    <div class="col-md-9">
                      Do you have any illness/impairment/disability (physical or psychological) which may affect your work?
                    </div>


                    <div class="col-md-3">
                      <input type="radio" formControlName="disability" (click)="setYes('restriction')"  [value]="true"/>
                      <label for="disability"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                      <input type="radio" formControlName="disability" (click)="resetYes('restriction')" [value]="false">
                      <label for="disability">&nbsp;No</label><br/>
                    </div>


                    <div *ngIf="restriction==true" class="mt-1 col-sm-12 col-md-12">
                      <Label>Please provide details:</Label>
                      <textarea type="text"  formControlName="disabilityRes" placeholder="" class="form-control"></textarea>
                    </div>




                  </div>
                  <div class="mt-1 col-sm-12 col-md-12 row mt-2 bg-default">
                    <div class="col-md-9">
                      Have you ever had any illness/impairment/disability which may have been caused or made worse by your work?
                      </div>

                      <div class="col-md-3">
                        <input type="radio" formControlName="disabilityByWork" (click)="setYes('dbs')"  [value]="true"/>
                        <label for="disabilityByWork"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                        <input type="radio" formControlName="disabilityByWork"  (click)="resetYes('dbs')" [value]="false">
                        <label for="disabilityByWork"> &nbsp;No</label><br/>
                      </div>


                      <div *ngIf="dbs==true" class="mt-1 col-sm-12 col-md-12">
                        <Label>Please provide details:</Label>
                        <textarea type="text"  placeholder="" formControlName="disabilityByWorkRes" class="form-control"></textarea>
                      </div>

                  </div>
                  <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                    <div class="col-md-9">
                      Are you having, or waiting for treatment (including medication) or investigations at present?
                    </div>

                    <div class="col-md-3">
                      <input type="radio" formControlName="currentTreatment" (click)="setYes('interview')"  [value]="true"/>
                      <label for="currentTreatment"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                      <input type="radio" formControlName="currentTreatment"  (click)="resetYes('interview')" [value]="false">
                      <label for="currentTreatment"> &nbsp;No</label><br/>
                    </div>


                    <div *ngIf="interview==true" class="mt-1 col-sm-12 col-md-12">
                      <Label>Please provide details:</Label>
                      <textarea type="text" formControlName="currentTreatmentRes"  placeholder="" class="form-control"></textarea>
                    </div>

                  </div>
                  <div class="mt-1 col-sm-12 col-md-12 row mt-2 bg-default">
                    <div class="col-md-9">
                      Do you think you may need any adjustments or assistance to help you to do the job?
                    </div>

                    <div class="col-md-3">
                      <input type="radio" formControlName="assistance" (click)="setYes('sanctions')"  [value]="true"/>
                      <label for="assistance"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                      <input type="radio" formControlName="assistance"  (click)="resetYes('sanctions')" [value]="false">
                      <label for="assistance"> &nbsp;No</label><br/>
                    </div>


                    <div *ngIf="sanctions==true" class="mt-1 col-sm-12 col-md-12">
                      <Label>Please provide details:</Label>
                      <textarea type="text" formControlName="assistanceRes" placeholder="" class="form-control"></textarea>
                    </div>

                  </div>

                  <span class="ml-2 text-info-custom">
                    <i class="bi bi-info-circle"></i>
                    Have you suffered from any of the following?
                  </span>

                  <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                    <div class="col-md-6">
                      Methicillin resistant staphylococcus aureus (MRSA)
                    </div>

                    <div class="col-md-3">
                      <input checked="checked" type="radio" formControlName="mrsa" [value]="true"/>
                      <label for="mrsa"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                      <input type="radio" formControlName="mrsa" [value]="false"/>
                      <label for="mrsa"> &nbsp;No</label><br/>
                    </div>
                    <div class="col-md-3">
                      <input type="date" formControlName="mrsaDate" />

                    </div>


                  </div>

                  <div class="mt-1 col-sm-12 col-md-12 row mt-2 bg-default">
                    <div class="col-md-6">
                      Clostridium difficile (C-Diff)

                    </div>

                    <div class="col-md-3">
                      <input checked="checked" type="radio" formControlName="cdiff" [value]="true"/>
                      <label for="cdiff"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                      <input type="radio" formControlName="cdiff" [value]="false"/>
                      <label for="cdiff"> &nbsp;No</label><br/>
                    </div>
                    <div class="col-md-3">
                      <input type="date" formControlName="cdiffDate"/>

                    </div>

                  </div>
                </div>
              </div>

          </section>

          <section>
            <div class="col-12">

              <div class="  text-white mx-4 ">
                <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
                  Chicken Pox Or Shingles</h5>
                <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </span>
              </div>

              <div class="row form-group mx-4">
                <div class="mt-1 col-sm-12 col-md-12 row mt-2 ">
                  <div class="col-md-6">
                    Have you ever had chicken pox or shingles
                  </div>

                  <div class="col-md-3">
                    <input checked="checked" type="radio" formControlName="chickenpox" [value]="true"/>
                    <label for="chickenpox"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                    <input type="radio" formControlName="chickenpox" [value]="false"/>
                    <label for="chickenpox"> &nbsp;No</label><br/>
                  </div>
                  <div class="col-md-3">
                    <input type="date" formControlName="chickenpoxDate" [value]="false"/>

                  </div>
                </div>

              </div>
            </div>

          </section>

          <section>
            <div class="col-12">

              <div class="  text-white mx-4 ">
                <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
                  BBV (Blood Borne Virus)</h5>
                <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </span>
              </div>

              <div class="row form-group mx-4">
                <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                  <div class="col-md-9">
                    Have you ever come into contact with any BBV's? Including Needle Stick Injuries?
                   </div>

                  <div class="col-md-3">
                    <input checked="checked" type="radio" formControlName="bbv" [value]="true"/>
                    <label for="bbv"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                    <input type="radio" formControlName="bbv" [value]="false"/>
                    <label for="bbv"> &nbsp;No</label><br/>
                  </div>

                </div>

              </div>
            </div>

          </section>


          <section>
            <div class="col-12">

              <div class="  text-white mx-4 ">
                <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
                  Tuberculosis</h5>
                  <span class="ml-2 text-info-custom">
                    <i class="bi bi-info-circle"></i>
                    Clinical diagnosis and management of tuberculosis, and measures for its prevention and control (NICE 2016)
                  </span>
              </div>

              <div class="row form-group mx-4">
                <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                  <div class="col-md-9">
                    Have you lived outside the UK or had an extended holiday outside the UK in the last year?
                  </div>

                  <div class="col-md-3">
                    <input checked="checked" type="radio" formControlName="travelled" [value]="true"/>
                    <label for="travelled"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                    <input type="radio" formControlName="travelled" [value]="false"/>
                    <label for="travelled"> &nbsp;No</label><br/>
                  </div>

                </div>

                <span class="ml-2 text-info-custom">
                  <i class="bi bi-info-circle"></i>
                  If you answered YES to the above, please list all the countries that you have lived in/visited over the last year, including holidays and vacations. This MUST include duration of stay and dates or this form will be rejected.
                </span>

                <div class="row col-12">
                  <div class="col-4">
                    <Label>Country visited</Label>
                  </div>
                  <div class="col-4">
                    <Label>from</Label>
                  </div>
                  <div class="col-4">
                    <Label>to</Label>
                  </div>
                </div>

                <div *ngFor="let r of journeyArray?.controls; let i = index; trackBy: track">
                  <form [formGroup]="r"  >
                    <div class="row form-group mx-4 bg-default col-12 bg-default my-1 ">
                      <div class="d-flex col-12">
                        <span></span>
                        <span class="m-auto"></span>
                        <button class="btn btn-danger ml-4 btn-circle" (click)="removeJourney(i)">
                          <i class="fa fa-minus"></i>
                        </button>
                      </div>
                      <div class="col-4">
                        <select  class="form-control">
                          <option *ngFor="let c of countries" value="{{c.code}}" >{{c.name}}</option>
                        </select>
                      </div>
                      <div class="col-4">
                        <input type="date"  placeholder="" class="form-control">
                      </div>
                      <div class="col-4">
                        <input type="date"  placeholder="" class="form-control">
                      </div>

                    </div>
                  </form>
                </div>

                <button type="button" class="btn btn-success btn-circle mb-2" (click)="addJourney()">
                  <i class="fa fa-plus"></i>
                </button>



                <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                  <div class="col-md-6">
                    Have you had a BCG vaccination in relation to Tuberculosis?
                  </div>

                  <div class="col-md-3">
                    <input checked="checked" type="radio" formControlName="bcg" [value]="true"/>
                    <label for="bcg"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                    <input type="radio" formControlName="bcg" [value]="false"/>
                    <label for="bcg"> &nbsp;No</label><br/>
                  </div>

                  <div class="col-md-3">
                    <input type="date" formControlName="bcgDate" [value]="false"/>

                  </div>

                </div>

              </div>
            </div>

          </section>


          <section>
            <div class="col-12">

              <div class="  text-white mx-4 ">
                <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
                  Tuberculosis Continued</h5>
                  <span class="ml-2 text-info-custom">
                    <i class="bi bi-info-circle"></i>
                    Do you have any of the following?
                  </span>
              </div>

              <div class="row form-group mx-4">
                <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                  <div class="col-md-9">
                    A cough which has lasted for more than 3 weeks

                  </div>

                  <div class="col-md-3">
                    <input type="radio" formControlName="cough" (click)="setYes('confidential')"  [value]="true"/>
                    <label for="cough"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                    <input type="radio" formControlName="cough"  (click)="resetYes('confidential')" [value]="false">
                    <label for="cough"> &nbsp;No</label><br/>
                  </div>



                </div>
                <div class="mt-1 col-sm-12 col-md-12 row mt-2 bg-default">
                  <div class="col-md-9">
                    Unexplained weight loss

                  </div>


                  <div class="col-md-3">
                    <input type="radio" formControlName="weight" (click)="setYes('confidential')"  [value]="true"/>
                    <label for="weight"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                    <input type="radio" formControlName="weight"  (click)="resetYes('confidential')" [value]="false">
                    <label for="weight"> &nbsp;No</label><br/>
                  </div>

                </div>
                <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                  <div class="col-md-9">
                    Unexplained fever

                  </div>


                  <div class="col-md-3">
                    <input type="radio" formControlName="fever" (click)="setYes('confidential')"  [value]="true"/>
                    <label for="fever"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                    <input type="radio" formControlName="fever"  (click)="resetYes('confidential')" [value]="false">
                    <label for="fever"> &nbsp;No</label><br/>
                  </div>

                </div>
                <div class="mt-1 col-sm-12 col-md-12 row mt-2 bg-default">
                  <div class="col-md-9">
                    Have you had tuberculosis (TB) or been in recent contact with open TB

                  </div>


                  <div class="col-md-3">
                    <input type="radio" formControlName="tb" (click)="setYes('confidential')"  [value]="true"/>
                    <label for="tb"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                    <input type="radio" formControlName="tb"  (click)="resetYes('confidential')" [value]="false">
                    <label for="tb"> &nbsp;No</label><br/>
                  </div>

                </div>

                <div *ngIf="confidential==true" class="mt-1 col-sm-12 col-md-12">
                  <Label>Please provide details:</Label>
                  <textarea type="text" formControlName="tbcRes" placeholder="" class="form-control"></textarea>
                </div>



              </div>
            </div>

          </section>





        <section>
          <div class="col-12">

            <div class="  text-white mx-4 ">
              <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
                Immunisation History</h5>
                <span class="ml-2 text-info-custom">
                  <i class="bi bi-info-circle"></i>
                  Have you had any of the following immunisations?
                </span>
            </div>

            <div class="row form-group mx-4">
              <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                <div class="col-md-6">
                  Triple vaccination as a child ( Diptheria / Tetanus / Whooping cough )

                </div>

                <div class="col-md-3">
                  <input checked="checked" type="radio" formControlName="tripleVac" [value]="true"/>
                  <label for="tripleVac"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                  <input type="radio" formControlName="tripleVac" [value]="false"/>
                  <label for="tripleVac"> &nbsp;No</label><br/>
                </div>
                <div class="col-md-3">
                  <input type="date" formControlName="tripleVacDate" />
                </div>

              </div>
              <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                <div class="col-md-6">
                  Polio
                </div>

                <div class="col-md-3">
                  <input checked="checked" type="radio" formControlName="polio" [value]="true"/>
                  <label for="polio"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                  <input type="radio" formControlName="polio" [value]="false"/>
                  <label for="polio"> &nbsp;No</label><br/>
                </div>
                <div class="col-md-3">
                  <input type="date" formControlName="polioDate" />
                </div>

              </div>
              <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                <div class="col-md-6">
                  Tetanus
                </div>

                <div class="col-md-3">
                  <input checked="checked" type="radio" formControlName="tetanus" [value]="true"/>
                  <label for="tetanus"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                  <input type="radio" formControlName="tetanus" [value]="false"/>
                  <label for="tetanus"> &nbsp;No</label><br/>
                </div>
                <div class="col-md-3">
                  <input type="date" formControlName="tetanusDate" />
                </div>

              </div>
              <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                <div class="col-md-12">
                Hepatitis B (If Yes is ticked please give dates below)
                </div>
                <div class="col-md-4">
                  <input type="date" formControlName="c1" />
                  <label for="rd2"> &nbsp;Course 1</label><br/>
                </div>
                <div class="col-md-4">
                  <input type="date" formControlName="c2" />
                  <label for="rd2"> &nbsp;Course 2</label><br/>
                </div>
                <div class="col-md-4">
                  <input type="date" formControlName="c3" />
                  <label for="rd2"> &nbsp;Course 3</label><br/>
                </div>
                <div class="col-md-4">
                  <input type="date" formControlName="b1" />
                  <label for="rd2"> &nbsp;Booster 1</label><br/>
                </div>
                <div class="col-md-4">
                  <input type="date" formControlName="b2" />
                  <label for="rd2"> &nbsp;Booster 2</label><br/>
                </div>
                <div class="col-md-4">
                  <input type="date" formControlName="b3" />
                  <label for="rd2"> &nbsp;Booster 3</label><br/>
                </div>



              </div>
            </div>
          </div>

        </section>



        <section>
          <div class="col-12">

            <div class="  text-white mx-4 ">
              <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
                Proof of Immunity (Please send the following)</h5>

            </div>

            <div class="row form-group mx-4">
              <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                <div >

                  <div class="row">
                    <div class="col-md-6">
                      <h5 class="mt-2">VARICELLA</h5>
                    </div>

                    <div class=" col-sm-12 col-md-6">

                    <label for="varicella" class="btn btn-success">Upload Proof</label>
                    <a *ngIf="occupationalDetails?.varUp" [href]="occupationalDetails?.varUp" class="bold mx-2" >View File</a>

                    <input id="varicella"  style="visibility:hidden;" type="file" (change)="onChange($event); uploadVar()">


                    </div>
                  </div>


                  <p class="bg-default">
                    You must provide a written statement to confirm that you have had chicken pox or shingles however we strongly advise that you provide serology test result showing varicella immunity.
                  </p>
                </div>
                <div >
                  <div class="row">
                    <div class="col-md-6">
                      <h5 class="mt-2">Tuberculosis</h5>
                    </div>

                    <div class=" col-sm-12 col-md-6">

                    <label for="tb" class="btn btn-success">Upload Proof</label>
                    <a *ngIf="occupationalDetails?.tbUp" [href]="occupationalDetails?.tbUp" class="bold mx-2" >View File</a>

                    <input id="tb"  style="visibility:hidden;" type="file" (change)="onChange($event); uploadTb()">
                    </div>
                  </div>
                  <p class="bg-default">
                    We require an occupational health/GP certificate of a positive scar or a record of a positive skin test result (Do not Self Declare)
                  </p>
                </div>
                <div >

                  <div class="row">
                    <div class="col-md-6">
                      <h5 class="mt-2">RUBELLA, MEASLES & MUMPS</h5>
                    </div>

                    <div class=" col-sm-12 col-md-6">

                    <label for="rubella" class="btn btn-success">Upload Proof</label>
                    <a *ngIf="occupationalDetails?.rubUp" [href]="occupationalDetails?.rubUp" class="bold mx-2" >View File</a>

                    <input id="rubella"  style="visibility:hidden;" type="file" (change)="onChange($event); uploadRub()">
                    </div>
                  </div>
                  <p class="bg-default">
                    Certificate of “two” MMR vaccinations or proof of a positive antibody for Rubella and Measles
                  </p>
                </div>
                <div >


                  <div class="row">
                    <div class="col-md-6">
                      <h5 class="mt-2">HEPATITIS B</h5>
                    </div>

                    <div class=" col-sm-12 col-md-6">
                      <label for="hepb" class="btn btn-success">Upload Proof</label>
                      <a *ngIf="occupationalDetails?.hepbUP" [href]="occupationalDetails?.hepbUP" class="bold mx-2" >View File</a>

                      <input id="hepb"  style="visibility:hidden;" type="file" (change)="onChange($event); uploadHepb()">
                    </div>
                  </div>
                  <p class="bg-default">
                    You must provide a copy of the most recent pathology report showing titre levels of 100lu/l or above
                  </p>
                </div>
              </div>
            </div>
          </div>

        </section>


        <section>
          <div class="col-12">

            <div class="  text-white mx-4 ">
              <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
                Proof of Immunity (Please send the following) EPP Candidates Only</h5>

            </div>

            <div class="row form-group mx-4">
              <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                <div >
                  <div class="row">
                    <div class="col-md-6">
                      <h5 class="mt-2">HEPATITIS B SURFACE ANTIGEN</h5>
                    </div>

                    <div class=" col-sm-12 col-md-6">
                      <label for="hepbs" class="btn btn-success">Upload Proof</label>
                      <a *ngIf="occupationalDetails?.hepbsUp" [href]="occupationalDetails?.hepbsUp" class="bold mx-2" >View File</a>

                      <input id="hepbs"  style="visibility:hidden;" type="file" (change)="onChange($event); uploadHepbs()">
                    </div>
                  </div>

                  <p class="bg-default">
                    Evidence of Hepatitis B Surface Antigen Test (Inc. ‘e’ antigen and DNA viral loads if applicable Report must be an identified validated sample. (IVS)
                  </p>
                </div>
                <div >
                  <div class="row">
                    <div class="col-md-6">
                      <h5 class="mt-2">HEPATITIS C</h5>
                    </div>

                    <div class=" col-sm-12 col-md-6">
                      <label for="hepc" class="btn btn-success">Upload Proof</label>
                      <a *ngIf="occupationalDetails?.hepcUp" [href]="occupationalDetails?.hepcUp" class="bold mx-2" >View File</a>
                      <input id="hepc"  style="visibility:hidden;" type="file" (change)="onChange($event); uploadHepc()">
                    </div>
                  </div>
                  <p class="bg-default">
                    Evidence of a Hepatitis C antibody test (Inc. Hepatitis C RNA/PCR if applicable) Reports must be an identified validated sample. (IVS)
                  </p>
                </div>
                <div >
                  <div class="row">
                    <div class="col-md-6">
                      <h5 class="mt-2">HIV</h5>
                    </div>

                    <div class=" col-sm-12 col-md-6">
                      <label for="hiv" class="btn btn-success">Upload Proof</label>
                      <a *ngIf="occupationalDetails?.hivUp" [href]="occupationalDetails?.hivUp" class="bold mx-2" >View File</a>

                      <input id="hiv"  style="visibility:hidden;" type="file" (change)="onChange($event); uploadHiv()">
                    </div>
                  </div>
                  <p class="bg-default">
                    Evidence of a HIV I and II antibody test (Inc. DNA viral loads if applicable) Reports must be an identified validated sample. (IVS)
                  </p>
                </div>
              </div>
            </div>
          </div>

        </section>



        <section>
          <div class="col-12">

            <div class="  text-white mx-4 ">
              <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
              Exposure Prone Procedures</h5>
            </div>

            <div class="row form-group mx-4">
              <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                <div class="col-md-9">
                  Will your role involve Exposure Prone Procedures?
                </div>

                <div class="col-md-3">
                  <input checked="checked" type="radio" formControlName="exposure" [value]="true"/>
                  <label for="exposure"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                  <input type="radio" formControlName="exposure" [value]="false"/>
                  <label for="exposure"> &nbsp;No</label><br/>
                </div>

              </div>
            </div>
          </div>

        </section>




        <section>
          <div class="col-12">

            <div class="  text-white mx-4 ">
              <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
                The General Data Protection Regulation (GDPR) (EU) 2016/679 </h5>

            </div>

            <div class="row form-group mx-4">
              <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                <div class="col-md-12">
                  All information supplied by you will be held in confidence by Work-Link. Records will be retained electronically in accordance with best practice and the requirements of the General Data Protection Regulations at which time it may be subject to audit. Your data may also be cross referenced should you have registered with other clients of Work-Link. Your personal data may be required to be seen by an occupational health advisor or physician, however it will not be shown, nor their contents shared with anyone - including Managers, Human Resources Advisors, GP, Specialist’s or third party’s - without your explicit consent. You have the right of erasure (the right to be forgotten), withdrawal of consent and refusal of consent without detriment. The only exceptions to this may be a court order for release of records in a judicial dispute or where there is a public responsibility obligation.
                </div>
              </div>
            </div>
          </div>

        </section>


        <section>
          <div class="col-12">

            <div class="  text-white mx-4 ">
              <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
                Recommendations</h5>
                <span class="ml-2 text-info-custom">
                  <i class="bi bi-info-circle"></i>
                  I understand that following this assessment, recommendations may be provided to assist my health at work;
                </span>
            </div>

            <div class="row form-group mx-4">
              <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                <div class="col-md-9">
                  I give consent for my employer/agency to provide these recommendations to my placement
                </div>

                <div class="col-md-3">
                  <input checked="checked" type="radio" formControlName="consent" [value]="true"/>
                  <label for="consent"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                  <input type="radio" formControlName="consent" [value]="false"/>
                  <label for="consent"> &nbsp;No</label><br/>
                </div>

              </div>
              <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                <div class="col-md-9">
                  I would like to see a written copy of any recommendations employer/agency provide them to my placement
                </div>

                <div class="col-md-3">
                  <input checked="checked" type="radio" formControlName="copy" [value]="true"/>
                  <label for="copy"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                  <input type="radio" formControlName="copy" [value]="false"/>
                  <label for="copy"> &nbsp;No</label><br/>
                </div>

              </div>
            </div>
          </div>

        </section>
        <section>

          <div class="  text-white mx-4 ">
            <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">Declaration</h5>
            <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close">
            <!-- <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
              (click)="modal.dismiss()"> -->
              <span aria-hidden="true">&times;</span>
            </span>
          </div>
          <hr class="bg-black mt-0">

          <div class="row form-group">
              <div class="col-md-12">
                I will inform my employer if I am planning to or leave the UK for longer than a three-month period to enable a reassessment of my health to be conducted on my return. I declare that the answers to the above questions are true and complete to the best of my knowledge and belief.
            </div>

            <div class="row my-3"></div>
            <div class="col-md-12">
                <label>Full name</label><br>
                <input  type="text" formControlName="fullname" class="form-control">
            </div>
            <div class="mt-1 col-sm-12 col-md-6 row mt-2">
              <div class="col-md-6">
                <!-- <label for="signa" class="btn btn-success">Upload Signature</label> -->
                <!-- <a *ngIf="occupationalDetails?.sigUp" [href]="occupationalDetails?.sigUp" class="bold mx-2" >View File</a> -->

                <input id="signa"  style="visibility:hidden;" type="file" (change)="onChange($event); uploadSignature()">
                <input  type="date" formControlName="sigDate"/>
            </div>
            </div>
          </div>

    </section>




        </div>
      </div>
      <div align="right" class="mt-3">
        <button type="submit" *ngIf="!occupationalDetails?.submitted" class="btn btn-danger btn-sm mr-3" >
          Save
        </button>
        <button type="submit" *ngIf="occupationalDetails?.submitted" class="btn btn-success btn-sm mr-3" >
          Update
        </button>
        <button *ngIf="!occupationalDetails?.submitted"  type="button" (click)="addWorkerOccupational(addOccupationalForm, 'submit')" class="btn btn-sm btn-success">
          Submit
        </button>
        <!-- <button *ngIf="occupationalDetails?.submitted"  type="button"  class="btn btn-sm btn-info">
          SUBMITTED
        </button> -->
      </div>
    </form>
  </div>
</div>
