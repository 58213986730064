import { ToastrService } from 'ngx-toastr';
import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    public router: Router,
    private toast: ToastrService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const url: string = state.url;
    let currentUrl = window.location.pathname;

    if (this.authService.isLoggedIn !== true) {
      this.toast.error("Access not allowed!");
      this.router.navigateByUrl('/login');
    }
    else if (currentUrl !== '/login' && !currentUrl.includes(this.authService.getUserType())) {
      this.toast.error("Access To Portal not allowed!");
      // this.authService.doLogout();
      this.router.navigateByUrl('/login');
    }
    // else if (currentUrl !== '/login' && !currentUrl.includes(this.authService.getUserType())) {

    //   if (this.jwtService.hasExpired(this.jwt));

    //   this.authService.doLogout();
    //   this.router.navigateByUrl('/login');
    // }
    else {
      return true;
    }

    console.warn(currentUrl, this.authService.getUserType(), currentUrl.includes(this.authService.getUserType()));
  }

}
