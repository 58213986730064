import { Pipe, PipeTransform } from '@angular/core';
import { StorageService } from '../services/storage.service';


@Pipe({name: 'permission'})
export class PermissionPipe implements PipeTransform {
  constructor(
    private storageService: StorageService,

  ) {}


  transform(perm: string): boolean {
    var permissions = JSON.parse(this.storageService.decrypt(localStorage.getItem('permissions')));
    var allowed = false;

    for(var i = 0; i<permissions.length; i++){
      if(permissions[i].authority==perm){
        allowed = true;
        break;
      }
    }

    return allowed;
  }
}
