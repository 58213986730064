import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import { WorkerTransportListComponent } from './worker-transport-list/worker-transport-list.component';
import { NewTransportComponent } from './worker-transport-list/transport-table/worker-new-transport.component';
import { WorkerTransportRoutingModule } from './worker-transport-routing.module';
import { MatSliderModule } from '@angular/material/slider';
import { AgencyJobsModule } from 'src/app/agency/agency-jobs/jobs.module';
import { LogTransportTabsComponent } from './log-transport-job/log-transport-job1.component';
import { LogTransportJob2Component } from './log-transport-job/tab2/log-transport-job1.component';
import { LogTransportJob3Component } from './log-transport-job/tab3/log-transport-job1.component';
import { LogTransportJob4Component } from './log-transport-job/tab4/log-transport-job1.component';
import { LogTransportJob1Component } from './log-transport-job/tab1/log-transport-job1.component';
import { LogTransportJobComponent } from './view/log-transport-job.component';

@NgModule({
  declarations: [WorkerTransportListComponent,LogTransportTabsComponent, LogTransportJob1Component, LogTransportJob2Component,LogTransportJob3Component, LogTransportJob4Component, LogTransportJobComponent,NewTransportComponent],
  imports: [
    CommonModule,
    AgencyJobsModule,
    WorkerTransportRoutingModule,
    ReactiveFormsModule,
    NgSelectModule,
    AgencyJobsModule,
    MatSliderModule,
    FormsModule,
    MatDatepickerModule,
    MatFormFieldModule
  ],
  exports:[
    LogTransportJob1Component, LogTransportJob2Component,LogTransportJob3Component, LogTransportJob4Component
  ],
  providers: [DatePipe]
})
export class WorkerTransportsModule { }
