import { AuthService } from './../../../shared/services/auth.service';
import { AgencyService } from './../../../shared/services/agency.service';
import { ServicesService } from './../../../shared/services/services.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/shared/services/client.service';
import { UsersService } from 'src/app/shared/services/users.service';
import { StorageService } from 'src/app/shared/services/storage.service';
declare var $: any;

@Component({
  selector: 'app-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.css']
})
export class ClientsListComponent implements OnInit {

  clients: any;
  allClients: any;
  userList: any;
  agencies: any;
  services: any;
  searchCriteria: any;
  selectedClient: any;
  clientId: number;

  addForm: FormGroup;
  editForm: FormGroup;

  page = 1;
  pageSize = 25;
  totalItems = 0;
  showin = 0;
  first: boolean;
  last: boolean;

  userType: string;
  showUsers: boolean;
  new: boolean = true;
  existing: boolean;
  formArea: boolean;
  agencyId: any;

  constructor(
    private toast: ToastrService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private clientService: ClientService,
    private servicesService: ServicesService,
    private agencyService: AgencyService,
    private authService: AuthService,
    private usersService: UsersService,
    private storageService: StorageService
  ) {
    this.userType = authService.getUserType();
    this.agencyId =this.storageService.decrypt(localStorage.getItem('agentId'));
   }

  ngOnInit(): void {
    this.getClients();

    this.editForm = this.fb.group({
      id: 0
    });

    this.getServices();
    this.getAgencies();
  }

  formInit(modal){
    this.addForm = this.fb.group({
      name: ['', Validators.required],
      logo: [""],
      email: ['', Validators.required],
      // status: ['ACTIVE', Validators.required],
      serviceId: ['', Validators.required],
      agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
      address: this.fb.group({
        firstLine: ['', Validators.required],
        postcode: ['', Validators.required],
        town: ['', Validators.required],
      }),
      telephone: ['', Validators.required],
      administratorCreateDto: this.fb.group({
        adminEmail: ['', Validators.required],
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
      }),
      billingEmail: ['', Validators.required],
      purchaseCode: ['', Validators.required],
      sbsCode: ['', Validators.required]


    });

    this.openModal(modal);
  }

  handlePageChange(event) {

    if(event == 'next'){
      this.page += 1;
    }
    else if(event == 'prev' && this.page != 1){
      this.page -= 1;
    }

    this.getClients();
  }

  getClients(){
    if(this.userType == 'agency'){
      this.agencyService.getPaginatedAgencyClients(this.page - 1, this.pageSize, this.storageService.decrypt(localStorage.getItem('agentId'))).subscribe(
        data => {
          console.table(data.content);
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }

          this.clients = data.content;
        }
      );
    }
    else{
      this.clientService.getPaginatedClients(this.page - 1, this.pageSize).subscribe(
        data => {
          console.table(data.content);
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }

          this.clients = data.content;
        }
      );
    }
  }

  getAllClients(){
    this.clientService.getPaginatedClients(0, 100).subscribe(
      data => {
        this.allClients = data.content;
      }
    );
  }

  searchClient(){
    console.log(this.searchCriteria);
    if(this.userType=='agency'){
      this.clientService.searchAgencyClients(this.agencyId, this.searchCriteria,0, 100).subscribe(
        data => {
            this.totalItems = data.totalElements;
            this.first = data.first;
            this.last = data.last;

            if(this.page * this.pageSize > data.totalElements){
              this.showin = data.totalElements;
            }
            else{
              this.showin = this.page * this.pageSize;
            }

            this.clients = data.content;
        }
      );
    }
    else{
      this.clientService.searchClient(this.searchCriteria,0, 100).subscribe(
        data => {
            this.totalItems = data.totalElements;
            this.first = data.first;
            this.last = data.last;

            if(this.page * this.pageSize > data.totalElements){
              this.showin = data.totalElements;
            }
            else{
              this.showin = this.page * this.pageSize;
            }

            this.clients = data.content;
        }
      );
    }

  }

  getServices(){
    this.servicesService.getPaginatedServices(0, 100).subscribe(
      data => {
        // console.table(data.content);
        this.services = data.content;
      }
    );
  }

  getAgencies(){
    this.agencyService.getPaginatedAgencies(0, 100).subscribe(
      data => {
        this.agencies = data.content;
      }
    );
  }


  getServiceId(ref){
    let sid;
    if(ref){
      this.services.forEach(r => {
        if(r.name == ref){
          sid = r.id;
        }
      });
    }

    return sid;
  }


  openModal(modal){
    this.modalService.open(modal, {centered: true, size: 'lg'});
  }

  newClient(form: FormGroup){
    // console.table(form.value);

    if(form.valid){
      this.clientService.createClient(form.value).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          this.getClients();
          // this.addForm.reset();
          // this.addForm.value.agencyId = localStorage.getItem('agentId');
          this.toast.success('Client Added Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }


  updateClient(form: FormGroup){
    // console.table(form.value);

    if(form.valid){
      this.clientService.updateClient(form.value).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          this.getClients();
          this.toast.success('Client Updated Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }


  loadClient(ref, modal, edit){
    if(ref){
      this.clients.forEach(r => {
        if(r.id == ref){
          this.selectedClient = r;
        }
      });

      if(edit){
        this.editForm = this.fb.group({
          id: [this.selectedClient.id],
          name: [this.selectedClient.name, Validators.required],
          logo: [this.selectedClient.logo, Validators.required],
          email: [this.selectedClient.email, Validators.required],
          billingEmail: [this.selectedClient.billingEmail, Validators.required],
          purchaseCode: [this.selectedClient.purchaseCode, Validators.required],
          sbsCode: [this.selectedClient.sbsCode, Validators.required],
          status: [this.selectedClient.status, Validators.required],
          serviceId: [this.getServiceId(this.selectedClient.service), Validators.required],
          address: this.fb.group({
            firstLine: [this.selectedClient.firstLine],
            postcode: [this.selectedClient.postCode],
            town: [this.selectedClient?.town],
          }),
          telephone: [this.selectedClient.telephone, Validators.required],
        });
      }

      this.openModal(modal);
    }

  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  checkEmail(ele){
    // console.log(ele);

    var typed = ele.target.value;

    if(this.validateEmail(typed)){
      $(ele.target).removeClass('is-invalid');
      $(ele.target).addClass('is-valid');
    }
    else{
      $(ele.target).removeClass('is-valid');
      $(ele.target).addClass('is-invalid');
    }
  }

  deleteClient(){

    if(this.selectedClient.id){
      this.clientService.deleteClient(this.selectedClient.id).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          this.getClients();
          this.toast.success('Client Deleted Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }
  }

  getUsers(){
    this.userList = null;
    this.usersService.getPaginatedClientUsers(0, 100, this.clientId).subscribe(
      data => {
        console.log(data.content);

        this.userList = data.content;
        this.showUsers = true;
      }
    );
  }

  openBigModal(modal, ref){
    console.log(ref);
    this.clientId = ref;
    this.getUsers();
    this.modalService.open(modal, {centered: true, size: 'xl'});
  }

  getName(name){
    if(name){
      let n = name.replace('ROLE', "");
      return n.replace(/_/g, " ");
    }

    return '';
  }

  activateUser(ref, role, enable){

    let formData: FormGroup = this.fb.group({
      userId : ref,
      roleName: role,
      enabled: enable
    });

    this.usersService.enableUser(formData.value).subscribe(
      resp => {
        // console.log(resp);
        this.toast.success('User Status Changed.')
        this.getUsers();
      }
    );

  }

  loadClientInfo(ele){
    let ref = ele.target.value;

    // console.log(ref);


    if(ref){
      let r;

      this.allClients.forEach(a => {
        if(a.id == ref){
          r = a;
          this.clientId = a.id;
        }
      });

      if(ref != 'NEW'){
        this.addForm = this.fb.group({
          name: [r.name, Validators.required],
          logo: [r.logo],
          email: [r.email, Validators.required],
          // status: ['ACTIVE', Validators.required],
          serviceId: [this.getServiceId(r.service), Validators.required],
          agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
          address: this.fb.group({
            firstLine: [r.firstLine, Validators.required],
            postcode: [r.postCode, Validators.required],
            town: ['', Validators.required],
          }),
          telephone: [r.telephone, Validators.required],
          administratorCreateDto: this.fb.group({
            adminEmail: ['', Validators.required],
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
          }),
          billingEmail: [r.billingEmail, Validators.required],
          purchaseCode: [r.purchaseCode],
          sbsCode: [r.sbsCode]
        });

        this.getAdminInfo(r.id);

        this.new = false;
        this.existing = true;
        this.formArea = true;
      }
      else{
        this.addForm = this.fb.group({
          name: ['', Validators.required],
          logo: [''],
          email: ['', Validators.required],
          // status: ['ACTIVE', Validators.required],
          serviceId: ['', Validators.required],
          agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
          address: this.fb.group({
            firstLine: ['', Validators.required],
            postcode: ['', Validators.required],
            town: ['', Validators.required],
          }),
          telephone: ['', Validators.required],
          administratorCreateDto: this.fb.group({
            adminEmail: ['', Validators.required],
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
          }),
          billingEmail: ['', Validators.required],
          purchaseCode: ['', Validators.required],
          sbsCode: ['',, Validators.required]
        });

        this.new = true;
        this.existing = false;
        this.formArea = false;
      }
    }

  }

  getAdminInfo(ref){
    // console.log(ref);

  }

  createLinkToAgent(){
    // console.log(this.clientId);
    this.clientService.linkClientToAgent(this.clientId, this.storageService.decrypt(localStorage.getItem('agentId'))).subscribe(
      resp => {
        console.table(resp);
        this.modalService.dismissAll();
        this.getClients();
        // this.addForm.reset();
        // this.addForm.value.agencyId = localStorage.getItem('agentId');
        this.toast.success('Client Linked Successfully');
      }
    );
  }


}
