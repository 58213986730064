import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'src/app/shared/services/storage.service';
import { WorkerService } from 'src/app/shared/services/worker.service';
import { ServicesService } from 'src/app/shared/services/services.service';
import { AgencyService } from 'src/app/shared/services/agency.service';

@Component({
  selector: 'app-worker-home',
  templateUrl: './worker-home.component.html',
  styleUrls: ['./worker-home.component.css']
})

export class WorkerHomeComponent implements OnInit {
  public userType: string;
  workerId: number;
  agencyId: number;
  worker: any;
  workerInfo: any;
  workerProperty: any;
  jobCounts: any;
  trainings:any;
  trainingAll:any;
  jobs: any;
  fitToWork:any;
  complianceInformation:any;
  notes:any;
  editInfoForm: FormGroup;
  addTrainingForm: FormGroup;
  complianceInformationForm: FormGroup;
  addNoteForm: FormGroup;
  addJobForm: FormGroup;
  edit = false;
  edit2 = false;
  editCompliance = false;
  assignmentCodes = [{id:1,name:'001 Cleaner'},{id:2,name:'002 Driver'},{id:1,name:'003 Nurse'}];
  paymentMethods = [{id:1,name:'Paye'},{id:2,name:'Umbrella'},{id:1,name:'Pvt. Ltd'}];
  services = [];

  //trainingOptions = [{id:1,name:'Training 1'},{id:2,name:'Training 2'},{id:1,name:'Training 3'}];

  constructor(
    private activateRoute: ActivatedRoute,
    private workerService: WorkerService,
    private servicesService: ServicesService,
    private agencyService: AgencyService,
    private authService: AuthService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private storageService: StorageService
  ){
    this.userType = authService.getUserType();
    if(localStorage.getItem('agentId') != null){
      this.complianceInformationForm = this.fb.group({
        workerId: ['', Validators.required],
        agencyId: [this.storageService.decrypt(localStorage.getItem('agentId')), Validators.required]
      });
    }

    this.addTrainingForm = this.fb.group({
      agencyId: [this.agencyId,],
      workerId: [this.workerId,],
      name: ['',],
      code: ['',],
      // description: ['',],
      serviceId: ['', ],
      trainingId: ['', ],
      trainingDate: ['', Validators.required],
      trainingExpiry: ['', Validators.required],
    });

    this.addNoteForm = this.fb.group({
    });
    this.addJobForm = this.fb.group({
      assignmentCodeId: ['', Validators.required],
      paymentMethodId: ['', Validators.required]
    });



   }

  ngOnInit(): void {
    this.activateRoute.params.subscribe((params) => {
      this.workerId = params["id"]
    });
    this.agencyId = this.storageService.decrypt(localStorage.getItem('agentId'));
    // console.log(this.agencyId)
    this.getWorker();
    this.getAllServices();
    // this.getWorkerInfo();
    // this.getWorkerJobCount();
    // this.getWorkerJobs();
    this.getWorkerTrainings();
    this.getWorkComplianceInformation();
    // this.getWorkerNotes();
    this.getAllTrainings();


    this.editInfoForm = this.fb.group({
      workerId: [this.workerId, Validators.required],
      agencyId: [this.storageService.decrypt(localStorage.getItem('agentId')), Validators.required],
      paymentMethod: [''],
      contractEndDate: [''],
      nextCheckDate: [''],
      id:[this.workerId, Validators.required], /// This is a worker id
      employmentStartDate: [''],
      nationality: [''],
      dob: [''],
    });

    this.complianceInformationForm = this.fb.group({
      id:[this.workerId, Validators.required], /// This is a worker id
      workerId: [this.workerId, Validators.required],
      agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
      rightToWork: [this.complianceInformation?.rightToWork],
      restrictions: [this.complianceInformation?.restrictions],
      dbsNumber: [this.complianceInformation?.dbsNumber],
      expiry: [this.complianceInformation?.expiry]
    });

  }


  toggleEdit(){
    this.edit = !this.edit;

    // this.editInfoForm = this.fb.group({
    //   id: [this.worker.id, Validators.required],
    //   agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
    //   paymentMethod: [this.worker.paymentMethod, Validators.required],
    //   employmentStartDate: [this.worker.employmentStartDate, Validators.required],
    //   contractEndDate: [this.worker.contractEndDate, Validators.required],
    //   nextCheckDate: [this.worker.nextCheckDate, Validators.required],
    //   dob: [this.worker.dob],
    //   gender: [this.workerInfo.gender],
    //   nationality: [this.worker.nationality, Validators.required],
    //   assignmentCodeId: [this.worker.assignmentCodeId],
    //   // serviceId: [this.getServiceId(this.selectedAgency.service), Validators.required],
    //   // address: this.fb.group({
    //   //   firstLine: [this.selectedAgency.address.firstLine, Validators.required],
    //   //   postcode: [this.selectedAgency.address.postcode, Validators.required],
    //   //   town: [this.selectedAgency.address.town, Validators.required],
    //   // }),
    // });
  }
  toggleEdit2(){
    this.edit2 = !this.edit2;
  }
  toggleEditCompliance(){
    this.editCompliance = !this.editCompliance
  }
  toggleAddTraining (){

    this.editCompliance = !this.edit2;
  }

  getWorker(){

    this.workerService.getWorkerById(this.storageService.decrypt(localStorage.getItem('workerId'))).subscribe(
      data => {
        this.worker = data;
        // console.log(this.worker)
        // this.agencyId = data.agencyId;
      }
    );
  }

  getAllTrainings(){
    this.workerService.getAllTrainings().subscribe(
      data => {
        // console.log('datadatadatadatadata' ,data)
        this.trainingAll = data;
      }
    );
  }

  getAllServices(){
    this.servicesService.getAllServices().subscribe(
      data => {
        this.services = data;
        // console.log("----------------------",this.services)
      }
    );
  }

  // getWorkerInfo(){
  //   this.workerService.getWorkerInfoById(this.workerId).subscribe(
  //     data => {
  //       this.workerInfo = {id:data.id,assignmentCodeId:data.assignmentCodeId, employmentNumber : data.employmentNumber,nationality : data.nationality,dob : data.dob,gender : data.gender};
  //       let agencyId =  data?.agencyId ? data.agencyId : this.agencyId;
  //       if(  this.storageService.decrypt(localStorage.getItem('userType')) != 'client'   )
  //         this.agencyService.getAgencyWorkerProperties(this.workerId,agencyId).subscribe(
  //           dataPrWorkerProperty => {
  //             this.workerInfo = { id:data.id, assignmentCodeId:data.assignmentCodeId, employmentNumber : data.employmentNumber, paymentMethod :dataPrWorkerProperty?.paymentMethod , employmentStartDate : dataPrWorkerProperty?.employmentStartDate, contractEndDate : dataPrWorkerProperty?.contractEndDate , nextCheckDate :  dataPrWorkerProperty?.nextCheckDate, nationality : data.nationality,dob : data.dob,gender : data.gender};
  //           },
  //           err => { }
  //         );
  //     },
  //     err => {
  //
  //       if(err.status == 0){
  //         this.toast.error('Network Connection Failure');
  //       }
  //       else if(err.error?.message){
  //         this.toast.error(err.error.message);
  //       }else if(err.status == 500){
  //         this.toast.error('Internal Server Error')
  //       }
  //       else{
  //         this.toast.error('Request Failed: ' + err.status);
  //       }
  //     }
  //   );
  // }

  getWorkerJobCount(){
    this.workerService.getWorkerJobCount(this.workerId).subscribe(
      data => {
        this.jobCounts =
          [
            {asignmentCode : "678", jobs : "9", hrs : "88"},
            {asignmentCode : "105", jobs : "3", hrs : "60"},
            {asignmentCode : "307", jobs : "7", hrs : "95"},
            {asignmentCode : "624", jobs : "2", hrs : "66"},
          ]
        ;// delete this devip
        //this.jobCounts = data; // uncomment this devip
      }
    );
  }
  getWorkerJobs(){
    this.workerService.getWorkerJobs(this.workerId).subscribe(
      data => {
        this.jobs =
          [
            {id : "1", job : "Nurse"},
            {id : "2", job : "Cleaner"},
            {id : "3", job : "Driver"}
          ]
        ;// delete this devip
        //this.jobs = data; // uncomment this devip
      }
    );
  }

  getWorkerTrainings(){
    this.workerService.getWorkerTrainings(this.storageService.decrypt(localStorage.getItem('workerId'))).subscribe(
      data => {
        // this.trainings =
        //   [
        //     {id : "1", details : "lorem ipsum 1", issued : "24/05/2022", expires : "	12/12/2023"},
        //     {id : "2", details : "lorem ipsum 2", issued : "24/05/2022", expires : "	12/12/2023"},
        //     {id : "3", details : "lorem ipsum 3", issued : "24/05/2022", expires : "	12/12/2023"},
        //   ];
        // delete this devip
        this.trainings = data.content; // uncomment this devip
      }
    );
  }

  updateWorkerInfo(form: FormGroup){
    if(form.valid){
      console.log(form.value);
      this.workerService.updateWorkerInfo(form.value).subscribe(
        resp => {
          console.log("Worker details updated")
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }


  updateAgencyWorkerInfo(form: FormGroup){
    if(form.valid){
      console.log(form.value);
      this.agencyService.updateAgencyWorkerProperties(form.value).subscribe(
        resp => {
          this.toggleEdit();
          this.updateWorkerInfo(form)
          this.getWorkComplianceInformation();
          this.getWorker();
          this.toast.success('Worker Info Updated Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }


  toggleFitToWork(value){
    if(value == 'yes')
    {
      this.fitToWork = true;
    }
    else{
      this.fitToWork = false;
    }

  }

  submitFitToWork(){
    this.workerService.updateFitToWork(this.workerId,this.fitToWork).subscribe(
      resp => {
        this.getWorkerJobs();
        this.toast.success('Worker Fit To Work Updated Successfully');
      }
    );
  }

  addTraining(form: FormGroup){
    // console.table(form.value);
    if(form.valid){
      form.value.workerId =this.workerId ;
      form.value.agencyId =this.agencyId ;
      // console.log("----------", form.value);


      this.workerService.addTraining(form.value).subscribe(
        resp => {
          this.closeModals();
          this.getWorkerTrainings();
          this.toast.success('Worker Training Added Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  getWorkComplianceInformation(){
    // console.log(this.storageService.decrypt(localStorage.getItem('userType')))
    if(  this.storageService.decrypt(localStorage.getItem('userType')) == 'agency'   )
    {
      this.workerService.getWorkComplianceInformation(this.workerId,this.storageService.decrypt(localStorage.getItem('agentId'))).subscribe(
        data => {
          this.complianceInformation = data;
          console.log(this.complianceInformation)
        }
      );
    }
  }

  // updateComplianceInformation(form: FormGroup){
  //   // console.table(form.value);
  //   if(form.valid){
  //     this.workerService.updateComplianceInformation(form.value).subscribe(
  //       resp => {
  //         this.toggleEditCompliance();
  //         this.getWorkComplianceInformation();
  //         this.toast.success('Worker Compliance Information Updated Successfully');
  //       },
  //       err => {
  //
  //         if(err.status == 0){
  //           this.toast.error('Network Connection Failure');
  //         }
  //         else if(err.error?.message){
  //           this.toast.error(err.error.message);
  //         }else if(err.status == 500){
  //           this.toast.error('Internal Server Error')
  //         }
  //         else{
  //           this.toast.error('Request Failed: ' + err.status);
  //         }
  //       }
  //     );
  //   }
  //   else{
  //     this.toast.warning('Missing Required Info.');
  //   }
  // }

  getWorkerNotes(){
    this.workerService.getWorkerNotes(this.workerId).subscribe(
      data => {
        this.notes = [
          {id : "1", note : "Worker note 1"},
          {id : "2", note : "Worker note 2"},
          {id : "3", note : "Worker note 3"},
          {id : "4", note : "Worker note 4"},
        ]; // delete this devip
        //this.notes = data; // uncomment this devip
      }
    );
  }

  addNote(form: FormGroup){
    // console.table(form.value);
    if(form.valid){
      this.workerService.addNote(form.value).subscribe(
        resp => {
          this.closeModals();
          this.getWorkerNotes();
          this.toast.success('Worker Note Added Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  addJob(form: FormGroup){
    // console.table(form.value);
    if(form.valid){
      this.workerService.addJob(form.value).subscribe(
        resp => {
          this.closeModals();
          this.getWorkerJobs();
          this.toast.success('Worker Job Added Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  removeJob(id){
    this.workerService.removeJob(id).subscribe(
      resp => {
        this.getWorkerJobs();
        this.toast.success('Worker Removed Successfully');
      }
    );
}

  openModal(modal){
    this.modalService.open(modal, {centered: true});
    };

  closeModals(){
    this.modalService.dismissAll();
  };
}
