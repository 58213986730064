<div class="d-flex">
  <span><h5>Logs</h5></span>
</div>

<section class="">
    <table class="table table-striped mb-0 styled-table text-left">
      <thead>
          <tr class="text-left">
              <th>Id</th>
              <th>Date</th>
              <th>Driver</th>
              <th>Authoriser</th>
              <th>Actions</th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let d of logs;">
              <td>{{d.id}}</td>
              <td>{{d.createdDate | date: 'dd/MM/yy' }}</td>
              <td>{{d.worker.firstname}} {{d.worker.lastname}}</td>
              <td>{{d.approvedByName}}</td>
              <td >
                <span class="m-1 text-primary-custom pointer" (click)="viewDriverInfo(d, jobInfo)">
                  <i class="fa fa-eye" title="view"></i>
              </span>
              </td>
          </tr>
      </tbody>
    </table>
    <section class="pagination-container" *ngIf="logs">
      <div class="container-fluid">
        <div class="row m-0">
          <div class="col-12 col-sm-12 col-md-5 text-left acontent-center">
            Showing {{ (page - 1) * pageSize + 1 }} - {{ showin }} of {{ totalItems }}
          </div>
          <div class="col-12 col-sm-6 col-md-4 text-right">
            <button class="btn text-white" (click)="handlePageChange('prev')" [disabled]="first">
              <i class="fa fa-caret-up"></i>&nbsp;&nbsp;Previous Page
            </button>
          </div>
          <div class="col-12 col-sm-6 col-md-3 text-right pr-0">
            <button class="btn text-white" (click)="handlePageChange('next')" [disabled]="last">
              Next Page&nbsp;&nbsp;<i class="fa fa-caret-down"></i>
            </button>
          </div>
        </div>
      </div>
    </section>
    <div class=" col-12 mt-2 text-right" >
      <button type="button" class="btn-danger text-right mr-1" (click)="prevTab()" >Back</button>
      <button type="submit" class="btn-primary text-right" (click)="nextTab();">Next</button>
    </div>
</section>


<ng-template #jobInfo let-modal>
  <driver-info-modal [log]="selectedLog" ></driver-info-modal>
</ng-template>