<main class="mt-5">

    <!-- Header part -->
    <div class="container-fluid">
        <div class="row text-white">
            <div class="co-sm-12 col-md-6 col-lg-4 text-center">
                <div class="bg-agency-card w-100 text-center">
                    <h4 class="">Agencies</h4>
                    <h3 class="font-weight-bold">123</h3>
                </div>
            </div>
            <div class="co-sm-12 col-md-6 col-lg-4 mt-3 mt-md-0 w-100 text-center">
                <div class="bg-client-card text-center">
                    <h4>Clients</h4>
                    <h3 class="font-weight-bold">541</h3>
                </div>
            </div>
            <div class="co-sm-12 col-md-12 col-lg-4 mt-3 mt-lg-0 w-100 text-center">
                <div class="bg-worker-card text-center">
                    <h4>Flexi-Workers</h4>
                    <h3 class="font-weight-bold">321</h3>
                </div>
            </div>
        </div>
    </div>

    <!-- Content -->
    <section class="content mt-5">
        <div class="container-fluid">
            <figure>
                <img src="/assets/images/svg/dashboard-graph.svg" alt="dashboard graph" class="w-100">
            </figure>
        </div>
    </section>
</main>