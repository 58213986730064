<main class="mt-5">
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,1,0" />
  <!-- header part -->
  <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 text-left" *ngIf="worker">
          <span class="text-decoration-none" style="font-size: xx-large;">Trainings & Qualifications</span>
        </div>
        <!-- <div class="col-12 col-sm-6 col-md-4 text-md-right text-sm-left mt-2 mt-md-0">

        </div> -->
      </div>
  </div>

  <!-- content -->
  <section class="content mt-4">
      <div class="container-fluid">
          <div class="row" *ngIf="worker">
              <div class="col-md-12">
                  <div class="card">
                    <div class="">
                      <div class=" tab-column">
                          <div class="tab-column-inner tab-column-inner-bottom">
                              <div class="text-right">
                                <span>
                                  <button class="btn btn-success ml-auto" *ngIf="userType == 'worker'" (click)="openModal(add)">Upload Qualifications</button>
                                </span>
                                <span>
                                  <a class="btn btn-success ml-1 text-white" style=" width: 170px;"  href="http://app.ihasco.co.uk/yvydkz" target="_blank" ><img class="nav-icon" src="/assets/images/svg/trainings.svg" alt="trainings">Online Training</a>
                                </span>

                              </div>
                          </div>
                          <div class="tab-column-inner ">
                              <div class="tab-content-info">
                                  <table id="customers">
                                      <tr >
                                      <th>Name</th>
                                      <th>Uploaded</th>
                                      <th>Expires</th>
                                      <!-- <th class="text-center">Status</th> -->
                                      <th class="text-center">Actions</th>
                                      </tr >

                                      <tr *ngFor="let training of trainings;">
                                      <td>{{training.name}}</td>
                                      <!-- <td>{{training.description}}</td> -->
                                      <td>{{training.trainingDate}}</td>
                                      <td>{{training.trainingExpiry}}

                                        <img *ngIf="checkExpiry(training.trainingExpiry) == 'alert'" style="width:15px"  title="About to expire" alt="warning icon" src="/assets/images/alert_caution.png">
                                        <img *ngIf="checkExpiry(training.trainingExpiry) == 'expired'" style="width:15px"  title="Expired" alt="danger icon" src="/assets/images/alert_danger.png">

                                      </td>

                                      <td class="text-center" >
                                        <span class="mr-3 text-primary-custom pointer" (click)="selectedTraining(training); openModal(view)">
                                          <i class="fa fa-eye" title="view"></i>
                                        </span>
                                        <span *ngIf="training.type=='EXTERNAL'" class="mr-3 text-success pointer" title="Edit"  (click)="selectedTraining(training); openModal(edit)">
                                          <i class="fa fa-pencil-alt" title="edit"></i>
                                        </span>
                                        <span *ngIf="training.type=='EXTERNAL'" class="mr-3 text-danger-custom pointer" title="Delete"  (click)="selectedTraining(training); openModal(delete)">
                                          <i class="fa fa-trash" title="delete"></i>
                                        </span>
                                      </td>
                                      </tr>
                                  </table>
                              </div>
                          </div>

                      </div>
                  </div>
                  </div>
              </div>
          </div>
      </div>
  </section>

  <!-- Add Training & Qualifications Modal -->
  <ng-template class="modal fade" #add let-modal>
    <app-worker-training-modal></app-worker-training-modal>
  </ng-template>

  <!-- Add Training & Qualifications Modal -->
  <ng-template class="modal fade" #edit let-modal>
    <div class="">
      <div class="modal-header bg-main text-white">
        <h5 class="modal-title text-center mx-4 w-100">Upload Certificate</h5>
        <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
          (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </span>
      </div>
      <div class="modal-body">
        <form [formGroup]="editTrainingForm" (ngSubmit)="updateTraining(editTrainingForm)">
          <input type="hidden" formControlName="agencyId" value="{{agencyId}}" />
          <input type="hidden" formControlName="workerId" value="{{workerId}}" />
          <input formControlName="code" type="hidden"  placeholder="Code"
                      class="form-control">
          <input formControlName="code" type="hidden"  placeholder="Code"
                      class="form-control">
          <input type="hidden" formControlName="serviceId" value="1" />
          <div class="bg-modal card-body">
            <div class="container-fluid">
              <div class="">
                <div class="">
                  <i class="bi bi-info-circle"></i>
                  <span class="ml-2">Training</span>
                  <hr class="bg-black mt-0">
                </div>
                <!-- <div class="">
                  <div class="form-group">
                    <label for="" class="modal-label">Name</label>
                    <input formControlName="name" type="hidden"  placeholder="Name"
                      class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="" class="modal-label">Code</label>
                    <input formControlName="code" type="hidden"  placeholder="Code"
                      class="form-control">
                  </div>
                </div> -->
                <div class="">
                  <div class="form-group">
                    <input    type="text" id="file" disabled class="form-control" value="{{editTrainingForm.value.name}}">

                    <!-- <select formControlName="trainingId" disabled class="form-control w-content" style="width:400px">
                      <option value="" selected disabled>Select training</option>
                      <option *ngFor="let training of trainingAll;" value="{{training.id}}">{{training.name}}</option>
                    </select> -->
                  </div>
                  <div class="form-group">
                    <label for="" class="modal-label">Certificate</label><br>
                      <input    type="file" id="file" (change)="onChange($event)" accept="application/pdf">
                      <!-- <input  formControlName="workerId" type="hidden" [ngModel]="workerId"> -->
                  </div>
                </div>
                <div class="">
                  <div class="form-group">
                    <label for="" class="modal-label">Issue Date</label>
                    <input formControlName="trainingDate" disabled type="text"  placeholder="Issue Date"
                      class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="" class="modal-label">ExpiryDate</label>
                    <input formControlName="trainingExpiry" disabled type="text"  placeholder="ExpiryDate"
                      class="form-control">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div align="right" class="mt-3">
            <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
              Cancel
            </button>
            <button type="submit" class="btn btn-sm btn-success">
              Upload Certificate
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
  <!-- Add Training & Qualifications Modal -->
  <ng-template class="modal fade" #delete let-modal>
    <div class="">
      <div class="modal-header bg-main text-white">
        <h5 class="modal-title text-center mx-4 w-100">Delete Training</h5>
        <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
          (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </span>
      </div>
      <div class="modal-body">
        <form [formGroup]="editTrainingForm" (ngSubmit)="deleteTraining(editTrainingForm)">
          <p class="text-danger-custom my-1">Are you sure you want to delete this training?</p>

          <input type="hidden" formControlName="agencyId" value="{{agencyId}}" />
          <input type="hidden" formControlName="workerId" value="{{workerId}}" />
          <input formControlName="code" type="hidden"  placeholder="Code"
                      class="form-control">
          <input formControlName="code" type="hidden"  placeholder="Code"
                      class="form-control">
          <input type="hidden" formControlName="serviceId" value="1" />
          <div class="bg-modal card-body">
            <div class="container-fluid">
              <div class="">
                <div class="">
                  <i class="bi bi-info-circle"></i>
                  <span class="ml-2">Training</span>
                  <hr class="bg-black mt-0">
                </div>
                <!-- <div class="">
                  <div class="form-group">
                    <label for="" class="modal-label">Name</label>
                    <input formControlName="name" type="hidden"  placeholder="Name"
                      class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="" class="modal-label">Code</label>
                    <input formControlName="code" type="hidden"  placeholder="Code"
                      class="form-control">
                  </div>
                </div> -->
                <div class="">
                  <div class="form-group">
                    <input formControlName="name" disabled type="text" disabled class="form-control">

                    <!-- <select formControlName="trainingId" disabled class="form-control w-content" style="width:400px">
                      <option value="" selected disabled>Select training</option>
                      <option *ngFor="let training of trainingAll;" value="{{training.id}}">{{training.name}}</option>
                    </select> -->
                  </div>
                </div>
                <div class="">
                  <div class="form-group">
                    <label for="" class="modal-label">Issue Date</label>
                    <input formControlName="trainingDate" disabled type="text"  placeholder="Issue Date"class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="" class="modal-label">ExpiryDate</label>
                    <input formControlName="trainingExpiry" disabled type="text"  placeholder="ExpiryDate"
                      class="form-control">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div align="right" class="mt-3">
            <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
              Cancel
            </button>
            <button type="submit" class="btn btn-sm btn-success">
              Delete
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</main>


<!-- Modals -->
<!-- Modals -->
<ng-template class="modal fade" #view let-modal>
  <div class="">
    <div class="modal-header bg-main text-white">
      <h5 class="modal-title text-center w-100">View DOCUMENT</h5>
      <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
        (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </span>
    </div>
    <div class="modal-body">
      <form [formGroup]="updateTrainingForm">
        <div class="bg-modal card-body">
          <div class="container-fluid">
            <section>
                <div *ngIf="activeTraining.type=='EXTERNAL'" class="col-12">
                  <i class="bi bi-info-circle"></i>
                  <!-- <a *ngIf="activeTraining.document" [href]="activeTraining.document" target="_blank"> Click To View Document</a> -->
                  <a *ngIf="activeTraining.document" (click)="openDoc(activeTraining.document)" target="_blank"> Click To View Document</a>

                  <p *ngIf="!activeTraining.document" >Document Not Uploaded</p>
                  <hr class="bg-black mt-0">
                </div>
                <div *ngIf="activeTraining.type=='PHYSICAL'" class="col-12">
                  <i class="bi bi-info-circle"></i>
                  Physical Training<br>
                  <!-- <a >Certificate Design</a> -->
                  <a  *ngIf="activeTraining.showCertificate==true"  routerLink="/worker/certificate/{{activeTraining.id}}" class="text-primary-custom" > Click To View Certificate</a>
                  <p  *ngIf="activeTraining.showCertificate==false||activeTraining.showCertificate==null"   >Certificate was not issued</p>
                  <hr class="bg-black mt-0">
                </div>

            </section>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
