import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkerService {

  //base_url: string = environment.API_url + ':8300/api/v1';
  base_url: string = environment.API_url + '/worklink-api/api/v1';

  // constructor(
  //   private http: HttpClient

  // ) { }



  constructor(
    private http: HttpClient,
    private httpNew: HttpClient,
    handler: HttpBackend ) {
    this.httpNew = new HttpClient(handler);
}

  getPaginatedWorkers(page, size): Observable<any> {
    return this.http.get(`${this.base_url}/workers/${page}/${size}`);
  }

  getPaginatedApplicants(page, size): Observable<any> {
    return this.http.get(`${this.base_url}/applicants/${page}/${size}`);
  }

  createWorker(data): Observable<any> {
    return this.http.post(`${this.base_url}/worker`, data);
  }

  updateWorker(data): Observable<any> {
    return this.http.put(`${this.base_url}/worker`, data);
  }

  getWorkerById(ref): Observable<any> {
    return this.http.get(`${this.base_url}/worker/${ref}`);
  }

  searchWorkers(searchCriteria, agencyId, page, size): Observable<any> {
    return this.http.get(`${this.base_url}/worker-search/${agencyId}/${page}/${size}?searchCriteria=${searchCriteria}`);
  }

  searchAgencyWorkers(searchCriteria, agencyId, page, size): Observable<any> {
    return this.http.get(`${this.base_url}/worker-search/agency/${agencyId}/${page}/${size}?searchCriteria=${searchCriteria}`);
  }

  searchAgencyApplicants(searchCriteria, agencyId, page, size): Observable<any> {
    return this.http.get(`${this.base_url}/applicant-search/agency/${agencyId}/${page}/${size}?searchCriteria=${searchCriteria}`);
  }

  searchClientWorkers(searchCriteria, agencyId, page, size): Observable<any> {
    return this.http.get(`${this.base_url}/worker-search/client/${agencyId}/${page}/${size}?searchCriteria=${searchCriteria}`);
  }

  getWorkerInfoById(ref): Observable<any> {
    return this.http.get(`${this.base_url}/worker/${ref}`);
  }
  getWorkerJobCount(ref): Observable<any> {
    return this.http.get(`${this.base_url}/worker/${ref}`); // delete this devip
    //return this.http.get(`${this.base_url}/workerjobcount/${ref}`); // uncomment this devip
  }
  getWorkerJobs(ref): Observable<any> {
    return this.http.get(`${this.base_url}/worker/${ref}`); // delete this devip
    //return this.http.get(`${this.base_url}/get-worker-jobs/${ref}`); // uncomment this devip
  }
  updateWorkerInfo(data): Observable<any> {
    return this.http.put(`${this.base_url}/worker`, data);
  }
  getWorkerTrainings(ref): Observable<any> {
    //return this.http.get(`${this.base_url}/worker/76`); // delete this devip
    return this.http.get(`${this.base_url}/worker-training/${ref}/0/1000000`); // uncomment this devip
  }


  downloadPDF(url: string): Observable<any> {
        const options = { responseType: 'blob' as 'json' };
        let me = this.httpNew
       .get<any>(url, options);
       return me.pipe(map(res => new Blob([res], { type: 'application/pdf'})));
 }


 getContentTpye(url: string): Observable<any> {
   return this.httpNew.get<any>(url, {observe: 'response', responseType: 'blob' as 'json'})
 }

  getOnlineTrainingCertificate(enrolmentId): Observable<any> {
    //return this.http.get(`${this.base_url}/worker/76`); // delete this devip
    return this.http.get(`${this.base_url}/online-trainings/certificate/${enrolmentId}`); // uncomment this devip
  }

  getAgencyWorkerTrainings(workerId, agencyId): Observable<any> {
    //return this.http.get(`${this.base_url}/worker/76`); // delete this devip
    return this.http.get(`${this.base_url}/worker-training/${workerId}/${agencyId}/0/1000000`); // uncomment this devip
  }
  getAgencyWorkerCompliances(workerId, agencyId): Observable<any> {
    //return this.http.get(`${this.base_url}/worker/76`); // delete this devip
    return this.http.get(`${this.base_url}/worker-compliance/${workerId}/${agencyId}/0/1000000`); // uncomment this devip
  }
  getWorkerCompliances(ref): Observable<any> {
    //return this.http.get(`${this.base_url}/worker/76`); // delete this devip
    return this.http.get(`${this.base_url}/worker-compliance/${ref}/0/1000000`); // uncomment this devip
  }
  getWorkerForms(ref): Observable<any> {
    //return this.http.get(`${this.base_url}/worker/76`); // delete this devip
    return this.http.get(`${this.base_url}/worker-form/${ref}/0/1000000`); // uncomment this devip
  }
  updateFitToWork(workerId,status): Observable<any> {
    return this.http.get(`${this.base_url}/worker/76`); // delete this devip
    //return this.http.put(`${this.base_url}/workers-training/${workerId}/${status}`);
  }
  addTraining(data, file?): Observable<any> {
    const formulario = new FormData();
    Object.keys(data).forEach((key) => {
      formulario.append(key, data[key]);
    });
    formulario.append('file', file, file.name);
    return this.http.post(`${this.base_url}/worker-training`, formulario);
  }
  delteTraining(id): Observable<any> {
    //return this.http.get(`${this.base_url}/worker/76`); // delete this devip
    return this.http.delete(`${this.base_url}/worker-training/${id}`);
  }
  deleteCompliance(id): Observable<any> {
    //return this.http.get(`${this.base_url}/worker/76`); // delete this devip
    return this.http.delete(`${this.base_url}/worker-compliance/${id}`);
  }
  updateTraining(data): Observable<any> {
    //return this.http.get(`${this.base_url}/worker/76`); // delete this devip
    return this.http.put(`${this.base_url}/worker-training`, data);
  }
  addCompliance(data, file?): Observable<any> {
    const formulario = new FormData();
    Object.keys(data).forEach((key) => {
      formulario.append(key, data[key]);
    });
    formulario.append('file', file, file.name);
    return this.http.post(`${this.base_url}/worker-compliance`, formulario);
  }
  addForm(data): Observable<any> {
    //return this.http.get(`${this.base_url}/worker/76`); // delete this devip
    return this.http.post(`${this.base_url}/worker-form`, data);
  }
  updateCompliance(data): Observable<any> {
    //return this.http.get(`${this.base_url}/worker/76`); // delete this devip
    return this.http.put(`${this.base_url}/worker-compliance`, data);
  }
  updateForm(data): Observable<any> {
    //return this.http.get(`${this.base_url}/worker/76`); // delete this devip
    return this.http.put(`${this.base_url}/worker-form`, data);
  }
  getWorkComplianceInformation(workerId,agencyId): Observable<any> {
    return this.http.get(`${this.base_url}/agency-worker-properties/${workerId}/${agencyId}`);
  }
  updateComplianceInformation(data): Observable<any> {
    return this.http.post(`${this.base_url}/agency-worker-properties`, data);
  }
  getWorkerNotes(ref): Observable<any> {
    return this.http.get(`${this.base_url}/worker/${ref}`); // delete this devip
    //return this.http.get(`${this.base_url}/get-worker-notes/${ref}`); // uncomment this devip
  }
  addNote(data): Observable<any> {
    return this.http.get(`${this.base_url}/worker/76`); // delete this devip
    //return this.http.post(`${this.base_url}/workers-note/`, data);
  }
  getWorkerAvailableDate(workerId): Observable<any> {
    //return this.http.get(`${this.base_url}/worker/76`); // delete this devip
    return this.http.get(`${this.base_url}/availability/${workerId}/0/100000`);
  }
  addJob(data): Observable<any> {
    return this.http.get(`${this.base_url}/worker/76`); // delete this devip
    //return this.http.post(`${this.base_url}/workers-job/`, data);
  }
  removeJob(ref): Observable<any> {
    return this.http.get(`${this.base_url}/worker/76`); // delete this devip
    //return this.http.delete(`${this.base_url}/worker-job/${ref}`);
  }

  deleteWorker(ref): Observable<any> {
    return this.http.delete(`${this.base_url}/worker/${ref}`);
  }

  getPaginatedWorkerAgencies(page, size, worker): Observable<any> {
    return this.http.get(`${this.base_url}/worker-agencies/${worker}/${page}/${size}`);
  }

  getPaginatedWorkerAgencyShifts(page, size, worker): Observable<any> {
    return this.http.get(`${this.base_url}/worker-agency-shift/${worker}/${page}/${size}`);
  }

  getPaginatedClientWorkersForBooking(page, size, assCode, client, gender): Observable<any> {
    return this.http.get(`${this.base_url}/workers-client/${page}/${size}?assignmentCodeId=${assCode}&clientId=${client}&gender=${gender}`);
  }

  getWorkerShifts(page, size, worker,status): Observable<any> {
    return this.http.get(`${this.base_url}/worker-agency-shift-status/${worker}/${page}/${size}/${status}`);
  }

  getWorkersUnderAgency(code, data, gender): Observable<any> {
    return this.http.post(`${this.base_url}/workers-under-agency/${code}/${gender}`, data);
  }

  linkWorkerToAgent(worker, agent): Observable<any> {
     // return this.http.put(`${this.base_url}/worker-agency/${worker}/${agent}`, null);
    return this.http.put(`${this.base_url}/worker-link/${worker}/${agent}`, null);
  }

  getAllTrainings(): Observable<any> {
    return this.http.get(`${this.base_url}/training`);
  }

  getAllCompliances(): Observable<any> {
    return this.http.get(`${this.base_url}/compliance`);
  }
  getAllForms(): Observable<any> {
    return this.http.get(`${this.base_url}/forms`);
  }

  updateWorkerBank(data): Observable<any> {
    return this.http.put(`${this.base_url}/bank`, data);
  }

  addWorkerBank(data): Observable<any> {
    return this.http.post(`${this.base_url}/bank`, data);
  }
  getWorkerBank(ref): Observable<any> {

    return this.http.get(`${this.base_url}/bank/worker/${ref}`);
  }

   openDoc(url){
    this.getContentTpye(url).subscribe(res => {
      if(res.headers.get('Content-Type')!='application/pdf') {
        return window.open(url);
      }else{
        this.viewDoc(url);
      }
    });
  }

  viewDoc(url){
    this.downloadPDF(url).subscribe(res => {
      console.log(res);
      if(res==null)
        window.open(url, '_blank');
      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, '_blank');
    });
  }

}
