<main class="mt-5">
  <!-- Header part -->
  <div class="container-fluid">
    <div class="row aitems-center">
      <div class="col-12 col-sm-12 col-md-4 text-left">
        <h3 class="mb-0">Shifts</h3>
      </div>
      <!-- <div class="col-12 col-sm-12 col-md-8 text-left">
        <div class="input-group">
          <input type="text" maxlength="255" class="form-control col-5 border-right-0" placeholder="Search"
            aria-describedby="inputGroupPrepend2" required />
          <div class="input-group-prepend">
            <span class="input-group-text bg-transparent border-left-0" id="inputGroupPrepend2">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </div>
      </div> -->
    </div>
  </div>

  <!-- content -->
  <section class="content mt-4">
    <div class="container-fluid">
      <!-- <div class="row">
        <div class="col-12 col-sm-6 col-md-2 text-right pt-2">
          <span class="text-black-50">Filters</span>
        </div>
        <div class="col-12 col-sm-6 col-md-2 text-left">
          <select name="" class="form-control" [(ngModel)]="agencyFilter">
            <option value="" selected  disabled >Select Agency</option>
            <option *ngFor="let a of agencies" [value]="a.id" >{{ a.name }}</option>
          </select>
        </div>
        <div class="col-12 col-sm-12 col-md-2 text-left mt-2 mt-md-0">
          <select name="" class="form-control" [(ngModel)]="clientFilter">
            <option value="" selected disabled >Select Client</option>
            <option *ngFor="let c of clients" [value]="c.name" >{{ c.name }}</option>
          </select>
        </div>
        <div class="col-12 col-sm-12 col-md-4 text-left mt-2 mt-md-0">
          <div class="input-group">
            <input type="date" class="form-control" [(ngModel)]="startDateFilter">
            <input type="date" class="form-control" [(ngModel)]="endDateFilter">
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-2 text-left ">
          <div class="input-group">
            <p (click)="clearFilters()" class="btn">Clear</p>
          </div>
        </div>
      </div> -->
    </div>
    <div class="container-fluid mt-4">
      <div class="row">
        <div class="col-6 ml-3 pointer col-sm-4 col-md-1 text-left" (click)="selectTab('new')"
          [ngClass]="{'bg-custom-danger': selectedTab == 'new','bg-custom-unselected': selectedTab != 'new'}" style="border-top-left-radius: 5px;">New
          <br><span  class="badge  btn-dark"  [ngClass]="{'badge-light': selectedTab == 'new'}" >{{shiftsSummary.newShift!=0?shiftsSummary.newShift:null}}</span>
        </div>
        <div class="col-6 col-sm-4 col-md-2 pointer text-left" (click)="selectTab('applied')"
          [ngClass]="{'bg-custom-danger': selectedTab == 'applied','bg-custom-unselected': selectedTab != 'applied'}">Applied
          <br><span  class="badge  btn-dark"  [ngClass]="{'badge-light': selectedTab == 'applied'}" >{{shiftsSummary.applied!=0?shiftsSummary.applied:null}}</span>
        </div>
        <div class="col-6 col-sm-4 col-md-1 pointer text-left" (click)="selectTab('booked')"
          [ngClass]="{'bg-custom-danger': selectedTab == 'booked','bg-custom-unselected': selectedTab != 'booked'}">Booked
          <br><span  class="badge  btn-dark"  [ngClass]="{'badge-light': selectedTab == 'booked'}" >{{shiftsSummary.booked!=0?shiftsSummary.booked:null}}</span>
        </div>
        <div class="col-6 col-sm-6 col-md-2 pointer text-left" (click)="selectTab('awaiting')"
          [ngClass]="{'bg-custom-danger': selectedTab == 'awaiting','bg-custom-unselected': selectedTab != 'awaiting'}">Waiting Auth
          <br><span  class="badge  btn-dark"  [ngClass]="{'badge-light': selectedTab == 'awaiting'}" >{{shiftsSummary.awaiting!=0?shiftsSummary.awaiting:null}}</span>
        </div>
        <div class="col-6 col-sm-4 col-md-2 pointer text-left" (click)="selectTab('authorized')"
          [ngClass]="{'bg-custom-danger': selectedTab == 'authorized','bg-custom-unselected': selectedTab != 'authorized'}">Authorized
          <br><span  class="badge  btn-dark"  [ngClass]="{'badge-light': selectedTab == 'authorized'}" >{{shiftsSummary.authorized!=0?shiftsSummary.authorized:null}}</span>
        </div>
        <div class="col-6 col-sm-4 col-md-2 pointer text-left" (click)="selectTab('queried')"
          [ngClass]="{'bg-custom-danger': selectedTab == 'queried','bg-custom-unselected': selectedTab != 'queried'}">Queried
          <br><span  class="badge  btn-dark"  [ngClass]="{'badge-light': selectedTab == 'queried'}" >{{shiftsSummary.queried!=0?shiftsSummary.queried:null}}</span>
        </div>
        <div class="col-6 col-sm-4 col-md-1 pointer text-center" (click)="selectTab('closed')"
          [ngClass]="{'bg-custom-danger': selectedTab == 'closed','bg-custom-unselected': selectedTab != 'closed'}" style="border-top-right-radius: 5px;">Closed
          <br><span  class="badge  btn-dark"  [ngClass]="{'badge-light': selectedTab == 'closed'}" >{{shiftsSummary.closed!=0?shiftsSummary.closed:null}}</span>
        </div>
      </div>
    </div>
    <div class="container-fluid mt-1">
        <div *ngIf="selectedTab == 'new'">
            <app-new-shifts

                        [agencyFilter]  =    "agencyFilter"
            [directorateFilter]  =    "directorateFilter"
            [clientFilter]  =    "clientFilter"
            [startDateFilter]  = "startDateFilter"
            [endDateFilter]  = "endDateFilter"

            ></app-new-shifts>
        </div>
        <div *ngIf="selectedTab == 'applied'">
            <app-applied-shifts
                        [agencyFilter]  =    "agencyFilter"
            [directorateFilter]  =    "directorateFilter"
            [clientFilter]  =    "clientFilter"
            [startDateFilter]  = "startDateFilter"
            [endDateFilter]  = "endDateFilter"
            ></app-applied-shifts>
        </div>
        <div *ngIf="selectedTab == 'booked'">
            <app-booked-shifts
                        [agencyFilter]  =    "agencyFilter"
            [directorateFilter]  =    "directorateFilter"
            [clientFilter]  =    "clientFilter"
            [startDateFilter]  = "startDateFilter"
            [endDateFilter]  = "endDateFilter"
            ></app-booked-shifts>
        </div>
        <div *ngIf="selectedTab == 'awaiting'">
            <app-awaiting-shifts
                        [agencyFilter]  =    "agencyFilter"
            [directorateFilter]  =    "directorateFilter"
            [clientFilter]  =    "clientFilter"
            [startDateFilter]  = "startDateFilter"
            [endDateFilter]  = "endDateFilter"
            ></app-awaiting-shifts>
        </div>
        <div *ngIf="selectedTab == 'authorized'">
            <app-authorized-shifts
                        [agencyFilter]  =    "agencyFilter"
            [directorateFilter]  =    "directorateFilter"
            [clientFilter]  =    "clientFilter"
            [startDateFilter]  = "startDateFilter"
            [endDateFilter]  = "endDateFilter"
            ></app-authorized-shifts>
        </div>
        <div *ngIf="selectedTab == 'queried'">
            <app-queried-shifts
                        [agencyFilter]  =    "agencyFilter"
            [directorateFilter]  =    "directorateFilter"
            [clientFilter]  =    "clientFilter"
            [startDateFilter]  = "startDateFilter"
            [endDateFilter]  = "endDateFilter"
            ></app-queried-shifts>
        </div>
        <div *ngIf="selectedTab == 'closed'">
            <app-closed-shifts
                        [agencyFilter]  =    "agencyFilter"
            [directorateFilter]  =    "directorateFilter"
            [clientFilter]  =    "clientFilter"
            [startDateFilter]  = "startDateFilter"
            [endDateFilter]  = "endDateFilter"
            ></app-closed-shifts>
        </div>
    </div>
  </section>


</main>
