import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TransportService } from 'src/app/shared/services/transport.service';
import { UsersService } from 'src/app/shared/services/users.service';

@Component({
  selector: 'app-leader-entry2',
  templateUrl: './leader-entry1.component.html',

})
export class LeaderEntry1Component implements OnChanges {

  @Input('form') form:FormGroup;
  @Input('job') job:any;
  @Input('tabindex') tabindex:number;
  @Output() formO = new EventEmitter<FormGroup>();
  @Output() tabindexO = new EventEmitter<number>();

  propertyListForm: FormGroup;

  constructor(
    private toast: ToastrService,
    private transporService: TransportService,
    private storageService: StorageService,
    private agencyService: AgencyService,
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnChanges(): void {
    while(this.form.value.propertyList==null || this.form.value.propertyList.length<15){
      if(this.form.value.propertyList==null)
        this.form.patchValue({propertyList: []});
      else
      this.form.patchValue({propertyList: [...this.form.value.propertyList, ""]});
    }
  }

  replaceStringAtIndex(arr: string[], index: number, replacement: string): string[] {
    if (index >= 0 && index < arr.length) {
        arr[index] = replacement;
    }
    return arr;
  }

  updateItem(i, event){

    var arr = [];
    arr = this.form.value.propertyList;

    var arr2 = this.replaceStringAtIndex(arr,i,event.target.value);

    console.log(arr2,this.form.value.propertyList );

    this.form.patchValue({propertyList: arr2});
  }

  getValue(i){
    return this.form.value.propertyList[i];
  }

  nextTab(){
    ++this.tabindex
    if(this.tabindex>4)this.tabindex =4;
    this.tabindexO.emit(this.tabindex);
    this.formO.emit(this.form);
  }

  prevTab(){
    --this.tabindex;
    if(this.tabindex<1)this.tabindex =1;
    this.tabindexO.emit(this.tabindex);
    this.formO.emit(this.form);
  }

  updateMileage() {
    this.transporService.updateMileage(this.form.value).subscribe(
      data => { this.toast.success("Info updated")}
    );
   }


updateTeamLeader(){
   this.transporService.updateTeamLeader(this.form.value).subscribe(
   data=> { this.toast.success("Property List Updated")
   })
}


}
