import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  //base_url: string = environment.API_url + ':8300/api/v1';
  base_url: string = environment.API_url + '/worklink-api/api/v1';
  base_url2: string = environment.API_url + '/forms-service/api/v1';

  constructor(
    private http: HttpClient
  ) { }

  downloadFile(ref): Observable<any> {
    return this.http.get(`${this.base_url}/download/${ref}`, {responseType: 'blob'});
  }

  uploadDocument(file: File): Observable<HttpEvent<any>> {

    const formData: FormData = new FormData();

    const uploadUrl = `${this.base_url}/upload`;

    formData.append('file', file);

    const uploadRequest = new HttpRequest('POST', uploadUrl, formData, {
      reportProgress: true,
      // responseType: 'json'
    });

    return this.http.request(uploadRequest);
  }

  uploadPayslip(file: File,workerId, agencyId): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('workerId', workerId);
    formData.append('agencyId', agencyId);

    return this.http.post(`${this.base_url}/payslip-upload`, formData);
  }

  broadcast(form, file?): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('title', form.title);
    formData.append('body', form.body);
    formData.append('sendToAll', form.sendToAll);
    formData.append('sendToAssCodes', form.sendToAssCodes);
    formData.append('sendToIds', form.sendToIds);
    formData.append('sendToType', form.sendToType);
    if(file){formData.append('file',  file, file?.name)}
    else{formData.append('file',  null)};
    formData.append('senderType', form.senderType);
    formData.append('sendingAgencyId', form.sendingAgencyId);
    formData.append('sendingClientId', form.sendingClientId);

    console.log('File availability',file);
    if(file){
      return this.http.post(`${this.base_url}/broadcast-attachment`, formData);
    }else{
      return this.http.post(`${this.base_url}/broadcast`, formData);
    }

  }

  agencyUploadLogo(file: File, agencyId): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('agencyId', agencyId);

    return this.http.post(`${this.base_url}/agency/logo?agencyId=${agencyId}`, formData);
  }

  uploadApplicationSig(file: File, workerId): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('workerId', workerId);

    return this.http.post(`${this.base_url2}/worker-application/general-upload`, formData);
  }

  uploadHmrcSig(file: File, workerId): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('workerId', workerId);

    return this.http.post(`${this.base_url2}/hmrc/signature-upload`, formData);
  }
  uploadBankSig(file: File, workerId): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('workerId', workerId);

    return this.http.post(`${this.base_url2}/worker-banking/banking-upload`, formData);
  }

  uploadSafeguardingSig(file: File, workerId): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('workerId', workerId);

    return this.http.post(`${this.base_url2}/worker-application/safeguarding-upload`, formData);
  }


  uploadHepc(file: File, workerId): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('workerId', workerId);

    return this.http.post(`${this.base_url2}/occupational/hepc-upload`, formData);
  }

  uploadOccupationalSignature(file: File, workerId): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('workerId', workerId);

    return this.http.post(`${this.base_url2}/occupational/signature-upload`, formData);
  }

  uploadHiv(file: File, workerId): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('workerId', workerId);

    return this.http.post(`${this.base_url2}/occupational/hiv-upload`, formData);
  }

  uploadHepbs(file: File, workerId): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('workerId', workerId);

    return this.http.post(`${this.base_url2}/occupational/hepbs-upload`, formData);
  }

  uploadHepb(file: File, workerId): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('workerId', workerId);

    return this.http.post(`${this.base_url2}/occupational/hepb-upload`, formData);
  }

  uploadRub(file: File, workerId): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('workerId', workerId);

    return this.http.post(`${this.base_url2}/occupational/mmr-upload`, formData);
  }

  uploadTb(file: File, workerId): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('workerId', workerId);

    return this.http.post(`${this.base_url2}/occupational/tb-upload`, formData);
  }

  uploadVar(file: File, workerId): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('workerId', workerId);

    return this.http.post(`${this.base_url2}/occupational/varicella-upload`, formData);
  }




  workerUploadTraining(file: File,training, workerId): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('workerId', workerId);
    formData.append('training', training);


    return this.http.post(`${this.base_url}/worker/upload-training-doc?workerId=${workerId}?training=${training}`, formData);
  }
  workerUploadCompliance(file: File,compliance, workerId): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('workerId', workerId);
    formData.append('compliance', compliance);

    return this.http.post(`${this.base_url}/worker/upload-compliance-doc?workerId=${workerId}?compliance=${compliance}`, formData);
  }


  uploadBacsSummary(file: File, agencyId): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('agencyId', agencyId);

    return this.http.post(`${this.base_url}/upload-csv-file`, formData);
  }

}
