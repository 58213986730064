<div class="mt-5">
  <div class="container-fluid">
    <div class="row text-center">
      <div class="col-12 col-sm-12 col-md-8 text-left">
        <h3> New Secure Transport Booking</h3>
        <p *ngIf="job?.vehicleLog?.comment!=null" class="text-bold">Authoriser's Comment: {{job?.vehicleLog?.comment}}</p>
      </div>
    </div>
  </div>

  <section class="content mt-4">
    <div class="container-fluid mt-1 ">
      <div class="row">
        <div class="col-12">
          <h5>Date & Time</h5>
        </div>

        <div class="form-group col-6 col-sm-12 col-md-6">
          <label>Collection date</label>
          <input type="text"  maxlength="255"  value="{{job?.dateTimeRequired| date:'dd/MM/yyyy'}}" class="form-control" disabled >
        </div>
        <div class="form-group col-6 col-sm-12 col-md-6">
          <label>Collection time</label>
          <input type="text"  maxlength="255"   value="{{job?.dateTimeRequired | date:'HH:mm'}}" name="mentalHealthStatus" class="form-control" disabled >
        </div>

        <div class="col-12">

          <h5>Collection details</h5>
        </div>

        <div class="form-group col-sm-12 col-md-4">
          <label>Adresss</label>
          <input type="text"  maxlength="255"  [value]="job?.pcaddress"  name="mentalHealthStatus" class="form-control" disabled >
        </div>
        <div class="form-group col-sm-12 col-md-4">
          <label>Post code</label>
          <input type="text"  maxlength="255"  [value]="job?.pickupPostCode"  name="mentalHealthStatus" class="form-control" disabled >
        </div>
        <div class="form-group col-sm-12 col-md-4">
          <label>Phone</label>
          <input type="text"  maxlength="255"  [value]="job?.pickupLocationContactNumber"  name="mentalHealthStatus" class="form-control" disabled >
        </div>


        <div class="col-12">
          <h5>Destination</h5>
        </div>

        <div class="form-group col-sm-12 col-md-4">
          <label>Address</label>
          <textarea type="text"  maxlength="255"  [value]="job?.destination"  name="mentalHealthStatus" class="form-control" disabled >
          </textarea>
        </div>
        <div class="form-group col-sm-12 col-md-4">
          <label>Post code</label>
          <input type="text"  maxlength="255"  [value]="job?.destinationPostCode"  name="mentalHealthStatus" class="form-control" disabled >
        </div>
        <div class="form-group col-sm-12 col-md-4">
          <label>Phone</label>
          <input type="text"  maxlength="255"  [value]="job?.destinationContactNumber"  name="mentalHealthStatus" class="form-control" disabled >
        </div>

        <div class="col-sm-12 col-md-12 text-right">
          <button type="button" class="btn btn-danger btn-sm mr-3" onclick="history.back()" >BACK</button>&nbsp;&nbsp;
          <a routerLink="/worker/transport/log/{{jobId}}"><button type="button" class="btn-primary">START LOG</button></a>
        </div>

<!--  <button class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
                     BACK
                </button>
                -->
        
      </div>

    </div>
  </section>



</div>
