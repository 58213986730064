import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from '../../services/storage.service';
import { WorkerService } from '../../services/worker.service';
import { AuthService } from '../../services/auth.service';
declare var $: any;

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {

  @Input() public userType: string;

  public uiShifts = false;
  public uiTrainings = false;
  public uiRates = false;
  public uiAgency = false;
  public uiClients = false;
  public uiWorkers = false;
  public uiTransport = false;
  public uiBilling = false;
  public uiStaff = false;
  public uiReport = false;
  public uiComms = false;
  public uiPayroll = false;
  agencies: any;
  public uiJobs = false;

  constructor(
    private toast: ToastrService,

    private workerService: WorkerService,
    private storageService: StorageService,
    private authService: AuthService
  ) {
    this.userType = authService.getUserType();
  }

  ngOnInit(): void {
    if(window.location.pathname.includes('shifts')){
      this.uiShifts = true;
    }
    else if(window.location.pathname.includes('agencies')){
      this.uiAgency = true;
    }
    else if(window.location.pathname.includes('clients')){
      this.uiClients = true;
    }
    else if(window.location.pathname.includes('workers')){
      this.uiWorkers = true;
    }
    else if(window.location.pathname.includes('staff')){
      this.uiStaff = true;
    }
    else if(window.location.pathname.includes('reporting')){
      this.uiReport = true;
    }
    else if(window.location.pathname.includes('rates')){
      this.uiRates = true;
    }
    else if(window.location.pathname.includes('communications')){
      this.uiComms = true;
    }
    else if(window.location.pathname.includes('jobs')){
      this.uiJobs = true;
    }
    else if(window.location.pathname.includes('payroll')){
      this.uiPayroll = true;
    }
    if(this.userType == "worker"){
      this.getAgencies();
    }
  }


  getAgencies(){


      this.workerService.getPaginatedWorkerAgencies(0, 100, this.storageService.decrypt(localStorage.getItem('workerId'))).subscribe(
        data => {
          // console.table(data.content);

          this.agencies = data.content;
        },
        err => {

        }
      );

  }


  closeSide(){
    console.log("Pakaipa wena");

    // $("#sidebarToggle, #sidebarToggleTop").on("click", function (e) {
      $("body").toggleClass("sidebar-toggled"),
      $(".sidebar").toggleClass("toggled"),
      $(".sidebar").hasClass("toggled") && $(".sidebar .collapse").collapse("hide")
    // });

}


}
