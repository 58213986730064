<main class="mt-5">
    <!-- Header part -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-3 text-left">
          <h3>Jobs</h3>
        </div>
        <div class="col-4 input-container" >
          <span>
            <input
              type="text"
              class="form-control "
              placeholder="Search By Job Ref"
              aria-describedby="inputGroupPrepend2"
              required
              [(ngModel)]="searchCriteria"/>
          </span>
        </div>
        <div class="col-5 text-right">
          <button class="btn btn-success mr-1" (click)="openModal(shareLinkModal)">
              <i  class='fas fa-link'></i>&nbsp;&nbsp;Booking Link
          </button>
          <button class="btn btn-success" (click)="openModal(add)">
              <i  class='fas fa-car-alt'></i>&nbsp;&nbsp;Book
          </button>
        </div>
      </div>
    </div>

    <!-- content -->
    <section class="content mt-2">
      <div class="container-fluid" hidden>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-1 text-right pt-2">
            <span class="text-black-50">Filters</span>
          </div>
          <div class="col-12 col-sm-12 col-md-3 text-left mt-2 mt-md-0">
            <select name="" class="form-control" [(ngModel)]="clientFilter">
              <option value="" selected disabled>Select Client</option>
              <option *ngFor="let c of clients" [value]="c.id" selected >{{ c.name }}</option>
            </select>
          </div>
          <div class="col-12 col-sm-12 col-md-5 text-left mt-2 mt-md-0">
            <div class="input-group">
              <input type="date" class="form-control" [(ngModel)]="startDateFilter">
              <input type="date" class="form-control" [(ngModel)]="endDateFilter">
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-1 mt-3 mt-md-0 ">
            <div class="input-group" (click)="reloadTab()">
              <button type="submit" class="btn btn-sm"><i class="fa fa-search"></i></button>
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-2 mt-2 mt-md-0">
            <div class="input-group" style="justify-content: right;">
              <p (click)="clearFilters()" class="btn btn-success" >Clear</p>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="row text-center mx-0 my-1">
          <div class="col-6  pointer col-sm-4 col-md-2 " (click)="selectTab('NEW')"
            [ngClass]="{'bg-custom-danger': selectedTab == 'NEW','bg-custom-unselected': selectedTab != 'NEW'}" style="border-top-left-radius: 5px;">New
            <br><span  class="badge  btn-dark"  [ngClass]="{'badge-light': selectedTab == 'NEW'}" >{{shiftsSummary?.newShift!=0?shiftsSummary?.newShift:null}}</span>
          </div>
          <div class="col-6 col-sm-4 col-md-2 pointer " (click)="selectTab('BOOKED')"
            [ngClass]="{'bg-custom-danger': selectedTab == 'BOOKED','bg-custom-unselected': selectedTab != 'BOOKED'}">Accepted
            <br><span  class="badge  btn-dark"  [ngClass]="{'badge-light': selectedTab == 'BOOKED'}" >{{shiftsSummary?.booked!=0?shiftsSummary?.booked:null}}</span>
          </div>
          <div class="col-6 col-sm-4 col-md-2 pointer " (click)="selectTab('PENDING')"
            [ngClass]="{'bg-custom-danger': selectedTab == 'PENDING','bg-custom-unselected': selectedTab != 'PENDING'}">Pending
            <br><span  class="badge  btn-dark"  [ngClass]="{'badge-light': selectedTab == 'PENDING'}" >{{shiftsSummary?.pending!=0?shiftsSummary?.pending:null}}</span>
          </div>
          <div class="col-6 col-sm-4 col-md-2 pointer " (click)="selectTab('WAITING')"
            [ngClass]="{'bg-custom-danger': selectedTab == 'WAITING','bg-custom-unselected': selectedTab != 'WAITING'}">Waiting Auth
            <br><span  class="badge  btn-dark"  [ngClass]="{'badge-light': selectedTab == 'WAITING'}" >{{shiftsSummary?.awaiting!=0?shiftsSummary?.awaiting:null}}</span>
          </div>
          <div class="col-6 col-sm-4 col-md-2 pointer " (click)="selectTab('AUTHORIZED')"
            [ngClass]="{'bg-custom-danger': selectedTab == 'AUTHORIZED','bg-custom-unselected': selectedTab != 'AUTHORIZED'}">Authorized
            <br><span  class="badge  btn-dark"  [ngClass]="{'badge-light': selectedTab == 'AUTHORIZED'}" >{{shiftsSummary?.authorized!=0?shiftsSummary?.authorized:null}}</span>
          </div>
          <div class="col-6 col-sm-4 col-md-2 pointer " (click)="selectTab('CLOSED')"
            [ngClass]="{'bg-custom-danger': selectedTab == 'CLOSED','bg-custom-unselected': selectedTab != 'CLOSED'}" style="border-top-right-radius: 5px;">Closed
            <br><span  class="badge  btn-dark"  [ngClass]="{'badge-light': selectedTab == 'CLOSED'}" >{{shiftsSummary?.closed!=0?shiftsSummary?.closed:null}}</span>
          </div>
        </div>


          <div>
              <app-jobs-table
              [agencyFilter]  =    "agencyFilter"
              [searchCriteria]  =    "searchCriteria"
              [clientFilter]  =    "clientFilter"
              [startDateFilter]  = "startDateFilter"
              [endDateFilter]  = "endDateFilter"
              [transportStatus] = "selectedTab"
              (updated)="refreshPage($event)"
              ></app-jobs-table>
          </div>


          <div *ngIf="selectedTab == ''">
            <div>Processing</div>
        </div>
      </div>
    </section>


  </main>
  <ng-template class="modal fade" #add let-modal>
    <app-create-transport-job (updated)="refreshPage($event)"></app-create-transport-job>
</ng-template>

<ng-template class="modal fade" #shareLinkModal let-modal>
  <div class="">
    <div class="modal-header bg-main text-white">
      <h5 class="modal-title text-center mx-4 w-100">Booking Link</h5>
      <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </span>
  </div>
      <div class="modal-body">
          <div class="bg-modal card-body">
               <span>Booking link: <b>{{link}}</b></span>
               <span (click)="copyMessage(link)" value="click to copy"
                class="btn btn-sm btn-success ml-3" >Copy Link
               </span>
          </div>
      </div>
  </div>
</ng-template>
