<main class="mt-5">
  <!-- Header part -->
  <div class="container-fluid">
    <div class="row text-center">
      <div class="col-12 col-sm-12 col-md-8 text-left">
        <h3>Secure Transport</h3>
      </div>
      <div class="col-4 text-md-right">
        <button class="btn btn-success" (click)="openModal(add)">
            <i  class='fas fa-car-alt'></i>&nbsp;&nbsp;Book
        </button>
      </div>
    </div>
  </div>

  <!-- content -->
  <section class="content mt-4">
    <div class="container-fluid mt-1">
      <div class="row mb-1 mx-0 text-center">
        <div class=" pointer col-sm-2 col-md-2" (click)="selectTab('new')"
          [ngClass]="{'bg-custom-danger': selectedTab == 'new','bg-custom-unselected': selectedTab != 'new'}" style="border-top-left-radius: 5px;">New
          <br><span  class="badge  btn-dark"  [ngClass]="{'badge-light': selectedTab == 'new'}" >{{shiftsSummary?.newShift!=0?shiftsSummary?.newShift:null}}</span>
        </div>
        <!--div class="col-6 col-sm-4 col-md-2 pointer" (click)="selectTab('applied')"
          [ngClass]="{'bg-custom-danger': selectedTab == 'applied'}">Applied</div-->
        <div class="col-sm-2 col-md-2 pointer" (click)="selectTab('booked')"
          [ngClass]="{'bg-custom-danger': selectedTab == 'booked','bg-custom-unselected': selectedTab != 'booked'}">Booked
          <br><span  class="badge  btn-dark"  [ngClass]="{'badge-light': selectedTab == 'accepted'}" >{{shiftsSummary?.applied!=0?shiftsSummary?.applied:null}}</span>
        </div>
        <div class="col-sm-3 col-md-3 pointer" (click)="selectTab('awaiting')"
          [ngClass]="{'bg-custom-danger': selectedTab == 'awaiting','bg-custom-unselected': selectedTab != 'awaiting'}">Waiting Auth
          <br><span  class="badge  btn-dark"  [ngClass]="{'badge-light': selectedTab == 'awaiting'}" >{{shiftsSummary?.awaiting!=0?shiftsSummary?.awaiting:null}}</span>
        </div>
        <div class="col-sm-2 col-md-3 pointer" (click)="selectTab('authorized')"
          [ngClass]="{'bg-custom-danger': selectedTab == 'authorized','bg-custom-unselected': selectedTab != 'authorized'}">Authorized
          <br><span  class="badge  btn-dark"  [ngClass]="{'badge-light': selectedTab == 'authorized'}" >{{shiftsSummary?.authorized!=0?shiftsSummary?.authorized:null}}</span>
        </div>
        <div class="col-sm-2 col-md-2 pointer" (click)="selectTab('closed')"
          [ngClass]="{'bg-custom-danger': selectedTab == 'closed','bg-custom-unselected': selectedTab != 'closed'}" style="border-top-right-radius: 5px;">Closed
          <br><span  class="badge  btn-dark"  [ngClass]="{'badge-light': selectedTab == 'closed'}" >{{shiftsSummary?.closed!=0?shiftsSummary?.closed:null}}</span>
        </div>
      </div>


        <div *ngIf="selectedTab == 'new'">
            <app-new-transport
            [agencyFilter]  =    "agencyFilter"
            [directorateFilter]  =    "directorateFilter"
            [clientFilter]  =    "clientFilter"
            [startDateFilter]  = "startDateFilter"
            [endDateFilter]  = "endDateFilter"
            ></app-new-transport>
        </div>
        <div *ngIf="selectedTab == 'booked'">
            <app-booked-transport
                        [agencyFilter]  =    "agencyFilter"
          [directorateFilter]  =    "directorateFilter"
          [clientFilter]  =    "clientFilter"
          [startDateFilter]  = "startDateFilter"
          [endDateFilter]  = "endDateFilter"
          ></app-booked-transport>
        </div>
        <div *ngIf="selectedTab == 'awaiting'">
            <app-awaiting-transport
                        [agencyFilter]  =    "agencyFilter"
          [directorateFilter]  =    "directorateFilter"
          [clientFilter]  =    "clientFilter"
          [startDateFilter]  = "startDateFilter"
          [endDateFilter]  = "endDateFilter"
          ></app-awaiting-transport>
        </div>
        <div *ngIf="selectedTab == 'authorized'">
            <app-authorized-transport
                        [agencyFilter]  =    "agencyFilter"
          [directorateFilter]  =    "directorateFilter"
          [clientFilter]  =    "clientFilter"
          [startDateFilter]  = "startDateFilter"
          [endDateFilter]  = "endDateFilter"
          ></app-authorized-transport>
        </div>
        <!--div *ngIf="selectedTab == 'queried'">
            <app-queried-shifts
                        [agencyFilter]  =    "agencyFilter"
          [directorateFilter]  =    "directorateFilter"
          [clientFilter]  =    "clientFilter"
          [startDateFilter]  = "startDateFilter"
          [endDateFilter]  = "endDateFilter"
          ></app-queried-shifts>
        </div-->
        <div *ngIf="selectedTab == 'closed'">
            <app-closed-transport
                        [agencyFilter]  =    "agencyFilter"
          [directorateFilter]  =    "directorateFilter"
          [clientFilter]  =    "clientFilter"
          [startDateFilter]  = "startDateFilter"
          [endDateFilter]  = "endDateFilter"
          ></app-closed-transport>
        </div>
    </div>
  </section>



</main>


<ng-template class="modal fade" #add let-modal>
    <app-create-transport-job></app-create-transport-job>

</ng-template>
