import { AuthService } from './../../shared/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/shared/services/storage.service';


import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {  FormControl} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';


import {  ElementRef, ViewChild} from '@angular/core';
import { AssignmentCodeService } from 'src/app/shared/services/assignment-code.service';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { UsersService } from 'src/app/shared/services/users.service';

import {MatChipInputEvent} from '@angular/material/chips';
import { WorkerService } from 'src/app/shared/services/worker.service';
import { ClientService } from 'src/app/shared/services/client.service';




declare var $: any;

@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.css']
})
export class ApplyComponent  implements OnInit {
  animal: string;
  name: string;


  visible = true;
  selectable = true;
  removable = true;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl('');
  filteredFruits: Observable<string[]>;
  fruits: string[] = ['New Applicant'];
  allFruits: string[] = [''] ;

  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;


  workers: any;
  allWorkers: any;
  assCodes = [];
  selectedWorker: any;
  workerId: number;

  addForm: FormGroup;
  editForm: FormGroup;
  loginForm: FormGroup;


  page = 1;
  pageSize = 20;
  totalItems = 0;
  showin = 0;
  first: boolean;
  last: boolean;

  userType: string;
  new: boolean = true;
  existing: boolean;
  formArea: boolean;
  query = new FormControl('');
  email: any;
  bookedDates: any;
  currentBookings: any;
  agency: any;
  agencyId: any;
  msg: string;
  worker: any;

  constructor(
    private router: Router,
    private workerService: WorkerService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private assignmentCodeService: AssignmentCodeService,
    private authService: AuthService,
    private agencyService: AgencyService,
    private usersService: UsersService,
    private clientService: ClientService,
    private storageService: StorageService,
    private activateRoute: ActivatedRoute,

  ) {
    this.userType = authService.getUserType();
    this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => (fruit ? this._filter(fruit) : this.allFruits.slice())),
    );


    this.addForm = this.fb.group({
      agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
      assignmentCode: ['', Validators.required],
      email: ['', Validators.required],
      firstname: ['', Validators.required],
      dob: ['', Validators.required],
      gender: ['', Validators.required],
      nationality: ['', Validators.required],
      lastname: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      username: ['', Validators.required],
      });

      this.loginForm = this.fb.group({
        username: ['', Validators.required],
        password: ['', Validators.required]
      });

   }



  ngOnInit(): void {
    this.activateRoute.params.subscribe((params) => {
      this.agencyId = params["agencyId"];
    });

    this.new = true;
    this.existing = false;
    this.formArea = false;


    this.getAgency();

    this.getAssCodes();

    this.editForm = this.fb.group({
      id: 0
    });




  }


  getAssCodes(){
    this.assignmentCodeService.getGuestPaginatedAssignmentCodes(this.agencyId).subscribe(
      data => {
        let assCodes = data;
        if(data.content)
        {
          assCodes = data.content;
        }
        this.assCodes = assCodes;
      }
    );
  }

  getAgency(){
    this.agencyService.getAgencyById(this.agencyId).subscribe(
      data => {  this.agency=data; }
    );
  }


  addval(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    console.log(value);
    // this.loadWorkerInfo(value);

    // Add our fruit
    if (value) {
      this.fruits= [value];
      this.loadWorkerInfo(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.fruitCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits = ['NEW'];

    }
    this.loadWorkerInfo('value');
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fruits= [event.option.viewValue];
    this.loadWorkerInfo(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allFruits.filter(fruit => fruit?.toLowerCase().includes(filterValue));
  }



  newWorker(form: FormGroup){
    this.email = form.value.email;

    form.patchValue({
      username: this.email,
      agencyId: this.agencyId
    });

    console.table(form.value);

    if(form.valid){
      this.workerService.createWorker(form.value).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          this.toast.success('Applicant Added Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }
    this.ngOnInit();

  }

  loadWorkerInfo(ele){
    let ref = this.fruits;

    // console.log(ref);
    if(ref){
      if(ref[0] != 'NEW'){
        let r;

        this.allWorkers.forEach(w => {
          if(w.email == ref){
            r = w;
            this.workerId = w.id;
          }
        });

        this.addForm = this.fb.group({
          agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
          assignmentCode: [r.assignmentCodeId, Validators.required],
          email: [r.email, Validators.required],
          firstname: [r.firstname, Validators.required],
          dob: [r.dob, Validators.required],
          gender: [r.gender, Validators.required],
          nationality: [r.gender, Validators.required],
          lastname: [r.lastname, Validators.required],
          phoneNumber: [r.phoneNumber, Validators.required],
          username: [r.username, Validators.required]
        });

        this.new = false;
        this.existing = true;
        this.formArea = true;
      }
      else{
        this.addForm = this.fb.group({
          agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
          assignmentCode: ['', Validators.required],
          email: ['', Validators.required],
          firstname: ['', Validators.required],
          dob: ['', Validators.required],
          gender: ['', Validators.required],
          nationality: ['', Validators.required],
          lastname: ['', Validators.required],
          phoneNumber: ['', Validators.required],
          username: ['', Validators.required]
        });

        this.new = true;
        this.existing = false;
        this.formArea = false;
      }
    }

  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  checkEmail(ele){
    // console.log(ele);

    var typed = ele.target.value;

    if(this.validateEmail(typed)){
      $(ele.target).removeClass('is-invalid');
      $(ele.target).addClass('is-valid');
    }
    else{
      $(ele.target).removeClass('is-valid');
      $(ele.target).addClass('is-invalid');
    }
  }

  getAssCodeId(ref){
    let lid;

    if(ref){
      this.assCodes.forEach(r => {
        if(r.name == ref){
          lid = r.id;
        }
      });
    }

    return lid;
  }

  linkWorker(){
    console.log(this.workerId);
    this.workerService.linkWorkerToAgent(this.workerId, this.agencyId).subscribe(
      resp => {
        // console.table(resp);
        this.modalService.dismissAll();
        this.toast.success('Applicant Linked Successfully');
      }
    );

  };

  resetPage(){
    this.modalService.dismissAll();
    this.router.navigate(['reset-password']);
  }

  loginUser(form: FormGroup){
    // console.log(form.value);
    this.msg = '';

    if(form.valid){
      this.authService.userLogin(form.value).subscribe(
        resp => {
          console.table(resp);

          if(resp.roles[0].name == 'ROLE_WORKER'){
            //validate id
            if(!resp.workerId){
              this.toast.error('No worker Identifier Found.');
              this.msg = 'No worker Identifier Found.';
              return false;
            }
            this.getWorker(resp.workerId);

          }
          else{
            this.toast.warning('Failed to determine user or Invalid role: ' + resp.roles[0]?.name);
          }
        },
        err => {

          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
          else if(err.error.message.includes("Bad credentials")){
            this.toast.error("Invalid Credentials");
          }
          else if(err.error.message){
            this.toast.error(err.error.message);
          }
          else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Login Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('Enter Credentials.');
    }
  }

  openModal(modal){
    this.modalService.open(modal, {centered: true, size: 'lg'});
  }


  getWorker(workerId){

    this.workerService.getWorkerById(workerId).subscribe(
      data => {
        var r = data;

        this.workerId =workerId;
        this.addForm = this.fb.group({
          agencyId: [this.agencyId],
          assignmentCode: [r.assignmentCodeId, Validators.required],
          email: [r.email, Validators.required],
          firstname: [r.firstname, Validators.required],
          dob: [r.dob, Validators.required],
          gender: [r.gender, Validators.required],
          nationality: [r.nationality, Validators.required],
          lastname: [r.lastname, Validators.required],
          phoneNumber: [r.phoneNumber, Validators.required],
          username: [r.username, Validators.required]
        });

        this.new = false;
        this.existing = true;
        this.formArea = true;

        this.modalService.dismissAll();

      }
    );
  }

}
