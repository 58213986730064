import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FileService } from 'src/app/shared/services/file.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { WorkerService } from 'src/app/shared/services/worker.service';

@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.css']
})
export class BankComponent implements OnInit {
  addBankForm: FormGroup;
  workerId: any;
  signedDoc: any;
  uploaded=false;
  bankingDetails: any;

  constructor(
    private activateRoute: ActivatedRoute,
    private toast: ToastrService,
    private formsService: FormsService,
    private workerService: WorkerService,
    private fb: FormBuilder,
    private fileService: FileService,
    private storageService: StorageService,

  ) {
    this.addBankForm =  this.fb.group({
        workerId: [],
        signDate: [],
        bank: [],
        name: [],
        code: [],
        account: [],
        signed: [],
        fullname: [],
    })
  }

  ngOnInit(): void {
    this.activateRoute.params.subscribe((params) => {
      this.workerId = params["id"]
    });

    this.getBank();

  }


  getBank(){
    this.workerService.getWorkerBank(this.workerId).subscribe(
      data => {
        this.bankingDetails = data;


        this.addBankForm = this.fb.group({
          workerId: [data.workerId],
          signDate: [data.signDate],
          bank: [data.bank],
          name: [data.name],
          code: [data.code],
          account: [data.account],
          signed: [data.signed],
          fullname: [data.fullname],
        });




        // this.addBankForm.value.firstname = this.worker?.firstname;
        // this.addBankForm.value.lastname = this.worker?.lastname;
        console.log(this.addBankForm.value);


      }
    );
  }


  addWorkerBank(form: FormGroup, submit?){
    if(submit=='accept'){
      this.addBankForm.value.status = 'APPROVED';
    }else{
      this.addBankForm.value.status = 'REJECTED';
    }
    form.value.serviceId = 1;
    if(form.valid){
      form.value.workerId =this.workerId ;

      this.workerService.addWorkerBank(form.value).subscribe(
        resp => {
          this.getBank();
          this.toast.success('Worker Banking Details Updated Successfully');

        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  onChange(event) {
    this.signedDoc = event.target.files[0];
  }

  uploadSignature(){
    this.fileService.uploadBankSig(this.signedDoc, this.storageService.decrypt(localStorage.getItem('workerId'))).subscribe(
      event => {
        if(event instanceof HttpResponse) {

        };
        this.getBank();
        this.toast.info("Sucessfully uploaded.");

      },
      (err) => {





      }, () => {

      }
    );
  }


}
