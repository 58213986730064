import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/app/shared/services/users.service';
declare var $: any;

@Component({
  selector: 'app-save-password',
  templateUrl: './save-password.component.html',
  styleUrls: ['./save-password.component.css']
})
export class SavePasswordComponent implements OnInit {

  addForm: FormGroup;
  token: any;
  StrongPasswordRegx: RegExp =
  /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;

  constructor(
    private router: Router,
    private toast: ToastrService,
    private userService: UsersService,
    private fb: FormBuilder,
    private activateRoute: ActivatedRoute,
  ) {

    this.activateRoute.queryParams.subscribe(params=>      {
        this.token = params['token']
      })
  }

  ngOnInit(): void {
    console.log(this.token);
    console.log("Sport");
    this.addForm = this.fb.group({
      token :this.token,
      newPassword :['',{
        validators: [Validators.required, Validators.pattern(this.StrongPasswordRegx)],
      }],
      repeatedpass :""
    });
  }

  get passwordFormField() {
    return this.addForm.get('newPassword');
  }



  formInit(){
    this.addForm = this.fb.group({
      newPassword: ['',{
        validators: [Validators.required, Validators.pattern(this.StrongPasswordRegx)],
      }],
      token: ['', Validators.required],
    })
  }

  savePassword(form:FormGroup){
    console.log(form.value);
    if(!$('#password').val()){
      this.toast.warning('Enter New Password');
      return false;
    }
    if($('#password').val() != $('#repeatedpass').val()){
      this.toast.warning('Passwords Should Match');
      return false;
    }

    this.userService.savePassword(form.value).subscribe(
      resp => {
        // console.log(resp);

        if(resp.message){
          this.toast.success(resp.message)
        }
        else{
          this.toast.success('Password Saved')
        }

        this.router.navigate(['/login']);
      },
      err => {

        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        }else if(err.status == 500){
          this.toast.error('Internal Server Error')
        }

      }
    );

  }

  loginPage(){
    this.router.navigate(['/login']);
  }

}
