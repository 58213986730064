import { ErrorComponent } from './pages/error/error.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { ChangePasswordDialog, NavBarComponent } from './components/nav-bar/nav-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { Directive, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from './pipes.module';

@Directive({
  selector: '[routerLinkActive]',
  exportAs: 'routerLinkActive'
})
export class RouterLinkActiveDirectiveStub {
}

@NgModule({
  declarations: [FooterComponent, NavBarComponent, SideBarComponent, NotFoundComponent, ErrorComponent, ChangePasswordDialog],
  imports: [
    CommonModule,
    AppRoutingModule,
    PipesModule
  ],
  exports: [
    MatDialogModule,
    FooterComponent,
    SideBarComponent,
    NavBarComponent,
    NotFoundComponent,
    ErrorComponent,
    NgbModule
  ]
})
export class SharedModule { }
