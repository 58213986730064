import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TransportService } from 'src/app/shared/services/transport.service';
import { WorkerService } from '../../../../../shared/services/worker.service';


interface FeedbackItem {
  attribute: string;
  rating: string;
}

@Component({
  selector: 'app-leader-entry4',
  templateUrl: './leader-entry3.component.html',
  styleUrls: ['./leader-entry3.component.css']


})
export class LeaderEntry3Component implements OnChanges{
  @Input('form') form: FormGroup;
  @Input('job') job: any;
  @Input('tabindex') tabindex: number;
  @Output() formO = new EventEmitter<FormGroup>();
  @Output() tabindexO = new EventEmitter<number>();

  showModal: boolean = false;

  attributes = [
    'Cleanliness of the vehicle',
    'Comfort of the ride',
    'Driver\'s courtesy',
    'Punctuality',
    'Overall experience'
  ];




  extremeRag: Array<String> = ['S','H','M'];


  // Declare and initialize LeaderEntry3ComponentForm
  LeaderEntry3ComponentForm: FormGroup;

  showNewDestinationFields = false;

  showTest: boolean = false;
  loginForm: FormGroup;
  msg: string;
  authService: any;
  workerId: any;
  addForm: FormGroup;
  agencyId: any;
  new: boolean;
  existing: boolean;
  formArea: boolean;
  // newLocation: any;




  constructor(
    private toast: ToastrService,
    private transporService: TransportService,
    private storageService: StorageService,
    private agencyService: AgencyService,
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private workerService: WorkerService,

  ) {
    // this.LeaderEntry3ComponentForm = this.fb.group({
    //   postCode: ['', Validators.required],
    //   pcaddress:[""],
    //   destination: ['', Validators.required],
    //   dropTime: ['', Validators.required],
    //   phone: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
    //   email: ['', [Validators.required, Validators.email]],
    //   cleanliness: ['', Validators.required],
    //   courtesy: ['', Validators.required],
    //   knowledge: ['', Validators.required],
    //   treatment: ['', Validators.required],
    //   advice: ['', Validators.required],
    //   comfort: ['', Validators.required],
    //   overallExperience: ['', Validators.required],
    //   comments: [''],
    //   confirmation: [false, Validators.requiredTrue],
    //   recipientContact: [""],
    //   recipientSignature: [""],
    //   recipientRole:[""],
    //   nameEmail:[""],
    //   nameSurname:[""],
    //   rapidStatus:[""],
    //   genderIssues:[""],
    //   newAddress: [""],
    //   newEmail: [""],
    //   newPhone: [""],
    //   newPostCode: [""],
    // });

    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.job);

    if(this.form.value.newEmail!=null||this.form.value.newPhone!=null||this.form.value.newPostCode!=null||this.form.value.newAddress!=null){
      this.form.patchValue({
        newLocation: true
      })
    }
  }





  /* Dialogue */

  setNewLocation(val){
    if(val==false){
      this.form.patchValue({
        newEmail:null,
        newPhone:null,
        newPostCode:null,
        newAddress:null
      });
    }
    // this.newLocation = val;
  }


  loginPage(){this.router.navigate(['login']);}

  droppedOff: boolean | null = null;



  getWorker(workerId){

    this.workerService.getWorkerById(workerId).subscribe(
      data => {
        var r = data;

        this.workerId =workerId;
        this.addForm = this.fb.group({
          agencyId: [this.agencyId],
          assignmentCode: [r.assignmentCodeId, Validators.required],
          email: [r.email, Validators.required],
          firstname: [r.firstname, Validators.required],
          dob: [r.dob, Validators.required],
          gender: [r.gender, Validators.required],
          nationality: [r.nationality, Validators.required],
          lastname: [r.lastname, Validators.required],
          phoneNumber: [r.phoneNumber, Validators.required],
          username: [r.username, Validators.required]
        });

        this.new = false;
        this.existing = true;
        this.formArea = true;

        this.modalService.dismissAll();

      }
    );
  }



  loginUser(form: FormGroup){
    // console.log(form.value);
    this.msg = '';

    if(form.valid){
      this.authService.userLogin(form.value).subscribe(
        resp => {
          console.table(resp);

          if(resp.roles[0].name == 'ROLE_WORKER'){
            //validate id
            if(!resp.workerId){
              this.toast.error('No worker Identifier Found.');
              this.msg = 'No worker Identifier Found.';
              return false;
            }
            this.getWorker(resp.workerId);

          }
          else{
            this.toast.warning('Failed to determine user or Invalid role: ' + resp.roles[0]?.name);
          }
        },
        err => {

          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
          else if(err.error.message.includes("Bad credentials")){
            this.toast.error("Invalid Credentials");
          }
          else if(err.error.message){
            this.toast.error(err.error.message);
          }
          else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Login Failed: ' + err.status);
          }
        }
      );
    }
  }

  resetPage(){
    this.modalService.dismissAll();
    this.router.navigate(['reset-password']);
  }



   openModal(modal){
    this.modalService.open(modal, {centered: true, size: 'lg'});
  }



  setDroppedOff(status: boolean) {
    this.droppedOff = status;
  }

  submitDropoffStatus() {
    if (this.droppedOff !== null) {
      console.log('Patient dropped off:', this.droppedOff);
      // Here you would typically send this data to a server
    } else {
      console.log('Please select a drop-off status');
    }
  }

  onSubmit() {
    if (this.LeaderEntry3ComponentForm.valid) {
      console.log('Form Submitted', this.LeaderEntry3ComponentForm.value);
    }
  }

  nextTab() {
    ++this.tabindex;
    if (this.tabindex > 5) this.tabindex = 5;
    this.tabindexO.emit(this.tabindex);
    this.formO.emit(this.form);
  }

  prevTab() {
    --this.tabindex;
    this.tabindexO.emit(this.tabindex);
    this.formO.emit(this.form);
  }

  updateTeamLeader(modal){
    if(!this.form.value.signature){
      return this.toast.warning("Please confirm information is correct.");
    }
    this.transporService.updateTeamLeader(this.form.value).subscribe(
    data=> {
      this.toast.success("Feedback sucessfully updated");
      this.openModal(modal);
    })
 }
}
