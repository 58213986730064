import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

const SECRET_KEY = 'worklink-frontend@2022';
const prodMode = environment.production;


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  encrypt(data) {
    // console.log(prodMode);

    // return data;
    if(!prodMode)
      return data;
    
    data = CryptoJS.AES.encrypt(data, SECRET_KEY);
    let encData = data.toString();

    // return encData;

    

  }

  decrypt(data) {
    if(data){
      // return data;
      // console.log(prodMode);
      if(!prodMode)
        return data;

      data = CryptoJS.AES.decrypt(data, SECRET_KEY);
      let decData = data.toString(CryptoJS.enc.Utf8);
      // return decData;

    }
  }

}
