import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth.service';
import { UsersService } from '../../services/users.service';
declare var $: any;

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  myUser: string;
  @Input() public userType: string;

  constructor(
    private authService: AuthService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.myUser = this.authService.getDisplayName();
  }

  logout() {
    this.authService.doLogout()
  }

  changePasswordDialog(): void {
    const dialogRef = this.dialog.open(ChangePasswordDialog, {
      width: '450px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }


  closeSide(){
    console.log("Pakaipa wena");

    // $("#sidebarToggle, #sidebarToggleTop").on("click", function (e) {
      $("body").toggleClass("sidebar-toggled"),
      $(".sidebar").toggleClass("toggled"),
      $(".sidebar").hasClass("toggled") && $(".sidebar .collapse").collapse("hide")
    // });

}

}


@Component({
  selector: 'change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
})
export class ChangePasswordDialog implements OnInit {
  passForm: FormGroup;


  constructor(
    private router: Router,
    private toast: ToastrService,
    private userService: UsersService,
    private authService: AuthService,
    private fb : FormBuilder,
    public dialogRef: MatDialogRef<ChangePasswordDialog>,

  ) { }

  ngOnInit(): void {

    this.passForm = this.fb.group({
      "oldPassword": ['', Validators.required],
      "matchingPassword": ['', Validators.required],
      "newPassword": ['', Validators.required]
    })
  }




  changePass(){
    //Logout
    localStorage.clear();

    // Go to reset password page
    this.router.navigate(['/change-password']);
    this.dialogRef.close();


    // const oldPass = document.getElementById('old') as HTMLInputElement | null;
    // const newPass = document.getElementById('new') as HTMLInputElement | null;
    // const repeatPass = document.getElementById('repeat') as HTMLInputElement | null;

    // if(newPass.value != repeatPass.value ){
    //   this.toast.warning('Passwords do not match');
    //   return false;
    // }
    // console.log(oldPass);
    // this.passForm.patchValue({
    //   oldPassword: oldPass.value,
    //   matchingPassword: repeatPass.value,
    //   newPassword: newPass.value
    // });

    // if(this.passForm.valid){
    //   this.userService.changePassword(this.passForm.value).subscribe(
    //     resp => {
    //       // console.log(resp);

    //       if(resp.message){
    //         this.toast.success(resp.message)
    //       }
    //       else{
    //         this.toast.success('Email sent.')
    //       }

    //       this.router.navigate(['/login']);
    //     },
    //     err => {
    //       
    //       if(err.status == 0){
    //         this.toast.error('Network Connection Failure');
    //       }
    //       else if(err.error?.message){
    //         this.toast.error(err.error.message);
    //       }else if(err.status == 500){
    //         this.toast.error('Internal Server Error')
    //       }
    //       else{
    //         this.toast.error('Password Reset Failed: ' + err.status);
    //       }
    //     }
    //   );
    // }else{
    //   this.toast.warning('Missing Required Info.');

    // }

  }



}
