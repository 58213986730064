import { ClientsServicesComponent } from './../admin/clients/clients-services/clients-services.component';
import { ClientHomeComponent } from './client-home/client-home.component';
import { NotFoundComponent } from './../shared/pages/not-found/not-found.component';
import { AuthGuard } from './../shared/utilities/auth.guard';
import { ClientContainerComponent } from './client-container/client-container.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AgenciesListComponent } from '../admin/agencies/agencies-list/agencies-list.component';

const routes: Routes = [
  {
    path: 'client',
    component: ClientContainerComponent,
    canActivate: [AuthGuard],
    children: [

      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: ClientHomeComponent },
      { path: 'services', component: ClientsServicesComponent },
      { path: 'agencies', component: AgenciesListComponent },


      { path: 'shifts', loadChildren: () => import('./shifts/shifts.module').then(m => m.ClientShiftsModule) },
      { path: 'transport', loadChildren: () => import('./transport/transport.module').then(m => m.ClientTransportsModule) },
      { path: 'rates', loadChildren: () => import('./client-rates-codes/client-rates-codes.module').then(m => m.ClientRatesCodesModule) },
      { path: 'workers', loadChildren: () => import('./client-workers/client-workers.module').then(m => m.ClientWorkersModule) },
      { path: 'staff', loadChildren: () => import('./client-staff/client-staff.module').then(m => m.ClientStaffModule) },
      { path: 'communications', loadChildren: () => import('../admin/communications/communications.module').then(m => m.CommunicationsModule) },
      { path: 'billing', loadChildren: () => import('./client-billing/client-billing.module').then(m => m.ClientBillingModule) },


      // not found
      { path: '**', component: NotFoundComponent, pathMatch: 'full' },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientRoutingModule { }
