import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  //base_url: string = environment.API_url + ':8300/api/v1';
  base_url: string = environment.API_url + '/forms-service/api/v1';

  constructor(
    private http: HttpClient
  ) { }

    getWorkerApplication(ref): Observable<any> {
      return this.http.get(`${this.base_url}/worker-application/${ref}`);
    }

    getAgencyWorkerApplication(workerId, agencyId): Observable<any> {
      return this.http.get(`${this.base_url}/worker-application/${workerId}/${agencyId}`);
    }

    getApplicationRefs(ref): Observable<any> {
      return this.http.get(`${this.base_url}/worker-application/references/${ref}`);
    }

    getWorkerOccupational(ref): Observable<any> {

      return this.http.get(`${this.base_url}/occupational/${ref}`);
    }


    getAgencyWorkerOccupational(workerId, agencyId): Observable<any> {

      return this.http.get(`${this.base_url}/occupational/${workerId}/${agencyId}`);
    }
    getWorkerHmrc(ref): Observable<any> {

      return this.http.get(`${this.base_url}/hmrc/${ref}`);
    }
    getAgencyWorkerHmrc(workerId, agencyId): Observable<any> {

      return this.http.get(`${this.base_url}/hmrc/${workerId}/${agencyId}`);
    }


    addWorkerApplication(data): Observable<any> {
      return this.http.post(`${this.base_url}/worker-application`, data);
    }

    addWorkerReference(data): Observable<any> {
      return this.http.post(`${this.base_url}/worker-application/reference`, data);
    }


    addWorkerOccupational(data): Observable<any> {

      return this.http.post(`${this.base_url}/occupational`, data);
    }




    addWorkerHmrc(data): Observable<any> {

      return this.http.post(`${this.base_url}/hmrc`, data);
    }



    updateWorkerApplication(data): Observable<any> {
      return this.http.put(`${this.base_url}/worker-application`, data);
    }


    updateWorkerOccupational(data): Observable<any> {

      return this.http.put(`${this.base_url}/occupational`, data);
    }
    updateWorkerHmrc(data): Observable<any> {

      return this.http.put(`${this.base_url}/hmrc`, data);
    }
}
