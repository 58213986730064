<form [formGroup]="form" (ngSubmit)="updateTeamLeader()">

  <div class="col-12 text-bold">

    <label for="">
      Medication List
    </label>
  </div>
  <form>
    <div class="row">
      <div class="col-4 btn-group" >
         <input type="text" maxlength="255" placeholder="1." [value]="getValue(0)" class="form-control" (change)="updateItem(0, $event)">
      </div>
      <div class="col-4 btn-group" >
        <input type="text" maxlength="255" placeholder="2." [value]="getValue(1)" class="form-control" (change)="updateItem(1, $event)">
      </div>
      <div class="col-4 btn-group" >
        <input type="text" maxlength="255" placeholder="3." [value]="getValue(2)" class="form-control" (change)="updateItem(2, $event)">
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-4 btn-group" >
         <input type="text" maxlength="255" placeholder="4." [value]="getValue(3)" class="form-control" (change)="updateItem(3, $event)">
      </div>
      <div class="col-4 btn-group" >
        <input type="text" maxlength="255" placeholder="5." [value]="getValue(4)" class="form-control" (change)="updateItem(4, $event)">
      </div>
      <div class="col-4 btn-group" >
        <input type="text" maxlength="255" placeholder="6." [value]="getValue(5)" class="form-control" (change)="updateItem(5, $event)">
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-4 btn-group" >
         <input type="text" maxlength="255" placeholder="7." [value]="getValue(6)" class="form-control" (change)="updateItem(6, $event)">
      </div>
      <div class="col-4 btn-group" >
        <input type="text" maxlength="255" placeholder="8." [value]="getValue(7)" class="form-control" (change)="updateItem(7, $event)">
      </div>
      <div class="col-4 btn-group" >
        <input type="text" maxlength="255" placeholder="9." [value]="getValue(8)" class="form-control" (change)="updateItem(8, $event)">
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-4 btn-group" >
         <input type="text" maxlength="255" placeholder="10." [value]="getValue(9)" class="form-control" (change)="updateItem(9, $event)">
      </div>
      <div class="col-4 btn-group" >
        <input type="text" maxlength="255" placeholder="11." [value]="getValue(10)" class="form-control" (change)="updateItem(10, $event)">
      </div>
      <div class="col-4 btn-group" >
        <input type="text" maxlength="255" placeholder="12." [value]="getValue(11)" class="form-control" (change)="updateItem(11, $event)">
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-4 btn-group" >
         <input type="text" maxlength="255" placeholder="13." [value]="getValue(12)" class="form-control" (change)="updateItem(12, $event)">
      </div>
      <div class="col-4 btn-group" >
        <input type="text" maxlength="255" placeholder="14." [value]="getValue(13)" class="form-control" (change)="updateItem(13, $event)">
      </div>
      <div class="col-4 btn-group" >
        <input type="text" maxlength="255" placeholder="15." [value]="getValue(14)" class="form-control" (change)="updateItem(14, $event)">
      </div>
    </div>
  </form> 
  <div class=" col-12 mt-2 text-right">
    <button type="button" class="btn-danger text-right mr-1" (click)="prevTab()">Back</button>
    <button type="submit" class="btn-primary text-right" (click)="updateTeamLeader(); nextTab();">Next</button>
  </div>
</form>
