<div class="d-flex">
  <span><h5>Documents</h5></span>
  <span class="ml-auto">
    <button class="btn btn-success" (click)="openModalFunction(document)">
      Add New
  </button>
  </span>
</div>

<section class="">
    <table class="table table-striped mb-0 styled-table text-left">
      <thead>
          <tr class="text-left">
              <th>Name</th>
              <th>Uploaded</th>
              <th>Expires</th>
              <th>Actions</th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let d of job.documents;">
              <td>{{d.name}}</td>
              <td>{{d.createdDate  | date: 'dd/MM/yy'}}</td>
              <td>{{d.expiryDate  | date: 'dd/MM/yy'}}</td>
              <td >
                <span class="mr-3 text-primary-custom pointer" (click)="openDoc(d.url)"  >
                    <i class="fa fa-eye" title="edit"></i>
                </span>
                <!-- <span class="text-danger-custom pointer">
                    <i class="fa fa-trash-alt" title="delete"></i>
                </span> -->
              </td>
          </tr>
      </tbody>
    </table>
    <section class="pagination-container" *ngIf="directorates">
      <div class="container-fluid">
        <div class="row m-0">
          <div class="col-12 col-sm-12 col-md-5 text-left acontent-center">
            Showing {{ (page - 1) * pageSize + 1 }} - {{ showin }} of {{ totalItems }}
          </div>
          <div class="col-12 col-sm-6 col-md-4 text-right">
            <button class="btn text-white" (click)="handlePageChange('prev')" [disabled]="first">
              <i class="fa fa-caret-up"></i>&nbsp;&nbsp;Previous Page
            </button>
          </div>
          <div class="col-12 col-sm-6 col-md-3 text-right pr-0">
            <button class="btn text-white" (click)="handlePageChange('next')" [disabled]="last">
              Next Page&nbsp;&nbsp;<i class="fa fa-caret-down"></i>
            </button>
          </div>
        </div>
      </div>
    </section>
    <div class="d-flex mt-2">
      <div class="ml-auto btn-group">
        <button type="button" class="btn-secondary btn" (click)="goBack()">Back</button>
        <button type="button" class="btn-primary ml-1 btn" (click)="goNext()">Next</button>
      </div>
    </div>
</section>

<ng-template #document let-modal>
  <div class="">
    <div class="modal-header bg-main text-white">
        <h5 class="modal-title text-center w-100">Add new document</h5>
        <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </span>
    </div>
    <div class="modal-body">
        <div class="bg-modal card-body bg-primary-accent">
            <div class="">
                <form>
                    <div class="bg-modal card-body">
                        <div class="">
                            <form [formGroup]="vehicleDocumentForm" (ngSubmit)="onSubmit()">
                                <div class="row">
                                    <div class="col-6">
                                      <div class="form-group">
                                        <label>Document Type</label>
                                        <select formControlName="docName" name="item" class="form-control" required>
                                            <option value="" selected disabled>Select document type</option>
                                            <option *ngFor="let item of items" [value]="item">{{ item }}</option>
                                        </select>
                                      </div>
                                      <div class="form-group">
                                        <label>Expiry Date</label>
                                        <input type="date"class="form-control" formControlName="expiryDate" required />
                                      </div>
                                      <div class="form-group">
                                        <label>Select Document</label><br>
                                        <input type="file" formControlName="files" class="form-control" (change)="onChange($event)" class="file-input">
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      
                                    </div>
                                </div>
                                <div align="right" class="mt-3">
                                    <button class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
                                        Cancel
                                    </button>
                                    <button type="submit" class="btn btn-sm btn-primary ml-1">
                                        Add
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>
</ng-template>

