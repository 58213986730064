<main class="mt-5">
  <!-- Header part -->
  <div class="container-fluid">
    <div class="row aitems-center">
      <div class="col-12 col-sm-12 col-md-4 text-left">
        <h3 class="mb-0">LOCATIONS</h3>
      </div>
      <div class="col-12 col-sm-6 col-md-4 text-center">
        <div class="input-group">
          <input
            type="text"
            class="form-control col-8 border-right-0"
            placeholder="Search Location"
            aria-describedby="inputGroupPrepend2"
            required
          />
          <div class="input-group-prepend">
            <span class="input-group-text bg-transparent border-left-0" id="inputGroupPrepend2">
                <i class="fa fa-search"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 text-md-right text-sm-left mt-2 mt-md-0">
          <button *ngIf="userType == 'admin'&&'UPDATE_SHIFT_LOCATION'|permission" class="btn btn-success" (click)="formInit(add)">
              <i class="fa fa-plus-circle"></i>&nbsp;&nbsp;Add Location
          </button>
      </div>
    </div>
  </div>

  <!-- Content -->
  <section class="content mt-4">
      <div class="container-fluid">
        <table class="table table-striped mb-0 styled-table text-left">
          <thead>
              <tr class="text-left">
                  <th>LOCATION Name</th>
                  <!-- <th>Post Code</th> -->
                  <!--th>Phone</th-->
                  <!-- <th *ngIf="userType == 'client'">Actions</th> -->
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let l of locations;">
                  <td>{{l.name}}</td>
                  <!-- <td>{{l.postcode}}</td> -->
                  <!--td>{{l.phoneNumber}}</td-->
                  <!-- <td *ngIf="userType == 'admin'">
                    <span class="mr-3 text-success pointer" (click)="loadLocation(l.id, edit, true)">
                        <i class="fa fa-pencil-alt" title="edit"></i>
                    </span>
                    <span class="text-danger-custom pointer" (click)="loadLocation(l.id, delete, false)">
                        <i class="fa fa-trash-alt" title="delete"></i>
                    </span>
                  </td> -->
              </tr>
          </tbody>
        </table>
        <section class="pagination-container" *ngIf="locations">
          <div class="">
            <div class="row m-0">
              <div class="col-12 col-sm-12 col-md-5 text-left acontent-center">
                Showing {{ (page - 1) * pageSize + 1 }} - {{ showin }} of {{ totalItems }}
              </div>
              <div class="col-12 col-sm-6 col-md-4 text-right">
                <button class="btn text-white" (click)="handlePageChange('prev')" [disabled]="first">
                  <i class="fa fa-caret-up"></i>&nbsp;&nbsp;Previous Page
                </button>
              </div>
              <div class="col-12 col-sm-6 col-md-3 text-right pr-0">
                <button class="btn text-white" (click)="handlePageChange('next')" [disabled]="last">
                  Next Page&nbsp;&nbsp;<i class="fa fa-caret-down"></i>
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
  </section>
</main>

<!-- Modals -->
<ng-template class="modal fade" #delete let-modal>
  <div class="">
      <div class="modal-header bg-danger text-white">
          <h5 class="modal-title text-center w-100">Delete LOCATION</h5>
          <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </span>
      </div>
      <div class="modal-body">
          <div class="bg-modal card-body">
              <div class="text-center">
                  <h3>You are about to delete this Location?</h3>
              </div>
          </div>
          <div align="right" class="mt-3">
            <button class="btn btn-info btn-sm mr-3" (click)="modal.dismiss()">
                Cancel
            </button>
            <button class="btn btn-sm btn-danger" (click)="deleteLocation()">
                Delete LOCATION
            </button>
          </div>
      </div>
  </div>
</ng-template>

<ng-template class="modal fade" #add let-modal>
  <div class="">
      <div class="modal-header bg-main text-white">
          <h5 class="modal-title text-center mx-4 w-100">Add Shift LOCATION</h5>
          <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </span>
      </div>
      <div class="modal-body">
          <form [formGroup]="addForm" (ngSubmit)="newLocation(addForm)">
            <div class="bg-modal card-body">
              <div class="container-fluid">
                <div class="">
                  <div class="">
                    <i class="bi bi-info-circle"></i>
                    <span class="ml-2">Location Details</span>
                    <hr class="bg-black mt-0">
                  </div>
                  <!-- <div class="">
                    <div class="form-group">
                      <select formControlName="clientId" id="myClient" class="form-control">
                        <option value="" selected disabled>Select Client</option>
                        <option *ngFor="let l of clients;" value="{{l.id}}" >{{l.name}}</option>
                      </select>
                    </div>
                  </div> -->
                  <div class="">
                    <div class="form-group">
                      <input type="text" maxlength="255" formControlName="name" placeholder="Location Name" class="form-control">
                    </div>
                  </div>
                  <!-- <div class="">
                    <div class="form-group">
                      <input type="text" maxlength="255" formControlName="postcode" placeholder="Post Code" class="form-control">
                    </div>
                  </div> -->
                  <!--div class=" mt-4">
                    <i class="bi bi-info-circle"></i>
                    <span class="ml-2">Contact Details</span>
                    <hr class="bg-black mt-0">
                  </div-->
                  <!--div class="">
                    <div class="form-group">
                      <input type="text" maxlength="255" formControlName="phoneNumber" placeholder="Phone Number" class="form-control col-12 col-md-8">
                    </div>
                  </div-->
                </div>
              </div>
            </div>
            <div align="right" class="mt-3">
              <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
                  Cancel
              </button>
              <button type="submit" class="btn btn-sm btn-success">
                  Add LOCATION
              </button>
            </div>
          </form>
      </div>
  </div>
</ng-template>

<!--ng-template class="modal fade" #edit let-modal>
  <div class="">
      <div class="modal-header bg-main text-white">
          <h5 class="modal-title text-center w-100">View & EDIT Shift LOCATION</h5>
          <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </span>
      </div>
      <div class="modal-body">
          <form [formGroup]="editForm" (ngSubmit)="updateLocation(editForm)">
            <div class="bg-modal card-body">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12">
                    <i class="bi bi-info-circle"></i>
                    <span class="ml-2">Location Details</span>
                    <hr class="bg-black mt-0">
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <input type="text" maxlength="255" formControlName="name" placeholder="Location Name" disabled class="form-control">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <input type="text" maxlength="255" formControlName="postcode" placeholder="Post Code" disabled class="form-control">
                    </div>
                  </div>
                  <div class="col-12 mt-4">
                    <i class="bi bi-info-circle"></i>
                    <span class="ml-2">Contact Details</span>
                    <hr class="bg-black mt-0">
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <input type="text" maxlength="255" formControlName="phoneNumber" placeholder="Phone Number" class="form-control col-12 col-md-8">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div align="right" *ngIf="userType == 'client'" class="mt-3">
              <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
                  Cancel
              </button>
              <button type="submit" class="btn btn-sm btn-success">
                  Update LOCATION
              </button>
            </div>
          </form>
      </div>
  </div>
</ng-template-->
