<main class="mt-5">
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,1,0" />
  <!-- header part -->
  <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 text-left" *ngIf="worker">
          <span class="text-decoration-none" style="font-size: xx-large;"><b>{{worker.firstname}} {{worker.lastname}}</b> Details</span>
        </div>
        <div class="col-12 col-sm-6 col-md-4 text-md-right text-sm-left mt-2 mt-md-0">

        </div>
      </div>
  </div>

  <!-- content -->
  <section class="content mt-4">
      <div class="container-fluid">
          <div class="row" *ngIf="worker">
              <div class="col-md-12">
                  <div class="card">
                      <div class="card-body">
                        <div class="tabs">

                          <input type="radio" name="tabs" id="tabone" checked="checked">
                          <label for="tabone">Overview</label>
                          <div class="tab">
                              <div class="row tab-content">
                                  <div class="col-sm-12 col-md-12 col py-2 tab-column">
                                    <form [formGroup]="editInfoForm" (ngSubmit)="updateAgencyWorkerInfo(editInfoForm)">
                                      <!-- <input formControlName="id" type="hidden"> -->

                                      <div class="tab-column-inner tab-column-inner-bottom">
                                          <div class="tab-title H2">
                                              <span><mat-icon>how_to_reg</mat-icon> &nbsp;&nbsp;Flexi-Worker Information</span>
                                              <div class="ml-auto " *ngIf="edit==false">
                                                <button class="btn-success rounded-3" (click)="toggleEdit()" *ngIf="userType == 'agency'">Edit</button>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="tab-column-inner" *ngIf="edit==true">
                                          <div class="row  my-2">
                                            <div class="col-5">Payment Method</div>
                                            <div class="col-1">:</div>
                                            <div class="col-5">
                                              <select formControlName="paymentMethod" class="border border-primary rounded-3">
                                                <option value="" selected disabled>Select</option>
                                                <option >Paye</option>
                                                <option >Umbrella</option>
                                                <option value="PVT" >PVT Ltd</option>
                                              </select>
                                            </div>
                                          </div>
                                          <div class="row  my-2">
                                            <div class="col-5">Employment Start Date</div>
                                            <div class="col-1">:</div>
                                            <div class="col-5">
                                                <input formControlName="employmentStartDate" class="border border-primary rounded-3" type="date">
                                            </div>
                                          </div>
                                          <div class="row  my-2">
                                            <div class="col-5">Contract End Date</div>
                                            <div class="col-1">:</div>
                                            <div class="col-5">
                                              <input formControlName="contractEndDate" class="border border-primary rounded-3" type="date">
                                            </div>
                                          </div>
                                          <div class="row  my-2">
                                            <div class="col-5">Next Check Date</div>
                                            <div class="col-1">:</div>
                                            <div class="col-5">
                                              <input formControlName="nextCheckDate" class="border border-primary rounded-3" type="date" >
                                            </div>
                                          </div>
                                          <div class="fw-btns d-flex my-2"  *ngIf="edit==true">
                                            <button class="btn btn-danger mx-2" (click)="toggleEdit()">Cancel</button>
                                            <button class="btn btn-success  mx-2">Update</button>
                                          </div>
                                      </div>
                                      <div class="tab-column-inner" *ngIf="userType=='client'">
                                          <div class="row  my-2">
                                            <div class="col-5">Assignment code</div>
                                            <div class="col-1">:</div>
                                            <div class="col-5">
                                                {{worker.assignmentCode ?? ''}}
                                            </div>
                                          </div>
                                          <div class="row  my-2">
                                            <div class="col-5">Gender</div>
                                            <div class="col-1">:</div>
                                            <div class="col-5">
                                                {{worker.gender ?? ''}}
                                            </div>
                                          </div>
                                      </div>
                                      <div class="tab-column-inner" *ngIf="edit==false && userType!='client'">

                                          <div class="row  my-2">
                                            <div class="col-5">Payment Method</div>
                                            <div class="col-1">:</div>
                                            <div class="col-5">
                                              {{complianceInformation?.paymentMethod ??  'Not Set'}}
                                            </div>
                                          </div>
                                          <div class="row  my-2">
                                            <div class="col-5">Employment Start Date</div>
                                            <div class="col-1">:</div>
                                            <div class="col-5">
                                              {{complianceInformation?.employmentStartDate ?? 'Not Known'}}
                                            </div>
                                          </div>
                                          <div class="row  my-2">
                                            <div class="col-5">Contract End Date</div>
                                            <div class="col-1">:</div>
                                            <div class="col-5">
                                              {{complianceInformation?.contractEndDate ??  'Not Set'}}
                                            </div>
                                          </div>
                                          <div class="row  my-2">
                                            <div class="col-5">Next Check Date</div>
                                            <div class="col-1">:</div>
                                            <div class="col-5">
                                              {{complianceInformation?.nextCheckDate ?? 'Not Set'}}
                                            </div>
                                          </div>
                                      </div>

                                    </form>
                                    <div *ngIf="userType=='null'"  class="tab-column-inner tab-column-inner-bottom">
                                      <div class="tab-title">
                                          <span> <mat-icon>fitness_center</mat-icon>&nbsp;&nbsp; Fitness Status</span>
                                      </div>
                                    </div>
                                    <div  *ngIf="userType=='null'" class="tab-column-inner ">
                                        <div class="tab-title d-flex center">
                                            Fit to work
                                            <div class="form-check mx-3 d-flex center">
                                                <label class="form-check-label p-0">
                                                <input type="radio" class="form-check-input" style="display:block" value="yes" (click)="toggleFitToWork('yes')" [checked]="fitToWork" name="fitToWork">
                                                    YES
                                                </label>
                                            </div>
                                            <div class="form-check mx-3 d-flex center">
                                                <label class="form-check-label p-0">
                                                <input type="radio" class="form-check-input" style="display:block" value="no" (click)="toggleFitToWork('no')" [checked]="!fitToWork" name="fitToWork">
                                                    No
                                                </label>
                                            </div>
                                            <button type="button" (click)="submitFitToWork()" class="btn btn-success mx-4"  > Update</button>
                                        </div>
                                    </div>
                                  </div>

                                  <div class="col-sm-12 col-md-12 col " *ngIf="userType!='client'">
                                    <div class=" py-2 tab-column">
                                      <div class="tab-column-inner tab-column-inner-bottom">
                                          <div class="tab-title">
                                              <span>Job Information</span>
                                          </div>
                                      </div>
                                      <div class="tab-column-inner">
                                        <div class="">
                                          <div class="tab-column-inner" *ngIf="edit==false && userType!='client'">

                                            <div class="row  my-2">
                                              <div class="col-5">Assignment Code</div>
                                              <div class="col-1">:</div>
                                              <div class="col-5">
                                                {{worker?.assignmentCode ??  'Not Set'}}
                                              </div>
                                            </div>
                                            <div class="row  my-2">
                                              <div class="col-5">Job</div>
                                              <div class="col-1">:</div>
                                              <div class="col-5">
                                                {{worker?.assignmentName ?? 'Not Known'}}
                                              </div>
                                            </div>
                                        </div>

                                      </div>
                                      </div>
                                    </div>

                                  </div>

                              </div>
                          </div>

                          <input type="radio" name="tabs" id="tabtwo">
                          <label for="tabtwo">Training & Qualifications</label>
                          <div class="tab">
                              <div class="">
                                  <div class=" tab-column">
                                      <div class="tab-column-inner tab-column-inner-bottom">
                                          <div class="tab-title">
                                              <span><mat-icon>how_to_reg</mat-icon> &nbsp;&nbsp; Training & Qualifications</span>
                                              <button class="btn btn-success ml-auto" *ngIf="userType == 'agency'" (click)="openModal(add)">Add</button>

                                          </div>
                                      </div>
                                      <div class="tab-column-inner ">
                                          <div class="tab-content-info">
                                              <table id="customers">
                                                  <tr >
                                                  <th>Name</th>
                                                  <th>Uploaded</th>
                                                  <th>Expires</th>
                                                  <th class="text-center">Status</th>
                                                  <th class="text-center">Actions</th>
                                                  </tr >
                                                  <tr *ngFor="let training of trainings;">
                                                  <td>{{training.name}}</td>
                                                  <!-- <td>{{training.description}}</td> -->
                                                  <td>{{training.trainingDate}}</td>
                                                  <td>{{training.trainingExpiry}}

                                                  <img *ngIf="checkExpiry(training.trainingExpiry) == 'alert'" style="width:15px" alt="warning icon" title="About to expire" src="/assets/images/alert_caution.png">
                                                  <img *ngIf="checkExpiry(training.trainingExpiry) == 'expired'" style="width:15px" alt="danger icon" title="Expired" src="/assets/images/alert_danger.png">

                                                </td>
                                                <td *ngIf="training.status =='REJECTED' && training.hascoId ==null "  class="text-center text-danger-custom" >
                                                  <mat-icon title="Rejected">error</mat-icon></td>
                                                <td *ngIf="training.status !='REJECTED' && training.hascoId ==null && training.status !='APPROVED' "  class="text-center">
                                                  <span class="material-symbols-outlined" style="color:#eba82a">
                                                    schedule
                                                    </span>
                                                  </td>
                                                <td *ngIf="training.status =='APPROVED' || training.hascoId !=null "  class="text-center">
                                                  <i class="fa fa-square" style="color: rgb(26, 216, 16);"></i>

                                                </td>
                                                  <td class="text-center" (click)="openModal(viewT); selectedTraining(training)"><i class="fa fa-eye text-primary-custom"></i></td>
                                                  </tr>
                                              </table>
                                          </div>
                                      </div>

                                  </div>
                              </div>
                          </div>

                          <input type="radio" name="tabs" id="tabthree">
                          <label for="tabthree" *ngIf="userType!='client'">Compliance</label>
                          <div class="tab">
                            <div class="">
                                <div class=" tab-column">
                                    <div class="tab-column-inner tab-column-inner-bottom">
                                        <div class="tab-title">
                                            <span><mat-icon>assignment</mat-icon> &nbsp;&nbsp; Compliance Information</span>
                                            <button class="btn btn-success ml-auto" *ngIf="userType == 'agency'" (click)="openModal(addCompliance)">Add</button>
                                            <!-- <button class="outline-btn rounded-3" *ngIf="userType != 'admin' && editCompliance==false" (click)="toggleEditCompliance()" >Edit</button> -->
                                        </div>
                                    </div>
                                    <div class="tab-column-inner ">
                                      <div class="tab-content-info">
                                          <table id="customers">
                                              <tr >
                                              <th>Document Name</th>
                                              <!-- <th>File</th> -->
                                              <th>Uploaded</th>
                                              <th>Expires</th>
                                              <th>Status</th>
                                              <th>Actions</th>
                                              </tr >
                                              <tr *ngFor="let training of compliances;">
                                                <td>{{training.name}}</td>
                                                <!-- <td>Not Uploaded</td> -->
                                                <td>{{training.complianceDate}}</td>
                                                <td>{{training.complianceExpiry}}

                                                <img *ngIf="checkExpiry(training.complianceExpiry) == 'alert'" style="width:15px" alt="warning icon" title="About to expire" src="/assets/images/alert_caution.png">
                                                <img *ngIf="checkExpiry(training.complianceExpiry) == 'expired'" style="width:15px" alt="danger icon" title="Expired" src="/assets/images/alert_danger.png">

                                              </td>
                                                <td *ngIf="training.status =='REJECTED' "  class="text-center text-danger-custom" ><mat-icon title="Rejected">error</mat-icon></td>
                                                <td *ngIf="training.status !='APPROVED' && training.status !='REJECTED' "  class="text-center"><span class="material-symbols-outlined" style="color:#eba82a">
                                                    schedule
                                                    </span></td>
                                                <td *ngIf="training.status =='APPROVED' "  class="text-center">
                                                  <i class="fa fa-square" style="color: rgb(26, 216, 16);"></i>
                                                </td>
                                                <td class="text-center" (click)="openModal(viewC); selectedTraining(training)"><i class="fa fa-eye text-primary-custom"></i></td>
                                                </tr>
                                          </table>
                                      </div>
                                  </div>

                                </div>
                            </div>
                          </div>


                          <input type="radio" name="tabs" id="tabfour">
                          <label for="tabfour" >Form</label>
                          <div class="tab">
                            <div class="">
                                <div class=" tab-column">
                                    <div class="tab-column-inner tab-column-inner-bottom">
                                        <div class="tab-title">
                                            <span><mat-icon>assignment</mat-icon> &nbsp;&nbsp; Form Information</span>
                                            <!-- <button class="btn btn-success ml-auto" *ngIf="userType == 'agency'" (click)="openModal(addForm)">Add</button> -->
                                            <!-- <button class="outline-btn rounded-3" *ngIf="userType != 'admin' && editForm==false" (click)="toggleEditForm()" >Edit</button> -->
                                        </div>
                                    </div>
                                    <div class="tab-column-inner ">
                                      <div class="tab-content-info">
                                          <table id="customers">
                                              <tr >
                                              <th>Form</th>
                                              <th>Last Modified</th>
                                              <th>Status</th>
                                              <th>Actions</th>
                                              </tr >
                                              <tr >
                                                <td>Application form</td>
                                                <td>{{application?.lastModifiedDate}}</td>
                                                <td *ngIf="application?.status =='REJECTED' "  class="text-center text-danger-custom" ><mat-icon title="Rejected">error</mat-icon></td>
                                                <td *ngIf="application?.status !='REJECTED' && application?.status !='APPROVED' "  class="text-center"><span class="material-symbols-outlined" style="color:#eba82a">
                                                    schedule
                                                    </span></td>
                                                <td *ngIf="application?.status =='APPROVED' "  class="text-center">
                                                  <i class="fa fa-square" style="color: rgb(26, 216, 16);"></i>
                                                </td>

                                                <td class="text-center text-primary-custom" (click)="openModal(formComment); selectedTraining(application)" ><i class="fa fa-eye text-primary-custom"></i></td>
                                                </tr>
                                                <tr >
                                                  <td>Occupational Health Medical Questionnaire</td>
                                                  <td>{{occupationalDetails?.lastModifiedDate}}</td>


                                                  <td *ngIf="occupationalDetails?.status =='REJECTED' "  class="text-center text-danger-custom" ><mat-icon title="Rejected">error</mat-icon></td>
                                                  <td *ngIf="occupationalDetails?.status !='REJECTED' && occupationalDetails?.status !='APPROVED' "  class="text-center"><span class="material-symbols-outlined" style="color:#eba82a">
                                                    schedule
                                                    </span></td>
                                                  <td *ngIf="occupationalDetails?.status =='APPROVED' "  class="text-center">
                                                    <i class="fa fa-square" style="color: rgb(26, 216, 16);"></i>
                                                  </td>

                                                  <td class="text-center text-primary-custom"  (click)="openModal(formComment); selectedTraining(occupationalDetails)" ><i class="fa fa-eye text-primary-custom"></i></td>
                                                </tr>
                                                <tr >
                                                  <td>HMRC Tax Declaration</td>
                                                  <td>{{hmrcDetails?.lastModifiedDate}}</td>

                                                  <td *ngIf="hmrcDetails?.status =='REJECTED' "  class="text-center text-danger-custom" ><mat-icon title="Rejected">error</mat-icon></td>
                                                  <td *ngIf="hmrcDetails?.status !='REJECTED' && hmrcDetails?.status !='APPROVED' "  class="text-center"><span class="material-symbols-outlined" style="color:#eba82a">
                                                    schedule
                                                    </span></td>
                                                  <td *ngIf="hmrcDetails?.status =='APPROVED' "  class="text-center">
                                                    <i class="fa fa-square" style="color: rgb(26, 216, 16);"></i>
                                                  </td>

                                                  <td class="text-center text-primary-custom"  (click)="openModal(formComment); selectedTraining(hmrcDetails)" ><i class="fa fa-eye text-primary-custom"></i></td>
                                                </tr>
                                                <!-- <tr >
                                                  <td>Bank Details</td>
                                                  <td>{{bankingDetails?.lastModifiedDate}}</td>
                                                  <td></td>
                                                  <td class="text-center text-primary-custom" routerLink="/agency/bank/{{this.workerId}}" ><i class="fa fa-eye text-primary-custom"></i></td>
                                                </tr> -->

                                          </table>
                                      </div>
                                  </div>

                                </div>
                            </div>
                          </div>



                          <div class="tab" *ngIf="edit2==true">
                              <div class="">
                                  <div class="tab-column">
                                      <div class="tab-column-inner tab-column-inner-bottom">
                                          <div class="tab-title">
                                              <span>Compliance Information</span>
                                              <button class="outline-btn" *ngIf="userType != 'admin'" (click)="toggleEdit2()">Edit</button>
                                          </div>
                                      </div>
                                      <div class="tab-column-inner tab-content">
                                          <div class="  p-10">
                                              <div class="tab-content-info">
                                                  <span>Right to Work Status</span>
                                                  <span class="tab-input-before">:</span>
                                                  <span>
                                                      <div class="form-group">
                                                        <Select type="number"
                                                          class="form-control w-content" name="" id="" aria-describedby="helpId" >
                                                          <option value="">Temporary</option>
                                                          <option value="">Residency</option>
                                                          <option value="">Citizen</option>
                                                        </Select>
                                                      </div>
                                                  </span>
                                              </div>
                                              <div class="tab-content-info">
                                                  <span>DBS Number</span>
                                                  <span class="tab-input-before">:</span>
                                                  <span>
                                                      <div class="form-group">
                                                        <input type="number"
                                                          class="form-control w-content" name="" id="" aria-describedby="helpId" placeholder="Null">
                                                      </div>
                                                  </span>
                                              </div>
                                              <div class="tab-content-info">
                                                  <span>DBS/PVG No</span>
                                                  <span class="tab-input-before">:</span>
                                                  <span>
                                                      <div class="form-group">
                                                        <input type="number"
                                                          class="form-control w-content" name="" id="" aria-describedby="helpId" placeholder="Null">
                                                      </div>
                                                  </span>
                                              </div>
                                              <div class="tab-content-info">
                                                  <span>Expiry Date</span>
                                                  <span class="tab-input-before">:</span>
                                                  <span>
                                                      <div class="form-group">
                                                        <input type="date"
                                                          class="form-control w-content" name="" id="" aria-describedby="helpId" >
                                                      </div>
                                                  </span>
                                              </div>
                                              <div class="tab-content-info">
                                                  <span>Occupational Health Checked</span>
                                                  <span class="tab-input-before">:</span>
                                                  <span>
                                                      <div class="form-group">
                                                        <input type="date"
                                                          class="form-control w-content" name="" id="" aria-describedby="helpId" >
                                                      </div>
                                                  </span>
                                              </div>
                                          </div>
                                          <div class="  p-10">
                                              <div class="tab-content-info">
                                                  <span>Restrictions</span>
                                                  <span class="tab-input-before">:</span>
                                                  <span>
                                                      <div class="form-group">
                                                        <select type="number"
                                                          class="form-control w-content" name="" id="" aria-describedby="helpId" placeholder="Null">
                                                          <option>Student</option>
                                                          <option>Lorem Ipsum</option>
                                                          <option>Lorem Ipsum</option>
                                                        </select>
                                                      </div>
                                                  </span>
                                              </div>
                                              <div class="tab-content-info">
                                                  <span>Id Number</span>
                                                  <span class="tab-input-before">:</span>
                                                  <span>
                                                      <div class="form-group">
                                                        <input type="number"
                                                          class="form-control w-content" name="" id="" aria-describedby="helpId" placeholder="Null">
                                                      </div>
                                                  </span>
                                              </div>
                                              <div class="tab-content-info">
                                                  <span>Id Issue Date</span>
                                                  <span class="tab-input-before">:</span>
                                                  <span>
                                                      <div class="form-group">
                                                        <input type="date"
                                                          class="form-control w-content" name="" id="" aria-describedby="helpId" placeholder="Null">
                                                      </div>
                                                  </span>
                                              </div>
                                              <div class="tab-content-info">
                                                  <span>Id ExpiryDate</span>
                                                  <span class="tab-input-before">:</span>
                                                  <span>
                                                      <div class="form-group">
                                                        <input type="date"
                                                          class="form-control w-content" name="" id="" aria-describedby="helpId" placeholder="Null">
                                                      </div>
                                                  </span>
                                              </div>
                                              <div class="mt-3 ">
                                                <div class="tab-title d-flex center">
                                                    Fit to work
                                                    <div class="form-check mx-3 d-flex center">
                                                        <label class="form-check-label p-0">
                                                        <input type="checkbox" class="form-check-input" name="" id="" value="checkedValue" checked>
                                                            YES
                                                        </label>
                                                    </div>
                                                    <div class="form-check mx-3 d-flex center">
                                                        <label class="form-check-label p-0">
                                                        <input type="checkbox" class="form-check-input" name="" id="" value="checkedValue" checked>
                                                            No
                                                        </label>
                                                    </div>
                                                    <button class="btn btn-danger mx-4" (click)="toggleEdit2()"> Cancel</button>
                                                    <button class="btn btn-success mx-4" *ngIf="userType != 'admin'"> Update</button>
                                                </div>
                                            </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <!-- <input type="radio" name="tabs" id="tabfour">
                          <label for="tabfour">Payroll</label>
                          <div class="tab">
                              <div class="">
                                  <div class=" tab-column">
                                      <div class="tab-column-inner tab-column-inner-bottom">
                                          <div class="tab-title">
                                              <span>Payslips</span>
                                          </div>
                                      </div>
                                      <div class="tab-column-inner ">
                                          <div class="tab-content-info">
                                              <table id="customers">
                                                  <tr>
                                                  <th>Company</th>
                                                  <th>Contact</th>
                                                  <th>Country</th>
                                                  </tr>
                                                  <tr>
                                                  <td>Alfreds Futterkiste</td>
                                                  <td>Maria Anders</td>
                                                  <td>Germany</td>
                                                  </tr>
                                                  <tr>
                                                  <td>Berglunds snabbköp</td>
                                                  <td>Christina Berglund</td>
                                                  <td>Sweden</td>
                                                  </tr>
                                                  <tr>
                                                  <td>Centro comercial Moctezuma</td>
                                                  <td>Francisco Chang</td>
                                                  <td>Mexico</td>
                                                  </tr>
                                                  <tr>
                                                  <td>Ernst Handel</td>
                                                  <td>Roland Mendel</td>
                                                  <td>Austria</td>
                                                  </tr>
                                                  <tr>
                                                  <td>Island Trading</td>
                                                  <td>Helen Bennett</td>
                                                  <td>UK</td>
                                                  </tr>
                                                  <tr>
                                                  <td>Königlich Essen</td>
                                                  <td>Philip Cramer</td>
                                                  <td>Germany</td>
                                                  </tr>
                                                  <tr>
                                                  <td>Laughing Bacchus Winecellars</td>
                                                  <td>Yoshi Tannamuri</td>
                                                  <td>Canada</td>
                                                  </tr>
                                                  <tr>
                                                  <td>Magazzini Alimentari Riuniti</td>
                                                  <td>Giovanni Rovelli</td>
                                                  <td>Italy</td>
                                                  </tr>
                                                  <tr>
                                                  <td>North/South</td>
                                                  <td>Simon Crowther</td>
                                                  <td>UK</td>
                                                  </tr>
                                                  <tr>
                                                  <td>Paris spécialités</td>
                                                  <td>Marie Bertrand</td>
                                                  <td>France</td>
                                                  </tr>
                                              </table>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div> -->

                          <input type="radio" name="tabs" id="tabfive">
                          <!-- <label for="tabfive">Notes</label>
                          <div class="tab">
                              <div class="">
                                  <div class="tab-column">
                                      <div class="tab-column-inner tab-column-inner-bottom">
                                        <div class="tab-title">
                                          <span> <mat-icon>notes</mat-icon>&nbsp;&nbsp; Notes</span>
                                          <div class="ml-auto" *ngIf="edit==false">
                                            <button class="outline-btn rounded-3" *ngIf="userType == 'agency'" (click)="openModal(addNoteModel)">Add</button>

                                          </div>
                                        </div>
                                      </div>
                                      <div class="tab-column-inner">
                                          <div class="  p-10">
                                              <div class="tab-content-info">
                                                <table id="customers">
                                                  <tr *ngFor="let note of notes;">
                                                    <td>{{note.note}}</td>
                                                  </tr>
                                              </table>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div> -->
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>

  <!-- Add Training & Qualifications Modal -->
  <ng-template class="modal fade" #add let-modal>
    <div class="">
      <div class="modal-header bg-main text-white">
        <h5 class="modal-title text-center mx-4 w-100">Add Training</h5>
        <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
          (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </span>
      </div>
      <div class="modal-body">
        <form [formGroup]="addTrainingForm" (ngSubmit)="addTraining(addTrainingForm)">
          <input type="hidden" formControlName="agencyId" value="{{agencyId}}" />
          <input type="hidden" formControlName="workerId" value="{{workerId}}" />
          <input formControlName="code" type="hidden"  placeholder="Code"
                      class="form-control">
          <input formControlName="code" type="hidden"  placeholder="Code"
                      class="form-control">
          <input type="hidden" formControlName="serviceId" value="1" />
          <div class="bg-modal card-body">
            <div class="container-fluid">
              <div class="">
                <div class="">
                  <i class="bi bi-info-circle"></i>
                  <span class="ml-2">Training</span>
                  <hr class="bg-black mt-0">
                </div>
                <!-- <div class="">
                  <div class="form-group">
                    <label for="" class="modal-label">Name</label>
                    <input formControlName="name" type="hidden"  placeholder="Name"
                      class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="" class="modal-label">Code</label>
                    <input formControlName="code" type="hidden"  placeholder="Code"
                      class="form-control">
                  </div>
                </div> -->
                <div class="">
                  <div class="form-group">
                    <select formControlName="trainingId" class="form-control w-content" style="width:400px">
                      <option value="" selected disabled>Select training</option>
                      <option *ngFor="let training of trainingAll;" value="{{training.id}}">{{training.name}}</option>
                    </select>
                  </div>
                  <!-- <div class="form-group">
                    <label for="" class="modal-label">DESCRIPTION</label>
                    <input formControlName="description" type="text"  placeholder="Description"
                      class="form-control">
                  </div> -->
                </div>
                <div class="">
                  <div class="form-group">
                    <label for="" class="modal-label">Issue Date</label>
                    <input formControlName="trainingDate" type="date"  placeholder="Issue Date"
                      class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="" class="modal-label">ExpiryDate</label>
                    <input formControlName="trainingExpiry" type="date"  placeholder="ExpiryDate"
                      class="form-control">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div align="right" class="mt-3">
            <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
              Cancel
            </button>
            <button type="submit" class="btn btn-sm btn-success">
              Add Training
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>

  <!-- Add Training & Qualifications Modal -->
  <ng-template class="modal fade" #addCompliance let-modal>
    <div class="">
      <div class="modal-header bg-main text-white">
        <h5 class="modal-title text-center mx-4 w-100">Add Compliance</h5>
        <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
          (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </span>
      </div>
      <div class="modal-body">
        <form [formGroup]="addComplianceForm" (ngSubmit)="addWorkerCompliance(addComplianceForm)">
          <input type="hidden" formControlName="agencyId" value="{{agencyId}}" />
          <input type="hidden" formControlName="workerId" value="{{workerId}}" />
          <input formControlName="code" type="hidden"  placeholder="Code"
                      class="form-control">
          <input formControlName="code" type="hidden"  placeholder="Code"
                      class="form-control">
          <input type="hidden" formControlName="serviceId" value="1" />
          <div class="bg-modal card-body">
            <div class="container-fluid">
              <div class="">
                <div class="">
                  <i class="bi bi-info-circle"></i>
                  <span class="ml-2">Compliance</span>
                  <hr class="bg-black mt-0">
                </div>
                <!-- <div class="">
                  <div class="form-group">
                    <label for="" class="modal-label">Name</label>
                    <input formControlName="name" type="hidden"  placeholder="Name"
                      class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="" class="modal-label">Code</label>
                    <input formControlName="code" type="hidden"  placeholder="Code"
                      class="form-control">
                  </div>
                </div> -->
                <div class="">
                  <div class="form-group">
                    <select formControlName="complianceId" class="form-control w-content" style="width:400px">
                      <option value="" selected disabled>Select training</option>
                      <option *ngFor="let training of complianceAll;" value="{{training.id}}">{{training.name}}</option>
                    </select>
                  </div>
                  <!-- <div class="form-group">
                    <label for="" class="modal-label">DESCRIPTION</label>
                    <input formControlName="description" type="text"  placeholder="Description"
                      class="form-control">
                  </div> -->
                </div>
                <div class="">
                  <div class="form-group">
                    <label for="" class="modal-label">Issue Date</label>
                    <input formControlName="complianceDate" type="date"  placeholder="Issue Date"
                      class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="" class="modal-label">ExpiryDate</label>
                    <input formControlName="complianceExpiry" type="date"  placeholder="ExpiryDate"
                      class="form-control">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div align="right" class="mt-3">
            <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
              Cancel
            </button>
            <button type="submit" class="btn btn-sm btn-success">
              Add Compliance
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>

    <!-- Add Note Modal -->
    <ng-template class="modal fade" #addNoteModel let-modal>
      <div class="">
        <div class="modal-header bg-main text-white">
          <h5 class="modal-title text-center mx-4 w-100">Add NOTE</h5>
          <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </span>
        </div>
        <div class="modal-body">
          <form [formGroup]="addNoteForm" (ngSubmit)="addNote(addNoteForm)">
            <div class="bg-modal card-body">
              <div class="container-fluid">
                <div class="">
                  <div class="">
                    <span class="ml-2">NOTE</span>
                    <hr class="bg-black mt-0">
                  </div>
                  <div class="">
                    <div class="form-group">
                      <input formControlName="note" class="border border-primary rounded-3" type="text">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div align="right" class="mt-3">
              <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
                Cancel
              </button>
              <button type="submit" class="btn btn-sm btn-success">
                Add NOTE
              </button>
            </div>
          </form>
        </div>
      </div>
    </ng-template>

     <!-- Add Jobs Modal -->
    <ng-template class="modal fade" #addJobModel let-modal>
      <div class="">
        <div class="modal-header bg-main text-white">
          <h5 class="modal-title text-center mx-4 w-100">Add Job</h5>
          <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </span>
        </div>
        <div class="modal-body">
          <form [formGroup]="addJobForm" (ngSubmit)="addJob(addJobForm)">
            <div class="bg-modal card-body">
              <div class="container-fluid">
                <div class="">
                  <div class="">
                    <div class="form-group">
                      <select formControlName="assignmentCodeId" class="form-control">
                        <option value="" disabled>Select Assignment Code</option>
                        <option *ngFor="let assignmentCode of assignmentCodes;" value="{{assignmentCode.id}}">{{assignmentCode.name}}</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <select formControlName="paymentMethodId" class="form-control">
                        <option value="" disabled>Select Payment Method</option>
                        <option *ngFor="let paymentMethod of paymentMethods;" value="{{paymentMethod.id}}">{{paymentMethod.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div align="right" class="mt-3">
              <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
                Cancel
              </button>
              <button type="submit" class="btn btn-sm btn-success">
                Add Job
              </button>
            </div>
          </form>
        </div>
      </div>
    </ng-template>
</main>


<!-- Modals -->
<ng-template class="modal fade" #viewT let-modal>
  <div class="">
    <div class="modal-header bg-main text-white">
      <h5 class="modal-title text-center w-100">View Document</h5>
      <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
        (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </span>
    </div>
    <div class="modal-body">
      <form [formGroup]="updateTrainingForm">
        <div class="bg-modal card-body">
          <div class="container-fluid">
            <section>
              <div *ngIf="activeTraining.type=='EXTERNAL'" class="col-12">
                <i class="bi bi-info-circle"></i>
                <!-- <a *ngIf="activeTraining.document" [href]="activeTraining.document" target="_blank"> Click To View Document</a> -->
                <a *ngIf="activeTraining.document" (click)="openDoc(activeTraining.document)" target="_blank"> Click To View Document</a>

                <p *ngIf="!activeTraining.document" >Document Not Uploaded</p>
                <hr class="bg-black mt-0">
              </div>
              <div *ngIf="activeTraining.type=='PHYSICAL'" class="col-12">
                <i class="bi bi-info-circle"></i>
                Physical Training<br>
                <!-- <a >Certificate Design</a> -->
                <a  *ngIf="activeTraining.showCertificate==true"  routerLink="/worker/certificate/{{activeTraining.id}}" class="text-primary-custom" > Click To View Certificate</a>
                <p  *ngIf="activeTraining.showCertificate==false||activeTraining.showCertificate==null"   >Certificate was not issued</p>
                <hr class="bg-black mt-0">
              </div>

          </section>
            <section>

                <div *ngIf="userType!='client' && activeTraining.hascoId ==null"  class="row">
                  <div class="form-group col-12 col-sm-12 col-md-12">
                    <label for="comment">Comment</label>
                    <div class="text-info-custom" *ngIf="activeTraining.status != 'WAITING_APPROVAL'">
                      {{activeTraining.comment}}
                    </div>
                    <input type="text" maxlength="255"  placeholder="Comment" class="form-control">
                  </div>
                </div>

            </section>
          </div>
        </div>
        <div align="right" *ngIf="userType!='client' && activeTraining.hascoId ==null"  class="mt-3">
          <button type="button" class="btn btn-danger btn-sm mr-3"   (click)="updateTraining(updateTrainingForm, 'REJECTED')" >
            Reject
          </button>
          <button type="submit" class="btn btn-sm btn-success" (click)="updateTraining(updateTrainingForm, 'APPROVED')">
            Approve
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

  <ng-template class="modal fade" #formComment let-modal>
    <div class="">
      <div class="modal-header bg-main text-white">
        <h5 class="modal-title text-center w-100">Agency Comment</h5>
        <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
          (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </span>
      </div>
      <div class="modal-body">
        <form [formGroup]="updateTrainingForm">
          <div class="bg-modal card-body">
            <div class="container-fluid">
              <section>
                  <div class="col-12">

                    <span><p *ngIf="activeTraining.comment"  target="_blank"><i class="bi bi-info-circle"></i> {{activeTraining.comment}}</p></span>
                    <span><p *ngIf="!activeTraining.comment" ><i class="bi bi-info-circle"></i> No Comment</p></span>
                  </div>

              </section>
            </div>
          </div>
          <div align="right" class="mt-3">
            <!-- <button type="button" class="btn btn-danger btn-sm mr-3"   (click)="updateTraining(updateTrainingForm, 'REJECTED')" >
              Reject
            </button>
            <button type="submit" class="btn btn-sm btn-success" (click)="updateTraining(updateTrainingForm, 'APPROVED')">
              Approve
            </button> -->
          </div>
        </form>
      </div>
    </div>
  </ng-template>

<ng-template class="modal fade" #viewC let-modal>
  <div class="">
    <div class="modal-header bg-main text-white">
      <h5 class="modal-title text-center w-100">View Document</h5>
      <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
        (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </span>
    </div>
    <div class="modal-body">
      <form [formGroup]="updateTrainingForm">
        <div class="bg-modal card-body">
          <div class="container-fluid">
            <section>
                <div class="col-12">
                  <i class="bi bi-info-circle"></i>
                  <!-- <a *ngIf="activeTraining.document" [href]="activeTraining.document" target="_blank">Click To View Document</a> -->
                  <a *ngIf="activeTraining.document" (click)="openDoc(activeTraining.document)" target="_blank"> Click To View Document</a>

                  <p *ngIf="!activeTraining.document" >Document Not Uploaded</p>
                </div>
                <div class="row" *ngIf="activeTraining.status != 'WAITING_APPROVAL'">
                  <div class="form-group col-12 col-sm-12 col-md-12">
                    <label for="comment">Comment</label>
                    <input type="text" maxlength="255" disabled  value="{{activeTraining.comment}}" placeholder="Comment" class="form-control">
                  </div>
                </div>

            </section>
          </div>
        </div>
        <div align="right" class="mt-3">
          <!-- <button type="button" class="btn btn-danger btn-sm mr-3"   (click)="updateTraining(updateTrainingForm, 'REJECTED')" >
            Reject
          </button>
          <button type="submit" class="btn btn-sm btn-success" (click)="updateTraining(updateTrainingForm, 'APPROVED')">
            Approve
          </button> -->
        </div>
      </form>
    </div>
  </div>
  </ng-template>
