import { ClientService } from './../../../shared/services/client.service';
import { ServicesService } from './../../../shared/services/services.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UsersService } from 'src/app/shared/services/users.service';
import { StorageService } from 'src/app/shared/services/storage.service';
declare var $: any;

@Component({
  selector: 'app-agencies-list',
  templateUrl: './agencies-list.component.html',
  styleUrls: ['./agencies-list.component.css']
})
export class AgenciesListComponent implements OnInit {

  agencies: any;
  userList: any = [];
  showUsers: boolean;
  services: any;
  agencyId: number;
  selectedAgency: any;

  addForm: FormGroup;
  editForm: FormGroup;
  agencyTypeFilter="";

  page = 1;
  pageSize = 20;
  totalItems = 0;
  showin = 0;
  first: boolean;
  last: boolean;

  userType: string;

  constructor(
    private toast: ToastrService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private agencyService: AgencyService,
    private servicesService: ServicesService,
    private authService: AuthService,
    private usersService: UsersService,
    private clientService: ClientService,
    private storageService: StorageService
  ) {
    this.userType = authService.getUserType();
  }

  ngOnInit(): void {
    this.getAgencies();

    this.formInit();

    this.editForm = this.fb.group({
      id: 0
    });

    this.getServices();
  }

  formInit(){
    this.addForm = this.fb.group({
      name: ['', Validators.required],
      logo: [''],
      // email: ['', Validators.required],
      status: ['ACTIVE'],
      agencyType: ['DEFAULT'],
      serviceId: ['', Validators.required],
      address: this.fb.group({
        firstLine: ['', Validators.required],
        postcode: ['', Validators.required],
        town: ['', Validators.required],
      }),
      telephone: ['', Validators.required],
      administratorCreateDto: this.fb.group({
        adminEmail: ['', Validators.required],
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
      }),
      billingEmail: ['', Validators.required],
    });
  }

  handlePageChange(event) {

    if(event == 'next'){
      this.page += 1;
    }
    else if(event == 'prev' && this.page != 1){
      this.page -= 1;
    }

    this.getAgencies();
  }

  getServices(){
    this.servicesService.getPaginatedServices(0, 100).subscribe(
      data => {
        // console.table(data.content);
        this.services = data.content;
      }
    );
  }

  getAgencies(){
    console.log(this.agencyTypeFilter);
    if(this.userType == 'admin'){
      this.agencyService.getPaginatedAgencies(this.page - 1, this.pageSize, this.agencyTypeFilter).subscribe(
        data => {
          // console.table(data.content);
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }
          let array = data.content;
          array.sort(function(a, b){
              if(a.name < b.name) { return -1; }
              if(a.name > b.name) { return 1; }
              return 0;
          })
          this.agencies = array;
        },
        err => {

          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }

        }
      );
    }
    else{
      this.clientService.getPaginatedClientAgencies(this.page - 1, this.pageSize, this.storageService.decrypt(localStorage.getItem('clientId'))).subscribe(
        data => {
          // console.table(data.content);
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }

          this.agencies = data.content;
        },
        err => {

          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }

        }
      );
    }
  }

  openModal(modal){
    this.modalService.open(modal, {centered: true, size: 'lg'});
  }

  openBigModal(modal, ref){
    this.agencyId = ref;
    this.getUsers();
    this.modalService.open(modal, {centered: true, size: 'xl'});
  }

  newAgency(form: FormGroup){
    // console.table(form.value);

    if(form.valid){
      this.agencyService.createAgency(form.value).subscribe(
        resp => {
          this.modalService.dismissAll();
          this.getAgencies();
          this.toast.success('Agency Added Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  updateAgency(form: FormGroup){
    // console.table(form.value);

    if(form.valid){
      this.agencyService.updateAgency(form.value).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          this.getAgencies();
          this.toast.success('Agency Updated Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  loadAgency(ref, modal){
    if(ref){
      // console.log(ref);

      this.agencies.forEach(r => {
        if(r.agencyId == ref){
          this.selectedAgency = r;
        }
      });

      if(this.selectedAgency){
        this.editForm = this.fb.group({
          agencyId: [this.selectedAgency.agencyId, Validators.required],
          email: [this.selectedAgency.email, Validators.required],
          name: [this.selectedAgency.name, Validators.required],
          logo: [this.selectedAgency.logo, Validators.required],
          serviceId: [this.getServiceId(this.selectedAgency.service), Validators.required],
          address: this.fb.group({
            firstLine: [this.selectedAgency.address.firstLine, Validators.required],
            postcode: [this.selectedAgency.address.postcode, Validators.required],
            town: [this.selectedAgency.address.town, Validators.required],
          }),
          telephone: [this.selectedAgency.telephone, Validators.required],
          agencyType: ["DEFAULT", Validators.required],
          billingEmail: [this.selectedAgency.billingEmail, Validators.required],
        });
      }

      this.openModal(modal);
    }

  }

  getServiceId(ref){
    let sid;
    if(ref){
      this.services.forEach(r => {
        if(r.name == ref){
          sid = r.id;
        }
      });
    }

    return sid;
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  checkEmail(ele){
    // console.log(ele);

    var typed = ele.target.value;

    if(this.validateEmail(typed)){
      $(ele.target).removeClass('is-invalid');
      $(ele.target).addClass('is-valid');
    }
    else{
      $(ele.target).removeClass('is-valid');
      $(ele.target).addClass('is-invalid');
    }
  }

  getUsers(){
    this.usersService.getPaginatedAgencyUsers(0, 100, this.agencyId).subscribe(
      data => {
        // console.table(data.content);

        this.userList = data.content;
        this.showUsers = true;
      }
    );
  }

  getName(name){
    if(name){
      return name.replace(/_/g, " ");
    }

    return '';
  }

  activateUser(ref, role, enable){

    let formData: FormGroup = this.fb.group({
      userId : ref,
      roleName: role,
      enabled: enable
    });

    this.usersService.enableUser(formData.value).subscribe(
      resp => {
        // console.log(resp);
        this.toast.success('User Status Changed.')
        this.getUsers();
      }
    );

  }

  activateAgent(ref){
    if(ref){
      this.agencyService.activateAgency(ref).subscribe(
        resp => {
          // console.table(resp);
          this.getAgencies();
          this.toast.success('Agency Activated Successfully');
        },
        err => {

          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          // }
          // else if(err.error?.message){
          //   this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }

        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  deactivateAgent(ref){
    if(ref){
      this.agencyService.deactivateAgency(ref).subscribe(
        resp => {
          // console.table(resp);
          this.getAgencies();
          this.toast.success('Agency De-activated Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

}
