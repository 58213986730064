import { W } from '@angular/cdk/keycodes';
import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { AssetService } from 'src/app/shared/services/asset.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { JobService } from 'src/app/shared/services/job.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TransportService } from 'src/app/shared/services/transport.service';
import { WorkerService } from 'src/app/shared/services/worker.service';

@Component({
  selector: 'app-delete-transport-modal',
  templateUrl: './delete-transport-modal.html',
  styleUrls: ['./delete-transport-modal.css']
})
export class DeleteTransportModalComponent implements OnInit {
  @Input('selectedJob') selectedJob : any;
  @Output() updated = new EventEmitter<boolean>();

  workers:any;
  vehicles: any;
  bookedWorkers=[];
  authorizeForm: FormGroup;
  userType: any;
  riskDoc: any;
  uploadDoc: boolean = false;

  constructor(
    private storageService: StorageService,
    private agencyService: AgencyService,
    private assetService: AssetService,
    private transportService: TransportService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private authService: AuthService,
    private workerService: WorkerService,
    private jobsService: JobService
   ) {
    this.userType = authService.getUserType();
   }

  ngOnInit(): void {
    console.log(this.selectedJob);
    this.authorizeForm = this.fb.group({
      transportId: [this.selectedJob.id, Validators.required],
      start: ['', Validators.required],
      end: ['', Validators.required],
      breakTime: ['', Validators.required],
    });

  }


  deleteInvoice(){
    this.transportService.cancelTransport(this.selectedJob.id).subscribe(
      resp => {
        this.modalService.dismissAll();
        this.toast.success('Job cancelled successfuly');
        this.updated.emit(true);
      }
    );
}


  openModal(modal) {
    this.modalService.dismissAll();
    this.modalService.open(modal, {centered: true, size: 'lg'})
  }

  closeModal() {
    this.modalService.dismissAll();
  }



}
