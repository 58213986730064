import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { InventoryService } from 'src/app/shared/services/inventory.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TransportService } from 'src/app/shared/services/transport.service';
import { CompressImageService } from 'src/app/shared/utilities/compress-image';

@Component({
  selector: 'app-vehicle-inventory-tab',
  templateUrl: './vehicle-inventory-tab.component.html',
  styleUrls: ['./vehicle-inventory-tab.component.css']
})
export class VehicleInventoryTabComponent implements OnChanges {
  @Input('form') form: FormGroup;
  @Input('job') job: any;
  @Input('tabindex') tabindex: string;
  @Output() formO = new EventEmitter<FormGroup>();
  @Output() tabindexO = new EventEmitter<string>();

  directorates: any;

  page = 1;
  pageSize = 20;
  totalItems = 0;
  showin = 0;
  first: boolean;
  last: boolean;

  userType: string;
  clients: any;
  searchCriteria: string = null;
  isModalOpen = false;
  items = ['PPE', 'Fire Extinguisher', 'J67 Spanner Kit', 'Jumpers', 'Warning Triangle'];
  selectedItem = '';
  dateInstalled;
  nextCheckDate;
  selectedFile: File = null;
  http: any;
  inventoryPics = [];

  constructor(
    private toast: ToastrService,
    private transporService: TransportService,
    private storageService: StorageService,
    private agencyService: AgencyService,
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private inventoryService: InventoryService,
    private compressImage: CompressImageService
  ) { }

  ngOnChanges(): void {
    console.log(this.job);
    this.inventoryService.currentInventory.subscribe(inventory => {
      if (inventory) {
        this.selectedItem = inventory.item;
      }
    });
  }


  onChange(event) {
    console.log(event.target.files);

    for(let i=0; i<event.target.files.length; i++){
       let image = event.target.files[i];

       console.log(`Image size before compressed: ${image.size} bytes.`)
       this.compressImage.compress(image)
         .pipe(take(1))
         .subscribe(compressedImage => {
           console.log(`Image size after compressed: ${compressedImage.size} bytes.`)
           this.inventoryPics.push(compressedImage);
         })
    }

    console.log(this.inventoryPics);
  }


  submitInventories(){
    this.transporService.saveInventoryUrl(this.inventoryPics,this.job.id,this.selectedItem,this.dateInstalled, this.nextCheckDate, null  ).subscribe(
      event => {
        if(event instanceof HttpResponse) {
        };
        this.toast.info("Sucessfully uploaded.");
        this.modalService.dismissAll();
        this.tabindexO.emit(this.tabindex);
      }
    );
  }


  openModalFunction(content:any){
    this.modalService.open(content,{centered: true, size: 'sm'});
  }


  closeModalFunction(){
    this.modalService.dismissAll();
  }




  addItem() {
    const newItem = {
      item: 'New Item',
      date: new Date(),
      images: []
    };
    this.inventoryService.updateInventory(newItem);
  }

  handlePageChange(event: string) {
    if (event === 'next') {
      this.page += 1;
    } else if (event === 'prev' && this.page !== 1) {
      this.page -= 1;
    }
  }

  nextTab() {
    console.log(this.tabindex);
    this.tabindex = "logs";
    // ++this.tabindex;
    // if (this.tabindex > 4) this.tabindex = 4;
    this.tabindexO.emit(this.tabindex);
    this.formO.emit(this.form);
  }

  prevTab() {
    console.log(this.tabindex);
    this.tabindex = "docs";
    // --this.tabindex;
    this.tabindexO.emit(this.tabindex);
    this.formO.emit(this.form);
  }

  updateCleaningCheck() {
    this.transporService.updateCleaningCheck(this.form.value).subscribe(
      data => { this.toast.success("Info updated"); }
    );
  }

  goBack() {
    this.prevTab();
  }

  goNext() {
    this.nextTab();
  }

  /* Ng template */

  onFileSelected(event){
    this.selectedFile=<File> event.target.files[0];
  }

onUpload(){
  const fd = new FormData();
  fd.append('image', this.selectedFile, this.selectedFile.name);
  this.http.post('https://angularbyprocademy-dbe68-default-rtdb.firebaseio.com/products.json',fd)
  .subscribe(res => {
    console.log(res);
  });
}

openModal() {
  this.isModalOpen = true;
}

closeModal() {
  this.isModalOpen = false;
}







}
