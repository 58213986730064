import { AuthService } from './../../../shared/services/auth.service';
import { ClientService } from 'src/app/shared/services/client.service';
import { ToastrService } from 'ngx-toastr';
import { ShiftService } from './../../../shared/services/shift.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StorageService } from 'src/app/shared/services/storage.service';
import { AgencyService } from 'src/app/shared/services/agency.service';

@Component({
  selector: 'app-shifts-locations',
  templateUrl: './shifts-locations.component.html',
  styleUrls: ['./shifts-locations.component.css']
})
export class ShiftsLocationsComponent implements OnInit {

  locations: any;
  clients: any;
  selectedLocation: any;

  addForm: FormGroup;
  editForm: FormGroup;

  page = 1;
  pageSize = 20;
  totalItems = 0;
  showin = 0;
  first: boolean;
  last: boolean;

  userType: string;
  agencyId: any;

  constructor(
    private shiftsService: ShiftService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private clientService: ClientService,
    private agencyService: AgencyService,
    private authService: AuthService,
    private storageService: StorageService
  ) {
    this.userType = authService.getUserType();
    this.agencyId = this.storageService.decrypt(localStorage.getItem('agentId'));
  }

  ngOnInit(): void {
    this.getLocations();

    this.editForm = this.fb.group({
      id: '',
      name: '',
      phoneNumber:''
    });

    this.getClients();
  }



  formInit(modal){
    this.addForm = this.fb.group({
      // clientId: [this.storageService.decrypt(localStorage.getItem('clientId'))??"", Validators.required],
      name: ['', Validators.required],
      // postcode: ['', Validators.required],
      phoneNumber: ['' ],
    });
    this.editForm = this.fb.group({
      // clientId: [this.storageService.decrypt(localStorage.getItem('clientId')), Validators.required],
      name: ['', Validators.required],
      // postcode: ['', Validators.required],
      phoneNumber: ['' ],
    });

    this.openModal(modal);
  }

  handlePageChange(event) {

    if(event == 'next'){
      this.page += 1;
    }
    else if(event == 'prev' && this.page != 1){
      this.page -= 1;
    }

    this.getLocations();
  }

  getLocations(){
    if(this.userType == 'client'){
      this.shiftsService.getPaginatedShiftLocations(this.page-1, this.pageSize).subscribe(
        data => {
          console.table(data.content);
          this.totalItems = data.totalElements ;
          this.first = data.first;
          this.last = data.last;

          this.showin = data.numberOfElements;

          let array = data.content;
          array.sort(function(a, b){
              if(a.name < b.name) { return -1; }
              if(a.name > b.name) { return 1; }
              return 0;
          })

          this.locations = array;
        },
        err => {

          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }

        }
      );
    }
    else{
      this.shiftsService.getPaginatedShiftLocations(this.page - 1, this.pageSize).subscribe(
        data => {
          console.table(data);
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }

          this.locations = data.content;
        },
        err => {

          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }

        }
      );
    }
  }

  getClients(){
    if(this.userType == "agency"){
      this.agencyService.getPaginatedAgencyClients(0, 100, this.agencyId).subscribe(
        data => { this.clients = data.content }
      );
    }else{
      this.clientService.getPaginatedClients(0, 100).subscribe(
        data => { this.clients = data.content }
      );
    }

  }

  openModal(modal) {
    this.modalService.open(modal, {centered: true});
  }

  deleteLocation(){

    if(this.selectedLocation.id){
      this.shiftsService.deleteShiftLocation(this.selectedLocation.id).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          this.getLocations();
          this.toast.success('Location Deleted Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }
  }

  newLocation(form: FormGroup){
    console.table(form.value);

    if(form.valid){
      this.shiftsService.createShiftLocation(form.value).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          this.addForm.reset();
          this.addForm.value.clientId = this.storageService.decrypt(localStorage.getItem('clientId'));
          this.getLocations();
          this.toast.success('Location Added Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }
  }

  updateLocation(form: FormGroup){
    console.log(form.value);

    if(form.valid){
      this.shiftsService.updateShiftLocation(form.value).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          this.getLocations();
          this.toast.success('Location Updated Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }
  }

  loadLocation(ref, modal, edit){
    if(ref){
      this.locations.forEach(r => {
        if(r.id == ref){
          this.selectedLocation = r;
        }
      });

      if(edit && this.selectedLocation){
        this.editForm = this.fb.group({
          id: [this.selectedLocation.id, Validators.required],
          name: [this.selectedLocation.name, Validators.required],
          // postcode: [this.selectedLocation.postcode, Validators.required],
          phoneNumber: [this.selectedLocation.phoneNumber],
        });
      }
    }

    if(modal){
      this.openModal(modal);
    }
  }

}
