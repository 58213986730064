  <div class="d-flex my-1">
    <span><h5>Inventory</h5></span>
    <button class="btn btn-primary btn-sm ml-auto" (click)="openModalFunction(inventory)">Add new</button>
  </div>

  <section class="">
      <table class="table table-striped mb-0 styled-table text-left">
        <thead>
            <tr class="text-left">
                <th>Item</th>
                <th>Date Installed</th>
                <th>Next Check Date</th>
                <!-- <th>Status</th> -->
                <!-- <th>Photos</th> -->
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let d of job.inventory;">
                <td>{{d.name}}</td>
                <td>{{d.dateInstalled | date: 'dd/MM/yy'}}</td>
                <td>{{d.nextCheckDate | date: 'dd/MM/yy'}}</td>
                <!-- <td >
                  <span class="mr-3 text-primary-custom pointer" >
                      <i class="fa fa-eye" title="edit"></i>
                  </span>
                </td> -->
            </tr>
        </tbody>
      </table>
      <section class="pagination-container" *ngIf="directorates">
        <div class="container-fluid">
          <div class="row m-0">
            <div class="col-12 col-sm-12 col-md-5 text-left acontent-center">
              Showing {{ (page - 1) * pageSize + 1 }} - {{ showin }} of {{ totalItems }}
            </div>
            <div class="col-12 col-sm-6 col-md-4 text-right">
              <button class="btn text-white" (click)="handlePageChange('prev')" [disabled]="first">
                <i class="fa fa-caret-up"></i>&nbsp;&nbsp;Previous Page
              </button>
            </div>
            <div class="col-12 col-sm-6 col-md-3 text-right pr-0">
              <button class="btn text-white" (click)="handlePageChange('next')" [disabled]="last">
                Next Page&nbsp;&nbsp;<i class="fa fa-caret-down"></i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <div class="d-flex mt-2">
        <div class="ml-auto btn-group">
          <button type="button" class="btn-secondary btn" (click)="goBack()">Back</button>
          <button type="button" class="btn-primary btn ml-1" (click)="goNext()">Next</button>
        </div>
      </div>

</section>



<ng-template #inventory let-modal>
  <div class="">
      <div class="modal-header bg-main text-white">
          <h5 class="modal-title text-center w-100">Add Inventory Item</h5>
          <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()"><span aria-hidden="true">&times;</span></span>
      </div>
      <div class="modal-body">
          <div class="bg-modal card-body">
              <div class="bg-primary-accent">
                  <div class="bg-modal card-body">
                      <div class="container-fluid">
                          <form >
                              <div class="">
                                  <div class="">
                                      <div class="">
                                          <div class="form-group">
                                              <select id="itemSelect" [(ngModel)]="selectedItem" name="item" class="form-control" required>
                                                  <option value="" disabled>Select inventory item</option>
                                                  <option *ngFor="let item of items" [value]="item">{{ item }}</option>
                                              </select>
                                          </div>
                                      </div>
                                      <div class="">
                                          <div class="form-group">
                                              <label>Date Installed</label>
                                              <input type="date" name="dateInstalled" [(ngModel)]="dateInstalled" class="form-control" name="date" required />
                                          </div>
                                      </div>
                                      <div  class="">
                                          <div class="form-group">
                                              <label>Next Check Date</label>
                                              <input type="date" name="nextCheckDate" [(ngModel)]="nextCheckDate" class="form-control" name="date" required />
                                          </div>
                                      </div>
                                      <!-- <div class="">
                                          <div class="form-group">
                                              <label for="">Upload Images</label><br>
                                              <input     type="file" id="files" name="files[]" multiple="multiple" (change)="onChange($event)" accept="image/png, image/webp, image/svg, image/jpg, image/jpeg">
                                          </div>
                                      </div> -->
                                  </div>
                              </div>
                              <div align="right" class="mt-3">
                                  <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
                                      Cancel
                                  </button>
                                  <button type="button" (click)="submitInventories()" class="btn btn-sm btn-primary">
                                      Add
                                  </button>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</ng-template>
