
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
// import { TrainerService } from 'src/app/shared/services/trainer.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UsersService } from 'src/app/shared/services/users.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ServicesService } from 'src/app/shared/services/services.service';
import { ClientService } from 'src/app/shared/services/client.service';
import { AgencyService } from 'src/app/shared/services/agency.service';
declare var $: any;

@Component({
  selector: 'app-trainers-list',
  templateUrl: './trainers-list.component.html',
  styleUrls: ['./trainers-list.component.css']
})
export class TrainersListComponent implements OnInit {

  trainers: any;
  userList: any = [];
  showUsers: boolean;
  services: any;
  trainerId: number;
  selectedTrainer: any;

  addForm: FormGroup;
  editForm: FormGroup;

  page = 1;
  pageSize = 20;
  totalItems = 0;
  showin = 0;
  first: boolean;
  last: boolean;

  userType: string;

  constructor(
    private toast: ToastrService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private agencyService: AgencyService,
    private servicesService: ServicesService,
    private authService: AuthService,
    private usersService: UsersService,
    private clientService: ClientService,
    private storageService: StorageService
  ) {
    this.userType = authService.getUserType();
  }

  ngOnInit(): void {
    this.getTrainers();

    this.formInit();

    this.editForm = this.fb.group({
      id: 0
    });

    this.getServices();
  }

  formInit(){
    this.addForm = this.fb.group({
      name: ['', Validators.required],
      logo: [''],
      // email: ['', Validators.required],
      status: ['ACTIVE'],
      agencyType: ['TRAINER'],
      serviceId: ['', Validators.required],
      address: this.fb.group({
        firstLine: ['', Validators.required],
        postcode: ['', Validators.required],
        town: ['', Validators.required],
      }),
      telephone: ['', Validators.required],
      administratorCreateDto: this.fb.group({
        adminEmail: ['', Validators.required],
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
      }),
      billingEmail: ['', Validators.required],
    });
  }

  handlePageChange(event) {

    if(event == 'next'){
      this.page += 1;
    }
    else if(event == 'prev' && this.page != 1){
      this.page -= 1;
    }

    this.getTrainers();
  }

  getServices(){
    this.servicesService.getPaginatedServices(0, 100).subscribe(
      data => {
        // console.table(data.content);
        this.services = data.content;
      }
    );
  }

  getTrainers(){
    // if(this.userType == 'admin'){
    //   this.trainerService.getPaginatedTrainers(this.page - 1, this.pageSize).subscribe(
    //     data => {
    //       // console.table(data.content);
    //       this.totalItems = data.totalElements;
    //       this.first = data.first;
    //       this.last = data.last;

    //       if(this.page * this.pageSize > data.totalElements){
    //         this.showin = data.totalElements;
    //       }
    //       else{
    //         this.showin = this.page * this.pageSize;
    //       }
    //       let array = data.content;
    //       array.sort(function(a, b){
    //           if(a.name < b.name) { return -1; }
    //           if(a.name > b.name) { return 1; }
    //           return 0;
    //       })
    //       this.trainers = array;
    //     },
    //     err => {

    //       if(err.status == 0){
    //         this.toast.error('Network Connection Failure');
    //       }
    //        else if(err.error?.message){
    //         this.toast.error(err.error.message);
    //       }else if(err.status == 500){
    //         this.toast.error('Internal Server Error')
    //       }

    //     }
    //   );
    // }
    // else{
    //   this.clientService.getPaginatedClientTrainers(this.page - 1, this.pageSize, this.storageService.decrypt(localStorage.getItem('clientId'))).subscribe(
    //     data => {
    //       // console.table(data.content);
    //       this.totalItems = data.totalElements;
    //       this.first = data.first;
    //       this.last = data.last;

    //       if(this.page * this.pageSize > data.totalElements){
    //         this.showin = data.totalElements;
    //       }
    //       else{
    //         this.showin = this.page * this.pageSize;
    //       }

    //       this.trainers = data.content;
    //     },
    //     err => {

    //       if(err.status == 0){
    //         this.toast.error('Network Connection Failure');
    //       }
    //        else if(err.error?.message){
    //         this.toast.error(err.error.message);
    //       }else if(err.status == 500){
    //         this.toast.error('Internal Server Error')
    //       }

    //     }
    //   );
    // }
  }

  openModal(modal){
    this.modalService.open(modal, {centered: true, size: 'lg'});
  }

  openBigModal(modal, ref){
    this.trainerId = ref;
    this.getUsers();
    this.modalService.open(modal, {centered: true, size: 'xl'});
  }

  newTrainer(form: FormGroup){
    console.table(form.value);

    if(form.valid){
      this.agencyService.createAgency(form.value).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          this.getTrainers();
          this.toast.success('Trainer Added Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  updateTrainer(form: FormGroup){
    // console.table(form.value);

    // if(form.valid){
    //   this.trainerService.updateTrainer(form.value).subscribe(
    //     resp => {
    //       // console.table(resp);
    //       this.modalService.dismissAll();
    //       this.getTrainers();
    //       this.toast.success('Trainer Updated Successfully');
    //     },
    //     err => {

    //       if(err.status == 0){
    //         this.toast.error('Network Connection Failure');
    //       }
    //       else if(err.error?.message){
    //         this.toast.error(err.error.message);
    //       }else if(err.status == 500){
    //         this.toast.error('Internal Server Error')
    //       }

    //     }
    //   );
    // }
    // else{
    //   this.toast.warning('Missing Required Info.');
    // }
  }

  loadTrainer(ref, modal){
    if(ref){
      // console.log(ref);

      this.trainers.forEach(r => {
        if(r.trainerId == ref){
          this.selectedTrainer = r;
        }
      });

      if(this.selectedTrainer){
        this.editForm = this.fb.group({
          trainerId: [this.selectedTrainer.trainerId, Validators.required],
          email: [this.selectedTrainer.email, Validators.required],
          name: [this.selectedTrainer.name, Validators.required],
          logo: [this.selectedTrainer.logo, Validators.required],
          serviceId: [this.getServiceId(this.selectedTrainer.service), Validators.required],
          address: this.fb.group({
            firstLine: [this.selectedTrainer.address.firstLine, Validators.required],
            postcode: [this.selectedTrainer.address.postcode, Validators.required],
            town: [this.selectedTrainer.address.town, Validators.required],
          }),
          telephone: [this.selectedTrainer.telephone, Validators.required],
          billingEmail: [this.selectedTrainer.billingEmail, Validators.required],
        });
      }

      this.openModal(modal);
    }

  }

  getServiceId(ref){
    let sid;
    if(ref){
      this.services.forEach(r => {
        if(r.name == ref){
          sid = r.id;
        }
      });
    }

    return sid;
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  checkEmail(ele){
    // console.log(ele);

    var typed = ele.target.value;

    if(this.validateEmail(typed)){
      $(ele.target).removeClass('is-invalid');
      $(ele.target).addClass('is-valid');
    }
    else{
      $(ele.target).removeClass('is-valid');
      $(ele.target).addClass('is-invalid');
    }
  }

  getUsers(){
    // this.usersService.getPaginatedTrainerUsers(0, 100, this.trainerId).subscribe(
    //   data => {
    //     // console.table(data.content);

    //     this.userList = data.content;
    //     this.showUsers = true;
    //   }
    // );
  }

  getName(name){
    if(name){
      return name.replace(/_/g, " ");
    }

    return '';
  }

  activateUser(ref, role, enable){

    let formData: FormGroup = this.fb.group({
      userId : ref,
      roleName: role,
      enabled: enable
    });

    this.usersService.enableUser(formData.value).subscribe(
      resp => {
        // console.log(resp);
        this.toast.success('User Status Changed.')
        this.getUsers();
      }
    );

  }

  activateAgent(ref){
    // if(ref){
    //   this.trainerService.activateTrainer(ref).subscribe(
    //     resp => {
    //       // console.table(resp);
    //       this.getTrainers();
    //       this.toast.success('Trainer Activated Successfully');
    //     },
    //     err => {

    //       if(err.status == 0){
    //         this.toast.error('Network Connection Failure');
    //       // }
    //       // else if(err.error?.message){
    //       //   this.toast.error(err.error.message);
    //       }else if(err.status == 500){
    //         this.toast.error('Internal Server Error')
    //       }

    //     }
    //   );
    // }
    // else{
    //   this.toast.warning('Missing Required Info.');
    // }
  }

  deactivateAgent(ref){
    // if(ref){
    //   this.trainerService.deactivateTrainer(ref).subscribe(
    //     resp => {
    //       // console.table(resp);
    //       this.getTrainers();
    //       this.toast.success('Trainer De-activated Successfully');
    //     },
    //     err => {

    //       if(err.status == 0){
    //         this.toast.error('Network Connection Failure');
    //       }
    //       else if(err.error?.message){
    //         this.toast.error(err.error.message);
    //       }else if(err.status == 500){
    //         this.toast.error('Internal Server Error')
    //       }

    //     }
    //   );
    // }
    // else{
    //   this.toast.warning('Missing Required Info.');
    // }
  }

}
