<main class="mt-5">
  <!-- Header part -->
  <div class="container-fluid">
    <div class="row aitems-center">
      <div class="col-12 col-sm-12 col-md-4 text-left">
        <h3 class="mb-0"></h3>
      </div>
      <div class="col-12 col-sm-6 col-md-8 row pr-0 justify-right">
        <span>
            <div class="input-group">
                <input
                  type="text"
                  class="form-control  border-right-0"
                  placeholder="Search"
                  aria-describedby="inputGroupPrepend2"
                  required
                  [(ngModel)]="searchCriteria"
                />
                <div class="input-group-prepend" (click)="getVehicles()">
                  <span class="input-group-text bg-transparent border-left-0" id="inputGroupPrepend2">
                      <i class="fa fa-search"></i>
                  </span>
                </div>
              </div>
            </span>
            <span class="ml-2">
              <button class="btn btn-success" (click)="formInit(add)">
                Add Vehicle
            </button>
            </span>
      </div>
    </div>
  </div>

  <!-- Content -->
  <section class="content mt-4">
      <div class="container-fluid">
        <table class="table table-striped mb-0 styled-table text-left">
          <thead>
              <tr class="text-left">
                  <th>Model</th>
                  <th>Name</th>
                  <th>Reg No</th>
                  <th>Colour</th>
                  <th>Status</th>
                  <th>Actions</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let d of vehicles;">
                  <td>{{d.description}}</td>
                  <td>{{d.name}}</td>
                  <td>{{d.regno}}</td>
                  <td>{{d.color}}</td>
                  <td>{{d.status}}</td>
                  <td>
                    <a routerLink="/agency/jobs/vehicle/view/{{d.id}}"><span class="mr-3 text-primary-custom pointer">
                        <i class="fa fa-eye" title="edit"></i>
                    </span></a>
                    <!-- <span class="text-danger-custom pointer" (click)="loadDirectorate(d.id, delete, false)">
                        <i class="fa fa-trash-alt" title="delete"></i>
                    </span> -->
                  </td>
              </tr>
          </tbody>
        </table>
        <section class="pagination-container" *ngIf="vehicles">
          <div class="container-fluid">
            <div class="row m-0">
              <div class="col-12 col-sm-12 col-md-5 text-left acontent-center">
                Showing {{ (page - 1) * pageSize + 1 }} - {{ showin }} of {{ totalItems }}
              </div>
              <div class="col-12 col-sm-6 col-md-4 text-right">
                <button class="btn text-white" (click)="handlePageChange('prev')" [disabled]="first">
                  <i class="fa fa-caret-up"></i>&nbsp;&nbsp;Previous Page
                </button>
              </div>
              <div class="col-12 col-sm-6 col-md-3 text-right pr-0">
                <button class="btn text-white" (click)="handlePageChange('next')" [disabled]="last">
                  Next Page&nbsp;&nbsp;<i class="fa fa-caret-down"></i>
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
  </section>
</main>

<!-- Modals -->
<ng-template class="modal fade" #delete let-modal>
<div class="">
  <div class="modal-header bg-danger text-white">
    <h5 class="modal-title text-center w-100">Delete Vehicle</h5>
    <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
      (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </span>
  </div>
  <div class="modal-body">
    <div class="bg-modal card-body">
      <div class="text-center">
        <h3>You are about to delete this Vehicle?</h3>
      </div>
    </div>
    <div align="right" class="mt-3">
      <button class="btn btn-info btn-sm mr-3" (click)="modal.dismiss()">
        Cancel
      </button>
      <button class="btn btn-sm btn-danger" (click)="deleteDirectorate()">
        Delete Vehicle
      </button>
    </div>
  </div>
</div>
</ng-template>

<ng-template class="modal fade" #add let-modal>
<div class="">
  <div class="modal-header bg-main text-white">
    <h5 class="modal-title text-center mx-4 w-100">Add Vehicle</h5>
    <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
      (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </span>
  </div>
  <div class="modal-body">
    <form [formGroup]="addForm" (ngSubmit)="createVehicle(addForm)">
      <div class="bg-modal card-body">
        <div class="container-fluid">
          <div class="">
            <div class="">
              <i class="bi bi-info-circle"></i>
              <span class="ml-2">Vehicle Details</span>
              <hr class="bg-black mt-0">
            </div>
            <div class="">
              <div class="form-group">
                <!-- <label>Name</label> -->
                <input type="text" maxlength="255" formControlName="name" placeholder="Vehicle Name" class="form-control">
              </div>
            </div>
            <div class="">
              <div class="form-group">
                <!-- <label>Descriptio</label> -->
                <input type="text" maxlength="255" formControlName="description" placeholder="Model"
                  class="form-control">
              </div>
            </div>
            <div class="">
              <div class="form-group">
                <!-- <label>Descriptio</label> -->
                <input type="text" maxlength="255" formControlName="color" placeholder="Colour"
                  class="form-control">
              </div>
            </div>
            <div class="">
              <div class="form-group">
                <!-- <label>Descriptio</label> -->
                <input type="text" maxlength="255" formControlName="regno" placeholder="Registration Number"
                  class="form-control">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div align="right" class="mt-3">
        <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
          Cancel
        </button>
        <button type="submit" class="btn btn-sm btn-success">
          Add Vehicle
        </button>
      </div>
    </form>
  </div>
</div>
</ng-template>

<ng-template class="modal fade" #edit let-modal>
<div class="">
  <div class="modal-header bg-main text-white">
    <h5 class="modal-title text-center w-100">View & Edit Vehicle</h5>
    <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
      (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </span>
  </div>
  <div class="modal-body">
    <form [formGroup]="editForm" (ngSubmit)="updateDirectorate(editForm)">
      <div class="bg-modal card-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <i class="bi bi-info-circle"></i>
              <span class="ml-2">Vehicle Details</span>
              <hr class="bg-black mt-0">
            </div>
            <div class="col-12">
              <div class="form-group">
                <select formControlName="locationId" disabled class="form-control">
                  <option value="" selected disabled>Select Location</option>
                  <option *ngFor="let l of locations;" value="{{l.id}}">{{l.name}}</option>
                </select>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <input type="text" maxlength="255" formControlName="postCode" placeholder="Post Code" class="form-control">
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <input type="text" maxlength="255" formControlName="name" disabled  placeholder="Vehicle Name"
                  class="form-control">
              </div>
            </div>
            <div class="col-12 mt-4">
              <i class="bi bi-info-circle"></i>
              <span class="ml-2">Contact Details</span>
              <hr class="bg-black mt-0">
            </div>
            <div class="col-12">
              <div class="form-group">
                <input type="text" maxlength="255" formControlName="phoneNumber" placeholder="Phone Number"
                  class="form-control col-12 col-md-8">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div align="right" *ngIf="userType != 'admin'" class="mt-3">
        <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
          Cancel
        </button>
        <button type="submit" class="btn btn-sm btn-success">
          Update Vehicle
        </button>
      </div>
    </form>
  </div>
</div>
</ng-template>
