<form  [formGroup]="form" (ngSubmit)="updateMileage()">
  <div class="d-flex">
    <span><h5>Photos</h5></span>
  </div>

  <section>
    <!-- Gallery -->
    <div class="row photos">
      <div *ngFor="let p of job?.photos" class="col-sm-6 col-md-4 col-lg-3 item">
        <span (click)="openModal(viewImageModal,p)"  data-lightbox="photos"><img class="img-fluid zoom-box" src="{{p.url}}"></span>
      </div>
      <div class="col-md-4">
        <div class="profile-picture">
          <h1 class="upload-icon">
              <i class="bi bi-upload"></i>
          </h1>
          <input    type="file" id="files" name="files[]"  (change)="onChange($event)" accept="image/png, image/webp, image/svg, image/jpg, image/jpeg">
        </div>
      </div>
    </div>



    <!-- Gallery -->
  </section>
  <div class=" col-12 mt-2 text-right" >
    <button type="button" class="btn-danger text-right mr-1" (click)="prevTab()" >Back</button>
    <!-- <button type="submit" class="btn-primary text-right" (click)="updateMileage(); nextTab();">Next</button> -->
  </div>

</form>




<ng-template #viewImageModal let-modal>
  <div class="">
      <div class="modal-body">
          <div class="bg-modal card-body">
              <div class="bg-primary-accent">
                  <div class="p-1">
                      <div class="">
                          <form >
                              <div class="text-center">
                                <img class="img-fluid" style="max-height: 70vh;" src="{{selectedImage.url}}">
                              </div>
                              <div align="center" class="mt-3">
                                <button type="button" class="btn btn-danger btn-sm " (click)="deletePhoto()">
                                  Delete
                                 </button>
                                  <button type="button" class="btn btn-primary btn-sm mx-3" (click)="modal.dismiss()">
                                      Close
                                  </button>

                                  <button type="button" (click)="setMain()" class="btn btn-sm btn-primary">
                                      Set As Main
                                  </button>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</ng-template>
