import { AuthService } from 'src/app/shared/services/auth.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AssetService {

  base_url: string = environment.API_url + '/worklink-api/api/v1';
  userType: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private storageService: StorageService
  ) {
    this.userType = this.authService.getUserType();
   }


  createAsset(data): Observable<any> {
    return this.http.post(`${this.base_url}/asset`,data);
  }

  getAgencyAssets(agencyId: Int32Array, status: string, searchCriteria: string, page, size): Observable<any> {
    return this.http.get(`${this.base_url}/asset/agency/type/${agencyId}/${status}/${page}/${size}?searchCriteria=${searchCriteria??''}`);
  }

  getAsset(id: Int32Array): Observable<any> {
    return this.http.get(`${this.base_url}/asset/${id}}`);
  }


}
