import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TransportService } from 'src/app/shared/services/transport.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShiftService } from 'src/app/shared/services/shift.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ClientService } from 'src/app/shared/services/client.service';
import { AssignmentCodeService } from 'src/app/shared/services/assignment-code.service';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { StatisticsService } from 'src/app/shared/services/statistics.service';

@Component({
  selector: 'app-transport',
  templateUrl: './transport.component.html',
  styleUrls: ['./transport.component.css']
})
export class ClientTransportListComponent implements OnInit {

  agencyFilter  : string= "";
  clientFilter  : string= "";
  startDateFilter: string= "";
  endDateFilter: string= "";
  directorateFilter: string= "";
  worker: any;
  countries: { name: string; code: string; }[];
  rde2 =false;
  restriction = false;
  eHistory= [{}];
  nHistory:[FormGroup];
  education=[{}];

  directorates :any;
  addForm: FormGroup;

  selectedTab: string = 'new';
  edit: any;
  locations: any;
  clientId: any;
  agencies: any;
  assCodes: any;
  selectedPDirectorate: any;
  pickupLocationContactNumber: any;
  pPostCode: any;
  shiftsSummary: any;


  constructor(
    private router: Router,
    private agencyService: AgencyService,
    private transportService: TransportService,
    private toast : ToastrService,
    private statisticsService : StatisticsService,
    private shiftsService: ShiftService,
    private storageService: StorageService,
    private assignmentCodeService: AssignmentCodeService,
    private fb: FormBuilder,
    private clientService: ClientService,
    private modalService: NgbModal
  ) {
    this.clientId =this.storageService.decrypt(localStorage.getItem('clientId'));
   }


  ngOnInit(): void {
    console.log("client transport list");
    this.getAgencies();
    this.getLocations();
    this.getCodes();
    // this.getStats();


    this.addForm = this.fb.group({

      transportLegibleAgencyIds : [[], Validators.required],
      pickupDirectorateId : [[], Validators.required],
      // canPassengerWalk : ["", Validators.required],
      clientId : [this.clientId, Validators.required],
      dateTimeRequired : ["", Validators.required],
      destination : ["", Validators.required],
      destinationContactNumber : ["", Validators.required],
      destinationPostCode : ["", Validators.required],
      escortServiceRisk : ["", Validators.required],
      passengerAdditionalRisks : [""],
      isPassengerAwareOfTransport : ['', Validators.required],
      passengerAge : [ "", Validators.required],
      passengerGender : ["", Validators.required],
      // passengerNHSNumber : ["", Validators.required],
      // passengerName : ["", Validators.required],
      physicalAggression : [""],
      verballyAggressive : [""],
      specialRequests : [""],
      sexuallyInappropriate : [""],
      selfHarm : [""],
      reasonsForRestrains : [""],
      reasonForTransport : ["",Validators.required],
      passengerRequiresRestraints : ["", Validators.required],
      // passengerRisksSet : ["", Validators.required],
      // passengerWalkInfo : [ "", Validators.required],
      pickupLocationContactNumber : [""],
      // pickupLocationId : ["", Validators.required],
      pPostCode : [""],
      assaultStaff : [''],
      absconsionRisk: [''],
      otherRisks: [""],
      otherMobilityIssues: [''],
      mobilityIssues: ["", Validators.required],
      transportWorkerSpecList : this.fb.array([
        this.fb.group({
          assignmentCode : ["", Validators.required],
          gender : ["", Validators.required],
          numberOfStaff : [],
       })
     ]),


      // file : [ ],



    });
  }

  get escortStaffArray() {
    return <FormArray>this.addForm.get('transportWorkerSpecList');
  }


  displayPickupDirectorateInfo(){
    let d = this.directorates.filter(v => v.id.toString() == this.addForm.value.pickupDirectorateId);

    console.log(d);
    console.log(this.directorates, this.addForm.value.pickupDirectorateId);

    this.pickupLocationContactNumber = d[0]?.phoneNumber??"";
    this.pPostCode = d[0]?.postCode?? "";

    this.addForm.patchValue({
      pickupLocationContactNumber: d[0]?.phoneNumber,
      pPostCode: d[0]?.postCode
    });
    console.log(this.addForm.value)
  }

  getCodes(){
    this.assignmentCodeService.getPaginatedAssignmentCodes(0, 100).subscribe(
      data => {
        // console.table(data);

        this.assCodes = data;
      }
    );
  }


  getAgencies(){
    this.agencyService.getPaginatedAgencies(0, 100, 'TRANSPORTER').subscribe(
      data => {
        // console.table(data.content);

        let array = data.content;
        array.sort(function(a, b){
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })

        this.agencies = array;
      }
    );
  }




  selectTab(ref) {
    if(ref){
      this.selectedTab = ref;
    }
  }

  newPage(){
    this.router.navigate(['/client/new']);
  }


  getLocations(){
    this.shiftsService.getPaginatedClientDirectorates(0, 1000, this.clientId).subscribe(
      data => {
        this.directorates = data.content;
        console.log(this.locations);
      }
    );
  }



  createJob(form){
    if(form.valid){
    this.transportService.createTransport(form.value).subscribe(
      data => {
      this.toast.success("Job created");
        this.addForm.reset();
        let last = this.selectedTab.toString();
        this.selectedTab = "none";
        this.ngOnInit();
        this.selectedTab = last;
        this.modalService.dismissAll();
      }
    );}else{
      console.log(form);
      this.toast.warning("Missing info");
    }
  }

  clearFilters(){
    this.agencyFilter     = "";
    this.clientFilter     = "";
    this.startDateFilter   = "";
    this.endDateFilter   = "";
    this.directorateFilter   = "";
  }
  formInit(modal){


    this.openModal(modal);
  }

  openModal(modal: any){
    this.modalService.open(modal);
  }


  track(item: any, index: number) {
    return index;
  }
toggleEdit(){
      this.edit = !this.edit;
    }



    removeEscort(i: number) {
      this.escortStaffArray.removeAt(i);
    }

    addEscort(){

      this.escortStaffArray.push(
        this.fb.group({
          assignmentCode : ["", Validators.required],
          gender : ["", Validators.required],
          numberOfStaff : [],
       })
      );



  }

  getStats(){
    this.statisticsService.getAgencyTransportShiftsDashboard( this.storageService.decrypt(localStorage.getItem('clientId'))).subscribe(
      data => {
        this.shiftsSummary = data;
      }
    );}

}
