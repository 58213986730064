<main class="mt-5" id="content">

    <!-- Header part -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-5 text-left acontent-center">

         <h3>Flexi-Workers</h3>

        </div>


        <div class="col-12 col-sm-6 col-md-6 text-center">
          <div class="input-group">
            <form action="" class="d-flex" *ngIf="userType =='agency'">
              <input type="text" maxlength="255" class="form-control col-12 border-right-0" placeholder="Search Worker"
              aria-describedby="inputGroupPrepend2" required [(ngModel)]="searchCriteria" (keyup.enter)="searchWorkers(searchCriteria)"
              [ngModelOptions]="{standalone: true}"/>
              <div class="input-group-prepend">
                <span class="input-group-text bg-transparent border-left-0" (click)="searchWorkers(searchCriteria)" id="inputGroupPrepend2">
                  <i class="fa fa-search"></i>
                </span>
              </div>
            </form>
            <form action="" class="d-flex" *ngIf="userType =='client'">
              <input type="text" maxlength="255" class="form-control col-12 border-right-0" placeholder="Search Worker"
              aria-describedby="inputGroupPrepend2" required [(ngModel)]="searchCriteria" (keyup.enter)="searchClientWorkers(searchCriteria)"
              [ngModelOptions]="{standalone: true}"/>
              <div class="input-group-prepend">
                <span class="input-group-text bg-transparent border-left-0" (click)="searchClientWorkers(searchCriteria)" id="inputGroupPrepend2">
                  <i class="fa fa-search"></i>
                </span>
              </div>
            </form>
          </div>
        </div>
        <!--div class="col-12 col-sm-6 col-md-4 text-md-right text-sm-left mt-2 mt-md-0">
          <button class="btn btn-success" *ngIf="userType == 'agency'" title="Add a new worker to the list." (click)="openDialog()">
            <i class="fa fa-plus-circle" ></i>&nbsp;&nbsp;Add Applicant
          </button>
        </div-->
      </div>
    </div>

    <!-- content -->
    <section class="content mt-4">
      <div class="container-fluid" *ngIf="userType != 'agency'">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-2 text-right pt-2">
            <span class="text-black-50">Filters</span>
          </div>
          <div class="col-12 col-sm-6 col-md-2 text-left">
            <select name="" class="form-control">
              <option value="" selected disabled>Select Agency</option>
            </select>
          </div>
        </div>
      </div>

      <div class="container-fluid mt-4">
        <table class="table  table-striped mb-0 styled-table  text-left">
          <thead>
            <tr class="text-left">
              <th>Name</th>
              <th>Assignment<br>Name</th>
              <th>Assignment<br>Code</th>
              <th>Phone</th>
              <th>Email</th>
              <th style="min-width: 120px;">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let w of workers;">
              <td class="text-primary-custom" *ngIf="userType=='client'" (click)="openModal(view,w)">{{w.firstname}} {{w.lastname}}


              </td>
              <td class="text-primary-custom" *ngIf="userType!='client'" (click)="detailsPage(w.id)">{{w.firstname}} {{w.lastname}}


              </td>
              <td>{{w?.assignmentName}}</td>
              <td>{{w.assignmentCode}}</td>
              <td>{{w.phoneNumber}}</td>
              <td>{{w.email}}

              </td>
              <td>
                <!-- <span class="text-success mr-2 pointer" *ngIf="userType == 'agency'"  (click)="loadWorker(w.id, edit, true)">
                  <i class="fa fa-pencil-alt" title="edit"></i>
                </span> -->
                <span *ngIf="userType != 'agency'" class="text-primary-custom mr-2 pointer" (click)="openModal2(view,w);">
                    <i class="fa fa-eye" title="view" title="View Worker Details"></i>
                </span>
                <span *ngIf="userType=='agency'" class="text-primary-custom mr-2 pointer" (click)="detailsPage(w.id)">
                    <i class="fa fa-eye" title="view" title="View Worker Details"></i>
                </span>
                <span class="text-primary-custom mr-2 pointer" >
                  <mat-datepicker-toggle matSuffix  class="text-primary-custom" (click)="openCalander(availability, w.id)" ></mat-datepicker-toggle>
                </span>
                <!-- <span class="mr-3 text-danger-custom pointer" title="De-Activate User" (click)="activateUser(w.id, false)" *ngIf="w.agencyStatus == 'ACTIVE' && userType == 'agency'">
                  <i class="fa fa-lock"></i>
                </span> -->
                <span class="mr-3 text-success pointer" title="Activate User" (click)="activateUser(w.id, true)" *ngIf="w.agencyStatus == 'INACTIVE' && userType == 'agency'">
                  <i class="fa fa-lock-open"></i>
                </span>
                <img *ngIf="w.agencyStatus!= 'APPROVED' && userType!='client'" alt="alert danger icon" style="width:15px"  title="Worker Inactive" src="/assets/images/alert_danger.png">

              </td>
            </tr>
          </tbody>
        </table>
        <section class="pagination-container" *ngIf="workers">
          <div class="">
            <div class="row m-0">
              <div class="col-12 col-sm-12 col-md-5 text-left acontent-center">
                Showing {{ (page - 1) * pageSize + 1 }} - {{ showin }} of {{ totalItems }}
              </div>
              <div class="col-12 col-sm-6 col-md-4 text-right">
                <button class="btn text-white" (click)="handlePageChange('prev')" [disabled]="first">
                  <i class="fa fa-caret-up"></i>&nbsp;&nbsp;Previous Page
                </button>
              </div>
              <div class="col-12 col-sm-6 col-md-3 text-right pr-0">
                <button class="btn text-white" (click)="handlePageChange('next')" [disabled]="last">
                  Next Page&nbsp;&nbsp;<i class="fa fa-caret-down"></i>
                </button>
              </div>
            </div>
          </div>
        </section>


      </div>


    </section>

  </main>

  <!-- Modals -->
  <ng-template class="modal fade" #add let-modal>
    <div class="">
        <div class="modal-header bg-main text-white">
            <h5 class="modal-title text-center w-100" title="Add a new worker to the list.">Add Flexi-Worker</h5>
            <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </span>
        </div>
        <div class="modal-body">
            <form [formGroup]="addForm" (ngSubmit)="newWorker(addForm)">
              <div class="bg-modal card-body">
                <div class="container-fluid">
                  <div class="form-group row">
                    <!-- <div class="col-4 text-capitalize">Link Worker</div> -->
                    <div class="col-10 m-auto">
                      <!-- <select class="form-control" title="Link a new worker account to existing account" (change)="loadWorkerInfo($event)">
                        <option value="" selected disabled>choose...</option>
                        <option value="NEW">Create New</option>
                        <option *ngFor="let w of allWorkers" value="{{w.id}}">{{w.email}} {{w.lastname}} </option>
                      </select> -->




                    </div>
                  </div>
                  <hr>
                </div>
                <fieldset class="container-fluid" [disabled]="formArea">
                  <div class="form-group row">
                    <div class="col-4 text-capitalize">First Name</div>
                    <div class="col-8">
                      <input type="text" maxlength="255" title="First name of the Flexi-worker" formControlName="firstname" class="form-control" placeholder="First Name">
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-4 text-capitalize">Last Name</div>
                    <div class="col-8">
                      <input type="text" maxlength="255" formControlName="lastname" title="Last name of the Flexi-Worker." class="form-control" placeholder="Last Name">
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-4 text-capitalize" title="Select the gender">Gender</div>
                    <div class="col-8">
                      <select formControlName="gender" class="form-control">
                        <option value="" selected disabled>Select gender</option>
                        <option value="FEMALE">Female</option>
                        <option value="MALE">Male</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-4 text-capitalize">Phone Number</div>
                    <div class="col-8">
                      <input type="text" maxlength="255" formControlName="phoneNumber" class="form-control" placeholder="Phone Number">
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-4 text-capitalize">Email</div>
                    <div class="col-8">
                      <input type="email" (keyup)="checkEmail($event)" formControlName="email" class="form-control" placeholder="Email">
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-4 text-capitalize">Username</div>
                    <div class="col-8">
                      <input type="text" maxlength="255" formControlName="username" class="form-control" placeholder="Username">
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-4 text-capitalize">Assignment Code</div>
                    <div class="col-8">
                      <select formControlName="assignmentCode" class="form-control">
                        <option value="" selected disabled>Select Code</option>
                        <option *ngFor="let a of assCodes;" value="{{a.id}}">{{a.name}}, {{a.code}}</option>
                      </select>
                    </div>
                  </div>
                  <!-- <div class="form-group row">
                    <div class="col-8 text-capitalize">Do You want to send Login Details?</div>
                    <div class="col-4">
                      YES <input type="radio" class="mr-5 ml-3" name="credentials" value="true">
                      No <input type="radio" class="ml-3" name="credentials" value="false">
                    </div>
                  </div> -->
                </fieldset>
              </div>
              <div align="right" class="mt-3">
                <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
                    Cancel
                </button>
                <button type="submit" *ngIf="new" title="Add a new worker to the list." class="btn btn-sm btn-success">
                    Add Flexi-Worker
                </button>
                <button type="button" *ngIf="existing" title="Add a new worker to the link." (click)="linkWorker()" class="btn btn-sm btn-success">
                  Add Flexi-Worker Link
              </button>
              </div>
            </form>

        </div>
    </div>
  </ng-template>

  <ng-template class="modal fade" #edit let-modal>
    <div class="">
        <div class="modal-header bg-main text-white">
            <h5 class="modal-title text-center w-100">Edit Flexi-Worker 1</h5>
            <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </span>
        </div>
        <div class="modal-body">
            <form [formGroup]="editForm" (ngSubmit)="updateWorker(editForm)">
              <div class="bg-modal card-body">
                <div class="container-fluid">
                  <div class="form-group row">
                    <div class="col-4 text-capitalize">First Name</div>
                    <div class="col-8">
                      <input type="text" maxlength="255" formControlName="firstname" class="form-control" placeholder="First Name">
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-4 text-capitalize">Last Name</div>
                    <div class="col-8">
                      <input type="text" maxlength="255" formControlName="lastname" class="form-control" placeholder="Last Name">
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-4 text-capitalize">Gender</div>
                    <div class="col-8">
                      <select formControlName="gender" class="form-control">
                        <option value="" selected disabled>Select gender</option>
                        <option value="FEMALE">Female</option>
                        <option value="MALE">Male</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-4 text-capitalize">Phone Number</div>
                    <div class="col-8">
                      <input type="text" maxlength="255" formControlName="phoneNumber" class="form-control" placeholder="Phone Number">
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-4 text-capitalize">Email</div>
                    <div class="col-8">
                      <input type="email" (keyup)="checkEmail($event)" formControlName="email" class="form-control" placeholder="Email">
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-4 text-capitalize">Username</div>
                    <div class="col-8">
                      <input type="text" maxlength="255" formControlName="username" class="form-control" placeholder="Username">
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-4 text-capitalize">Assignment Code</div>
                    <div class="col-8">
                      <select formControlName="assignmentCodeId" class="form-control">
                        <option value="" selected disabled>Select Code</option>
                        <option *ngFor="let a of assCodes;" value="{{a.id}}">{{a.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div align="right" class="mt-3">
                <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
                    Cancel
                </button>
                <button type="submit" class="btn btn-sm btn-success">
                    Update Flexi-Worker
                </button>
              </div>
            </form>
        </div>
    </div>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/chosen/1.4.2/chosen.jquery.min.js"></script>
<script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.3.8/angular.min.js"></script>
<script src="https://adityasharat.github.io/angular-chosen/javascripts/angular-chosen.min.js"></script>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/chosen/1.4.2/chosen.min.css" />
  </ng-template>

  <!-- Availability -->
<ng-template class="modal fade" #view let-modal>
  <div class=" border-0">
    <div class="modal-header bg-main text-white">
      <h5 class="modal-title text-center w-100">Select Associated Agency</h5>
    <span
      type="button"
      class="btn close text-white"
      data-dismiss="modal"
      aria-label="Close"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </span>
  </div>
  <div class="m-4">
  <div class="m-4" *ngFor="let a of activeAgencies">
    <button type="button" class="ml-4 btn" (click)="detailsPageClient(a.id); modal.dismiss()">
      <i class="bi bi-circle-fill " style="color: rgb(49, 120, 252)"></i>&nbsp;&nbsp;
      {{ a.name}}
    </button>

  </div>

  </div>


  </div>
</ng-template>
  <!-- Availability -->
<ng-template class="modal fade" #availability let-modal>
  <div class=" border-0">
    <div class="modal-header bg-main text-white">
      <h5 class="modal-title text-center w-100">Flexi-Worker Availability</h5>
    <span
      type="button"
      class="btn close text-white"
      data-dismiss="modal"
      aria-label="Close"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </span>
  </div>
  <div class="m-4">
      <div class="m-4">
    <button type="button" class="ml-4 btn">
      <i class="bi bi-circle-fill " style="color: rgb(49, 120, 252); font-size: 40px;"></i>&nbsp;&nbsp;
      <br>Booked
    </button>

       <button type="button" class="ml-4 btn">
      <i class="bi bi-circle-fill " style="color: rgb(233, 74, 69); font-size: 40px;"></i>&nbsp;&nbsp;
      <br>UNAVAILABLE
    </button>

    <button type="button" class="ml-4 btn">
      <i class="bi bi-circle-fill " style="color: rgb(235, 232, 232); font-size: 40px;"></i>&nbsp;&nbsp;
      <br>AVAILABLE
    </button>

    <!-- <p class="text-primary-custom m-4">
      <i class="bi bi-info-circle"></i>&nbsp;&nbsp;
      Tap on a date to view details for a booked shift
    </p> -->
  </div>
  <mat-calendar  [dateClass]="dateClass()"  #dp3 disabled="false" ></mat-calendar>

  </div>


  </div>
</ng-template>
