import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private documentSource = new BehaviorSubject<any>(null);
  currentDocument = this.documentSource.asObservable();

  constructor() {}

  updateInventory(inventory: any) {
    this.documentSource.next(inventory);
  }
}