import { ClientService } from 'src/app/shared/services/client.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssignmentCodeService } from './../../../shared/services/assignment-code.service';
import { AgencyService } from './../../../shared/services/agency.service';
import { ToastrService } from 'ngx-toastr';
import { ShiftService } from './../../../shared/services/shift.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { WorkerService } from 'src/app/shared/services/worker.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { MatCalendarCellClassFunction, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/shared/services/auth.service';

declare var $: any;

@Component({
  selector: 'app-new-shift',
  templateUrl: './new-shift.component.html',
  styleUrls: ['./new-shift.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NewShiftComponent implements OnInit {

  addForm: FormGroup;
  loader: boolean;
  bookingConflicts = [];

  quest: boolean;
  workerids: Array<any> = [];
  agencies: any;
  locations: any;
  types: any;
  workers: any;
  directorates: any;
  assCodes: any;
  date = new Date();
  date1 = ( this.date.getFullYear() +"-"+ String(this.date.getMonth()+1).padStart(2, '0') +"-"+ String(this.date.getDate()).padStart(2, '0')  );
  shifts:any = [
     {
      'startdate': this.date1 ,
      'starttime':'00:00' ,
      'enddate':this.date1 ,
      'endtime':'00:00' ,
      'breaktime': '0hr 0mins',
      'numberofshifts':'1',
      'minend':this.date1,
      'worker':"",
      'agency':"",
      'breaktimehr': '0',
      'breaktimemins': '0'
  }
  ];
  row: any = {
    'startdate':this.date1 ,
    'starttime':'00:00' ,
    'enddate':this.date1  ,
    'endtime':'00:00' ,
    'breaktimehr': '0',
    'breaktime': '0',
    'breaktimemins': '0',
    'worker':"",
    'agency':"",
    'numberofshifts':'1',
    'minend':this.date1
  };
  shiftrow: any= {
  };

  page = 1;
  pageSize = 2500;

  location: number;
  activeShift: any;
  conflicts=[];
  toggleDate: any = 99999;
  currentBookings: any;
  bookedShifts: any;
  unavailableDates: any[];
  userType: any;
  clientId: any;
  agencyId: any;
  clients: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private shiftService: ShiftService,
    private toast: ToastrService,
    private agencyService: AgencyService,
    private assignmentService: AssignmentCodeService,
    private modalService: NgbModal,
    private authService: AuthService,
    private clientService: ClientService,
    private workerService: WorkerService,
    private storageService: StorageService
  ) {

    this.userType = authService.getUserType();
    this.agencyId = this.storageService.decrypt(localStorage.getItem('agentId'));
    this.clientId = this.storageService.decrypt(localStorage.getItem('clientId'));
    this.addForm = this.fb.group({
      notes: [" "],
      agentIdList: [this.userType=="agency"?[this.agencyId]:[], Validators.required],
      assignmentCodeId: ['', Validators.required],
      breakTime: [''],
      agency: [''],
      workerId: [this.agencyId],
      directBooking: [],
      clientId: [this.clientId??"", Validators.required],
      gender: ['', Validators.required],
      hoursBeforeBroadcasting: ['1', Validators.required],
      publishToAllWorkers: [false],
      requireApplicationByWorkers: [false],
      shiftDate: [''],
      shiftEndDate: [''],
      shiftDirectorateId: ['', Validators.required],
      shiftEndTime: [''],
      shiftLocationId: ['', Validators.required],
      shiftStartTime: [''],
      shiftStatus: ['NEW', Validators.required],
      shiftType: ['', Validators.required],
      showNoteToAgency: [true, Validators.required],
      showNoteToFw: [true, Validators.required]

    });
   }

  ngOnInit(): void {

    if(this.userType=='agency'){this.getClients();}
    if(this.userType=='client'){
      this.getAgencies();
      this.getCodes();
      this.getLocations();
      this.getDirectorates();
      this.getTypes();
    }

    this.checkClient();

    var highlightDate = [];

    highlightDate.push(new Date('06/20/2022'));
    highlightDate.push(new Date('06/21/2022'));
    highlightDate.push(new Date('06/22/2022'));


  }

  checkClient(){
    if(this.addForm.get('clientId').value=="") {
      this.addForm.get('assignmentCodeId').disable();
      this.addForm.get('shiftType').disable();
      this.addForm.get('shiftDirectorateId').disable();
    }else{
      this.addForm.get('assignmentCodeId').enable();
      this.addForm.get('shiftType').enable();
      this.addForm.get('shiftDirectorateId').enable();
    }

  }

  getClients(){
    this.agencyService.getPaginatedAgencyClients(this.page - 1, this.pageSize, this.storageService.decrypt(localStorage.getItem('agentId'))).subscribe(
      data => {
        this.clients = data.content;
      }
    );
  }

  getAgencies(){
    this.clientService.getPaginatedClientAgencies(0, 100, this.clientId).subscribe(
      data => {
        // console.table(data.content);

        let array = data.content;
        array.sort(function(a, b){
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })

        this.agencies = array;
      }
    );
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  updateShifts(i, property?){

      // Initializations
      var enddate;
      var startDate = $('#shiftStartDate'.concat(i.toString())).val();
      let prevStartDate = this.shifts[i].startdate;
      var endTime;
      var oldRequiredDates;
      let str = $('#worker'.concat(i.toString())).val();
      let workerId;
      let agencyId1;
      try{
        let arr = str.split(",");
        workerId = arr[1];
        agencyId1 = arr[0];
      }catch(e){
        // console.log(e);
      }

      var requiredDates = [];

      let selectedDay = $('#shiftStartDate'.concat(i.toString())).val();

      if(property == 'shiftStartDate'  ){
        if(startDate != this.shifts[i].startdate){
          enddate = startDate;
          this.toast.info('End date automatically adjusted');
        }else{
          enddate = $('#shiftEndDate'.concat(i.toString())).val();
        }
        if(workerId){
          oldRequiredDates = this.workers.find(i => i.id == workerId).requiredDates ?? [];
          oldRequiredDates = oldRequiredDates.filter(w => w !==  prevStartDate);
          this.workers.find(i => i.id == workerId).requiredDates = oldRequiredDates;

          let array: Array<string> = this.workers.find(i => i.id == workerId).requiredDates || [];
          array.push( startDate);
          this.workers.find(i => i.id == workerId).requiredDates = array;
        };
      }
      else{
        if( requiredDates.includes( selectedDay)){

        }else{
          if($('#shiftEndDate'.concat(i.toString())).val() < $('#shiftStartDate'.concat(i.toString())).val()){
            enddate = $('#shiftStartDate'.concat(i.toString())).val();
            this.toast.info('End date adjusted. End date cannot be less than start date');
          }
          else{
            enddate = $('#shiftEndDate'.concat(i.toString())).val();
          }
        }
      }

      if(property == 'time'){
        if($('#shiftEndDate'.concat(i.toString())).val() == $('#shiftStartDate'.concat(i.toString())).val()){
          if($('#shiftEndTime'.concat(i.toString())).val() < $('#shiftStartTime'.concat(i.toString())).val()){
            endTime = $('#shiftStartTime'.concat(i.toString())).val() ;
            this.toast.info('End time adjusted. End time cannot be less than start time on the same date.');
          }
          else{
            endTime = $('#shiftEndTime'.concat(i.toString())).val();
          }
        }else  endTime = $('#shiftEndTime'.concat(i.toString())).val();

      }else {endTime = $('#shiftEndTime'.concat(i.toString())).val();}

      this.shifts[i]      = {
        'startdate':$('#shiftStartDate'.concat(i.toString())).val(),
        'minend':startDate,
        'starttime':$('#shiftStartTime'.concat(i.toString())).val() ,
        'enddate':enddate ,
        'endtime':endTime ,
        'breaktimehr': $('#myHrs'.concat(i.toString())).val(),
        'breaktimemins': $('#myMins'.concat(i.toString())).val(),
        'numberofshifts': $('#numberOfShifts'.concat(i.toString())).val(),
        'worker': workerId ,
        'agency': agencyId1,
        'breaktime' : `${$('#myHrs'.concat((i).toString())).val()}hr ${$('#myMins'.concat(i.toString())).val()}mins`
      };








      //////// Conflict Detection

      //Initialise new shift properties
      this.shifts[i].workerShiftDateTime = [];
      this.shifts[i].selectedWorker = this.workers.find(i => i.id == workerId);

      // Extract the shift dates of a single worker, convert them to dates then to a string then push into an array inside the shift object


      if(this.shifts[i].selectedWorker !== undefined){
        if('shifts' in this.shifts[i].selectedWorker){
          this.shifts[i].selectedWorker.shifts.forEach(shift => {

            let shiftStartDate = new Date(this.formatDate(shift.shiftDate));
            var startTime = shift.shiftStartTime.split(":");
            shiftStartDate.setHours(parseInt(startTime[0]),parseInt(startTime[1]));

            let shiftEndDate = new Date(this.formatDate(shift.shiftEndDate))
            var endTime = shift.shiftEndTime.split(":");
            shiftEndDate.setHours(parseInt(endTime[0]),parseInt(endTime[1]));

            let endDate = shiftEndDate ;
            let startDate = shiftStartDate ;

            this.shifts[i].workerShiftDateTime.push({startDate,endDate});

            this.shifts[i].unavailableDates = (this.shifts[i].selectedWorker.unavailableDates);

          });
        }
      }
      console.log(this.shifts[i].workerShiftDateTime);

      //Check for conflicting shift dates
      //Get date time from form
      var d  = new Date(this.formatDate($('#shiftStartDate'.concat(i.toString())).val()));
      var sTime =this.formatDate($('#shiftStartTime'.concat(i.toString())).val()).split(":");
      d.setHours(sTime[0], sTime[1]);

      var d2  =    new Date(this.formatDate($('#shiftEndDate'.concat(i.toString())).val()));;
      var eTime =this.formatDate($('#shiftEndTime'.concat(i.toString())).val()).split(":");
      d2.setHours(eTime[0], eTime[1]);
      console.log("Conflicts", this.conflicts);

      // Check conflicting date time with booked date times
      this.conflicts = this.conflicts.filter(obj => obj !== i);

      console.log(this.shifts[i].workerShiftDateTime);
      this.shifts[i].workerShiftDateTime.forEach(async r => {
        console.log("Compared start time:", r.startDate.getTime(), r.endDate.getTime() , " vs ", d.getTime());
        console.log("Compared end time:", r.startDate.getTime(), r.endDate.getTime() , " vs ", d.getTime());
        // console.log("Looping through booked dates", r , " vs ", d);
        if(r.startDate.getTime()<=d.getTime()&&d.getTime()<=r.endDate.getTime()){
          console.log("conflict in start");
          this.conflicts.push(i)
          console.log("Conflicts", this.conflicts);
          this.toast.clear();
          // await this.delay(500);
          return this.toast.warning('Shift start time conflicts with an existing shift for the worker. Select a different worker');
        }else if(r.startDate.getTime()<=d2.getTime()&&d2.getTime()<=r.endDate.getTime()){
          this.conflicts.push(i);
          this.toast.clear();
          // await this.delay(500);
          return this.toast.warning('Shift end time conflicts with an existing shift for the worker. Select a different worker')
        }else if( this.shifts[i].unavailableDates?.includes($('#shiftStartDate'.concat(i.toString())).val())){
          this.conflicts.push(i)
          this.toast.clear();
          // await this.delay(500);
          return this.toast.warning(this.shifts[i].selectedWorker.firstname+' is not available on this day. Select a different worker')
        }

      });



      this.checkBookingConflicts();

  }

  checkBookingConflicts(){

    this.bookingConflicts = [];
    let shiftCheck = this.shifts;

    shiftCheck.forEach((shift,i) => {

      let shiftDate = shift.startdate;
      let shiftWorker = shift.worker;

      if(shiftWorker){
        let shiftCompare = shiftCheck.filter(s => s.worker == shiftWorker)

        let result = shiftCompare.filter(s => s.startdate == shiftDate)

        result = result.filter(s => s != shift)

        if (result){

          result.forEach(sft => {
            this.bookingConflicts.push(i);
          });

        }
      };
    });
    // console.log(this.bookingConflicts)

 }

  checkConflict(i){
    if(this.conflicts.includes(i)){
      return true;
    }else {
      return false;
    }
  }

  checkBookingConflict(i){
    if(this.bookingConflicts.includes(i)){
      return true;
    }else {
      return false;
    }
  }


  setActiveShift(i){
    this.activeShift = this.shifts[i];
    // console.log(this.activeShift);
  }

  formatDate(date){
    const myArray = date.split("/");
    if(myArray.length>=2){
      let formateddate =myArray[2] + "-" +myArray[1] +"-"+myArray[0] ;
      return formateddate;
    }else{
      return date;
    }

  }



  formatDate2(date: Date){
    let mm: string = (date.getUTCMonth() + 1).toString();
    let dd: string = (date.getUTCDate() +1).toString();
    if(mm.length==1){
      mm = '0' + mm;
    }else mm = mm
    if(dd.length==1){
      dd = '0' + dd;
    }

    return date.getUTCFullYear() + '-' + mm + '-' + dd
  }

  addShift(){
    if(this.shifts.length>=10){
      this.toast.error('Maximum number of shifts per request reached.');
    }else{
      this.shifts = [...this.shifts, this.row]
    }
  }

  removeShift(i){
    this.bookingConflicts = this.bookingConflicts.filter(i => i != i);


    if(this.shifts.length>1){
    this.shifts.splice(i,1);
    this.conflicts.splice(i,1)
    };

  }

  toggleTheDate(i){
    this.toggleDate = i;
  }


  getCodes(){
    this.assignmentService.getPaginatedAssignmentCodes(0, 100).subscribe(
      data => {
        // console.table(data.content);
        let array = data;
        array.sort(function(a, b){
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })

        this.assCodes = array;
      }
    );
  }

  getLocations(){
    this.shiftService.getPaginatedShiftLocations(this.page - 1, 1000).subscribe(
    // this.shiftService.getPaginatedClientShiftLocations(this.clientId, 0, 100).subscribe(
      data => {
        // console.table(data);

        this.locations = data.content;
      }
    );
  }



  getDirectorates(){
    this.shiftService.getPaginatedClientShiftDirectorates(this.clientId, 0, 100).subscribe(
      data => {
        // console.table(data.content);
        let array = data.content;
        array.sort(function(a, b){
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })

        this.directorates = array;
      }
    );
  }

  getTypes(){
    this.shiftService.getPaginatedShiftTypes(0, 100).subscribe(
      data => {
        // console.table(data.content);
        let array = data.content;
        array.sort(function(a, b){
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })

        this.types = array;
      }
    );
  }

  createShift(form: FormGroup, allWorkers, reqApp, directBook){
    form.patchValue({
      publishToAllWorkers : allWorkers,
      requireApplicationByWorkers : reqApp,
      directBooking: directBook,
      shiftLocationId : this.location,
    });
    var idtracker = -1;

    if(this.conflicts.length !=0){
      return this.toast.error('Please select different workers or dates for conflicting shifts.')
    }

    if(this.bookingConflicts.length !=0){
      return this.toast.error('Please select different workers or dates for conflicting shifts.')
    }

    for ( var shift of this.shifts ){
      if(directBook){
        if(shift.selectedWorker == undefined){
           return this.toast.error('Please select a worker for a all shifts.')
        }
      }
    }

    for ( var shift of this.shifts ){

      let setAgency;
      if(shift.agency=="" || !shift.agency){
        setAgency = "1";
      }else{
        setAgency = shift.agency;
      }

      form.patchValue({
        shiftDate: shift.startdate,
        shiftEndDate: shift.enddate,
        agency: setAgency,
        shiftEndTime: shift.endtime,
        shiftStartTime: shift.starttime,
        breakTime : shift.breaktime,
        workerId: shift.worker
      });

      console.table(form.value);
      var i = shift.numberofshifts;

      this.workerids.push(shift.worker);
      idtracker +=1;
      console.log(this.workerids);

      if(form.valid){
        for(var c=i; c>0; c-- ){
          this.shiftService.createShift(form.value).subscribe(
            resp => {
              // console.table(resp);

              this.toast.success('New Shift Created');

              // if(directBook){
              //   this.newBooking(resp.id, 0, this.workerids[idtracker], true);
              //   // idtracker-=1;

              // }
            }
          );
          $('#newContainer').show();
          $('#addContainer').hide();

          console.log(c)
        }
      }
      else{
        this.toast.warning('Missing Required Info.');
      }

    }
  }

  newBooking(shift, agent, worker, last){
    // console.log(shift, agent, worker, last);

    this.shiftService.newBookShift(shift, worker).subscribe(
      resp => {
        // console.table(resp);

        if(last){
          this.toast.success('Worker Booked Successfully.');
          // sessionStorage.clear();
          // this.shiftsPage();
        }
      }
    );

  }

  shiftsPage(){
    this.router.navigate(['/client/shifts/list']);
  }

  dismissModal(){
    this.modalService.dismissAll();
    this.shifts = 0
    this.workerids = [];

  }

  newShiftsPage(){
    this.modalService.dismissAll();
    if(this.userType=="client")this.router.navigate(['/client/shifts/list']);
    if(this.userType=="agency")this.router.navigate(['/agency/shifts/list']);
  }

  openModal(modal){
    // console.log(this.addForm);
    this.bookingConflicts = [];

    this.shifts = [
      {
       'startdate': this.date1 ,
       'starttime':'00:00' ,
       'enddate':this.date1 ,
       'endtime':'00:00' ,
       'breaktime': '0hr 0mins',
       'numberofshifts':'1',
       'minend':this.date1,
       'breaktimehr': '0',
       'breaktimemins': '0'
   }
   ];

    this.addForm.patchValue({
      shiftLocationId : $('#locationId').val(),
    });
    this.modalService.dismissAll();
    // this.getWorkers(modal);
    if(this.addForm.valid)
        this.getWorkers(modal);
    else
      this.toast.warning('Enter All Shift Details');
  };

  openDateModal(modal,i){
    // let workerId = $('#worker'.concat(i.toString())).val();

    let str = $('#worker'.concat(i.toString())).val();
    let arr = str.split(",");
    let workerId = arr[1];

    if(workerId > 0)
    {
      workerId = workerId;
    }
    else
    {
      workerId = 0;
    }

    this.currentBookings = [];
    this.workerService.getWorkerShifts(0, 100, workerId, "BOOKED").subscribe(
      data => {
        data.forEach(shift => {

              let bookDate = new Date(this.formatDate(shift.shiftDate));
              bookDate.setHours(0);
              let endDate = new Date(this.formatDate(shift.shiftEndDate));
              endDate.setHours(0);

              var dates = getDatesInRange(bookDate, endDate);

              dates?.forEach( e => {
                e.setHours(0);
                this.currentBookings.push(e.toString());
                // console.log(' dates array', e)
              });

              this.currentBookings.push(bookDate.toString());
              // console.log('this.currentBookings',this.currentBookings)
            });
        return (this.modalService.open(modal, {centered: true}))
      }
    );
    this.getWorkerAvailableDate(workerId);

  }

  validateLocation(ele){
    let ref = ele.target.value;

    if(ref){
      let dir;
      this.directorates.forEach(r => {
        if(r.id == ref){
          dir = r.location;
        }
      });

      console.log(dir);

      if(dir){
        this.locations.forEach(l => {
          if(l.name == dir){
            this.location = l.id;
            $('#locationId').val(dir);
          }
        });
      }

    }
  }

  getWorkerShifts(workerId, i){

    this.workerService.getWorkerShifts(0, 30, workerId, "BOOKED").subscribe(
      data => {
        // console.table(data);
        this.workers[i].shifts = data;
        this.workers[i].currentBookings=[];
        const datepipe: DatePipe = new DatePipe('en-US')


        data.forEach(shift => {

          let bookDate = new Date(this.formatDate(shift.shiftDate));
          bookDate.setHours(0);
          let endDate = new Date(this.formatDate(shift.shiftEndDate));
          endDate.setHours(0);

          var dates = getDatesInRange(bookDate, endDate);

          dates?.forEach( e => {
            e.setHours(0);
            this.workers[i].currentBookings.push(datepipe.transform(e, 'YYYY-MM-dd').toString());
            // console.log(' dates array', e)
          });

          this.workers[i].currentBookings.push(datepipe.transform(bookDate, 'YYYY-MM-dd').toString());
          // console.log('this.currentBookings',this.currentBookings)
        });

      }
    );
  }

  getWorkerAvailableDate(workerId){
    // console.log(workerId);
    this.workerService.getWorkerAvailableDate(workerId).subscribe(
      data => {
        const datepipe: DatePipe = new DatePipe('en-US')

        // console.log(data)
        let unavailableDates = [];
        let unavailableDatesLiteral = [];
        data.content.map(item => {
          unavailableDatesLiteral.push(item.date);
          var d = new Date(item.date);
          d.setHours(0);
          // console.log(d);
          unavailableDates.push(d.toString());

        })
        this.workers.find(i => i.id == workerId).unavailableDates = unavailableDatesLiteral;
        this.unavailableDates = unavailableDates ;
      }
    );
  };

  clientSelected(){
    this.clientId = this.addForm.value.clientId;
    this.getAgencies();
    this.getCodes();
    this.getLocations();
    this.getDirectorates();
    this.getTypes();
  }

  getWorkers(modal){
    this.loader = true;
    let info = {
      agencyId: this.addForm.value.agentIdList
    };

    this.workerService.getWorkersUnderAgency(this.addForm.value.assignmentCodeId, info, this.addForm.value.gender).subscribe(
      data => {
        // console.table(data);
        this.workers = data;
        this.loader = false;

        if(data.length == 0){
          this.toast.info("No Worker found who match selected Gender and Assignment.");
        }
        else{

          for (let i = 0; i < this.workers.length; i++) {
            const worker = this.workers[i];
            this.getWorkerShifts(worker.id, i);
            this.getWorkerAvailableDate(worker.id);

          }
        }

        this.modalService.open(modal, {centered: true});
      }
    );
  }

  toggleConfirm(){
    this.quest = false;
  }

  // setDates(){
  //   this.currentBookings = [];
  //   this.bookedShifts.forEach(shift => {
  //     let bookDate = new Date(this.formatDate(shift.shiftDate))
  //     bookDate.setHours(0);
  //     let strDate = bookDate.toString();
  //     this.currentBookings.push(strDate);
  //     console.log('this.currentBookings',this.currentBookings)
  //   });
  // };
  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {

      date = new Date(date);
      const booked = this.currentBookings
        .map(strDate => new Date(strDate))
        .some(d => {
          return d.getDate() === date.getDate() && d.getMonth() === date.getMonth() && d.getFullYear() === date.getFullYear()
        });
      const unavailable = this.unavailableDates
      .map(strDate => new Date(strDate))
      .some(d => {
        return d.getDate() === date.getDate() && d.getMonth() === date.getMonth() && d.getFullYear() === date.getFullYear()
      });

      return booked ? 'booked-class' : unavailable ? 'unavailable-class' : "available-class";
    };
  };





}


function getDatesInRange(startDate, endDate) {
  const date = new Date(startDate.getTime());

  const dates = [];

  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
}
