<main class="mt-5">

  <!-- Header part -->
  <div class="container-fluid">
    <div class="col-12 col-sm-12 col-md-12 text-left">
      <h3>Service Provider</h3>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-4">
        <select  class="form-control" (change)="getAgencies()" [(ngModel)]="agencyTypeFilter">
            <option  value="" disabled>Select Service</option>
            <option value="DEFAULT" >Agency</option>
            <option value="TRAINER" >Trainer</option>
            <option value="TRANSPORTER" >Transporter</option>
        </select>
      </div>

      <div class="col-12 col-sm-6 col-md-4 text-center">
        <div class="input-group">
          <input type="text" maxlength="255" class="form-control col-8 border-right-0" placeholder="Search Provider"
            aria-describedby="inputGroupPrepend2" required />
          <div class="input-group-prepend">
            <span class="input-group-text bg-transparent border-left-0" id="inputGroupPrepend2">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3 text-md-right text-sm-left mt-2 mt-md-0">
        <button class="btn btn-success" (click)="formInit(); openModal(add);" *ngIf="userType == 'admin'">
          <i class="fa fa-plus-circle"></i>&nbsp;&nbsp;Add Provider
        </button>
      </div>
    </div>
  </div>

  <!-- Content -->
  <section class="content mt-4">
    <div class="container-fluid">
      <table class="table table-striped mb-0 styled-table text-left">
        <thead>
          <tr class="text-left">
            <th>Provider Name</th>
            <th>Service Type</th>
            <th>Phone</th>
            <th>Email</th>
            <!-- <th>Status</th> -->
            <th *ngIf="userType=='admin'" >Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let a of agencies;">
            <td >{{a.name}}</td>
            <td>{{a.agencyType=='DEFAULT'?'AGENCY':a.agencyType}}</td>
            <td>{{a.telephone}}</td>
            <td>{{a.email}}</td>
            <!-- <td>
              <span *ngIf="a.status == 'ACTIVE'" title="Active">
                <i class="fa fa-square text-success"></i>
              </span>
              <span *ngIf="a.status == 'INACTIVE'">
                <i class="fa fa-square text-danger-custom" title="Inactive"></i>
              </span>
            </td> -->
            <td *ngIf="userType=='admin'" >
              <span class="mr-3 text-primary-custom pointer"  *ngIf="userType != 'admin'" (click)="loadAgency(a.agencyId, edit)">
                <i class="fa fa-eye" title="view"></i>
              </span>
              <span class="mr-3 text-success pointer" *ngIf="userType == 'admin'" title="Edit Provider Details"(click)="loadAgency(a.agencyId, edit)">
                <i class="fa fa-pencil-alt" title="edit"></i>
              </span>
              <!-- <span class="text-danger-custom pointer mr-3" (click)="deactivateAgent(a.agencyId)" *ngIf="userType == 'admin' && a.status == 'ACTIVE'">
                <i class="fa fa-lock"></i>
              </span>
              <span class="text-success pointer mr-3" (click)="activateAgent(a.agencyId)" *ngIf="userType == 'admin' && a.status == 'INACTIVE'">
                <i class="fa fa-lock-open"></i>
              </span> -->
              <!-- <span class="text-primary-custom pointer" title="Manage Users" (click)="openBigModal(users, a.agencyId)" *ngIf="userType == 'admin'">
                <i class="fa fa-users-cog"></i>
              </span> -->
            </td>
          </tr>
        </tbody>
      </table>
      <section class="pagination-container" *ngIf="agencies">
        <div class="container-fluid">
          <div class="row m-0">
            <div class="col-12 col-sm-12 col-md-5 text-left acontent-center">
              Showing {{ (page - 1) * pageSize + 1 }} - {{ showin }} of {{ totalItems }}
            </div>
            <div class="col-12 col-sm-6 col-md-4 text-right">
              <button class="btn text-white" (click)="handlePageChange('prev')" [disabled]="first">
                <i class="fa fa-caret-up"></i>&nbsp;&nbsp;Previous Page
              </button>
            </div>
            <div class="col-12 col-sm-6 col-md-3 text-right pr-0">
              <button class="btn text-white" (click)="handlePageChange('next')" [disabled]="last">
                Next Page&nbsp;&nbsp;<i class="fa fa-caret-down"></i>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>

</main>

<!-- Modals -->
<ng-template class="modal fade" #add let-modal>
  <div class="">
    <div class="modal-header bg-main text-white">
      <h5 class="modal-title text-center w-100">Add Service Provider</h5>
      <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
        (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </span>
    </div>
    <div class="modal-body">
      <form [formGroup]="addForm" (ngSubmit)="newAgency(addForm)">
        <div class="bg-modal card-body">
          <div class="container-fluid">
            <section>
                <div class="col-12">
                  <i class="bi bi-info-circle"></i>
                  <span class="ml-2">About Provider</span>
                  <hr class="bg-black mt-0">
                </div>
                <div class="row">
                  <div class="form-group col-12 col-sm-12 col-md-12">
                    <input type="text" maxlength="255" formControlName="name" placeholder="Provider Name" class="form-control">
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-12 col-sm-12 col-md-12">
                    <select formControlName="status" class="form-control" id="">
                        <option value="" selected disabled>Select Status</option>
                        <option value="ACTIVE" selected disabled>Active</option>
                        <option value="BLOCKED" selected disabled>Disabled</option>
                    </select>
                    </div>
                    <div class="form-group col-12 col-sm-12 col-md-12">
                        <select formControlName="serviceId" class="form-control">
                            <option value="" selected disabled>Select Service</option>
                            <option *ngFor="let s of services;" value="{{s.id}}">{{s.name}}</option>
                        </select>
                    </div>
                    <div class="form-group col-12 col-sm-12 col-md-12">
                      <select formControlName="agencyType" class="form-control" id="">
                          <option value="" selected disabled>Service Provider Type</option>
                          <option value="DEFAULT">Agency</option>
                          <option value="TRAINER">Trainer</option>
                          <option value="TRANSPORTER">Transport</option>
                      </select>
                   </div>
                </div>
                <div class="col-12 mt-4">
                  <i class="bi bi-info-circle"></i>
                  <span class="ml-2">Contacts and Addresses</span>
                  <hr class="bg-black mt-0">
                </div>
                <div formGroupName="address">
                  <div class="row">
                    <div class="form-group col-12 col-sm-12 col-md-12">
                      <input type="text" maxlength="255" formControlName="firstLine" placeholder="First Line Address" class="form-control">
                    </div>
                  </div>
                  <div class="row">
                      <div class="form-group col-12 col-sm-12 col-md-12">
                        <input type="text" maxlength="255" formControlName="town" placeholder="Town" class="form-control">
                      </div>
                  </div>
                  <div class="row">
                      <div class="form-group col-12 col-sm-12 col-md-12">
                        <input type="text" maxlength="255" formControlName="postcode" placeholder="Post Code" class="form-control">
                      </div>
                  </div>
                </div>
                <div class="row">
                    <div class="form-group col-12 col-sm-12 col-md-12">
                      <input type="text" maxlength="255" formControlName="telephone" placeholder="Telephone" class="form-control">
                    </div>
                </div>
                <!-- <div class="row">
                  <div class="form-group col-12 col-sm-12 col-md-12">
                    <input type="email" formControlName="email" placeholder="Email" (keyup)="checkEmail($event)" class="form-control">
                  </div>
                </div> -->
                <div class="col-12 mt-4">
                    <i class="bi bi-info-circle"></i>
                    <span class="ml-2">Admin</span>
                    <hr class="bg-black mt-0">
                </div>
                <div formGroupName="administratorCreateDto" class="row">
                    <div class="form-group col-12 col-sm-12 col-md-12">
                      <input type="email" formControlName="adminEmail" (keyup)="checkEmail($event)" placeholder="Admin Email" class="form-control">
                    </div>
                    <div class="form-group col-12 col-sm-12 col-md-12">
                      <input type="text" maxlength="255" formControlName="firstname" placeholder="Admin First Name" class="form-control">
                    </div>
                    <div class="form-group col-12 col-sm-12 col-md-12">
                      <input type="text" maxlength="255" formControlName="lastname" placeholder="Admin Last Name" class="form-control">
                    </div>
                </div>
                <div class="col-12 mt-4">
                    <i class="bi bi-info-circle"></i>
                    <span class="ml-2">Billing</span>
                    <hr class="bg-black mt-0">
                </div>
                <div class="row">
                    <div class="form-group col-12 col-sm-12 col-md-12">
                      <input type="email" formControlName="billingEmail" (keyup)="checkEmail($event)" placeholder="Billing Email" class="form-control">
                    </div>
                </div>
            </section>
          </div>
        </div>
        <div align="right" class="mt-3">
          <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
            Cancel
          </button>
          <button type="submit" class="btn btn-sm btn-success">
            Add Provider
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template class="modal fade" #edit let-modal>
    <div class="">
      <div class="modal-header bg-main text-white">
        <h5 class="modal-title text-center w-100">View Provider Information</h5>
        <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
          (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </span>
      </div>
      <div class="modal-body">
        <form [formGroup]="editForm" (ngSubmit)="updateAgency(editForm)">
          <div class="bg-modal card-body">
            <div class="container-fluid">
                <section>
                    <div class="col-12">
                      <i class="bi bi-info-circle"></i>
                      <span class="ml-2">About Provider</span>
                      <hr class="bg-black mt-0">
                    </div>
                    <div class="row">
                      <div class="form-group col-12 col-sm-12 col-md-12">
                        <input type="text" maxlength="255" formControlName="name" placeholder="Provider Name" class="form-control">
                      </div>
                    </div>
                    <div class="row">
                      <!-- <div class="form-group col-12 col-sm-12 col-md-12">
                        <select formControlName="status" class="form-control" id="">
                            <option value="" selected disabled>Select Status</option>
                        </select>
                        </div> -->
                        <div class="form-group col-12 col-sm-12 col-md-12">
                            <select formControlName="serviceId" class="form-control" id="">
                                <option value="" selected disabled>Select Service</option>
                                <option *ngFor="let s of services;" value="{{s.id}}">{{s.name}}</option>
                            </select>
                        </div>


                    </div>
                    <div class="col-12 mt-4">
                      <i class="bi bi-info-circle"></i>
                      <span class="ml-2">Contacts and Addresses</span>
                      <hr class="bg-black mt-0">
                    </div>
                    <div formGroupName="address">
                      <div class="row">
                        <div class="form-group col-12 col-sm-12 col-md-12">
                          <input type="text" maxlength="255" formControlName="firstLine" placeholder="First Line Address" class="form-control">
                        </div>
                          <!-- <div class="form-group col-12 col-sm-12 col-md-12">
                            <input type="text" maxlength="255" formControlName="secondLine" placeholder="Second Line Address" class="form-control">
                          </div> -->
                      </div>
                      <div class="row">
                          <div class="form-group col-12 col-sm-12 col-md-12">
                            <input type="text" maxlength="255" formControlName="town" placeholder="Town" class="form-control">
                          </div>
                      </div>
                      <div class="row">
                          <div class="form-group col-12 col-sm-12 col-md-12">
                            <input type="text" maxlength="255" formControlName="postcode" placeholder="Post Code" class="form-control">
                          </div>
                      </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-12 col-sm-12 col-md-12">
                          <input type="text" maxlength="255" formControlName="telephone" placeholder="Telephone" class="form-control">
                        </div>
                    </div>
                    <div class="col-12 mt-4">
                        <i class="bi bi-info-circle"></i>
                        <span class="ml-2">Billing</span>
                        <hr class="bg-black mt-0">
                    </div>
                    <div class="row">
                        <div class="form-group col-12 col-sm-12 col-md-12">
                          <input type="email" formControlName="billingEmail" (keyup)="checkEmail($event)" placeholder="Billing Email" class="form-control">
                        </div>
                    </div>
                </section>
            </div>
          </div>
          <div align="right" class="mt-3" *ngIf="userType == 'admin'">
            <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
              Cancel
            </button>
            <button type="submit" class="btn btn-sm btn-success">
              Update Provider
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>

  <ng-template class="modal fade" #users let-modal>
    <div class="">
      <div class="modal-header bg-main text-white">
        <h5 class="modal-title text-center w-100">View Provider USERS</h5>
        <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
          (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </span>
      </div>
      <div class="modal-body">
        <table class="table table-striped mb-0 styled-table table-responsive-md text-center">
          <thead>
              <tr class="text-center">
                  <th>Name</th>
                  <th>Username</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Status</th>
                  <th>Actions</th>
              </tr>
          </thead>
          <tbody *ngIf="userList">
              <tr *ngFor="let u of userList;">
                  <td>{{u.lastname}} {{u.firstname}}</td>
                  <td>{{u.username}}</td>
                  <td>{{u.email}}</td>
                  <td>{{getName(u.roles[0].name)}}</td>
                  <td>
                      <span *ngIf="u.enabled == 'Active'">
                          <i class="fa fa-square text-success"></i>
                      </span>
                      <span *ngIf="u.enabled == 'Inactive'">
                        <i class="fa fa-square text-danger-custom"></i>
                    </span>
                  </td>
                  <td>
                      <span class="mr-3 text-danger-custom pointer" title="De-Activate User" (click)="activateUser(u.id, u.roles[0].name, false)" *ngIf="u.enabled == 'Active'">
                        <i class="fa fa-lock"></i>
                      </span>
                      <span class="mr-3 text-success pointer" title="Activate User" (click)="activateUser(u.id, u.roles[0].name, true)" *ngIf="u.enabled == 'Inactive'">
                        <i class="fa fa-lock-open"></i>
                      </span>
                  </td>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-template>
