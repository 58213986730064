<main class="mt-5">

    <!-- Header part -->
    <div class="container-fluid">
      <section>
        <div class="co-sm-12 col-md-6 col-lg-4 mt-lg-5 mt-md-0 w-100 text-center pl-0">
          <div class=" text-left">
              <h3>Quick links</h3>
          </div>
      </div>
        <div class="row text-white">
            <div *ngIf="'VIEW_WORKER'| permission" class="co-sm-12 col-md-6  col-lg-4 mt-3 mt-md-0 w-100 text-center">
                <a routerLink="/agency/workers">
                  <div class ="border rounded-1 bg-link-card text-center text-white">
                    <h4 class="font-weight-bold">View Flexi Workers</h4>
                    <p >View a list of flexi workers.</p>
                  </div>
                </a>
            </div>
            <div *ngIf="'VIEW_SHIFT'| permission" class="co-sm-12 col-md-6   col-lg-4 mt-3 mt-md-0 w-100 text-center">
              <a routerLink="/agency/shifts/list">
                <div class=" border rounded-1 bg-link-card text-center text-white">
                    <h4 class="font-weight-bold">View Shifts</h4>
                    <p >View the list of available shifts.</p>
                </div>
              </a>
            </div>
            <div *ngIf="'DEPUTY_USER'| permission" class="co-sm-12 col-md-6   col-lg-4 mt-3 mt-md-0 w-100 text-center">
              <a href="https://deputy.com" target="_blank">
                <div class=" border rounded-1 bg-link-card text-center text-white">
                    <h4 class="font-weight-bold">Deputy</h4>
                    <p>Connect to your deputy account</p>
                </div>
              </a>
            </div>
        </div>
      </section>


    </div>

    <!-- Content -->
    <section>
      <app-worker-compliance></app-worker-compliance>
    </section>

</main>
