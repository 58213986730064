import { BillingModule } from './../admin/billing/billing.module';
import { WorkersListComponent } from './../admin/workers/workers-list/workers-list.component';
import { WorkerDetailsComponent } from 'src/app/admin/workers/worker-details/worker-details.component';
import { AgencyHomeComponent } from './agency-home/agency-home.component';
import { NotFoundComponent } from './../shared/pages/not-found/not-found.component';
import { AuthGuard } from './../shared/utilities/auth.guard';
import { AgencyContainerComponent } from './agency-container/agency-container.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClientsListComponent } from '../admin/clients/clients-list/clients-list.component';
import { ApplicationComponent } from '../admin/workers/worker-details/components/application/application.component';
import { OccupationalComponent } from '../admin/workers/worker-details/components/occupational/occupational.component';
import { BankComponent } from '../admin/workers/worker-details/components/bank/bank.component';
import { HmrcComponent } from '../admin/workers/worker-details/components/hmrc/hmrc.component';
import { ApplicantListComponent } from '../admin/workers/applicant-List/applicant-list.component';
import { CertificateComponent } from '../worker/certificate/certificate.component';
import { WorkerInfoComponent } from '../admin/workers/worker-details/components/workerinfo/worker-info.component';

const routes: Routes = [
  {
    path: 'agency',
    component: AgencyContainerComponent,
    canActivate: [AuthGuard],
    children: [

      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: AgencyHomeComponent },
      { path: 'clients', component: ClientsListComponent },
      { path: 'workers', component: WorkersListComponent },
      { path: 'applicants', component: ApplicantListComponent },
      { path: 'workers/info/:id', component: WorkerDetailsComponent },
      { path: 'workers-print/info/:id', component: WorkerInfoComponent },



      { path: 'application/:id', component: ApplicationComponent },
      { path: 'occupational/:id', component: OccupationalComponent },
      { path: 'hmrc/:id', component: HmrcComponent },
      { path: 'bank/:id', component: BankComponent },

      { path: 'shifts', loadChildren: () => import('./agency-shifts/agency-shifts.module').then(m => m.AgencyShiftsModule) },
      { path: 'jobs', loadChildren: () => import('./agency-jobs/jobs.module').then(m => m.AgencyJobsModule) },
      { path: 'trainings', loadChildren: () => import('./agency-trainings/agency-trainings.module').then(m => m.AgencyTrainingsModule) },
      { path: 'staff', loadChildren: () => import('./agency-staff/agency-staff.module').then(m => m.AgencyStaffModule) },
      { path: 'billing', loadChildren: () => import('./agency-billing/agency-billing.module').then(m => m.AgencyBillingModule) },
      { path: 'rates', loadChildren: () => import('./agency-rates-codes/agency-rates-codes.module').then(m => m.AgencyRatesCodesModule) },
      { path: 'payslips', loadChildren: () => import('./agency-payroll/agency-payroll.module').then(m => m.AgencyPayrollModule) },
      { path: 'communications', loadChildren: () => import('../admin/communications/communications.module').then(m => m.CommunicationsModule) },
      { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },

      { path: 'reporting', loadChildren: () => import('./agency-reporting/agency-reporting.module').then(m => m.AgencyReportingModule) },
      { path: 'certificate/:id', component: CertificateComponent },
      // not found
      { path: '**', component: NotFoundComponent, pathMatch: 'full' },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AgencyRoutingModule { }
