import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  //base_url: string = environment.API_url + ':8300/api/v1';
  base_url: string = environment.API_url + '/worklink-api/api/v1';

  constructor(
    private http: HttpClient
  ) { }

  getPaginatedClients(page, size): Observable<any> {
    return this.http.get(`${this.base_url}/clients/${page}/${size}`);
  }

  searchAgencyClients(agencyId, searchCriteria, page, size): Observable<any> {
    return this.http.get(`${this.base_url}/client/agency/search/${agencyId}/${searchCriteria}/${page}/${size}`);
  }

  createClient(data): Observable<any> {
    return this.http.post(`${this.base_url}/client`, data);
  }

  updateClient(data): Observable<any> {
    return this.http.put(`${this.base_url}/clients`, data);
  }

  searchClient(query, page, size): Observable<any> {
    return this.http.post(`${this.base_url}/client-search/${page}/${size}?searchCriteria=${query}`,{});
  }

  getClientById(ref): Observable<any> {
    return this.http.get(`${this.base_url}/client/${ref}`);
  }

  deleteClient(ref): Observable<any> {
    return this.http.delete(`${this.base_url}/client/${ref}`);
  }

  getPaginatedClientAgencies(page, size, client): Observable<any> {
    return this.http.get(`${this.base_url}/client-agencies/${client}/${page}/${size}`);
  }

  getPaginatedClientWorkers(page, size, client): Observable<any> {
    return this.http.get(`${this.base_url}/client-workers/${client}/${page}/${size}`);
  }

  // link client to agent
  linkClientToAgent(client, agent): Observable<any> {
    // return this.http.put(`${this.base_url}/agency-client/${client}/${agent}`, null);
    return this.http.put(`${this.base_url}/client-link/${client}/${agent}`, null);
  }

}
