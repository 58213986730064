
import { ClientService } from '../../../../shared/services/client.service';
import { AuthService } from '../../../../shared/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { WorkerService } from '../../../../shared/services/worker.service';
import { FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';


import { Component, OnInit, ElementRef, ViewChild, Inject, ViewEncapsulation} from '@angular/core';
import { Router } from '@angular/router';
import { AssignmentCodeService } from 'src/app/shared/services/assignment-code.service';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { UsersService } from 'src/app/shared/services/users.service';
import { StorageService } from 'src/app/shared/services/storage.service';

import {MatChipInputEvent} from '@angular/material/chips';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatCalendarCellClassFunction, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { DialogData } from '../applicant-list.component';
import { environment } from 'src/environments/environment';
import { NgSelectModule } from '@ng-select/ng-select';




declare var $: any;

@Component({
  selector: 'dialog-overview-example-dialog',
  styleUrls: ['../applicant-list.component.css'],
  templateUrl: 'applicant-list-dialog.component.html',
})
export class ApplicantListDialog {
  animal: string;
  name: string;


  visible = true;
  selectable = true;
  removable = true;
  link = environment.baseUrl+'/guest/'+localStorage.getItem('agentId')+'/agency#guest-land';


  separatorKeysCodes: number[] = [ENTER, COMMA];
  applicantCtrl = new FormControl('');
  filteredFruits: Observable<string[]>;
  applicants: string[] = ['New Worker'];
  allFruits: string[] = [''] ;

  @ViewChild('applicantInput') applicantInput: ElementRef<HTMLInputElement>;


  workers: any;
  allWorkers: any;
  assCodes: any;
  selectedWorker: any;
  workerId: number;

  addForm: FormGroup;
  editForm: FormGroup;

  page = 1;
  pageSize = 20;
  totalItems = 0;
  showin = 0;
  first: boolean;
  last: boolean;

  userType: string;
  new: boolean = true;
  existing: boolean;
  formArea: boolean;
  query = new FormControl('');
  email: any;
  // inviteEmail: string = null;
  emails = [];
  bookedDates: any;
  currentBookings: any;
  agencyId: any;

  constructor(
    private router: Router,
    private workerService: WorkerService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private assignmentCodeService: AssignmentCodeService,
    private authService: AuthService,
    private agencyService: AgencyService,
    private usersService: UsersService,
    private clientService: ClientService,
    private storageService: StorageService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ApplicantListDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this.userType = authService.getUserType();
    this.filteredFruits = this.applicantCtrl.valueChanges.pipe(
      startWith(null),
      map((applicant: string | null) => (applicant ? this._filter(applicant) : this.allFruits.slice())),
    );
   }



  ngOnInit(): void {


    this.getWorkers();
    this.getAssCodes();

    this.editForm = this.fb.group({
      id: 0
    });

    this.getAllWorkers();
    this.getAllWorkers();


    this.addForm = this.fb.group({
      agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
      assignmentCode: ['', Validators.required],
      email: ['', Validators.required],
      firstname: ['', Validators.required],
      nationality: ['', Validators.required],
      dob: ['', Validators.required],
      gender: ['', Validators.required],
      lastname: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      username: ['', Validators.required],
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog(){
    this.dialogRef.close();
  }

  addval(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    console.log(value);
    // this.loadWorkerInfo(value);

    // Add our applicant
    if (value) {
      this.applicants= [value];
      this.loadWorkerInfo(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.applicantCtrl.setValue(null);
  }

  remove(applicant: string): void {
    const index = this.applicants.indexOf(applicant);

    if (index >= 0) {
      this.applicants = ['NEW'];

    }
    this.loadWorkerInfo('value');
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.applicants= [event.option.viewValue];
    this.loadWorkerInfo(event.option.viewValue);
    this.applicantInput.nativeElement.value = '';
    this.applicantCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allFruits.filter(applicant => applicant?.toLowerCase().includes(filterValue));
  }


  detailsPage(ref){
    if(ref){
      this.router.navigate([`/${this.userType}/workers/info/${ref}`]);
    }
  }

  handlePageChange(event) {

    if(event == 'next'){
      this.page += 1;
    }
    else if(event == 'prev' && this.page != 1){
      this.page -= 1;
    }

    this.getWorkers();
  }

  getWorkers(){
    if(this.userType == 'admin'){
      this.workerService.getPaginatedWorkers(this.page - 1, this.pageSize).subscribe(
        data => {
          // console.table(data.content);
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }
          let array = data.content;
          array.sort(function(a, b){
              if(a.firstname < b.firstname) { return -1; }
              if(a.firstname > b.firstname) { return 1; }
              return 0;
          })

          this.workers = array;
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
          else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else if(this.userType == 'agency'){
      this.agencyService.getPaginatedAgencyWorkers(this.page - 1, this.pageSize, this.storageService.decrypt(localStorage.getItem('agentId'))).subscribe(
        data => {
          // console.table(data.content);
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }
          let array = data.content;
          array.sort(function(a, b){
              if(a.firstname < b.firstname) { return -1; }
              if(a.firstname > b.firstname) { return 1; }
              return 0;
          })

          this.workers =array;
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
          else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.clientService.getPaginatedClientWorkers(this.page - 1, this.pageSize, this.storageService.decrypt(localStorage.getItem('clientId'))).subscribe(
        data => {
          // console.table(data.content);
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }
          let array = data.content;
          array.sort(function(a, b){
              if(a.firstname < b.firstname) { return -1; }
              if(a.firstname > b.firstname) { return 1; }
              return 0;
          })
          this.workers = array;
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
          else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
  }

  getAllWorkers(){
    this.workerService.getPaginatedWorkers(0, 100).subscribe(
      data => {
        // console.table(data.content);

        this.allWorkers= data.content;
        this.allFruits = [];
        for (let index = 0; index < this.allWorkers.length; index++) {
          this.allFruits.push(this.allWorkers[index].email);

        }
        console.log(this.allFruits)
        // console.log(this.allWorkers[0].email)
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        }else if(err.status == 500){
          this.toast.error('Internal Server Error')
        }
        else{
          this.toast.error('Request Failed: ' + err.status);
        }
      }
    );
  }

  getAssCodes(){
    this.assignmentCodeService.getPaginatedAssignmentCodes(0, 100).subscribe(
      data => {
        // console.table(data.content);
        this.assCodes = data;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        }else if(err.status == 500){
          this.toast.error('Internal Server Error')
        }
        else{
          this.toast.error('Request Failed: ' + err.status);
        }
      }
    );
  }

  openModal(modal) {
    this.modalService.dismissAll();
    this.modalService.open(modal, {centered: true, size: 'lg'});
  }



  newWorker(form: FormGroup){
    this.email = form.value.email;

    form.patchValue({
      username: this.email
    });

    console.table(form.value);

    if(form.valid){
      this.workerService.createWorker(form.value).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          this.ngOnInit();
          this.dialogRef.close();
          this.getWorkers();
          this.toast.success('Worker Added Successfully');

        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
          else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }


  }

  loadWorkerInfo(ele){
    let ref = this.applicants;

    // console.log(ref);
    if(ref){
      if(ref[0] != 'NEW'){
        let r;

        this.allWorkers.forEach(w => {
          if(w.email == ref){
            r = w;
            this.workerId = w.id;
          }
        });

        this.addForm = this.fb.group({
          agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
          assignmentCode: [r.assignmentCodeId, Validators.required],
          email: [r.email, Validators.required],
          nationality: [r.nationality, Validators.required],
          firstname: [r.firstname, Validators.required],
          dob: [r.dob, Validators.required],
          gender: [r.gender, Validators.required],
          lastname: [r.lastname, Validators.required],
          phoneNumber: [r.phoneNumber, Validators.required],
          username: [r.username, Validators.required]
        });

        this.new = false;
        this.existing = true;
        this.formArea = true;
      }
      else{
        this.addForm = this.fb.group({
          agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
          assignmentCode: ['', Validators.required],
          email: ['', Validators.required],
          nationality: ['', Validators.required],
          firstname: ['', Validators.required],
          dob: ['', Validators.required],
          gender: ['', Validators.required],
          lastname: ['', Validators.required],
          phoneNumber: ['', Validators.required],
          username: ['', Validators.required]
        });

        this.new = true;
        this.existing = false;
        this.formArea = false;
      }
    }

  }

  updateWorker(form: FormGroup){
    // console.table(form.value);

    if(form.valid){
      this.workerService.updateWorker(form.value).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          this.getWorkers();
          this.toast.success('Worker Updated Successfully');
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
          else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }
  }

  loadWorker(ref, modal, edit){
    if(ref){
      this.workers.forEach(r => {
        if(r.id == ref){
          this.selectedWorker = r;
        }
      });

      if(edit && this.selectedWorker){
        this.editForm = this.fb.group({
          agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
          assignmentCodeId: [this.getAssCodeId(this.selectedWorker.assignmentName), Validators.required],
          email: [this.selectedWorker.email, Validators.required],
          firstname: [this.selectedWorker.firstname, Validators.required],
          gender: [this.selectedWorker.gender, Validators.required],
          lastname: [this.selectedWorker.lastname, Validators.required],
          phoneNumber: [this.selectedWorker.phoneNumber, Validators.required],
          username: [this.selectedWorker.username, Validators.required],
          status: [this.selectedWorker.status],
          id: [this.selectedWorker.id, Validators.required]
        });
      }
    }

    if(modal){
      this.openModal(modal);
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  checkEmail(ele){
    // console.log(ele);

    var typed = ele.target.value;

    if(this.validateEmail(typed)){
      $(ele.target).removeClass('is-invalid');
      $(ele.target).addClass('is-valid');
    }
    else{
      $(ele.target).removeClass('is-valid');
      $(ele.target).addClass('is-invalid');
    }
  }

  getAssCodeId(ref){
    let lid;

    if(ref){
      this.assCodes.forEach(r => {
        if(r.name == ref){
          lid = r.id;
        }
      });
    }

    return lid;
  }

  activateUser(ref, enable){

    this.usersService.changeWorkerStatus(ref, enable).subscribe(
      resp => {
        // console.log(resp);
        this.toast.success('User Status Changed.')
        this.getWorkers();
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        }else if(err.status == 500){
          this.toast.error('Internal Server Error')
        }
        else{
          this.toast.error('Request Failed: ' + err.status);
        }
      }
    );

  }

  linkWorker(){
    console.log(this.workerId);
    this.workerService.linkWorkerToAgent(this.workerId, this.storageService.decrypt(localStorage.getItem('agentId'))).subscribe(
      resp => {
        // console.table(resp);
        this.modalService.dismissAll();
        this.getWorkers();
        this.toast.success('Worker Linked Successfully');
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
        else if(err.error?.message){
          this.toast.error(err.error.message);
        }else if(err.status == 500){
          this.toast.error('Internal Server Error')
        }
        else{
          this.toast.error('Request Failed: ' + err.status);
        }
      }
    );

  };


  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toast.info("Copied the text: " + val);
  }




  sendEmail(){
    if(this.emails == null){
      this.toast.warning("Enter email");
      return null;
    }

    let data = {
      emails: this.emails,
      agencyName: localStorage.getItem('display_name'),
      link: this.link
    };
    this.agencyService.inviteWorker(data).subscribe(
      data => {
        this.toast.success("Invite sent");
        this.modalService.dismissAll();
      }
    );
  }
}
