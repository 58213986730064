<div class="modal-header bg-main text-white">
    <h5 class="modal-title text-center mx-4 w-100">Delete Training</h5>
  </div>
  <div class="modal-body p-0">
    <p>Are you sure you want to delete this training?</p>
    <div align="right" class="mt-3">
      <button type="button" class="btn btn-secondary btn-sm mr-3" (click)="dismissModal()">Cancel</button>
      <button type="button" class="btn btn-sm btn-danger" (click)="deleteTraining(trainings)">Delete</button>
    </div>
  </div>
  

  <!-- <div class="">
    <div class="modal-header bg-main text-white">
        <h5 class="modal-title text-center w-100">Delete Transport</h5>
    </div>
    <div class="modal-body">
        <div class="bg-modal card-body">
            <div class="text-center">
                <h3>You are about to delete this transport job?</h3>
                Job Ref: {{selectedJob.id}}
            </div>
        </div>
        <div class="mt-3">
          <button class="btn btn-sm btn-danger mr-3" (click)="deleteInvoice()">
            Delete
          </button>
          <button class="btn btn-success btn-sm mr-3" (click)="closeModal()">
              Dismiss
          </button>
        </div>
    </div>
  </div>
 -->  