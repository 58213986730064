import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'src/app/shared/services/storage.service';
import { WorkerService } from 'src/app/shared/services/worker.service';
import { ServicesService } from 'src/app/shared/services/services.service';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { HttpResponse } from '@angular/common/http';
import { FileService } from 'src/app/shared/services/file.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-compliance',
  templateUrl: './compliance.component.html',
  styleUrls: ['./compliance.component.css']
})

export class ComplianceComponent implements OnInit {

  public userType: string;
  workerId: number;
  agencyId: number;
  worker: any;
  workerInfo: any;
  // workerProperty: any;
  jobCounts: any;
  compliances:any;
  complianceAll:any;
  jobs: any;
  fitToWork:any;
  // complianceInformation:any;
  notes:any;
  editInfoForm: FormGroup;
  addComplianceForm: FormGroup;
  updateComplianceForm: FormGroup;
  docLink = environment.API_url+"/worklink-api/tina/worklink/agency/"+localStorage.getItem('agentId')+"/compliance-2.png";
  // complianceInformationForm: FormGroup;
  addNoteForm: FormGroup;
  addJobForm: FormGroup;
  edit = false;
  edit2 = false;
  editCompliance = false;
  assignmentCodes = [{id:1,name:'001 Cleaner'},{id:2,name:'002 Driver'},{id:1,name:'003 Nurse'}];
  paymentMethods = [{id:1,name:'Paye'},{id:2,name:'Umbrella'},{id:1,name:'Pvt. Ltd'}];
  services = [];
  activeCompliance: any;
  complianceDoc: any;
  activecompliance: any;

  //complianceOptions = [{id:1,name:'Compliance 1'},{id:2,name:'Compliance 2'},{id:1,name:'Compliance 3'}];

  constructor(
    private activateRoute: ActivatedRoute,
    private workerService: WorkerService,
    private servicesService: ServicesService,
    private agencyService: AgencyService,
    private authService: AuthService,
    private fileService: FileService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private storageService: StorageService
  ){
    this.userType = authService.getUserType();
    if(localStorage.getItem('agentId') != null){

    }

    this.addComplianceForm = this.fb.group({
      agencyId: [1],
      workerId: [this.workerId,],
      name: ['',],
      code: ['',],
      // description: ['',],
      serviceId: ['', ],
      complianceId: ['', ],
      complianceDate: ['', Validators.required],
      complianceExpiry: ['', Validators.required],
    });



    this.updateComplianceForm = this.fb.group({
      id: [Validators.required ],
      comment: [''],
      status: [Validators.required],
    });

    this.updateComplianceForm = this.fb.group({
      id: [Validators.required ],
      comment: [''],
      status: [Validators.required],
    });

    this.addNoteForm = this.fb.group({
    });
    this.addJobForm = this.fb.group({
      assignmentCodeId: ['', Validators.required],
      paymentMethodId: ['', Validators.required]
    });



   }

  ngOnInit(): void {
    this.activateRoute.params.subscribe((params) => {
      this.agencyId = params["id"]
    });
    this.workerId = this.storageService.decrypt(localStorage.getItem('workerId'));
    this.getWorker();
    this.getAllServices();
    this.getWorkerCompliances();
    this.getWorkerCompliances();
    this.getAllCompliances();
    this.getAllCompliances();

    this.docLink = environment.API_url+"/worklink-api/tina/worklink/worker/"+this.workerId+"/";





  }


  toggleEdit(){
    this.edit = !this.edit;
    console.log(this.editInfoForm.value);
  }
  toggleEdit2(){
    this.edit2 = !this.edit2;
  }
  toggleEditCompliance(){
    this.editCompliance = !this.editCompliance
  }
  toggleAddCompliance (){

    this.editCompliance = !this.edit2;
  }

  getWorker(){

    this.workerService.getWorkerById(this.workerId).subscribe(
      data => {
        this.worker = data;
        // console.log(this.worker)
        // this.agencyId = data.agencyId;
      }
    );
  }

  openDoc(url){
    this.workerService.getContentTpye(url).subscribe(res => {
      if(res.headers.get('Content-Type')!='application/pdf') {
        return window.open(url);
      }else{
        this.viewDoc(url);
      }
    });
  }

  viewDoc(url){
    this.workerService.downloadPDF(url).subscribe(res => {
      console.log(res);
      if(res==null)
        window.open(url, '_blank');
      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, '_blank');
    });
  }

  getAllCompliances(){
    this.workerService.getAllCompliances().subscribe(
      data => {
        // console.log('datadatadatadatadata' ,data)
        this.complianceAll = data;
      }
    );
  }

  getAllServices(){
    this.servicesService.getAllServices().subscribe(
      data => {
        this.services = data;
        // console.log("----------------------",this.services)
      }
    );
  }
getWorkerJobCount(){
    this.workerService.getWorkerJobCount(this.workerId).subscribe(
      data => {
        this.jobCounts =
          [
            {asignmentCode : "678", jobs : "9", hrs : "88"},
            {asignmentCode : "105", jobs : "3", hrs : "60"},
            {asignmentCode : "307", jobs : "7", hrs : "95"},
            {asignmentCode : "624", jobs : "2", hrs : "66"},
          ]
        ;// delete this devip
        //this.jobCounts = data; // uncomment this devip
      }
    );
  }
  getWorkerJobs(){
    this.workerService.getWorkerJobs(this.workerId).subscribe(
      data => {
        this.jobs =
          [
            {id : "1", job : "Nurse"},
            {id : "2", job : "Cleaner"},
            {id : "3", job : "Driver"}
          ]
        ;// delete this devip
        //this.jobs = data; // uncomment this devip
      }
    );
  }

  getWorkerCompliances(){
    this.workerService.getWorkerCompliances(this.workerId).subscribe(
      data => {
        this.compliances = data.content; // uncomment this devip
      }
    );
  }




  toggleFitToWork(value){
    if(value == 'yes')
    {
      this.fitToWork = true;
    }
    else{
      this.fitToWork = false;
    }

  }

  submitFitToWork(){
    this.workerService.updateFitToWork(this.workerId,this.fitToWork).subscribe(
      resp => {
        this.getWorkerJobs();
        this.toast.success('Worker Fit To Work Updated Successfully');
      }
    );
  }


  addCompliance(form: FormGroup){
    // console.table(form.value);
    if(form.valid){
      form.value.workerId =this.workerId ;
      form.value.agencyId = 1 ;
      form.value.serviceId =1 ;
      // console.log("----------", form.value);

      this.workerService.addCompliance(form.value).subscribe(
        resp => {

          this.closeModals();
          this.getWorkerCompliances();

          console.log(resp);
          this.uploadCompliance(resp.id);

          this.toast.success('Worker Compliance Added Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }


  addWorkerCompliance(form: FormGroup){
    // console.table(form.value);
    form.value.serviceId = 1;
    if(form.valid){
      form.value.workerId =this.workerId ;
      form.value.agencyId =1 ;
      // console.log("----------", form.value);


      this.workerService.addCompliance(form.value).subscribe(
        resp => {
          this.closeModals();
          this.getWorkerCompliances();
          this.toast.success('Worker Compliance Added Successfully');

          console.log(resp);
          this.uploadCompliance(resp.id);
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }





  getWorkerNotes(){
    this.workerService.getWorkerNotes(this.workerId).subscribe(
      data => {
        this.notes = [
          {id : "1", note : "Worker note 1"},
          {id : "2", note : "Worker note 2"},
          {id : "3", note : "Worker note 3"},
          {id : "4", note : "Worker note 4"},
        ]; // delete this devip
        //this.notes = data; // uncomment this devip
      }
    );
  }

  addNote(form: FormGroup){
    // console.table(form.value);
    if(form.valid){
      this.workerService.addNote(form.value).subscribe(
        resp => {
          this.closeModals();
          this.getWorkerNotes();
          this.toast.success('Worker Note Added Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  selectedcompliance(compliance){
    // console.log(compliance);
    this.activecompliance = compliance;
  };


  addJob(form: FormGroup){
    // console.table(form.value);
    if(form.valid){
      this.workerService.addJob(form.value).subscribe(
        resp => {
          this.closeModals();
          this.getWorkerJobs();
          this.toast.success('Worker Job Added Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  removeJob(id){
    this.workerService.removeJob(id).subscribe(
      resp => {
        this.getWorkerJobs();
        this.toast.success('Worker Removed Successfully');
      }
    );
}

  openModal(modal){
    this.modalService.open(modal, {centered: true});
  };

  selectedCompliance(compliance){

    this.addComplianceForm = this.fb.group({
      agencyId: [1],
      workerId: [this.workerId,],
      name: [compliance.name],
      id: [compliance.id],
      code: [compliance.code],
      serviceId: [1 ],
      complianceId: [compliance.complianceId],
      complianceDate: [compliance.complianceDate],
      complianceExpiry: [compliance.complianceExpiry],
    });
    this.activeCompliance = compliance;
    this.activecompliance = compliance;
  };

  closeModals(){
    this.modalService.dismissAll();
  };

   onChange(event) {
    this.complianceDoc = event.target.files[0];
  }


  uploadCompliance(compliance){
    console.log(this.complianceDoc);
    if(this.complianceDoc.size>=1048576){
      this.toast.warning("Maximum upload file size is 1mb")
    }
    else {
    this.fileService.workerUploadCompliance(this.complianceDoc,compliance ,this.storageService.decrypt(localStorage.getItem('workerId'))).subscribe(
      event => {
        if(event instanceof HttpResponse) {

        };
        this.toast.info("Sucessfully uploaded.");
        this.modalService.dismissAll();
      },
    );}
  }



  checkExpiry(date){
    var d = new Date(date);
    var dnow =  new Date();
    var d30 =  new Date();
    d30.setDate(dnow.getDate()+30);

    if(d30> d && d > dnow){
      return "alert";
    }
    if(d30> d){
      return "expired";
    }
    return null;
  }



  updateCompliance(form: FormGroup){
    form.value.id =this.activeCompliance.id ;
    if(form.valid){
      if(this.complianceDoc==null){
        this.toast.info('Please select a certificate to upload.');
      }
      this.uploadCompliance(form.value.id);
    }
    else{
      console.log(form.value);
      this.toast.warning('Missing Required Info.');
    }
  }

  deleteCompliance(form: FormGroup){
    form.value.id =this.activecompliance.id ;
    console.log(form.value);
    if(true){

      this.workerService.deleteCompliance(form.value.id).subscribe(
        resp => {
          this.closeModals();
          this.getWorkerCompliances();
          this.toast.success('Worker compliance deleted successfully');
        }
      );
    }
    else{
      console.log(form.value);
      this.toast.warning('Missing Required Info.');
    }
  }
}
