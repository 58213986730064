<form  [formGroup]="form" (ngSubmit)="updateVehicleCheck()">
  <h5>Vehicle Check & Defect Report</h5>

  <div class="card p-2 bg-grey">
    Driver Vehicle Check - items to be checked by driver before and during driving <br>
    Function | Damage | Cleanliness | Serviceable | Defect
  </div>

  <div class="row mt-3">

      <div class = 'row'>
        <div class="col-md-6">
          <b>
            <div class="btn-group col-12 row" >
              <div class="col-8">
                <h5>Description</h5>
              </div>
              <div class="col-4 text-center">
               Condition
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </b>
        </div>


        <div class="col-md-6">
          <b>
            <div class="btn-group col-12 row" >
              <div class="col-8">
                <h5>Description</h5>
              </div>
              <div class="col-4 text-center">
               Condition
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </b>
        </div>


          <div class="col-md-6">
            <div class="btn-group col-12 row" >

              <div class="col-8"></div>
              <div class="col-2 text-center">
               Good
              </div>
              <div class="col-2 text-center">
                Bad
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>
          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8"></div>
              <div class="col-2 text-center">
               Good
              </div>
              <div class="col-2 text-center">
                Bad
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>



          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Lamp
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="lamp"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="lamp"  class="form-control mx-auto" style="width: 20px;">
              </div>
            </div>

            <div class="col-12">
              <input type="text" (change)="updateDescriptions('lamp', $event)" value="{{damageDescriptions.get('lamp')}}" *ngIf="!form.value.lamp&&isBool(form.value.lamp)"  class="form-control mx-auto" placeholder="Description">
            </div>

            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row">
              <div class="col-8">
                Indicators
              </div>
              <div class="col-2 text-center">
                <input type="radio" [value]="true" formControlName="indicators" class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio" [value]="false" formControlName="indicators" class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('indicators', $event)" value="{{damageDescriptions.get('indicators')}}" *ngIf="!form.value.indicators&&isBool(form.value.indicators)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>

            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>


          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Side Reapter
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="sideReapter"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="sideReapter"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('sideReapter', $event)" value="{{damageDescriptions.get('sideReapter')}}" *ngIf="!form.value.sideReapter&&isBool(form.value.sideReapter)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>


            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
               Stop lamp
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="stoplamp"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="stoplamp"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('stoplamp', $event)" value="{{damageDescriptions.get('stoplamp')}}" *ngIf="!form.value.stoplamp&&isBool(form.value.stoplamp)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>

            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>
          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Reflectors
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="reflectors"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="reflectors"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('reflectors', $event)" value="{{damageDescriptions.get('reflectors')}}" *ngIf="!form.value.reflectors&&isBool(form.value.reflectors)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Markers
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="markers"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="markers"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('markers', $event)" value="{{damageDescriptions.get('markers')}}" *ngIf="!form.value.markers&&isBool(form.value.markers)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Warning Devices
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="warningdevices"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="warningdevices"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('warningdevices', $event)" value="{{damageDescriptions.get('warningdevices')}}" *ngIf="!form.value.warningdevices&&isBool(form.value.warningdevices)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Mirror
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="mirror"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="mirror"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('mirror', $event)" value="{{damageDescriptions.get('mirror')}}" *ngIf="!form.value.mirror&&isBool(form.value.mirror)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Brakes
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="brakes"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="brakes"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('brakes', $event)" value="{{damageDescriptions.get('brakes')}}" *ngIf="!form.value.brakes&&isBool(form.value.brakes)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Driving Control
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="drivingcontrol"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="drivingcontrol"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('drivingcontrol', $event)" value="{{damageDescriptions.get('drivingcontrol')}}" *ngIf="!form.value.drivingcontrol&&isBool(form.value.drivingcontrol)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Tyres Inflation
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="tyreInflation"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="tyreInflation"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12"></div>
                <input type="text" (change)="updateDescriptions('drivingcontrol', $event)" value="{{damageDescriptions.get('drivingcontrol')}}" *ngIf="!form.value.drivingcontrol&&isBool(form.value.drivingcontrol)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Wheel Condition
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="wheelcondition"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName= "wheelcondition"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('wheelcondition', $event)" value="{{damageDescriptions.get('wheelcondition')}}" *ngIf="!form.value.wheelcondition&&isBool(form.value.wheelcondition)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Body
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="body"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="body"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('body', $event)" value="{{damageDescriptions.get('body')}}" *ngIf="!form.value.body&&isBool(form.value.body)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>
          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Horn
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="horn"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="horn"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('horn', $event)" value="{{damageDescriptions.get('horn')}}" *ngIf="!form.value.horn&&isBool(form.value.horn)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Wipers
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="wipers"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="wipers"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('wipers', $event)" value="{{damageDescriptions.get('wipers')}}" *ngIf="!form.value.wipers&&isBool(form.value.wipers)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Washers
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="washers"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="washers"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('washers', $event)" value="{{damageDescriptions.get('washers')}}" *ngIf="!form.value.washers&&isBool(form.value.washers)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Fuel
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="fuel"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="fuel"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('fuel', $event)" value="{{damageDescriptions.get('fuel')}}" *ngIf="!form.value.fuel&&isBool(form.value.fuel)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Oil
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="oil"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="oil"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('oil', $event)" value="{{damageDescriptions.get('oil')}}" *ngIf="!form.value.oil&&isBool(form.value.oil)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Fluid Leaks
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="fluidleaks"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="fluidleaks"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('fluidleaks', $event)" value="{{damageDescriptions.get('fluidleaks')}}" *ngIf="!form.value.fluidleaks&&isBool(form.value.fluidleaks)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Exhaust
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="exhaust"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="exhaust"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('exhaust', $event)" value="{{damageDescriptions.get('exhaust')}}" *ngIf="!form.value.exhaust&&isBool(form.value.exhaust)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Windscreen
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="windscreen"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="windscreen"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('windscreen', $event)" value="{{damageDescriptions.get('windscreen')}}" *ngIf="!form.value.windscreen&&isBool(form.value.windscreen)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Speedometer
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="speedometer"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="speedometer"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('speedometer', $event)" value="{{damageDescriptions.get('speedometer')}}" *ngIf="!form.value.speedometer&&isBool(form.value.speedometer)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Battery
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="battery"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="battery"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('battery', $event)" value="{{damageDescriptions.get('battery')}}" *ngIf="!form.value.battery&&isBool(form.value.battery)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Coolant
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="coolant"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="coolant"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('coolant', $event)" value="{{damageDescriptions.get('coolant')}}" *ngIf="!form.value.coolant&&isBool(form.value.coolant)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Seat Belt
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="seatbelt"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="seatbelt"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('seatbelt', $event)" value="{{damageDescriptions.get('seatbelt')}}" *ngIf="!form.value.seatbelt&&isBool(form.value.seatbelt)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Doors
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="doors"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="doors"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('doors', $event)" value="{{damageDescriptions.get('doors')}}" *ngIf="!form.value.doors&&isBool(form.value.doors)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Instrumental Panel
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="InstrumentalPanel"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="InstrumentalPanel"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('InstrumentalPanel', $event)" value="{{damageDescriptions.get('InstrumentalPanel')}}" *ngIf="!form.value.InstrumentalPanel&&isBool(form.value.InstrumentalPanel)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                AdBlue
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="adblue"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="adblue"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('adblue', $event)" value="{{damageDescriptions.get('adblue')}}" *ngIf="!form.value.adblue&&isBool(form.value.adblue)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

          <div class="col-md-6">
            <div class="btn-group col-12 row" >
              <div class="col-8">
                Trailer Coulping
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="true"  formControlName="trailercoupling"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-2 text-center">
                <input type="radio"  [value]="false"  formControlName="trailercoupling"  class="form-control mx-auto" style="width: 20px;">
              </div>
              <div class="col-12">
                <input type="text" (change)="updateDescriptions('trailercoupling', $event)" value="{{damageDescriptions.get('trailercoupling')}}" *ngIf="!form.value.trailercoupling&&isBool(form.value.trailercoupling)"  class="form-control mx-auto" placeholder="Description">
              </div>
            </div>
            <div class="col-12">
              <hr class="w-100">
            </div>
          </div>

      </div>

      <div class="col-12 mt-4 btn-group">
        <h5 style="align-self: center;">Damage Report & Notes</h5>
        </div>
    <div class="col-12">
        <textarea name="form-control w-100" formControlName="damageReport" style="width:100%" placeholder="Enter description"></textarea>
    </div>
    <div class="col-12">
        <input type="file" id="imageUpload" (change)="onChange($event)" name="imageUpload[]" multiple>
        <div *ngIf="form.value.damageReport!=null">Damage Report Uploaded</div>
    </div>
    <div class=" col-12 mt-2 text-right" >
      <button type="button" class="btn btn-danger btn-sm mr-3"  (click)="prevTab()" >BACK</button>
      <button type="submit" class="btn-primary text-right" (click)="updateVehicleCheck();">NEXT</button>
    </div>


</form>
