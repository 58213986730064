<h2 mat-dialog-title>CHANGE PASSWORD</h2>
  <div class="">
      <div class="">
          <!-- <form >
            <div class="bg-modal text-center">
              <div class="container-fluid">
                <div class="mb-1 ">
                  <label for="" class="form-label"></label>
                  <input type="text" maxlength="255"
                    class="form-control" name="" id="old" aria-describedby="helpId" placeholder="">
                  <small id="helpId" class="form-text text-muted">Current Password</small>
                </div>
                <div class="mb-1 ">
                  <label for="" class="form-label"></label>
                  <input type="text" maxlength="255"
                    class="form-control" name="" id="new" aria-describedby="helpId" placeholder="">
                  <small id="helpId" class="form-text text-muted">New Password</small>
                </div>
                <div class="mb-1">
                  <label for="" class="form-label"></label>
                  <input type="text" maxlength="255"
                    class="form-control" name="" id="repeat" aria-describedby="helpId" placeholder="">
                  <small id="helpId" class="form-text text-muted">Repeat Password</small>
                </div>
                <hr>
              </div>
            </div>
            <div align="right" class="mt-3">

            </div>
          </form> -->
          Are you sure you want to change your current password? This action will log you out of your account.
      </div>
  </div>


<div mat-dialog-actions >
  <div class="">
    <button type="submit" (click)="changePass()" class="btn btn-sm btn-success my-4 ">
      PROCEED
  </button>
  </div>
</div>
