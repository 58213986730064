<table class="table table-striped mb-0 styled-table text-center">
  <thead class="">
      <tr class="text-center">
          <th>Job Ref</th>
          <!-- <th>Date Booked</th> -->
          <th>Date Required</th>
          <th>Provider</th>
          <th>Actions</th>
      </tr>
  </thead>
  <tbody>
      <tr *ngFor="let s of shifts">
          <td>{{s.id}}</td>
          <!-- <td>{{s.dateTimeBooked}}</td> -->
          <td>{{s.dateTimeRequired | date:'dd/MM/yyyy'}}</td>
          <td>{{s.agencyName}}</td>
          <td>
              <span class="mr-3 text-primary-custom pointer"   (click)="openViewModal(view,s)" >
                  <i class="fa fa-eye" title="view"></i>
              </span>
              <!-- <span class="mr-3 text-success pointer" *ngIf="userType == 'client'" (click)="editDetails(s.id)">
                <i class="fa fa-pencil-alt" title="edit"></i>
              </span> -->
              <!-- <span class="mr-3 text-danger-custom pointer" *ngIf="userType == 'client'" (click)="loadAgency(s.id, delNew)">
                <i class="fa fa-trash-alt" title="delete"></i>
            </span> -->
          </td>
      </tr>
  </tbody>
</table>
<section class="pagination-container" *ngIf="shifts">
  <div class="container-fluid">
    <div class="row m-0">
      <div class="col-12 col-sm-12 col-md-5 text-left acontent-center">
        Showing {{ (page - 1) * pageSize + 1 }} - {{ showin }} of {{ totalItems }}
      </div>
      <div class="col-12 col-sm-6 col-md-4 text-right">
        <button class="btn text-white" (click)="handlePageChange('prev')" [disabled]="first">
          <i class="fa fa-caret-up"></i>&nbsp;&nbsp;Previous Page
        </button>
      </div>
      <div class="col-12 col-sm-6 col-md-3 text-right pr-0">
        <button class="btn text-white" (click)="handlePageChange('next')" [disabled]="last">
          Next Page&nbsp;&nbsp;<i class="fa fa-caret-down"></i>
        </button>
      </div>
    </div>
  </div>
</section>

<!-- Modals -->

<ng-template class="modal fade" #view let-modal>
  <div class="card">
    <transport-info-modal [selectedJob]="selectedTransport"></transport-info-modal>
  </div>
  </ng-template>
