import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TransportService } from 'src/app/shared/services/transport.service';

@Component({
  selector: 'app-view-vehicle-tab4',
  templateUrl: './view-vehicle-tab4.component.html'
})
export class VehicleTab4Component implements OnChanges {
  @Input('job') job;
  @Input('tabindex') tabindex:string;
  @Output() tabindexO = new EventEmitter<string>();

  page = 1;
  pageSize = 20;
  totalItems = 0;
  showin = 0;
  first: boolean;
  last: boolean;

  userType: string;
  clients: any;
  searchCriteria: string = null;
  logs: any;
  selectedLog: any;


  constructor(
    private toast: ToastrService,
    private transporService: TransportService,
    private storageService: StorageService,
    private agencyService: AgencyService,
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal
  ) { }


  ngOnChanges(): void {
    if(this.job!=null) this.getVehicleLogs();
  }

  newPage(){
    return this.router.navigate([`/worker/transport`]);
  }

  nextTab(){
    console.log(this.tabindex);
    this.tabindex = "photos";
    // ++this.tabindex;
    // if(this.tabindex>4)this.tabindex =4;
     this.tabindexO.emit(this.tabindex);
  }

  prevTab(){
    console.log(this.tabindex);
    this.tabindex = "inventory";
    // --this.tabindex;
    this.tabindexO.emit(this.tabindex);
  }

  handlePageChange(event) {

    if(event == 'next'){
      this.page += 1;
    }
    else if(event == 'prev' && this.page != 1){
      this.page -= 1;
    }

  }



  getVehicleLogs() {
    console.log("Hello maam");
    this.transporService.getVehicleLogs(this.job.id, this.page-1, this.pageSize).subscribe(
      data => {
        this.totalItems = data.totalElements;
        this.first = data.first;
        this.last = data.last;

        if(this.page * this.pageSize > data.totalElements){
          this.showin = data.totalElements;
        }
        else{
          this.showin = this.page * this.pageSize;
        }

        let array = data.content;
        array.sort(function(a, b){
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })

        this.logs = array;
      }
    );
  }

  viewDriverInfo(log: any, modalContent: TemplateRef<any>): void {
    this.selectedLog = log;
    this.modalService.open(modalContent, { ariaLabelledBy: 'modal-basic-title', backdrop: true, centered: true });
  }

}
