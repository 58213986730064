import { Component, OnInit } from '@angular/core';
import { InventoryService } from 'src/app/shared/services/inventory.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
@Component({
  selector: 'app-add-new-inventory',
  templateUrl: './add-new-inventory.component.html',
  styleUrls: ['./add-new-inventory.component.css']
})
export class AddNewInventoryComponent {
  
  isModalOpen = false;
  items = ['PPE', 'Fire Extinguisher', 'J67 Spanner Kit', 'Jumpers', 'Warning Triangle'];
  selectedItem = '';
  selectedDate: string | null = null;
  selectedFile: File = null;
  
  constructor(private inventoryService: InventoryService,private http: HttpClient) {}

  ngOnInit() {
    this.inventoryService.currentInventory.subscribe(inventory => {
      if (inventory) {
        this.selectedItem = inventory.item;
        this.selectedDate = inventory.date;
      }
    });
  }

  onFileSelected(event){
      this.selectedFile=<File> event.target.files[0];
  
  }
  onUpload(){
    const fd = new FormData();
    fd.append('image', this.selectedFile, this.selectedFile.name);
    this.http.post('https://angularbyprocademy-dbe68-default-rtdb.firebaseio.com/products.json',fd)
    .subscribe(res => {
      console.log(res);
    });
  }

  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
    this.resetForm();
  }

  resetForm() {
    this.selectedItem = '';
    this.selectedDate = null;
  }


  onSubmit() {
    if (this.selectedItem && this.selectedDate) {
      console.log('Item:', this.selectedItem);
      console.log('Date:', this.selectedDate);
      this.closeModal();
    }
  }



}