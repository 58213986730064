import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TransportService } from 'src/app/shared/services/transport.service';

@Component({
  selector: 'app-log-transport-tab3',
  templateUrl: './log-transport-job1.component.html'
})
export class LogTransportJob3Component implements OnInit {
  @Input('form') form:FormGroup;
  @Input('job') job:FormGroup;
  @Input('tabindex') tabindex:number;
  @Output() formO = new EventEmitter<FormGroup>();
  @Output() tabindexO = new EventEmitter<number>();


  constructor(
    private toast: ToastrService,
    private transporService: TransportService,
    private storageService: StorageService,
    private agencyService: AgencyService,
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal
  ) { }


  ngOnInit(): void {
    console.log(this.form.value);
  }

  nextTab(){
    ++this.tabindex;
    if(this.tabindex>4)this.tabindex =4;
    this.tabindexO.emit(this.tabindex);
    this.formO.emit(this.form);
  }

  prevTab(){
    --this.tabindex;
    this.tabindexO.emit(this.tabindex);
    this.formO.emit(this.form);
  }

  updateCleaningCheck() {
    this.transporService.updateCleaningCheck(this.form.value).subscribe(
      data => { this.toast.success("Info updated")}
    );
   }

}
