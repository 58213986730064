<!-- Admin Side Bar -->
<ul class="hideMePrint navbar-nav sidebar sidebar-dark accordion pt-4 h-100" id="accordionSidebar" *ngIf="userType == 'admin'">

    <!-- Sidebar - Brand -->
    <!-- <a class="sidebar-brand d-flex align-items-center justify-content-center mb-4" href="#">
        <div class="sidebar-brand-icon">
            <img src="/assets/images/svg/logo.svg" class="w-100" alt="logo">
        </div>
    </a> -->

        <!-- Nav Item - Dashboard -->
    <li class="nav-item">
        <a class="nav-link" routerLink="/admin/home" routerLinkActive="active">
            <img class="nav-icon" src="/assets/images/svg/dashboard.svg" alt="dashboard image">
            <span class="text-white">Dashboard</span>
        </a>
    </li>

    <!-- Nav Item - Shifts -->
    <li class="nav-item" *ngIf="('VIEW_SHIFT'| permission)||('VIEW_SHIFT_LOCATION'| permission)||('VIEW_SHIFT_DIRECTORATE'| permission)">
        <a class="nav-link  {{uiShifts ? '' : 'collapsed'}}" [ngClass]="{'active' : shift1.isActive || shift2.isActive || shift3.isActive || shift4.isActive || shift5.isActive}" href="#" data-toggle="collapse" data-target="#collapseTwo"
            aria-expanded="true" aria-controls="collapseTwo">
            <img class="nav-icon" src="/assets/images/svg/shifts.svg" alt="dashboard">
            <span class="text-white">Shifts</span>
        </a>
        <div id="collapseTwo" class="collapse" [ngClass]="{'show' : uiShifts}"  aria-labelledby="headingTwo" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white" *ngIf="'VIEW_SHIFT'| permission"  routerLink="/admin/shifts/applied" routerLinkActive="active" #shift5="routerLinkActive" hidden>Applied Shift</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_SHIFT'| permission"  routerLink="/admin/shifts/list" routerLinkActive="active" #shift1="routerLinkActive">Shifts</a>
                <a class="collapse-item text-white"  *ngIf="'VIEW_SHIFT_LOCATION'| permission"  routerLink="/admin/shifts/locations" routerLinkActive="active" #shift2="routerLinkActive">Shift Locations</a>
                <a class="collapse-item text-white"  *ngIf="'VIEW_SHIFT_DIRECTORATE'| permission" routerLink="/admin/shifts/directorates" routerLinkActive="active" #shift3="routerLinkActive">Shift Directorates</a>
                <a class="collapse-item text-white"  routerLink="/admin/shifts/types" routerLinkActive="active" #shift4="routerLinkActive">Shift Types</a>
            </div>

            <input type="hidden" #shift1>
            <input type="hidden" #shift2>
            <input type="hidden" #shift3>
            <input type="hidden" #shift4>
            <input type="hidden" #shift5>

        </div>
    </li>

    <!-- Nav Item - Training -->
    <!-- <li class="nav-item" *ngIf="'VIEW_TRAINING_SESSION'| permission"> -->
    <li class="nav-item" >
      <a class="nav-link" routerLink="/admin/trainings/list" routerLinkActive="active">
          <img class="nav-icon" src="/assets/images/svg/trainings.svg" style="color: white;" alt="settings">
          <span class="text-white">Trainings</span>
      </a>
    </li>


    <!-- Nav Item - Rates & codes -->
    <li class="nav-item">
        <a class="nav-link {{uiRates ? '' : 'collapsed'}}" href="#" [ngClass]="{'active' : rate1.isActive || rate2.isActive}" data-toggle="collapse" data-target="#collapseThree"
            aria-expanded="true" aria-controls="collapseThree">
            <img class="nav-icon" src="/assets/images/svg/rates_codes.svg" alt="rates">
            <span class="text-white">Rates & Codes</span>
        </a>
        <div id="collapseThree" class="collapse" [ngClass]="{'show' : uiRates}" aria-labelledby="heading3" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white" *ngIf="'VIEW_ASSIGNMENT_CODE'| permission" routerLink="/admin/rates/codes" routerLinkActive="active" #rate1="routerLinkActive">Assignment Codes</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_ASSIGNMENT_CODE_RATE'| permission" routerLink="/admin/rates/charge-rates" routerLinkActive="active" #rate2="routerLinkActive">Charge Rates</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_EXPENSE_RATE'| permission" routerLink="/admin/rates/expense-rates" routerLinkActive="active" #rate3="routerLinkActive">Expense Rates</a>

            </div>
        </div>
        <input type="hidden" #rate1>
        <input type="hidden" #rate2>
        <input type="hidden" #rate3>
    </li>

    <!-- Nav Item - Agencies -->
    <li class="nav-item">
        <a class="nav-link"
        *ngIf="'VIEW_AGENCY'| permission" routerLink="/admin/agencies/list" routerLinkActive="active"
         >
            <img class="nav-icon" src="/assets/images/svg/agency.svg" alt="agency">
            <span class="text-white">Service Providers</span>
        </a>
        <!-- <div id="collapseFour" class="collapse" [ngClass]="{'show' : uiAgency}" aria-labelledby="heading4" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white" *ngIf="'VIEW_AGENCY'| permission" routerLink="/admin/agencies/list" routerLinkActive="active" #agency1="routerLinkActive">Service Providers</a>
            </div>
        </div> -->
        <input type="hidden" #agency1>

    </li>

    <!-- Nav Item - Clients -->
    <li class="nav-item">
        <a class="nav-link {{uiClients ? '' : 'collapsed'}}" [ngClass]="{'active' : client1.isActive || client2.isActive || client3.isActive}" href="#" data-toggle="collapse" data-target="#collapseFive"
            aria-expanded="true" aria-controls="collapseFive">
            <img class="nav-icon" src="/assets/images/svg/clients.svg" alt="clients">
            <span class="text-white">Clients</span>
        </a>
        <div id="collapseFive" class="collapse" [ngClass]="{'show' : uiClients}" aria-labelledby="heading5" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white" *ngIf="'VIEW_CLIENT'| permission" routerLink="/admin/clients/list" routerLinkActive="active" #client1="routerLinkActive">List Clients</a>
                <!-- <a class="collapse-item text-white" *ngIf="'VIEW_CLIENT'| permission" routerLink="/admin/clients/users" routerLinkActive="active" #client2="routerLinkActive">Client Users</a> -->
                <a class="collapse-item text-white" *ngIf="'VIEW_CLIENT'| permission" routerLink="/admin/clients/services" routerLinkActive="active" #client3="routerLinkActive">Client Services</a>
            </div>
        </div>
        <input type="hidden" #client1>
        <input type="hidden" #client2>
        <input type="hidden" #client3>


    </li>

    <!-- Nav Item - Flexi-Workers -->
    <li class="nav-item" *ngIf="'VIEW_WORKER'| permission">
        <a class="nav-link {{uiWorkers ? '' : 'collapsed'}}" [ngClass]="{'active' : workers1.isActive || workers2.isActive}" href="#" data-toggle="collapse" data-target="#collapseSix"
            aria-expanded="true" aria-controls="collapseSix">
            <img class="nav-icon" src="/assets/images/svg/workers.svg" alt="workers">
            <span class="text-white">Flexi-Workers</span>
        </a>
        <div id="collapseSix" class="collapse" [ngClass]="{'show': uiWorkers}" aria-labelledby="heading6" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white" *ngIf="'VIEW_WORKER'| permission" routerLink="/admin/workers/list" routerLinkActive="active" #workers1="routerLinkActive">List Flexi-Workers</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_WORKER'| permission" routerLink="/admin/workers/training-and-qualifications" routerLinkActive="active" #workers1="routerLinkActive">Trainings & Qualifications</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_WORKER'| permission" routerLink="/admin/workers/info/" routerLinkActive="active" #workers2="routerLinkActive" hidden>Flexi-Worker Info</a>
            </div>
        </div>
        <input type="hidden" #workers1>
        <input type="hidden" #workers2>
        <input type="hidden" #workers3>
    </li>

    <!-- Nav Item - Billing -->
    <li class="nav-item">
        <a class="nav-link {{uiBilling ? '' : 'collapsed'}}" [ngClass]="{'active' : bill1.isActive || bill2.isActive || bill3.isActive || bill4.isActive}" href="#" data-toggle="collapse" data-target="#collapseSeven"
            aria-expanded="true" aria-controls="collapseSeven">
            <img class="nav-icon" src="/assets/images/svg/billing.svg" alt="billing">
            <span class="text-white">Billing</span>
        </a>
        <div id="collapseSeven" class="collapse" [ngClass]="{'show' : uiBilling}" aria-labelledby="heading7" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">

                <a class="collapse-item text-white" *ngIf="'VIEW_WORKLINK_BILLING' | permission" routerLink="/admin/billing/agency" routerLinkActive="active" #bill1="routerLinkActive">MyWorklink Billing</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_WORKLINK_BILLING' | permission" routerLink="/admin/billing/invoicing" routerLinkActive="active" #bill2="routerLinkActive" hidden>Invoicing</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_WORKLINK_BILLING' | permission" routerLink="/admin/billing/debit-notes" routerLinkActive="active" #bill3="routerLinkActive" hidden>Debit Notes</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_WORKLINK_BILLING' | permission" routerLink="/admin/billing/vat-rate" routerLinkActive="active" #bill4="routerLinkActive">VAT Rate</a>
            </div>
            <input type="hidden" #bill1>
            <input type="hidden" #bill2>
            <input type="hidden" #bill3>
            <input type="hidden" #bill4>
        </div>
    </li>

    <!-- Nav Item - Staff -->
    <li class="nav-item" *ngIf="'VIEW_SYSTEM_USERS'| permission" >
        <a class="nav-link {{uiStaff ? '' : 'collapsed'}}" [ngClass]="{'active' : staff1.isActive || staff2.isActive || staff3.isActive}" href="#" data-toggle="collapse" data-target="#collapseEight"
            aria-expanded="true" aria-controls="collapseEight">
            <img class="nav-icon" src="/assets/images/svg/staff.svg" alt="staff">
            <span class="text-white">Staff Management</span>
        </a>
        <div id="collapseEight" class="collapse" [ngClass]="{'show': uiStaff}" aria-labelledby="heading8" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white" *ngIf="'VIEW_SYSTEM_USERS' | permission" routerLink="/admin/staff/system-users" routerLinkActive="active" #staff1="routerLinkActive"></a>
                <a class="collapse-item text-white" *ngIf="'VIEW_SYSTEM_USERS' | permission" routerLink="/admin/staff/system-user/info/" routerLinkActive="active" #staff2="routerLinkActive" hidden>User Info</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_SYSTEM_USERS' | permission" routerLink="/admin/staff/role-permissions" routerLinkActive="active" #staff3="routerLinkActive">Roles & Permissions</a>
            </div>
        </div>
        <input type="hidden" #staff1>
        <input type="hidden" #staff2>
        <input type="hidden" #staff3>
    </li>

    <!-- Nav Item - Reports -->
    <li class="nav-item">
        <a class="nav-link {{uiReport ? '' : 'collapsed'}}" href="#" [ngClass]="{'active' : report1.isActive || report2.isActive || report3.isActive}" data-toggle="collapse" data-target="#collapseNine"
            aria-expanded="true" aria-controls="collapseNine">
            <img class="nav-icon" src="/assets/images/svg/reports.svg" alt="reports">
            <span class="text-white">Reports</span>
        </a>
        <div id="collapseNine" class="collapse" [ngClass]="{'show': uiReport}" aria-labelledby="heading9" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white" *ngIf="'VIEW_AGENCY_REPORT' permission" routerLink="/admin/reporting/agency" routerLinkActive="active" #report1="routerLinkActive">Agency Reports</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_AGENCY_REPORT' permission" routerLink="/admin/reporting/client" routerLinkActive="active" #report2="routerLinkActive">Client Reports</a>
                <!-- <a class="collapse-item text-white" *ngIf="'VIEW_AGENCY_REPORT' permission" routerLink="/admin/reporting/flexi-worker" routerLinkActive="active" #report3="routerLinkActive">Flexi-Worker Reports</a> -->
            </div>
        </div>
        <input type="hidden" #report1>
        <input type="hidden" #report2>
        <input type="hidden" #report3>
    </li>

    <!-- Nav Item - Comms -->
    <li class="nav-item" *ngIf="'VIEW_BROADCAST'| permission">
        <a class="nav-link {{uiComms ? '' : 'collapsed'}}" [ngClass]="{'active' : comms1.isActive}" href="#" data-toggle="collapse" data-target="#collapseTen"
            aria-expanded="true" aria-controls="collapseTen">
            <img class="nav-icon" src="/assets/images/svg/communication.svg" alt="comms">
            <span class="text-white">Communications</span>
        </a>
        <div id="collapseTen" [ngClass]="{'show' : uiComms}" class="collapse" aria-labelledby="heading10" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white" *ngIf="'VIEW_BROADCAST'| permission" routerLink="/admin/communications/broadcast" routerLinkActive="active" #comms1="routerLinkActive">Broadcast</a>
            </div>
        </div>
        <input type="hidden" #comms1>
        <input type="hidden" #comms2>
        <input type="hidden" #comms3>
    </li>

    <!-- Nav Item - Settings -->
    <li class="nav-item">
        <a class="nav-link" routerLink="/admin/settings/all" routerLinkActive="active">
            <img class="nav-icon" src="/assets/images/svg/settings.svg" alt="settings">
            <span class="text-white">Settings</span>
        </a>
    </li>

    <!-- Sidebar Toggler (Sidebar) -->
    <!-- <div class="text-center d-none d-md-inline mt-4">
        <button class="rounded-circle border-0" id="sidebarToggle"></button>
    </div> -->

</ul>

<!-- Client SIde bar -->
<ul class="hideMePrint navbar-nav sidebar sidebar-dark accordion pt-4 h-100" id="accordionSidebar" *ngIf="userType == 'client'">

    <!-- Sidebar - Brand -->
    <!-- <a class="sidebar-brand d-flex align-items-center justify-content-center mb-4" href="#" title="Home">
        <div class="sidebar-brand-icon">
            <img src="/assets/images/svg/logo.svg" class="w-100" alt="logo">
        </div>
    </a> -->

        <!-- Nav Item - Dashboard -->
    <li class="nav-item">
        <a class="nav-link" routerLink="/client/home" routerLinkActive="active">
            <img class="nav-icon" src="/assets/images/svg/dashboard.svg" alt="dashboard">
            <span class="text-white">Dashboard</span>
        </a>
    </li>

    <!-- Nav Item - Shifts -->
    <li class="nav-item">
        <a class="nav-link  {{uiShifts ? '' : 'collapsed'}}" [ngClass]="{'active' : shift1.isActive || shift2.isActive || shift3.isActive || shift4.isActive || shift5.isActive}" href="#" data-toggle="collapse" data-target="#collapseTwo"
            aria-expanded="true" aria-controls="collapseTwo">
            <img class="nav-icon" src="/assets/images/svg/shifts.svg" alt="dashboard">
            <span class="text-white">Shifts</span>
        </a>
        <div id="collapseTwo" class="collapse" [ngClass]="{'show' : uiShifts}"  aria-labelledby="headingTwo" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white" *ngIf="'VIEW_SHIFT'| permission" routerLink="/client/shifts/list" routerLinkActive="active" #shift1="routerLinkActive">Shifts</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_SHIFT'| permission" routerLink="/client/shifts/new" routerLinkActive="active" #shift4="routerLinkActive" hidden>New Shift</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_SHIFT'| permission" routerLink="/client/shifts/applied" routerLinkActive="active" #shift5="routerLinkActive" hidden>Applied Shift</a>
                <!-- <a class="collapse-item text-white" *ngIf="'VIEW_SHIFT_LOCATION'| permission" routerLink="/client/shifts/locations" routerLinkActive="active" #shift2="routerLinkActive">Shift Locations</a> -->
                <a class="collapse-item text-white" *ngIf="'VIEW_SHIFT_DIRECTORATE' | permission" routerLink="/client/shifts/directorates" routerLinkActive="active" #shift3="routerLinkActive">Shift Directorates</a>
            </div>
        </div>
        <input type="hidden" #shift1>
        <input type="hidden" #shift2>
        <input type="hidden" #shift3>
        <input type="hidden" #shift4>
        <input type="hidden" #shift5>
    </li>

    <!-- Nav Item - Rates & codes -->
    <li class="nav-item" *ngIf="('VIEW_ASSIGNMENT_CODE'| permission )&& ('VIEW_ASSIGNMENT_CODE_RATE'| permission)" >
        <a class="nav-link {{uiRates ? '' : 'collapsed'}}" href="#" [ngClass]="{'active' : rate1.isActive || rate2.isActive}" data-toggle="collapse" data-target="#collapseThree"
            aria-expanded="true" aria-controls="collapseThree">
            <img class="nav-icon" src="/assets/images/svg/rates_codes.svg" alt="rates">
            <span class="text-white">Rates & Codes</span>
        </a>
        <div id="collapseThree" class="collapse" [ngClass]="{'show' : uiRates}" aria-labelledby="heading3" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white" *ngIf="'VIEW_ASSIGNMENT_CODE'| permission" routerLink="/client/rates/codes" routerLinkActive="active" #rate1="routerLinkActive">Assignment Codes</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_ASSIGNMENT_CODE_RATE'| permission" routerLink="/client/rates/assignment-rates" routerLinkActive="active" #rate2="routerLinkActive">Assignment Rates</a>
            </div>
        </div>
        <input type="hidden" #rate1>
        <input type="hidden" #rate2>
        <input type="hidden" #rate3>
    </li>

    <!-- <li class="nav-item">
        <a class="nav-link" routerLink="/client/services" routerLinkActive="active">
            <img class="nav-icon" src="/assets/images/svg/clients.svg" alt="client">
            <span class="text-white">Client Services</span>
        </a>
    </li> -->

    <!-- Nav Item - Agencies -->
    <li class="nav-item" *ngIf="'VIEW_AGENCY'| permission" >
        <a class="nav-link  {{uiAgency ? '' : 'collapsed'}}" [ngClass]="{'active': agency1.isActive}" href="#" data-toggle="collapse" data-target="#collapseFour"
            aria-expanded="true" aria-controls="collapseFour">
            <img class="nav-icon" src="/assets/images/svg/agency.svg" alt="agency">
            <span class="text-white">Service Providers</span>
        </a>
        <div id="collapseFour" class="collapse" [ngClass]="{'show' : uiAgency}" aria-labelledby="heading4" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white" *ngIf="'VIEW_AGENCY'| permission" routerLink="/client/agencies" routerLinkActive="active" #agency1="routerLinkActive">Service Providers</a>
            </div>
        </div>
        <input type="hidden" #agency1>
        <input type="hidden" #agency2>
        <input type="hidden" #agency3>
    </li>

    <!-- Nav Item - Flexi-Workers -->
    <li class="nav-item" *ngIf="'VIEW_WORKER'| permission">
        <a class="nav-link {{uiWorkers ? '' : 'collapsed'}}" [ngClass]="{'active' : workers1.isActive || workers2.isActive}" href="#" data-toggle="collapse" data-target="#collapseSix"
            aria-expanded="true" aria-controls="collapseSix">
            <img class="nav-icon" src="/assets/images/svg/workers.svg" alt="workers">
            <span class="text-white">Flexi-Workers</span>
        </a>
        <div id="collapseSix" class="collapse" [ngClass]="{'show': uiWorkers}" aria-labelledby="heading6" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white" *ngIf="'VIEW_WORKER'| permission" routerLink="/client/workers/list" routerLinkActive="active" #workers1="routerLinkActive">List Flexi-Workers</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_WORKER'| permission" routerLink="/client/workers/directly-book" routerLinkActive="active" #workers3="routerLinkActive" hidden>Book Workers</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_WORKER'| permission" routerLink="/client/workers/info/" routerLinkActive="active" #workers2="routerLinkActive" hidden>Flexi-Worker Info</a>
            </div>
        </div>
        <input type="hidden" #workers1>
        <input type="hidden" #workers2>
        <input type="hidden" #workers3>
    </li>

     <!-- Nav Item - Transport -->
     <li class="nav-item" *ngIf="'VIEW_TRANSPORT'| permission">
        <a class="nav-link {{uiTransport ? '' : 'collapsed'}}" href="#" [ngClass]="{'active' : transport1.isActive || transport2.isActive}" data-toggle="collapse" data-target="#collapseFive"
            aria-expanded="true" aria-controls="collapseFive">
            <img class="nav-icon" src="/assets/images/svg/jobs.svg" alt="rates">
            <span class="text-white">Transport</span>
        </a>
        <div id="collapseFive" class="collapse" [ngClass]="{'show': uiTransport}" aria-labelledby="heading5" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white"  routerLink="/client/transport" routerLinkActive="active" #transport1="routerLinkActive">Secure Transport</a>
                <a class="collapse-item text-white"   routerLink="/client/taxi" routerLinkActive="active" #transport2="routerLinkActive">Taxi</a>
            </div>
        </div>
        <input type="hidden" #transport1>
        <input type="hidden" #transport2>
        <input type="hidden" #transport3>
    </li>


    <!-- Nav Item - Billing -->
    <li class="nav-item"
      *ngIf="
      ('VIEW_WORKLINK_BILLING'| permission)&&
      ('VIEW_WORKLINK_BILLING'| permission)&&
      ('VIEW_WORKLINK_BILLING'| permission)
      "
    >
        <a class="nav-link {{uiBilling ? '' : 'collapsed'}}" [ngClass]="{'active' : bill1.isActive || bill2.isActive || bill3.isActive }" href="#" data-toggle="collapse" data-target="#collapseSeven"
            aria-expanded="true" aria-controls="collapseSeven">
            <img class="nav-icon" src="/assets/images/svg/billing.svg" alt="billing">
            <span class="text-white">Billing</span>
        </a>
        <div id="collapseSeven" class="collapse" [ngClass]="{'show' : uiBilling}" aria-labelledby="heading7" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white" *ngIf="('VIEW_WORKLINK_BILLING'| permission)" routerLink="/client/billing/client" routerLinkActive="active" #bill1="routerLinkActive">My Billing</a>
                <a class="collapse-item text-white" *ngIf="('VIEW_WORKLINK_BILLING'| permission)" routerLink="/client/billing/invoicing" routerLinkActive="active" #bill2="routerLinkActive" hidden>Invoicing</a>
                <a class="collapse-item text-white" *ngIf="('VIEW_WORKLINK_BILLING'| permission)" routerLink="/client/billing/debit-notes" routerLinkActive="active" #bill3="routerLinkActive" hidden>Debit Notes</a>
            </div>
        </div>
        <input type="hidden" #bill1>
        <input type="hidden" #bill2>
        <input type="hidden" #bill3>
    </li>

    <!-- Nav Item - Staff -->
    <li class="nav-item" *ngIf="'VIEW_SYSTEM_USERS'| permission" >
        <a class="nav-link {{uiStaff ? '' : 'collapsed'}}" [ngClass]="{'active' : staff1.isActive || staff2.isActive || staff3.isActive}" href="#" data-toggle="collapse" data-target="#collapseEight"
            aria-expanded="true" aria-controls="collapseEight">
            <img class="nav-icon" src="/assets/images/svg/staff.svg" alt="staff">
            <span class="text-white">Staff Management</span>
        </a>
        <div id="collapseEight" class="collapse" [ngClass]="{'show': uiStaff}" aria-labelledby="heading8" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white" *ngIf="'VIEW_SYSTEM_USERS'| permission" routerLink="/client/staff/users" routerLinkActive="active" #staff1="routerLinkActive">System Users</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_SYSTEM_USERS'| permission" routerLink="/client/staff/user/info/" routerLinkActive="active" #staff2="routerLinkActive" hidden>User Info</a>
                <a class="collapse-item text-white" routerLink="/client/staff/permissions" routerLinkActive="active" #staff3="routerLinkActive"></a>
            </div>
        </div>
        <input type="hidden" #staff1>
        <input type="hidden" #staff2>
        <input type="hidden" #staff3>
    </li>

    <!-- Nav Item - Reports -->
    <!-- <li class="nav-item">
        <a class="nav-link {{uiReport ? '' : 'collapsed'}}" href="#" [ngClass]="{'active' : report2.isActive || report3.isActive}" data-toggle="collapse" data-target="#collapseNine"
            aria-expanded="true" aria-controls="collapseNine">
            <img class="nav-icon" src="/assets/images/svg/reports.svg" alt="reports">
            <span class="text-white">Reports</span>
        </a>
        <div id="collapseNine" class="collapse" [ngClass]="{'show': uiReport}" aria-labelledby="heading9" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white"  routerLink="/client/reporting/client" routerLinkActive="active" #report2="routerLinkActive">Client Reports</a>
                <a class="collapse-item text-white"  routerLink="/client/reporting/flexi-worker" routerLinkActive="active" #report3="routerLinkActive">Assignment Reports</a>
            </div>
        </div>
        <input type="hidden" #report1>
        <input type="hidden" #report2>
        <input type="hidden" #report3>
    </li> -->

    <!-- Nav Item - Comms -->
    <li class="nav-item" *ngIf="'VIEW_BROADCAST'| permission">
        <a class="nav-link {{uiComms ? '' : 'collapsed'}}" [ngClass]="{'active' : comms1.isActive}" href="#" data-toggle="collapse" data-target="#collapseTen"
            aria-expanded="true" aria-controls="collapseTen">
            <img class="nav-icon" src="/assets/images/svg/communication.svg" alt="comms">
            <span class="text-white">Communications</span>
        </a>
        <div id="collapseTen" [ngClass]="{'show' : uiComms}" class="collapse" aria-labelledby="heading10" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white" *ngIf="'VIEW_BROADCAST'| permission" routerLink="/client/communications/broadcast" routerLinkActive="active" #comms1="routerLinkActive">Broadcast</a>
            </div>
        </div>
        <input type="hidden" #comms1>
        <input type="hidden" #comms2>
        <input type="hidden" #comms3>
    </li>

    <!-- Nav Item - Settings -->
    <!-- <li class="nav-item" *ngIf="'VIEW_SETTINGS'| permission">
        <a class="nav-link" routerLink="/client/settings/all" routerLinkActive="active">
            <img class="nav-icon" src="/assets/images/svg/settings.svg" alt="settings">
            <span class="text-white">Settings</span>
        </a>
    </li> -->

    <!-- Sidebar Toggler (Sidebar) -->
    <!-- <div class="text-center d-none d-md-inline mt-4">
        <button class="rounded-circle border-0" id="sidebarToggle"></button>
    </div> -->

</ul>

<!-- Agency SIde Bar -->
<ul class="hideMePrint navbar-nav sidebar sidebar-dark accordion pt-4 h-100" id="accordionSidebar" *ngIf="userType == 'agency'">

  <!-- Nav Item - Dashboard -->
    <li class="nav-item">
        <a class="nav-link" routerLink="/agency/home" routerLinkActive="active">
            <img class="nav-icon" src="/assets/images/svg/dashboard.svg" alt="dashboard">
            <span class="text-white">Dashboard</span>
        </a>
    </li>


    <!-- Nav Item - Shifts -->
    <li class="nav-item" *ngIf="('VIEW_SHIFT'| permission)||('VIEW_SHIFT_LOCATION'| permission)||('VIEW_SHIFT_DIRECTORATE'| permission)">
        <a class="nav-link  {{uiShifts ? '' : 'collapsed'}}" [ngClass]="{'active' : shift1.isActive || shift2.isActive || shift3.isActive || shift4.isActive}" href="#" data-toggle="collapse" data-target="#collapseTwo"
            aria-expanded="true" aria-controls="collapseTwo">
            <img class="nav-icon" src="/assets/images/svg/shifts.svg" alt="dashboard">
            <span class="text-white">Shifts</span>
        </a>
        <div id="collapseTwo" class="collapse" [ngClass]="{'show' : uiShifts}"  aria-labelledby="headingTwo" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white"  routerLink="/agency/shifts/applied" routerLinkActive="active" #shift4="routerLinkActive" hidden>Applied Shift</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_SHIFT'| permission"  routerLink="/agency/shifts/list" routerLinkActive="active" #shift1="routerLinkActive">Shifts</a>
                <!-- <a class="collapse-item text-white" *ngIf="'VIEW_SHIFT_LOCATION'| permission" routerLink="/agency/shifts/locations" routerLinkActive="active" #shift2="routerLinkActive">Shift Locations</a> -->
                <a class="collapse-item text-white" *ngIf="'VIEW_SHIFT_DIRECTORATE'| permission" routerLink="/agency/shifts/directorates" routerLinkActive="active" #shift3="routerLinkActive">Shift Directorates</a>
            </div>
        </div>
        <input type="hidden" #shift1>
        <input type="hidden" #shift2>
        <input type="hidden" #shift3>
        <input type="hidden" #shift4>
    </li>


        <!-- Nav Item - Training -->
    <li class="nav-item" *ngIf="'VIEW_TRAINING_SESSION'| permission">
        <a class="nav-link  {{uiTrainings ? '' : 'collapsed'}}" [ngClass]="{'active.dummy' : training1.isActive || training2.isActive  }" href="#" data-toggle="collapse" data-target="#collapseTwo1"
            aria-expanded="true" aria-controls="collapseTwo1">
            <img class="nav-icon" src="/assets/images/svg/trainings.svg" alt="dashboard">
            <span class="text-white">Trainings</span>
        </a>
        <div id="collapseTwo1" class="collapse" [ngClass]="{'show' : uiTrainings}"  aria-labelledby="headingTwo" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
              <a class="collapse-item text-white"  routerLink="/agency/trainings/list/self" routerLinkActive="active" #training1="routerLinkActive">Offered Trainings</a>
              <a class="collapse-item text-white"   routerLink="/agency/trainings/list/other" routerLinkActive="active" #training2="routerLinkActive">Received Trainings</a>
              <a class="collapse-item text-white"   routerLink="/agency/trainings/list/report/self-report" routerLinkActive="active" #training2="routerLinkActive">Reports</a>
            </div>
        </div>
        <input type="hidden" #training1>
        <input type="hidden" #training2>
    </li>



      <!-- Nav Item - Secure Tranport -->
      <li class="nav-item" *ngIf="('VIEW_TRANSPORT'| permission) || ('VIEW_EXPENSE_RATE'| permission)"  >
        <a class="nav-link {{uiJobs ? '' : 'collapsed'}}" href="#" [ngClass]="{'active' : job1.isActive || job2.isActive }" data-toggle="collapse" data-target="#collapseJobs"
            aria-expanded="true" aria-controls="collapseJobs">
            <img class="nav-icon" src="/assets/images/svg/jobs.svg" alt="rates">
            <span class="text-white">Secure Transport</span>
        </a>
        <div id="collapseJobs" class="collapse" [ngClass]="{'show' : uiJobs}" aria-labelledby="heading3" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white" *ngIf="'VIEW_TRANSPORT'| permission"  routerLink="/agency/jobs/agency-list" routerLinkActive="active" #rate1="routerLinkActive">Jobs</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_TRANSPORT'| permission"  routerLink="/agency/jobs/agency-vehicles" routerLinkActive="active" #rate2="routerLinkActive">Fleet Management</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_TRANSPORT'| permission"  routerLink="/agency/jobs/agency-logs-list" routerLinkActive="active" #rate3="routerLinkActive">General Logs</a> 
                <!-- <a class="collapse-item text-white" *ngIf="'VIEW_'| permission" routerLink="/agency/rates/tax-codes-rates" routerLinkActive="active" #rate4="routerLinkActive">Tax Codes & Rates</a> -->
            </div>
        </div>
        <input type="hidden" #job1>
        <input type="hidden" #job2>
        <!-- <input type="hidden" #rate3> -->
    </li>




    <!-- Nav Item - Rates & codes -->
    <li class="nav-item" *ngIf="('VIEW_ASSIGNMENT_CODE_RATE'| permission) || ('VIEW_EXPENSE_RATE'| permission)"  >
        <a class="nav-link {{uiRates ? '' : 'collapsed'}}" href="#" [ngClass]="{'active' : rate1.isActive || rate2.isActive || rate3.isActive }" data-toggle="collapse" data-target="#collapseThree"
            aria-expanded="true" aria-controls="collapseThree">
            <img class="nav-icon" src="/assets/images/svg/rates_codes.svg" alt="rates">
            <span class="text-white">Rates & Codes</span>
        </a>
        <div id="collapseThree" class="collapse" [ngClass]="{'show' : uiRates}" aria-labelledby="heading3" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white" *ngIf="'VIEW_ASSIGNMENT_CODE_RATE'| permission"  routerLink="/agency/rates/codes" routerLinkActive="active" #rate1="routerLinkActive">Assignment Codes</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_ASSIGNMENT_CODE_RATE'| permission"  routerLink="/agency/rates/assignment-rates" routerLinkActive="active" #rate2="routerLinkActive">Assignment Rates</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_EXPENSE_RATE'| permission"  routerLink="/agency/rates/expense-rates" routerLinkActive="active" #rate3="routerLinkActive">Expense Rates</a>
                <!-- <a class="collapse-item text-white" *ngIf="'VIEW_'| permission" routerLink="/agency/rates/tax-codes-rates" routerLinkActive="active" #rate4="routerLinkActive">Tax Codes & Rates</a> -->
            </div>
        </div>
        <input type="hidden" #rate1>
        <input type="hidden" #rate2>
        <input type="hidden" #rate3>
    </li>

    <!-- Nav Item - Clients -->
    <li class="nav-item" *ngIf="'VIEW_CLIENT'| permission">
        <a class="nav-link {{uiClients ? '' : 'collapsed'}}" [ngClass]="{'active' : client1.isActive}" href="#" data-toggle="collapse" data-target="#collapseFive"
            aria-expanded="true" aria-controls="collapseFive">
            <img class="nav-icon" src="/assets/images/svg/clients.svg" alt="clients">
            <span class="text-white">Clients</span>
        </a>
        <div id="collapseFive" class="collapse" [ngClass]="{'show' : uiClients}" aria-labelledby="heading5" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white" *ngIf="'VIEW_CLIENT'| permission"  routerLink="/agency/clients" routerLinkActive="active" #client1="routerLinkActive">List Clients</a>
            </div>
        </div>
        <input type="hidden" #client1>

    </li>

    <!-- Nav Item - Flexi-Workers -->
    <li class="nav-item" *ngIf="'VIEW_WORKER'| permission">
        <a class="nav-link {{uiWorkers ? '' : 'collapsed'}}" [ngClass]="{'active' : workers1.isActive || workers2.isActive}" href="#" data-toggle="collapse" data-target="#collapseSix"
            aria-expanded="true" aria-controls="collapseSix">
            <img class="nav-icon" src="/assets/images/svg/workers.svg" alt="workers">
            <span class="text-white">Flexi-Workers</span>
        </a>
        <div id="collapseSix" class="collapse" [ngClass]="{'show': uiWorkers}" aria-labelledby="heading6" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white" *ngIf="'VIEW_WORKER'| permission"  routerLink="/agency/workers" routerLinkActive="active" #workers1="routerLinkActive">List Flexi-Workers</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_WORKER'| permission"  routerLink="/agency/applicants" routerLinkActive="active" #workers2="routerLinkActive">Applicants</a>
                <a class="collapse-item text-white"  routerLink="/agency/workers/info/" routerLinkActive="active" #workers2="routerLinkActive" hidden>Flexi-Worker Info</a>
                <a class="collapse-item text-white"  routerLink="/agency/applicants/info/" routerLinkActive="active" #workers2="routerLinkActive" hidden>Applicant Info</a>
            </div>
        </div>
        <input type="hidden" #workers1>
        <input type="hidden" #workers2>
        <input type="hidden" #workers3>
        <input type="hidden" #workers4>


    </li>

    <!-- Nav Item - Billing -->
    <li class="nav-item">
        <a class="nav-link {{uiBilling ? '' : 'collapsed'}}" [ngClass]="{'active' : bill1.isActive || bill2.isActive || bill3.isActive || bill4.isActive}" href="#" data-toggle="collapse" data-target="#collapseSeven"
            aria-expanded="true" aria-controls="collapseSeven">
            <img class="nav-icon" src="/assets/images/svg/billing.svg" alt="billing">
            <span class="text-white">Billing</span>
        </a>
        <div id="collapseSeven" class="collapse" [ngClass]="{'show' : uiBilling}" aria-labelledby="heading7" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white" *ngIf="'VIEW_WORKLINK_BILLING'| permission"  routerLink="/agency/billing/agency" routerLinkActive="active" #bill1="routerLinkActive">MyWorklink Billing</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_CLIENT_BILLING'| permission"  routerLink="/agency/billing/client-billing" routerLinkActive="active" #bill4="routerLinkActive">Client Billing</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_TRAINING_BILLING'| permission"  routerLink="/agency/billing/training-billing" routerLinkActive="active" #bill4="routerLinkActive">Training Billing</a>
                <!-- <a class="collapse-item text-white" *ngIf="'VIEW_WORKLINK_BILLING'| permission"  routerLink="/agency/billing/invoicing" routerLinkActive="active" #bill2="routerLinkActive" >Invoicing</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_WORKLINK_BILLING'| permission"  routerLink="/agency/billing/invoice-notes" routerLinkActive="active" #bill3="routerLinkActive" >Invoice Notes</a> -->
            </div>
        </div>
        <input type="hidden" #bill1>
        <input type="hidden" #bill2>
        <input type="hidden" #bill3>
        <input type="hidden" #bill4>

    </li>

    <!-- Nav Item - Payroll -->
    <li class="nav-item" *ngIf="'VIEW_PAYROL'| permission">
        <a class="nav-link {{uiPayroll ? '' : 'collapsed'}}" [ngClass]="{'active' : pay1.isActive || pay2.isActive || pay3.isActive }" href="#" data-toggle="collapse" data-target="#collapseEleven"
            aria-expanded="true" aria-controls="collapseEleven">
            <img class="nav-icon" src="/assets/images/svg/payroll.svg" alt="billing">
            <span class="text-white">Payroll</span>
        </a>
        <div id="collapseEleven" class="collapse" [ngClass]="{'show' : uiPayroll}" aria-labelledby="heading7" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white" *ngIf="'VIEW_PAYROL'| permission"  routerLink="/agency/payslips/worker-pay-advices" routerLinkActive="active" #pay1="routerLinkActive">Worker Payroll</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_PAYROL'| permission"  routerLink="/agency/payslips" routerLinkActive="active" #pay3="routerLinkActive">Pay Advices</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_PAYROL'| permission"  routerLink="/agency/payslips/expenses-claimed" routerLinkActive="active" #pay2="routerLinkActive">Queries & Expenses</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_SETTINGS' | permission"  routerLink="/agency/payslips/payroll-settings" routerLinkActive="active" #pay4="routerLinkActive">Payroll Settings</a>


            </div>
        </div>
        <input type="hidden" #pay1>
        <input type="hidden" #pay2>
        <input type="hidden" #pay3>
        <input type="hidden" #pay4>
    </li>

    <!-- Nav Item - Staff -->
    <li class="nav-item" *ngIf="'VIEW_SYSTEM_USERS'| permission" >
        <a class="nav-link {{uiStaff ? '' : 'collapsed'}}" [ngClass]="{'active' : staff1.isActive || staff2.isActive}" href="#" data-toggle="collapse" data-target="#collapseEight"
            aria-expanded="true" aria-controls="collapseEight">
            <img class="nav-icon" src="/assets/images/svg/staff.svg" alt="staff">
            <span class="text-white">Staff Management</span>
        </a>
        <div id="collapseEight" class="collapse" [ngClass]="{'show': uiStaff}" aria-labelledby="heading8" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white" *ngIf="'VIEW_SYSTEM_USERS'| permission"   routerLink="/agency/staff/users" routerLinkActive="active" #staff1="routerLinkActive">System Users</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_SYSTEM_USERS'| permission"  routerLink="/agency/staff/users/info/" routerLinkActive="active" #staff2="routerLinkActive" hidden>User Info</a>
                <!-- <a class="collapse-item text-white" *ngIf="'VIEW_'| permission" routerLink="/agency/staff/permissions" routerLinkActive="active" #staff3="routerLinkActive">Roles & Permissions</a> -->
            </div>
        </div>

        <input type="hidden" #staff1>
        <input type="hidden" #staff2>

    </li>

    <!-- Nav Item - Reports -->
    <li class="nav-item" *ngIf="'VIEW_AGENCY_REPORT'| permission" >
        <a class="nav-link {{uiReport ? '' : 'collapsed'}}" href="#" [ngClass]="{'active' : report1.isActive || report2.isActive || report3.isActive}" data-toggle="collapse" data-target="#collapseNine"
            aria-expanded="true" aria-controls="collapseNine">
            <img class="nav-icon" src="/assets/images/svg/reports.svg" alt="reports">
            <span class="text-white">Reports</span>
        </a>
        <div id="collapseNine" class="collapse" [ngClass]="{'show': uiReport}" aria-labelledby="heading9" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white" *ngIf="'VIEW_AGENCY_REPORT'| permission"  routerLink="/agency/reporting/agency" routerLinkActive="active" #report1="routerLinkActive">Worklink Reports</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_AGENCY_REPORT'| permission"  routerLink="/agency/reporting/client" routerLinkActive="active" #report2="routerLinkActive">Client Reports</a>
                <!-- <a class="collapse-item text-white" *ngIf="'VIEW_AGENCY_REPORT'| permission"  routerLink="/agency/reporting/flexi-worker" routerLinkActive="active" #report3="routerLinkActive">Flexi-Worker Reports</a> -->
                <a class="collapse-item text-white" *ngIf="'VIEW_AGENCY_REPORT'| permission"  routerLink="/agency/reporting/closed-shift" routerLinkActive="active" #report4="routerLinkActive">Shift Reports</a>
                <a class="collapse-item text-white" *ngIf="'VIEW_AGENCY_REPORT'| permission"  routerLink="/agency/reporting/physical-trainings" routerLinkActive="active" #report4="routerLinkActive">Training Billing Reports</a>
            </div>
        </div>

        <input type="hidden" #report1>
        <input type="hidden" #report2>
        <input type="hidden" #report3>

    </li>

    <!-- Nav Item - Comms -->
    <li class="nav-item" *ngIf="'VIEW_BROADCAST'| permission">
        <a class="nav-link {{uiComms ? '' : 'collapsed'}}" [ngClass]="{'active' : comms1.isActive}" href="#" data-toggle="collapse" data-target="#collapseTen"
            aria-expanded="true" aria-controls="collapseTen">
            <img class="nav-icon" src="/assets/images/svg/communication.svg" alt="comms">
            <span class="text-white">Communications</span>
        </a>
        <div id="collapseTen" [ngClass]="{'show' : uiComms}" class="collapse" aria-labelledby="heading10" data-parent="#accordionSidebar">
            <div class="collapse-inner rounded">
                <a class="collapse-item text-white" *ngIf="'VIEW_BROADCAST'| permission" routerLink="/agency/communications/broadcast" routerLinkActive="active" #comms1="routerLinkActive">Broadcast</a>

            </div>
        </div>
        <input type="hidden" #comms1>

    </li>

    <!-- Nav Item - Settings -->
    <li class="nav-item" *ngIf="'VIEW_SETTINGS'| permission">
        <a class="nav-link" routerLink="/agency/settings/all" routerLinkActive="active">
            <img class="nav-icon" src="/assets/images/svg/settings.svg" alt="settings">
            <span class="text-white">Settings</span>
        </a>
    </li>

    <!-- Sidebar Toggler (Sidebar) -->
    <!-- <div class="text-center d-none d-md-inline mt-4">
        <button class="rounded-circle border-0" id="sidebarToggle"></button>
    </div> -->

</ul>


<!-- Worker Side bar -->
<ul class="hideMePrint navbar-nav sidebar sidebar-dark accordion pt-4 h-100 toggled  d-sm-block d-md-none " id="accordionSidebar" *ngIf="userType == 'worker'">

  <!-- Sidebar - Brand -->
  <!-- <a class="sidebar-brand d-flex align-items-center justify-content-center mb-4" href="#" title="Home">
      <div class="sidebar-brand-icon">
          <img src="/assets/images/svg/logo.svg" class="w-100" alt="logo">
      </div>
  </a> -->

      <!-- Nav Item - Dashboard -->
  <li class="nav-item">
      <a class="nav-link" routerLink="/worker/home" routerLinkActive="active">
          <img class="nav-icon" src="/assets/images/svg/dashboard.svg" alt="dashboard">
          <span class="text-white">Home</span>
      </a>
  </li>


  <!-- Nav Item - Agencies -->
  <li class="nav-item">
      <a class="nav-link  {{uiAgency ? '' : 'collapsed'}}"  href="#" data-toggle="collapse" data-target="#collapseFour"
          aria-expanded="true" aria-controls="collapseFour">
          <img class="nav-icon" src="/assets/images/svg/agency.svg" alt="agency">
          <span class="text-white">Agencies</span>
      </a>
      <div id="collapseFour" class="collapse" [ngClass]="{'show' : uiAgency}" aria-labelledby="heading4" data-parent="#accordionSidebar">
          <div *ngFor="let a of agencies" class="collapse-inner rounded">
              <a class="collapse-item text-white" href="/worker/agency/{{a.id}}" routerLinkActive="active" >{{a.name}}</a>
          </div>
      </div>
  </li>


  <!-- Nav Item - Trainings -->
  <li class="nav-item">
    <a class="nav-link" routerLink="/worker/trainings" routerLinkActive="active">
        <img class="nav-icon" src="/assets/images/svg/trainings.svg" alt="trainings">
        <span class="text-white">Trainings & Qualifications</span>
    </a>
  </li>

  <!-- Nav Item - Settings -->
  <li class="nav-item">
    <a class="nav-link" routerLink="/worker/compliance" routerLinkActive="active">
      <img class="nav-icon" src="/assets/images/svg/staff.svg" alt="staff">
      <span class="text-white">Compliance</span>
    </a>
  </li>



  <!-- Nav Item - Settings -->
  <li class="nav-item">
      <a class="nav-link" routerLink="/worker/forms/" routerLinkActive="active">
          <img class="nav-icon" src="/assets/images/svg/settings.svg" alt="settings">
          <span class="text-white">Forms</span>
      </a>
  </li>

  <li class="nav-item">
      <a class="nav-link" routerLink="/worker/transport/" routerLinkActive="active">
        <img class="nav-icon" src="/assets/images/svg/jobs.svg" alt="transport">
        <span class="text-white">Secure Transport</span>
      </a>
  </li>



  <!-- Sidebar Toggler (Sidebar) -->
  <!-- <div class="text-center d-none d-md-inline mt-4">
      <button class="rounded-circle border-0" id="sidebarToggle"></button>
  </div> -->

</ul>



<!-- Worker Side Bar -->
<ul class="hideMePrint navbar-nav sidebar sidebar-dark accordion pt-4 h-100  d-none d-md-block "  *ngIf="userType == 'worker'">

  <!-- Sidebar - Brand -->
  <!-- <a class="sidebar-brand d-flex align-items-center justify-content-center mb-4" href="#" title="Home">
    <div class="sidebar-brand-icon">
        <img src="/assets/images/svg/logo.svg" class="w-100" alt="logo">
    </div>
</a> -->

    <!-- Nav Item - Dashboard -->
<li class="nav-item">
    <a class="nav-link" routerLink="/worker/home" routerLinkActive="active">
        <img class="nav-icon" src="/assets/images/svg/dashboard.svg" alt="dashboard">
        <span class="text-white">Home</span>
    </a>
</li>


<!-- Nav Item - Agencies -->
<li class="nav-item">
    <a class="nav-link  {{uiAgency ? '' : 'collapsed'}}"  href="#" data-toggle="collapse" data-target="#collapseFour"
        aria-expanded="true" aria-controls="collapseFour">
        <img class="nav-icon" src="/assets/images/svg/agency.svg" alt="agency">
        <span class="text-white">Agencies</span>
    </a>
    <div id="collapseFour" class="collapse" [ngClass]="{'show' : uiAgency}" aria-labelledby="heading4" data-parent="#accordionSidebar">
        <div *ngFor="let a of agencies" class="collapse-inner rounded">
            <a class="collapse-item text-white" href="/worker/agency/{{a.id}}" routerLinkActive="active" >{{a.name}}</a>
        </div>
    </div>
</li>


<!-- Nav Item - Trainings -->
<li class="nav-item">
  <a class="nav-link" routerLink="/worker/trainings" routerLinkActive="active">
      <img class="nav-icon" src="/assets/images/svg/trainings.svg" alt="settings">
      <span class="text-white">Trainings & Qualifications</span>
  </a>
</li>

<!-- Nav Item - Settings -->
<li class="nav-item">
  <a class="nav-link" routerLink="/worker/compliance" routerLinkActive="active">
    <img class="nav-icon" src="/assets/images/svg/staff.svg" alt="staff">
    <span class="text-white">Compliance</span>
  </a>
</li>



<!-- Nav Item - Settings -->
<li class="nav-item">
    <a class="nav-link" routerLink="/worker/forms/" routerLinkActive="active">
        <img class="nav-icon" src="/assets/images/svg/settings.svg" alt="settings">
        <span class="text-white">Forms</span>
    </a>
</li>

<!-- Nav Item - Worker Secure Transport -->
<li class="nav-item">
  <a class="nav-link" routerLink="/worker/transport/" routerLinkActive="active">
    <img class="nav-icon" src="/assets/images/svg/jobs.svg" alt="rates">
    <span class="text-white">Secure Transport</span>
  </a>
</li>

<!-- Sidebar Toggler (Sidebar) -->
<!-- <div class="text-center d-none d-md-inline mt-4">
    <button class="rounded-circle border-0" id="sidebarToggle"></button>
</div> -->
</ul>
