<div class="">
  <div class="modal-header bg-main text-white">
    <h5 class="modal-title text-center mx-4 w-100">Upload Training</h5>
  </div>
  <div class="modal-body p-0">
    <form [formGroup]="addTrainingForm" (ngSubmit)="addTraining(addTrainingForm)">
      <input type="hidden" formControlName="workerId" value="{{workerId}}" />
      <input formControlName="code" type="hidden"  placeholder="Code"
                  class="form-control">
      <input formControlName="code" type="hidden"  placeholder="Code"
                  class="form-control">
      <input type="hidden" formControlName="serviceId" value="1" />
      <div class="bg-modal card-body">
        <div class="container-fluid">
          <div class="">
            <div class="">
              <i class="bi bi-info-circle"></i>
              <span class="ml-2">Training</span>
              <hr class="bg-black mt-0">
            </div>
            <div class="">
              <div class="form-group">
                <select formControlName="trainingId" class="form-control w-content" style="width:100%">
                  <option value="" selected disabled>Select training</option>
                  <option *ngFor="let training of trainingAll;" value="{{training.id}}">{{training.name}}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="" class="modal-label">Certificate</label><br>
                  <input    type="file" id="file" (change)="onChange($event)" accept="application/pdf">
              </div>
            </div>
            <div class="">
              <div class="form-group">
                <label for="" class="modal-label">Issue Date</label>
                <input formControlName="trainingDate" type="date"  placeholder="Issue Date"
                  class="form-control">
              </div>
              <div class="form-group">
                <label for="" class="modal-label">ExpiryDate</label>
                <input formControlName="trainingExpiry" type="date"  placeholder="ExpiryDate"
                  class="form-control">
              </div>
            </div>
            <div align="right" class="mt-3">
              <button type="button" class="btn btn-danger btn-sm mr-3" (click)="dismissModal()">
                Cancel
              </button>
              <button type="submit" class="btn btn-sm btn-success">
                Add Training
              </button>
            </div>
          </div>
        </div>
      </div>

    </form>
  </div>
</div>
