import { AuthService } from 'src/app/shared/services/auth.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  //base_url: string = environment.API_url + ':8300/api/v1';
  base_url: string = environment.API_url + '/worklink-api/api/v1';
  userType: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private storageService: StorageService
  ) {
    this.userType = this.authService.getUserType();
   }

  authorizeLog(id, authorizer): Observable<any> {
      return this.http.put(`${this.base_url}/vehicle-log/authorize/${id}/${authorizer}`,{});
  }

  commentLog(id, comment): Observable<any> {
      return this.http.put(`${this.base_url}/vehicle-log/comment/${id}/${comment}`,{});
  }

  getAgencyLogsByStatus(status,agencyId, page, size, logType, assignee, startDate, endDate): Observable<any> {
      return this.http.get(`${this.base_url}/vehicle-logs/agency/${agencyId}/${status}/${page}/${size}?startDate=${startDate}&endDate=${endDate}`);
  }


  getWorkerLogsByStatus(status,workerId, page, size, logType, assignee, startDate, endDate): Observable<any> {
      return this.http.get(`${this.base_url}/vehicle-logs/worker/${workerId}/${status}/${page}/${size}?startDate=${startDate}&endDate=${endDate}`);
  }


}
