import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TransportService } from 'src/app/shared/services/transport.service';
import { DocumentService } from '../../../../../shared/services/document.service';
import { HttpResponse } from '@angular/common/http';
import { WorkerService } from 'src/app/shared/services/worker.service';

@Component({
  selector: 'app-vehicle-documents-tab',
  templateUrl: './vehicle-documents-tab.component.html',
  styleUrls: ['./vehicle-documents-tab.component.css']

})
export class VehicleTab2Component implements OnChanges {
  @Input('tabindex') tabindex: string;
  @Input('job') job;
  @Output() formO = new EventEmitter<FormGroup>();
  @Output() tabindexO = new EventEmitter<string>();

  directorates: any;
  addForm: FormGroup;
  editForm: FormGroup;

  page = 1;
  pageSize = 20;
  totalItems = 0;
  showin = 0;
  first: boolean;
  last: boolean;

  userType: string;
  clients: any;
  searchCriteria: string = null;
  isModalOpen = false;
  items = ['Registration book', 'Insurance', 'MOT Certificate', 'Other'];
  selectedItem = '';
  selectedDate: string | null = null;
  selectedFile: File = null;
  http: any;
  vehicleDocumentForm: FormGroup;
  documentFile: any;

  constructor(
    private toast: ToastrService,
    private transporService: TransportService,
    private storageService: StorageService,
    private agencyService: AgencyService,
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private documentService: DocumentService,
    private workerService: WorkerService
  ) { }

  ngOnChanges(): void {
    this.documentService.currentDocument.subscribe(document => {
      if (document) {
        this.selectedItem = document.item;
        this.selectedDate = document.date;
      }
    });

    this.initForm();
  }

  initForm(){
    this.vehicleDocumentForm = this.fb.group({
      documentId: [''],
      expiryDate: [''],
      files: [],
      docName: [""],
      vehicleId: [this.job.id],
  });

  }

  openModalFunction(content:any){
    this.modalService.open(content,{centered: true, size: 'sm'});
  }


  onChange(event) {
    this.documentFile = event.target.files[0];
  }


  closeModalFunction(){
    this.modalService.dismissAll();
  }

  nextTab() {
    console.log(this.tabindex);
    this.tabindex = "inventory";
    // this.tabindex++;
    // if (this.tabindex > 4) this.tabindex = 4;
    this.tabindexO.emit(this.tabindex);
  }

  prevTab() {
    console.log(this.tabindex);
    this.tabindex = "about";
    // this.tabindex--;
    // if (this.tabindex < 1) this.tabindex = 1;
    this.tabindexO.emit(this.tabindex);
  }

  handlePageChange(event: string) {
    if (event === 'next') {
      this.page++;
    } else if (event === 'prev' && this.page > 1) {
      this.page--;
    }
  }


  goBack() {
    this.prevTab();
  }

  goNext() {
    this.nextTab();
  }


  onFileSelected(event){
    this.selectedFile=<File> event.target.files[0];

}
onUpload(){
  const fd = new FormData();
  fd.append('image', this.selectedFile, this.selectedFile.name);
  this.http.post('https://angularbyprocademy-dbe68-default-rtdb.firebaseio.com/products.json',fd)
  .subscribe(res => {
    console.log(res);
  });
}

openModal() {
  this.isModalOpen = true;
}

closeModal() {
  this.isModalOpen = false;
  this.resetForm();
}

resetForm() {
  this.selectedItem = '';
  this.selectedDate = null;
}


openDoc(url){
  this.workerService.getContentTpye(url).subscribe(res => {
    if(res.headers.get('Content-Type')!='application/pdf') {
      return window.open(url);
    }else{
      this.viewDoc(url);
    }
  });
}

viewDoc(url){
  this.workerService.downloadPDF(url).subscribe(res => {
    console.log(res);
    if(res==null)
      window.open(url, '_blank');
    const fileURL = URL.createObjectURL(res);
    window.open(fileURL, '_blank');
  });
}


onSubmit() {
  this.transporService.saveDocument(this.documentFile, this.vehicleDocumentForm ).subscribe(
    event => {
      if(event instanceof HttpResponse) {
      };
      this.toast.info("Sucessfully uploaded.");
      this.modalService.dismissAll();
      this.tabindexO.emit(this.tabindex);
    }
  );
}

}
