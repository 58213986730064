import { ShiftsFilterPipe } from './shifts-filter.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShiftsRoutingModule } from './shifts-routing.module';
import { ShiftsListComponent } from './shifts-list/shifts-list.component';
import { ShiftsLocationsComponent } from './shifts-locations/shifts-locations.component';
import { ShiftsDirectoratesComponent } from './shifts-directorates/shifts-directorates.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NewShiftsComponent } from './tables/new-shifts/new-shifts.component';
import { AppliedShiftsComponent } from './tables/applied-shifts/applied-shifts.component';
import { BookedShiftsComponent } from './tables/booked-shifts/booked-shifts.component';
import { AwaitingShiftsComponent } from './tables/awaiting-shifts/awaiting-shifts.component';
import { AuthorizedShiftsComponent } from './tables/authorized-shifts/authorized-shifts.component';
import { QueriedShiftsComponent } from './tables/queried-shifts/queried-shifts.component';
import { ClosedShiftsComponent } from './tables/closed-shifts/closed-shifts.component';
import { TypesComponent } from './types/types.component';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/shared/pipes.module';


@NgModule({
  declarations: [ShiftsListComponent, ShiftsLocationsComponent, ShiftsDirectoratesComponent, NewShiftsComponent, AppliedShiftsComponent, BookedShiftsComponent, AwaitingShiftsComponent, AuthorizedShiftsComponent, QueriedShiftsComponent, ClosedShiftsComponent, TypesComponent, ShiftsFilterPipe],
  imports: [
    CommonModule,
    ShiftsRoutingModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    FormsModule,
    PipesModule
  ],
  exports: [
    NewShiftsComponent,
    AppliedShiftsComponent,
    BookedShiftsComponent,
    AwaitingShiftsComponent,
    AuthorizedShiftsComponent,
    QueriedShiftsComponent,
    ClosedShiftsComponent
  ]
})
export class ShiftsModule { }
