<nav class="navbar navbar-expand navbar-light border bg-white topbar mb-2 static-top" >
  <!-- Sidebar Toggle (Topbar) -->
  <button
    id="sidebarToggleTop"
    class="btn btn-link d-md-none rounded-circle mr-3"
  >
    <i class="fa fa-bars" style="color:#231E5C; z-index: 99999;"></i>
  </button>

  <!-- logo -->
  <div class="ml-2">
    <img src="/assets/images/svg/new-logo.svg" class="w-50" alt="logo">
  </div>

  <!-- Topbar Navbar -->
  <ul class="navbar-nav ml-auto" *ngIf="userType == 'admin'">
    <span class="pt-4">{{myUser}}</span>
    <div class="topbar-divider d-none d-sm-block"></div>

    <!-- Nav Item - Notifications -->
    <!-- <li class="nav-item dropdown">
      <a
        class="nav-link dropdown-toggle"
        id="notyDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{myUser}}</span>
                <i class="bi bi-person"></i>
        <img src="/assets/images/svg/ic_sms.svg" alt="profile" />
      </a>
      <div
        class="
          dropdown-list dropdown-menu dropdown-menu-right
          shadow
          animated--grow-in
        "
        aria-labelledby="messagesDropdown"
      >
        <h6 class="dropdown-header">Message Center</h6>

        <a class="dropdown-item d-flex align-items-center">
          <div class="dropdown-list-image mr-3">
            <img
              class="rounded-circle"
              src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
              alt=""
            />
            <i class="fa fa-user fa-2x"></i>
            <div class="status-indicator bg-main"></div>
          </div>
          <div>
            <div class="text-truncate">
              Am I a good boy? The reason I ask is because someone told me that
              people say this to all dogs, even if they aren't good...
            </div>
            <div class="small text-gray-500">Chicken the Dog · 2w</div>
          </div>
        </a>
        <a class="dropdown-item text-center small text-gray-500"
          >Read More Messages</a
        >
      </div>
    </li> -->

    <!-- Nav Item - User Information -->
    <li class="nav-item dropdown">
      <a
        class="nav-link dropdown-toggle"
        id="userDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <!-- <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{myUser}}</span>
                <i class="bi bi-person"></i> -->
        <img src="/assets/images/svg/profile.svg" alt="profile image" />
      </a>
      <!-- Dropdown - User Information -->
      <div
        class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
        aria-labelledby="userDropdown"
      >
        <a class="dropdown-item" (click)="changePasswordDialog()">
          <i class="fas fa-user-cog fa-sm fa-fw mr-2 text-gray-400"></i>
          Change Password
        </a>
        <div class="dropdown-divider"></div>
        <a
          class="dropdown-item"
          href="#"
          data-toggle="modal"
          (click)="logout()"
          data-target="#logoutModal"
        >
          <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
          Logout
        </a>
      </div>
    </li>
  </ul>

  <ul class="navbar-nav ml-auto" *ngIf="userType == 'agency'">
    <span class="pt-4">{{myUser}}</span>
    <div class="topbar-divider d-none d-sm-block"></div>

    <!-- Nav Item - Notifications -->
    <!-- <li class="nav-item dropdown" title="Message center">
      <a
        class="nav-link dropdown-toggle"
        id="notyDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{myUser}}</span>
                <i class="bi bi-person"></i>
        <img src="/assets/images/svg/ic_sms.svg" alt="profile" />
      </a>
      <div
        class="
          dropdown-list dropdown-menu dropdown-menu-right
          shadow
          animated--grow-in
        "
        aria-labelledby="messagesDropdown"
      >
        <h6 class="dropdown-header">Message Center</h6>

        <a class="dropdown-item d-flex align-items-center">
          <div class="dropdown-list-image mr-3">
            <img
              class="rounded-circle"
              src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
              alt=""
            />
            <i class="fa fa-user fa-2x"></i>
            <div class="status-indicator bg-main"></div>
          </div>
          <div>
            <div class="text-truncate">
              Am I a good boy? The reason I ask is because someone told me that
              people say this to all dogs, even if they aren't good...
            </div>
            <div class="small text-gray-500">Chicken the Dog · 2w</div>
          </div>
        </a>
        <a class="dropdown-item text-center small text-gray-500"
          >Read More Messages</a
        >
      </div>
    </li> -->

    <!-- Nav Item - User Information -->
    <li class="nav-item dropdown" title="Account">
      <a
        class="nav-link dropdown-toggle"
        id="userDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <!-- <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{myUser}}</span>
                <i class="bi bi-person"></i> -->
        <img src="/assets/images/svg/profile.svg" alt="profile" />
      </a>
      <!-- Dropdown - User Information -->
      <div
        class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
        aria-labelledby="userDropdown"
      >
        <a class="dropdown-item" (click)="changePasswordDialog()">
          <i class="fas fa-user-cog fa-sm fa-fw mr-2 text-gray-400"></i>
          Change Password
        </a>
        <div class="dropdown-divider"></div>
        <a
          class="dropdown-item"
          href="#"
          data-toggle="modal"
          (click)="logout()"
          data-target="#logoutModal"
        >
          <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
          Logout
        </a>
      </div>
    </li>
  </ul>

  <ul class="navbar-nav ml-auto" *ngIf="userType == 'client'">
    <span class="pt-4">{{myUser}}</span>
    <div class="topbar-divider d-none d-sm-block"></div>

    <!-- Nav Item - Notifications -->
    <!-- <li class="nav-item dropdown">
      <a
        class="nav-link dropdown-toggle"
        id="notyDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{myUser}}</span>
                <i class="bi bi-person"></i>
        <img src="/assets/images/svg/ic_sms.svg" alt="profile" />
      </a>
      <div
        class="
          dropdown-list dropdown-menu dropdown-menu-right
          shadow
          animated--grow-in
        "
        aria-labelledby="messagesDropdown"
      >
        <h6 class="dropdown-header">Message Center</h6>

        <a class="dropdown-item d-flex align-items-center">
          <div class="dropdown-list-image mr-3">
            <img
              class="rounded-circle"
              src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
              alt=""
            />
            <i class="fa fa-user fa-2x"></i>
            <div class="status-indicator bg-main"></div>
          </div>
          <div>
            <div class="text-truncate">
              Am I a good boy? The reason I ask is because someone told me that
              people say this to all dogs, even if they aren't good...
            </div>
            <div class="small text-gray-500">Chicken the Dog · 2w</div>
          </div>
        </a>
        <a class="dropdown-item text-center small text-gray-500"
          >Read More Messages</a
        >
      </div>
    </li> -->

    <!-- Nav Item - User Information -->
    <li class="nav-item dropdown">
      <a
        class="nav-link dropdown-toggle"
        id="userDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <!-- <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{myUser}}</span>
                <i class="bi bi-person"></i> -->
        <img src="/assets/images/svg/profile.svg" alt="profile" />
      </a>
      <!-- Dropdown - User Information -->
      <div
        class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
        aria-labelledby="userDropdown"
      >
        <a class="dropdown-item" (click)="changePasswordDialog()">
          <i class="fas fa-user-cog fa-sm fa-fw mr-2 text-gray-400"></i>
          Change Password
        </a>
        <div class="dropdown-divider"></div>
        <a
          class="dropdown-item"
          href="#"
          data-toggle="modal"
          (click)="logout()"
          data-target="#logoutModal"
        >
          <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
          Logout
        </a>
      </div>
    </li>
  </ul>

  <ul class="navbar-nav ml-auto" *ngIf="userType == 'worker'">
    <!-- <span class="pt-4">welcome <b class="d-inline">{{myUser}}</b></span> -->
    <div class="topbar-divider d-none d-sm-block"></div>

    <!-- Nav Item - Notifications -->


    <!-- Nav Item - User Information -->
    <li class="nav-item dropdown">
      <a
        class="nav-link dropdown-toggle"
        id="userDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <!-- <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{myUser}}</span>
                <i class="bi bi-person"></i> -->
        <img src="/assets/images/svg/profile.svg" alt="profile" />
      </a>
      <!-- Dropdown - User Information -->
      <div
        class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
        aria-labelledby="userDropdown"
      >
        <a
          class="dropdown-item w-50"
          href="#"
          data-toggle="modal"
          (click)="logout()"
          data-target="#logoutModal"
        >
          <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
          Logout
        </a>
      </div>
    </li>
  </ul>
</nav>
