import { ToastrService } from 'ngx-toastr';
import { ShiftService } from './../../../shared/services/shift.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { StatisticsService } from 'src/app/shared/services/statistics.service';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-shifts-list',
  templateUrl: './shifts-list.component.html',
  styleUrls: ['./shifts-list.component.css']
})
export class ShiftsListComponent implements OnInit {

  agencyFilter  : string= "";
  clientFilter  : string= "";
  startDateFilter: string= "";
  endDateFilter: string= "";
  directorateFilter: string= "";

  directorates :any;

  selectedTab: string = 'new';
  locationFilter: number = null;
  searchCriteria: string = null;
  shiftsSummary: any;

  constructor(
    private router: Router,
    private shiftService: ShiftService,
    private statisticsService: StatisticsService,
    private storageService: StorageService,
    private toast : ToastrService
  ) { }

  ngOnInit(): void {
    this.getDirectorates();
    this.getStats();
  }

  selectTab(ref) {
    if(ref){
      this.selectedTab = ref;
    }
  }

  getStats(){
    this.statisticsService.getClientShiftsDashboard( this.storageService.decrypt(localStorage.getItem('clientId'))).subscribe(
      data => {
        this.shiftsSummary = data;
      }
    );
}



  newPage(){
    this.router.navigate(['/client/shifts/new']);
  }

  getDirectorates(){
    this.shiftService.getPaginatedShiftDirectorates(this.searchCriteria, this.locationFilter, 0, 100).subscribe(
      data => {
        // console.table(data.content);
        let array = data.content;
        array.sort(function(a, b){
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })

        this.directorates = array;
      },
      err => {




      }
    );
  }

  clearFilters(){
    this.agencyFilter     = "";
    this.clientFilter     = "";
    this.startDateFilter   = "";
    this.endDateFilter   = "";
    this.directorateFilter   = "";
  }

}
