import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-worker-container',
  templateUrl: './worker-container.component.html',
  styleUrls: ['./worker-container.component.css']
})
export class WorkerContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $("#sidebarToggle, #sidebarToggleTop").on("click", function (e) {
      $("body").toggleClass("sidebar-toggled"),
      $(".sidebar").toggleClass("toggled"),
      $(".sidebar").hasClass("toggled") && $(".sidebar .collapse").collapse("hide")
    });


    // $(".container-fluid").on("click", function (e) {
    //   $(".sidebar").addClass("toggled")
    // });


  }

}
