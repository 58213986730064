import { UsersService } from './../../shared/services/users.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  constructor(
    private router: Router,
    private toast: ToastrService,
    private userService: UsersService
  ) { }

  ngOnInit(): void {
  }

  sendEmail(){
    if(!$('#myEmail').val()){
      this.toast.warning('Enter Recovery Email');
      return false;
    }

    this.userService.resetPassword($('#myEmail').val()).subscribe(
      resp => {
        // console.log(resp);

        if(resp.message){
          this.toast.success(resp.message)
        }
        else{
          this.toast.success('Email sent.')
        }

        this.router.navigate(['/login']);
      }
    );

  }

  loginPage(){
    this.router.navigate(['/login']);
  }

}
