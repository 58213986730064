
<div class="">

  <section>
      <div class="col-12">
        <div class="  text-white">
          <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
            Patient Information
          </h5>
          <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </span>
        </div>
        <hr class="bg-black mt-0">

        <div class="row form-group ">
          <div class="mt-1 col-sm-12 col-md-4">
            <Label>Name of Patient</Label>
            <input type="text" maxlength="255"   disabled value="{{selectedJob?.patientName}}" class="form-control">
          </div>
          <!-- <div class="col-md-8"></div> -->

          <div class="mt-1 col-sm-12 col-md-4">
            <Label>Gender </Label>
            <input type="text" maxlength="255"   disabled value="{{selectedJob?.passengerGender}}" class="form-control">
          </div>
          <div class="mt-1 col-sm-12 col-md-4">
            <Label>Date of Birth</Label>
            <input type="text" maxlength="255"   disabled value="{{selectedJob?.pdob}}" class="form-control">
          </div>

          <div class="mt-1 col-sm-12 col-md-4">
            <Label>NHS Number </Label>
            <input type="text" maxlength="255"   disabled value="{{selectedJob?.nhs}}" class="form-control">
          </div>

          <div class="form-group col-6 col-sm-12 col-md-4">
            <label>Diagnosis</label>
            <input type="text"  maxlength="255" disabled value="{{selectedJob?.diagnosis??'None'}}" class="form-control" placeholder="Contact Number" >
          </div>
          <div class="mt-1 col-sm-12 col-md-4">
            <Label>Reason For Transport</Label>
            <select class="form-control" disabled value="{{selectedJob?.reasonForTransport}}">
              <option value="MENTAL_HEALTH_ACT">Mental Health ACT</option>
              <option value="CHILDREN_ACT">Children ACT</option>
              <option value="INFORMAL_PATIENT">Informal Patient</option>
            </select>
          </div>
          <div class="mt-1 col-12 col-md-4">
            <Label>Has patient been administered medication within 4 hours to transfer?</Label>
            <input type="text" disabled value="{{selectedJob?.medication??'None'}}"    class="form-control date">
          </div>
          <div class="mt-1 col-12 col-md-4">
            <Label>Physical health issues or concerns</Label>
            <input type="text" disabled value="{{selectedJob?.physicalHealth??'None'}}"   placeholder="" class="form-control date">
          </div>

          <div class="mt-1 col-12 col-md-4" >
            <Label>Any specific infection control concerns?</Label>
            <input type="text" disabled value="{{selectedJob?.infectionControl??'None'}}"   placeholder="" class="form-control date">
          </div>
          <div class="mt-1 col-12 col-md-4">
            <Label>Covid-19 Status in the last 48hrs?</Label>
            <input type="text" disabled value="{{selectedJob?.covid!=null?(selectedJob?.covid?'Positive':'Negative'):'Covid-19 Status in the last 48hrs not known'}}"   placeholder="" class="form-control date">
          </div>

          <div class="mt-1 col-12 col-md-4">
            <Label>Dietary requirements?</Label>
            <input type="text" disabled value="{{selectedJob?.offerFood??'Staff cannot offer food.'}}"   placeholder="" class="form-control date">
          </div>

          <div class="mt-1 col-12 col-md-4" >
            <Label>Allergies</Label>
            <input type="text" disabled value="{{selectedJob?.allergies??'No known allergies'}}"   placeholder="" class="form-control date">
          </div>
          <div class="mt-1 col-12 col-md-4">
            <Label>Present Medication</Label>
            <input type="text" disabled value="{{selectedJob?.pmeds??'None'}}"    class="form-control date">
          </div>

          <div class="mt-1 col-12 col-md-12" >
            <Label>Has the patient received a Rapid Tranquillisation/Sedation medication in the last 6hrs before transfer??</Label>
            <input type="text" disabled value="{{selectedJob?.rapidTranq??'None administered'}}"   placeholder="" class="form-control date">
          </div>


          <div class="col-12"></div>


     <h5 class="mt-2 mb-1">Current Risks</h5>

          <form [formGroup]="addForm" >
            <div class="row">
              <div class="row col-md-8 col-lg-8">
                <hr class="w-100 my-1">
               
                <div class="col-6">
                  <label class="ml-1" for=""> Assault Staff</label><br>
                  <textarea *ngIf="extremeRag.includes(addForm.value.assaultStaff)" formControlName="assaultStaffDesc" placeholder="Explanation" class="form-control">
                  </textarea>
                </div>
                <div class="col-6 row">
                    <div title="High" class="col-sm-2 px-1">
                      <button class="badge rounded-pill bg-primary">Signi</button>
                    </div>
                    <div title="High" class="col-sm-2 px-1">
                      <button class="badge badge-danger border-0">High</button>
                    </div>
                    <div title="High" class="col-sm-2 px-1">
                      <button class="badge rounded-pill bg-warning text-dark">Med</button>
                    </div>
                    <div title="High" class="col-sm-2 px-1">
                      <button class="badge rounded-pill bg-success">Low</button>
                    </div>
                    <div title="High" class="col-sm-2 px-1">
                      <button class="badge rounded-pill bg-secondary">Unknown</button>
                    </div>
                </div>

                <hr class="w-100 my-1">
                <div class="col-6">
                  <label class="ml-1" for=""> Physical Aggression</label>
                  <textarea *ngIf="extremeRag.includes(addForm.value.physicalAggression)" formControlName="physicalAggressionDesc" placeholder="Explanation" class="form-control">
                  </textarea>
                </div>
                
                <div class="col-6 row">
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-primary">Signi</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge badge-danger border-0">High</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-warning text-dark">Med</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-success">Low</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-secondary">Unknown</button>
                  </div>
              </div>
                <hr class="w-100 my-1">
                <div class="col-6">
                  <label class="ml-1" for="">Verbal aggression</label>
                  <textarea *ngIf="extremeRag.includes(addForm.value.verballyAggressive)" formControlName="verballyAggressiveDesc" placeholder="Explanation" class="form-control">
                  </textarea>
                </div>
                <div class="col-6 row">
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-primary">Signi</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge badge-danger border-0">High</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-warning text-dark">Med</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-success">Low</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-secondary">Unknown</button>
                  </div>
              </div>



                <hr class="w-100 my-1">
                <div class="col-6">
                  <label class="ml-1" for=""> Self Harm</label><br>
                  <textarea *ngIf="extremeRag.includes(addForm.value.selfHarm)" formControlName="selfHarmDesc" placeholder="Explanation" class="form-control">
                  </textarea>
                </div>
                <div class="col-6 row">
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-primary">Signi</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge badge-danger border-0">High</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-warning text-dark">Med</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-success">Low</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-secondary">Unknown</button>
                  </div>
              </div>



                <hr class="w-100 my-1">
                <div class="col-6">
                  <label class="ml-1" for=""> Self Neglect</label>
                  <textarea *ngIf="extremeRag.includes(addForm.value.selfNeglectDesc)" formControlName="verballyAggressiveDesc" placeholder="Explanation" class="form-control">
                  </textarea>
                </div>
                <div class="col-6 row">
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-primary">Signi</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge badge-danger border-0">High</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-warning text-dark">Med</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-success">Low</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-secondary">Unknown</button>
                  </div>
              </div>



                <hr class="w-100 my-1">
                <div class="col-6">
                  <label class="ml-1" for=""> Absconsion/AWOL</label>
                  <textarea *ngIf="extremeRag.includes(addForm.value.absconsionRisk)" formControlName="absconsionRiskDesc" placeholder="Explanation" class="form-control">
                  </textarea>
                </div>
                <div class="col-6 row">
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-primary">Signi</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge badge-danger border-0">High</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-warning text-dark">Med</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-success">Low</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-secondary">Unknown</button>
                  </div>
              </div>


                <hr class="w-100 my-1">
                <div class="col-6">
                  <label class="ml-1" for=""> Gender Issues</label>
                  <textarea *ngIf="extremeRag.includes(addForm.value.genderIssues)" formControlName="genderIssuesDesc" placeholder="Explanation" class="form-control">
                  </textarea>
                </div>
                <div class="col-6 row">
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-primary">Signi</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge badge-danger border-0">High</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-warning text-dark">Med</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-success">Low</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-secondary">Unknown</button>
                  </div>
              </div>


                <hr class="w-100 my-1">
                <div class="col-6">
                  <label class="ml-1" for=""> Racial Issues</label>
                  <textarea *ngIf="extremeRag.includes(addForm.value.racialIssues)" formControlName="racialIssuesDesc" placeholder="Explanation" class="form-control">
                  </textarea>
                </div>
                <div class="col-6 row">
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-primary">Signi</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge badge-danger border-0">High</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-warning text-dark">Med</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-success">Low</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-secondary">Unknown</button>
                  </div>
              </div>


                <hr class="w-100 my-1">
                <div class="col-6">
                  <label class="ml-1" for=""> Sexually Inappropriate Behaviour</label>
                  <textarea *ngIf="extremeRag.includes(addForm.value.sexuallyInappropriate)" formControlName="sexuallyInappropriateDesc" placeholder="Explanation" class="form-control">
                  </textarea>
                </div>
                <div class="col-6 row">
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-primary">Signi</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge badge-danger border-0">High</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-warning text-dark">Med</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-success">Low</button>
                  </div>
                  <div title="High" class="col-sm-2 px-1">
                    <button class="badge rounded-pill bg-secondary">Unknown</button>
                  </div>
              </div>


              </div>
              <div class="col-md-4 col-lg-4">
                <div class="form-group ">
                  <div class="">
                    <label>Can the person walk to the vehicle and step up into a vehicle unaided? </label>
                    <label *ngIf="selectedJob?.walk" for="walk"> &nbsp;Yes</label>&nbsp;
                    <label *ngIf="!selectedJob?.walk" for="walk"> &nbsp;No</label>
                    <input  *ngIf="!selectedJob?.walk" type="text" maxlength="255" disabled  [value]="selectedJob?.walkInfo" class="form-control" placeholder="No explanation given">
                  </div>
                  <div class="">
                    <label>Do you require the use of mechanical restraint/soft cuffs?</label>&nbsp;
                    <label *ngIf="selectedJob?.passengerRequiresRestraints" for="passengerRequiresRestraints"> &nbsp;Yes</label>&nbsp;
                    <label *ngIf="!selectedJob?.passengerRequiresRestraints" for="passengerRequiresRestraints"> &nbsp;No</label>
                    <textarea *ngIf="selectedJob?.passengerRequiresRestraints" disabled type="text" [value]="selectedJob?.reasonsForRestrains" placeholder="No reason for restraints" class="form-control" cols="70"></textarea>
                  </div>
                  <div class="">
                    <label>Is patient aware of the transport?&nbsp;
                      <label *ngIf="selectedJob?.isPassengerAwareOfTransport" for="isPassengerAwareOfTransport"> &nbsp;Yes</label>&nbsp;
                      <label *ngIf="!selectedJob?.isPassengerAwareOfTransport" for="isPassengerAwareOfTransport"> &nbsp;No</label>
                    </label>
                  </div>
                  <div class="">
                    <label>Total ward staff escorting the patient: </label>
                    <input  type="text" maxlength="255" disabled  [value]="selectedJob?.wardEscort" class="form-control" placeholder="Not specified">
                  </div>

                  <p>Other risks:</p>
                  <input type="text" maxlength="255" disabled  [value]="selectedJob?.otherRisks" class="form-control" placeholder="No other risks">

                  <br><a *ngIf="selectedJob?.riskDoc!=null" target="_blank" (click)="openDoc(selectedJob?.riskDoc)">View Risk Document</a><br>



                </div>
              </div>
            </div>

            <div class="mt-2 text-right">
              <button type="button" class="btn-danger text-right mr-1" (click)="prevTab()">Back</button>
              <button type="submit" class="btn-primary text-right " (click)="nextTab();">Next</button>
            </div>
          </form>
        </div>
      </div>
  </section>

</div>
