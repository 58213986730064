import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TransportService } from 'src/app/shared/services/transport.service';

@Component({
  selector: 'app-about-vehicle-tab',
  templateUrl: './about-vehicle-tab.component.html'
})
export class AboutVehicleTabComponent implements OnChanges {

  @Input('form') form: FormGroup;
  @Input('job') job: any;
  @Input('tabindex') tabindex: string; // Ensure tabindex is initialized
  @Output() formO = new EventEmitter<FormGroup>();
  @Output() tabindexO = new EventEmitter<string>();

  constructor(
    private toast: ToastrService,
    private transportService: TransportService,
    private storageService: StorageService,
    private agencyService: AgencyService,
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnChanges(): void {
    this.disableForm();
  }

  enableEdit() {
    this.form.enable();
  }

  disableForm() {
    this.form.disable();
  }

  ngOnInit(): void {}

  nextTab(){
    console.log(this.tabindex);
    this.tabindex = "docs";
    // ++this.tabindex;
    // if(this.tabindex > 4) this.tabindex = 4;
    this.tabindexO.emit(this.tabindex);
    this.formO.emit(this.form);
    // console.log();


  }

  prevTab(){
    // --this.tabindex;
    // if(this.tabindex < 1) this.tabindex = 1;
    // this.tabindexO.emit(this.tabindex);
    // this.formO.emit(this.form);
  }

  updateVehicle() {
    this.form.patchValue({
      agency: {id: this.form.value.agency.id }
    })
    this.transportService.updateVehicle(this.form.value).subscribe(
      data => { this.toast.success("Info updated"); }
    );
  }

  goBack() {
    this.prevTab();
  }

  goNext() {
    this.nextTab();
  }
}
