<main class="mt-5">

    <!-- Header part -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-4 text-left">
            <h3>View Applied Shift</h3>
            </div>
            <div class="col-12 col-sm-6 col-md-4 text-left">
            <!-- <div class="input-group">
                <input type="text" maxlength="255" class="form-control col-9 border-right-0" placeholder="Search Shift Id"
                aria-describedby="inputGroupPrepend2" required />
                <div class="input-group-prepend">
                <span class="input-group-text bg-transparent border-left-0" id="inputGroupPrepend2">
                    <i class="fa fa-search"></i>
                </span>
                </div>
            </div> -->
            </div>
            <div class="col-12 col-sm-6 col-md-4 text-md-right text-sm-left mt-2 mt-md-0">
                <!-- <button class="btn btn-success" (click)="newPage()">
                    <i class="fa fa-plus-circle"></i>&nbsp;&nbsp;Add Shift
                </button> -->
            </div>
        </div>
    </div>

    <!-- Content -->
    <section class="content mt-4">

        <div class="container-fluid">
            <div class="bg-modal card-body" *ngIf="shiftInfo">
                <div class="container">
                  <div class="row">
                    <div class="col-md-2 border-right">{{shiftInfo.shiftDate}} to {{shiftInfo.shiftEndDate}} <br> {{shiftInfo.shiftLocation}}</div>
                    <div class="col-md-2 border-right container">
                      <div class="row">
                        <div class="col-md-6 border-right">Starts <br> <b>{{shiftInfo.shiftStartTime}}</b> </div>
                        <div class="col-md-6">Ends <br> <b>{{shiftInfo.shiftEndTime}}</b></div>
                      </div>
                    </div>
                    <div class="col-md-3 border-right">
                      Assignment Code : <b>{{shiftInfo.assignmentCode}}</b> <br>
                      Gender <b class="ml-5">{{shiftInfo.gender}}</b>
                    </div>
                    <div class="col-md-5">
                      {{shiftInfo.notes}}
                    </div>
                  </div>
                </div>
            </div>
        </div>

        <div class="container-fluid card-body mt-3">
            <h5>Shift Applicants</h5>
            <h6 class="font-italic mb-2">
                <i class="fa fa-info-circle text-primary-custom"></i> &nbsp;&nbsp; NOTE: You can only approve 1 Applicant
            </h6>
            <table class="table table-striped mb-0 styled-table table-responsive-md text-center">
              <thead>
                  <tr class="text-center">
                      <th>Name</th>
                      <th>Jobs</th>
                      <th>Assignment Code</th>
                      <th>Agency Name</th>
                      <th *ngIf="userType != 'admin'">Actions</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let l of applicants;">
                      <td class="text-primary-custom" (click)="detailsPage(l.id)"><a href="/agency/workers/info/{{l.id}}">{{l.firstname}} {{l.lastname}}</a></td>
                      <td>{{l.assignmentName}}</td>
                      <td>{{l.assignmentCode}}</td>
                      <td>{{l.agencyName}}</td>
                      <td *ngIf="userType != 'admin'&& availability[l.id]">
                        <span class="mr-3 btn-success btn-sm pointer" (click)="approveWorker(l.id)">
                            Approve
                        </span>
                      </td>
                      <td *ngIf="userType != 'admin' && !availability[l.id]">
                        <span class="mr-3 btn-danger btn-sm pointer" >
                            Unavailable
                        </span>
                      </td>
                  </tr>
              </tbody>
            </table>
            <!-- <section class="pagination-container" *ngIf="locations">
              <div class="container-fluid">
                <div class="row m-0">
                  <div class="col-12 col-sm-12 col-md-5 text-left acontent-center">
                    Showing {{ (page - 1) * pageSize + 1 }} - {{ showin }} of {{ totalItems }}
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 text-right">
                    <button class="btn text-white" (click)="handlePageChange('prev')" [disabled]="first">
                      <i class="fa fa-caret-up"></i>&nbsp;&nbsp;Previous Page
                    </button>
                  </div>
                  <div class="col-12 col-sm-6 col-md-3 text-right pr-0">
                    <button class="btn text-white" (click)="handlePageChange('next')" [disabled]="last">
                      Next Page&nbsp;&nbsp;<i class="fa fa-caret-down"></i>
                    </button>
                  </div>
                </div>
              </div>
            </section>   -->
          </div>

    </section>

</main>
