import { ShiftsDirectoratesComponent } from './shifts-directorates/shifts-directorates.component';
import { ShiftsLocationsComponent } from './shifts-locations/shifts-locations.component';
import { ShiftsListComponent } from './shifts-list/shifts-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TypesComponent } from './types/types.component';
import { AppliedShiftComponent } from 'src/app/client/shifts/applied-shift/applied-shift.component';

const routes: Routes = [
  { path: '', redirectTo: 'list', pathMatch: 'full' },
  { path: 'list', component: ShiftsListComponent },
  { path: 'applied/:id', component: AppliedShiftComponent },
  { path: 'locations', component: ShiftsLocationsComponent },
  { path: 'directorates', component: ShiftsDirectoratesComponent },
  { path: 'types', component: TypesComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShiftsRoutingModule { }
