import { ClientService } from '../../../shared/services/client.service';
import { AuthService } from '../../../shared/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { WorkerService } from '../../../shared/services/worker.service';
import { FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';


import { Component, OnInit, ElementRef, ViewChild, Inject, ViewEncapsulation} from '@angular/core';
import { Router } from '@angular/router';
import { AssignmentCodeService } from 'src/app/shared/services/assignment-code.service';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { UsersService } from 'src/app/shared/services/users.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { NgSelectModule } from '@ng-select/ng-select';

import {MatChipInputEvent} from '@angular/material/chips';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatCalendarCellClassFunction, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { ApplicantListDialog } from './dialog/applicant-list-dialog.component';




declare var $: any;
export interface Fruit {
  name: string;
}
export interface DialogData {
  animal: string;
  name: string;
}

export interface allWorkers {
  email: string;
  name: string;
}

@Component({
  selector: 'app-workers-list',
  templateUrl: './applicant-list.component.html',
  styleUrls: ['./applicant-list.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ApplicantListComponent implements OnInit {

  bookedDates = [];
  unavailableDates = [];
  animal: string;
  name: string;


  visible = true;
  selectable = true;
  removable = true;


  workers: any;
  allWorkers: any;
  assCodes: any;
  selectedWorker: any;
  workerId: number;

  addForm: FormGroup;
  editForm: FormGroup;

  page = 1;
  pageSize = 25;
  totalItems = 0;
  showin = 0;
  first: boolean;
  last: boolean;

  userType: string;
  new: boolean = true;
  existing: boolean;
  formArea: boolean;
  query = new FormControl('');
  currentBookings: any;
  bookedShifts: any;
  searchResult: boolean;

  searchCriteria:any;
  activeWorker: any;
  activeAgencies: any;
  agencyId: any;

  constructor(
    private router: Router,
    private workerService: WorkerService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private assignmentCodeService: AssignmentCodeService,
    private authService: AuthService,
    private agencyService: AgencyService,
    private usersService: UsersService,
    private clientService: ClientService,
    private storageService: StorageService,
    public dialog: MatDialog
  ) {
    this.userType = authService.getUserType();

   }

  ngOnInit(): void {

    this.workerId = this.storageService.decrypt(localStorage.getItem('agentId'));
    this.getApplicants();
    this.getAssCodes();
    this.agencyId = this.storageService.decrypt(localStorage.getItem('agentId'));


    this.editForm = this.fb.group({
      id: 0
    });


  }

  formInit(modal){
    this.addForm = this.fb.group({
      agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
      assignmentCode: ['', Validators.required],
      email: ['', Validators.required],
      firstname: ['', Validators.required],
      dob: ['', Validators.required],
      gender: ['', Validators.required],
      nationality: ['', Validators.required],
      lastname: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      username: ['', Validators.required]
    });

    this.new = true;
    this.existing = false;

    this.openModal(modal);
  }


  detailsPage(ref){
    if(ref){
      this.router.navigate([`/${this.userType}/workers/info/${ref}`]);
    }
  }
  detailsPageClient(ref){
    if(ref){
      this.router.navigate([`/${this.userType}/workers/info/${this.activeWorker.id}/${ref}`]);
    }
  }

  handlePageChange(event) {

    if(event == 'next'){
      this.page += 1;
    }
    else if(event == 'prev' && this.page != 1){
      this.page -= 1;
    }

    this.getApplicants();
  }

  getApplicants(){
    // console.log(this.userType);

    if(this.userType == 'admin'){
      this.workerService.getPaginatedApplicants(this.page - 1, this.pageSize).subscribe(
        data => {
          // console.table(data.content);
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }
          let array = data.content;
          array.sort(function(a, b){
              if(a.firstname < b.firstname) { return -1; }
              if(a.firstname > b.firstname) { return 1; }
              return 0;
          })

          this.workers = array;
        }
      );
    }
    else if(this.userType == 'agency'){
      this.agencyService.getPaginatedAgencyApplicants(this.page - 1, this.pageSize, this.storageService.decrypt(localStorage.getItem('agentId'))).subscribe(
        data => {
          console.table(data.content);
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }
          let array = data.content;
          array.sort(function(a, b){
              if(a.firstname < b.firstname) { return -1; }
              if(a.firstname > b.firstname) { return 1; }
              return 0;
          })

          this.workers =array;
        }
      );
    }
    else{
      this.clientService.getPaginatedClientWorkers(this.page - 1, this.pageSize, this.storageService.decrypt(localStorage.getItem('clientId'))).subscribe(
        data => {
          // console.table(data.content);
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }
          let array = data.content;
          array.sort(function(a, b){
              if(a.firstname < b.firstname) { return -1; }
              if(a.firstname > b.firstname) { return 1; }
              return 0;
          })
          this.workers = array;
        }
      );
    }
  }

  searchApplicants(searchCriteria){

    if(!searchCriteria.replace(/[\s]/g, '')){
      this.ngOnInit();
    }else{
      this.workerService.searchAgencyApplicants(searchCriteria, this.agencyId, this.page - 1, this.pageSize).subscribe(
        data => {
          // console.table(data.content);
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;
          this.searchResult = true;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }
          let array = data.content;
          array.sort(function(a, b){
              if(a.firstname < b.firstname) { return -1; }
              if(a.firstname > b.firstname) { return 1; }
              return 0;
          })

          this.workers = array;
        }
      );
    }

  }
  getAllWorkers(){
    this.workerService.getPaginatedApplicants(0, 100).subscribe(
      data => {
        // console.table(data.content);

        this.allWorkers = data.content;
      }
    );
  }

  getAssCodes(){
    this.assignmentCodeService.getPaginatedAssignmentCodes(0, 100).subscribe(
      data => {
        let assCodes = data;
        if(data.content)
        {
          assCodes = data.content;


        }
        this.assCodes = assCodes;
      }
    );
  }

  getWorkerAgencies(id){


    this.workerService.getPaginatedWorkerAgencies(0, 100, id).subscribe(
      data => {
        // console.table(data.content);

        this.activeAgencies = data.content;
      },
      err => {

      }
    );

}

  openModal(modal, i?) {
    if(i){
      this.getWorkerAgencies(i.id);
      // this.getWorkerBookedDate(i);
      // this.getWorkerUnavailableDate(i);
      this.dateClass();
    };
    this.activeWorker = i;
    this.modalService.open(modal, {centered: true, size: 'lg'});
  }

  openCalander(modal, i) {
    this.getWorkerAvailableDate(modal,i);
  }

  openDialog(): void {
    this.getAllWorkers()
    const dialogRef = this.dialog.open(ApplicantListDialog, {
      width: '900px',
      data: {name: this.name, animal: this.animal},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.animal = result;
    });
  }

  newWorker(form: FormGroup){
    // console.table(form.value);

    if(form.valid){
      this.workerService.createWorker(form.value).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          this.getApplicants();
          this.toast.success('Applicant Added Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }
  }

  loadWorkerInfo(ele){
    let ref = ele.target.value;

    // console.log(ref);
    if(ref){
      if(ref != 'NEW'){
        let r;

        this.allWorkers.forEach(w => {
          if(w.id == ref){
            r = w;
            this.workerId = w.id;
          }
        });

        this.addForm = this.fb.group({
          agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
          assignmentCode: [r.assignmentCodeId, Validators.required],
          email: [r.email, Validators.required],
          firstname: [r.firstname, Validators.required],
          dob: [r.dob, Validators.required],
          gender: [r.gender, Validators.required],
          nationality: [r.nationality, Validators.required],
          lastname: [r.lastname, Validators.required],
          phoneNumber: [r.phoneNumber, Validators.required],
          username: [r.username, Validators.required]
        });

        this.new = false;
        this.existing = true;
        this.formArea = true;
      }
      else{
        this.addForm = this.fb.group({
          agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
          assignmentCode: ['', Validators.required],
          email: ['', Validators.required],
          firstname: ['', Validators.required],
          dob: ['', Validators.required],
          gender: ['', Validators.required],
          nationality: ['', Validators.required],
          lastname: ['', Validators.required],
          phoneNumber: ['', Validators.required],
          username: ['', Validators.required]
        });

        this.new = true;
        this.existing = false;
        this.formArea = false;
      }
    }

  }

  updateWorker(form: FormGroup){
    // console.table(form.value);

    if(form.valid){
      this.workerService.updateWorker(form.value).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          this.getApplicants();
          this.toast.success('Applicant Updated Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }
  }

  loadWorker(ref, modal, edit){
    if(ref){
      this.workers.forEach(r => {
        if(r.id == ref){
          this.selectedWorker = r;
        }
      });

      if(edit && this.selectedWorker){
        this.editForm = this.fb.group({
          agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
          assignmentCodeId: [this.getAssCodeId(this.selectedWorker.assignmentName), Validators.required],
          email: [this.selectedWorker.email, Validators.required],
          firstname: [this.selectedWorker.firstname, Validators.required],
          gender: [this.selectedWorker.gender, Validators.required],
          lastname: [this.selectedWorker.lastname, Validators.required],
          phoneNumber: [this.selectedWorker.phoneNumber, Validators.required],
          username: [this.selectedWorker.username, Validators.required],
          status: [this.selectedWorker.status],
          id: [this.selectedWorker.id, Validators.required]
        });
      }
    }

     if(modal){
      this.openModal(modal);
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  checkEmail(ele){
    // console.log(ele);

    var typed = ele.target.value;

    if(this.validateEmail(typed)){
      $(ele.target).removeClass('is-invalid');
      $(ele.target).addClass('is-valid');
    }
    else{
      $(ele.target).removeClass('is-valid');
      $(ele.target).addClass('is-invalid');
    }
  }

  getAssCodeId(ref){
    let lid;

    if(ref){
      this.assCodes.forEach(r => {
        if(r.name == ref){
          lid = r.id;
        }
      });
    }

    return lid;
  }

  activateUser(ref, enable){

    this.usersService.changeWorkerStatus(ref, enable).subscribe(
      resp => {
        // console.log(resp);
        this.toast.success('User Status Changed.')
        this.getApplicants();
      }
    );

  }

  linkWorker(){
    // console.log(this.workerId);
    this.workerService.linkWorkerToAgent(this.workerId, this.storageService.decrypt(localStorage.getItem('agentId'))).subscribe(
      resp => {
        // console.table(resp);
        this.modalService.dismissAll();
        this.getApplicants();
        this.toast.success('Applicant Linked Successfully');
      }
    );

  };



  getWorkerAvailableDate(modal,workerId){
    this.workerService.getWorkerAvailableDate(workerId).subscribe(
      data => {
        console.log(data)
        let unavailableDates = [];
        data.content.map(item => {
          const [day, month, year] = item.date.split('-');
          unavailableDates.push(["20"+year, month, day].join('-'));

        })
        console.log(unavailableDates);
        this.unavailableDates = unavailableDates ;// ['2022-10-05','2022-10-17','2022-10-28'];// delete this devip
        //this.bookedDates = ['2022-10-01','2022-10-11','2022-10-23'];// delete this devip
        //this.unavailableDates = data.unavailableDates; // uncomment this devip
        //this.bookedDates = data.bookedDates; // uncomment this devip
        this.openModal(modal, workerId);
      }
    );
  };

  formatDate(date){
    const myArray = date.split("/");
    if(myArray.length>=2){
      let formateddate =myArray[2] + "-" +myArray[1] +"-"+myArray[0] ;
      return formateddate;
    }else{
      return date;
    }

  };


  setDates(){
    this.currentBookings = [];
    this.bookedShifts.forEach(shift => {
      let bookDate = new Date(this.formatDate(shift.shiftDate))
      bookDate.setHours(0);
      let strDate = bookDate.toString();
      this.currentBookings.push(strDate);
      console.log('this.currentBookings',this.currentBookings)
    });
  };

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      date = new Date(date);
      const booked = this.currentBookings
        .map(strDate => new Date(strDate))
        .some(d => {
          return d.getDate() === date.getDate() && d.getMonth() === date.getMonth() && d.getFullYear() === date.getFullYear()
        });
      // const unavailable = this.unavailableDates
      // .map(strDate => new Date(strDate))
      // .some(d => {
      //   return d.getDate() === date.getDate() && d.getMonth() === date.getMonth() && d.getFullYear() === date.getFullYear()
      // });
      //return booked ? 'booked-class' : unavailable ? 'unavailable-class' : "available-class";
      return booked ? 'booked-class' : "available-class";
    };
  };

}

