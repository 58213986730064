<div class="">

  <div class="">
    <form  [formGroup]="addBankForm" (ngSubmit)="addWorkerBank(addBankForm)" >
      <!-- <form [formGroup]="addForm" (ngSubmit)="newForm(addForm)"> -->
        <div class="bg-modal card-body">
          <div class="container-fluid">
          <div class="col-12 text-left">
            <h3>Forms/ Bank Details</h3>
          </div>
          <section>
            <div class="col-12" >
              <!-- <i class="bi bi-info-circle"></i>
              <span class="ml-2 text-info-custom">You must complete all sections of the application form electronically. We will use this form to help us decide on your suitability for the post so please make sure it is accurate and complete. CVs in isolation will not be accepted.
              </span> -->
              <div class="  text-white  ">
                <h5 class=" bg-main border radius-3  py-2 px-4 w-80" style="border-radius: 5px;">
                  Bank Details</h5>

              </div>
              <hr class="bg-black mt-0">

              <div class="row form-group">
                  <div class="mt-1 col-sm-12 col-md-6">
                    <Label><b>NAME ON Account</b></Label>
                    <input type="text" maxlength="255" formControlName="name" placeholder="" class="form-control">
                  </div>
              </div>

                <div class="row form-group">
                <div class="mt-1 col-sm-12 col-md-6">
                  <Label><b>Bank</b></Label>
                  <input type="text" maxlength="255" formControlName="bank" placeholder="" class="form-control">
                </div>
               </div>

               <div class="mt-0 col-sm-12 col-md-6">
                <Label><b>Account Number</b></Label>
                <div class="row">
                  <input type="text" maxlength="255" formControlName="account1" placeholder="" class="form-control col-2" maxlength="3">
                  <i class="fa fa-minus m-auto"></i>
                  <input type="text" maxlength="255" formControlName="account2" placeholder="" class="form-control col-2" maxlength="3">
                  <i class="fa fa-minus m-auto"></i>
                  <input type="text" maxlength="255" formControlName="account3" placeholder="" class="form-control col-2" maxlength="2">
                </div>

              </div>


                <div class="mt-0 col-sm-12 col-md-6">
                  <Label><b>Sort Code</b></Label>
                  <div class="row">
                    <input type="text" maxlength="255" formControlName="code1" placeholder="" class="form-control col-2" maxlength="3">
                    <i class="fa fa-minus m-auto"></i>
                    <input type="text" maxlength="255" formControlName="code2" placeholder="" class="form-control col-2" maxlength="3">
                    <i class="fa fa-minus m-auto"></i>
                    <input type="text" maxlength="255" formControlName="code3" placeholder="" class="form-control col-2" maxlength="2">
                  </div>

                </div>


                <!-- <div class="mt-1 col-12 col-sm-12 col-md-12">
                  <p>Upload signature below to authorise payment through this account.</p>
                </div> -->



            </div>

        </section>


        </div>
      </div>
      <div align="right" class="mt-3 mx-4">
        <button type="submit" class="btn btn-danger btn-sm mr-3" >
        <!-- <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()"> -->
          Save
        </button>
      </div>
    </form>
  </div>
</div>
