<main class="mt-5">

  <!-- header part -->
  <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 text-left" *ngIf="worker">
          <span class="text-decoration-none" style="font-size: xx-large;"><b>{{worker.firstname}} {{worker.lastname}}</b> Details</span>
        </div>
        <div class="col-12 col-sm-6 col-md-4 text-md-right text-sm-left mt-2 mt-md-0">

        </div>
      </div>
  </div>

  <!-- content -->
  <section class="content mt-4">
      <div class="container-fluid">
          <div class="row" *ngIf="worker">
              <div class="col-md-5 col-lg-4">
                  <div class="card border-0">
                      <div class="text-center bg-main"  >
                          <img class="card-img-top card-img-bottom w-75  mt-2 mb-3" [src]="worker.profilePic" onError="this.src='assets/images/Placeholder_500x500.jpg'" alt="worker pic">
                          <h5 class="text-white text-bold bold">{{worker.firstname}} {{worker.lastname}}</h5>
                      </div>
                      <div class="w-100 ">
                        <h6 class="text-bold mt-2" style="color: #231E5C;">Contact Information</h6>
                        <section style="background-color: rgb(221, 221, 221);" class="p-1 pr-2">
                              <i class="fa fa-phone mt-2" ></i>&nbsp;&nbsp;{{worker.phoneNumber}} <br>
                              <div><i class="fa fa-envelope mt-2" style=" overflow-wrap: anywhere">&nbsp;&nbsp;<small class="text-bold" style="font-family: 'roboto_regular';">{{worker.email}}</small></i><span></span> </div>
                              <img src="assets/images/svg/location.svg" alt="location icon">&nbsp;&nbsp;&nbsp;{{worker.address}} <br>
                          </section>
                      </div>
                      <div class="w-100 ">
                        <h6 class="text-bold mt-2" style="color: #231E5C;">Personal Details</h6>
                        <section style="background-color: rgb(221, 221, 221);" class="p-1 pr-2">
                              <small class="mt-2 text-bold" >Employee Number </small>&nbsp;&nbsp; {{worker.employmentNumber}}<br>
                              <!-- <small class="mt-2 text-bold" >Nationality </small>&nbsp;&nbsp; {{worker.nationality?? ""}}<br> -->
                              <small class="mt-2 text-bold" >Date of Birth </small>&nbsp;&nbsp; {{worker.dob}}<br>
                              <small class="mt-2 text-bold" >Gender </small>&nbsp;&nbsp; {{worker.gender}}<br>
                        </section>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>

  <!-- Add Training & Qualifications Modal -->
  <ng-template class="modal fade" #add let-modal>
    <div class="">
      <div class="modal-header bg-main text-white">
        <h5 class="modal-title text-center mx-4 w-100">Add Training</h5>
        <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
          (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </span>
      </div>
      <div class="modal-body">
        <form [formGroup]="addTrainingForm" (ngSubmit)="addTraining(addTrainingForm)">
          <input type="hidden" formControlName="agencyId" value="{{agencyId}}" />
          <input type="hidden" formControlName="workerId" value="{{workerId}}" />
          <input formControlName="code" type="hidden"  placeholder="Code"
                      class="form-control">
          <input formControlName="code" type="hidden"  placeholder="Code"
                      class="form-control">
          <input type="hidden" formControlName="serviceId" value="1" />
          <div class="bg-modal card-body">
            <div class="container-fluid">
              <div class="">
                <div class="">
                  <i class="bi bi-info-circle"></i>
                  <span class="ml-2">Training</span>
                  <hr class="bg-black mt-0">
                </div>
                <!-- <div class="">
                  <div class="form-group">
                    <label for="" class="modal-label">Name</label>
                    <input formControlName="name" type="hidden"  placeholder="Name"
                      class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="" class="modal-label">Code</label>
                    <input formControlName="code" type="hidden"  placeholder="Code"
                      class="form-control">
                  </div>
                </div> -->
                <div class="">
                  <div class="form-group">
                    <select formControlName="trainingId" class="form-control w-content">
                      <option value="" selected disabled>Select training</option>
                      <option *ngFor="let training of trainingAll;" value="{{training.id}}">{{training.name}}</option>
                    </select>
                  </div>
                  <!-- <div class="form-group">
                    <label for="" class="modal-label">DESCRIPTION</label>
                    <input formControlName="description" type="text"  placeholder="Description"
                      class="form-control">
                  </div> -->
                </div>
                <div class="">
                  <div class="form-group">
                    <label for="" class="modal-label">Issue Date</label>
                    <input formControlName="trainingDate" type="date"  placeholder="Issue Date"
                      class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="" class="modal-label">ExpiryDate</label>
                    <input formControlName="trainingExpiry" type="date"  placeholder="ExpiryDate"
                      class="form-control">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div align="right" class="mt-3">
            <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
              Cancel
            </button>
            <button type="submit" class="btn btn-sm btn-success">
              Add Training
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>

    <!-- Add Note Modal -->
    <ng-template class="modal fade" #addNoteModel let-modal>
      <div class="">
        <div class="modal-header bg-main text-white">
          <h5 class="modal-title text-center mx-4 w-100">Add NOTE</h5>
          <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </span>
        </div>
        <div class="modal-body">
          <form [formGroup]="addNoteForm" (ngSubmit)="addNote(addNoteForm)">
            <div class="bg-modal card-body">
              <div class="container-fluid">
                <div class="">
                  <div class="">
                    <span class="ml-2">NOTE</span>
                    <hr class="bg-black mt-0">
                  </div>
                  <div class="">
                    <div class="form-group">
                      <input formControlName="note" class="border border-primary rounded-3" type="text">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div align="right" class="mt-3">
              <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
                Cancel
              </button>
              <button type="submit" class="btn btn-sm btn-success">
                Add NOTE
              </button>
            </div>
          </form>
        </div>
      </div>
    </ng-template>

     <!-- Add Jobs Modal -->
    <ng-template class="modal fade" #addJobModel let-modal>
      <div class="">
        <div class="modal-header bg-main text-white">
          <h5 class="modal-title text-center mx-4 w-100">Add Job</h5>
          <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </span>
        </div>
        <div class="modal-body">
          <form [formGroup]="addJobForm" (ngSubmit)="addJob(addJobForm)">
            <div class="bg-modal card-body">

              <div class="container-fluid">
                <div class="">
                  <div class="">
                    <div class="form-group">
                      <select formControlName="assignmentCodeId" class="form-control">
                        <option value="" disabled>Select Assignment Code</option>
                        <option *ngFor="let assignmentCode of assignmentCodes;" value="{{assignmentCode.id}}">{{assignmentCode.name}}</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <select formControlName="paymentMethodId" class="form-control">
                        <option value="" disabled>Select Payment Method</option>
                        <option *ngFor="let paymentMethod of paymentMethods;" value="{{paymentMethod.id}}">{{paymentMethod.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div align="right" class="mt-3">
              <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
                Cancel
              </button>
              <button type="submit" class="btn btn-sm btn-success">
                Add Job
              </button>
            </div>
          </form>
        </div>
      </div>
    </ng-template>
</main>
