import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'src/app/shared/services/storage.service';
import { environment } from 'src/environments/environment';
import jspdf, { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { FormsService } from 'src/app/shared/services/forms.service';
import { HttpResponse } from '@angular/common/http';
import { FileService } from 'src/app/shared/services/file.service';
import { WorkerService } from 'src/app/shared/services/worker.service';

@Component({
  selector: 'app-worker-compliance-modal',
  templateUrl: './worker-compliance-modal.component.html',
  styleUrls: ['./worker-compliance-modal.component.css']
})

export class WorkerComplianceModalComponent implements OnInit {
  public userType: string;
  workerId: number;
  agencyId: number;
  worker: any;
  workerInfo: any;
  workerProperty: any;
  jobCounts: any;
  trainings:any;
  trainingAll:any;
  jobs: any;
  forms: any;
  fitToWork:any;
  complianceInformation:any;
  notes:any;
  editInfoForm: FormGroup;
  agencyReviewForm: FormGroup;
  addTrainingForm: FormGroup;
  addComplianceForm: FormGroup;
  updateTrainingForm: FormGroup;
  updateComplianceForm: FormGroup;
  complianceInformationForm: FormGroup;
  addNoteForm: FormGroup;
  addJobForm: FormGroup;
  edit = false;
  edit2 = false;
  editCompliance = false;
  assignmentCodes = [{id:1,name:'001 Cleaner'},{id:2,name:'002 Driver'},{id:1,name:'003 Nurse'}];
  paymentMethods = [{id:1,name:'Paye'},{id:2,name:'Umbrella'},{id:1,name:'Pvt. Ltd'}];
  services = [];
  activeTraining: any;
  complianceAll: any;
  compliances: any;
  docLink: string;
  related: boolean;
  restriction: boolean;
  dbs: boolean;
  application: any;

  applicationDetails: any;
  bankingDetails: any;
  hmrcDetails: any;
  occupationalDetails: any;


  counter: number
  pdf: jsPDF
  complianceDoc: any;
  //trainingOptions = [{id:1,name:'Training 1'},{id:2,name:'Training 2'},{id:1,name:'Training 3'}];

  constructor(
    private activateRoute: ActivatedRoute,
    private workerService: WorkerService,
    private authService: AuthService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private fileService: FileService,
    private fb: FormBuilder,
    private formsService: FormsService,
    private storageService: StorageService
  ){
    this.userType = authService.getUserType();
    if(localStorage.getItem('agentId') != null){
      this.complianceInformationForm = this.fb.group({
        workerId: ['', Validators.required],
        agencyId: [this.storageService.decrypt(localStorage.getItem('agentId')), Validators.required]
      });
    }

    this.addTrainingForm = this.fb.group({

      agencyId: [this.agencyId,],
      workerId: [this.workerId,],
      name: ['',],
      code: ['',],
      serviceId: ['', ],
      trainingId: ['', ],
      trainingDate: ['', Validators.required],
      trainingExpiry: ['', Validators.required],
    });

    this.addComplianceForm = this.fb.group({
      agencyId: [this.agencyId,],
      workerId: [this.workerId,],
      name: ['',],
      code: ['',],
      // description: ['',],
      serviceId: ['', ],
      complianceId: ['', ],
      complianceDate: ['', Validators.required],
      complianceExpiry: ['', Validators.required],
    });

    this.updateTrainingForm = this.fb.group({
      id: [Validators.required ],
      comment: [''],
      status: [Validators.required],
      agencyId: [Validators.required],
      workerId: [Validators.required],
    });

    this.updateComplianceForm = this.fb.group({
      id: [Validators.required ],
      comment: [''],
      status: [Validators.required],
      agencyId: [Validators.required],
      workerId: [Validators.required],
    });


   }

  ngOnInit(): void {
    this.agencyId = this.storageService.decrypt(localStorage.getItem('agentId'));

    if(localStorage.getItem('workerId') != null){
      this.workerId = +localStorage.getItem('workerId');
    }else{
      this.activateRoute.params.subscribe((params) => {
        this.workerId = params["id"]
      });
    }

    this.getAllCompliances();

    if(!this.agencyId){
      this.activateRoute.params.subscribe((params) => {
        this.agencyId = params["agencyId"];
      });

    }
    this.getAllTrainings();
  }





  dismissModal(){
    this.modalService.dismissAll();
  }

  getAllTrainings(){
    this.workerService.getAllTrainings().subscribe(
      data => {
        this.trainingAll = data;
      }
    );
  }


  addTraining(form: FormGroup){
    if(this.complianceDoc.size>=1048576){
      this.toast.warning("Maximum upload file size is 1mb")
    }

    if(form.valid){
      form.value.workerId =this.workerId;
      form.value.agencyId =this.agencyId;

      this.workerService.addTraining(form.value, this.complianceDoc).subscribe(
        resp => {
          this.closeModals();
          this.toast.success('Worker Training Added Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  updateTraining(form: FormGroup, status: string){
    form.value.id =this.activeTraining.id ;
    form.value.status = status ;
    form.value.workerId = this.workerId ;
    form.value.agencyId = this.agencyId ;
    console.log(form.value);
    if(form.valid){

      this.workerService.updateTraining(form.value).subscribe(
        resp => {
          this.closeModals();
          this.toast.success('Worker Training Updated Successfully');
        }
      );
    }
    else{
      console.log(form.value);
      this.toast.warning('Missing Required Info.');
    }
  }

  updateCompliance(form: FormGroup, status: string){
    form.value.id =this.activeTraining.id ;
    form.value.status = status ;
    form.value.workerId = this.workerId ;
    form.value.agencyId = this.agencyId ;
    console.log(form.value);
    if(form.valid){

      this.workerService.updateCompliance(form.value).subscribe(
        resp => {
          this.closeModals();
          this.toast.success('Worker Compliance Updated Successfully');
        }
      );
    }
    else{
      console.log(form.value);
      this.toast.warning('Missing Required Info.');
    }
  }


  openModal(modal){
    this.modalService.open(modal, {centered: true});
  };

  selectedTraining(training){
    this.activeTraining = training;
  };

  closeModals(){
    this.modalService.dismissAll();
  };




  formatDate(date){
    const myArray = date.split("/");
    if(myArray.length>=2){
      let formateddate =myArray[2] + "-" +myArray[1] +"-"+myArray[0] ;
      return formateddate;
    }else{
      return date;
    }
  }


  onChange(event) {
    this.complianceDoc = event.target.files[0];
    console.log(this.complianceDoc);
    console.log(this.complianceDoc.size);
  }


  uploadCompliance(compliance){
    console.log(this.complianceDoc);
    if(this.complianceDoc.size>=1048576){
      this.toast.warning("Maximum upload file size is 1mb")
    }
    else {
    this.fileService.workerUploadCompliance(this.complianceDoc,compliance , this.workerId).subscribe(
      event => {
        if(event instanceof HttpResponse) {

        };
        this.toast.info("Sucessfully uploaded.");
        this.modalService.dismissAll();
      },
    );}
  }

  getAllCompliances(){
    this.workerService.getAllCompliances().subscribe(
      data => {
        this.complianceAll = data;
      }
    );
  }


  addWorkerCompliance(form: FormGroup){
    if(this.complianceDoc.size>=1048576){
      this.toast.warning("Maximum upload file size is 1mb")
    }

    form.value.serviceId = 1;
    form.value.agencyId =1;
    if(form.valid){
      form.value.workerId =this.workerId ;
      this.workerService.addCompliance(form.value, this.complianceDoc).subscribe(
        resp => {
          this.closeModals();
          this.toast.success('Worker Compliance Added Successfully');

          console.log(resp);
          this.uploadCompliance(resp.id);
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }



}
