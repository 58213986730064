import { JobsFilterPipe } from './jobs-filter.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { JobsRoutingModule } from './jobs-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/shared/pipes.module';
import { AgencyJobsListComponent } from './agency-jobs-list/agency-jobs-list.component';
import { JobsTableComponent } from './agency-jobs-list/components/jobs-table/jobs-table.component';
import { VehicleComponent } from './vehicle/vehicle.component';
import { TransportInfoModalComponent } from './agency-jobs-list/components/info-modal/transport-info-modal';
import { CreateTransportJobComponent } from 'src/app/client/transport/create-transport-job/create-transport-job.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DeleteTransportModalComponent } from './agency-jobs-list/components/delete-transport/delete-transport-modal';


@NgModule({
  declarations: [AgencyJobsListComponent,     TransportInfoModalComponent,DeleteTransportModalComponent,
     JobsTableComponent,   VehicleComponent, CreateTransportJobComponent,
       JobsFilterPipe],
  imports: [
    CommonModule,
    JobsRoutingModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    FormsModule,
    PipesModule,
    NgSelectModule
  ],
  exports: [
    TransportInfoModalComponent,
    DeleteTransportModalComponent,
    JobsTableComponent,
    CreateTransportJobComponent
  ],
  providers: [DatePipe]
})
export class AgencyJobsModule { }
