<form [formGroup]="form" (ngSubmit)="updateCleaningCheck()">
  <h5>Daily Cleaning Schedule</h5>

  <div class="card p-2 bg-grey btn-group">
    <p>
      This cleaning schedule should be completed after <span class="text-bold"> EACH</span> patient transfer.
      Tasks must be completed in order as listed. <span class="text-bold"> Bioguard Disinfectant</span> surface spray must
      be used for cleaning all surfaces. <span class="text-bold">Clinell Wipes</span>
      should be used to clean all door handles and equipment.
    </p>
  </div>

  <div class="row mt-3">
    <div class="col-6">
      <h5>Description</h5>
    </div>
    <div class="col-6 text-right">
      <h5>Completed</h5>
    </div>
    
    <div class="btn-group col-12">
      <label style="align-self: center;">Remove any visible litter from vehicle</label>
      <input type="checkbox" formControlName="litter" class="form-control ml-auto" style="width: 20px;">
    </div>
    <div class="col-12">
      <hr class="w-100">
    </div>

    <div class="btn-group col-12">
      <label style="align-self: center;">Clean all hard surfaces</label>
      <input type="checkbox" formControlName="hardSurface" class="form-control ml-auto" style="width: 20px;">
    </div>
    <div class="col-12">
      <hr class="w-100">
    </div>

    <div class="btn-group col-12">
      <label style="align-self: center;">Clean all seats including base & rails</label>
      <input type="checkbox" formControlName="seats" class="form-control ml-auto" style="width: 20px;">
    </div>
    <div class="col-12">
      <hr class="w-100">
    </div>

    <div class="btn-group col-12">
      <label style="align-self: center;">Clean and check if all equipment is working (note any concerns in "Notes")</label>
      <input type="checkbox" formControlName="equipment" class="form-control ml-auto" style="width: 20px;">
    </div>
    <div class="col-12">
      <hr class="w-100">
    </div>

    <div class="btn-group col-12">
      <label style="align-self: center;">Ensure hand sanitizer dispenser is full</label>
      <input type="checkbox" formControlName="sanitizer" class="form-control ml-auto" style="width: 20px;">
    </div>
    <div class="col-12">
      <hr class="w-100">
    </div>

    <div class="btn-group col-12">
      <label style="align-self: center;">Clean cell area (if applicable)</label>
      <input type="checkbox" formControlName="cellArea" class="form-control ml-auto" style="width: 20px;">
    </div>
    <div class="col-12">
      <hr class="w-100">
    </div>

    <div class="col-12 mt-4 btn-group">
      <h5 style="align-self: center;">Notes</h5>
    </div>
    <div class="col-12">
      <textarea name="form-control w-100" formControlName="notes" style="width:100%" placeholder="Enter Description"></textarea>
    </div>

    <div class="col-sm-12 text-right">
      <button type="button" class="btn btn-danger btn-sm mr-3"  (click)="prevTab()">BACK</button>
      <button type="submit" class="btn-primary text-right" (click)="updateCleaningCheck();nextTab()">COMPLETE</button>
    </div>
  </div>
</form>
