import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shiftsFilter'
})
export class ShiftsFilterPipe implements PipeTransform {

  rates: any;

  transform(values: any[], agencyFilter:string, clientFilter:string, startDateFilter:string, endDateFilter:string, directorateFilter:string): any[] {

    // return values;
    var val;
    var result = values;
    // let v = values.length;

    // var minutesToAdd=30;
    // var currentDate = new Date();
    // var futureDate = new Date(currentDate.getTime() + minutesToAdd*60000);

    // for(let i = 0; i < v ; i++){
    //   values[i].shiftDate = this.formatDate(values[i].shiftDate);
    //   values[i].shiftEndDate = this.formatDate(values[i].shiftEndDate);
    //   let date = new Date(values[i].shiftDate);
    //   date.setHours(this.formatDate(values[i].shiftStartTime)[0]) ;
    //   date.setMinutes(this.formatDate(values[i].shiftStartTime)[1]) ;
    //   values[i].sortDate = date;
    // };


    // if (agencyFilter) {
    //   // result = values.filter(v => v.shiftDate >= startDateFilter);
    // }
    // if (clientFilter) {
    //   result = values.filter(v => v.client == clientFilter);
    // }
    // if (startDateFilter) {
    //   result = values.filter(v => v.shiftDate >= startDateFilter);
    // }
    // if (endDateFilter) {
    //         result = values.filter(v => v.shiftEndDate <= endDateFilter);
    // }
    // if (directorateFilter) {
    //         result = values.filter(v => v.directorate >= directorateFilter);
    // }

    // Return TO Standard Date Format
    // for(let i = 0; i < result.length ; i++){
    //   result[i].shiftDate = this.returnDate(result[i].shiftDate);
    //   result[i].shiftEndDate = this.returnDate(result[i].shiftEndDate);
    // };

    // const results = result.sort(
    //   (objA, objB) => objA.sortDate.getTime() - objB.sortDate.getTime(),
    // );

    let results = result;
    console.table(results);

    return results;

  }

  formatDate(date){
    const myArray = date.split("/");
    if(myArray.length>=2){
      let formateddate =myArray[2] + "-" +myArray[1] +"-"+myArray[0] ;
      return formateddate;
    }else{
      return date;
    }

  }

  formatTime(date){
    const myArray = date.split(":");

    let hours =myArray[0] ;
    let minutes =myArray[1] ;


    return myArray;


  }

  returnDate(date){
    const myArray = date.split("-");
    if(myArray.length>=2){
      let formateddate =myArray[2] + "/" +myArray[1] +"/"+myArray[0] ;
      return formateddate;
    }else{
      return date;
    }

  }

}
