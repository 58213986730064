<form [formGroup]="form" (ngSubmit)="updateMileage()" >
  <h5>Complete job & submit log</h5>

  <div class="card p-2 bg-grey btn-group">
    <p>
      Once you submit, you can find this log sheet under completed jobs and
      or under the vehicle's logs.
    </p>

  </div>



  <h5 class="my-2">Mileage</h5>

  <div class="card p-2 bg-grey">
    <div class="row">
      <div class="form-group col-6 col-sm-6 col-md-4">
        <label>Date</label>
        <input type="text"  maxlength="255"  value="{{job?.dateTimeRequired | date:'dd/mm/yyyy'}}"  class="form-control" disabled >
      </div>
      <!-- <div class="form-group col-6 col-sm-6 col-md-3">
        <label>Driver</label>
        <input type="text"  maxlength="255"  value="{{job?.driver?.email}}"  class="form-control" disabled >
      </div> -->
      <div class="form-group col-6 col-sm-6 col-md-4">
        <label>Vehicle</label>
        <input type="text"  maxlength="255"  value="{{job?.vehicle?.name}}"  class="form-control" disabled >
      </div>
      <div class="form-group col-6 col-sm-6 col-md-4">
        <label>Vehicle Reg</label>
        <input type="text"  maxlength="255"  value="{{job?.vehicle?.regno}}"  class="form-control" disabled >
      </div>
      <div class="form-group col-6 col-sm-6 col-md-4">
        <label>Start Mileage</label>
        <input type="text" disabled formControlName="startMileage"  maxlength="255"  placeholder=""  class="form-control"    >
      </div>
      <div class="form-group col-6 col-sm-6 col-md-4">
        <label>End Mileage</label>
        <input type="number" formControlName="endMileage" maxlength="255"  placeholder=""  class="form-control"    >
      </div>
      <div class="form-group col-6 col-sm-6 col-md-4">
        <label>Total Journey Mileage</label>
        <input type="text" disabled value="{{(form.value.endMileage-form.value.startMileage)>1?form.value.endMileage-form.value.startMileage:'End Mileage Invalid'}}" maxlength="255"  placeholder=""  class="form-control"    >
      </div>

    </div>
  </div>

  <div class="row mt-3">




    <div class="col-12">
      <input name="form-control w-100" formControlName="feedback" style="width:100%" placeholder="Overall feedback (Optional)">
    </div>

    <div class="btn-group col-12 mb-3" style="display: flex; align-items: center;">
      <label for="confirmationCheckbox" style="margin-right: 10px;">I confirm that all the information entered on this log is correct</label>
      <input type="checkbox" id="confirmationCheckbox" class="form-control" style="width: 15px; height: 15px;">
    </div>
    
    

    <div class="mt-2 text-right">
      <button type="button" class="btn btn-danger btn-sm mr-3"  (click)="prevTab()">BACK</button>
      <button type="submit" class="btn-primary text-right" (click)="validateForm($event);nextTab()">COMPLETE</button>
    </div>


  </div>
</form>
