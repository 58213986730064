import { W } from '@angular/cdk/keycodes';
import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Injectable, Input, OnInit, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { AssetService } from 'src/app/shared/services/asset.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { JobService } from 'src/app/shared/services/job.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TransportService } from 'src/app/shared/services/transport.service';
import { WorkerService } from 'src/app/shared/services/worker.service';
import { LogService } from 'src/app/shared/services/log.service';
import { ActivatedRoute } from '@angular/router';
import { Console } from 'console';

@Component({
  selector: 'driver-info-modal',
  templateUrl: './driver-info-modal.html',
  styleUrls: ['./driver-info-modal.css'],
  encapsulation: ViewEncapsulation.None // or ShadowDom
})
@Injectable()
export class DriverInfoModalComponent implements OnInit {


    isModalOpen = false;

    modal: any;
  damageDescriptions: any;

    print() {
    throw new Error('Method not implemented.');
    }
      @Input() log: any;
      @Input('selectedJob') selectedJob : any;
      @Output() updated = new EventEmitter<boolean>();
      driverInfo: string | undefined;

      workers:any;
      vehicles: any;
      bookedWorkers=[];
      authorizeForm: FormGroup;
      userType: any;
      riskDoc: any;
      addForm: FormGroup;
      extremeRag: Array<String> = ['S','H','M'];
      uploadDoc: boolean = false;
      agencyId: any;
      reviewComment: any;
      viewFullDetails: boolean = false;
      myWindow: Window | null;



      constructor(
        private storageService: StorageService,
        private agencyService: AgencyService,
        private assetService: AssetService,
        private transportService: TransportService,
        private toast: ToastrService,
        private modalService: NgbModal,
        private logService: LogService,
        private authService: AuthService,
        private workerService: WorkerService,
        private jobsService: JobService,
      ) {
        this.userType = authService.getUserType();
        this.agencyId =this.storageService.decrypt(localStorage.getItem('agentId'));

      }

  ngOnInit(): void {

    this.damageDescriptions = JSON.parse(this.log.damageDescriptions);
    // console.log(this.log);
    // console.log(this.damageDescriptions);
  }

  closeWin() {
    // Close the window if it exists
    if (this.myWindow) {
      this.myWindow.close();
    }
  }

  closeModal() {
    this.modal.dismiss();  // This closes the ng-bootstrap modal
  }
  

  openModal(modal){
    this.modalService.open(modal, {centered: true, size: 'lg'});
  }


  checkNull(array): boolean{
    let res = false;
    array.forEach(element => {
      // console.log(element,element!=null);
      if (element!=null) {res = true;}
    });
    return res;
  }

  comment(){
    this.logService.commentLog(this.log.id, this.reviewComment).subscribe(
      data => {
        this.toast.success("Done");
        this.modalService.dismissAll();
      }
    );
  }


  openDoc(url){
    this.workerService.getContentTpye(url).subscribe(res => {
      if(res.headers.get('Content-Type')!='application/pdf') {
        return window.open(url);
      }else{
        this.viewDoc(url);
      }
    });
  }

  viewDoc(url){
    this.workerService.downloadPDF(url).subscribe(res => {
      console.log(res);
      if(res==null)
        window.open(url, '_blank');
      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, '_blank');
    });
  }




  authorize() {
    this.logService.authorizeLog(this.log.id, this.storageService.decrypt(localStorage.getItem('display_name')).replace(/\./g, '')).subscribe(
      data => {
        this.toast.success("Done");
        this.modalService.dismissAll();
      }
    );
    }

    downloadModalAsPDF() {
      const modalContent = document.getElementById('printableModal');
  
      if (modalContent) {
          const printWindow = window.open('', '', 'width=800,height=600');
          
          printWindow!.document.write(`
              <html>
                  <head>
                      <title>Print Modal</title>
                      <style>
                          /* Optional CSS for the modal, to style for print */
                          body, html {
                              font-family: Arial, sans-serif;
                          }
                          .info-box {
                              border: 1px solid #ccc;
                              padding: 10px;
                              margin: 5px;
                          }
                      </style>
                  </head>
                  <body>
                      ${modalContent.innerHTML}
                  </body>
              </html>
          `);
  
          printWindow!.document.close(); // Close document to finish writing
          printWindow!.focus();          // Focus on the new window
          printWindow!.print();          // Trigger the print dialog
          printWindow!.close();          // Close the print window after printing
      }
  }
  


}
