import { AdminHomeComponent } from './admin-home/admin-home.component';
import { NotFoundComponent } from './../shared/pages/not-found/not-found.component';
import { AuthGuard } from './../shared/utilities/auth.guard';
import { AdminContainerComponent } from './admin-container/admin-container.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AgencyTrainingsListComponent } from '../agency/agency-trainings/agency-training-list/agency-trainings-list.component';
import { NewTrainingComponent } from '../agency/agency-trainings/new-training/new-training.component';
import { ApproveTrainingComponent } from '../agency/agency-trainings/approve-training/approve-training.component';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminContainerComponent,
    canActivate: [AuthGuard],
    children: [

      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: AdminHomeComponent },

      { path: 'shifts', loadChildren: () => import('./shifts/shifts.module').then(m => m.ShiftsModule) },

      { path: 'trainings', loadChildren: () => import('./trainings/trainings.module').then(m => m.TrainingsModule) },




      { path: 'trainings', loadChildren: () => import('../agency/agency-trainings/agency-trainings.module').then(m => m.AgencyTrainingsModule) },


      { path: 'agencies', loadChildren: () => import('./agencies/agencies.module').then(m => m.AgenciesModule) },
      { path: 'clients', loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule) },
      { path: 'workers', loadChildren: () => import('./workers/workers.module').then(m => m.AdminWorkersModule) },
      { path: 'staff', loadChildren: () => import('./staff/staff.module').then(m => m.StaffModule) },
      { path: 'reporting', loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingModule) },
      { path: 'rates', loadChildren: () => import('./rates-codes/rates-codes.module').then(m => m.RatesCodesModule) },
      { path: 'billing', loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule) },
      { path: 'communications', loadChildren: () => import('./communications/communications.module').then(m => m.CommunicationsModule) },
      { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },

      // not found
      { path: '**', component: NotFoundComponent, pathMatch: 'full' },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
