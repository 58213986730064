import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkersRoutingModule } from './workers-routing.module';
import {WorkerListDialog, WorkersListComponent } from './workers-list/workers-list.component';
import { WorkerDetailsComponent } from './worker-details/worker-details.component';
import { EditWorkerTrainingModalComponent } from './worker-details/components/edit-training/edit-worker-training-modal.component';
import { DeleteWorkerTrainingModalComponent } from './worker-details/components/delete-training/delete-worker-training-modal.component';
import { TrainingAndQualificationsComponent } from './training-and-qualifications/training-and-qualifications.component';
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatDialogModule} from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TrainingComponent } from './training-and-qualifications/training/training.component';
import { CompliancesComponent } from './training-and-qualifications/compliance/compliance.component';
import { ApplicationComponent } from './worker-details/components/application/application.component';
import { OccupationalComponent } from './worker-details/components/occupational/occupational.component';
import { HmrcComponent } from './worker-details/components/hmrc/hmrc.component';
import { BankComponent } from './worker-details/components/bank/bank.component';
import { ApplicantListComponent } from './applicant-List/applicant-list.component';
import { ApplicantListDialog } from './applicant-List/dialog/applicant-list-dialog.component';
import { PipesModule } from 'src/app/shared/pipes.module';
import { TrainersListComponent } from './training-and-qualifications/trainers-list/trainers-list.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { WorkerInfoComponent } from './worker-details/components/workerinfo/worker-info.component';
import { WorkerComplianceModalComponent,  } from './worker-details/components/worker-compliance/worker-compliance-modal.component';
import { WorkerTrainingModalComponent } from './worker-details/components/worker-training/worker-training-modal.component';

@NgModule({
  declarations: [WorkersListComponent, WorkerDetailsComponent, WorkerInfoComponent,
    TrainingComponent,  CompliancesComponent, TrainingAndQualificationsComponent, WorkerListDialog,
    ApplicantListDialog, ApplicationComponent, ApplicantListComponent,
    OccupationalComponent, TrainersListComponent,WorkerTrainingModalComponent,DeleteWorkerTrainingModalComponent,EditWorkerTrainingModalComponent,WorkerComplianceModalComponent,
    HmrcComponent,
    BankComponent],
  imports: [
    NgSelectModule,
    FormsModule,
    CommonModule,
    WorkersRoutingModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatButtonModule,
    MatDatepickerModule,
    PipesModule
  ],
  exports:[WorkerTrainingModalComponent, WorkerComplianceModalComponent],
  providers: [
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'fill'}}
  ],
  bootstrap: [
    ApplicantListDialog
  ]
})
export class AdminWorkersModule { }
