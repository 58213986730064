import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class TrainingService {

  //base_url: string = environment.API_url + ':8300/api/v1';
  base_url: string = environment.API_url + '/worklink-api/api/v1';
  userType: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private storageService: StorageService
  ) {
    this.userType = authService.getUserType();
  }

  getPaginatedTraining(page, size): Observable<any> {
    return this.http.get(`${this.base_url}/training/${page}/${size}`);
  }

  getPaginatedAgencyTraining(agencyd): Observable<any> {
    return this.http.get(`${this.base_url}/trainings-agency/${agencyd}`);
  }


  addAgencyTraining(agencyId, trainingId): Observable<any> {
    return this.http.put(`${this.base_url}/training/agency/${agencyId}/${trainingId}`,{});
  }

  getTrainingBooking(id): Observable<any> {
    return this.http.get(`${this.base_url}/training-booking/${id} `);
  }

  authorizeBooking(form): Observable<any> {
    return this.http.put(`${this.base_url}/training-booking/authorize`, form);
  }

  getTrainingSessions( status,page, size, trainingId, startDate, endDate, reports): Observable<any> {
    return this.http.get(`${this.base_url}/training-sessions/${status}/${page}/${size}?trainingId=${trainingId}&startDate=${startDate}&endDate=${endDate}&reports=${reports}`);
  }

  getAgencyTrainingSessions(  agencyId,status,page, size, trainingId, startDate, endDate, reports): Observable<any> {
    return this.http.get(`${this.base_url}/training-sessions/agency/${agencyId}/${status}/${page}/${size}?trainingId=${trainingId}&startDate=${startDate}&endDate=${endDate}&reports=${reports}`);
  }

  getTrainerTrainingSessions(  agencyId,status,page, size, trainingId, startDate, endDate): Observable<any> {
    return this.http.get(`${this.base_url}/training-sessions/trainer/${agencyId}/${status}/${page}/${size}?trainingId=${trainingId}&startDate=${startDate}&endDate=${endDate}`);
  }

  getForTrainerBilling( trainerId, agencyId,page, size, trainingId, startDate, endDate): Observable<any> {
    return this.http.get(`${this.base_url}/training-sessions/trainer/billing/${trainerId}/${agencyId}/${page}/${size}?trainingId=${trainingId}&startDate=${startDate}&endDate=${endDate}`);
  }

  getPaginatedTrainingInvoices( agencyId: number,onlyPaid: boolean, page, size, trainingId, startDate, endDate): Observable<any> {

    return this.http.get(`${this.base_url}/invoice/trainer/worker/${agencyId}/${page}/${size}?trainingId=${trainingId}&startDate=${startDate}&endDate=${endDate}&onlyPaid=${onlyPaid}`);


  }

  getPaginatedAgencyTrainingInvoices( agencyId: number,page, size, agencyIdFilter, startDate, endDate: String, onlyPaid:boolean): Observable<any> {
    return this.http.get(` ${this.base_url}/invoice/trainer/agency/${agencyId}/${page}/${size}?agencyIdFilter=${agencyIdFilter}&startDate=${startDate}&endDate=${endDate}&onlyPaid=${onlyPaid}`);


  }

  getAgencyTrainingBookings(  agencyId,status,page, size, trainingId, startDate, endDate): Observable<any> {
    return this.http.get(`
    ${this.base_url}/training-bookings/agency/${agencyId}/${status}/${page}/${size}?trainingId=${trainingId}&startDate=${startDate}&endDate=${endDate}`);


  }

  getTrainerTrainingBookings(  agencyId,status,page, size, trainingId, startDate, endDate): Observable<any> {
    return this.http.get(`
    ${this.base_url}/training-bookings/trainer/${agencyId}/${status}/${page}/${size}?trainingId=${trainingId}&startDate=${startDate}&endDate=${endDate}`);


  }

  getPaginatedCompliance(page, size): Observable<any> {
    return this.http.get(`${this.base_url}/compliance/${page}/${size}`);
  }

  getTrainingBookings(id): Observable<any> {
    return this.http.get(`${this.base_url}/training-booking/training-session/${id}`);
  }

  // getPaginatedAssignmentCodes(page, size): Observable<any> {
  //   if(this.userType == 'admin'){
  //     return this.http.get(`${this.base_url}/assignment-codes/${page}/${size}`);
  //   }
  //   else if(this.userType == 'agency'){
  //     return this.http.get(`${this.base_url}/assignment-code-agency/${this.storageService.decrypt(localStorage.getItem('agentId'))}`);
  //   }
  //   else if(this.userType == 'client'){
  //     return this.http.get(`${this.base_url}/assignment-code-client/${this.storageService.decrypt(localStorage.getItem('clientId'))}`);
  //   }
  // }

  // getPaginatedAgencyAssignmentCodes(agency): Observable<any> {
  //     return this.http.get(`${this.base_url}/assignment-code-agency/${agency}`);
  // };

  createAssignmentCodes(data): Observable<any> {
    return this.http.post(`${this.base_url}/training`, data);
  }

  createTrainingSession(data): Observable<any> {
    return this.http.post(`${this.base_url}/training-session`, data);
  }

  bookWorkers(agencyId,trainerId, workerId): Observable<any> {
    return this.http.put(`${this.base_url}/training-booking/book/${agencyId}/${trainerId}/${workerId}`, {});
  }

  showCertificate(id,show): Observable<any> {
    return this.http.put(`${this.base_url}/training-booking/show-certificate/${id}/${show}`, {});
  }

  createCompliance(data): Observable<any> {
    return this.http.post(`${this.base_url}/compliance`, data);
  }

  updateAssignmentCodes(data): Observable<any> {
    return this.http.put(`${this.base_url}/training`, data);
  }

  updateCompliance(data): Observable<any> {
    return this.http.put(`${this.base_url}/compliance`, data);
  }

  getAssignmentCodesById(ref): Observable<any> {
    return this.http.get(`${this.base_url}/training/${ref}`);
  }


  getWorkerTraining(ref): Observable<any> {
    return this.http.get(`${this.base_url}/worker-training/${ref}`);
  }

  deleteAssignmentCodes(ref): Observable<any> {
    return this.http.delete(`${this.base_url}/training/${ref}`);
  }

  deleteCompliance(ref): Observable<any> {
    return this.http.delete(`${this.base_url}/compliance/${ref}`);
  }


  cancelTrainingSesssion( sessionId): Observable<any> {
    return this.http.delete(`${this.base_url}/training-session/${sessionId}`,{});
  }


  approveBooking(bookingId: number, approveBooking: boolean): Observable<any> {
    return this.http.put(`${this.base_url}/training-booking/approval/${bookingId}/${approveBooking}`,{});
  }


  getTrainerSessionCount(trainerId): Observable<any> {
    return this.http.get(`${this.base_url}/training-session/trainer/report/${trainerId}`);
  }

  getAgencySessionCount(agencyId): Observable<any> {
    return this.http.get(`${this.base_url}/training-session/agency/report/${agencyId}`);
  }

  getSessionFeedback(sessionId): Observable<any> {
    return this.http.get(`${this.base_url}/training-feedback/booking/${sessionId}`);
  }

  getBookingFeedback(agencyId): Observable<any> {
    return this.http.get(`${this.base_url}/training-session/agency/report/${agencyId}`);
  }



}

