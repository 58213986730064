import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FileService } from 'src/app/shared/services/file.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { WorkerService } from 'src/app/shared/services/worker.service';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css']
})
export class ApplicationComponent implements OnInit {
  countries: { name: string; code: string; }[];
  worker: any;
  rde2 =false;
  restriction = false;
  eHistory= [{}];
  nHistory:[FormGroup];
  education=[{}];

  refsForms:[FormGroup];
  employersForms:[FormGroup];
  educationsForms:[FormGroup];
  employmentsForms:[FormGroup];


  dbs = false;
  dbscert = false;
  offenses = false;
  related = false;
  confidential = false;
  sanctions = false;
  uploaded = false;
  uploaded2 = false;
  interview = false;
  workerId: any;
  addApplicationForm: FormGroup;
  signedDoc: any;
  applicationData: any;
  applicationLoaded = false;
  submitForm = false;

  constructor(
    private formsService: FormsService,
    private workerService: WorkerService,
    private fileService: FileService,
    private toast: ToastrService,
    private storageService: StorageService,
    private fb: FormBuilder,


  ) {

    this.addApplicationForm = this.fb.group({
      workerId: [],
      position : [],
      location : [],
      hours : [],
      closingDate : [],
      title : [],
      lastname : [],
      firstname : [],
      preferredName : [],
      nationality : [],
      dob : [],
      address : [],
      email : [],
      phone : [],
      ukWork : [],
      restrictionRes : [],
      insuaranceNum : [],
      personalState : [],
      offensesRes : [],
      investigationsRes : [],
      dbsCert : [],
      dbsRenewal : [],
      signed : [],
      printName : [],
      signDate : [],
      licence : [],
      car : [],
      onlyEmployment : [],
      sanctionsRes : [],
      dismissedRes : [],
      relatedRes : [],
      genFullname : [],
      genSigned : [],
      genDate : [],
      unavailable : [],
      submitted : [],
      profreg : [],
      certificate : [],

      restriction: [],
      offenses: [],
      investigations: [],
      dismissed: [],
      sanctions: [],
      related: [],

      dbsNumber : [],
      dbsType : [],

      confidential: [],
      dbs: [],
      availability: [],
      references : this.fb.array([
        this.fb.group({
          name:[],
          position:[],
          employer:[],
          relationship:[],
          address:[],
          phone:[],
          email:[],
          contact:[],
          latest:[],
          workerApplicationId:[]
      })
      ]),
      employments : this.fb.array([
         this.fb.group({
          id : [],
          address : [],
          reason : [],
          position : [],
          latest : [],
          start : [],
          employer : [],
          end : [],
          workerApplicationId : [],
        })
      ]),
      educations : this.fb.array([
         this.fb.group({
          name : [],
          id : [],
          qualification : [],
          start : [],
          end : [],
          workerApplicationId : [],
        })
      ]),
      employers : this.fb.array([
         this.fb.group({

            id:[],
            description:[],
            job:[],
            start:[],
            name:[],
            address:[],
            end:[],
            notice:[],
            salary:[],
            workerApplicationId:[]

      })
      ])




    });

    this.nHistory = [this.fb.group({
      name:[],
      position:[],
      employer:[],
      relationship:[],
      address:[],
      phone:[],
      email:[],
      contact:[],
      latest:[],
      workerApplicationId:[]

    })];

   }


  get refsArray() {
    return <FormArray>this.addApplicationForm.get('references');
  }
  get employersArray() {
    return <FormArray>this.addApplicationForm.get('employers');
  }
  get employmentArray() {
    return <FormArray>this.addApplicationForm.get('employments');
  }
  get educationArray() {
    return <FormArray>this.addApplicationForm.get('educations');
  }

  ngOnInit(): void {
    this.workerId = this.storageService.decrypt(localStorage.getItem('workerId'));

    this.setCoutries();
    this.getWorker();
    this.getApplication();



  }


  intervalId = setInterval(() => this.autoSubmit(), 10000);

  // Unsubscribe in ngOnDestroy
  ngOnDestroy() {
    clearInterval(this.intervalId);
  }


  autoSubmit(){
    if(this.submitForm){
      this.addWorkerApplication(this.addApplicationForm);
      this.submitForm = false;
    }
  }

  setSubmit(){
    this.submitForm = true;
  }

  addWorkerApplication(form: FormGroup, submit?, saved?){
    console.log(this.addApplicationForm);
    // let form = this.addApplicationForm;
    if(submit){
      form.value.submitted = true;
    }
    if(form.valid){
      form.value.workerId =this.workerId ;

      this.formsService.addWorkerApplication(form.value).subscribe(
        resp => {
          if(saved)this.toast.success('Worker Application Updated Successfully');

        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  addRefs(appId){
    let form = [];
    this.refsArray.controls.forEach(f =>{
      f.value.workerApplicationId =appId ;
      form.push(f.value);
    });

    console.log(form);

    if(form){

      this.formsService.addWorkerReference({"references":form}).subscribe(
        resp => {
          this.toast.success('Worker References Added Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  onChange(event) {
    this.signedDoc = event.target.files[0];
  }

  uploadSignature(){
    this.fileService.uploadApplicationSig(this.signedDoc, this.storageService.decrypt(localStorage.getItem('workerId'))).subscribe(
      event => {
        if(event instanceof HttpResponse) {

        };
        this.uploaded = true;
        this.toast.info("Sucessfully uploaded.");

      },
      (err) => {





      }, () => {

      }
    );
  }

  uploadSafeguardingSig(){
    this.fileService.uploadSafeguardingSig(this.signedDoc, this.storageService.decrypt(localStorage.getItem('workerId'))).subscribe(
      event => {
        if(event instanceof HttpResponse) {

        };
        this.uploaded2 = true;
        this.toast.info("Sucessfully uploaded.");

      },
      (err) => {





      }, () => {

      }
    );
  }

  getApplication(){
    this.formsService.getWorkerApplication(this.workerId).subscribe(

      data => {

        this.applicationLoaded = true;
        this.applicationData = data;

        this.getRefs(data.references);
        this.getEmployers(data.employers);
        this.getEducations(data.educations);
        this.getEmployments(data.employments);

        this.addApplicationForm = this.fb.group({
          workerId: [data.workerId],
          position : [data.position],
          location : [data.location],
          hours : [data.hours],
          closingDate : [data.closingDate],
          title : [data.title],
          lastname : [this.worker?.lastname],
          firstname : [this.worker?.firstname],
          preferredName : [data.preferredName],
          nationality : [this.worker?.nationality],
          dob : [this.worker?.dob],
          address : [data.address],
          email : [this.worker?.email],
          phone : [data.phone],
          ukWork : [data.ukWork],
          restrictionRes : [data.restrictionRes],
          insuaranceNum : [data.insuaranceNum],
          personalState : [data.personalState],
          offensesRes : [data.offensesRes],
          investigationsRes : [data.investigationsRes],
          dbsCert : [data.dbsCert],
          dbsRenewal : [data.dbsRenewal],
          signed : [data.signed],
          printName : [data.printName],
          signDate : [data.signDate],
          licence : [data.licence],
          car : [data.car],
          onlyEmployment : [data.onlyEmployment],
          sanctionsRes : [data.sanctionsRes],
          dismissedRes : [data.dismissedRes],
          relatedRes : [data.relatedRes],
          genFullname : [data.genFullname],
          genSigned : [data.genSigned],
          genDate : [data.genDate],
          unavailable : [data.unavailable],
          submitted : [data.submitted],
          restriction: [data.restriction],
          offenses: [data.offenses],
          investigations: [data.investigations],
          sanctions: [data.sanctions],
          dismissed: [data.dismissed],
          related: [data.related],
          confidential: [data.confidential],
          dbs: [data.dbs],

          dbsNumber : [data.dbsNumber],
          dbsType : [data.dbsType],

          profreg : [data.profreg],
          certificate : [data.certificate],


          availability: [data.availability],

          references : this.fb.array(this.refsForms),
          employments : this.fb.array(this.employmentsForms),
          educations : this.fb.array(this.educationsForms),
          employers : this.fb.array(this.employersForms)

        });




        this.addApplicationForm.value.firstname = this.worker?.firstname;
        this.addApplicationForm.value.lastname = this.worker?.lastname;
        console.log(this.addApplicationForm.value);

        this.restriction = data.restriction;
        this.offenses = data.offenses;
        this.sanctions = data.sanctions;
        this.confidential = data.confidential;
        this.related = data.related;
        this.dbscert = data.dbsCert;
        this.dbs = data.dbs;
        this.interview = data.availability;

        console.log(this.restriction)
      }
    );
  }

  getRefs(data){
    this.refsForms = null;

    data.sort(function(a, b){
      if(a.latest < b.latest) { return -1; }
      if(a.latest > b.latest) { return 1; }
      return 0;
  });


    data.forEach(data => {

      if(this.refsForms==null){
        this.refsForms= [this.fb.group({
          name:[data.name],
          position:[data.position],
          employer:[data.employer],
          relationship:[data.relationship],
          address:[data.address],
          phone:[data.phone],
          email:[data.email],
          contact:[data.contact],
          latest:[data.latest],
          workerApplicationId:[data.workerApplicationId]
        })];
      }else{
        this.refsForms.push(this.fb.group({
          name:[data.name],
          position:[data.position],
          employer:[data.employer],
          relationship:[data.relationship],
          address:[data.address],
          phone:[data.phone],
          email:[data.email],
          contact:[data.contact],
          latest:[data.latest],
          workerApplicationId:[data.workerApplicationId]
        }));
      }

    });

    if(this.refsForms==null){
      this.refsForms= [this.fb.group({
        name:[],
        position:[],
        employer:[],
        relationship:[],
        address:[],
        phone:[],
        email:[],
        contact:[],
        latest:[],
        workerApplicationId:[]
      })];


    }

    return this.refsForms;
  }

  getEmployers(data){
    this.employersForms = null;

    data.forEach(data => {

      if(this.employersForms==null){
        this.employersForms= [this.fb.group({

          job:[data.job],
          start:[data.start],
          end:[data.end],
          description:[data.description],
          notice:[data.notice],
          name:[data.name],
          address:[data.address],
          salary:[data.salary],
          workerApplicationId:[data.workerApplicationId],

        })];
      }else{
        this.employersForms.push(this.fb.group({

          job:[data.job],
          start:[data.start],
          end:[data.end],
          description:[data.description],
          notice:[data.notice],
          address:[],
          name:[],
          salary:[data.salary],
          workerApplicationId:[data.workerApplicationId],

        }));
      }

    });

    if(this.employersForms==null){
      this.employersForms= [this.fb.group({

        job:[],
        start:[],
        end:[],
        description:[],
        notice:[],
        salary:[],
        workerApplicationId:[],

      })];
    }

    return this.employersForms;
  }

  getEducations(data){
    this.educationsForms = null;

    data.forEach(data => {

      if(this.educationsForms==null){
        this.educationsForms= [this.fb.group({
          id:[data.id],
          name:[data.name],
          qualification:[data.qualification],
          start:[data.start],
          end:[data.end],
          workerApplicationId:[data.workerApplicationId],
        })];
      }else{
        this.educationsForms.push(this.fb.group({
          id:[data.id],
          name:[data.name],
          qualification:[data.qualification],
          start:[data.start],
          end:[data.end],
          workerApplicationId:[data.workerApplicationId],
        }));
      }

    });

    if(this.educationsForms==null){
      this.educationsForms= [this.fb.group({
        id:[],
        name:[],
        qualification:[],
        start:[],
        end:[],
        workerApplicationId:[],
      })];
    }

    return this.refsForms;
  }

  getEmployments(data){
    this.employmentsForms = null;

    data.forEach(data => {

      if(this.employmentsForms==null){
        this.employmentsForms= [this.fb.group({
          start:[data.start],
          employer:[data.employer],
          position:[data.position],
          end:[data.end],
          address:[data.address],
          reason:[data.reason],
          latest:[data.latest],
          workerApplicationId: [data.workerApplicationId],
        })];
      }else{
        this.employmentsForms.push(this.fb.group({
          start:[data.start],
          employer:[data.employer],
          position:[data.position],
          end:[data.end],
          address:[data.address],
          reason:[data.reason],
          latest:[data.latest],
          workerApplicationId: [data.workerApplicationId],
        }));
      }

    });

    if(this.employmentsForms==null){
      this.employmentsForms= [this.fb.group({
        start:[],
        employer:[],
        position:[],
        end:[],
        address:[],
        reason:[],
        latest:[],
        workerApplicationId: [],
      })];
    }


    return this.refsForms;
  }



  setCoutries(){
    this.countries =
    [
      {"name": "Afghanistan", "code": "AF"},
      {"name": "land Islands", "code": "AX"},
      {"name": "Albania", "code": "AL"},
      {"name": "Algeria", "code": "DZ"},
      {"name": "American Samoa", "code": "AS"},
      {"name": "AndorrA", "code": "AD"},
      {"name": "Angola", "code": "AO"},
      {"name": "Anguilla", "code": "AI"},
      {"name": "Antarctica", "code": "AQ"},
      {"name": "Antigua and Barbuda", "code": "AG"},
      {"name": "Argentina", "code": "AR"},
      {"name": "Armenia", "code": "AM"},
      {"name": "Aruba", "code": "AW"},
      {"name": "Australia", "code": "AU"},
      {"name": "Austria", "code": "AT"},
      {"name": "Azerbaijan", "code": "AZ"},
      {"name": "Bahamas", "code": "BS"},
      {"name": "Bahrain", "code": "BH"},
      {"name": "Bangladesh", "code": "BD"},
      {"name": "Barbados", "code": "BB"},
      {"name": "Belarus", "code": "BY"},
      {"name": "Belgium", "code": "BE"},
      {"name": "Belize", "code": "BZ"},
      {"name": "Benin", "code": "BJ"},
      {"name": "Bermuda", "code": "BM"},
      {"name": "Bhutan", "code": "BT"},
      {"name": "Bolivia", "code": "BO"},
      {"name": "Bosnia and Herzegovina", "code": "BA"},
      {"name": "Botswana", "code": "BW"},
      {"name": "Bouvet Island", "code": "BV"},
      {"name": "Brazil", "code": "BR"},
      {"name": "British Indian Ocean Territory", "code": "IO"},
      {"name": "Brunei Darussalam", "code": "BN"},
      {"name": "Bulgaria", "code": "BG"},
      {"name": "Burkina Faso", "code": "BF"},
      {"name": "Burundi", "code": "BI"},
      {"name": "Cambodia", "code": "KH"},
      {"name": "Cameroon", "code": "CM"},
      {"name": "Canada", "code": "CA"},
      {"name": "Cape Verde", "code": "CV"},
      {"name": "Cayman Islands", "code": "KY"},
      {"name": "Central African Republic", "code": "CF"},
      {"name": "Chad", "code": "TD"},
      {"name": "Chile", "code": "CL"},
      {"name": "China", "code": "CN"},
      {"name": "Christmas Island", "code": "CX"},
      {"name": "Cocos (Keeling) Islands", "code": "CC"},
      {"name": "Colombia", "code": "CO"},
      {"name": "Comoros", "code": "KM"},
      {"name": "Congo", "code": "CG"},
      {"name": "Congo, The Democratic Republic of the", "code": "CD"},
      {"name": "Cook Islands", "code": "CK"},
      {"name": "Costa Rica", "code": "CR"},
      {"name": "Cote D'Ivoire", "code": "CI"},
      {"name": "Croatia", "code": "HR"},
      {"name": "Cuba", "code": "CU"},
      {"name": "Cyprus", "code": "CY"},
      {"name": "Czech Republic", "code": "CZ"},
      {"name": "Denmark", "code": "DK"},
      {"name": "Djibouti", "code": "DJ"},
      {"name": "Dominica", "code": "DM"},
      {"name": "Dominican Republic", "code": "DO"},
      {"name": "Ecuador", "code": "EC"},
      {"name": "Egypt", "code": "EG"},
      {"name": "El Salvador", "code": "SV"},
      {"name": "Equatorial Guinea", "code": "GQ"},
      {"name": "Eritrea", "code": "ER"},
      {"name": "Estonia", "code": "EE"},
      {"name": "Ethiopia", "code": "ET"},
      {"name": "Falkland Islands (Malvinas)", "code": "FK"},
      {"name": "Faroe Islands", "code": "FO"},
      {"name": "Fiji", "code": "FJ"},
      {"name": "Finland", "code": "FI"},
      {"name": "France", "code": "FR"},
      {"name": "French Guiana", "code": "GF"},
      {"name": "French Polynesia", "code": "PF"},
      {"name": "French Southern Territories", "code": "TF"},
      {"name": "Gabon", "code": "GA"},
      {"name": "Gambia", "code": "GM"},
      {"name": "Georgia", "code": "GE"},
      {"name": "Germany", "code": "DE"},
      {"name": "Ghana", "code": "GH"},
      {"name": "Gibraltar", "code": "GI"},
      {"name": "Greece", "code": "GR"},
      {"name": "Greenland", "code": "GL"},
      {"name": "Grenada", "code": "GD"},
      {"name": "Guadeloupe", "code": "GP"},
      {"name": "Guam", "code": "GU"},
      {"name": "Guatemala", "code": "GT"},
      {"name": "Guernsey", "code": "GG"},
      {"name": "Guinea", "code": "GN"},
      {"name": "Guinea-Bissau", "code": "GW"},
      {"name": "Guyana", "code": "GY"},
      {"name": "Haiti", "code": "HT"},
      {"name": "Heard Island and Mcdonald Islands", "code": "HM"},
      {"name": "Holy See (Vatican City State)", "code": "VA"},
      {"name": "Honduras", "code": "HN"},
      {"name": "Hong Kong", "code": "HK"},
      {"name": "Hungary", "code": "HU"},
      {"name": "Iceland", "code": "IS"},
      {"name": "India", "code": "IN"},
      {"name": "Indonesia", "code": "ID"},
      {"name": "Iran, Islamic Republic Of", "code": "IR"},
      {"name": "Iraq", "code": "IQ"},
      {"name": "Ireland", "code": "IE"},
      {"name": "Isle of Man", "code": "IM"},
      {"name": "Israel", "code": "IL"},
      {"name": "Italy", "code": "IT"},
      {"name": "Jamaica", "code": "JM"},
      {"name": "Japan", "code": "JP"},
      {"name": "Jersey", "code": "JE"},
      {"name": "Jordan", "code": "JO"},
      {"name": "Kazakhstan", "code": "KZ"},
      {"name": "Kenya", "code": "KE"},
      {"name": "Kiribati", "code": "KI"},
      {"name": "Korea, Democratic People's Republic of", "code": "KP"},
      {"name": "Korea, Republic of", "code": "KR"},
      {"name": "Kuwait", "code": "KW"},
      {"name": "Kyrgyzstan", "code": "KG"},
      {"name": "Lao People's Democratic Republic", "code": "LA"},
      {"name": "Latvia", "code": "LV"},
      {"name": "Lebanon", "code": "LB"},
      {"name": "Lesotho", "code": "LS"},
      {"name": "Liberia", "code": "LR"},
      {"name": "Libyan Arab Jamahiriya", "code": "LY"},
      {"name": "Liechtenstein", "code": "LI"},
      {"name": "Lithuania", "code": "LT"},
      {"name": "Luxembourg", "code": "LU"},
      {"name": "Macao", "code": "MO"},
      {"name": "Macedonia, The Former Yugoslav Republic of", "code": "MK"},
      {"name": "Madagascar", "code": "MG"},
      {"name": "Malawi", "code": "MW"},
      {"name": "Malaysia", "code": "MY"},
      {"name": "Maldives", "code": "MV"},
      {"name": "Mali", "code": "ML"},
      {"name": "Malta", "code": "MT"},
      {"name": "Marshall Islands", "code": "MH"},
      {"name": "Martinique", "code": "MQ"},
      {"name": "Mauritania", "code": "MR"},
      {"name": "Mauritius", "code": "MU"},
      {"name": "Mayotte", "code": "YT"},
      {"name": "Mexico", "code": "MX"},
      {"name": "Micronesia, Federated States of", "code": "FM"},
      {"name": "Moldova, Republic of", "code": "MD"},
      {"name": "Monaco", "code": "MC"},
      {"name": "Mongolia", "code": "MN"},
      {"name": "Montenegro", "code": "ME"},
      {"name": "Montserrat", "code": "MS"},
      {"name": "Morocco", "code": "MA"},
      {"name": "Mozambique", "code": "MZ"},
      {"name": "Myanmar", "code": "MM"},
      {"name": "Namibia", "code": "NA"},
      {"name": "Nauru", "code": "NR"},
      {"name": "Nepal", "code": "NP"},
      {"name": "Netherlands", "code": "NL"},
      {"name": "Netherlands Antilles", "code": "AN"},
      {"name": "New Caledonia", "code": "NC"},
      {"name": "New Zealand", "code": "NZ"},
      {"name": "Nicaragua", "code": "NI"},
      {"name": "Niger", "code": "NE"},
      {"name": "Nigeria", "code": "NG"},
      {"name": "Niue", "code": "NU"},
      {"name": "Norfolk Island", "code": "NF"},
      {"name": "Northern Mariana Islands", "code": "MP"},
      {"name": "Norway", "code": "NO"},
      {"name": "Oman", "code": "OM"},
      {"name": "Pakistan", "code": "PK"},
      {"name": "Palau", "code": "PW"},
      {"name": "Palestinian Territory, Occupied", "code": "PS"},
      {"name": "Panama", "code": "PA"},
      {"name": "Papua New Guinea", "code": "PG"},
      {"name": "Paraguay", "code": "PY"},
      {"name": "Peru", "code": "PE"},
      {"name": "Philippines", "code": "PH"},
      {"name": "Pitcairn", "code": "PN"},
      {"name": "Poland", "code": "PL"},
      {"name": "Portugal", "code": "PT"},
      {"name": "Puerto Rico", "code": "PR"},
      {"name": "Qatar", "code": "QA"},
      {"name": "Reunion", "code": "RE"},
      {"name": "Romania", "code": "RO"},
      {"name": "Russian Federation", "code": "RU"},
      {"name": "RWANDA", "code": "RW"},
      {"name": "Saint Helena", "code": "SH"},
      {"name": "Saint Kitts and Nevis", "code": "KN"},
      {"name": "Saint Lucia", "code": "LC"},
      {"name": "Saint Pierre and Miquelon", "code": "PM"},
      {"name": "Saint Vincent and the Grenadines", "code": "VC"},
      {"name": "Samoa", "code": "WS"},
      {"name": "San Marino", "code": "SM"},
      {"name": "Sao Tome and Principe", "code": "ST"},
      {"name": "Saudi Arabia", "code": "SA"},
      {"name": "Senegal", "code": "SN"},
      {"name": "Serbia", "code": "RS"},
      {"name": "Seychelles", "code": "SC"},
      {"name": "Sierra Leone", "code": "SL"},
      {"name": "Singapore", "code": "SG"},
      {"name": "Slovakia", "code": "SK"},
      {"name": "Slovenia", "code": "SI"},
      {"name": "Solomon Islands", "code": "SB"},
      {"name": "Somalia", "code": "SO"},
      {"name": "South Africa", "code": "ZA"},
      {"name": "South Georgia and the South Sandwich Islands", "code": "GS"},
      {"name": "Spain", "code": "ES"},
      {"name": "Sri Lanka", "code": "LK"},
      {"name": "Sudan", "code": "SD"},
      {"name": "Suriname", "code": "SR"},
      {"name": "Svalbard and Jan Mayen", "code": "SJ"},
      {"name": "Swaziland", "code": "SZ"},
      {"name": "Sweden", "code": "SE"},
      {"name": "Switzerland", "code": "CH"},
      {"name": "Syrian Arab Republic", "code": "SY"},
      {"name": "Taiwan, Province of China", "code": "TW"},
      {"name": "Tajikistan", "code": "TJ"},
      {"name": "Tanzania, United Republic of", "code": "TZ"},
      {"name": "Thailand", "code": "TH"},
      {"name": "Timor-Leste", "code": "TL"},
      {"name": "Togo", "code": "TG"},
      {"name": "Tokelau", "code": "TK"},
      {"name": "Tonga", "code": "TO"},
      {"name": "Trinidad and Tobago", "code": "TT"},
      {"name": "Tunisia", "code": "TN"},
      {"name": "Turkey", "code": "TR"},
      {"name": "Turkmenistan", "code": "TM"},
      {"name": "Turks and Caicos Islands", "code": "TC"},
      {"name": "Tuvalu", "code": "TV"},
      {"name": "Uganda", "code": "UG"},
      {"name": "Ukraine", "code": "UA"},
      {"name": "United Arab Emirates", "code": "AE"},
      {"name": "United Kingdom", "code": "GB"},
      {"name": "United States", "code": "US"},
      {"name": "United States Minor Outlying Islands", "code": "UM"},
      {"name": "Uruguay", "code": "UY"},
      {"name": "Uzbekistan", "code": "UZ"},
      {"name": "Vanuatu", "code": "VU"},
      {"name": "Venezuela", "code": "VE"},
      {"name": "Viet Nam", "code": "VN"},
      {"name": "Virgin Islands, British", "code": "VG"},
      {"name": "Virgin Islands, U.S.", "code": "VI"},
      {"name": "Wallis and Futuna", "code": "WF"},
      {"name": "Western Sahara", "code": "EH"},
      {"name": "Yemen", "code": "YE"},
      {"name": "Zambia", "code": "ZM"},
      {"name": "Zimbabwe", "code": "ZW"}
      ];
  }

  getWorker(){

    this.workerService.getWorkerById(this.storageService.decrypt(localStorage.getItem('workerId'))).subscribe(
      data => {
        this.worker = data;
        console.log(this.worker)
        // this.agencyId = data.agencyId;
      }
    );
  }

  setYes(title){
    console.log(this.offenses);

    if(title=='restriction'){this.restriction = true}
    if(title=='dbs'){this.dbs = true}
    if(title=='interview'){this.interview = true}
    if(title=='sanctions'){this.sanctions = true}
    if(title=='sanctions'){this.sanctions = true}
    if(title=='confidential'){this.confidential = true}
    if(title=='dbscert'){this.dbscert = true}
    if(title=='offences'){this.offenses = true}
    if(title=='related'){this.related = true}

  }

  resetYes(title){
    console.log(this.rde2);

    if(title=='restriction'){this.restriction = false}
    if(title=='dbs'){this.dbs = false}
    if(title=='interview'){this.interview = false}
    if(title=='sanctions'){this.sanctions = false}
    if(title=='confidential'){this.confidential = false}
    if(title=='dbscert'){this.dbscert = false}
    if(title=='offences'){this.offenses = false}
    if(title=='related'){this.related = false}

  }

  eHistoryAction(action){
    console.log(this.rde2);

    if(action=='add'){this.eHistory.push({'one':true})}
    else{
      var indexToRemove = 0;
      var numberToRemove = 1;

      this.eHistory.splice(indexToRemove, numberToRemove);
    }
  }
  nHistoryAction(action){
    if(action=='add'){this.nHistory.push(
      this.fb.group({
        name:[],
        position:[],
        employer:[],
        relationship:[],
        address:[],
        phone:[],
        email:[],
        contact:[],
        latest:[],
        workerApplicationId:[]
      })
    )}
    else{
      var indexToRemove = 0;
      var numberToRemove = 1;

      this.nHistory.splice(indexToRemove, numberToRemove);
    }
  }
  educationAction(action){
    if(action=='add'){this.education.push({'one':true})}
    else{
      var indexToRemove = 0;
      var numberToRemove = 1;

      this.education.splice(indexToRemove, numberToRemove);
    }
  }


  addEmployment(){

      this.employmentArray.push(this.fb.group({
        start:[],
        employer:[],
        position:[],
        end:[],
        address:[],
        reason:[],
        latest:[],
        workerApplicationId: [],
      }));



  }

  removeEmployment(i: number) {
    this.employmentArray.removeAt(i);
  }


  addEducation(){

      this.educationArray.push(this.fb.group({
        id:[],
        name:[],
        qualification:[],
        start:[],
        end:[],
        workerApplicationId:[],
      }));



  }

  removeEducation(i: number) {
    this.educationArray.removeAt(i);
  }


  addEmployers(){

      this.employersArray.push(this.fb.group({

        job:[],
        start:[],
        end:[],
        description:[],
        notice:[],
        salary:[],
        workerApplicationId:[],
      }));



  }

  removeEmployers(i: number) {
    this.employersArray.removeAt(i);

  }


  addRef(){

      this.refsArray.push(this.fb.group({
        name:[],
        position:[],
        employer:[],
        relationship:[],
        address:[],
        phone:[],
        email:[],
        contact:[],
        latest:[],
        workerApplicationId:[]
      }));



  }

  removeRef(i: number) {
    this.refsArray.removeAt(i);
  }




  track(item: any, index: number) {
    return index;
  }



}
