<main class="mt-5">
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,1,0" />
  <!-- header part -->
  <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 text-left" *ngIf="worker">
          <span class="text-decoration-none" style="font-size: xx-large;">Compliances</span>
        </div>
        <div class="col-12 col-sm-6 col-md-4 text-md-right text-sm-left mt-2 mt-md-0">

        </div>
      </div>
  </div>

  <!-- content -->
  <section class="content mt-4">
      <div class="container-fluid">
          <div class="row" *ngIf="worker">
              <div class="col-md-12">
                  <div class="card">
                    <div class="">
                      <div class=" tab-column">
                          <div class="tab-column-inner tab-column-inner-bottom">
                              <div class="">
                                  <button class="btn btn-success ml-auto" *ngIf="userType == 'worker'" (click)="openModal(add)">Upload Compliances</button>
                              </div>
                          </div>
                          <div class="tab-column-inner ">
                              <div class="tab-content-info">
                                  <table id="customers">
                                      <tr >
                                      <th>Name</th>
                                      <th>Uploaded</th>
                                      <th>Expires</th>
                                      <!-- <th class="text-center">Status</th> -->
                                      <th class="text-center">Actions</th>
                                      </tr >
                                      <tr *ngFor="let compliance of compliances;">
                                      <td>{{compliance.name}}</td>
                                      <!-- <td>{{compliance.description}}</td> -->
                                      <td>{{compliance.complianceDate}}</td>
                                      <td>{{compliance.complianceExpiry}}


                                        <img *ngIf="checkExpiry(compliance.complianceExpiry) == 'alert'" style="width:15px"  title="About to expire" alt="danger icon" src="/assets/images/alert_caution.png">
                                        <img *ngIf="checkExpiry(compliance.complianceExpiry) == 'expired'" style="width:15px"  title="Expired" alt="expired icon" src="/assets/images/alert_danger.png">
                                      </td>
                                        <td class="text-center" >
                                          <span class="mr-3 text-primary-custom pointer" (click)="selectedCompliance(compliance); openModal(view)">
                                            <i class="fa fa-eye" title="view"></i>
                                          </span>
                                          <span class="mr-3 text-success pointer" title="Edit"  (click)="selectedCompliance(compliance); openModal(edit)">
                                            <i class="fa fa-pencil-alt" title="edit"></i>
                                          </span>
                                          <span class="mr-3 text-danger-custom pointer" title="Delete"  (click)="selectedCompliance(compliance); openModal(delete)">
                                            <i class="fa fa-trash" title="delete"></i>
                                          </span>
                                        </td>                                      </tr>
                                  </table>
                              </div>
                          </div>

                      </div>
                  </div>
                  </div>
              </div>
          </div>
      </div>
  </section>

  <!-- Add Compliance & Qualifications Modal -->
  <ng-template class="modal fade" #add let-modal>
    <app-worker-compliance-modal></app-worker-compliance-modal>
  </ng-template>

  <!-- Add Compliance & Qualifications Modal -->
  <ng-template class="modal fade" #addCompliance let-modal>
    <div class="">
      <div class="modal-header bg-main text-white">
        <h5 class="modal-title text-center mx-4 w-100">Add Compliance</h5>
        <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
          (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </span>
      </div>
      <div class="modal-body p-0">
        <form [formGroup]="addComplianceForm" (ngSubmit)="addWorkerCompliance(addComplianceForm)">
          <input type="hidden" formControlName="agencyId" value="{{agencyId}}" />
          <input type="hidden" formControlName="workerId" value="{{workerId}}" />
          <input formControlName="code" type="hidden"  placeholder="Code"
                      class="form-control">
          <input formControlName="code" type="hidden"  placeholder="Code"
                      class="form-control">
          <input type="hidden" formControlName="serviceId" value="1" />
          <div class="bg-modal card-body">
            <div class="container-fluid">
              <div class="">
                <div class="">
                  <i class="bi bi-info-circle"></i>
                  <span class="ml-2">Compliance</span>
                  <hr class="bg-black mt-0">
                </div>
                <!-- <div class="">
                  <div class="form-group">
                    <label for="" class="modal-label">Name</label>
                    <input formControlName="name" type="hidden"  placeholder="Name"
                      class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="" class="modal-label">Code</label>
                    <input formControlName="code" type="hidden"  placeholder="Code"
                      class="form-control">
                  </div>
                </div> -->
                <div class="">
                  <div class="form-group">
                    <select formControlName="complianceId" class="form-control w-content" style="width:100%">
                      <option value="" selected disabled>Select compliance</option>
                      <option *ngFor="let compliance of complianceAll;" value="{{compliance.id}}">{{compliance.name}}</option>
                    </select>
                  </div>
                  <!-- <div class="form-group">
                    <label for="" class="modal-label">DESCRIPTION</label>
                    <input formControlName="description" type="text"  placeholder="Description"
                      class="form-control">
                  </div> -->
                </div>
                <div class="">
                  <div class="form-group">
                    <label for="" class="modal-label">Issue Date</label>
                    <input formControlName="complianceDate" type="date"  placeholder="Issue Date"
                      class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="" class="modal-label">ExpiryDate</label>
                    <input formControlName="complianceExpiry" type="date"  placeholder="ExpiryDate"
                      class="form-control">
                  </div>
                </div>
                <div align="right" class="mt-3">
                  <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-sm btn-success">
                    Add Compliance
                  </button>
                </div>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  </ng-template>

</main>


<!-- Modals -->
<ng-template class="modal fade" #view let-modal>
<div class="">
  <div class="modal-header bg-main text-white">
    <h5 class="modal-title text-center w-100">View Document</h5>
    <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
      (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </span>
  </div>
  <div class="modal-body">
    <form [formGroup]="updateComplianceForm">
      <div class="bg-modal card-body">
        <div class="container-fluid">
          <section>
              <div class="col-12">
                <i class="bi bi-info-circle"></i>
                <!-- <a *ngIf="activeCompliance.document" [href]="activeCompliance.document" target="_blank">Click To View Document</a> -->
                <a *ngIf="activeCompliance.document" (click)="openDoc(activeCompliance.document)" target="_blank"> Click To View Document</a>

                <p *ngIf="!activeCompliance.document" >Document Not Uploaded</p>
              </div>
              <!-- <div class="row">
                <div class="form-group col-12 col-sm-12 col-md-12">
                  <label for="comment">Comment</label>
                  <input type="text" maxlength="255" formControlName="comment" placeholder="Comment" class="form-control">
                </div>
              </div> -->

          </section>
        </div>
      </div>
      <div align="right" class="mt-3">
        <!-- <button type="button" class="btn btn-danger btn-sm mr-3"   (click)="updateCompliance(updateComplianceForm, 'REJECTED')" >
          Reject
        </button>
        <button type="submit" class="btn btn-sm btn-success" (click)="updateCompliance(updateComplianceForm, 'APPROVED')">
          Approve
        </button> -->
      </div>
    </form>
  </div>
</div>
</ng-template>


  <!-- Add Compliance & Qualifications Modal -->
  <ng-template class="modal fade" #delete let-modal>
    <div class="">
      <div class="modal-header bg-main text-white">
        <h5 class="modal-title text-center mx-4 w-100">Delete Compliance</h5>
        <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
          (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </span>
      </div>
      <div class="modal-body">
        <form [formGroup]="addComplianceForm" (ngSubmit)="deleteCompliance(addComplianceForm)">
          <p class="text-danger-custom my-1">Are you sure you want to delete this compliance?</p>

          <input type="hidden" formControlName="agencyId" value="{{agencyId}}" />
          <input type="hidden" formControlName="workerId" value="{{workerId}}" />
          <input formControlName="code" type="hidden"  placeholder="Code"
                      class="form-control">
          <input formControlName="code" type="hidden"  placeholder="Code"
                      class="form-control">
          <input type="hidden" formControlName="serviceId" value="1" />
          <div class="bg-modal card-body">
            <div class="container-fluid">
              <div class="">
                <div class="">
                  <i class="bi bi-info-circle"></i>
                  <span class="ml-2">Compliance</span>
                  <hr class="bg-black mt-0">
                </div>
                <div class="">
                  <div class="form-group">
                    <select formControlName="complianceId" disabled class="form-control w-content w-100" >
                      <option value="" selected disabled>Select compliance</option>
                      <option *ngFor="let compliance of complianceAll;" value="{{compliance.id}}">{{compliance.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="">
                  <div class="form-group">
                    <label for="" class="modal-label">Issue Date</label>
                    <input formControlName="complianceDate" disabled type="date"  placeholder="Issue Date"
                      class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="" class="modal-label">ExpiryDate</label>
                    <input formControlName="complianceExpiry" disabled type="date"  placeholder="ExpiryDate"
                      class="form-control">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div align="right" class="mt-3">
            <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
              Cancel
            </button>
            <button type="submit" class="btn btn-sm btn-success">
              Delete
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>


    <!-- Add Compliance & Qualifications Modal -->
    <ng-template class="modal fade" #edit let-modal>
      <div class="">
        <div class="modal-header bg-main text-white">
          <h5 class="modal-title text-center mx-4 w-100">Upload Certificate</h5>
          <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </span>
        </div>
        <div class="modal-body">
          <form [formGroup]="addComplianceForm" (ngSubmit)="updateCompliance(addComplianceForm)">
            <input type="hidden" formControlName="agencyId" value="{{agencyId}}" />
            <input type="hidden" formControlName="workerId" value="{{workerId}}" />
            <input formControlName="code" type="hidden"  placeholder="Code"
                        class="form-control">
            <input formControlName="code" type="hidden"  placeholder="Code"
                        class="form-control">
            <input type="hidden" formControlName="serviceId" value="1" />
            <div class="bg-modal card-body">
              <div class="container-fluid">
                <div class="">
                  <div class="">
                    <i class="bi bi-info-circle"></i>
                    <span class="ml-2">Compliance</span>
                    <hr class="bg-black mt-0">
                  </div>
                  <div class="">
                    <div class="form-group">
                      <select formControlName="complianceId" disabled class="form-control w-content" style="width:400px">
                        <option value="" selected disabled>Select compliance</option>
                        <option *ngFor="let compliance of complianceAll;" value="{{compliance.id}}">{{compliance.name}}</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="" class="modal-label">Certificate</label><br>
                        <input    type="file" id="file" (change)="onChange($event)" accept="application/pdf">
                    </div>
                  </div>
                  <div class="">
                    <div class="form-group">
                      <label for="" class="modal-label">Issue Date</label>
                      <input formControlName="complianceDate" disabled type="date"  placeholder="Issue Date"
                        class="form-control">
                    </div>
                    <div class="form-group">
                      <label for="" class="modal-label">ExpiryDate</label>
                      <input formControlName="complianceExpiry" disabled type="date"  placeholder="ExpiryDate"
                        class="form-control">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div align="right" class="mt-3">
              <button type="button" class="btn btn-danger btn-sm mr-3" (click)="modal.dismiss()">
                Cancel
              </button>
              <button type="submit" class="btn btn-sm btn-success">
                Upload Certificate
              </button>
            </div>
          </form>
        </div>
      </div>
    </ng-template>
