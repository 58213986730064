<!-- Page Wrapper -->
<div id="wrapper" class=" d-sm-flex d-md-flex" class="nav-item" aria-controls="mySection"  aria-expanded="true" data-toggle="collapse" data-target="#mySection">
  <div id="mySection" class="collapse"  data-parent="#accordionSidebar"></div>

    <!-- Sidebar -->
    <app-side-bar [userType]="'worker'" class="sideFixed"></app-side-bar>
    <!-- End of Sidebar -->

    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column bg-white mainMargin">

        <!-- Main Content -->
        <div id="content">

            <!-- Topbar -->
            <app-nav-bar [userType]="'worker'"></app-nav-bar>
            <!-- End of Topbar -->

            <!-- Begin Page Content -->
            <router-outlet id="main"></router-outlet>
            <!-- /.container-fluid -->

        </div>
        <!-- End of Main Content -->

        <!-- Footer -->
        <app-footer></app-footer>
        <!-- End of Footer -->

    </div>
    <!-- End of Content Wrapper -->

</div>
<!-- End of Page Wrapper -->

<!-- width overlay -->
<!-- <div class="container-fluid d-block d-md-none" id="myWidthOverlay" style="display: none;">
    <section class="bg-white" style="margin-top: 10rem !important;">
        <div class="text-center">
            <div class="mt-5">
                <figure>
                    <img style="width: 50%;" src="/assets/images/width_overlay.png" alt="width_overlay" />
                </figure>
            </div>
            <div class="pt-3">
                <h1 class="font-weight-bolder">
                    WOOPS!
                </h1>
                <p class="font-weight-bold text-wrap">
                    Unfortunately, this site is not compatible with mobile devices as some features will break.
                    Please access this site via a tablet or desktop computer.
                </p>
            </div>
        </div>
    </section>
</div> -->
