
    <div class="container">
      <form [formGroup]="form" >


        <div class="  text-bold  ">
          <h5>Patient Experience </h5>
        </div>

        <div class="mt-3 card p-2 bg-grey">

                <div class="row text-bold">
                  <div class="col-6">
                    <span></span>
                  </div>
                        <div class="col">Very Unsatisfied</div>
                        <div class="col">unSatisfied</div>
                        <div class="col"> Neutral</div>
                        <div class="col">Satisfied</div>
                        <div class="col">Very Satisfied</div>
                </div>



                <div class="row">
                      <div class="col-6">
                        <span>Cleanliness of the vehicle</span>
                      </div>

                      <div class="col">
                        <input type="radio" formControlName="pfCleanliness" [value]="1">
                      </div>


                        <div class="col">
                          <input type="radio" formControlName="pfCleanliness" [value]="2">

                        </div>

                      <div class="col">
                        <input type="radio" formControlName="pfCleanliness" [value]="3">
                      </div>

                      <div class="col">
                        <input type="radio" formControlName="pfCleanliness" [value]="4">

                      </div>

                    <div class="col">
                      <input type="radio" formControlName="pfCleanliness" [value]="5">
                    </div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <span>Courtesy of Ambulance team</span>
                  </div>

                  <div class="col">
                    <input type="radio" formControlName="pfCourtesy" [value]="1">
                  </div>


                    <div class="col">
                      <input type="radio" formControlName="pfCourtesy" [value]="2">

                    </div>

                  <div class="col">
                    <input type="radio" formControlName="pfCourtesy" [value]="3">
                  </div>

                  <div class="col">
                    <input type="radio" formControlName="pfCourtesy" [value]="4">

                  </div>

                <div class="col">
                  <input type="radio" formControlName="pfCourtesy" [value]="5">
                </div>
            </div>

            <div class="row">
              <div class="col-6">
                <span>Knowledge of Ambulance team</span>
              </div>

              <div class="col">
                <input type="radio" formControlName="pfKnowledge" [value]="1">
              </div>


                <div class="col">
                  <input type="radio" formControlName="pfKnowledge" [value]="2">

                </div>

              <div class="col">
                <input type="radio" formControlName="pfKnowledge" [value]="3">
              </div>

              <div class="col">
                <input type="radio" formControlName="pfKnowledge" [value]="4">

              </div>

              <div class="col">
                <input type="radio" formControlName="pfKnowledge" [value]="5">
              </div>
          </div>

          <div class="row">
            <div class="col-6">
              <span>Treatment of you/your relative</span>
            </div>

            <div class="col">
              <input type="radio" formControlName="pfTreatment" [value]="1">
            </div>


              <div class="col">
                <input type="radio" formControlName="pfTreatment" [value]="2">

              </div>

            <div class="col">
              <input type="radio" formControlName="pfTreatment" [value]="3">
            </div>

            <div class="col">
              <input type="radio" formControlName="pfTreatment" [value]="4">

            </div>

            <div class="col">
              <input type="radio" formControlName="pfTreatment" [value]="5">
            </div>
          </div>


        <div class="row">
          <div class="col-6">
            <span>Did you feel safe during transportation</span>
          </div>

          <div class="col">
            <input type="radio" formControlName="pfAdvice" [value]="1">
          </div>


            <div class="col">
              <input type="radio" formControlName="pfAdvice" [value]="2">

            </div>

          <div class="col">
            <input type="radio" formControlName="pfAdvice" [value]="3">
          </div>

          <div class="col">
            <input type="radio" formControlName="pfAdvice" [value]="4">

          </div>

          <div class="col">
            <input type="radio" formControlName="pfAdvice" [value]="5">
          </div>
      </div>

      <div class="row">
        <div class="col-6">
          <span>Comfort for your journey</span>
        </div>

        <div class="col">
          <input type="radio" formControlName="pfComfort" [value]="1">
        </div>


          <div class="col">
            <input type="radio" formControlName="pfComfort" [value]="2">

          </div>

        <div class="col">
          <input type="radio" formControlName="pfComfort" [value]="3">
        </div>

        <div class="col">
          <input type="radio" formControlName="pfComfort" [value]="4">

        </div>

        <div class="col">
          <input type="radio" formControlName="pfComfort" [value]="5">
        </div>
    </div>


    <div class="row">
      <div class="col-6">
        <span>Overall experience</span>
      </div>

      <div class="col">
        <input type="radio" formControlName="pfExperience" [value]="1">
      </div>


        <div class="col">
          <input type="radio" formControlName="pfExperience" [value]="2">

        </div>

      <div class="col">
        <input type="radio" formControlName="pfExperience" [value]="3">
      </div>

      <div class="col">
        <input type="radio" formControlName="pfExperience" [value]="4">

      </div>

      <div class="col">
        <input type="radio" formControlName="pfExperience" [value]="5">
      </div>
  </div>
        </div>



        <div class="form-group mt-1">
          <textarea
            formControlName="pcomment"
            placeholder="Patient's Overall Comments"
            cols="20"
            rows="2"
            class="form-control"
            maxlength="20"
            required
          ></textarea>
        </div>


        <div class="mt-2 card p-2 bg-grey">
          <div class="row">
            <div class="col-2 text-bold">
              <label>Drop off time</label>
            </div>
            <div>
              <input type="datetime-local"  formControlName="dropTime"  class="form-control">
            </div>
          </div>
        </div>
        <div class="text-bold">
          <h5>Patient Handover </h5>
          <label>Patient was handed over to</label>
        </div>
        <div class="mt-2 card p-2 bg-grey">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <input type="text" maxlength="255" formControlName="patientRecipient" class="form-control" placeholder="Recipient name & Surname">
                </div>
                <div class="form-group">
                  <input type="text" maxlength="255" formControlName="recipientContact" class="form-control" placeholder="Recipient contact number or Email">
                </div>
                <div class="form-group">
                  <input type="text" maxlength="255" formControlName="recipientRole" class="form-control" placeholder="Recipient job role">
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <textarea maxlength="255" formControlName="recipientSignature" class="form-control w-100" style="height:199px" placeholder="Recipient's signature or Initials"></textarea>
                </div>
              </div>
            </div>
          </div>

        </div>


            <br>
            <div class="  text-bold  ">
              <h5>Confirm Destination </h5>
            </div>
            <div class="mt-2 pt-4 card p-2 bg-grey">


            <div class="container">
              <div class="row">
                <div class="col-12 col-md-6">
                  <label >Address</label>
                  <span class="ml-2">{{job?.destination}}</span>
                </div>
                <div class="col-12 col-md-6">
                  <label>Post code</label>
                  <span class="ml-2">{{job?.destinationPostCode}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <label >Phone</label>
                  <span class="ml-2">{{job?.destinationContactNumber}}</span>
                </div>
                <div class="col-12 col-md-6">
                  <label >Email</label>
                  <span class="ml-2">{{job?.demail}}</span>
                </div>
              </div>
            </div>
            </div>


            <br>
            <br>

            <div class="">
              <div class="row">
                <div class="col-6">
                  <label>Was the patient dropped off at the above destination?</label>
                </div>
                <div class="col-2">
                  <div>
                    <label for=""><input type="radio"  (click)="setNewLocation(false)" formControlName="newLocation" [value]="false">Yes  </label>
                  </div>
                </div>
                <div class="col-2">
                  <div>
                    <label for=""><input type="radio"  (click)="setNewLocation(true)" formControlName="newLocation" [value]="true">No</label>
                  </div>
                </div>
              </div>


              <div *ngIf="form.value.newLocation==true">
                <div class="  text-bold  ">
                  <h5>Enter New Destination</h5>
                </div>
                <div class="mt-2 pt-4 card p-2 bg-grey">
                  <div class="container">
                    <div class="row">
                      <div class="form-group col-12 col-sm-6 col-md-6">
                        <input type="text" maxlength="255" formControlName="newAddress" class="form-control" placeholder="Address">
                      </div>
                      <div class="form-group col-12 col-sm-6 col-md-6">
                        <input type="text" maxlength="255" formControlName="newPostCode" class="form-control" placeholder="Post Code">
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-12 col-sm-6 col-md-6">
                        <input type="text" maxlength="255" formControlName="newPhone" class="form-control" placeholder="Phone">
                      </div>
                      <div class="form-group col-12 col-sm-6 col-md-6">
                        <input type="text" maxlength="255" formControlName="newEmail" class="form-control" placeholder="Email">
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>

            <!-- New Destination Tab -->



          <!--Emoji table --->




        <!-- Courtesy -->

        <!-- Knowledge -->

        <!-- Treatment -->

        <!-- Advice -->

        <!-- Comfort -->

        <!-- Overall -->

          <hr>


    <hr>


    <!-- Disclaimer -->
    <div class="mt-1 col-12 col-md-">
      <Label> I confirm that the information entered on this log is correct
        <span>
          <input type="checkbox" class="checkbox" formControlName="signature"   [value]="true" >
        </span>
      </Label>&nbsp;

    </div>

    <!-- Navigation Buttons -->
    <div class=" col-12 mt-2 text-right">
      <button type="button" class="btn-danger text-right mr-1" (click)="prevTab()">Back</button>
      <button type="submit" class="btn-primary text-right" (click)="updateTeamLeader(edit)">Next</button>
    </div>
  </form>
  </div>




<ng-template class="modal fade" #edit let-modal>
  <div class="">
      <div class="modal-header bg-main text-white">
          <h5 class="modal-title text-center w-100">Successful</h5>
          <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </span>
      </div>
      <div class="modal-body">
        <form class="form" [formGroup]="loginForm" (ngSubmit)="loginUser(loginForm)">

          <div class="m-3">
            <p>
              Job was completed & log was submitted for
              authorisation.</p>
              Proceed to enter individual worker times.
          </div>
          <br>
            <div class="center">
              <button type="submit" class="btn btn-success btn-info bg-core-lime" (click)="nextTab();modal.dismiss()" >Ok</button>
            </div>
        </form>
      </div>
  </div>
</ng-template>


<ng-template class="modal fade" #access let-modal>
  <div class="">
      <div class="modal-header bg-main text-white">
          <h5 class="modal-title text-center w-100">Access Control</h5>
          <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </span>
      </div>
      <div class="modal-body">
        <form class="form" [formGroup]="loginForm" (ngSubmit)="loginUser(loginForm)">

          <div class="m-3">
            <p>
              You are about to access patient confidential information. Please enter emailed passcode to continue                         </p>
          </div>
          <br>

          <input
              type="text"
              class="form-control mb-4"

              placeholder="*  *  *  *  *" formControlName="username"
            />
            <br>

          <div class="ok">
            <div class="center">
              <button type="button" class="btn-danger text-right mr-1" (click)="prevTab()">Cancel</button>
              <button type="submit" class="btn btn-success btn-info bg-core-lime" (click)="updateTeamLeader(edit);" >Enter</button>
            </div>
          </div>



        </form>
      </div>
  </div>
</ng-template>

