<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Playball&display=swap" rel="stylesheet">


<div style="background-color: rgb(226, 225, 225);" class="p-4 hideMePrintMargins">
  <div class="text-default text-center m-4 hideMePrintMargins" style="width:9.9in; height:13.7in; padding: 1in; background-color: white;">
    <div class="text-right text-code">
      <!-- D 785692 -->
      {{(training.id).toLocaleString('en-US', {minimumIntegerDigits: 6, useGrouping:false})}}
    </div>
    <div class="col-6 m-auto">
      <img class="logo-sm my-auto" style="max-height: 2in; max-width: 4in;" alt="agency logo" src="{{training.agency.logo}}"  onError="this.src='assets/images/logo.png'">
    </div>
    <div class="text-title mt-4 pt-4">
      THIS IS TO CERTIFY THAT
    </div>
    <div class="text-name m-4 p-4">
      {{training.worker.firstname}} {{training.worker.lastname}}
    </div>
    <div class="text-title mt-3" >
      HAS BEEN AWARDED THE
    </div>
    <div class="text-title text-bold" >
      {{training.name}}
    </div>
    <div class="mt-3 text-justify">
      Having satisfied the examiners in the designated areas, and is
      therefore entitled the proffessional priviledges and responsibilities
      emanating therefrom.
    </div>
    <div class="row text-faded mt-5 text-left">
      <div class="col-5">COMPLETED:</div>
      <div class="col-4">{{training?.trainingDate}}</div>
    </div>
    <div class="row text-faded text-left">
      <div class="col-5">EXPIRES/REVIEW ON:</div>
      <div class="col-4">{{training?.trainingExpiry}}</div>
    </div>

    <div class="row" style="margin-top: 1in;">
      <div class="col-6 text-left">

        <hr class="mt-4">
        {{training.supervisor}} <br>
        Supervisor
        <div class="text-bold">
          {{training.agency.name}}
        </div>
      </div>

      <div class="col-6 text-right">
        Powered By <br>
        <img
          class="w-50"
          src="assets/images/logo.png"
          alt="logo"
        />
      </div>
    </div>
  </div>

</div>
