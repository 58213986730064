import { ClientService } from './../../../shared/services/client.service';
import { AuthService } from './../../../shared/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { WorkerService } from './../../../shared/services/worker.service';
import { FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';


import { Component, OnInit, ElementRef, ViewChild, Inject, ViewEncapsulation} from '@angular/core';
import { Router } from '@angular/router';
import { AssignmentCodeService } from 'src/app/shared/services/assignment-code.service';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { UsersService } from 'src/app/shared/services/users.service';
import { StorageService } from 'src/app/shared/services/storage.service';

import {MatChipInputEvent} from '@angular/material/chips';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatCalendarCellClassFunction, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';




declare var $: any;
export interface Fruit {
  name: string;
}
export interface DialogData {
  animal: string;
  name: string;
}

export interface allWorkers {
  email: string;
  name: string;
}

@Component({
  selector: 'app-workers-list',
  templateUrl: './workers-list.component.html',
  styleUrls: ['./workers-list.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class WorkersListComponent implements OnInit {

  bookedDates = [];
  unavailableDates = [];
  animal: string;
  name: string;


  visible = true;
  selectable = true;
  removable = true;


  workers: any;
  allWorkers: any;
  assCodes: any;
  selectedWorker: any;
  workerId: number;

  addForm: FormGroup;
  editForm: FormGroup;

  page = 1;
  pageSize = 25;
  totalItems = 0;
  showin = 0;
  first: boolean;
  last: boolean;

  userType: string;
  new: boolean = true;
  existing: boolean;
  formArea: boolean;
  query = new FormControl('');
  currentBookings: any;
  bookedShifts: any;
  searchResult: boolean;

  searchCriteria:any;
  activeWorker: any;
  activeAgencies: any;
  agencyId: any;
  clientId: any;

  constructor(
    private router: Router,
    private workerService: WorkerService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private assignmentCodeService: AssignmentCodeService,
    private authService: AuthService,
    private agencyService: AgencyService,
    private usersService: UsersService,
    private clientService: ClientService,
    private storageService: StorageService,
    public dialog: MatDialog
  ) {
    this.userType = authService.getUserType();

   }

  ngOnInit(): void {
    this.agencyId = this.storageService.decrypt(localStorage.getItem('agentId'));
    this.clientId = this.storageService.decrypt(localStorage.getItem('clientId'));

    this.getWorkers();
    this.getAssCodes();

    this.editForm = this.fb.group({
      id: 0
    });


  }

  formInit(modal){
    this.addForm = this.fb.group({
      agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
      assignmentCode: ['', Validators.required],
      email: ['', Validators.required],
      firstname: ['', Validators.required],
      dob: ['', Validators.required],
      gender: ['', Validators.required],
      lastname: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      username: ['', Validators.required]
    });

    this.new = true;
    this.existing = false;

    this.openModal(modal);
  }


  detailsPage(ref){
    if(ref){
      this.router.navigate([`/${this.userType}/workers/info/${ref}`]);
    }
  }
  detailsPageClient(ref){
    if(ref){
      this.router.navigate([`/${this.userType}/workers/info/${this.activeWorker.id}/${ref}`]);
    }
  }

  handlePageChange(event) {

    if(event == 'next'){
      this.page += 1;
    }
    else if(event == 'prev' && this.page != 1){
      this.page -= 1;
    }

    this.getWorkers();
  }

  getWorkers(){
    // console.log(this.userType);

    if(this.userType == 'admin'){
      this.workerService.getPaginatedWorkers(this.page - 1, this.pageSize).subscribe(
        data => {
          // console.table(data.content);
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }
          let array = data.content;
          array.sort(function(a, b){
              if(a.firstname < b.firstname) { return -1; }
              if(a.firstname > b.firstname) { return 1; }
              return 0;
          })

          this.workers = array;
        }
      );
    }
    else if(this.userType == 'agency'){
      this.agencyService.getPaginatedAgencyWorkers(this.page - 1, this.pageSize, this.storageService.decrypt(localStorage.getItem('agentId'))).subscribe(
        data => {
          console.table(data.content);
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }
          let array = data.content;
          array.sort(function(a, b){
              if(a.firstname < b.firstname) { return -1; }
              if(a.firstname > b.firstname) { return 1; }
              return 0;
          })

          this.workers =array;
        }
      );
    }
    else{
      this.clientService.getPaginatedClientWorkers(this.page - 1, this.pageSize, this.storageService.decrypt(localStorage.getItem('clientId'))).subscribe(
        data => {
          // console.table(data.content);
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }
          let array = data.content;
          array.sort(function(a, b){
              if(a.firstname < b.firstname) { return -1; }
              if(a.firstname > b.firstname) { return 1; }
              return 0;
          })
          this.workers = array;
        }
      );
    }
  }

  searchWorkers(searchCriteria){

    if(!searchCriteria.replace(/[\s]/g, '')){
      this.ngOnInit();
    }else{
      this.workerService.searchAgencyWorkers(searchCriteria, this.agencyId, this.page - 1, this.pageSize).subscribe(
        data => {
          // console.table(data.content);
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;
          this.searchResult = true;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }
          let array = data.content;
          array.sort(function(a, b){
              if(a.firstname < b.firstname) { return -1; }
              if(a.firstname > b.firstname) { return 1; }
              return 0;
          })

          this.workers = array;
        }
      );
    }

  }
  searchClientWorkers(searchCriteria){

    if(!searchCriteria.replace(/[\s]/g, '')){
      this.ngOnInit();
    }else{
      this.workerService.searchClientWorkers(searchCriteria, this.clientId, this.page - 1, this.pageSize).subscribe(
        data => {
          // console.table(data.content);
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;
          this.searchResult = true;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }
          let array = data.content;
          array.sort(function(a, b){
              if(a.firstname < b.firstname) { return -1; }
              if(a.firstname > b.firstname) { return 1; }
              return 0;
          })

          this.workers = array;
        }
      );
    }

  }

  getAllWorkers(){
    this.workerService.getPaginatedWorkers(0, 100).subscribe(
      data => {
        // console.table(data.content);

        this.allWorkers = data.content;
      }
    );
  }

  getAssCodes(){
    this.assignmentCodeService.getPaginatedAssignmentCodes(0, 100).subscribe(
      data => {
        let assCodes = data;
        if(data.content)
        {
          assCodes = data.content;
        }
        this.assCodes = assCodes;
      }
    );
  }

  getWorkerAgencies(id){


    this.workerService.getPaginatedWorkerAgencies(0, 100, id.id).subscribe(
      data => {
        console.table(data.content);
        this.activeAgencies = data.content;
      },
      err => {

      }
    );

}

  openModal(modal, i?) {
    if(i){
      // this.getWorkerAgencies(i);
      this.getWorkerShifts(modal, i);
      // this.getWorkerBookedDate(i);
      this.getWorkerAvailableDate(i);
      // this.dateClass();
    };
    this.activeWorker = i;
  }

  openModal2(modal, i?) {
    if(i){
      this.getWorkerAgencies(i);
    };
    this.activeWorker = i;
    this.modalService.open(modal, {centered: true, size: 'lg'});
  }

  openCalander(modal, i) {
    this.openModal(modal,i);
  }

  openDialog(): void {
    this.getAllWorkers()
    const dialogRef = this.dialog.open(WorkerListDialog, {
      width: '900px',
      data: {name: this.name, animal: this.animal},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.animal = result;
    });
  }

  newWorker(form: FormGroup){
    // console.table(form.value);

    if(form.valid){
      this.workerService.createWorker(form.value).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          this.getWorkers();
          this.toast.success('Worker Added Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }
  }

  loadWorkerInfo(ele){
    let ref = ele.target.value;

    // console.log(ref);
    if(ref){
      if(ref != 'NEW'){
        let r;

        this.allWorkers.forEach(w => {
          if(w.id == ref){
            r = w;
            this.workerId = w.id;
          }
        });

        this.addForm = this.fb.group({
          agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
          assignmentCode: [r.assignmentCodeId, Validators.required],
          email: [r.email, Validators.required],
          firstname: [r.firstname, Validators.required],
          dob: [r.dob, Validators.required],
          gender: [r.gender, Validators.required],
          lastname: [r.lastname, Validators.required],
          phoneNumber: [r.phoneNumber, Validators.required],
          username: [r.username, Validators.required]
        });

        this.new = false;
        this.existing = true;
        this.formArea = true;
      }
      else{
        this.addForm = this.fb.group({
          agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
          assignmentCode: ['', Validators.required],
          email: ['', Validators.required],
          firstname: ['', Validators.required],
          dob: ['', Validators.required],
          gender: ['', Validators.required],
          lastname: ['', Validators.required],
          phoneNumber: ['', Validators.required],
          username: ['', Validators.required]
        });

        this.new = true;
        this.existing = false;
        this.formArea = false;
      }
    }

  }

  updateWorker(form: FormGroup){
    // console.table(form.value);

    if(form.valid){
      this.workerService.updateWorker(form.value).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          this.getWorkers();
          this.toast.success('Worker Updated Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }
  }

  loadWorker(ref, modal, edit){
    if(ref){
      this.workers.forEach(r => {
        if(r.id == ref){
          this.selectedWorker = r;
        }
      });

      if(edit && this.selectedWorker){
        this.editForm = this.fb.group({
          agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
          assignmentCodeId: [this.getAssCodeId(this.selectedWorker.assignmentName), Validators.required],
          email: [this.selectedWorker.email, Validators.required],
          firstname: [this.selectedWorker.firstname, Validators.required],
          gender: [this.selectedWorker.gender, Validators.required],
          lastname: [this.selectedWorker.lastname, Validators.required],
          phoneNumber: [this.selectedWorker.phoneNumber, Validators.required],
          username: [this.selectedWorker.username, Validators.required],
          status: [this.selectedWorker.status],
          id: [this.selectedWorker.id, Validators.required]
        });
      }
    }

     if(modal){
      this.openModal(modal);
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  checkEmail(ele){
    // console.log(ele);

    var typed = ele.target.value;

    if(this.validateEmail(typed)){
      $(ele.target).removeClass('is-invalid');
      $(ele.target).addClass('is-valid');
    }
    else{
      $(ele.target).removeClass('is-valid');
      $(ele.target).addClass('is-invalid');
    }
  }

  getAssCodeId(ref){
    let lid;

    if(ref){
      this.assCodes.forEach(r => {
        if(r.name == ref){
          lid = r.id;
        }
      });
    }

    return lid;
  }

  activateUser(ref, enable){

    this.usersService.changeWorkerStatus(ref, enable).subscribe(
      resp => {
        // console.log(resp);
        this.toast.success('User Status Changed.')
        this.getWorkers();
      }
    );

  }

  linkWorker(){
    // console.log(this.workerId);
    this.workerService.linkWorkerToAgent(this.workerId, this.storageService.decrypt(localStorage.getItem('agentId'))).subscribe(
      resp => {
        // console.table(resp);
        this.modalService.dismissAll();
        this.getWorkers();
        this.toast.success('Worker Linked Successfully');
      }
    );

  };


  getWorkerShifts(modal,  workerId){

    this.workerService.getWorkerShifts(0, 1000000, workerId, "BOOKED").subscribe(
      data => {
        console.table(data);

        this.bookedShifts = data;
        // data.forEach( e => {
        //   console.log(e);
        //   this.bookedShifts.push(e.shiftDate);
        // });

        // console.log(this.bookedShifts);

        this.currentBookings = [];
        this.bookedShifts.forEach(shift => {
          let bookDate = new Date(this.formatDate(shift.shiftDate))
          bookDate.setHours(0);

          let endDate = new Date(this.formatDate(shift.shiftEndDate));
          endDate.setHours(0);

          var dates = getDatesInRange(bookDate, endDate);

          dates?.forEach( e => {
            e.setHours(0);
            this.currentBookings.push(e.toString());
            console.log(' dates array', e)
          });


          let strDate = bookDate.toString();
          this.currentBookings.push(strDate);
        });
        console.log('this.currentBookings',this.currentBookings)

        this.dateClass();

        this.modalService.open(modal, {centered: true, size: 'lg'});
        // this.setDates();

      }
    );
  };

  // getWorkerAvailableDate(modal,workerId){
  //   console.log(workerId);
  //   this.workerService.getWorkerAvailableDate(workerId).subscribe(
  //     data => {
  //       console.log(data)
  //       let unavailableDates = [];
  //       data.content.map(item => {
  //         const [day, month, year] = item.date.split('-');
  //         unavailableDates.push(["20"+year, month, day].join('-'));

  //       })
  //       console.log(unavailableDates);
  //       this.unavailableDates = unavailableDates ;//

  //       this.openModal(modal, workerId);
  //     },
  //     err => {
  //
  //       if(err.status == 0){
  //         this.toast.error('Network Connection Failure');
  //       }
  //        else if(err.error?.message){
  //         this.toast.error(err.error.message);
  //       }else if(err.status == 500){
  //         this.toast.error('Internal Server Error')
  //       }
  //       else{
  //         this.toast.error('Request Failed: ' + err.status);
  //       }
  //     }
  //   );
  // };

  formatDate(date){
    const myArray = date.split("/");
    if(myArray.length>=2){
      let formateddate =myArray[2] + "-" +myArray[1] +"-"+myArray[0] ;
      return formateddate;
    }else{
      return date;
    }

  };


  getWorkerAvailableDate(workerId){
    this.workerService.getWorkerAvailableDate(workerId).subscribe(
      data => {
        const datepipe: DatePipe = new DatePipe('en-US')

        console.log(data)
        let unavailableDates = [];
        let unavailableDatesLiteral = [];
        data.content.map(item => {
          unavailableDatesLiteral.push(item.date);
          var d = new Date(item.date);
          d.setHours(0);
          console.log(d);
          unavailableDates.push(d.toString());

        })
        this.workers.find(i => i.id == workerId).unavailableDates = unavailableDatesLiteral;
        this.unavailableDates = unavailableDates ;
      }
    );
  };

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      date = new Date(date);
      const booked = this.currentBookings
        .map(strDate => new Date(strDate))
        .some(d => {
          return d.getDate() === date.getDate() && d.getMonth() === date.getMonth() && d.getFullYear() === date.getFullYear()
        });


      const unavailable = this.unavailableDates
        .map(strDate => new Date(strDate))
        .some(d => {
          return d.getDate() === date.getDate() && d.getMonth() === date.getMonth() && d.getFullYear() === date.getFullYear()
        });


      return booked ? 'booked-class' : unavailable ? 'unavailable-class' : "available-class";
    };
  };

}


@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'workers-list-dialog.component.html',
})
export class WorkerListDialog {
  animal: string;
  name: string;


  visible = true;
  selectable = true;
  removable = true;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl('');
  filteredFruits: Observable<string[]>;
  fruits: string[] = ['New Worker'];
  allFruits: string[] = [''] ;

  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;


  workers: any;
  allWorkers: any;
  assCodes: any;
  selectedWorker: any;
  workerId: number;

  addForm: FormGroup;
  editForm: FormGroup;

  page = 1;
  pageSize = 20;
  totalItems = 0;
  showin = 0;
  first: boolean;
  last: boolean;

  userType: string;
  new: boolean = true;
  existing: boolean;
  formArea: boolean;
  query = new FormControl('');
  email: any;
  bookedDates: any;
  currentBookings: any;

  constructor(
    private router: Router,
    private workerService: WorkerService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private assignmentCodeService: AssignmentCodeService,
    private authService: AuthService,
    private agencyService: AgencyService,
    private usersService: UsersService,
    private clientService: ClientService,
    private storageService: StorageService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<WorkerListDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this.userType = authService.getUserType();
    this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => (fruit ? this._filter(fruit) : this.allFruits.slice())),
    );
   }



  ngOnInit(): void {

    this.getWorkers();
    this.getAssCodes();

    this.editForm = this.fb.group({
      id: 0
    });

    this.getAllWorkers();
    this.getAllWorkers();


    this.addForm = this.fb.group({
      agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
      assignmentCode: ['', Validators.required],
      email: ['', Validators.required],
      firstname: ['', Validators.required],
      dob: ['', Validators.required],
      gender: ['', Validators.required],
      lastname: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      username: ['', Validators.required],
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog(){
    this.dialogRef.close();
  }

  addval(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    console.log(value);
    // this.loadWorkerInfo(value);

    // Add our fruit
    if (value) {
      this.fruits= [value];
      this.loadWorkerInfo(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.fruitCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits = ['NEW'];

    }
    this.loadWorkerInfo('value');
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fruits= [event.option.viewValue];
    this.loadWorkerInfo(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allFruits.filter(fruit => fruit?.toLowerCase().includes(filterValue));
  }


  detailsPage(ref){
    if(ref){
      this.router.navigate([`/${this.userType}/workers/info/${ref}`]);
    }
  }

  handlePageChange(event) {

    if(event == 'next'){
      this.page += 1;
    }
    else if(event == 'prev' && this.page != 1){
      this.page -= 1;
    }

    this.getWorkers();
  }

  getWorkers(){
    // console.log(this.userType);

    if(this.userType == 'admin'){
      this.workerService.getPaginatedWorkers(this.page - 1, this.pageSize).subscribe(
        data => {
          // console.table(data.content);
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }
          let array = data.content;
          array.sort(function(a, b){
              if(a.firstname < b.firstname) { return -1; }
              if(a.firstname > b.firstname) { return 1; }
              return 0;
          })

          this.workers = array;
        }
      );
    }
    else if(this.userType == 'agency'){
      this.agencyService.getPaginatedAgencyWorkers(this.page - 1, this.pageSize, this.storageService.decrypt(localStorage.getItem('agentId'))).subscribe(
        data => {
          // console.table(data.content);
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }
          let array = data.content;
          array.sort(function(a, b){
              if(a.firstname < b.firstname) { return -1; }
              if(a.firstname > b.firstname) { return 1; }
              return 0;
          })

          this.workers =array;
        }
      );
    }
    else{
      this.clientService.getPaginatedClientWorkers(this.page - 1, this.pageSize, this.storageService.decrypt(localStorage.getItem('clientId'))).subscribe(
        data => {
          // console.table(data.content);
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }
          let array = data.content;
          array.sort(function(a, b){
              if(a.firstname < b.firstname) { return -1; }
              if(a.firstname > b.firstname) { return 1; }
              return 0;
          })
          this.workers = array;
        }
      );
    }
  }

  getAllWorkers(){
    this.workerService.getPaginatedWorkers(0, 100).subscribe(
      data => {
        // console.table(data.content);

        this.allWorkers= data.content;
        this.allFruits = [];
        for (let index = 0; index < this.allWorkers.length; index++) {
          this.allFruits.push(this.allWorkers[index].email);

        }
        console.log(this.allFruits)
        // console.log(this.allWorkers[0].email)
      }
    );
  }

  getAssCodes(){
    this.assignmentCodeService.getPaginatedAssignmentCodes(0, 100).subscribe(
      data => {
        // console.table(data.content);
        this.assCodes = data;
      }
    );
  }

  openModal(modal) {
    this.modalService.open(modal, {centered: true, size: 'lg'});
  }



  newWorker(form: FormGroup){
    this.email = form.value.email;

    form.patchValue({
      username: this.email
    });

    console.table(form.value);

    if(form.valid){
      this.workerService.createWorker(form.value).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          this.dialogRef.close();
          this.getWorkers();
          this.toast.success('Worker Added Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }
    this.ngOnInit();

  }

  loadWorkerInfo(ele){
    let ref = this.fruits;

    // console.log(ref);
    if(ref){
      if(ref[0] != 'NEW'){
        let r;

        this.allWorkers.forEach(w => {
          if(w.email == ref){
            r = w;
            this.workerId = w.id;
          }
        });

        this.addForm = this.fb.group({
          agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
          assignmentCode: [r.assignmentCodeId, Validators.required],
          email: [r.email, Validators.required],
          firstname: [r.firstname, Validators.required],
          dob: [r.dob, Validators.required],
          gender: [r.gender, Validators.required],
          lastname: [r.lastname, Validators.required],
          phoneNumber: [r.phoneNumber, Validators.required],
          username: [r.username, Validators.required]
        });

        this.new = false;
        this.existing = true;
        this.formArea = true;
      }
      else{
        this.addForm = this.fb.group({
          agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
          assignmentCode: ['', Validators.required],
          email: ['', Validators.required],
          firstname: ['', Validators.required],
          dob: ['', Validators.required],
          gender: ['', Validators.required],
          lastname: ['', Validators.required],
          phoneNumber: ['', Validators.required],
          username: ['', Validators.required]
        });

        this.new = true;
        this.existing = false;
        this.formArea = false;
      }
    }

  }

  updateWorker(form: FormGroup){
    // console.table(form.value);

    if(form.valid){
      this.workerService.updateWorker(form.value).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          this.getWorkers();
          this.toast.success('Worker Updated Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }
  }

  loadWorker(ref, modal, edit){
    if(ref){
      this.workers.forEach(r => {
        if(r.id == ref){
          this.selectedWorker = r;
        }
      });

      if(edit && this.selectedWorker){
        this.editForm = this.fb.group({
          agencyId: [this.storageService.decrypt(localStorage.getItem('agentId'))],
          assignmentCodeId: [this.getAssCodeId(this.selectedWorker.assignmentName), Validators.required],
          email: [this.selectedWorker.email, Validators.required],
          firstname: [this.selectedWorker.firstname, Validators.required],
          gender: [this.selectedWorker.gender, Validators.required],
          lastname: [this.selectedWorker.lastname, Validators.required],
          phoneNumber: [this.selectedWorker.phoneNumber, Validators.required],
          username: [this.selectedWorker.username, Validators.required],
          status: [this.selectedWorker.status],
          id: [this.selectedWorker.id, Validators.required]
        });
      }
    }

    if(modal){
      this.openModal(modal);
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  checkEmail(ele){
    // console.log(ele);

    var typed = ele.target.value;

    if(this.validateEmail(typed)){
      $(ele.target).removeClass('is-invalid');
      $(ele.target).addClass('is-valid');
    }
    else{
      $(ele.target).removeClass('is-valid');
      $(ele.target).addClass('is-invalid');
    }
  }

  getAssCodeId(ref){
    let lid;

    if(ref){
      this.assCodes.forEach(r => {
        if(r.name == ref){
          lid = r.id;
        }
      });
    }

    return lid;
  }

  activateUser(ref, enable){

    this.usersService.changeWorkerStatus(ref, enable).subscribe(
      resp => {
        // console.log(resp);
        this.toast.success('User Status Changed.')
        this.getWorkers();
      }
    );

  }

  linkWorker(){
    console.log(this.workerId);
    this.workerService.linkWorkerToAgent(this.workerId, this.storageService.decrypt(localStorage.getItem('agentId'))).subscribe(
      resp => {
        // console.table(resp);
        this.modalService.dismissAll();
        this.getWorkers();
        this.toast.success('Worker Linked Successfully');
      }
    );

  };



}

function getDatesInRange(startDate, endDate) {
  const date = new Date(startDate.getTime());

  const dates = [];

  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
}
