<form [formGroup]="form" (ngSubmit)="updateMileage()" >
  <h5>Pick up</h5>
  <div class="card p-2 bg-grey">
    <div class="row">
      <div class="form-group col-6 col-sm-6 col-md-4">
        <label>Date</label>
        <input type="text" value="{{job?.dateTimeRequired| date:'dd/MM/yyyy'}}"  maxlength="255"  placeholder=""  class="form-control" disabled >
      </div>
      <!-- <div class="form-group col-6 col-sm-6 col-md-3">
        <label>Driver</label>
        <input type="text"  maxlength="255"  placeholder=""  class="form-control" disabled >
      </div> -->
      <div class="form-group col-6 col-sm-6 col-md-4">
        <label>Vehicle Reg</label>
        <input type="text" value="{{job?.vehicle?.regno}}" maxlength="255"  placeholder=""  class="form-control" disabled >
      </div>
      <div class="form-group col-6 col-sm-6 col-md-4">
        <label>Start Mileage</label>
        <input type="number"  maxlength="255" formControlName="startMileage"  placeholder=""  class="form-control"    >
      </div>


    </div>
  </div>
  <div class="mt-2 text-right">
    <button class="btn btn-danger btn-sm mr-3" onclick="history.back()">BACK</button>

<style>
  .small-length {
    width: 80px; 
  }
  .blue-btn {
    background-color:#04459C;
    color: white;
    border: none;
    padding: 9px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
  }
</style>
    <button type="submit" class="btn btn-primary" (click)="updateMileage(); nextTab();">NEXT</button>
  </div>

</form>
