import { AuthService } from './../../shared/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  msg: string;
  otp: string;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastrService,
    private authService: AuthService,
    private storageService: StorageService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {

    if(localStorage.getItem('access_token') && localStorage.getItem('userType')){
      this.router.navigateByUrl(`/${this.storageService.decrypt(localStorage.getItem('userType'))}`);
      this.toast.info('Session Restored');
    }

    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      otp: []
    });
  }

  registerPage(){
    this.router.navigate(['register']);
  }

  resetPage(){    
    this.router.navigate(['reset-password']);
  }

  loginUser(form: FormGroup, OTPmodal){
    this.msg = '';

    if(this.otp!=null && this.otp!=""){
      form.patchValue(
        {
           otp: this.otp
        }
      )
    }else{
      form.patchValue(
        {
           otp: null
        }
      )
    }

    if(form.valid){
      this.authService.userLogin(form.value).subscribe(
        resp => {
          console.table(resp);

          if(resp.access_token=='OTP_SENT') {
             this.modalService.open(OTPmodal, {centered: true, size: 'lg'});
             return null;
          }

          if(resp.access_token=='PASSWORD_NEEDS_RESET') {
            this.toast.warning("Your password is expired please reset your password.")
            return this.resetPage();
          }

          this.modalService.dismissAll();
          // localStorage.setItem('expires_on', setInterval(() => { resp.expires_in }, 60000));


          let permissions = [];
          resp.roles.forEach(e => {
            permissions = permissions.concat(e.permissions)
          });

          console.log(permissions);
          console.log(resp.roles[0].permissions);

          localStorage.setItem('access_token', this.storageService.encrypt(resp.access_token));
          localStorage.setItem('refresh_token', this.storageService.encrypt(resp.refresh_token));
          localStorage.setItem('display_name', this.storageService.encrypt(`${resp.lastName} .${resp.firstName.charAt(0)}`));
          localStorage.setItem('permissions', this.storageService.encrypt( JSON.stringify(permissions) ));

          // console.log(this.storageService.encrypt(resp.access_token));


          if(resp.userType == 'AGENCY'){
            //validate id
            if(!resp.agentId){
              this.toast.error('No Agency Identifier Found.');
              this.msg = 'No Agency Identifier Found.';
              return false;
            }
            // Redirect to agency module
            localStorage.setItem('userType', this.storageService.encrypt('agency'));
            localStorage.setItem('agentId', this.storageService.encrypt(resp.agentId.toString()));
            this.router.navigateByUrl('/agency');
          }
          else if(resp.userType == 'CLIENT'){
            //validate id
            if(!resp.clientId){
              this.toast.error('No Client Identifier Found.');
              this.msg = 'No Client Identifier Found.';
              return false;
            }
            // Redirect to client module
            localStorage.setItem('userType', this.storageService.encrypt('client'));
            localStorage.setItem('clientId', this.storageService.encrypt(resp.clientId.toString()));
            // localStorage.setItem('clientId', resp.clientId);
            this.router.navigateByUrl('/client');
          }
          else if(resp.userType == 'WORKER'){
            //validate id
            if(!resp.workerId){
              this.toast.error('No worker Identifier Found.');
              this.msg = 'No worker Identifier Found.';
              return false;
            }
            // Redirect to client module
            localStorage.setItem('userType', this.storageService.encrypt('worker'));
            localStorage.setItem('workerId', this.storageService.encrypt(resp.workerId.toString()));
            // localStorage.setItem('clientId', resp.clientId);
            this.router.navigateByUrl('/worker');
          }
          else if(resp.userType == 'ADMIN'){
            // Redirect to admin module
            localStorage.setItem('userType', this.storageService.encrypt('admin'));
            this.router.navigateByUrl('/admin');
          }
          else{
            this.toast.warning('Failed to determine user or Invalid role: ' + resp.roles[0]?.name);
          }
        },
        err => {

          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
          else if(err.error.message.includes("Bad credentials")){
            this.toast.error("Invalid Credentials");
          }
          else if(err.error.message){
            this.toast.error(err.error.message);
          }
          else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Login Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('Enter Credentials.');
    }
  }

}
