import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'src/app/shared/services/storage.service';
import { WorkerService } from 'src/app/shared/services/worker.service';
import { ServicesService } from 'src/app/shared/services/services.service';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { HttpResponse } from '@angular/common/http';
import { FileService } from 'src/app/shared/services/file.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-trainings',
  templateUrl: './trainings.component.html',
  styleUrls: ['./trainings.component.css']
})

export class TrainingsComponent implements OnInit {

  public userType: string;
  workerId: number;
  agencyId: number;
  worker: any;
  workerInfo: any;
  // workerProperty: any;
  jobCounts: any;
  trainings:any;
  trainingAll:any;
  jobs: any;
  fitToWork:any;
  // complianceInformation:any;
  notes:any;
  editInfoForm: FormGroup;
  addTrainingForm: FormGroup;
  editTrainingForm: FormGroup;
  addComplianceForm: FormGroup;
  updateTrainingForm: FormGroup;
  docLink = environment.API_url+"/worklink-api/tina/worklink/agency/"+localStorage.getItem('agentId')+"/compliance-2.png";
  updateComplianceForm: FormGroup;
  // complianceInformationForm: FormGroup;
  addNoteForm: FormGroup;
  addJobForm: FormGroup;
  edit = false;
  edit2 = false;
  editCompliance = false;
  assignmentCodes = [{id:1,name:'001 Cleaner'},{id:2,name:'002 Driver'},{id:1,name:'003 Nurse'}];
  paymentMethods = [{id:1,name:'Paye'},{id:2,name:'Umbrella'},{id:1,name:'Pvt. Ltd'}];
  services = [];
  activeTraining: any;
  complianceAll: any;
  compliances: any;
  trainingDoc: any;

  //trainingOptions = [{id:1,name:'Training 1'},{id:2,name:'Training 2'},{id:1,name:'Training 3'}];

  constructor(
    private activateRoute: ActivatedRoute,
    private workerService: WorkerService,
    private servicesService: ServicesService,
    private agencyService: AgencyService,
    private authService: AuthService,
    private fileService: FileService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private storageService: StorageService
  ){
    this.userType = authService.getUserType();
    if(localStorage.getItem('agentId') != null){

    }

    this.addTrainingForm = this.fb.group({
      agencyId: [1],
      workerId: [this.workerId,],
      name: ['',],
      code: ['',],
      serviceId: ['', ],
      trainingId: ['', ],
      trainingDate: ['', Validators.required],
      trainingExpiry: ['', Validators.required],
    });

    this.editTrainingForm = this.fb.group({
      agencyId: [1],
      workerId: [this.workerId,],
      name: [''],
      id: [''],
      code: ['',],
      serviceId: ['', ],
      trainingId: ['', ],
      trainingDate: ['', Validators.required],
      trainingExpiry: ['', Validators.required],
    });


    this.updateTrainingForm = this.fb.group({
      id: [Validators.required ],
      comment: [''],
      status: [Validators.required],
    });

    this.updateComplianceForm = this.fb.group({
      id: [Validators.required ],
      comment: [''],
      status: [Validators.required],
    });

    this.addNoteForm = this.fb.group({
    });
    this.addJobForm = this.fb.group({
      assignmentCodeId: ['', Validators.required],
      paymentMethodId: ['', Validators.required]
    });



   }

  ngOnInit(): void {

    this.activateRoute.params.subscribe((params) => {
      this.agencyId = params["id"]
    });
    this.workerId = this.storageService.decrypt(localStorage.getItem('workerId'));
    this.getWorker();
    this.getAllServices();
    this.getWorkerTrainings();
    this.getWorkerCompliances();
    this.getAllTrainings();
    this.getAllCompliances();

    this.docLink = environment.API_url+"/worklink-api/tina/worklink/worker/"+this.workerId+"/";



  }


  toggleEdit(){
    this.edit = !this.edit;
    console.log(this.editInfoForm.value);
  }
  toggleEdit2(){
    this.edit2 = !this.edit2;
  }
  toggleEditCompliance(){
    this.editCompliance = !this.editCompliance
  }
  toggleAddTraining (){

    this.editCompliance = !this.edit2;
  }

  openDoc(url){
    this.workerService.getContentTpye(url).subscribe(res => {
      if(res.headers.get('Content-Type')!='application/pdf') {
        return window.open(url);
      }else{
        this.viewDoc(url);
      }
    });
  }

  viewDoc(url){
    this.workerService.downloadPDF(url).subscribe(res => {
      console.log(res);
      if(res==null)
        window.open(url, '_blank');
      const fileURL = URL.createObjectURL(res);
      window.open(fileURL, '_blank');
    });
  }

  getWorker(){

    this.workerService.getWorkerById(this.workerId).subscribe(
      data => {
        this.worker = data;
        // console.log(this.worker)
        // this.agencyId = data.agencyId;
      }
    );
  }

  getAllTrainings(){
    this.workerService.getAllTrainings().subscribe(
      data => {
        // console.log('datadatadatadatadata' ,data)
        this.trainingAll = data;
      }
    );
  }
  getAllCompliances(){
    this.workerService.getAllCompliances().subscribe(
      data => {
        // console.log('datadatadatadatadata' ,data)
        this.complianceAll = data;
      }
    );
  }

  getAllServices(){
    this.servicesService.getAllServices().subscribe(
      data => {
        this.services = data;
        // console.log("----------------------",this.services)
      }
    );
  }
getWorkerJobCount(){
    this.workerService.getWorkerJobCount(this.workerId).subscribe(
      data => {
        this.jobCounts =
          [
            {asignmentCode : "678", jobs : "9", hrs : "88"},
            {asignmentCode : "105", jobs : "3", hrs : "60"},
            {asignmentCode : "307", jobs : "7", hrs : "95"},
            {asignmentCode : "624", jobs : "2", hrs : "66"},
          ]
        ;// delete this devip
        //this.jobCounts = data; // uncomment this devip
      }
    );
  }
  getWorkerJobs(){
    this.workerService.getWorkerJobs(this.workerId).subscribe(
      data => {
        this.jobs =
          [
            {id : "1", job : "Nurse"},
            {id : "2", job : "Cleaner"},
            {id : "3", job : "Driver"}
          ]
        ;// delete this devip
        //this.jobs = data; // uncomment this devip
      }
    );
  }

  getWorkerTrainings(){
    this.workerService.getWorkerTrainings(this.workerId).subscribe(
      data => {
        this.trainings = data.content; // uncomment this devip
      }
    );
  }

  getWorkerCompliances(){
    this.workerService.getWorkerCompliances(this.workerId).subscribe(
      data => {
        this.compliances = data.content; // uncomment this devip
      }
    );
  }



  toggleFitToWork(value){
    if(value == 'yes')
    {
      this.fitToWork = true;
    }
    else{
      this.fitToWork = false;
    }

  }

  submitFitToWork(){
    this.workerService.updateFitToWork(this.workerId,this.fitToWork).subscribe(
      resp => {
        this.getWorkerJobs();
        this.toast.success('Worker Fit To Work Updated Successfully');
      }
    );
  }


  addTraining(form: FormGroup){
    // console.table(form.value);
    if(form.valid){
      form.value.workerId =this.workerId ;
      form.value.agencyId = 1 ;
      form.value.serviceId =1 ;
      // console.log("----------", form.value);

      this.workerService.addTraining(form.value).subscribe(
        resp => {
          this.closeModals();
          this.getWorkerTrainings();
          this.toast.success('Worker Training Added Successfully');

          console.log(resp);
          this.uploadTraining(resp.id);

        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  updateTraining(form: FormGroup){
    form.value.id =this.activeTraining.id ;
    console.log(form.value);
    if(form.valid){

      if(this.trainingDoc==null){
        this.toast.info('Please select a certificate to upload.');
      }
      this.uploadTraining(form.value.id);

    }
    else{
      console.log(form.value);
      this.toast.warning('Missing Required Info.');
    }
  }

  deleteTraining(form: FormGroup){
    form.value.id =this.activeTraining.id ;
    console.log(form.value);
    if(form.valid){

      this.workerService.deleteTraining(form.value.id).subscribe(
        resp => {
          this.closeModals();
          this.getWorkerTrainings();
          this.toast.success('Worker training deleted successfully');
        }
      );
    }
    else{
      console.log(form.value);
      this.toast.warning('Missing Required Info.');
    }
  }


  openModal(modal){
    this.modalService.open(modal, {centered: true});
  };

  selectedTraining(training){
    console.log(training);
    this.activeTraining = training;

    this.editTrainingForm = this.fb.group({
      agencyId: [1],
      workerId: [this.workerId,],
      name: [training.name],
      id: [training.id],
      code: [training.code],
      serviceId: [1 ],
      trainingId: [training.trainingId],
      trainingDate: [training.trainingDate],
      trainingExpiry: [training.trainingExpiry],
    });
  };

  closeModals(){
    this.modalService.dismissAll();
  };

  onChange(event) {
    this.trainingDoc = event.target.files[0];
  }


  uploadTraining(training){
    console.log(this.trainingDoc);
    if(this.trainingDoc.size>=1048576){
      this.toast.warning("Maximum upload file size is 1mb")
    }
    else if(this.trainingDoc!=null){
      this.fileService.workerUploadTraining(this.trainingDoc,training ,this.storageService.decrypt(localStorage.getItem('workerId'))).subscribe(
        event => {
          if(event instanceof HttpResponse) {

          };
          this.toast.info("Sucessfully uploaded.");
          this.modalService.dismissAll();

        },
        (err) => {

          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
          else if(err.error?.message){
            this.toast.error(err.error.message);
          }
          else if(err.error?.error?.message){
            this.toast.error(err.error.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }

        }, () => {

        }
      );
    }
  }



  checkExpiry(date){
    var d = new Date(date);
    var dnow =  new Date();
    var d30 =  new Date();
    d30.setDate(dnow.getDate()+30);

    if(d30> d && d > dnow){
      return "alert";
    }
    if(d30> d){
      return "expired";
    }
    return null;
  }

  openCertificate(enrolmentId){
    this.workerService.getOnlineTrainingCertificate(enrolmentId).subscribe(
      data => {
        window.open(data.data.certificate_link.url, "_blank");

      }
    );
  }
}
