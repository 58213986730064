import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-agency-container',
  templateUrl: './agency-container.component.html',
  styleUrls: ['./agency-container.component.css']
})
export class AgencyContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $("#sidebarToggle, #sidebarToggleTop").on("click", function (e) { 
      $("body").toggleClass("sidebar-toggled"), 
      $(".sidebar").toggleClass("toggled"), 
      $(".sidebar").hasClass("toggled") && $(".sidebar .collapse").collapse("hide") 
    });
  }

}
