import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { AuthService } from './../../../../shared/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import { ShiftService } from 'src/app/shared/services/shift.service';
import { Component, Input, OnInit } from '@angular/core';
import { IfStmt } from '@angular/compiler';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TransportService } from 'src/app/shared/services/transport.service';

@Component({
  selector: 'app-new-transport',
  templateUrl: './new-transport.component.html',
  styleUrls: ['./new-transport.component.css']
})
export class NewTransportComponent implements OnInit {

  @Input('agencyFilter') agencyFilter = '';
  @Input('clientFilter') clientFilter = '';
  @Input('startDateFilter') startDateFilter = '';
  @Input('endDateFilter') endDateFilter = '';
  @Input('directorateFilter') directorateFilter = '';


  shifts: any = [];
  shiftInfo: any;
  selectedTransport: any;

  userType: string;

  page = 1;
  pageSize = 20;
  totalItems = 0;
  showin = 0;
  first: boolean;
  last: boolean;

  showNotes: boolean;

  constructor(
    private transportService: TransportService,
    private toast: ToastrService,
    private router: Router,
    authService: AuthService,
    private modalService: NgbModal
  ) {
    this.userType = authService.getUserType();
   }

  ngOnInit(): void {
    this.getTransports();

    if(this.userType == 'admin'){
      this.showNotes = true;
    }
  }

  handlePageChange(event) {

    if(event == 'next'){
      this.page += 1;
    }
    else if(event == 'prev' && this.page != 1){
      this.page -= 1;
    }

    this.getTransports();
  }

  getTransports(){
    this.transportService.getPaginatedTransportsByStatus(this.page - 1, this.pageSize, 'NEW').subscribe(
      data => {
        console.log(data);

        this.totalItems = data.totalElements;
        this.first = data.first;
        this.last = data.last;

        if(this.page * this.pageSize > data.totalElements){
          this.showin = data.totalElements;
        }
        else{
          this.showin = this.page * this.pageSize;
        }

        this.shifts = data.content;
      }
    );
  }

  viewDetails(ref, modal){
    if(ref){
      this.shifts.forEach(r => {
        if(r.id == ref){
          this.shiftInfo = r;
        }
      });

      // check show notes issue
      if(this.userType != 'admin'){
        this.userType == 'agency' ? this.showNotes = this.shiftInfo.showNoteToAgency : this.userType == 'client' ? this.showNotes = this.shiftInfo.showNoteToFw : this.showNotes = false;
      }

      this.openBigModal(modal);
    }

    // console.table(this.shiftInfo);
  }

  editDetails(ref){
    this.router.navigate([`/${this.userType}/shifts/edit/${ref}`]);
  }

  loadAgency(ref, modal){
    if(ref){
      this.selectedTransport = ref;

      this.openModal(modal);
    }
  }

  deleteBooking(){

    if(this.selectedTransport){
      this.transportService.cancelTransport(this.selectedTransport).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          this.getTransports();
          this.toast.success('Booking Deleted Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }
  }

  openModal(modal){
    this.modalService.open(modal, {centered: true});
  }

  openViewModal(modal, selectedTransport){
    this.selectedTransport = selectedTransport;
    this.modalService.open(modal, {centered: true});
  }

  openBigModal(modal){
    this.modalService.open(modal, {centered: true, size: 'lg'});
  }

}
