import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AgencyService {

  //base_url: string = environment.API_url + ':8300/api/v1';
  base_url: string = environment.API_url + '/worklink-api/api/v1';

  constructor(
    private http: HttpClient
  ) { }

  getPaginatedAgencies(page, size, agencyTypeFilter?): Observable<any> {
    if(agencyTypeFilter==null) agencyTypeFilter = "";
    return this.http.get(`${this.base_url}/agencies/${page}/${size}?agencyTypeFilter=${agencyTypeFilter}`);
  }

  getPaginatedDefaultAgencies(page, size): Observable<any> {
    return this.http.get(`${this.base_url}/agencies/default/${page}/${size}`);
  }

  getTrainers( ): Observable<any> {
    return this.http.get(`${this.base_url}/trainers`);
  }

  forceGenerate(agencyId): Observable<any> {
    return this.http.post(`${this.base_url}/invoice/admin-billing/agency/${agencyId}`,{});
  }

  getAgencyNotifications(id): Observable<any> {
    return this.http.get(`${this.base_url}/sent-notifications/agency/${id}`);
  }

  deleteMessage(id): Observable<any> {
    return this.http.delete(`${this.base_url}/notification/${id}`);
  }

  getClientNotifications(id): Observable<any> {
    return this.http.get(`${this.base_url}/sent-notifications/client/${id}`);
  }
  getAdminNotifications(): Observable<any> {
    return this.http.get(`${this.base_url}/sent-notifications/admin`);
  }

  createAgency(data): Observable<any> {
    return this.http.post(`${this.base_url}/agency`, data);
  }

  inviteWorker(data): Observable<any> {
    return this.http.post(`${this.base_url}/agency/invite-worker`, data);
  }

  updateAgency(data): Observable<any> {
    return this.http.put(`${this.base_url}/agency`, data);
  }

  deactivateAgency(ref): Observable<any> {
    return this.http.put(`${this.base_url}/agency-deactivate/${ref}`, null);
  }

  activateAgency(ref): Observable<any> {
    return this.http.put(`${this.base_url}/agency-activate/${ref}`, null);
  }

  getAgencyById(ref): Observable<any> {
    return this.http.get(`${this.base_url}/agency/${ref}`);
  }

  getPaginatedAgencyClients(page, size, agency): Observable<any> {
    return this.http.get(`${this.base_url}/agency-clients/${agency}/${page}/${size}`);
  }

  getPaginatedAgencyShifts(page, size, agency): Observable<any> {
    return this.http.get(`${this.base_url}/agency-shifts/${agency}/${page}/${size}`);
  }

  getPaginatedAgencyShiftsByStatus(page, size, agency, status): Observable<any> {
    return this.http.get(`${this.base_url}/agency-shifts-status/${agency}/${page}/${size}/${status}`);
  }

  getPaginatedAdminAgencyShiftsByStatus(page, size, agency, startDate, endDate): Observable<any> {
    if(startDate && endDate){
      return this.http.get(`${this.base_url}/admin-billing-shifts?agencyId=${agency}&endDate=${endDate}&page=${page}&size=${size}&startDate=${startDate}`);
    }else{
      return this.http.get(`${this.base_url}/admin-billing-shifts?agencyId=${agency}&page=${page}&size=${size}`);

    }
  }

  getPaginatedAgencyWorkers(page, size, agency): Observable<any> {
    return this.http.get(`${this.base_url}/agency-workers/${agency}/${page}/${size}`);
  }


  getLegibleTransportWorkers(transportId): Observable<any> {
    return this.http.get(`${this.base_url}/transport/legible-workers/${transportId}`);
  }


  getPaginatedAgencyApplicants(page, size, agency): Observable<any> {
    return this.http.get(`${this.base_url}/agency-applicants/${agency}/${page}/${size}`);
  }

  getPaginatedAgencyWorkersPending(page, size, agency): Observable<any> {
    return this.http.get(`${this.base_url}/agency-workers/pending-shifts/${agency}/${page}/${size}`);
  }

  deleteAgency(ref): Observable<any> {
    return this.http.delete(`${this.base_url}/agency/${ref}`);
  }
  // Billing
  createDebitNote(data): Observable<any> {
    return this.http.post(`${this.base_url}/billing/debit-note/create`, data);
  }

  getAgencyDebitNotes(page, size): Observable<any> {
    return this.http.get(`${this.base_url}/billing/${page}/${size}`);
  }

  getAgencyDebitNote(ref): Observable<any> {
    return this.http.get(`${this.base_url}/billing/${ref}`);
  }

  publishInvoice(invoiceId, sendEmail): Observable<any> {
    return this.http.put(`${this.base_url}/invoice/agency/publish?invoiceId=${invoiceId}&sendEmail=${sendEmail}`,invoiceId);
  }

  changeAdminBillStatus(data): Observable<any> {
    return this.http.put(`${this.base_url}/billing/debit-note/status`, data);
  }

  setDiscount(invoiceId, discount): Observable<any> {
    return this.http.put(`${this.base_url}/invoice/agency/discount?discount=${discount}&invoiceId=${invoiceId}`, discount);
  }

  createClientInvoices(client, data, agent): Observable<any> {
    return this.http.post(`${this.base_url}/invoice/client/create?payerId=${client}&payeeId=${agent}`, data);
  }

  createTrainerAgencyInvoices(agencyId, trainerId, data): Observable<any> {
    return this.http.post(`${this.base_url}/invoice/trainer/invoice-trainings?agencyId=${agencyId}&trainerId=${trainerId}`, data);
  }

  createAgencyInvoices(client, data, agent): Observable<any> {
    return this.http.post(`${this.base_url}/invoice/agency/create?clientId=${client}&agencyId=${agent}`, data);
  }

  createWorkerPayAdvices(worker, data,payDate, agent): Observable<any> {
    return this.http.post(`${this.base_url}/payAdvice/worker/create?workerId=${worker}&payeeId=${agent}&agentId=${agent}&payDate=${payDate}`, data);
  }

  viewClientInvoices(client, page, size): Observable<any> {
    return this.http.get(`${this.base_url}/invoice/client/view?clientId=${client}&page=${page}&size=${size}`);
  }

  viewAdminInvoices( page, size): Observable<any> {
    return this.http.get(`${this.base_url}/invoice/admin/view?page=${page}&size=${size}`);
  }

  deleteInvoices(id): Observable<any> {
    return this.http.delete(`${this.base_url}/invoice/${id}`);
  }

  deleteAdminInvoice(id): Observable<any> {
    return this.http.delete(`${this.base_url}/invoice/admin/${id}`);
  }

  deletePayadvice(id): Observable<any> {
    return this.http.delete(`${this.base_url}/pay-advice/${id}`);
  }


  getClientInvoice(invoiceId): Observable<any> {
    return this.http.get(`${this.base_url}/invoice/client/view/invoice?invoiceId=${invoiceId}`);
  }

  viewWorkerPayAdvices(worker,agencyId, page, size): Observable<any> {
    return this.http.get(`${this.base_url}/payAdvice/agency-worker/view?workerId=${worker}&agencyId=${agencyId}&page=${page}&size=${size}`);
  }

  getWorkerPayAdvice(payadviceId): Observable<any> {
    return this.http.get(`${this.base_url}/payAdvice/worker/view/payadvice?payAdviceId=${payadviceId}`);
  }

  getAgencyInvoices(agencyId, page, size): Observable<any> {
    return this.http.get(`${this.base_url}/invoice/agency/view?agencyId=${agencyId}&page=${page}&size=${size}`);
  }

  getAgencyInvoiceReports(agencyId, page, size): Observable<any> {
    return this.http.get(`${this.base_url}/invoice/agency/view-reports?agencyId=${agencyId}&page=${page}&size=${size}`);
  }

  downloadInvoice(ref, typ): Observable<any> {
    return this.http.get(`${this.base_url}/invoice/download?invoiceId=${ref}&format=${typ}`, {responseType: 'blob'});
  }

  //Settings
  getAgencySettings( agencyId): Observable<any>{
    return this.http.get(`${this.base_url}/agency-settings?agencyId=${agencyId}`)
  }
  createAgencySettings(data): Observable<any>{
    return this.http.post(`${this.base_url}/agency-settings`,data)
  }
  updateAgencySettings(data, settingsId):Observable<any>{
    return this.http.put(`${this.base_url}/agency-settings/${settingsId}`, data)
  }
  updateAgencyBankingDetails(data, agencyId):Observable<any>{
    return this.http.put(`${this.base_url}/agency/bank-details/${agencyId}`, data)
  }


  //Assignment Code Rates
  getAgencyAssRates( agencyId): Observable<any>{
    return this.http.get(`${this.base_url}/assignment-code-rates-agent/${agencyId}`)
  }

  getClientAssRates( clientId): Observable<any>{
    return this.http.get(`${this.base_url}/assignment-code-rates-client/${clientId}`)
  }

  addRefNumber(invId, ref, payStatus, amount): Observable<any> {
    return this.http.put(`${this.base_url}/invoice/agency/acknowledge?invoiceId=${invId}&amount=${amount}&payStatus=${payStatus}&paymentRef=${ref}`, ref) // delete this devip
  }


  getPaginatedInvoiceCreditNotes(agencyId, page, size): Observable<any> {
    return this.http.get(`${this.base_url}/assignment-code-rates-agent/1`) // delete this devip
    //return this.http.get(`${this.base_url}/invoice/agency/invoice-credit-notes?agencyId=${agencyId}&page=${page}&size=${size}`);
  }

  getInvoiceCreditNoteDetail(id): Observable<any> {
    return this.http.get(`${this.base_url}/assignment-code-rates-agent/1`) // delete this devip
    //return this.http.get(`${this.base_url}/invoice/agency/invoice-credit-notes?id=${id}`);
  }

  getPaginatedClosedShifts(agencyId, page, size): Observable<any> {
    return this.http.get(`${this.base_url}/assignment-code-rates-agent/1`) // delete this devip
    //return this.http.get(`${this.base_url}/invoice/agency/closed-shifts?agencyId=${agencyId}&page=${page}&size=${size}`);
  }

  getAgencyWorkerProperties(workerId,agencyId): Observable<any> {
    return this.http.get(`${this.base_url}/agency-worker-properties/${workerId}/${agencyId}`)
  }

  updateAgencyWorkerProperties(data):Observable<any>{
    return this.http.post(`${this.base_url}/agency-worker-properties`, data)
  }

  activateWorker(data):Observable<any>{
    return this.http.put(`${this.base_url}/agency-worker-properties/activate-worker`, data)
  }

  deactivateWorker(data):Observable<any>{
    return this.http.put(`${this.base_url}/agency-worker-properties/deactivate-worker`, data)
  }

  activateApplicant(data):Observable<any>{
    return this.http.put(`${this.base_url}/agency-worker-properties/activate-applicant`, data)
  }

  deactivateApplicant(data):Observable<any>{
    return this.http.put(`${this.base_url}/agency-worker-properties/deactivate-applicant`, data)
  }

  downloadBacsCSV(date, agencyId):Observable<any>{
    var res =  this.http.get(`${this.base_url}/payadvice/bacs-payment-cvs/download/${date}/${agencyId}`,);
    console.log(res);
    return res;
  }

  getGetDebitNotes(agencyId, page, size): Observable<any> {
    return this.http.get(`${this.base_url}/assignment-code-rates-agent/1`) // delete this devip
    //return this.http.get(`${this.base_url}/agency/debit-notes?agencyId=${agencyId}&page=${page}&size=${size}`);
  }

  getClientInvoices(clientId, page, size): Observable<any> {
    return this.http.get(`${this.base_url}/invoice/client/view?clientId=${clientId}&page=${page}&size=${size}`)
  }

  getAdminAgencyInvoices(clientId, page, size): Observable<any> {
    return this.http.get(`${this.base_url}/invoice/admin-agency/view?agencyId=${clientId}&page=${page}&size=${size}`)
  }

  getAdminAgencyInvoiceReports(clientId, page, size): Observable<any> {
    return this.http.get(`${this.base_url}/invoice/admin-agency/view-reports?agencyId=${clientId}&page=${page}&size=${size}`)
  }

  getAgencyWorkersforPaslip(agencyId, page, size): Observable<any> {
    return this.http.get(`${this.base_url}/payroll-workers/${agencyId}/${page}/${size}`)
  }

  searchAgencyWorkersforPaslip(agencyId,query, page, size): Observable<any> {
    return this.http.get(`${this.base_url}/payroll-workers/search/${agencyId}/${query}/${page}/${size}`)
  }

  // getAgencyPayAdvices(agencyId, page, size): Observable<any> {
  //   return this.http.get(`${this.base_url}/payAdvice/agency/view?agencyId=${agencyId}&page=${page}&size=${size}`)
  //   //return this.http.get(`${this.base_url}/agency/Pay-Advices?agencyId=${agencyId}`);
  // }

  getAgencyPayAdvices(agencyId, page, size, workerId, startDate, endDate): Observable<any> {
    return this.http.get(`${this.base_url}/payAdvice/agency/view?agencyId=${agencyId}&page=${page}&size=${size}&workerId=${workerId}&startDate=${startDate}&endDate=${endDate}`)
    //return this.http.get(`${this.base_url}/agency/Pay-Advices?agencyId=${agencyId}`);
  }
  getBacsCSV(date, agencyId): Observable<any> {
    return this.http.get(`${this.base_url}/payadvice/bacs-payment-csv/download/${date}/${agencyId}`);
  }

  getWorkerPaslips(workerId, page, size): Observable<any> {
    return this.http.get(`${this.base_url}/worker-payslips/${workerId}/${page}/${size}?workerId=${workerId}`) // delete this devip
    //return this.http.get(`${this.base_url}/agency/Pay-Advices?agencyId=${agencyId}`);
  }


}
