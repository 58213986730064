import { AuthService } from 'src/app/shared/services/auth.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  //base_url: string = environment.API_url + ':8300/api/v1';
  base_url: string = environment.API_url + '/worklink-api/api/v1';
  userType: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private storageService: StorageService
  ) {
    this.userType = this.authService.getUserType();
   }

  getPaginatedJobsByStatus(page, size, status,agencyId, clientId, workerId, startDate, endDate, searchCriteria): Observable<any> {

     if(agencyId=="") agencyId = null;
     if( clientId=="") clientId = null;
     if( workerId=="") workerId = null;
     if( startDate=="") startDate = null;
     if( endDate=="") endDate = null;



    if(this.userType == 'admin'){
      return this.http.get(`${this.base_url}/tranport/${page}/${size}/${status}?clientId=${clientId}&agencyId=${agencyId}&workerId=${workerId}&startDate=${startDate}&endDate=${endDate}`);
    }
    else if(this.userType == 'agency'){
      return this.http.get(`${this.base_url}/transport/agency/${this.storageService.decrypt(localStorage.getItem('agentId'))}/${status}/${page}/${size}?clientId=${clientId}&workerId=${workerId}&startDate=${startDate}&endDate=${endDate}&searchCriteria=${this.removeLetters(searchCriteria)}`);
    }
    else if(this.userType == 'client'){
      return this.http.get(`${this.base_url}/transport/client/${page}/${size}/${this.storageService.decrypt(localStorage.getItem('clientId'))}/${status}?agencyId=${agencyId}&workerId=${workerId}&startDate=${startDate}&endDate=${endDate}`);
    }
    return null;

  }


  removeLetters(inputString: string): string {
    const numericOnly = inputString.replace(/[^0-9]/g, '');
    return numericOnly;
  }

  getPaginatedClientJobsByStatus(page, size, status, client): Observable<any> {

    return this.http.get(`${this.base_url}/jobs-clients-status/${page}/${size}/${client}/${status}`);

  }

  getPaginatedJobsByWorkerIdAndStatus(workerId, agencyId,page, size ): Observable<any> {

    return this.http.get(`${this.base_url}/worker-agency-job/billing/${workerId}/${agencyId}/${page}/${size}`);

  }

  getAgencyReleasedJobs(agenyId, page, size): Observable<any> {

    return this.http.get(`${this.base_url}/agency-jobs-released/${agenyId}/${page}/${size}`);

  }

  createJob(data): Observable<any> {
    return this.http.post(`${this.base_url}/job`, data);
  }

  getJobsClaims(jobIds): Observable<any> {
    return this.http.get(`${this.base_url}/job/expense-claim/${jobIds}`);
  }

  rejectClaim(data): Observable<any> {
    return this.http.put(`${this.base_url}/job/expense-claim`, data);
  }

  acceptClaim(data): Observable<any> {
    return this.http.put(`${this.base_url}/job/expense-claim`, data);
  }

  updateJob(data): Observable<any> {
    return this.http.put(`${this.base_url}/job`, data);
  }

  getJobById(ref): Observable<any> {
    return this.http.get(`${this.base_url}/job/${ref}`);
  }

  deleteJob(ref): Observable<any> {
    return this.http.delete(`${this.base_url}/job/${ref}`);
  }

  acceptJob(transportId, agencyId): Observable<any> {
    return this.http.put(`${this.base_url}/transport/accept/${transportId}/${agencyId}`, {});
  }

  authorizeJob(job, client): Observable<any> {
    return this.http.put(`${this.base_url}/authorize-job/${job}/${client}`, null);
  }

  bookJob(job, worker, agency): Observable<any> {
    return this.http.put(`${this.base_url}/book-job/${job}/${worker}/${agency}`, null);
  }

  newBookJob(job, worker): Observable<any> {
    return this.http.put(`${this.base_url}/direct-book-job/${job}/${worker}`, null);
  }

  cancelJob(job, client, reason): Observable<any> {
    return this.http.put(`${this.base_url}/cancel-job/${job}/${client}/${reason}`, null);
  }

  getAgencyJobApplicants(jobId, agencyId): Observable<any> {
    return this.http.get(`${this.base_url}/transport-booking/applicants/${jobId}`);
  }

  getJobApplicants(jobId): Observable<any> {
    return this.http.get(`${this.base_url}/job-applicants/${jobId}`);
  }

  approveAppliedJob(job): Observable<any> {
    return this.http.put(`${this.base_url}/transport-booking/approve-booking/${job}`, null);
  }

  rejectAppliedJob(job): Observable<any> {
    return this.http.put(`${this.base_url}/transport-booking/reject-booking/${job}`, null);
  }




  getPaginatedClientJobDirectorates(clientId,page, size): Observable<any> {
       return this.http.get(`${this.base_url}/job-directorates-client/${clientId}/${page}/${size}`);
  }
  getPaginatedJobDirectorates(page, size): Observable<any> {
    if(this.userType == "client"){
      return this.http.get(`${this.base_url}/job-directorates-client/${this.storageService.decrypt(localStorage.getItem('clientId'))}/${page}/${size}`);
    }
    else if(this.userType == "agency"){
      return this.http.get(`${this.base_url}/job-directorates-agency/${this.storageService.decrypt(localStorage.getItem('agentId'))}/${page}/${size}`);
    }
    else{
      return this.http.get(`${this.base_url}/job-directorates/${page}/${size}`);
    }

  }

  getPaginatedAgencyJobDirectorates(agency, page, size): Observable<any> {

      return this.http.get(`${this.base_url}/job-directorates-agency/${agency}/${page}/${size}`);
  }

  getPaginatedClientDirectorates(page, size, client): Observable<any> {
    return this.http.get(`${this.base_url}/job-directorates-client/${client}/${page}/${size}`);
  }

  createJobDirectorate(data): Observable<any> {
    return this.http.post(`${this.base_url}/job-directorate`, data);
  }

  updateJobDirectorate(data): Observable<any> {
    return this.http.put(`${this.base_url}/job-directorate`, data);
  }

  getJobDirectorateById(ref): Observable<any> {
    return this.http.get(`${this.base_url}/job-directorate/${ref}`);
  }

  getJobDirectorateByLocation(ref): Observable<any> {
    return this.http.get(`${this.base_url}/job-directorate-by-location/${ref}`);
  }

  deleteJobDirectorate(ref): Observable<any> {
    return this.http.delete(`${this.base_url}/job-directorate/${ref}`);
  }



  getPaginatedClientJobLocations(clientId, page, size): Observable<any> {
       return this.http.get(`${this.base_url}/job-location-client/${clientId}`);


  }
  getPaginatedJobLocations(page, size): Observable<any> {
    if(this.userType == "client"){
      return this.http.get(`${this.base_url}/job-locations/${page}/${1000}`);
      // return this.http.get(`${this.base_url}/job-location-client/${this.storageService.decrypt(localStorage.getItem('clientId'))}`);
    }
    else if(this.userType == "agency"){
      // return this.http.get(`${this.base_url}/job-location-agency/${this.storageService.decrypt(localStorage.getItem('agentId'))}/${page}/${size}`);
      return this.http.get(`${this.base_url}/job-locations/${page}/${1000}`);
    }
    else{
      return this.http.get(`${this.base_url}/job-locations/${page}/${1000}`);
    }

  }

  createJobLocation(data): Observable<any> {
    return this.http.post(`${this.base_url}/job-location`, data);
  }

  updateJobLocation(data): Observable<any> {
    return this.http.put(`${this.base_url}/job-location`, data);
  }

  getJobLocationById(ref): Observable<any> {
    return this.http.get(`${this.base_url}/job-location/${ref}`);
  }

  getJobLocationByClient(ref): Observable<any> {
    return this.http.get(`${this.base_url}/job-location-client/${ref}`);
  }

  deleteJobLocation(ref): Observable<any> {
    return this.http.delete(`${this.base_url}/job-location/${ref}`);
  }



  getPaginatedJobTypes(page, size): Observable<any> {
    return this.http.get(`${this.base_url}/job-types/${page}/${size}`);
  }

  createJobType(data): Observable<any> {
    return this.http.post(`${this.base_url}/job-type`, data);
  }

  updateJobType(data): Observable<any> {
    return this.http.put(`${this.base_url}/job-type`, data);
  }

  getJobTypeById(ref): Observable<any> {
    return this.http.get(`${this.base_url}/job-type/${ref}`);
  }

  deleteJobType(ref): Observable<any> {
    return this.http.delete(`${this.base_url}/job-type/${ref}`);
  }

}
