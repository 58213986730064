import { AuthService } from './../../../../shared/services/auth.service';
import { ServicesService } from './../../../../shared/services/services.service';
import { AssignmentCodeService } from './../../../../shared/services/assignment-code.service';
import { TrainingService } from './../../../../shared/services/training.service';
TrainingService
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css']
})
export class TrainingComponent implements OnInit {
  selectedTab: any = 'trainings';
  assCodes: any;
  trainings: any;
  services: any;
  selectedCode: any;

  addForm: FormGroup;
  editForm: FormGroup;

  userType: string;

  page = 1;
  pageSize = 20;
  totalItems = 0;
  showin = 0;
  first: boolean;
  last: boolean;

  constructor(
    private toast: ToastrService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private assignmentService: AssignmentCodeService,
    private trainingService: TrainingService,
    private servicesService: ServicesService,
    private authService: AuthService
  ) {
    this.userType = authService.getUserType();
  }

  ngOnInit(): void {
    this.getTrainings();

    this.addForm = this.fb.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      serviceId: ['', Validators.required],
    });

    this.editForm = this.fb.group({
      id: 0
    });

    this.getServices();
  }

  handlePageChange(event) {

    if(event == 'next'){
      this.page += 1;
    }
    else if(event == 'prev' && this.page != 1){
      this.page -= 1;
    }

    this.getTrainings();
  }

  getTrainings(){
      //this.assignmentService.getPaginatedAssignmentCodes(this.page - 1, this.pageSize).subscribe(
      this.trainingService.getPaginatedTraining(this.page - 1, this.pageSize).subscribe(
      data => {
        // console.table(data.content);
        if(this.userType == 'admin'){
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }
          let array = data.content;
          array.sort(function(a, b){
              if(a.name < b.name) { return -1; }
              if(a.name > b.name) { return 1; }
              return 0;
          })

          this.trainings = array;
        }
        else{
          this.totalItems = data.length;
          this.first = true;
          this.last = true;

          if(this.page * this.pageSize > data.length){
            this.showin = data.length;
          }
          else{
            this.showin = this.page * this.pageSize;
          }

          this.trainings = data;
        }
      }
    );
  }

  getServices(){
    this.servicesService.getPaginatedServices(0, 100).subscribe(
      data => {
        // console.table(data.content);
        this.services = data.content;
      }
    );
  }

  openModal(modal){
    this.modalService.open(modal, {centered: true, size: 'lg'});
  }

  deleteCode(){

    if(this.selectedCode.id){
      this.trainingService.deleteAssignmentCodes(this.selectedCode.id).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          this.getTrainings();
          this.toast.success('Assignment Code Deleted Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }
  }

  newCode(form: FormGroup){
    // console.table(form.value);

    if(form.valid){
      this.trainingService.createAssignmentCodes(form.value).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          form.reset();
          this.getTrainings();
          this.toast.success('Training Added Successfully');
        },
        err => {

          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
          else if(err.error?.message){
            if(err.status == 409){
              this.toast.error('This training code is already in use. Please try changing the assignment code and name.')
            }
            else{
              this.toast.error(err.error.message);
            }
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }

        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }
  }

  updateCode(form: FormGroup){
    // console.table(form.value);

    if(form.valid){
      this.trainingService.updateAssignmentCodes(form.value).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          this.getTrainings();
          this.toast.success('Assignment Code Updated Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }
  }

  loadCode(ref, modal, edit){
    if(ref){
      this.trainings.forEach(r => {
        if(r.id == ref){
          this.selectedCode = r;
        }
      });

      // {
      //   "description": "string",
      //   "service": "string"
      // }

      if(edit && this.selectedCode){
        this.editForm = this.fb.group({
          trainingId: [this.selectedCode.id, Validators.required],
          name: [this.selectedCode.name, Validators.required],
          code: [this.selectedCode.code, Validators.required],
          serviceId: [this.getServiceId(this.selectedCode.serviceName), Validators.required],
        });
      }
    }

    if(modal){
      this.openModal(modal);
    }
  }

  getServiceId(ref){
    let sid;
    if(ref){
      this.services.forEach(r => {
        if(r.name == ref){
          sid = r.id;
        }
      });
    }

    return sid;
  }

  selectTab(ref) {
    if(ref){
      this.selectedTab = ref;
    }
  }

}
