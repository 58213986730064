<div *ngIf="authorized" class="">
  <div class="modal-body">
    <div class="bg-modal card-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-6" style="align-self: center;">
            <h5>Job Refs: {{job?.refPrefix}}</h5>
          </div>
          <div class="col-6 card bg-grey">
            <b>{{job?.pickupPostCode}} > {{job?.destinationPostCode}}</b>
            {{job?.dateTimeRequired | date:'EEEE, MMMM d, y'}}
            <br>{{job?.dateTimeRequired | date:'HH:mm'}}
          </div>
        </div>

<!--
<div class="row my-4 text-center" style="align-items: end;">
  <div class="col-6 pointer col-sm-4 col-md-4 text-center">
    <i *ngIf="tabindex==1" class="fa fa-map-pin text-primary-custom"></i><br>
    <button [className]="tabindex>=1?'btn-primary w-100 rounded-tab':'rounded-tab w-100'" (click)="selectTab(1)">Patient's Property</button>
  </div>
  <div class="col-4">
    <i *ngIf="tabindex==2" class="fa fa-map-pin text-primary-custom"></i><br>
    <button [className]="tabindex>=2?'btn-primary w-100 rounded-tab':'rounded-tab w-100'" (click)="selectTab(2)">Medication</button>
  </div>
  <div class="col-4">
    <i *ngIf="tabindex==3" class="fa fa-map-pin text-primary-custom"></i><br>
    <button [className]="tabindex>=3?'btn-primary w-100 rounded-tab':'rounded-tab w-100'" (click)="selectTab(3)">Drop Off & Feedback</button>
  </div>
</div>
-->

        <div class="row my-4 text-center"  style="align-items: end;">
          <div class="col-1">
            <i *ngIf="tabindex==0" class="fa fa-map-pin text-primary-custom"></i><br>
            <button [className]="tabindex>=0?'btn-primary w-103 rounded-tab':'rounded-tab w-100'" (click)="tabindex = 0">Cleaning</button>
          </div>
          <div class="col-3">
            <i *ngIf="tabindex==1" class="fa fa-map-pin text-primary-custom"></i><br>
            <button [className]="tabindex>=1?'btn-primary w-100 rounded-tab':'rounded-tab w-100'" (click)="tabindex = 1">View Patient's Information</button>
          </div>
          <div class="col-2" >
            <i *ngIf="tabindex==2" class="fa fa-map-pin text-primary-custom"></i><br>
            <button [className]="tabindex>=2?'btn-primary w-100 rounded-tab':'rounded-tab w-100'" (click)="selectTab(2)">Patient's Property</button>
          </div>
          <div class="col-2">
            <i *ngIf="tabindex==3" class="fa fa-map-pin text-primary-custom"></i><br>
            <button [className]="tabindex>=3?'btn-primary w-100 rounded-tab':'rounded-tab w-100'" (click)="selectTab(3)">Medication</button>
          </div>
          <div class="col-2">
            <i *ngIf="tabindex==4" class="fa fa-map-pin text-primary-custom"></i><br>
            <button [className]="tabindex>=4?'btn-primary w-100 rounded-tab':'rounded-tab w-100'" (click)="tabindex = 4">Drop Off & Feedback</button>
          </div>
          <div class="col-2">
            <i *ngIf="tabindex==5" class="fa fa-map-pin text-primary-custom"></i><br>
            <button [className]="tabindex>=5?'btn-primary w-100 rounded-tab':'rounded-tab w-100'" (click)="tabindex = 5">Job Plan</button>
          </div>

        </div>
        <div class="mt-2">

          <app-view-transport-tab
            *ngIf="tabindex==1"
            [form]="logForm"
            [job]="job"
            [tabindex]="tabindex"
            (tabindexO)="updateTab($event)"
            (formO)="updateForm($event)">
          </app-view-transport-tab>

          <app-log-transport-tab3
          *ngIf="tabindex == 0"
          [form]="cleaningLogForm"
          [job]="job"
          [tabindex]="tabindex"
          (tabindexO)="updateTab($event)"
          (formO)="updateCleaningForm($event)"
        ></app-log-transport-tab3>


        <transport-info-modal
        *ngIf="tabindex == 5"
        [teamLeaderView] = "true"
        [selectedJob]  =  "job"
        ></transport-info-modal>


          <app-leader-entry2
            *ngIf="tabindex==2"
            [form]="logForm"
            [job]="job"
            [tabindex]="tabindex"
            (tabindexO)="updateTab($event)"
            (formO)="updateForm($event)">
          </app-leader-entry2>

          <app-leader-entry3
            *ngIf="tabindex==3"
            [form]="logForm"
            [job]="job"
            [tabindex]="tabindex"
            (tabindexO)="updateTab($event)"
            (formO)="updateForm($event)">
          </app-leader-entry3>

          <app-leader-entry4
            *ngIf="tabindex==4"
            [form]="logForm"
            [job]="job"
            [tabindex]="tabindex"
            (tabindexO)="updateTab($event)"
            (formO)="updateForm($event)">
          </app-leader-entry4>




        </div>
        <br>
      </div>
    </div>
  </div>
</div>





<ng-template class="modal fade" #edit let-modal>
  <div class="">
      <div class="modal-header bg-main text-white">
          <h5 class="modal-title text-center w-100">Access Control</h5>
          <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </span>
      </div>
      <div class="modal-body">
        <form class="form" [formGroup]="loginForm" (ngSubmit)="loginUser(loginForm)">
          <input
            type="text"
            class="form-control mb-4"
            placeholder="Username" formControlName="username"
          />
          <input
            type="password"
            class="form-control mb-4"
            placeholder="Password" formControlName="password"
          />
          <div class="d-flex justify-content-between mt-2 mb-3">

            <div class="forgot-password-option">
              <button type="button"
                (click)="resetPage()"
                class="text-decoration-none bg-white border-0 text-primary-custom"
                >Forgot Password?</button>
            </div>
          </div>
          <div class="m-3">
            <p>
              By signing in you accept our <a href="https://myworklink.uk/?page_id=13570">terms and conditions</a> and <a href="https://myworklink.uk/?page_id=3">privacy policy</a>.
            </p>
          </div>
          <div class="fg-actions d-flex justify-content-between">
            <button type="submit" class="btn btn-success btn-info bg-core-lime">Sign In</button>
          </div>
          <!-- <hr class="mt-3 mb-3" /> -->
          <div class="d-flex justify-content-between mt-3">
          </div>
        </form>
      </div>
  </div>
</ng-template>
