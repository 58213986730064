import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { ClientRoutingModule } from './client-routing.module';
import { ClientContainerComponent } from './client-container/client-container.component';
import { ClientHomeComponent } from './client-home/client-home.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShiftsRoutingModule } from './shifts/shifts-routing.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgSelectModule } from '@ng-select/ng-select';
import { ShiftsModule } from '../admin/shifts/shifts.module';
import { AppliedShiftComponent } from './shifts/applied-shift/applied-shift.component';
import { EditShiftComponent } from './shifts/edit-shift/edit-shift.component';
import { ShiftsListComponent } from './shifts/shifts-list/shifts-list.component';
import { TransportRoutingModule } from './transport/transport-routing.module';





@NgModule({
  declarations: [ClientContainerComponent, ClientHomeComponent],
  imports: [
    CommonModule,
    ClientRoutingModule,
    SharedModule,
    MatDialogModule
  ]
})
export class ClientModule { }

@NgModule({
  declarations: [  ],
  imports: [
    CommonModule,
    ShiftsRoutingModule,
    ShiftsModule,
    TransportRoutingModule,
    ReactiveFormsModule,
    NgSelectModule,
    FormsModule,
    MatDatepickerModule,
    MatFormFieldModule,

  ],
  providers: [DatePipe]
})
export class ClientTransportModule { }
