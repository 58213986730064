import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { StorageService } from 'src/app/shared/services/storage.service';
import { JobService } from 'src/app/shared/services/job.service';

@Component({
  selector: 'app-jobs-table', 
  templateUrl: './jobs-table.component.html',
  styleUrls: ['./jobs-table.component.css']
})
export class JobsTableComponent implements OnChanges {
  @Input('agencyFilter') agencyFilter = '';
  @Input('transportStatus') transportStatus = '';
  @Input('workerFilter') workerFilter = '';
  @Input('clientFilter') clientFilter = '';
  @Input('startDateFilter') startDateFilter = '';
  @Input('endDateFilter') endDateFilter = '';
  @Input('searchCriteria') searchCriteria = '';
  @Output() updated = new EventEmitter<boolean>();

  myData: any;
  jobs: any= [];
  selectedJob: any;
  applicants: any;
  userType: string;
  page = 1;
  pageSize = 1000;
  totalItems = 0;
  showin = 0;
  first: boolean;
  last: boolean;
  

  constructor(
    private jobsService: JobService,
    private toast: ToastrService,
    private router: Router,
    private authService: AuthService,
    private modalService: NgbModal,
    private storageService: StorageService
  ) {
    this.userType = authService.getUserType();
   }

  ngOnChanges(): void {
    this.jobs = [];
    this.getJobs();
    console.log(this.selectedJob)
    console.log(this.jobs)


  }

  handlePageChange(event) {
    if(event == 'next'){
      this.page += 1;
    }
    else if(event == 'prev' && this.page != 1){
      this.page -= 1;
    }
    this.getJobs();
  }

  refreshPage(changes): void {
    this.updated.emit(true);
    this.ngOnChanges();
  }

  getJobs(){
    this.jobsService.getPaginatedJobsByStatus(this.page - 1, this.pageSize, this.transportStatus, this.agencyFilter, this.clientFilter, this.workerFilter, this.startDateFilter, this.endDateFilter, this.searchCriteria).subscribe(
      data => {

        this.jobs = data.content;
        this.totalItems = data.totalElements;
        this.first = data.first;
        this.last = data.last;

        if(this.page * this.pageSize > data.totalElements){
          this.showin = data.totalElements;
        }
        else{
          this.showin = this.page * this.pageSize;
        }
       
      }

    );
  }

  viewDetails(ref, modal){
    this.selectedJob = ref;
    this.openBigModal(modal);
  }

  editDetails(ref){
    this.router.navigate([`/client/jobs/edit/${ref}`]);
  }

  loadAgency(ref, modal){
    if(ref){
      this.selectedJob = ref;
      this.openModal(modal);
    }
  }

  deleteJob(){
    if(this.selectedJob){
      this.jobsService.deleteJob(this.selectedJob).subscribe(
        resp => {
          this.modalService.dismissAll();
          this.getJobs();
          this.toast.success('Job Deleted Successfully');
          this.updated.emit(true);
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }
  }

  openModal(modal){
    this.modalService.open(modal, {centered: true});
  }

  openBigModal(modal){
    this.modalService.open(modal, {centered: true, size: 'xl'});
  }

  getApplicants(){
    this.applicants = [];
    if(this.storageService.decrypt(localStorage.getItem('agentId'))){

      this.jobsService.getAgencyJobApplicants(this.selectedJob.id, this.storageService.decrypt(localStorage.getItem('agentId'))).subscribe(
        data => {
          this.applicants = data;
        },
        err => {

          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }

        }
      );
    }else{
      this.jobsService.getJobApplicants(this.selectedJob.id).subscribe(
        data => {
          // console.log(data);

          this.applicants = data;
        },
        err => {

          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }

        }
      );
    }
  }

  openApplicantsModal(ref, modal){
    this.selectedJob = ref;
    this.getApplicants();
    this.openModal(modal);

  }

  approveWorker(ref){
    console.log(ref);

  }

  approveJobApplication(id){
    this.jobsService.approveAppliedJob(id).subscribe(data => {this.toast.success("Approved") });
  }

  rejectJobApplication(id){
    this.jobsService.rejectAppliedJob(id).subscribe(data => {this.toast.success("Rejected") });
  }

}
