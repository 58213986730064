import { ShiftsModule } from './shifts/shifts.module';
import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminContainerComponent } from './admin-container/admin-container.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatDialogModule } from '@angular/material/dialog';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  declarations: [AdminContainerComponent, AdminHomeComponent],
  imports: [
    NgSelectModule,
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    ShiftsModule,
    MatDialogModule
  ]
})
export class AdminModule { }
