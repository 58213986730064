<div class="">
  <!-- <div class="modal-header bg-main text-white">
  </div> -->
  <div *ngIf="requestSent==false" class="modal-body">
      <form (keydown.enter)="$event.preventDefault()" [formGroup]="addForm" (ngSubmit)="createJob(addForm)" class="example-form">
        <div class="bg-modal card-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 mb-1">
                Mandatory Fields Are Marked Red
              </div>
              <div class="col-12">

                <div class="  text-white  ">
                  <h5 class=" bg-main border radius-2  py-2 px-4 w-80" style="border-radius: 5px;"h5 class=" bg-main border radius-3  py-2 px-4 w-80" style="border-radius: 5px;">
                    New Secure Transport Booking</h5>

                </div>
                <div class="row">
                  <div class="col-12">
                    <h5>Collection</h5>
                  </div>

                  <div class="form-group col-6 col-sm-12 col-md-6">
                    <label>Reason For Transport</label>
                    <select class="form-control" formControlName="reasonForTransport">
                      <option selected disabled value="">Select Reason </option>
                      <option>Mental Health Act 1983 - Section 6 & 137</option>
                      <option>The Children’s Act 1989 Secure order</option>
                      <option>Other</option>
                    </select>
                  </div>

                  <div class="form-group col-6 col-sm-12 col-md-6">
                    <label>Mental Health Status </label>
                    <input type="text"  maxlength="255"  placeholder="" formControlName="mentalHealthStatus" class="form-control" placeholder="Mental Health Status" >
                  </div>



                  <div *ngIf="userType=='client'" class="form-group ng-select col-12 col-sm-12 col-md-6">
                    <label>Select Legible Provider(s)</label>
                    <ng-select
                      [items]="agencies"
                      [virtualScroll]="true"
                      bindLabel="name"
                      bindValue="agencyId"
                      placeholder="choose service providers"
                      [multiple]="true"
                      formControlName="transportLegibleAgencyIds"
                  ></ng-select>
                  </div>
                  <div *ngIf="userType=='agency'" class="form-group col-12 col-sm-12 col-md-6">
                    <label>Select Client(s)</label>
                    <select class="form-control" formControlName="clientId"  (change)="clientSelected($event)">
                      <option value="" selected disabled>Select Client</option>
                      <option *ngFor="let c of clients;" value="{{c.id}}"  >{{c.name}}</option>
                    </select>
                  </div>
                  <div class="mt-1 form-group col-sm-12 col-md-6">
                    <Label>Date & Time Transport Is Required </Label>
                    <input type="datetime-local" formControlName="dateTimeRequired" class="form-control">
                  </div>
                  <div class="form-group col-6 col-sm-12 col-md-4">
                   <label>Collection Address</label>
                   <input type="text"  maxlength="255" placeholder="" formControlName="pcaddress" class="form-control" placeholder="First Line of Address"  >

                  </div>
                  <div class="form-group col-6 col-sm-12 col-md-4">
                    <label>Post Code</label>
                    <input type="text"  maxlength="255" placeholder="" formControlName="pickupPostCode" class="form-control" placeholder="Post Code"  >
                  </div>
                  <div class="form-group col-6 col-sm-12 col-md-4">
                    <label>Collection Contact Name</label>
                    <input type="text"  maxlength="255"  placeholder="" formControlName="pname" class="form-control" placeholder="Collection Contact Name" >
                  </div>
                  <div class="form-group col-6 col-sm-12 col-md-4">
                    <label>Collection Email</label>
                    <input type="text"  maxlength="255"  placeholder="" formControlName="pcemail" class="form-control" placeholder="Collection Contact Email" >
                  </div>
                  <div class="form-group col-6 col-sm-12 col-md-4">
                    <label>Collection Ward</label>
                    <input type="text"  maxlength="255"  placeholder="" formControlName="pward" class="form-control" placeholder="Collection Ward" >
                  </div>
                  <div class="form-group col-6 col-sm-12 col-md-4">
                    <label>Collection Contact</label>
                    <input type="text"  maxlength="255" placeholder="" formControlName="pickupLocationContactNumber" class="form-control" placeholder="Contact Number" >
                  </div>

                  <div class="col-12">
                    <h5>Destination</h5>
                  </div>


                  <div class="form-group col-6 col-sm-12 col-md-4">
                    <label>Destination Address</label>
                    <input type="text" maxlength="255" placeholder="" formControlName="destination" class="form-control" placeholder="First Line of Address"  >
                  </div>
                  <div class="form-group col-6 col-sm-12 col-md-4">
                    <label>Post Code</label>
                    <input type="text" maxlength="255" placeholder="" formControlName="destinationPostCode" class="form-control" placeholder="Post Code" >
                  </div>
                  <div class="form-group col-6 col-sm-12 col-md-4">
                    <label>Destination Contact Person</label>
                    <input type="text"  maxlength="255"  placeholder="" formControlName="dname" class="form-control" placeholder="Destination Contact Person" >
                  </div>
                  <div class="form-group col-6 col-sm-12 col-md-4">
                    <label>Destination Contact Number</label>
                    <input type="text" maxlength="255" placeholder="" formControlName="destinationContactNumber" class="form-control" placeholder="Destination Contact Number" >
                  </div>
                  <div class="form-group col-6 col-sm-12 col-md-4">
                    <label>Destination Ward</label>
                    <input type="text"  maxlength="255"  placeholder="" formControlName="dward" class="form-control" placeholder="Destination Ward" >
                  </div>
                  <div class="form-group col-6 col-sm-12 col-md-4">
                    <label>Destination Email</label>
                    <input type="text"  maxlength="255"  placeholder="" formControlName="demail" class="form-control" placeholder="Destination Email" >
                  </div>


                </div>
                <br>

                <div class="  text-white  ">
                  <h5 class=" bg-main border radius-2  py-2 px-4 w-80" style="border-radius: 5px;"h5 class=" bg-main border radius-3  py-2 px-4 w-80" style="border-radius: 5px;">
                    Patient Information</h5>
                </div>
                  <div class="row">
                    <div class="form-group col-6 col-sm-12 col-md-4">
                      <label>Name / Initials of Patient</label>
                      <input type="text" maxlength="255" placeholder="Patient name" formControlName="patientName" class="form-control"  >
                    </div>
                    <div class="form-group col-6 col-sm-12 col-md-4">
                      <Label>Gender</Label>
                      <select class="form-control" formControlName="passengerGender">
                        <option value="" selected disabled> Select Gender</option>
                        <option value="MALE">Male</option>
                        <option value="FEMALE">Female</option>
                      </select>
                    </div>
                    <div class="form-group col-6 col-sm-12 col-md-4">
                      <label>Patient DOB</label>
                      <input type="date"  maxlength="255"  placeholder="" formControlName="pdob" class="form-control" >
                    </div>
                    <div class="form-group col-6 col-sm-12 col-md-4">
                      <label>NHS Number (IF KNOWN)</label>
                      <input type="text" maxlength="255" placeholder="NHS number" formControlName="nhs" class="form-control"  >
                    </div>
                    <div class="form-group col-6 col-sm-12 col-md-4">
                      <label>Diagnosis </label>
                      <input type="text"  maxlength="255"  placeholder="" formControlName="diagnosis" class="form-control" placeholder="Patient diagnosis" >
                    </div>
                    <div class="mt-1 col-12 col-md-4">
                      <Label>List any known allergies</Label>
                      <!-- &nbsp;<input   type="radio" name="allergies" (click)="toggleTrue('allergies')"  [value]="true"/>
                      <label  (click)="allergies=true"> &nbsp;Yes</label>&nbsp;
                      <input  type="radio" name="allergies" (click)="toggleFalse('allergies')" [value]="false"/>
                      <label  (click)="allergies=false"> &nbsp;No</label> -->
                      <input type="text" formControlName="allergies"   placeholder="Allergies" class="form-control date">
                    </div>


                    <div class="mt-1 col-12 col-md-4">
                      <Label>List any medication administered to the patient within 4 hours to transfer</Label>
                      <!-- <br>
                      <input   type="radio"  name="meds" (click)="toggleTrue('meds')"  />
                      <label for="meds" > &nbsp;Yes</label>&nbsp;
                      <input  type="radio" name="meds" (click)="toggleFalse('meds')"/>
                      <label for="meds" > &nbsp;No</label> -->
                      <textarea  type="text" formControlName="medication"   placeholder="" class="form-control date" placeholder="Medication description">
                        </textarea>
                    </div>
                    <div class="mt-1 col-12 col-md-4">
                      <Label>List any physical health issues or concerns</Label>
                      <!-- <br><input   type="radio" (click)="toggleTrue('phealth')" name="phealth" [value]="true"/>
                      <label  (click)="phealth=true"> &nbsp;Yes</label>&nbsp;
                      <input  type="radio" name="phealth" (click)="toggleFalse('phealth')" [value]="false"/>
                      <label  (click)="phealth=false"> &nbsp;No</label> -->
                      <textarea type="text" formControlName="physicalHealth"   placeholder="Physical Health Issues" class="form-control date">
                      </textarea>
                    </div>




                    <div class="mt-1 col-12 col-md-4">
                      <Label>List any specific infection control concerns</Label>
                      <!-- <br><input   type="radio" name="infection" (click)="toggleTrue('infection')"  [value]="true"/>
                      <label  (click)="infection=true"> &nbsp;Yes</label>&nbsp;
                      <input  type="radio" name="infection" (click)="toggleFalse('infection')" [value]="false"/>
                      <label  (click)="infection=false"> &nbsp;No</label> -->
                      <textarea  type="text" formControlName="infectionControl"   placeholder="Infection Control Concerns" class="form-control date">
                      </textarea>
                    </div>

                    <div class="mt-1 col-12 col-md-4">
                      <Label>Covid-19 Status in the last 48hrs</Label>
                      <br><input   type="radio" formControlName="covid"  [value]="true"/>
                      <label > &nbsp;Positive</label>&nbsp;
                      <input  type="radio" formControlName="covid"   [value]="false"/>
                      <label   > &nbsp;Negative</label>&nbsp;
                      <input  type="radio" formControlName="covid"   [value]="null"/>
                      <label   > &nbsp;Unkown</label>
                    </div>

                    <div class="mt-1 col-12 col-md-4">
                      <Label>Can staff offer food?</Label>
                      <br><input   type="radio" formControlName="canOfferFood"  [value]="true"/>
                      <label  (click)="diet=true"> &nbsp;Yes</label>&nbsp;
                      <input  type="radio" formControlName="canOfferFood" [value]="false"/>
                      <label  (click)="diet=false"> &nbsp;No</label>
                    </div>
                    <div *ngIf="addForm.value.canOfferFood" class="mt-1 col-12 col-md-4">
                      <Label>List any special dietary requirements</Label>
                      <input  type="text" formControlName="offerFood"   placeholder="" class="form-control date">
                    </div>


                    <div class="mt-1 col-12 col-md-12">
                      <Label>Has the patient received a Rapid Tranquillisation/Sedation medication in the last 6hrs before transfer?</Label>
                      &nbsp;
                      <input   type="radio"   formControlName="rapidStatus"  [value]="'YES'"/>
                      <label   (click)="rapid=true"> &nbsp;Yes</label>&nbsp;
                      <input  type="radio"   formControlName="rapidStatus" [value]="'NO'"/>
                      <label   (click)="rapid=false"> &nbsp;No</label>&nbsp;
                      <input  type="radio"  formControlName="rapidStatus" [value]="'NA'"/>
                      <label   (click)="rapid=false"> &nbsp;Not Applicable</label>
                      <textarea *ngIf="addForm.value.rapidStatus=='YES'" type="text" formControlName="rapidTranq"   placeholder="Rapid Tranquillisation/Sedation medication" class="form-control date"></textarea>
                    </div>

                    <div class="mt-1 col-12 col-md-12">
                      <Label>List any known present medication</Label>
                      <!-- &nbsp;<input   type="radio"  (click)="toggleTrue('pmeds')"  formControlName="patientMedication"  [value]="true"/>
                      <label   (click)="pmeds=true"> &nbsp;Yes</label>&nbsp;
                      <input  type="radio"  (click)="toggleFalse('pmeds')"  formControlName="patientMedication"  [value]="false"/>
                      <label   (click)="pmeds=false"> &nbsp;No</label> -->
                      <textarea  type="text" formControlName="pmeds"   placeholder="Present medication" class="form-control date">
                        </textarea>
                    </div>


                  </div>

                    <hr>

                    <h5 class="mt-2 mb-1">Current Risks</h5>
                    <div class="row">
                      <div class="col-md-8 col-lg-8 row mx-0 px-1">
                        <hr class="w-100 my-1">
                        <div class="row col-12 mx-0 px-0">
                          <div class="col-6">
                            <div class="text-bold">Risk</div>
                          </div>
                          <div class="col-6 row">
                            <div class="col-12 text-bold">Risk Level</div>

                            <div title="Significant" class="col-sm-2 px-1">
                              <small >sig</small><br>
                              <button class="badge badge-sig border-0">S</button>
                            </div>
                            <div title="High" class="col-sm-2 px-1">
                              <small>high</small><br>
                              <button class="badge badge-danger border-0">R</button>
                            </div>
                            <div title="Medium" class="col-sm-2 px-1">
                              <small>med</small><br>
                              <button class="badge badge-warning border-0">A</button>
                            </div>
                            <div title="Low" class="col-sm-2 px-1">
                              <small>low</small><br>
                              <button class="badge badge-success border-0">G</button>
                            </div>
                            <div title="Unkown" class="col-sm-2 px-1">
                              <small>unkown</small><br>
                              <button class="badge badge-default border-0">U</button>
                            </div>



                          </div>
                        </div>

                        <hr class="w-100 my-1">
                        <div class="col-6">
                          <label class="ml-1" for=""> Assault Staff</label><br>
                          <textarea *ngIf="extremeRag.includes(addForm.value.assaultStaff)" formControlName="assaultStaffDesc" placeholder="Explanation" class="form-control">
                          </textarea>
                        </div>
                        <div class="col-6 row">
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="assaultStaff" class="sig-rag" value="S">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="assaultStaff" class="high-rag" value="H">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="assaultStaff" class="med-rag" value="M">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="assaultStaff" class="low-rag" value="L">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="assaultStaff" class="unkown-rag" value="U">
                            </div>
                        </div>

                        <hr class="w-100 my-1">
                        <div class="col-6">
                          <label class="ml-1" for=""> Physical Aggression</label>
                          <textarea *ngIf="extremeRag.includes(addForm.value.physicalAggression)" formControlName="physicalAggressionDesc" placeholder="Explanation" class="form-control">
                          </textarea>
                        </div>
                        <div class="col-6 row">
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="physicalAggression" class="sig-rag" value="S">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="physicalAggression" class="high-rag" value="H">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="physicalAggression" class="med-rag" value="M">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="physicalAggression" class="low-rag" value="L">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="physicalAggression" class="unkown-rag" value="U">
                            </div>
                        </div>



                        <hr class="w-100 my-1">
                        <div class="col-6">
                          <label class="ml-1" for=""> Self Neglect</label>
                          <textarea *ngIf="extremeRag.includes(addForm.value.selfNeglectDesc)" formControlName="verballyAggressiveDesc" placeholder="Explanation" class="form-control">
                          </textarea>
                        </div>
                        <div class="col-6 row">
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="selfNeglect" class="sig-rag" value="S">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="selfNeglect" class="high-rag" value="H">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="selfNeglect" class="med-rag" value="M">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="selfNeglect" class="low-rag" value="L">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="selfNeglect" class="unkown-rag" value="U">
                            </div>
                        </div>


                        <hr class="w-100 my-1">
                        <div class="col-6">
                          <label class="ml-1" for="">Verbal aggression</label>
                          <textarea *ngIf="extremeRag.includes(addForm.value.verballyAggressive)" formControlName="verballyAggressiveDesc" placeholder="Explanation" class="form-control">
                          </textarea>
                        </div>
                        <div class="col-6 row">
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="verballyAggressive" class="sig-rag" value="S">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="verballyAggressive" class="high-rag" value="H">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="verballyAggressive" class="med-rag" value="M">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="verballyAggressive" class="low-rag" value="L">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="verballyAggressive" class="unkown-rag" value="U">
                            </div>
                        </div>


                        <hr class="w-100 my-1">
                        <div class="col-6">
                          <label class="ml-1" for=""> Self Harm</label><br>
                          <textarea *ngIf="extremeRag.includes(addForm.value.selfHarm)" formControlName="selfHarmDesc" placeholder="Explanation" class="form-control">
                          </textarea>
                        </div>
                        <div class="col-6 row">
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="selfHarm" class="sig-rag" value="S">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="selfHarm" class="high-rag" value="H">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="selfHarm" class="med-rag" value="M">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="selfHarm" class="low-rag" value="L">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="selfHarm" class="unkown-rag" value="U">
                            </div>
                        </div>


                        <hr class="w-100 my-1">
                        <div class="col-6">
                          <label class="ml-1" for=""> Absconsion/AWOL</label>
                          <textarea *ngIf="extremeRag.includes(addForm.value.absconsionRisk)" formControlName="absconsionRiskDesc" placeholder="Explanation" class="form-control">
                          </textarea>
                        </div>
                        <div class="col-6 row">
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="absconsionRisk" class="sig-rag" value="S">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="absconsionRisk" class="high-rag" value="H">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="absconsionRisk" class="med-rag" value="M">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="absconsionRisk" class="low-rag" value="L">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="absconsionRisk" class="unkown-rag" value="U">
                            </div>
                        </div>


                        <hr class="w-100 my-1">
                        <div class="col-6">
                          <label class="ml-1" for=""> Gender Issues</label>
                          <textarea *ngIf="extremeRag.includes(addForm.value.genderIssues)" formControlName="genderIssuesDesc" placeholder="Explanation" class="form-control">
                          </textarea>
                        </div>
                        <div class="col-6 row">
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="genderIssues" class="sig-rag" value="S">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="genderIssues" class="high-rag" value="H">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="genderIssues" class="med-rag" value="M">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="genderIssues" class="low-rag" value="L">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="genderIssues" class="unkown-rag" value="U">
                            </div>
                        </div>


                        <hr class="w-100 my-1">
                        <div class="col-6">
                          <label class="ml-1" for=""> Racial Issues</label>
                          <textarea *ngIf="extremeRag.includes(addForm.value.racialIssues)" formControlName="racialIssuesDesc" placeholder="Explanation" class="form-control">
                          </textarea>
                        </div>
                        <div class="col-6 row">
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="racialIssues" class="sig-rag" value="S">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="racialIssues" class="high-rag" value="H">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="racialIssues" class="med-rag" value="M">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="racialIssues" class="low-rag" value="L">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="racialIssues" class="unkown-rag" value="U">
                            </div>
                        </div>


                        <hr class="w-100 my-1">
                        <div class="col-6">
                          <label class="ml-1" for=""> Sexually Inappropriate Behaviour</label>
                          <textarea *ngIf="extremeRag.includes(addForm.value.sexuallyInappropriate)" formControlName="sexuallyInappropriateDesc" placeholder="Explanation" class="form-control">
                          </textarea>
                        </div>
                        <div class="col-6 row">
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="sexuallyInappropriate" class="sig-rag" value="S">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="sexuallyInappropriate" class="high-rag" value="H">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="sexuallyInappropriate" class="med-rag" value="M">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="sexuallyInappropriate" class="low-rag" value="L">
                            </div>
                            <div class="col-sm-2 px-1">
                              <input type="radio"   formControlName="sexuallyInappropriate" class="unkown-rag" value="U">
                            </div>
                        </div>

                      </div>
                      <div class="col-md-4 col-lg-4">
                        <div class="form-group ">
                          <div class="">
                            <label>Can the person walk to the vehicle and step up into a vehicle unaided </label><br>
                            &nbsp;<input   type="radio"    formControlName="walk"  [value]="true"/>
                            <label > &nbsp;Yes</label>&nbsp;
                            <input  type="radio"   formControlName="walk" [value]="false"/>
                            <label  > &nbsp;No</label>
                            <br>
                            <input *ngIf="!addForm.value.walk" type="text" maxlength="255"   formControlName="walkInfo" class="form-control" placeholder="Elaborate on mobility issues">
                          </div>
                          <div class="">
                            <label>Do you require the use of mechanical restraint/soft cuffs?</label><br>
                            &nbsp;<input   type="radio" formControlName="passengerRequiresRestraints"   [value]="true"/>
                            <label for="passengerRequiresRestraints"> &nbsp;Yes</label>&nbsp;
                            <input  type="radio" formControlName="passengerRequiresRestraints" [value]="false"/>
                           <label for="passengerRequiresRestraints"> &nbsp;No</label>
                           <label *ngIf="addForm.value.passengerRequiresRestraints">State Reason for Mechanical Restraint</label><br>
                           <textarea *ngIf="addForm.value.passengerRequiresRestraints" type="text" formControlName="reasonsForRestrains" class="form-control" cols="70"></textarea>
                          </div>
                          <div class="">
                            <label>Is patient aware of the transport?&nbsp;<br>
                              <input   type="radio" formControlName="isPassengerAwareOfTransport"  [value]="true"/>
                              <label for="isPassengerAwareOfTransport"> &nbsp;Yes</label>&nbsp;
                              <input  type="radio" formControlName="isPassengerAwareOfTransport"  [value]="false"/>
                               <label for="isPassengerAwareOfTransport"> &nbsp;No</label>
                            </label>
                          </div>
                          <textarea type="text" maxlength="255"   formControlName="otherRisks" class="w-100" style="height:199px" placeholder="Historic Risks & Other risks"></textarea>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="  text-white  ">
              <h5 class=" bg-main border radius-2  py-2 px-4 w-80" style="border-radius: 5px;"h5 class=" bg-main border radius-3  py-2 px-4 w-80" style="border-radius: 5px;">
                Staffing Requirements</h5>
            </div>
            <div class="row">
              <div class="form-group col-6 col-sm-12 col-md-12">
                <hr>
              </div>

              <div class="mt-1 col-12 col-md-12 btn-group">
                <Label>If ward staff are escorting the patient enter number of staff</Label>
                <!-- &nbsp;<input   type="radio" (click)="toggleTrue('wardEscort')"  formControlName="areStaffEscortingPatient"  [value]="true"/>
                <label > &nbsp;Yes</label>&nbsp;
                <input  type="radio" (click)="toggleFalse('wardEscort')"  formControlName="areStaffEscortingPatient"  [value]="false"/>
                <label   > &nbsp;No</label> -->
                <span>
                  <input type="number" maxlength="255"   formControlName="wardEscort" class="form-control mx-2" placeholder="Number of staff">
                </span>
              </div>
            </div>



            <label>Any Special Request/Instructions</label><br>
              <textarea type="text" formControlName="specialRequests" class="form-control" rows="2" cols="70" placeholder="Enter any special requests or instructions"></textarea>
            <br>

            <hr>

            <h5>Required Staff</h5>
            <div>
                <div class="row  bg-default">
                    <div class="mt-1 col-sm-12 col-md-6">
                      <label>Job Description</label>
                    </div>
                    <!-- <div class="mt-1 col-sm-12 col-md-4">
                      <Label>Gender</Label>
                    </div> -->
                    <div class="mt-1 col-sm-12 col-md-6">
                      <Label>Number Of Staff</Label>
                    </div>
                </div>
            </div>
            <div *ngFor="let r of escortStaffArray.controls; let i = index; trackBy: track" class="card bg-grey p-2 mb-1">
              <form [formGroup]="r"  >
                <div class="row form-group bg-default mb-0">

                    <div class="mt-1 col-sm-12 col-md-6">
                      <select disabled class="form-control" formControlName="assignmentCode">
                        <option value="" selected disabled>
                          Select Job Description
                        </option>
                        <option *ngFor="let a of assCodes" value="{{ a.id }}"  >
                          {{ a.name }}
                        </option>
                      </select>
                    </div>
                    <!-- <div class="mt-1 col-sm-12 col-md-3">
                      <select class="form-control" formControlName="gender" placeholder="Gender">
                        <option value="" selected disabled> Select Gender</option>
                        <option value="MALE">Male</option>
                        <option value="FEMALE">Female</option>
                        <option value="NO_PREFERENCE">NO PREFERENCE</option>
                      </select>
                    </div> -->
                    <div class="mt-1 col-sm-12 col-md-6">
                      <input type="number"  placeholder="" formControlName="numberOfStaff" placeholder="Number of Staff" class="form-control">
                    </div>
                    <!-- <div *ngIf="i!=0" class="d-flex col-md-2">
                      <span></span>
                      <span class="m-auto"></span>
                      <button class="btn btn-danger ml-4" (click)="removeEscort(i)">
                        Remove
                      </button>
                    </div> -->


                </div>
              </form>

            </div>

            <!-- <button type="button" class="btn btn-success  mb-2" (click)="addEscort()">
              <i class="fa fa-plus"></i>&nbsp;Add More Staff
            </button> -->
            <br>

            <div class="  text-white  ">
              <h5 class=" bg-main border radius-2  py-2 px-4 w-80" style="border-radius: 5px;"h5 class=" bg-main border radius-3  py-2 px-4 w-80" style="border-radius: 5px;">
                Billing Information
              </h5>
            </div>

            <div class="row">

              <div class="mt-1 col-12 col-md-4">
                <Label>Purchase Order Number</Label>
                <input type="text" formControlName="porderNum"   placeholder="" class="form-control date">
              </div>

              <div class="mt-1 col-12 col-md-4">
                <Label>SBS Code</Label>
                <input type="text" formControlName="sbsCode"   placeholder="" class="form-control date">
              </div>

              <div class="mt-1 col-12 col-md-4">
                <Label>Business Name</Label>
                <input type="text" formControlName="bname"   placeholder="" class="form-control date">
              </div>

              <div class="mt-1 col-12 col-md-4">
                <Label>Business Address</Label>
                <input type="text" formControlName="baddress"   placeholder="" class="form-control date">
              </div>

              <div class="mt-1 col-12 col-md-4">
                <Label>Billing Post Code</Label>
                <input type="text" formControlName="bpostCode"   placeholder="" class="form-control date">
              </div>

              <div class="mt-1 col-12 col-md-4">
                <Label>Billing Invoice Email</Label>
                <input type="text" formControlName="bemail"   placeholder="" class="form-control date">
              </div>
            </div>




            <div class="row">
              <div class="mt-1 col-12 col-md-4">
                <Label>Form submitted by</Label>
                <input type="text" formControlName="submittedBy"   placeholder="" class="form-control date">
              </div>
              <div class="mt-1 col-12 col-md-4">
                <Label>Submitter Email</Label>
                <input type="text" formControlName="semail"   placeholder="" class="form-control date">
              </div>
              <div class="mt-1 col-12 col-md-4">
                <Label>Submitter Phone</Label>
                <input type="text" formControlName="sphone"   placeholder="" class="form-control date">
              </div>
              <div class="mt-1 col-12 col-md-">
                <Label>By ticking the box below, I have full authority to place this booking and once this service is booked an invoice will be raised for the full amount.
                  <span>
                    <input type="checkbox" class="checkbox" formControlName="disclaimer"   [value]="true" >
                  </span>
                </Label>&nbsp;

              </div>
            </div>

            <div class="form-group col-12 col-sm-12 col-md-12">
              <div class="mt-4" align="right" >
                <button type="button" class="btn btn-danger btn-sm mr-3" (click)="closeModals()">
                  Cancel
                  </button>
                <button type="submit" class="btn btn-success  mx-2" >Submit </button>
              </div>
            </div>
          </div>
        </div>
      </form>
  </div>

  <div *ngIf="requestSent==true" class="p-4">
    <h2 class="text-success">Request was received successfully. We will be in touch soon.</h2>
  </div>

</div>
