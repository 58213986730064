import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  //base_url: string = environment.API_url + ':8300/api/v1';
  base_url: string = environment.API_url + '/worklink-api/api/v1';

  constructor(
    private http: HttpClient
  ) { }

  adminDashboardStatistics(): Observable<any> {
    return this.http.get(`${this.base_url}/admin-stats`);
  }

  getNumberOfAgencies(): Observable<any> {
    return this.http.get(`${this.base_url}/agency-dashboard`);
  }

  getAgencyStats(ref): Observable<any> {
    return this.http.get(`${this.base_url}/agency-stats/${ref}`);
  }

  getClientCounters(ref): Observable<any> {
    return this.http.get(`${this.base_url}/client-agencies/${ref}`);
  }

  clientDashboardStatistics(): Observable<any> {
    return this.http.get(`${this.base_url}/client-dashboard`);
  }

  getClientStats(ref): Observable<any> {
    return this.http.get(`${this.base_url}/client-stats/${ref}`);
  }

  getAgencyShiftsDashboard(ref): Observable<any> {
    return this.http.get(`${this.base_url}/agency-shifts-dashboard/${ref}`);
  }

  getClientShiftsDashboard(ref): Observable<any> {
    return this.http.get(`${this.base_url}/client-shifts-dashboard/${ref}`);
  }

  getAgencyTransportShiftsDashboard(ref): Observable<any> {
    return this.http.get(`${this.base_url}/transport/agency/dashboard/${ref}`);
  }

  getClientTransportShiftsDashboard(ref): Observable<any> {
    return this.http.get(`${this.base_url}/transport/client/dashboard/${ref}`);
  }

  getWorkerShiftsDashboard(ref): Observable<any> {
    return this.http.get(`${this.base_url}/worker-shifts-dashboard/${ref}`);
  }

  shiftDashboardStatistics(): Observable<any> {
    return this.http.get(`${this.base_url}/shifts-dashboard`);
  }

  getWorkerStats(ref): Observable<any> {
    return this.http.get(`${this.base_url}/worker-stats/${ref}`);
  }

  workerDashboardStatistics(): Observable<any> {
    return this.http.get(`${this.base_url}/worker-dashboard`);
  }


}
