// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//
export const environment = {

  production: false,
  // API_url: window["env"]["apiUrl"] || "default",
  // API_url2: window["env"]["apiUrl"] || "default",
  // debug: window["env"]["debug"] || false,

  // API_url: 'http://localhost:8765', // dev server
  // baseUrl:'http://localhost:4200', // dev server
  // API_url: 'https://test-api.myworklink.uk', // dev server
  // baseUrl:'https://test.myworklink.uk', // dev server
  API_url: 'https://api.myworklink.uk', // dev server
  baseUrl:'https://online.myworklink.uk', // dev server
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.ng serve
 *
 * This import should be commented out in production modxe because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
