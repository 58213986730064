import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TransportService } from 'src/app/shared/services/transport.service';
import { CompressImageService } from 'src/app/shared/utilities/compress-image';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-photos-tab',
  templateUrl: './photos-tab.component.html',
  styleUrls: ['./photos-tab.component.css']
})
export class PhotosTabComponent implements OnChanges {
  @Input('form') form: FormGroup;
  @Input('job') job: any;
  @Input('tabindex') tabindex: string;
  @Output() formO = new EventEmitter<FormGroup>();
  @Output() tabindexO = new EventEmitter<string>();

  page = 1;
  pageSize = 20;
  totalItems = 0;
  showin = 0;
  first: boolean;
  last: boolean;

  userType: string;
  clients: any;
  searchCriteria: string = null;
  directorates: any;
  pics = [];
  selectedImage: any;

  constructor(
    private toast: ToastrService,
    private transporService: TransportService,
    private storageService: StorageService,
    private agencyService: AgencyService,
    private fb: FormBuilder,
    private router: Router,
    private compressImage: CompressImageService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef // Added ChangeDetectorRef
  ) { }

  ngOnChanges(): void {}

  newPage() {
    return this.router.navigate([`/worker/transport`]);
  }

  nextTab() {
    console.log(this.tabindex);
    this.tabindex = "";
    this.tabindexO.emit(this.tabindex);
    this.formO.emit(this.form);
  }

  prevTab() {
    console.log(this.tabindex);
    this.tabindex = "logs";
    this.tabindexO.emit(this.tabindex);
    this.formO.emit(this.form);
  }

  onChange(event) {
    console.log(event.target.files);


    let done = false;

    for (let i = 0; i < event.target.files.length; i++) {
      let image = event.target.files[i];

      console.log(`Image size before compressed: ${image.size} bytes.`);
      this.compressImage.compress(image)
        .pipe(take(1))
        .subscribe(compressedImage => {
          this.pics = [];
          console.log(`Image size after compressed: ${compressedImage.size} bytes.`);
          this.pics.push(compressedImage);
          console.log(this.pics);
          this.submitInventories();

          // Trigger change detection after adding the image
          this.cdr.detectChanges();
        });
    }


    this.ngOnChanges();
  }

  submitInventories() {
    this.transporService.uploadVehicleImage(this.pics, this.job.id).subscribe(
      event => {
        this.pics = [];
        if (event instanceof HttpResponse) {
        };
        this.toast.info("Successfully uploaded.");
        this.modalService.dismissAll();
        this.tabindexO.emit(this.tabindex);
      }
    );
  }

  deletePhoto() {
    this.transporService.deletePhoto(this.selectedImage.id).subscribe(
      event => {
        this.toast.info("Successfully deleted.");
        this.modalService.dismissAll();
        this.tabindexO.emit(this.tabindex);
      }
    );
  }

  setMain() {
    this.transporService.setMain(this.selectedImage.id).subscribe(
      event => {
        this.toast.info("Successfully set as main.");
        this.modalService.dismissAll();
        this.tabindexO.emit(this.tabindex);
      }
    );
  }

  handlePageChange(event) {
    if (event == 'next') {
      this.page += 1;
    } else if (event == 'prev' && this.page != 1) {
      this.page -= 1;
    }
  }

  updateMileage() {
    this.transporService.updateMileage(this.form.value).subscribe(
      data => { this.toast.success("Info updated") }
    );
  }

  openModal(mod, pic) {
    this.selectedImage = pic;
    this.modalService.open(mod, { centered: true });
  }
}
