import { Component, ElementRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent {
  // @ViewChild('skipper', {static: false} ) skipper: ElementRef;
  // @ViewChild('goToTop', {static: false} ) goToTop: ElementRef;
  title = 'worklinkangular';
  currentUrl: any = '';


constructor(private router: Router) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        e.url.replace('#content', '');
        if (e.url != '') {
          this.currentUrl = e.url;
        } else {
          this.currentUrl ='';
        }
      }
    });
  }




  public skipToMainContent(): void {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {

        console.log(e.url, this.currentUrl);
        e.url =  e.url.replace('#content', '');
        console.log(e.url, this.currentUrl);

        console.log(e);

        if (e.url != '') {
          this.currentUrl = e.url;
        } else {
          this.currentUrl ='';
        }
      }
    });
  }

  public GoBackToTop(): void {
  }
}
