<div class="m-5">
  <div class="container-fluid">
    <div class="row text-center">
      <div class="col-12 col-sm-12 col-md-8 text-left">
        <h3> New Secure Transport Booking</h3>
      </div>
    </div>
  </div>

  <section class="content mt-4">
    <div class="container-fluid mt-1 ">
      <div class="row">
        <div class="col-12">
          <h5>Date & Time</h5>
        </div>

        <div class="form-group col-6 col-sm-12 col-md-6">
          <label>Collection date</label>
          <input type="text"  maxlength="255"  value="{{job?.dateTimeRequired| date:'dd/MM/yyyy'}}" class="form-control" disabled >
        </div>
        <div class="form-group col-6 col-sm-12 col-md-6">
          <label>Collection time</label>
          <input type="text"  maxlength="255"   value="{{job?.dateTimeRequired | date:'HH:mm'}}" name="mentalHealthStatus" class="form-control" disabled >
        </div>

        <div class="col-12">

          <h5>Collection details</h5>
        </div>

        <div class="form-group col-sm-12 col-md-4">
          <label>Adresss</label>
          <input type="text"  maxlength="255"  [value]="job?.pcaddress"  name="mentalHealthStatus" class="form-control" disabled >
        </div>
        <div class="form-group col-sm-12 col-md-4">
          <label>Post code</label>
          <input type="text"  maxlength="255"  [value]="job?.pickupPostCode"  name="mentalHealthStatus" class="form-control" disabled >
        </div>
        <div class="form-group col-sm-12 col-md-4">
          <label>Phone</label>
          <input type="text"  maxlength="255"  [value]="job?.pickupLocationContactNumber"  name="mentalHealthStatus" class="form-control" disabled >
        </div>


        <div class="col-12">
          <h5>Destination</h5>
        </div>

        <div class="form-group col-sm-12 col-md-4">
          <label>Address</label>
          <textarea type="text"  maxlength="255"  [value]="job?.destination"  name="mentalHealthStatus" class="form-control" disabled >
          </textarea>
        </div>
        <div class="form-group col-sm-12 col-md-4">
          <label>Post code</label>
          <input type="text"  maxlength="255"  [value]="job?.destinationPostCode"  name="mentalHealthStatus" class="form-control" disabled >
        </div>
        <div class="form-group col-sm-12 col-md-4">
          <label>Phone</label>
          <input type="text"  maxlength="255"  [value]="job?.destinationContactNumber"  name="mentalHealthStatus" class="form-control" disabled >
        </div>


        <div class="col-12">

          <div class="">
            <div class=" row ">
              <div class="col-12"><h5>Patient Information</h5></div>
              <div class="form-group col-sm-12 col-md-6">
                <label>Name</label>
                <textarea type="text"  maxlength="255"  [value]="job?.destination"  name="mentalHealthStatus" class="form-control" disabled >
                </textarea>
              </div>
              <div class="form-group col-sm-12 col-md-6">
                <label>NHS Number</label>
                <input type="text"  maxlength="255"  [value]="job?.destinationPostCode"  name="mentalHealthStatus" class="form-control" disabled >
              </div>

              <div class="form-group col-sm-12 col-md-3">
                <label>Age</label>
                <input type="text"  maxlength="255"  [value]="job?.destinationContactNumber"  name="mentalHealthStatus" class="form-control" disabled >
              </div>
              <div class="form-group col-sm-12 col-md-3">
                <label>Gender</label>
                <input type="text"  maxlength="255"  [value]="job?.destinationContactNumber"  name="mentalHealthStatus" class="form-control" disabled >
              </div>
              <div class="form-group col-sm-12 col-md-3">
                <label>Medical</label>
                <input type="text"  maxlength="255"  [value]="job?.destinationContactNumber"  name="mentalHealthStatus" class="form-control" disabled >
              </div>
              <div class="form-group col-sm-12 col-md-3">
                <label>Verbal aggression</label>
                <input type="text"  maxlength="255"  [value]="job?.destinationContactNumber"  name="mentalHealthStatus" class="form-control" disabled >
              </div>


              <div class="col-12">
                Mobility Issues - Can the person walk to the vehicle and step up into a
                vehicle unaided?<br>
                <b>Yes</b>
              </div>

              <div class="col-12 btn-group">
                <span>Risk of restraint in and out of the </span>

              </div>






            </div>
          </div>
        </div>


        <div class="col-sm-6 col-md-6">
          {{job?.vehicle?.name}} | {{job?.vehicle?.description}} {{job?.vehicle?.regno}}
        </div>

        <div class="col-sm-6 col-md-6 text-right">
          <a routerLink="/guest/team-leader/entry/{{jobId}}"><button type="button" class="btn-primary">Start Job</button></a>
        </div>

      </div>

    </div>
  </section>



</div>
