<div class="">
  <div *ngIf="applicationLoaded" class="">
    <form [formGroup]="addApplicationForm" (ngSubmit)="addWorkerApplication(addApplicationForm,false, true)" (change)="setSubmit()" >
      <!-- <form [formGroup]="addForm" (ngSubmit)="newForm(addForm)"> -->
        <div class="bg-modal card-body m-0 p-0 ">
          <div class="container-fluid">
            <div class="my-3  pb-4">
              <img
              class="my-4 showMePrint"
              src="/assets/images/svg/new-logo.svg"
              alt="logo"
              style="width:200px; "
              hidden
            />
            <h3>Application Form : {{worker?.firstname}} {{worker?.lastname}}</h3>
          </div>
          <section>
              <div class="">
                <i class="bi bi-info-circle"></i>
                <span class="text-info-custom">You must complete all sections of the application form electronically. We will use this form to help us decide on your suitability for
                   the post so please make sure it is accurate and complete. </span>
                <div class="  text-white  ">
                  <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">Vacancy Information</h5>

                </div>
                <!-- <hr class="bg-black mt-0"> -->
                <div class="row w-100">
                  <div class="form-group col-12 col-sm-12 col-md-6">
                    <Label>Position being applied for</Label>
                    <input type="text" maxlength="255" formControlName="position"  placeholder="" class="form-control">
                  </div>
                  <div class="form-group col-12 col-sm-12 col-md-6">
                    <Label>Location</Label>
                    <input type="text" maxlength="255"  placeholder="" formControlName="location" class="form-control">
                    </div>
                  <div class="form-group col-12 col-sm-12 col-md-6">
                    <Label>Hours</Label>
                      <select class="form-control" formControlName="hours" >
                          <option value="full" >Full Time</option>
                          <option value="part" >Part Time</option>
                          <option value="zero" >Zero Contact Hours</option>
                      </select>
                  </div>
                </div>
              </div>
          </section>

          <section>
              <div class="mb-4">
                <div class="  text-white  ">
                  <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">Personal Details</h5>
                </div>
                <!-- <hr class="bg-black mt-0"> -->
                <div class="row form-group">
                  <div class="mt-1 col-sm-12 col-md-6">
                    <Label>Title</Label>
                    <select class="form-control" formControlName="title">
                      <option value="Mr" >Mr</option>
                      <option value="Mrs" >Mrs</option>
                      <option value="Miss" >Miss</option>
                      <option value="Mrs" >Ms</option>
                      <option value="Mrs" >Dr</option>
                    </select>                  </div>
                  <div class="mt-1 col-sm-12 col-md-6">
                    <Label>Surname</Label>
                    <input type="text" maxlength="255" value="{{worker?.lastname}}" formControlName="lastname"   disabled  class="form-control">
                  </div>
                  <div class="mt-1 col-sm-12 col-md-6">
                    <Label>First names</Label>
                    <input type="text" maxlength="255"  placeholder="" value="{{worker?.firstname}}"  formControlName="firstname" disabled class="form-control">
                  </div>
                  <div class="mt-1 col-sm-12 col-md-6">
                    <Label>Preferred name</Label>
                    <input type="text" maxlength="255"  placeholder="" formControlName="preferredName" class="form-control">
                  </div>
                  <div class="mt-1 col-sm-12 col-md-6">
                    <Label>Nationality</Label>
                    <select formControlName="nationality" disabled class="form-control">
                      <option *ngFor="let c of countries"  [selected]="worker?.nationality==c.code"  value="{{c.code}}" >{{c.name}}</option>
                    </select>
                  </div>
                  <div class="mt-1 col-sm-12 col-md-6">
                    <Label>Date of birth</Label>
                    <input type="date" class="date" disabled  value="{{worker?.dob}}"  placeholder="" class="form-control date">
                  </div>

                <div class="mt-1 col-12 col-sm-12 col-md-12">
                  <Label>Home address</Label>
                  <input type="text" disabled maxlength="255" [value]="worker?.address" formControlName="address" placeholder="" class="form-control">
                </div>


                  <div class="mt-1 col-sm-12 col-md-6">
                    <Label>Email</Label>
                    <input type="text" maxlength="255" value="{{worker?.email}}"  formControlName="email" disabled placeholder="" class="form-control">
                  </div>
                  <div class="mt-1 col-sm-12 col-md-6">
                    <Label>Preferred Contact Number</Label>
                    <input type="text" maxlength="255" [value]="worker?.phoneNumber" formControlName="phone" placeholder="" class="form-control">
                  </div>
                </div>
              </div>

          </section>

          <section>
              <div class="mb-4">

                <div class="  text-white  ">
                  <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">Entitlement To Work In The UK</h5>
                  <span class="ml-2 text-info-custom">
                    <i class="bi bi-info-circle"></i>
                  All applicants will be required to bring original documentation to interview
                  as evidence for entintlement to work in the UK.
                </span>
                </div>
                <hr class="bg-black mt-0">

                <div class="row form-group">
                  <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                    <div class="col-md-9">I confirm that I am entitled to work in the uk </div>

                    <div class="col-md-3">
                      <input  type="radio" formControlName="ukWork" name="ukWork" id="ukWork" [value]="true"/>
                      <label for="ukWork"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                      <input type="radio" formControlName="ukWork" name="ukWork" id="ukWork" [value]="false"/>
                      <label for="ukWork"> &nbsp;No</label><br/>
                    </div>

                  </div>
                  <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                    <div class="col-md-9">Do you have any restrictions that apply to you.</div>

                    <div class="col-md-3">
                      <input type="radio" formControlName="restriction"  id="rde2" (click)="setYes('restriction')"  [value]="true"/>
                      <label for="restriction"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                      <input type="radio" formControlName="restriction"  id="rde2"  (click)="resetYes('restriction')" [value]="false">
                      <label for="restriction"> &nbsp;No</label><br/>
                    </div>

                  </div>

                  <div *ngIf="restriction==true" class="mt-1 col-sm-12 col-md-12">
                    <Label>Please provide details:</Label>
                    <textarea maxlength="1000" type="text" rows="9" formControlName="restrictionRes"  placeholder="" class="form-control"></textarea>
                    <small class="">Max 1000 characters</small>

                  </div>


                  <div class="mt-1 col-sm-12 col-md-6">
                    <Label>National Insurance Number:</Label>
                    <input type="text" maxlength="255" formControlName="insuaranceNum" placeholder="" class="form-control">
                  </div>


                </div>
              </div>

          </section>

          <section>
              <div class="mb-4">

                <div class="  text-white  ">
                  <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
                    Current Or Most Recent Employer</h5>
                </div>

                <div *ngFor="let r of employersArray.controls; let i = index; trackBy: track" class="mb-4">
                  <form [formGroup]="r"  >
                    <div class="row form-group p-3 bg-default">
                      <hr class=" mt-0">
                      <div class="mt-1 col-sm-12 col-md-12">
                        <Label class="d-flex">
                          <span>Job Title</span>
                          <span class="m-auto"></span>
                        </Label>
                        <input type="text" maxlength="255" formControlName="job"  placeholder="" class="form-control">
                      </div>
                      <div class="mt-1 col-sm-12 col-md-6">
                        <Label>Employer Name</Label>
                        <input formControlName="name"   placeholder="" class="form-control">
                      </div>
                      <div class="mt-1 col-sm-12 col-md-6">
                        <Label>Employer Address</Label>
                        <input formControlName="address"   placeholder="" class="form-control">
                      </div>
                      <div class="mt-1 col-sm-12 col-md-6">
                        <Label>Start Date</Label>
                        <input type="date" formControlName="start"   placeholder="" class="form-control date">
                      </div>
                      <div class="mt-1 col-sm-12 col-md-6">
                        <Label>End Date</Label>
                        <input type="date" formControlName="end"  placeholder="" class="form-control date">
                      </div>

                      <div class="mt-1 col-sm-12 col-md-12">
                        <Label>Provide a brief description of your main duties,
                          responsibilities and achievements.
                        </Label>
                        <textarea maxlength="1000" type="text" rows="9" formControlName="description" placeholder="" class="form-control"></textarea>
                        <small class="">Max 1000 characters</small>
                      </div>
                      <div class="mt-1 col-sm-12 col-md-6">
                        <Label>Notice Period
                        </Label>
                        <input type="text" maxlength="255" formControlName="notice" placeholder="" class="form-control">
                      </div>
                      <div class="mt-1 col-sm-12 col-md-6">
                        <Label>Salary <small>(optional)</small>
                        </Label>
                        <input type="text" maxlength="255" formControlName="salary"  placeholder="" class="form-control">
                      </div>

                    </div>
                  </form>
                </div>
              </div>
          </section>

          <section>
              <div class="mb-4">

                <div class="  text-white  ">
                  <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
                    Employment And Non-Employment History</h5>

                  <span class="ml-2 text-info-custom">
                    <i class="bi bi-info-circle"></i>
                    It is essential that a COMPLETE record of all periods of
                    employment and non-employment are fully documented here from
                    the time of leaving education. Please complete in the format requested
                    and in chronological order. Any previous employer(s) may be contacted should the need arise.
                  </span>

                </div>


                <hr class="bg-black mt-0">


                <div *ngFor="let r of employmentArray.controls; let i = index; trackBy: track" class="mb-4">
                  <form [formGroup]="r"  >
                    <div class="row form-group p-3 bg-default">

                      <!-- <div *ngFor="let e of nHistory" class="row form-group bg-default"> -->

                        <div class="d-flex col-12">
                          <span></span>
                          <span class="m-auto"></span>
                          <button class="btn btn-danger ml-4 btn-circle" (click)="removeEmployment(i)">
                            <i class="fa fa-minus"></i>
                          </button>
                        </div>

                        <div class="mt-1 col-sm-12 col-md-4">
                          <Label>Start Date</Label>
                          <input type="date" formControlName="start"  placeholder="" class="form-control">
                        </div>
                        <div class="mt-1 col-sm-12 col-md-4">
                          <Label>Employer Name</Label>
                          <input   placeholder="" formControlName="employer"  class="form-control">
                        </div>
                        <div class="mt-1 col-sm-12 col-md-4">
                          <Label>Position Held</Label>
                          <input   placeholder="" formControlName="position" class="form-control">
                        </div>

                        <div class="mt-1 col-sm-12 col-md-4">
                          <Label>End Date</Label>
                          <input type="date" formControlName="end"  placeholder="" class="form-control">
                        </div>
                        <div class="mt-1 col-sm-12 col-md-4">
                          <Label>Employer Address</Label>
                          <input formControlName="address"   placeholder="" class="form-control">
                        </div>
                        <div class="mt-1 col-sm-12 col-md-4">
                          <Label>Reason for leaving</Label>
                          <input  formControlName="reason"  placeholder="" class="form-control">
                        </div>

                    </div>
                  </form>
                </div>
                <button type="button" class="btn btn-success btn-circle mb-2" (click)="addEmployment()">
                  <i class="fa fa-plus"></i>
                </button>
              </div>

          </section>


          <section>
              <div class="mb-4">

                <div class="  text-white  ">
                  <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
                    Education, Training and Proffessional Status</h5>

                  <span class="ml-2 text-info-custom">
                    <i class="bi bi-info-circle"></i>
                    List your education history starting with your
                    current or most recent. Also list all relevant training
                    (professional vocational and on the job) applicable to the position you are
                    applying for. Please include details of the membership of any professional
                    associations or statutory body together with registration numbers. Please
                    note that if a post requries a qualification/ professional registration, you
                    will be asked to produce original certificates at the interview stage.
                  </span>

                </div>
                <hr class="bg-black mt-0">


                <div *ngFor="let r of educationArray.controls; let i = index; trackBy: track" class="mb-4">
                  <form [formGroup]="r"  >
                    <div class="row form-group p-3 bg-default">
                      <div class="d-flex col-12">
                        <span></span>
                        <span class="m-auto"></span>
                        <button class="btn btn-danger ml-4 btn-circle" (click)="removeEducation(i)">
                          <i class="fa fa-minus"></i>
                        </button>
                      </div>
                      <div class="mt-1 col-sm-12 col-md-6">
                        <Label>Name of Institution</Label>
                        <input type="text" maxlength="255" formControlName="name" placeholder="" class="form-control">
                      </div>
                      <div class="mt-1 col-sm-12 col-md-6">
                        <Label>Qualification achieved</Label>
                        <input formControlName="qualification"  placeholder="" class="form-control">
                      </div>
                      <div class="mt-1 col-sm-12 col-md-4">
                        <Label>Period: From</Label>
                        <input type="date" formControlName="start" placeholder="" class="form-control">
                      </div>
                      <div class="mt-1 col-sm-12 col-md-4">
                        <Label>To</Label>
                        <input type="date" formControlName="end" placeholder="" class="form-control">
                      </div>

                    </div>
                  </form>
                </div>

                <button type="button" class="btn btn-success btn-circle mb-2" (click)="addEducation()">
                  <i class="fa fa-plus"></i>
                </button>


              </div>

          </section>





          <section>
            <div class="mb-4">
              <div class="  text-white  ">
                <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;"> Proffessional Registration</h5>
              </div>
              <!-- <hr class="bg-black mt-0"> -->

              <div class="row form-group">
                <div class="mt-1 col-sm-12 col-md-6">
                  <Label> Professional Registration</Label>
                  <select class="form-control" formControlName="profreg">
                    <option value="NMC" >NMC</option>
                    <option value="UCAS" >UCAS</option>
                    <option value="SAQA" >SAQA</option>
                  </select>
                </div>
                <div class="mt-1 col-sm-12 col-md-6">
                  <Label>Certificate Number</Label>
                  <input type="text" maxlength="255" formControlName="certificate"  class="form-control">
                </div>

              </div>
            </div>

        </section>







          <section>
              <div class="mb-4">

                <div class="  text-white  ">
                  <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
                    Personal Statement</h5>

                  <span class="ml-2 text-info-custom">
                    <i class="bi bi-info-circle"></i>
                    This section is a particularly important part of your application so
                    that we can measure you suitability and help us
                    to consider your application further. We recommend that you provide as much evidence as
                    possible to show how your skills, abilities, knowledge and experience meet the selection
                    criteria for the job description and person specification. Use examples from your most recent
                    job and any other relevant experience gained through pai  d or unpaid
                    employment , life experience, activities and interests.
                  </span>

                </div>
                <hr class="bg-black mt-0">

                <div class="row form-group ">
                  <div class="mt-1 col-sm-12 col-md-12">
                    <textarea maxlength="2000" rows="18" formControlName="personalState" type="text"  placeholder="" class="form-control"></textarea>
                    <small class="">Max 2000 characters</small>
                  </div>


                </div>
              </div>

          </section>


          <section>
              <div class="mb-4">

                <div class="  text-white  ">
                  <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
                    References</h5>

                  <span class="ml-2 text-info-custom">
                    <i class="bi bi-info-circle"></i>
                    Please provide details of two references of which one MUST be your current
                    or last employer, or education provision if this is your first job. References from relatives or people who
                    you only know as a friend cannot be accepted. Additional references may be
                    required to complete our safe recruitment processs. We will seek information
                    about any safeguarding concerns. This company has the final decision on whether
                    references are "satisfactory" and meet with company requirements. If you have any
                    concerns about this, please do not hesitate to contact us before you apply for a job with us.
                  </span>

                </div>
                <hr class="bg-black mt-0">


                <div *ngFor="let r of refsArray.controls; let i = index; trackBy: track" class="mb-4">
                  <form [formGroup]="r"  >
                    <div class="row form-group p-3 bg-default">

                      <div class="d-flex col-12">
                        <span></span>
                        <span class="m-auto"></span>
                        <button class="btn btn-danger ml-4 btn-circle" (click)="removeRef(i)">
                          <i class="fa fa-minus"></i>
                        </button>
                      </div>


                      <!-- <h4>Reference {{r.indexOf(r)+2}}</h4> -->
                      <!-- <h4>Reference {{i+1}} <span *ngIf="i==0">  (Current Or Most Recent Employer)</span></h4> -->
                      <h4>Reference {{i+1}} </h4>
                      <div class="mt-1 col-sm-12 col-md-12">
                        <Label>Organization name</Label>
                        <input type="text" maxlength="255"  placeholder="" formControlName="name" class="form-control" >
                        <input type="text" maxlength="255"  hidden formControlName="latest" class="form-control" [value]="i==0?true:false" >
                      </div>
                      <div class="mt-1 col-sm-12 col-md-12">
                        <Label>Position Held</Label>
                        <input type="text" maxlength="255"  placeholder="" formControlName="position" class="form-control">
                      </div>
                      <div class="mt-1 col-sm-12 col-md-12">
                        <Label>Employer name</Label>
                        <input type="text" maxlength="255"  placeholder="" formControlName="employer" class="form-control">
                      </div>
                      <div class="mt-1 col-sm-12 col-md-12">
                        <Label>Relationship to you</Label>
                        <input type="text" maxlength="255"  placeholder="" formControlName="relationship" class="form-control">
                      </div>
                      <div class="mt-1 col-sm-12 col-md-12">
                        <Label>Organization address</Label>
                        <input type="text" maxlength="255"  placeholder="" formControlName="address" class="form-control">
                      </div>
                      <!-- <div class="mt-1 col-sm-12 col-md-7">
                        <Label>Phone Number</Label>
                        <input type="text" maxlength="255"  placeholder="" formControlName="number" class="form-control">
                      </div> -->
                      <div class="mt-1 col-sm-12 col-md-6">
                        <Label>Phone Number</Label>
                        <input type="text" maxlength="255"  placeholder="" formControlName="phone" class="form-control">
                      </div>
                      <div class="mt-1 col-sm-12 col-md-6">
                        <Label>Email</Label>
                        <input type="text" maxlength="255"  placeholder="" formControlName="email" class="form-control">
                      </div>
                      <div class="mt-1 col-sm-12 col-md-6">
                        <Label>May we contact this referee to interview?</Label>
                      </div>

                      <div class="col-md-6">
                        <input  type="radio"   formControlName="contact" id="rd1" [value]="true"/>
                        <label for="contact"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                        <input type="radio"  formControlName="contact" id="rd2" [value]="false"/>
                        <label for="contact"> &nbsp;No</label><br/>
                      </div>



                    </div>
                  </form>
                </div>


                <button type="button" class="btn btn-success btn-circle mb-2" (click)="addRef()">
                  <i class="fa fa-plus"></i>
                </button>


              </div>

          </section>


          <section>
            <div class="mb-4">

              <div class="  text-white  ">
                <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">Safeguarding Declarations</h5>
              <span class="ml-2 text-info-custom">
                <i class="bi bi-info-circle"></i>
                Our ability to make employment decisions based on any previous criminal
                convictions is governed by the Rehabilitation of Offenders Act 1974. The position for which
                you are applying for involves contact with vulnerable groups and is therefore
                exempt from the Rehabilitation of Offenders Act 1974 and all subsequent ammendments.
                (England and Wales). For this position you are not entitled to withhold information about
                police cautions, bind-overs, or any criminal convictions that would otherwise
                have been considered "spent" under the Act.<br>

                A criminal conviction does not automatically deny a person the chance to work or
                volunteer with us, except in the case of offense against vulnerable
                groups or withheld information on any offense.<br>

                Any information you may provide will be treated with the strictest confidence.
              </span>
              </div>
              <hr class="bg-black mt-0">

              <div class="row form-group ">
                <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                  <div class="col-md-12">
                    Have you ever been convicted of any offence, or been given a caution, reprimand, or final warning (see notes above).</div>

                  <div class="col-md-12">
                    <input  (click)="setYes('offences')" type="radio" formControlName="offenses" id="offences" [value]="true"/>
                    <label for="offenses" > &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                    <input type="radio"   (click)="resetYes('offences')" formControlName="offenses" id="offences" [value]="false"/>
                    <label for="offenses"> &nbsp;No</label><br/>
                  </div>
                  <div *ngIf="offenses" class="col-sm-12">
                    <label for="dbs1"> &nbsp;Enter Description</label>&nbsp;&nbsp;&nbsp;
                    <textarea maxlength="255" placeholder="" formControlName="offensesRes" class="form-control"></textarea>
                  </div>

                </div>
                <!-- <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                  <div class="col-md-12">
                    Are you currently registered with the DBS Update Service? If yes what is your renewal date?

                   </div>

                  <div class="col-md-12">
                    <input  type="radio"  id="rd1" value="1"/>
                    <label for="rd1"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                    <input type="radio"  id="rd2" value="2"/>
                    <label for="rd2"> &nbsp;No</label><br/>
                  </div>

                </div> -->



              <span class="ml-2 text-info-custom">
                <i class="bi bi-info-circle"></i>
                If your application is successful you will be required to complete
                an enhanced DBS (Disclosure and Barring Service) check so that any background
                (including  "spent" convictions, bind-overs or cautions are disclosed.)
              </span>


                <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                  <div class="col-md-6">
                    Do you currently have a DBS Certificate to cover working with children and adults?
                   </div>
                  <div class="col-md-6">
                    <input  type="radio" (click)="setYes('dbscert')" formControlName="dbsCert" id="rd1" [value]="true"/>
                    <label for="dbsCert"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                    <input type="radio" (click)="resetYes('dbscert')" formControlName="dbsCert" id="rd2" [value]="false"/>
                    <label for="dbsCert"> &nbsp;No</label><br/>
                  </div>
                  <div *ngIf="dbscert" class="col-sm-12 col-md-12">
                    <label for="dbs1"> &nbsp;BDS Type<small>(Only pdf allowed)</small></label>&nbsp;&nbsp;&nbsp;
                      <select class="form-control" formControlName="dbsType" >
                        <option value="basic" >Basic DBS Check</option>
                        <option value="standard" >STANDARD DBS Check</option>
                        <option value="enhanced" >ENHANCED DBS Check</option>
                        <option value="enhanced1" >Enhanced with Barred List(s) DBS check</option>
                      </select>
                    <label for="dbs1"> &nbsp;Certificate Number<small>(Only pdf allowed)</small></label>&nbsp;&nbsp;&nbsp;
                    <input type="text" maxlength="255" formControlName="dbsNumber" class="form-control">
                    <!-- <input type="file" formControlName="dbsCert" placeholder="" class="form-control"> -->
                  </div>

                </div>
                <div class="mt-1 col-sm-12 col-md-12 row mt-2">
                  <div class="col-md-6">
                    Are you currently registered with the DBS Update Service?
                  </div>
                  <div class="col-md-3">
                    <input  (click)="setYes('dbs')" type="radio" formControlName="dbs" id="dbs1" [value]="true"/>
                    <label for="dbs"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                    <input type="radio" (click)="resetYes('dbs')" formControlName="dbs" id="dbs2" [value]="false"/>
                    <label for="dbs"> &nbsp;No</label><br/>
                  </div>
                  <div *ngIf="dbs" class="col-sm-12 col-md-3">
                    <label for="dbs1"> &nbsp;Renewal Date</label>&nbsp;&nbsp;&nbsp;
                    <input type="date" formControlName="dbsRenewal" placeholder="" class="form-control">
                  </div>

                </div>




              </div>
            </div>

        </section>


        <section>
          <div class="mb-4">

            <div class="   ">
              <h5 class="text-white bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">
                Safeguarding Declaration</h5>
              <span class="ml-2 text-info-custom">

                <i class="bi bi-info-circle"></i>
                We are committed to safeguarding and promotion of the welfare of vulnerable
                children and young people and expect our staff and volunteers to share this commitment.
              </span>
              <p>I declare that the information I have given on this form is complete and accurate that:</p>
              <ul>
                <li>I am not barred or disqualified from working with vulnerable groups, children or young people.</li>
                <li>I am not subject to any sanctions or conditions on my employment by the Independent
                  Safeguarding Authority, Secretary of State or other Regulatory Body
                </li>
              </ul>
            </div>
            <hr class="bg-black mt-0">


            <div class="row mb-3">
              <div class="mt-1 col-sm-12 col-md-6">
                <Label>Print name</Label>
                <input type="text" maxlength="255" formControlName="printName" placeholder="" class="form-control">
                <input type="date"  formControlName="signDate" placeholder="" class="form-control">
              </div>
              <div class="mt-1 col-sm-12 col-md-6">
                <input id="sig"  style="visibility:hidden;" type="file" (change)="onChange($event); uploadSafeguardingSig()">
              </div>
            </div>
            </div>

      </section>


      <section>

        <div class="  text-white  ">
          <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">Other Declarations</h5>
        </div>
        <!-- <hr class="bg-black mt-0"> -->

        <div class="row form-group">

          <div class="col-md-12">
              Do you hold a current driving licence?
            <div class="col-md-12">
              <input  type="radio" formControlName="licence" name="licence" id="licence" [value]="true"/>
              <label for="licence"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
              <input type="radio" formControlName="licence" name="licence" id="licence" [value]="false"/>
              <label for="licence"> &nbsp;No</label><br/>
            </div>

          </div>

            <div class="col-md-12">
              Do you own  car?
            <div class="col-md-12">
              <input  type="radio" formControlName="car" name="car" id="car"  [value]="true"/>
              <label for="car"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
              <input type="radio" formControlName="car" name="car" id="car" [value]="false"/>
              <label for="car"> &nbsp;No</label><br/>
            </div>

          </div>


          <div class="col-md-12">
              Please confirm whether this will be your only employment.
            <div class="col-md-12">
              <input  type="radio" formControlName="onlyEmployment" id="onlyEmployment" [value]="true"/>
              <label for="onlyEmployment"> &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
              <input type="radio" formControlName="onlyEmployment" id="onlyEmployment" [value]="false"/>
              <label for="onlyEmployment"> &nbsp;No</label><br/>
            </div>

          </div>

          <div class="mt-1 col-sm-12 col-md-12 row mt-2">
            <div class=" row col-md-12">
              Have you ever been the subject of a formal safeguarding disciplinary sanctions
              or are you in the process of on-going safeguarding disciplinary proceedings in your current employment.
              <div class="col-md-6">
                <input  type="radio" (click)="setYes('sanctions')" formControlName="sanctions" [value]="true"/>
                <label for="sanctions" > &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                <input type="radio" (click)="resetYes('sanctions')" formControlName="sanctions" [value]="false"/>
                <label for="sanctions"> &nbsp;No</label><br/>
              </div>
              <div *ngIf="sanctions" class="mt-1 col-sm-12 col-md-12">
                <Label>Give details</Label>
                <textarea maxlength="255" type="text"  formControlName="sanctionsRes" placeholder="" class="form-control"></textarea>
              </div>
            </div>
          </div>
          <div class="mt-1 col-sm-12 col-md-12 row mt-2">
            <div class=" row col-md-12">
              Have you ever been dismissed from any previous employment for reason other than redudancy?
              If yes please indicate which employment and specify the reasons for your dismissal in a sealed
              envelope marked "Confidential -Disclosure"
              <div class="col-md-6">
                <input  (click)="setYes('confidential')" type="radio" formControlName="confidential" [value]="true"/>
                <label for="confidential" > &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                <input type="radio" (click)="resetYes('confidential')" formControlName="confidential" [value]="false"/>
                <label for="confidential2"> &nbsp;No</label><br/>
              </div>
              <div *ngIf="confidential" class="mt-1 col-sm-12 col-md-12">
                <Label>Upload</Label>
                <textarea maxlength="255" type="text" formControlName="dismissedRes"  placeholder="" class="form-control"></textarea>
              </div>
            </div>
          </div>
          <div class="mt-1 col-sm-12 col-md-12 row mt-2">
            <div class=" row col-md-12">
              Are you related to anyone within this orgnisation?

              <div class="col-md-6">
                <input  (click)="setYes('related')" type="radio" formControlName="related" id="rd1" [value]="true"/>
                <label for="rd1" > &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
                <input type="radio" (click)="resetYes('related')" formControlName="related" id="rd2" [value]="false"/>
                <label for="rd2"> &nbsp;No</label><br/>
              </div>
              <div *ngIf="related" class="mt-1 col-sm-12 col-md-12">
                <Label>Give details</Label>
                <textarea maxlength="1000" rows="9" type="text" formControlName="relatedRes" placeholder="" class="form-control"></textarea>
                <small class="">Max 1000 characters</small>
              </div>
            </div>
          </div>
        </div>

      </section>



      <section>

      <div class="  text-white  ">
        <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">General Declaration</h5>
      </div>
      <!-- <hr class="bg-black mt-0"> -->

      <div class="row mb-3 form-group">
          <div class="col-md-12">
            My signature confirms that all the information given on this application form is true and complete.
            I understand that any falsification or deliberate omissions may disqualify my application, lead to withdrawal of any
             offer of appointment, or lead to my dismissal at any time in the future. In some circumstances I understand that this
              may lead to criminal prosecution or reporting to the relevant authorities.
              <br><br>I understand that any offer of employment is conditional subject to safer recruitment checks outlined in our Safer Recruitment Policy.

        </div>

        <div class="row my-3"></div>
        <div class="col-md-12">
            <label>Full name</label><br>
            <input  type="text" formControlName="genFullname" maxlength="255" class="form-control"/>
        </div>
        <div class="mt-1 col-sm-12 col-md-12 row mt-2">
          <div class="col-md-12">
            <!-- <label for="files" class="btn btn-success">Upload Signature</label> -->
            <!-- <span *ngIf="uploaded" class="text-success bold mx-2" >Uploaded</span> -->
            <!-- <a *ngIf="applicationData?.signed" [href]="applicationData?.signed" class="bold mx-2" >View File</a> -->

            <input id="files"  style="visibility:hidden;" type="file" (change)="onChange($event); uploadSignature()">


            <input  type="date" formControlName="genDate"/>
        </div>
        </div>
      </div>

</section>

<section>

    <div class="  text-white  ">
      <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">      Availability      </h5>
          <i class="bi bi-info-circle"></i>
    <span class="ml-2 text-info-custom">
    </span>
    </div>
    <!-- <hr class="bg-black mt-0"> -->

    <div class="row form-group mb-2">
        <div class="col-md-6">
          Are there any dates when you are not available for interview?

        </div>
        <div class="col-md-6">
          <input  (click)="setYes('interview')" type="radio" formControlName="availability" id="interview" [value]="true"/>
          <label for="interview" > &nbsp;Yes</label>&nbsp;&nbsp;&nbsp;
          <input type="radio" (click)="resetYes('interview')" formControlName="availability" id="interview" [value]="false"/>
          <label for="interview"> &nbsp;No</label><br/>
        </div>
        <div *ngIf="interview" class="mt-1 col-sm-12 col-md-12">
          <Label>Give Dates</Label>
          <textarea maxlength="255" rows="9" type="text" formControlName="unavailable" placeholder="" class="form-control"></textarea>
        </div>

    </div>


</section>




<section>

    <div class="  text-white  ">
      <h5 class=" bg-main border radius-3  py-2 px-4 w-100" style="border-radius: 5px;">Data Protection</h5>
          <i class="bi bi-info-circle"></i>
    <span class="ml-2 text-info-custom">

    </span>
    </div>
    <!-- <hr class="bg-black mt-0"> -->

    <div class="row form-group">
          The information that you provide on this form will be used to process your application
           for employment purposes only. We process this information in line with our job applicant
           privacy notice. By Signing this application form under general declaration, you agree to the
            processing of your personal data, in accordance with our registration with the Information Commissioner’s Office.
      </div>



</section>
        </div>
      </div>
      <div align="right" class="mt-3">
        <button type="submit" *ngIf="!applicationData?.submitted" class="btn hideMePrint btn-danger btn-sm mr-3" >
          Save
        </button>
        <button type="submit" *ngIf="applicationData?.submitted" class="btn hideMePrint btn-success btn-sm mr-3" >
          Update
        </button>
        <button *ngIf="!applicationData?.submitted"  type="button" (click)="addWorkerApplication(addApplicationForm, 'submit', true)" class="btn hideMePrint btn-sm btn-success">
          Submit
        </button>
        <button type="button" class="btn hideMePrint btn-success btn-sm mr-3" onclick="window.print();  ">
          Download
        </button>
      </div>
    </form>
  </div>
  <div *ngIf="!applicationLoaded">
    <div class="spinner-grow mr-3" role="status" style="width: 1rem;height: 1rem; margin-left:50%; margin-top: 60px">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>


<script>
  function maxLength(el) {
    if (!('maxLength' in el)) {
        var max = el.attributes.maxLength.value;
        el.onkeypress = function () {
            if (this.value.length >= max) return false;
        };
    }
  }

  maxLength(document.getElementById("text"));
</script>
