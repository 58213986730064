<main class="mt-5">

  <!-- Header part
<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-sm-12 col-md-4 text-left">
      <h3>Training And Qualifications</h3>
    </div>
    <div class="col-12 col-sm-6 col-md-4 text-center">
      <div class="input-group">
        <input
          type="text"
          class="form-control col-8 border-right-0"
          placeholder="Search Training"
          aria-describedby="inputGroupPrepend2"
          required
        />
        <div class="input-group-prepend">
          <span class="input-group-text bg-transparent border-left-0" id="inputGroupPrepend2">
              <i class="fa fa-search"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4 text-md-right text-sm-left mt-2 mt-md-0">
        <button class="btn btn-success" (click)="openModal(add)"  *ngIf="userType == 'admin'">
            <i class="fa fa-plus-circle"></i>&nbsp;&nbsp;Add New
        </button>
    </div>
  </div>
</div> -->



  <!-- content -->
  <section class="content mt-4">
    <div class="container-fluid mt-4">
      <div class="row">
        <div class="col-3 ml-3 pointer text-center" (click)="selectTab('training')"
          [ngClass]="{'bg-custom-danger': selectedTab == 'training','bg-custom-unselected': selectedTab != 'training'}" style="border-top-left-radius: 5px;">
          Training
        </div>
        <div class="col-3  pointer text-center" (click)="selectTab('compliance')"
          [ngClass]="{'bg-custom-danger': selectedTab == 'compliance','bg-custom-unselected': selectedTab != 'compliance'}">
          Compliance
        </div>
      </div>
      <div class=" mt-1">
          <div *ngIf="selectedTab == 'training'">
            <app-training></app-training>
          </div>
          <div *ngIf="selectedTab == 'compliance'">
            <app-compliances></app-compliances>
          </div>
          <!-- <div *ngIf="selectedTab == 'trainers'">
            <app-trainers-list></app-trainers-list>
          </div> -->
      </div>
    </div>
  </section>




</main>


