import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders,
  HttpClient,
  HttpBackend
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StorageService } from '../services/storage.service';

@Injectable()
export class MyInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private _http: HttpClient,
    private toast: ToastrService,
    private storageService: StorageService,
    handler: HttpBackend
  ) {
    this._http = new HttpClient(handler);
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const authToken = this.authService.getToken();

    if (authToken) {
      req = req.clone({
        setHeaders: {
           Authorization: 'Bearer ' + authToken,
         },
      });
      return next.handle(req).pipe(
        catchError((error: HttpErrorResponse) => {
          console.log(error);

          if (error.status == 401) {
            this.refreshAccessToken();
          }
          if (error.status == 400) {
            this.toast.clear();
            this.toast.error('Bad request');
          }
          if(error.status == 409){
            this.toast.clear();
            this.toast.warning(error.error.message);
          }
          if(error.status == 0){
            this.toast.clear();
            this.toast.error('Network Connection Failure');
          }
          else if(error.status == 404){
            this.toast.clear();
            if(error.error.message!=null&&error.error.message!=""){
              this.toast.warning(error.error.message);
            }else{
              this.toast.warning('This service is currently unavailable.')
            }
          }else if(error.status == 403){
            this.toast.clear();
            this.toast.error('You are forbidden from accessing this resource')
          }else if(error.status == 500){
            this.toast.clear();
            if(error.error.message!=null&&error.error.message!=""){
              this.toast.warning(error.error.message);
            }else{
              this.toast.error('Internal server error, please try later')
            }
          }

          return throwError(error);
        })
      );
    } else {
      return next.handle(req);
    }
  }

  refreshAccessToken() {
      this.authService.refreshToken(this.storageService.decrypt(localStorage.getItem('refresh_token')))
      .subscribe(data =>{
          localStorage.setItem('access_token', this.storageService.encrypt(data.access_token));
          localStorage.setItem('refresh_token', this.storageService.encrypt(data.refresh_token));
        },
        err =>{
          this.toast.clear();
          let toststatus = this.toast.findDuplicate('Session Expired.','Session Expired.',true,true);
          if(toststatus?.toastRef?.componentInstance?.duplicatesCount==undefined)
          this.toast.warning('Session Expired.');
          this.authService.doLogout();
        }
      );
  }
}
