import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import { ShiftService } from './../../../../shared/services/shift.service';
import { Component, Input, OnInit } from '@angular/core';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TransportService } from 'src/app/shared/services/transport.service';
import {MatSliderModule} from '@angular/material/slider';

@Component({
  selector: 'app-awaiting-transport',
  templateUrl: './awaiting-transport.component.html',
  styleUrls: ['./awaiting-transport.component.css'],
})
export class AwaitingTransportComponent implements OnInit {
  @Input('agencyFilter') agencyFilter = '';
  @Input('clientFilter') clientFilter = '';
  @Input('startDateFilter') startDateFilter = '';
  @Input('endDateFilter') endDateFilter = '';
  @Input('directorateFilter') directorateFilter = '';

  shifts: any= [];
  shiftInfo: any;
  selectedShift: number;


  userType: string;

  page = 1;
  rating = 0;
  pageSize = 20;
  totalItems = 0;
  showin = 0;
  first: boolean;
  last: boolean;

  showNotes: boolean;
  selectedTransport: any;

  constructor(
    private transportService: TransportService,
    private toast: ToastrService,
    private router: Router,
    private authService: AuthService,
    private modalService: NgbModal,
    private storageService: StorageService
  ) {
    this.userType = authService.getUserType();
   }

  ngOnInit(): void {
    this.getShifts();

    if(this.userType == 'admin'){
      this.showNotes = true;
    }
  }

  handlePageChange(event) {

    if(event == 'next'){
      this.page += 1;
    }
    else if(event == 'prev' && this.page != 1){
      this.page -= 1;
    }

    this.getShifts();
  }


  formatLabel(value: number): string {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    this.rating=value;
    console.log(this.rating);
    return `${value}`;
  }

  loger(){
    console.log(this.rating);
  }


  getShifts(){
    this.transportService.getPaginatedTransportsByStatus(this.page - 1, this.pageSize, 'AWAITING_AUTHORIZATION').subscribe(
      data => {
        // console.table(data);

        this.totalItems = data.totalElements;
        this.first = data.first;
        this.last = data.last;

        if(this.page * this.pageSize > data.totalElements){
          this.showin = data.totalElements;
        }
        else{
          this.showin = this.page * this.pageSize;
        }

        this.shifts = data.content;
      },
      err => {




      }
    );
  }

  viewDetails(ref, modal){
    if(ref){
      this.shifts.forEach(r => {
        if(r.id == ref){
          this.shiftInfo = r;
        }
      });

      // check show notes issue
      if(this.userType != 'admin'){
        this.userType == 'agency' ? this.showNotes = this.shiftInfo.showNoteToAgency : this.userType == 'client' ? this.showNotes = this.shiftInfo.showNoteToFw : this.showNotes = false;
      }

      this.selectedShift = ref;
      this.openBigModal(modal);
    }

    // console.table(this.shiftInfo);
  }

  editDetails(ref){
    this.router.navigate([`/${this.userType}/shifts/edit/${ref}`]);
    this.modalService.dismissAll();
  }

  loadAgency(ref, modal){
    if(ref){
      this.selectedShift = ref;

      this.openModal(modal);
    }
  }



  openModal(modal){
    this.modalService.open(modal, {centered: true});
  }

  openViewModal(modal, selectedTransport){
    this.selectedTransport = selectedTransport;
    this.modalService.open(modal, {centered: true});
  }

  openBigModal(modal){
    this.modalService.open(modal, {centered: true, size: 'lg'});
  }

}
