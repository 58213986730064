<table class="table table-striped mb-0 styled-table table-responsive-md text-center">
  <thead class="">
      <tr class="text-center">
          <th>Shift Id</th>
          <th>Worker Name</th>
          <th>Shift Date</th>
          <th>Time</th>
          <th>Assignment<br>Code</th>
          <th *ngIf="userType == 'admin'">Directorate</th>
          <th *ngIf="userType != 'client'">Client Name</th>
          <th *ngIf="userType == 'client'">Directorate</th>
          <th>Actions</th>
      </tr>
  </thead>
  <tbody>
      <tr *ngFor="let s of shifts | shiftsFilter: agencyFilter :clientFilter :startDateFilter :endDateFilter :directorateFilter;">
          <td>{{s.id}}</td>
          <td>{{s.worker}}</td>
          <td>{{s.shiftDate}}</td>
          <td>{{s.shiftStartTime}} - {{s.shiftEndTime}}</td>
          <td>{{s.assignmentCode}}</td>
          <th *ngIf="userType == 'admin'">{{s.directorate}}</th>
          <td *ngIf="userType == 'client'">{{s.directorate}}</td>
          <td *ngIf="userType != 'client'">{{s.client}}</td>
          <td>
              <span class="mr-3 text-primary-custom pointer" (click)="viewDetails(s.id, infoNew)">
                  <i class="fa fa-eye" title="view"></i>
              </span>
              <!-- <span class="mr-3 text-success pointer" *ngIf="userType == 'client'" (click)="editDetails(s.id)">
                <i class="fa fa-pencil-alt" title="edit"></i>
              </span> -->
          </td>
      </tr>
  </tbody>
</table>
<section class="pagination-container" *ngIf="shifts">
  <div class="container-fluid">
    <div class="row m-0">
      <div class="col-12 col-sm-12 col-md-5 text-left acontent-center">
        Showing {{ (page - 1) * pageSize + 1 }} - {{ showin }} of {{ totalItems }}
      </div>
      <div class="col-12 col-sm-6 col-md-4 text-right">
        <button class="btn text-white" (click)="handlePageChange('prev')" [disabled]="first">
          <i class="fa fa-caret-up"></i>&nbsp;&nbsp;Previous Page
        </button>
      </div>
      <div class="col-12 col-sm-6 col-md-3 text-right pr-0">
        <button class="btn text-white" (click)="handlePageChange('next')" [disabled]="last">
          Next Page&nbsp;&nbsp;<i class="fa fa-caret-down"></i>
        </button>
      </div>
    </div>
  </div>
</section>

<!-- Modals -->
<ng-template class="modal fade" #infoNew let-modal>
<div class="">
    <div class="modal-header bg-main text-white">
        <h5 class="modal-title text-center w-100">Queried Shift</h5>
        <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </span>
    </div>
    <div class="modal-body">
        <div class="bg-modal card-body">
            <div class="container">
              <section>
                <table class="table table-striped mb-0 styled-table table-responsive-md text-center">
                  <thead class="">
                    <tr class="text-center">
                      <th>Date</th>
                      <th>End Date</th>
                      <th>Flexi-Worker</th>
                      <th>Client</th>
                      <th>Directorate</th>
                      <th>Assignment<br>Code</th>
                      <th>Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{shiftInfo.shiftDate}}</td>
                      <td>{{shiftInfo.shiftEndDate}}</td>
                      <td>{{shiftInfo.worker}} </td>
                      <td>{{shiftInfo.client}}</td>
                      <td>{{shiftInfo.directorate}}</td>
                      <td>{{shiftInfo.assignmentCode}}</td>
                      <td>{{shiftInfo.shiftStartTime}} - {{shiftInfo.shiftEndTime}}</td>
                    </tr>
                  </tbody>
                </table>


              </section>
              <hr>
              <section>
                <div class="text-center mt-3">
                  <strong>{{shiftInfo.worker}}:</strong>&nbsp;&nbsp;<span class="text-muted text-xs">on {{shiftInfo.queriedDate}}</span><br>
                  <p>
                    "{{shiftInfo.queriedReason}}"
                  </p>
                </div>
                <section *ngIf="userType == 'client'" class="row">
                  <div class="col-9 col-md-8" align="right">
                    <!--textarea name="" class="form-control col-12 col-md-7" placeholder="Reply... (optional)" cols="30" rows="5"></textarea-->
                  </div>
                  <div class="col-3 col-md-4" align="left">
                    <!--button class="btn btn-sm btn-success">
                      Send &nbsp;<i class="bi bi-cursor"></i>
                    </button-->
                  </div>
                </section>
              </section>
            </div>
        </div>
        <div class="text-center mt-3">
          <a class="btn btn-sm btn-success text-white" *ngIf="userType != 'admin'&&'UPDATE_SHIFT'|permission"  routerLink="/{{this.userType}}/shifts/edit/{{shiftInfo.id}}"   (click)="modal.dismiss()">
            <i class="fa fa-pencil-alt" title="edit"></i>&nbsp;&nbsp;Amend
          </a>
        </div>
    </div>
</div>
</ng-template>

<ng-template class="modal fade" #ammend let-modal>
<div class="">
    <div class="modal-header bg-main text-white">
        <h5 class="modal-title text-center w-100">Shift ADJUSTMENT</h5>
        <span type="button" class="btn close text-white" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </span>
    </div>
    <div class="modal-body">
        <div class="bg-modal card-body">
            <div class="container">
              <section>
                <div class="row">
                  <div class="col">
                  </div>
                  <div class="col">
                    <small>Booked</small>
                  </div>
                  <div class="col">
                    <small>ACTUAL</small>
                  </div>
                </div>
                <!-- //////////////////////////// -->
                <div class="row">
                  <div class="col">
                    <small>Start Time<br><br></small>
                  </div>
                  <div class="col">
                    <input type="time">
                  </div>
                  <div class="col">
                    <input type="time">
                  </div>
                </div>
                <!-- //////////////////////////// -->
                <div class="row">
                  <div class="col">
                    <small>End Time<br><br></small>
                  </div>
                  <div class="col">
                    <input type="time">
                  </div>
                  <div class="col">
                    <input type="time">
                  </div>
                </div>
                <!-- //////////////////////////// -->
                <div class="row">
                  <div class="col">
                    <small>Break Time<br><br></small>
                  </div>
                  <div class="col">
                    <input type="time">
                  </div>
                  <div class="col">
                    <input type="time">
                  </div>
                </div>
                <!-- //////////////////////////// -->
                <div class="row">
                  <div class="col">
                    <small>DID NOT ATTEND<br><br></small>
                  </div>
                  <div class="col">
                    <input type="checkbox">
                  </div>
                  <div class="col">
                  </div>
                </div>
                <!-- //////////////////////////// -->
              </section>
            </div>
        </div>
        <div class="text-center mt-3">
          <button class="btn btn-sm btn-danger mr-3" *ngIf="userType == 'client'" (click)="modal.dismiss()">
            <i class="bi bi-x-lg"></i>&nbsp;&nbsp;Cancel
          </button>
          <button class="btn btn-sm btn-success" *ngIf="userType == 'client'&&'UPDATE_SHIFT'|permission" >
            <i class="fa fa-pencil-alt" title="edit"></i>&nbsp;&nbsp;Save
          </button>
        </div>
    </div>
</div>
</ng-template>
