import { W } from '@angular/cdk/keycodes';
import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AgencyService } from 'src/app/shared/services/agency.service';
import { AssetService } from 'src/app/shared/services/asset.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { JobService } from 'src/app/shared/services/job.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { TransportService } from 'src/app/shared/services/transport.service';
import { WorkerService } from 'src/app/shared/services/worker.service';

@Component({
  selector: 'transport-info-modal',
  templateUrl: './transport-info-modal.html',
  styleUrls: ['./transport-info-modal.css']
})
export class TransportInfoModalComponent implements OnInit {
  @Input('selectedJob') selectedJob : any;
  @Input('teamLeaderView') teamLeaderView = false ;
  @Output() updated = new EventEmitter<boolean>();

  workers:any;
  vehicles: any;
  bookedWorkers=[];
  authorizeForm: FormGroup;
  userType: any;
  riskDoc: any;
  addForm: FormGroup;
  extremeRag: Array<String> = ['S','H','M'];
  uploadDoc: boolean = false;
  agencyId: any;
viewFullDetails: boolean = false;

  constructor(
    private storageService: StorageService,
    private agencyService: AgencyService,
    private assetService: AssetService,
    private transportService: TransportService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private authService: AuthService,
    private workerService: WorkerService,
    private jobsService: JobService
   ) {
    this.userType = authService.getUserType();
    this.agencyId =this.storageService.decrypt(localStorage.getItem('agentId'));
   }

  ngOnInit(): void {


    this.viewFullDetails = this.selectedJob.transportStatus == "NEW";

    if(this.userType!='client')this.getVehicles();
    if(this.userType=='agency'&&this.selectedJob.transportStatus!='NEW')this.getWorkers();
    this.pushBookedWorkers();
    console.log(this.selectedJob);
    this.authorizeForm = this.fb.group({
      transportId: [this.selectedJob.id, Validators.required],
      start: ['', Validators.required],
      end: ['', Validators.required],
      breakTime: ['', Validators.required],
    });

    this.initForm();
    this.addForm.disable();

  }


  pushBookedWorkers(){
    this.transportService.findById(this.selectedJob.id).subscribe(
      data => {
        this.bookedWorkers = [];
        this.selectedJob = data;
        // Create list of all bookings
        this.selectedJob.transportWorkerSpecList.forEach(ws => {
          ws.bookings?.forEach(bks => {
            if(!['NEW','APPLIED', 'CANCELLED', 'EXPIRED'].includes(bks.shiftStatus))
              this.bookedWorkers.push(bks);
          });
        });

        // Get total valid bookings

        this.selectedJob.transportWorkerSpecList.forEach(ws => {
          let count = 0;
          ws.bookings?.forEach(bks => {
            if(!['NEW','APPLIED', 'CANCELLED', 'EXPIRED'].includes(bks.shiftStatus))
              count++;
          });
          ws.bookingsCount = count;
        });
      }
    );
  }


  uploadCompliance(riskDoc){
    console.log(this.riskDoc);
    if(this.riskDoc.size>=1048576){
      this.toast.warning("Maximum upload file size is 1mb")
    }
    else {
    this.transportService.uploadRisk(this.riskDoc,this.selectedJob.id).subscribe(
      event => {
        if(event instanceof HttpResponse) {
        };
        this.toast.info("Sucessfully uploaded.");
      },
    );}
  }

  toggleTrue(val){
    this.uploadDoc = true;
  }

  toggleView(){
    this.viewFullDetails = !this.viewFullDetails;
  }

  getBookingsArrayzz(r){
    let count = 0;
    r.bookings?.forEach(bks => {
      if(!['NEW','APPLIED', 'CANCELLED', 'EXPIRED'].includes(bks.shiftStatus))
        count++;
    });
    r.bookingsCount = count;

    if(r.numberOfStaff < r.bookingsCount){
      return 0;
    }else{
      return r.numberOfStaff-r.bookingsCount;
    }
  }


  onChange(event) {
    this.riskDoc = event.target.files[0];
    this.uploadCompliance(this.riskDoc);
  }

  openDoc(selectedJo){
    this.workerService.openDoc(selectedJo);
  }


  getVehicles(){

    this.assetService.getAgencyAssets(this.storageService.decrypt(localStorage.getItem('agentId'))??this.selectedJob.agencyId,'VEHICLE',"", 0, 1000).subscribe(
      data => {
        let array = data.content;
        array.sort(function(a, b){
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })
        this.vehicles = array;
      }
    );
  }


  getWorkers(){
      this.agencyService.getLegibleTransportWorkers(this.selectedJob.id).subscribe(
        data => {
          this.workers = data;
        }
      );
  }

  getSpecWorkers(id){
    let woks = this.workers?.filter(wk=>wk.workerSpecId == id);
    return woks==undefined?[]:woks[0]?.workers;
  }


  bookWorker(specId: number,ele: any) {
    this.transportService.bookWorker(ele.target.value, specId).subscribe(
      data => {
        this.toast.info("Booked worker");
        this.pushBookedWorkers();
      }
    );
  }


commitJob() {
    this.transportService.commitJob(this.selectedJob.id).subscribe(
      data => {
        this.toast.info("Job commited and ready for execution");
        this.updated.emit(true);
        this.modalService.dismissAll();
        this.pushBookedWorkers();
      }
    );
  }

  
  

  pickVehicle( ele: any) {
    console.log(ele.target.value);
    this.transportService.pickVehicle(  ele.target.value, this.selectedJob.id ).subscribe(
      data => {
        this.toast.info("Vehicle set");
        this.selectedJob = data;
        this.pushBookedWorkers();
      }
    );
  }

  pickDriver(ele: any) {
    this.transportService.pickDriver(ele.target.value, this.selectedJob.id ).subscribe(
      data => {
        this.toast.info("Driver saved");
        this.selectedJob = data;
        this.pushBookedWorkers();
      }
    );
  }

  pickTeamLeader(ele: any) {
    this.transportService.pickTeamLeader(ele.target.value, this.selectedJob.id ).subscribe(
      data => {
        this.toast.info("Team leader selected");
        this.selectedJob = data;
        this.pushBookedWorkers();
      }
    );
  }


  authorizeJob(form: FormGroup){
    if(form.valid){
      this.transportService.authorizeTransport(form.value).subscribe(
        resp => {
          this.modalService.dismissAll();
          this.toast.success('Job authorized successfuly');
          this.updated.emit(true);
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }





  approveJobApplication(id){
    this.jobsService.approveAppliedJob(id).subscribe(data => {
      this.toast.success("Approved");
      this.pushBookedWorkers();
     });
  }

  rejectJobApplication(id){
    this.jobsService.rejectAppliedJob(id).subscribe(data => {this.toast.success("Rejected");
    this.pushBookedWorkers();
    });
  }

  removeBooking(id){
    this.jobsService.rejectAppliedJob(id).subscribe(data => {
      this.toast.success("Rejected");
      this.pushBookedWorkers();
     });
  }

  openModal(modal) {
    this.modalService.dismissAll();
    this.modalService.open(modal, {centered: true, size: 'lg'})
  }

  closeModals(){
    this.modalService.dismissAll();
  };

  initForm(){
    this.addForm = this.fb.group({
      transportLegibleAgencyIds : [""],
      pickupDirectorateId : [],
      id : [],
      version: [],
      agencyId : [""],
      clientId : [""],
      dateTimeRequired : ["", Validators.required],
      destination : ["", Validators.required],
      destinationContactNumber : [""],
      destinationPostCode : ["", Validators.required],
      escortServiceRisk : [],
      passengerAdditionalRisks : [],
      wardEscort   : [""],
      areStaffEscortingPatient   : ["",Validators.required],
      patientMedication   : ["",Validators.required],
      pmeds   : [""],
      disclaimer   : ["", Validators.required],
      isPassengerAwareOfTransport : ['', Validators.required],
      passengerGender : ["", Validators.required],
      physicalAggression : [],
      verballyAggressive : [],
      assaultStaffDesc : [""],
      selfNeglect : [""],
      selfNeglectDesc : [""],
      selfHarmDesc : [""],
      physicalAggressionDesc : [""],
      verballyAggressiveDesc : [""],
      absconsionRiskDesc : [""],
      genderIssuesDesc : [""],
      racialIssuesDesc : [""],
      sexuallyInappropriateDesc : [""],
      mentalHealthStatus : ["", Validators.required],
      specialRequests : [],
      patientName : ["", Validators.required],
      sexuallyInappropriate : [],
      selfHarm : [],
      reasonsForRestrains : [],
      reasonForTransport : ["",Validators.required],
      passengerRequiresRestraints : ["", Validators.required],
      pickupLocationContactNumber : [],
      pickupPostCode : ["", Validators.required],
      assaultStaff : [],
      absconsionRisk: [],
      otherRisks: [],
      otherMobilityIssues: [],
      mobilityIssues: [],
      transportWorkerSpecList : this.fb.array([
        this.fb.group({
          assignmentCode : ["1", Validators.required],
          gender : ["NO_PREFERENCE"],
          numberOfStaff : ["0",Validators.required],
       }),
        this.fb.group({
          assignmentCode : ["2", Validators.required],
          gender : ["NO_PREFERENCE"],
          numberOfStaff : ["0",Validators.required],
       }),
        this.fb.group({
          assignmentCode : ["32", Validators.required],
          gender : ["NO_PREFERENCE"],
          numberOfStaff : ["0",Validators.required],
       }),
     ]),
    mha:[],
    pcaddress:["",Validators.required],
    pcemail:["",[Validators.required,, Validators.email]],
    pcbusiness:[],
    pward:[],
    pname:["",Validators.required],
    pdob:["",Validators.required],
    walk:["",Validators.required],
    walkInfo:[""],
    nhs:[],
    diagnosis:[],
    dname:[""],
    dbusiness:[],
    dward:[],
    dcontact:[],
    demail:[, Validators.email],
    bpostCode:[],
    genderIssues:[],
    racialIssues:[],
    medication:[],
    physicalHealth:[],
    rapidTranq:[],
    infectionControl:[],
    covid:[],
    offerFood:[],
    allergies:[],
    submittedBy:[],
    semail:["",Validators.required],
    sphone:[],
    porderNum:[],
    sbsCode:[],
    bname:[],
    baddress:[],
    binvoice:[],
    bphone:[],
    bemail:[],
    authority:[],
    });

    this.formFromJson();
  }


  formFromJson(){
    if (this.selectedJob != null) {
      this.addForm.patchValue(this.selectedJob);
   }
  }

  acceptJob(){

    if(this.selectedJob){
      this.jobsService.acceptJob(this.selectedJob.id, this.agencyId).subscribe(
        resp => {
          this.updated.emit(true);
          this.modalService.dismissAll();
          this.toast.success('Job Accepted Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }
  }

  onSubmit() {
    if (this.addForm.valid) {
      // Here, the form values will automatically populate the form controls
      console.log('Form Submitted', this.addForm.value)
    } else {
      console.log('Form is invalid');
    }
  }
}
