<table class="table table-striped mb-0 styled-table table-responsive-md text-center">
  <thead class="">
      <tr class="text-center">
          <th>Job Ref</th>
          <th>Client</th>
          <!-- <th>Date Booked</th> -->
          <th>Date Required</th>
          <th>Job Card</th>
      </tr>
  </thead>
  <tbody>
      <tr *ngFor="let s of jobs">
          <td>{{s.refPrefix??''+s.id}}</td>
          <td>{{s.clientName}}</td>
          <!-- <td>{{s.dateTimeBooked}}</td> -->
          <td>{{s.dateTimeRequired | date:'dd/MM/yyyy'}}</td>
          <td>
              <span class="m-1 text-primary-custom pointer" (click)="viewDetails(s, jobInfo)">
                  <i class="fa fa-eye" title="view"></i>
              </span>
              <span class="m-1 text-success pointer" (click)="viewDetails(s, editJobModal)">
                <i class="fa fa-edit" title="edit"></i>
              </span>
              <span class="m-1 text-danger-custom pointer" (click)="viewDetails(s, deleteModal)">
                <i class="fa fa-trash-alt" title="delete"></i>
            </span>
          </td>
      </tr>
  </tbody>
</table>
<section class="pagination-container" *ngIf="jobs">
  <div class="container-fluid">
    <div class="row m-0">
      <div class="col-12 col-sm-12 col-md-5 text-left acontent-center">
        Showing {{ (page - 1) * pageSize + 1 }} - {{ showin }} of {{ totalItems }}
      </div>
      <div class="col-12 col-sm-6 col-md-4 text-right">
        <button class="btn text-white" (click)="handlePageChange('prev')" [disabled]="first">
          <i class="fa fa-caret-up"></i>&nbsp;&nbsp;Previous Page
        </button>
      </div>
      <div class="col-12 col-sm-6 col-md-3 text-right pr-0">
        <button class="btn text-white" (click)="handlePageChange('next')" [disabled]="last">
          Next Page&nbsp;&nbsp;<i class="fa fa-caret-down"></i>
        </button>
      </div>
    </div>
  </div>
</section>


<ng-template class="modal fade" #jobInfo let-modal>
  <div class="card">
    <transport-info-modal
    [selectedJob]  =  "selectedJob"
    (updated)="refreshPage($event)"
    ></transport-info-modal>
  </div>
</ng-template>

<ng-template class="modal fade" #editJobModal let-modal>
  <div class="card">
    <app-create-transport-job
    [selectedJob]  =  "selectedJob"
    (updated)="refreshPage($event)"
    ></app-create-transport-job>
  </div>
</ng-template>



  <ng-template class="modal fade" #deleteModal let-modal>
    <div class="card">
      <app-delete-transport-modal
      [selectedJob]  =  "selectedJob"
      (updated)="refreshPage($event)"
      ></app-delete-transport-modal>
    </div>
  </ng-template>

