import { Component, OnInit } from '@angular/core';
import { AgencyService } from './../../../shared/services/agency.service';
import { ClientService } from 'src/app/shared/services/client.service';
import { AuthService } from './../../../shared/services/auth.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { IfStmt } from '@angular/compiler';
import { StatisticsService } from 'src/app/shared/services/statistics.service';




@Component({
  selector: 'app-shifts-list',
  templateUrl: './shifts-list.component.html',
  styleUrls: ['./shifts-list.component.css']
})
export class ShiftsListComponent implements OnInit {

  selectedTab: string = 'new';
  agencyFilter  : string= "";
  clientFilter  : string= "";
  startDateFilter: string= "";
  endDateFilter: string= "";
  directorateFilter: string= "";
  userType: string;

  clients: any;
  allClients: any;
  userList: any;
  agencies: any;

  page: number =1;
  pageSize = 25;
  totalItems = 0;
  showin = 0;
  first: boolean;
  last: boolean;
  shiftsSummary?: any;


  constructor(
    private clientService: ClientService,
    private agencyService: AgencyService,
    private authService: AuthService,
    private storageService: StorageService,
    private statisticsService: StatisticsService,
    private toast: ToastrService,
    ) {
      this.userType = authService.getUserType();

   }

  ngOnInit(): void {
    this.getAgencies();
    this.getClients();
    this.getStats();
  }

  selectTab(ref) {
    if(ref){
      this.selectedTab = ref;
    }
  }

  getStats(){
      this.statisticsService.shiftDashboardStatistics().subscribe(
        data => {
          this.shiftsSummary = data;
        }
      );
  }


  getAgencies(){
    if(this.userType == 'admin'){
      this.agencyService.getPaginatedAgencies(this.page - 1, this.pageSize).subscribe(
        data => {
          console.table(data.content);
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }
          let array = data.content;
          array.sort(function(a, b){
              if(a.name < b.name) { return -1; }
              if(a.name > b.name) { return 1; }
              return 0;
          })
          this.agencies = array;
        },
        err => {

          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }

        }
      );
    }
    else{
      this.clientService.getPaginatedClientAgencies(this.page - 1, this.pageSize, this.storageService.decrypt(localStorage.getItem('clientId'))).subscribe(
        data => {
          // console.table(data.content);
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }

          this.agencies = data.content;
        },
        err => {

          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }

        }
      );
    }
  }


  getClients(){
    if(this.userType == 'agency'){
      this.agencyService.getPaginatedAgencyClients(this.page - 1, this.pageSize, this.storageService.decrypt(localStorage.getItem('agentId'))).subscribe(
        data => {
          console.table(data.content);
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }

          this.clients = data.content;
        },
        err => {

          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }

        }
      );
    }
    else{
      this.clientService.getPaginatedClients(this.page - 1, this.pageSize).subscribe(
        data => {
          // console.table(data.content);
          this.totalItems = data.totalElements;
          this.first = data.first;
          this.last = data.last;

          if(this.page * this.pageSize > data.totalElements){
            this.showin = data.totalElements;
          }
          else{
            this.showin = this.page * this.pageSize;
          }

          this.clients = data.content;
        },
        err => {

          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }

        }
      );
    }
  }

  clearFilters(){
    this.agencyFilter     = "";
    this.clientFilter     = "";
    this.startDateFilter   = "";
    this.endDateFilter   = "";
    this.directorateFilter   = "";
  }

}
