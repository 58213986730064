<main class="mt-5">
  <!-- <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,1,0" /> -->
  <!-- header part -->
  <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 text-left">
          <span class="text-decoration-none" style="font-size: xx-large;">Forms</span>
        </div>
        <div class="col-12 col-sm-6 col-md-4 text-md-right text-sm-left mt-2 mt-md-0">

        </div>
      </div>
  </div>

  <!-- content -->
  <section class="content mt-4">
      <div class="container-fluid">
          <div class="row" >
              <div class="col-md-12">
                  <div class="card">
                    <div class="">
                      <div class=" tab-column">
                          <!-- <div class="tab-column-inner tab-column-inner-bottom">
                              <div class="">
                                  <button class="btn btn-success ml-auto" *ngIf="userType == 'worker'" (click)="openModal(add)">Upload Forms</button>

                              </div>
                          </div> -->
                          <div class="tab-column-inner ">
                              <div class="tab-content-info">
                                  <table id="customers">
                                      <tr >
                                      <th>Form</th>
                                      <th>Actions</th>
                                      <th>Last Modified</th>
                                      <!-- <th class="text-center">Status</th> -->
                                      </tr >

                                      <tr >
                                      <td>Application form</td>
                                      <td class="text-center text-primary-custom" routerLink="/worker/forms/application" ><i class="fa fa-eye text-primary-custom"></i></td>
                                      <td>{{applicationDetails?.lastModifiedDate}}</td>
                                      </tr>
                                      <tr >
                                        <td>Occupational Health Medical Questionnaire</td>
                                        <td class="text-center text-primary-custom" routerLink="/worker/forms/occupational" ><i class="fa fa-eye text-primary-custom"></i></td>
                                        <td>{{occupationalDetails?.lastModifiedDate}}</td>
                                      </tr>
                                      <tr >
                                        <td>HMRC Tax Declaration</td>
                                        <td class="text-center text-primary-custom" routerLink="/worker/forms/hmrc" ><i class="fa fa-eye text-primary-custom"></i></td>
                                        <td>{{hmrcDetails?.lastModifiedDate}}</td>
                                      </tr>
                                      <tr >
                                        <td>Bank Details</td>
                                        <td class="text-center text-primary-custom" routerLink="/worker/forms/bank" ><i class="fa fa-eye text-primary-custom"></i></td>
                                        <td>{{bankingDetails?.lastModifiedDate}}</td>
                                      </tr>
                                      <tr >
                                  </table>
                              </div>
                          </div>

                      </div>
                  </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
</main>


