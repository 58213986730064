import { BillingModule } from '../admin/billing/billing.module';
import { WorkersListComponent } from '../admin/workers/workers-list/workers-list.component';
import { WorkerDetailsComponent } from 'src/app/admin/workers/worker-details/worker-details.component';
import { WorkerHomeComponent } from './worker-home/worker-home.component';
import { NotFoundComponent } from '../shared/pages/not-found/not-found.component';
import { AuthGuard } from '../shared/utilities/auth.guard';
import { WorkerContainerComponent } from './worker-container/worker-container.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClientsListComponent } from '../admin/clients/clients-list/clients-list.component';
import { WorkerAgencyComponent } from './worker-agency/worker-agency.component';
import { TrainingsComponent } from './trainings/trainings.component';
import { ComplianceComponent } from './compliance/compliance.component';
import { FormsComponent } from './forms/forms.component';
import { OccupationalComponent } from './forms/components/occupational/occupational.component';
import { HmrcComponent } from './forms/components/hmrc/hmrc.component';
import { BankComponent } from './forms/components/bank/bank.component';
import { ApplicationComponent } from './forms/components/application/application.component';
import { CertificateComponent } from './certificate/certificate.component';
import { WorkerTransportListComponent } from './transport/worker-transport-list/worker-transport-list.component';
import { WorkerTransportsModule } from './transport/worker-transport.module';

const routes: Routes = [
  {
    path: 'worker',
    component: WorkerContainerComponent,
    canActivate: [AuthGuard],
    children: [

      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: WorkerHomeComponent },
      { path: 'clients', component: ClientsListComponent },
      { path: 'workers', component: WorkersListComponent },
      { path: 'workers/info/:id', component: WorkerDetailsComponent },
      { path: 'agency/:id', component: WorkerAgencyComponent },
      { path: 'certificate/:id', component: CertificateComponent },
      { path: 'trainings', component: TrainingsComponent },
      { path: 'forms/application', component: ApplicationComponent },
      { path: 'forms/occupational', component: OccupationalComponent },
      { path: 'forms/hmrc', component: HmrcComponent },
      { path: 'forms/bank', component: BankComponent },
      { path: 'forms', component: FormsComponent },
      { path: 'compliance', component: ComplianceComponent },

      // { path: 'shifts', loadChildren: () => import('./worker-shifts/worker-shifts.module').then(m => m.WorkerShiftsModule) },
      // { path: 'staff', loadChildren: () => import('./worker-staff/worker-staff.module').then(m => m.WorkerStaffModule) },
      // { path: 'billing', loadChildren: () => import('./worker-billing/worker-billing.module').then(m => m.WorkerBillingModule) },
      // { path: 'rates', loadChildren: () => import('./worker-rates-codes/worker-rates-codes.module').then(m => m.WorkerRatesCodesModule) },
      // { path: 'payslips', loadChildren: () => import('./worker-payroll/worker-payroll.module').then(m => m.WorkerPayrollModule) },
      { path: 'communications', loadChildren: () => import('../admin/communications/communications.module').then(m => m.CommunicationsModule) },
      { path: 'transport', loadChildren: () => import('./transport/worker-transport.module').then(m => m.WorkerTransportsModule) },
      { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },

      // { path: 'reporting', loadChildren: () => import('./worker-reporting/worker-reporting.module').then(m => m.WorkerReportingModule) },

      // not found
      { path: '**', component: NotFoundComponent, pathMatch: 'full' },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkerRoutingModule { }
