import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  //base_url: string = environment.API_url + ':8300/api/v1';
  base_url: string = environment.API_url + '/worklink-api/api/v1';

  constructor(
    private http: HttpClient
  ) { }

  getAllServices(): Observable<any> {
    return this.http.get(`${this.base_url}/services`);
  }
  getPaginatedServices(page, size): Observable<any> {
    return this.http.get(`${this.base_url}/services/${page}/${size}`);
  }

  createService(data): Observable<any> {
    return this.http.post(`${this.base_url}/service`, data);
  }

  updateService(data): Observable<any> {
    return this.http.put(`${this.base_url}/service`, data);
  }

  getServiceById(ref): Observable<any> {
    return this.http.get(`${this.base_url}/service/${ref}`);
  }

  deleteService(ref): Observable<any> {
    return this.http.delete(`${this.base_url}/service/${ref}`);
  }

}
