
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { JobsTableComponent } from './agency-jobs-list/components/jobs-table/jobs-table.component';
import { AgencyJobsListComponent } from './agency-jobs-list/agency-jobs-list.component';
import { VehicleComponent } from './vehicle/vehicle.component';
import { ViewVehicleComponent } from './vehicle/view-vehicle/view-vehicle.component';
import { AgencyLogsListComponent } from './agency-logs-list/agency-logs-list.component';

const routes: Routes = [
  // { path: '', redirectTo: 'list', pathMatch: 'full' },
  { path: 'agency-list', component: AgencyJobsListComponent },
  { path: 'agency-vehicles', component: VehicleComponent },
  { path: 'applied/:id', component: JobsTableComponent },
  { path: 'vehicle/view/:id', component: ViewVehicleComponent },
  { path: 'agency-logs-list', component: AgencyLogsListComponent },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class JobsRoutingModule { }
