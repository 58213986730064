import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ShiftService } from './../../../../shared/services/shift.service';
import { Component, Input, OnInit } from '@angular/core';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-awaiting-shifts',
  templateUrl: './awaiting-shifts.component.html',
  styleUrls: ['./awaiting-shifts.component.css']
})
export class AwaitingShiftsComponent implements OnInit {
  @Input('agencyFilter') agencyFilter = '';
  @Input('clientFilter') clientFilter = '';

  @Input('startDateFilter') startDateFilter = '';
  @Input('endDateFilter') endDateFilter = '';
  @Input('directorateFilter') directorateFilter = '';
  @Input('workerFilter') workerFilter = '';


  shifts: any= [];
  shiftInfo: any;
  selectedShift: any;


  userType: string;

  page = 1;
  pageSize = 20;
  totalItems = 0;
  showin = 0;
  first: boolean;
  last: boolean;

  showNotes: boolean;
  clientId: any;

  constructor(
    private shiftsService: ShiftService,
    private toast: ToastrService,
    private router: Router,
    private authService: AuthService,
    private modalService: NgbModal,
    private storageService: StorageService
  ) {
    this.userType = authService.getUserType();
   }

  ngOnInit(): void {
    this.getShifts();

    if(this.userType == 'admin'){
      this.showNotes = true;
    }
  }

  handlePageChange(event) {

    if(event == 'next'){
      this.page += 1;
    }
    else if(event == 'prev' && this.page != 1){
      this.page -= 1;
    }

    this.getShifts();
  }



  getShifts(){
    this.shiftsService.getPaginatedShiftsByStatus(this.page - 1, this.pageSize, 'AWAITING_AUTHORIZATION', this.agencyFilter, this.clientFilter, this.workerFilter, this.startDateFilter, this.endDateFilter).subscribe(
      data => {
        // console.table(data);

        this.totalItems = data.totalElements;
        this.first = data.first;
        this.last = data.last;

        if(this.page * this.pageSize > data.totalElements){
          this.showin = data.totalElements;
        }
        else{
          this.showin = this.page * this.pageSize;
        }

        this.shifts = data.content;
      }
    );
  }

  viewDetails(ref, modal){
    if(ref){
      this.shifts.forEach(r => {
        if(r.id == ref || r==ref){
          this.shiftInfo = r;
        }
      });

      console.log(this.shifts);
      console.log(this.shiftInfo);
      console.log(ref);

      // check show notes issue
      if(this.userType != 'admin'){
        this.userType == 'agency' ? this.showNotes = this.shiftInfo?.showNoteToAgency : this.userType == 'client' ? this.showNotes = this.shiftInfo?.showNoteToFw : this.showNotes = false;
      }

      this.selectedShift = ref;
      this.openBigModal(modal);
    }

    // console.table(this.shiftInfo);
  }

  editDetails(ref){
    this.router.navigate([`/${this.userType}/shifts/edit/${ref}`]);
    this.modalService.dismissAll();
  }

  authorizeModal(ref, modal){
    this.modalService.dismissAll();
    if(ref){
      this.selectedShift = ref;

      this.openModal(modal);
    }
  }

  authorizeShift(clientId){
    if(this.userType=="client"){
      this.clientId =this.storageService.decrypt(localStorage.getItem('clientId'));
    }
    if(this.userType=="agency"){
      this.clientId = clientId;
    }

    if(this.selectedShift){
      this.shiftsService.authorizeShift(this.selectedShift.id,this.clientId ).subscribe(
        resp => {
          // console.table(resp);
          this.modalService.dismissAll();
          this.getShifts();
          this.toast.success('Shift Authorized Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info');
    }
  }

  openModal(modal){
    this.modalService.open(modal, {centered: true});
  }

  openBigModal(modal){
    this.modalService.open(modal, {centered: true, size: 'lg'});
  }

}
