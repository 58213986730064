import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FileService } from 'src/app/shared/services/file.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-hmrc',
  templateUrl: './hmrc.component.html',
  styleUrls: ['./hmrc.component.css']
})
export class HmrcComponent implements OnInit {
  restriction = false;
  dbs = false;
  related = false;
  addHmrcForm: FormGroup;
  workerId: any;
  signedDoc: any;
  uploaded= false;
  hmrcDetails: any;
  agencyId: any;

  constructor(
    private activateRoute: ActivatedRoute,
    private toast: ToastrService,
    private formsService: FormsService,
    private fb: FormBuilder,
    private fileService: FileService,
    private storageService: StorageService,

  ) {
    this.addHmrcForm =  this.fb.group({
      id: [],
      lastname: [],
      firstname: [],
      address: [],
      insuarance: [],
      employmentStart: [],
      sex: [],
      dob: [],
      otherJob: [],
      payments: [],
      april: [],
      statement: [],
      loan: [],
      applyStatement: [],
      plan: [],
      signed: [],
      signDate: [],
      fullname: [],
      submitted: [],
      workerId: [],
      agencyId: [],
      status: [],
      comment: [],
    })
  }

  ngOnInit(): void {

    this.activateRoute.params.subscribe((params) => {
      this.workerId = params["id"]
    });

    this.agencyId = this.storageService.decrypt(localStorage.getItem('agentId'));

    if(!this.agencyId){
      this.activateRoute.params.subscribe((params) => {
        this.agencyId = params["agencyId"];
      });

    }

    this.getHmrc();
  }



  getHmrc(){
    this.formsService.getAgencyWorkerHmrc(this.workerId, this.agencyId).subscribe(
      data => {
        // this.addHmrcForm = this.fb.group(data);

        this.hmrcDetails = data;
        this.addHmrcForm = this.fb.group({
          id: [data.id],
          lastname: [data.lastname],
          firstname: [data.firstname],
          address: [data.address],
          insuarance: [data.insuarance],
          employmentStart: [data.employmentStart],
          sex: [data.sex],
          dob: [data.dob],
          otherJob: [data.otherJob],
          payments: [data.payments],
          april: [data.april],
          statement: [data.statement],
          loan: [data.loan],
          applyStatement: [data.applyStatement],
          plan: [data.plan],
          signed: [data.signed],
          signDate: [data.signDate],
          fullname: [data.fullname],
          submitted: [data.submitted],
          workerId: [data.workerId],
          agencyId: [data.agencyId],
          status: [data.status],
          comment: [data.comment],
        });




        // this.addHmrcForm.value.firstname = this.worker?.firstname;
        // this.addHmrcForm.value.lastname = this.worker?.lastname;
        console.log(this.addHmrcForm.value);


      }
    );
  }


  addWorkerHmrc(form: FormGroup, submit?){

    if(submit=='accept'){
      this.addHmrcForm.value.status = 'APPROVED';
    }else{
      this.addHmrcForm.value.status = 'REJECTED';
    }


    form.value.serviceId = 1;
    if(form.valid){
      form.value.workerId =this.workerId ;
      form.value.agencyId =this.agencyId ;

      this.formsService.updateWorkerHmrc(form.value).subscribe(
        resp => {
          this.getHmrc();
          this.toast.success('Worker Hmrc Added Successfully');
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }


  setYes(title){
    if(title=='restriction'){this.restriction = true}
    if(title=='dbs'){this.dbs = true}
    if(title=='related'){this.related = true}
  }

  resetYes(title){
    if(title=='restriction'){this.restriction = false}
    if(title=='dbs'){this.dbs = false}
    if(title=='related'){this.related = false}

  }


  onChange(event) {
    this.signedDoc = event.target.files[0];
  }

  uploadSignature(){
    this.fileService.uploadHmrcSig(this.signedDoc, this.storageService.decrypt(localStorage.getItem('workerId'))).subscribe(
      event => {
        if(event instanceof HttpResponse) {

        };
        this.uploaded = true;
        this.getHmrc();
        this.toast.info("Sucessfully uploaded.");

      },
      (err) => {





      }, () => {

      }
    );
  }

}
